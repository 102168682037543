import React from 'react';
import RecruitingProvider from './RecruitingProvider';

type RecruitingComponentProps = {
  children: React.ReactNode;
};

function RecruitingComponent({ children }: RecruitingComponentProps) {
  return <RecruitingProvider>{children}</RecruitingProvider>;
}

export default RecruitingComponent;
