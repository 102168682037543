const handelCancelSearchContractorModal = (dispatch, from) => {
  dispatch({ type: 'hideContractorModal', payload: 0 });
  if (from == 'related') {
    dispatch({ type: 'nextStep', payload: { step: 3, from: null } });
  } else {
    dispatch({ type: 'removeContractInfo', payload: null });
  }
};

export default handelCancelSearchContractorModal;
