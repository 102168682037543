import axios from "@services/api/axios";

const endpoints = {
  getContractorByIIN: (data) => axios.post('contractorCard/search' , data),
  saveContractorKias: (data) => axios.post('contractorCard' , data),
  getContractorByISN: (isn) => axios.get(`contractorCard/${isn}`),
  getAgreementByISN: (data) => axios.post(`contract/get-contract`, data),
  getContragentCardSettings: () => axios.get('contractorCard/getAllSettings'),
  saveContractorDocs: (data) => axios.post('contractorCard/docContractor' , data),
  editContractor: (data) => axios.post('contractorCard/edit' , data),
  deleteContractorDocument: (docIsn) => axios.post('contractorCard/docContractor/delete' , {isn : docIsn}),
  getContractorKBM: (subj_isn) => axios.post('contractorCard/getSubjectKBM' , {subj_isn}),
  saveContractor: async (data) => {
    let result;
    await axios
      .post('/quotation/role/save', data)
      .then((success) => (result = success))
      .catch((error) => {
        console.log(error);
        
        if (error.response) {
          result = error.response;
        } else if (error.request) {
          result = error.request;
        } else {
          result = error.message;
        }
      });
    return result;
  },
  deleteContractor: (data) => axios.post('quotation/role/delete' , data),
  addEditRelatedPerson: (data) => axios.post('contractorCard/setRelatedPerson' , data),
  createAddendumQuotation: (data) => axios.post('contract/addendum/create ', data),
  denounceContract: (data) => axios.post('contract/cancel', data),
  getContractStatus: () =>  axios.get('contract/statuses'),
  updateContractStatus: (data) => axios.post('contract/setAgrStatus' , data)
}

export default endpoints;
