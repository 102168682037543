import CInput from '@components/UI/input/CInput';
import { Button, Col, Divider, Form, Modal, Row } from 'antd';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
// import useQuotation from '@modules/Quotation/hooks/useQuotation';

const SearchCarModal = ({
  closeTransportModal,
  onSearch,
  form,
  isLoading,
  open,
}) => {
  // const { stateDateQuotation } = useQuotation();

  return (
    <Modal
      title={
        <span
          style={{
            color: '#7449ef',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          Добавить транспортное средство
        </span>
      }
      open={open}
      onCancel={closeTransportModal}
      footer={<></>}
    >
      <Divider />
      <CustomLoader spinning={isLoading}>
        <Form
          className="c-form"
          layout="vertical"
          name="transportForm"
          form={form}
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="reg_no" label="Гос. Номер">
                <CInput placeholder="Гос. Номер" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="vin" label="VIN">
                <CInput placeholder="VIN" />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Button
              type="primary"
              form="transportForm"
              style={{ width: '100%' }}
              onClick={onSearch}
            >
              Найти
            </Button>
          </Col>
        </Form>
      </CustomLoader>
    </Modal>
  );
};
export default SearchCarModal;
