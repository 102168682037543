import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import apiQuotation from '@modules/Quotation/services';
import { useImmerReducer } from 'use-immer';
import useQuotation from '../hooks/useQuotation';
import dayjs from 'dayjs';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import MainInvoiceModal from '../MainContract/Invoice';
const { Option } = Select;

interface DataType {
  PAY_ISN: string;
  DATE_PAY: string;
  Currency: string;
  AMOUNT: string;
  OBJ_ISN: string;
  CONDISN: string;
  index: number;
}
type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

type Action = {
  type: 'saveGraphList' | 'loading';
  payload?: any;
};
interface State {
  graphList: DataType[];
  isLoad: boolean;
}
const initialState: State = {
  graphList: [],
  isLoad: false,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'saveGraphList': {
      draft.graphList = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoad = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const ContractDocumentModal = (props) => {
  const {
    isModalOpen,
    closeModal,
    agrIsn,
    userForeignId,
    objData,
    condData,
    setInvoiceIsn,
    productIsn,
  } = props;

  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string>();
  const [edit, setEdit] = useState<any>({});
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const { productSelectedQuotation } = useQuotation();
  const [editForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const { graphList, isLoad } = state;

  const formGraph = async () => {
    const { data } = await apiQuotation.quotation.setGraphAgr({
      agr_isn: agrIsn,
      user_isn: userForeignId,
    });
    dispatch({ type: 'saveGraphList', payload: data.data });
  };
  const editData = (editElem) => {
    dispatch({ type: 'loading', payload: true });
    setEdit(editElem);
    editForm.setFieldsValue({
      date: dayjs(editElem.DATE_PAY, 'DD.MM.YYYY'),
      currency: 9813,
      premium_sum: editElem.AMOUNT,
      object: Number(editElem.OBJ_ISN),
      cond: condData.filter((item) => item?.isn === Number(editElem.CONDISN))[0]
        ?.isn,
    });

    dispatch({ type: 'loading', payload: false });
  };
  const deleteGraph = async (pay_isn) => {
    dispatch({ type: 'loading', payload: true });
    const { data } = await apiQuotation.quotation.setGraphAgr({
      agr_isn: `${agrIsn}`,
      user_isn: `${userForeignId}`,
      pay_isn,
      oper: 'D',
    });
    dispatch({ type: 'saveGraphList', payload: data.data });
    dispatch({ type: 'loading', payload: false });
  };
  const columns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
    },
    {
      title: 'Дата',
      dataIndex: 'DATE_PAY',
      key: 'DATE_PAY',
      width: '15%',
    },
    {
      title: 'Валюта',
      dataIndex: 'Currency',
      key: 'Currency',
      width: '12%',
      render: () => 'KZT',
    },
    {
      title: 'Страховая премия',
      dataIndex: 'AMOUNT',
      key: 'AMOUNT',
      width: '18%',
    },
    {
      title: 'Объект',
      dataIndex: 'OBJ_ISN',
      key: 'OBJ_ISN',
      width: '20%',
      render: (_, record) => {
        return objData?.map((item) => {
          if (Number(item.isn) === Number(record.OBJ_ISN)) {
            return <p>{item.objname}</p>;
          }
        });
      },
    },
    {
      title: 'Условия ',
      dataIndex: 'CONDISN',
      key: 'CONDISN',
      width: '14%',
      render: (_, record) => {
        return condData?.map((item) => {
          if (Number(item.isn) === Number(record.CONDISN)) {
            return <p>{item.premiumsum}</p>;
          }
        });
      },
    },
    {
      title: '',
      key: 'action',
      width: '16%',
      render: (_, record) => (
        <Row>
          <Col span={11}>
            <Button type="primary" onClick={() => editData(record)}>
              <EditOutlined />
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              danger
              onClick={() => deleteGraph(record.PAY_ISN)}
            >
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
    },
  ];
  const closeEdit = () => {
    setEdit(null);
    editForm.resetFields();
  };

  const saveEdit = async (item) => {
    dispatch({ type: 'loading', payload: true });
    const formData = editForm.getFieldsValue();
    const { data } = await apiQuotation.quotation.setGraphAgr({
      agr_isn: agrIsn,
      user_isn: userForeignId,
      pay_isn: Number(item.PAY_ISN),
      date_pay: dayjs(formData.date).format('DD.MM.YYYY'),
      amount: formData.premium_sum,
      oper: 'U',
    });
    dispatch({ type: 'saveGraphList', payload: data.data });
    closeEdit();
    dispatch({ type: 'loading', payload: false });
  };
  const closeAdd = () => {
    setIsAdd(false);
    addForm.resetFields();
  };
  const addGraph = async (elem) => {
    dispatch({ type: 'loading', payload: true });
    const { data } = await apiQuotation.quotation.setGraphAgr({
      agr_isn: agrIsn,
      user_isn: userForeignId,
      pay_isn: Number(elem.PAY_ISN),
      date_pay: dayjs(elem.date).format('DD.MM.YYYY'),
      amount: elem.premium_sum,
      cond_isn: elem.cond,
      oper: 'I',
    });
    dispatch({ type: 'saveGraphList', payload: data.data });
    closeAdd();
    dispatch({ type: 'loading', payload: false });
  };
  const resetGraph = async () => {
    const { data } = await apiQuotation.quotation.resetGraph({
      agr_isn: agrIsn,
    });
    if (data.success) {
      dispatch({ type: 'saveGraphList', payload: [] });
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRows(selectedRows[0].PAY_ISN);
    },
  };
  const createInvoice = async (all: number) => {
    if (selectedRows && selectedRows.length > 0) {
      const { data } = await apiQuotation.invoice.createInvoice({
        isn: selectedRows,
        all: all,
      });
      setInvoiceIsn(data.data.INVOICEDOC_ISN);
    } else {
      notification.error({
        message: 'Выберите платеж',
        description: 'Выберите платеж для создания счета',
      });
    }
  };

  useEffect(() => {
    if (agrIsn && userForeignId) {
      (async () => {
        const { data } = await apiQuotation.quotation.getGraphAgr(agrIsn);
        dispatch({ type: 'saveGraphList', payload: data.data });
      })();
    }
  }, [agrIsn, userForeignId]);

  return (
    <Modal
      title={
        <span
          style={{
            color: '#7449ef',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          График платежей
        </span>
      }
      open={isModalOpen}
      onCancel={closeModal}
      width="1000px"
      footer={[
        <>
          <Col span={24}>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                setIsAdd(true);
              }}
            >
              Добавить строку
            </Button>
          </Col>
          <Col span={24} className="mt-3">
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={() => createInvoice(0)}
            >
              Создать счет
            </Button>
          </Col>
          <Col span={24} className="mt-3">
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={() => createInvoice(1)}
            >
              Объединить счета
            </Button>
          </Col>
          <Col span={24} className="mt-3">
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={formGraph}
            >
              Сформировать
            </Button>
          </Col>
          {graphList.length > 0 && !isAdd && (
            <Col span={24} className="mt-3">
              <Button
                type="primary"
                style={{ width: '100%' }}
                onClick={resetGraph}
                danger
              >
                Очистить график платежей
              </Button>
            </Col>
          )}
        </>,
      ]}
    >
      <CustomLoader spinning={isLoad}>
        <Table
          className={`mt-5 ${graphList?.length > 0 ? 'pay-table' : ''}`}
          columns={columns}
          dataSource={graphList
            .filter((item) => edit?.PAY_ISN !== item.PAY_ISN)
            .map((item, index) => {
              return { ...item, index: index + 1, key: index + 1 };
            })}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          showHeader={true}
          pagination={{
            position: [
              'none' as TablePaginationPosition,
              'none' as TablePaginationPosition,
            ],
          }}
        />
        {graphList.length > 0 &&
          graphList?.map((item, index) => {
            if (edit?.PAY_ISN === item.PAY_ISN) {
              return (
                <Form
                  name="editForm"
                  form={editForm}
                  onFinish={() => saveEdit(item)}
                >
                  <Row className="mt-3">
                    <Col style={{ width: '5%', padding: '0 15px' }}>
                      <p>{edit.index}</p>
                    </Col>
                    <Col style={{ width: '15%', padding: '0 15px' }}>
                      <Form.Item name="date">
                        <DatePicker
                          placeholder="Дата"
                          value={dayjs(edit.DATE_PAY)}
                          format="DD.MM.YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: '12%', padding: '0 15px' }}>
                      <Form.Item name="currency">
                        <Select placeholder="Валюта" style={{ width: '100%' }}>
                          {productSelectedQuotation?.map((element) =>
                            element.currency?.map((item) => {
                              return (
                                <Option
                                  value={Number(item.curr_isn)}
                                  key={Number(item.curr_isn)}
                                >
                                  {item.curr_name}
                                </Option>
                              );
                            })
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ width: '18%', padding: '0 15px' }}>
                      <Form.Item name="premium_sum">
                        <Input placeholder="Страховая премия" />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: '20%', padding: '0 15px' }}>
                      <Form.Item name="object">
                        <Select
                          placeholder="Объект"
                          style={{ width: '100%' }}
                          disabled
                        >
                          {objData?.map((element) => (
                            <Option
                              value={Number(element.isn)}
                              key={Number(element.isn)}
                            >
                              {element.obj_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ width: '14%', padding: '0 15px' }}>
                      <Form.Item name="cond">
                        <Select placeholder="Условия" style={{ width: '100%' }}>
                          {condData
                            ?.filter(
                              (item) =>
                                Number(item.obj_isn) === Number(edit.OBJ_ISN)
                            )
                            .map((element) => (
                              <Option
                                value={element.isn}
                                key={element.isn + Math.random()}
                              >
                                {element.premium_sum}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ width: '12%' }}>
                      <Button type="primary" htmlType="submit">
                        Сохранить
                      </Button>
                    </Col>
                    <Col style={{ width: '4%' }}>
                      <Button onClick={closeEdit}>x</Button>
                    </Col>
                  </Row>
                </Form>
              );
            }
          })}
        {isAdd && (
          <Form form={addForm} onFinish={addGraph}>
            <Row className="mt-3">
              <Col style={{ width: '5%', padding: '0 15px' }}>
                <p>{graphList.length + 1}</p>
              </Col>
              <Col style={{ width: '15%', padding: '0 15px' }}>
                <Form.Item name="date">
                  <DatePicker placeholder="Дата" />
                </Form.Item>
              </Col>
              <Col style={{ width: '12%', padding: '0 15px' }}>
                <Form.Item name="currency">
                  <Select placeholder="Валюта" style={{ width: '100%' }}>
                    {productSelectedQuotation?.map((element) =>
                      element.currency?.map((item) => {
                        return (
                          <Option
                            value={Number(item.curr_isn)}
                            key={Number(item.curr_isn)}
                          >
                            {item.curr_name}
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col style={{ width: '18%', padding: '0 15px' }}>
                <Form.Item name="premium_sum">
                  <Input placeholder="Страховая премия" />
                </Form.Item>
              </Col>
              <Col style={{ width: '20%', padding: '0 15px' }}>
                <Form.Item name="object">
                  <Select placeholder="Объект" style={{ width: '100%' }}>
                    {objData?.map((element) => (
                      <Option
                        value={Number(element.isn)}
                        key={Number(element.isn)}
                      >
                        {element.obj_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col style={{ width: '14%', padding: '0 15px' }}>
                <Form.Item name="cond">
                  <Select placeholder="Условия" style={{ width: '100%' }}>
                    {condData?.map((element) => (
                      <Option
                        value={Number(element.isn)}
                        key={Number(element.isn)}
                      >
                        {element.premium_sum}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col style={{ width: '12%' }}>
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
              </Col>
              <Col style={{ width: '4%' }}>
                <Button onClick={closeAdd}>x</Button>
              </Col>
            </Row>
          </Form>
        )}
      </CustomLoader>
    </Modal>
  );
};
export default ContractDocumentModal;
