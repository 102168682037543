import { useState, useEffect } from "react";
import axios from "axios";
import { notification } from "antd";

import api from "../services/api";

export interface Handbook {
  FULLNAME: string;
  ISN: string;
  active: boolean;
  children: HandbookListType;
  company_id: number;
  constant: string;
  constant1: any;
  foreign_id: number;
  full_name: string;
  has_child: number;
  id: number;
  num_code: any;
  parent_id: number;
  short_name: string;
  text_code: any;
  updated_at: Date;
  updated_by: string;
}

export type HandbookListType = Handbook[];

const useHandbook = (constant = "") => {
  const [handbookList, setHandbookList] = useState<HandbookListType>([]);
  const [handbookStageList, setHandbookStageList] = useState<HandbookListType>([]);
  const [isLoadingHandbookList, setIsLoadingHandbookList] = useState(false);
  const [isLoadingHandbookStageList, setIsLoadingHandbookStageList] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingHandbookList(true);
        setIsLoadingHandbookStageList(true);
        const { data } = await api.handbook.getHandbook({
          constant,
        });
        setHandbookList(data.data);
        if(constant == 'cDocStatus'){
          const stagesData = await api.handbook.getStageHandbook();
          const stages = stagesData.data;
          setHandbookStageList(stages.data);
        }
      } catch (error) {
        let message;

        if (axios.isAxiosError(error) && error.response) {
          message = error.response.data.message;
        } else {
          message = String(error);
        }

        notification.info({
          message: "Ошибка",
          description: message,
        });
      } finally {
        setIsLoadingHandbookList(false);
        setIsLoadingHandbookStageList(false);
      }
    })();
  }, []);

  return { handbookList, handbookStageList , isLoadingHandbookList , isLoadingHandbookStageList};
};

export default useHandbook;
