import { Table } from 'antd';
import { IDocRowMycent } from '../type';

interface IProps {
  data: [IDocRowMycent[]];
}

function DynamicTableMycent(props: IProps) {
  const { data } = props;

  let dynamicColumns: any = [];
  let modifiedData: any[] = [];
  let dynamicData: any[] = [];
  const sortByDisplayNo = (itemA, itemB) =>
    parseInt(itemA.display_no, 10) - parseInt(itemB.display_no, 10);

  if (data[0].length) {
    for (let row = 0; row < 1; row++) {
      const filterData = [...data[row]].sort(sortByDisplayNo);

      for (let col = 0; col < data[0].length; col++) {
        dynamicColumns.push({
          title: filterData[col].field_name,
          key: `row-${row}-col-${col}`,
          dataIndex: `full_name_${col}`,
          rowKey: row,
        });
      }
    }

    for (let row = 0; row < data.length; row++) {
      const filterData = [...data[row]].sort(sortByDisplayNo);
      for (let col = 0; col < data[row].length; col++) {
        if (filterData[col].num_value?.full_name) {
          modifiedData.push({
            key: `row-${row}-col-${col}`,
            [`full_name_${col}`]: filterData[col].num_value?.full_name
              ? filterData[col].num_value?.full_name
              : filterData[col].num_value,
            rowKey: row,
          });
        } else if (filterData[col].date_value) {
          modifiedData.push({
            key: `row-${row}-col-${col}`,
            [`full_name_${col}`]: filterData[col].date_value,
            rowKey: row,
          });
        }
      }
    }
  }

  if (data[0].length) {
    for (let row = 0; row < data.length; row++) {
      dynamicData = Object.values(
        modifiedData.reduce((acc, curr) => {
          const { key, rowKey } = curr;
          if (!acc[rowKey]) {
            acc[rowKey] = { key: '', rowKey };
          }
          if (key.includes(`row-${rowKey}-col-`)) {
            const suffix = key.split('-').pop();
            acc[rowKey][`full_name_${suffix}`] = curr[`full_name_${suffix}`];
          }
          acc[rowKey].key = key;
          return acc;
        }, {})
      );
    }
  }
  return (
    <Table
      dataSource={dynamicData}
      columns={dynamicColumns}
      bordered
      scroll={{ x: 1000 }}
    />
  );
}

export default DynamicTableMycent;
