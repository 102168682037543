import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from './providers/AuthProvider';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import locale from 'antd/locale/ru_RU';
import CounterProvider from '@providers/CounterProvider';
import 'antd/dist/reset.css';
import './styles/font.scss';
import './App.scss';
import './styles/about-me.scss';
import './styles/post.css';
import './pages/app.scss';
import './styles/centcoins.scss';
import 'antd-css-utilities/utility.min.css';
import './styles/extension-ant-design.scss';
dayjs.locale('ru');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// test

root.render(
  <AuthProvider>
    <CounterProvider>
      <Router>
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </Router>
    </CounterProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
