import ClassObjectQuotationContext from '@contexts/QuotationContext/ClassObjectQuotationContext';
import { useCallback, useMemo, useState } from 'react';
import useCarGetDictis from '../hooks/Objects/useCarGetDictis';
import useCargo from '../hooks/Objects/useCargo';
interface IClassObjectQuotationProvider {
  children: React.ReactNode;
}

type classObjecList = {
  classObjectId: number;
  id: string;
  isCarCalled?: boolean;
};

const QuotationClassObjectProvider: React.FC<IClassObjectQuotationProvider> = (
  props
) => {
  const [classObjecList, setClassObjecList] = useState<any[]>([]);

  const {
    autoData,
    autoCategory,
    autoOwner,
    autoPurpose,
    autoColor,
    autoOwnerShip,
    autoCountry,
    fetchCarDicti,
  } = useCarGetDictis();

  const {
    packageCargo,
    measureVolume,
    transport,
    vidTransportFullList,
    fetchCargoDicti,
  } = useCargo();

  const identifyClassObject = useCallback((classObjectId: number): void => {
    setClassObjecList((classObjecList) => {
      if (
        classObjecList.indexOf(classObjectId) === -1 &&
        classObjectId === 2118
      ) {
        fetchCarDicti();
        return [...classObjecList, classObjectId];
      } else if (
        classObjecList.indexOf(classObjectId) === -1 &&
        classObjectId === 2120
      ) {
        fetchCargoDicti();
        return [...classObjecList, classObjectId];
      } else if (classObjecList.indexOf(classObjectId) === -1) {
        return [...classObjecList, classObjectId];
      }
      return [...classObjecList];
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      autoData,
      autoCategory,
      autoOwner,
      autoPurpose,
      autoColor,
      autoOwnerShip,
      autoCountry,
      classObjecList,
      setClassObjecList,
      fetchCarDicti,
      identifyClassObject,
      packageCargo,
      measureVolume,
      transport,
      vidTransportFullList,
    }),
    [
      classObjecList,
      autoData,
      autoCategory,
      autoOwner,
      autoPurpose,
      autoColor,
      autoOwnerShip,
      autoCountry,
      packageCargo,
      measureVolume,
      transport,
      vidTransportFullList,
    ]
  );

  return (
    <ClassObjectQuotationContext.Provider value={contextValue}>
      {props.children}
    </ClassObjectQuotationContext.Provider>
  );
};

export default QuotationClassObjectProvider;
