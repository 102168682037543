import React, { useEffect, useMemo } from 'react';
import { Card, Checkbox, Col, Form, Row, notification, Select } from 'antd';
import CInput from '@components/UI/input/CInput';
import CTabs from '@components/UI/tabs/CTabs';
import CSelect from '@components/UI/select/CSelect';
import api from '@modules/Quotation/services/saveQuotation';
import apiDicti from '@modules/Quotation/services';
import axios from 'axios';
import TreeSelectKias from '@modules/TreeSelect/TreeSelectKias';
import dayjs from 'dayjs';
import useCompanyTree from '../hooks/CommonMethods/MainComponents/useCompanyTree';
import { useImmerReducer } from 'use-immer';
import QuotationTreeSelect from '../MainQuotation/QuotationTreeSelect';
import CDatePicker from '@components/UI/datepicker/CDatePicker';
import SubjectCard from '../ObjectQuotation/ObjectCardDynamic/SubjectCard';
const { Option } = Select;
type Action = {
  type: 'setDicti';
  payload?: any;
};
interface State {
  dicti: any;
}
const initialState: State = {
  dicti: null,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'setDicti': {
      draft.dicti = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};
const MainContractAdditionalAttributes = ({
  fields,
  contractData,
  userForeignId,
  agrIsn,
  quotationData,
  productIsn,
}) => {
  const { colleaguesKiasList, isLoadingColleaguesKiasList } = useCompanyTree();
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const [additionalAttributesForm] = Form.useForm();
  const { dicti } = state;

  const checkDisabledChecbkox = () => {
    // TODO: нет динамики !

    const disabledProductIsns = [
      437141, 437401, 437411, 449011, 449051, 471161, 471231, 471401, 471441, 
      471541, 471571, 471641, 471661, 471761, 471771, 473861, 473891, 474131, 
      474141, 474381, 474391, 474411, 474421, 474431, 474471, 475361, 483101, 
      501321, 501351, 501371, 854501, 856601, 882651, 2521131, 2618631, 2684231, 
      2694861, 2713201, 2718231, 2718311, 2718681, 2780971, 5344171, 5819621, 5844681
    ];

    return !disabledProductIsns.includes(Number(productIsn));
  };
  const underData = useMemo(() => {
    if (!contractData) return null;
    return contractData?.data?.addattr_underwriter;
  }, [contractData]);
  let attributesTimeout;
  const onChange = async (attr_isn, val, type, callback: any = undefined) => {
    console.log();

    clearTimeout(attributesTimeout);
    attributesTimeout = setTimeout(async () => {
      let requestBody = {};
      switch (type) {
        case 'DATETIME':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                // val_d: dayjs(val).format('DD-MM-YYYY HH:mm:ss'),
                // val_d: dayjs(val).format('DD.MM.YYYY HH:mm:ss'),
                val_d: dayjs(val).format('YYYY.MM.DD HH:mm:ss'),
                // val_c: dayjs(val).format('YYYY-MM-DD HH:mm:ss'),
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DATE':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_d: val ? dayjs(val).format('YYYY.MM.DD') : null,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'CHECKBOX':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val.target.checked,
                val_n: Number(val.target.checked),
                value: val.target.checked,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'TEXT':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val.target.value,
                val_n: Number(val.target.value),
                value: val.target.value,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DICTI':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val,
                val_n: val,
                value: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DICTI_CODENAME':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val,
                val_n: val,
                value: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'SUBJECT':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_n: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'FLOAT':
        case 'NUMBER':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_n: val.target.value.length > 0 ? +val.target.value : null,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DEPTEMPL':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_n: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        default:
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                value: val.target.value,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
      }
      try {
        await api.attributeSave(requestBody);
      } catch (error) {
        let message;
        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }
        notification.error({
          message: 'Ошибка',
          description: message,
        });
      }

      // Обновляем объект атрибутов
      // const attrValue = val?.target?.value || val;
      // setAttributes(attr_isn, attrValue);

      // Передаем выбранное значение во внешнюю функцию
      if (callback) {
        callback(val);
      }
    }, 400);
  };
  useEffect(() => {
    if (fields) {
      let list;
      fields.map(async (item) => {
        if (
          item.attr_type === 'DICTI' ||
          item.attr_type === 'DICTI_SQL' ||
          item.attr_type === 'SQL' ||
          item.attr_type === 'DICTI_CODENAME'
        ) {
          const { data } = await apiDicti.auto.getDictiByIsn({
            isn: item.attr_isn,
            obj_isn: agrIsn,
          });
          if (list) {
            list = { ...list, [item.attr_isn]: data.data };
          } else {
            list = { [item.attr_isn]: data.data };
          }
          dispatch({ type: 'setDicti', payload: list });
        }
      });
    }
  }, [fields]);

  function renderFieldByType(field, index) {
    switch (field?.attr_type) {
      case 'SQL':
      case 'TEXT':
        console.log(field);
        return (
          <Col span="8" key={index}>
            <Form.Item
              label={field?.attr_name}
              name={[`id-${field?.attr_isn}`, 'char_value']}
              initialValue={
                contractData?.data?.addattr_insurer?.filter(
                  (item) => Number(item.attr_isn) === Number(field.attr_isn)
                )[0]?.val_c
              }
            >
              <CInput
                disabled={
                  field.readonly
                    ? true
                    : field.attr_isn !== '781361' &&
                      field.attr_isn !== '1026221' &&
                      Number(productIsn) !== 221711 &&
                      Number(productIsn) !== 471391
                }
                onChange={(e) => onChange(field?.attr_isn, e, 'TEXT')}
                value={field?.val_c}
                size="large"
                placeholder={field?.attr_name}
              />
            </Form.Item>
          </Col>
        );

      case 'DEPTEMPL':
        return (
          <Col span={8} key={index}>
            <Form.Item
              label={field?.attr_name}
              name={[`id-${field?.attr_isn}`, 'value']}
              rules={[
                {
                  required:
                    typeof field?.required === 'boolean'
                      ? field.required
                      : false,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              initialValue={
                contractData?.data?.addattr_insurer?.filter(
                  (item) => Number(item.attr_isn) === Number(field.attr_isn)
                )[0]?.value
              }
            >
              <TreeSelectKias
                colleaguesKiasList={colleaguesKiasList}
                placeholder={field?.attr_name}
                style={{ width: '100%' }}
                size="large" 
                onChange={(e) => onChange(field?.attr_isn, e, 'DEPTEMPL')}
                disabled={
                  field.readonly
                    ? true
                    : Number(productIsn) !== 221711 &&
                      Number(productIsn) !== 471391
                }
              />
            </Form.Item>
          </Col>
        );
      case 'DICTI_CODENAME':
      case 'DICTI':
        const findItem =
          dicti &&
          dicti[field.attr_isn]?.children?.filter((item) => item.n_children);
        return (
          <Col span={8} key={index}>
            <Form.Item
              label={field?.attr_name}
              name={field?.attr_isn}
              validateTrigger="onChange"
              initialValue={
                contractData?.data?.addattr_insurer?.filter(
                  (item) => Number(item.attr_isn) === Number(field.attr_isn)
                )[0]?.value
              }
              rules={[
                {
                  required:
                    typeof field?.required === 'boolean'
                      ? field?.required
                      : false,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              {findItem?.length > 0 ? (
                <QuotationTreeSelect
                  placeholder={field?.attr_name}
                  disabled={
                    field.readonly
                      ? true
                      : Number(productIsn) !== 221711 &&
                        Number(productIsn) !== 471391
                  }
                  size="large"
                  onChange={(e) => {
                    onChange(field?.attr_isn, e, 'DICTI_CODENAME');
                  }}
                  list={dicti[field?.attr_isn]?.children}
                />
              ) : (
                <CSelect
                  showSearch
                  placeholder={field?.attr_name}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="large"
                  onChange={(e) => {
                    onChange(field?.attr_isn, e, 'DICTI_CODENAME');
                  }}
                  disabled={
                    field.readonly
                      ? true
                      : Number(productIsn) !== 221711 &&
                        Number(productIsn) !== 471391
                  }
                >
                  {dicti &&
                  dicti[field.attr_isn] &&
                  dicti[field.attr_isn].children
                    ? dicti[field.attr_isn].children.map((el) => {
                        return (
                          <Option
                            value={el.foreign_id ? el.foreign_id : el.isn}
                            key={el.foreign_id ? el.foreign_id : el.isn}
                          >
                            {el.full_name}
                          </Option>
                        );
                      })
                    : dicti &&
                      dicti[field.attr_isn] &&
                      dicti[field.attr_isn].map((el) => {
                        return (
                          <Option
                            value={el.foreign_id ? el.foreign_id : el.isn}
                            key={el.foreign_id ? el.foreign_id : el.isn}
                          >
                            {el.full_name}
                          </Option>
                        );
                      })}
                </CSelect>
              )}
            </Form.Item>
          </Col>
        );
      case 'DOCS':
      case 'FLOAT':
      case 'NUMBER':
      case 'INTEGER':
        return (
          <Col span="8" key={index}>
            <Form.Item
              label={field?.attr_name}
              name={[`id-${field?.attr_isn}`, 'value']}
              initialValue={
                contractData?.data?.addattr_insurer?.filter(
                  (item) => Number(item.attr_isn) === Number(field.attr_isn)
                )[0]?.value
              }
            >
              <CInput
                disabled={
                  field.readonly
                    ? true
                    : Number(productIsn) !== 221711 &&
                      Number(productIsn) !== 471391
                }
                placeholder={field?.attr_name}
                value={field?.value}
                size="large"
                onChange={(e) => onChange(field.attr_isn, e, 'NUMBER')}
              />
            </Form.Item>
          </Col>
        );
      case 'CHECKBOX':
        return (
          <Col span="8" key={index}>
            <Form.Item
              name={field?.attr_name}
              valuePropName="checked"
              initialValue={
                contractData?.data?.addattr_insurer?.filter(
                  (item) => Number(item.attr_isn) === Number(field.attr_isn)
                )[0]?.value
              }
            >
              <Checkbox
                disabled={
                  field.readonly
                    ? true
                    : Number(field.attr_isn) === 1796501
                    ? checkDisabledChecbkox()
                    : true &&
                      Number(productIsn) !== 221711 &&
                      Number(productIsn) !== 471391
                }
                onChange={(e) => onChange(field.attr_isn, e, 'CHECKBOX')}
              >
                {field?.attr_name}
              </Checkbox>
            </Form.Item>
          </Col>
        );
      case 'DATETIME': {
        return (
          <Col span={8} key={index}>
            <Form.Item
              label={field.attr_name}
              name={[`id-${field?.attr_isn}`, 'value']}
              rules={[
                {
                  required:
                    typeof field.required === 'boolean'
                      ? field.required
                      : false,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CDatePicker
                showTime
                style={{ width: '100%' }}
                onChange={(e) => onChange(field.attr_isn, e, 'DATETIME')}
                placeholder={field.attr_name}
                size="large"
                disabled={
                  field.readonly
                    ? true
                    : Number(productIsn) !== 221711 &&
                      Number(productIsn) !== 471391
                }
              />
            </Form.Item>
          </Col>
        );
      }
      case 'DATE': {
        return (
          <Col span={8} key={index}>
            <Form.Item
              label={field.attr_name}
              name={[`id-${field?.attr_isn}`, 'value']}
              rules={[
                {
                  required:
                    typeof field.required === 'boolean'
                      ? field.required
                      : false,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              initialValue={
                contractData?.data?.addattr_insurer?.filter(
                  (item) => Number(item.attr_isn) === Number(field.attr_isn)
                )[0]?.value
                  ? dayjs(
                      contractData?.data?.addattr_insurer?.filter(
                        (item) =>
                          Number(item.attr_isn) === Number(field.attr_isn)
                      )[0]?.value,
                      'DD.MM.YYYY'
                    )
                  : null
              }
            >
              <CDatePicker
                style={{ width: '100%' }}
                format={'DD.MM.YYYY'}
                placeholder={field.attr_name}
                size="large"
                className="dateMask"
                onChange={(e) => onChange(field.attr_isn, e, 'DATE')}
                disabled={
                  field.readonly
                    ? true
                    : Number(productIsn) !== 221711 &&
                      Number(productIsn) !== 471391
                }
              />
            </Form.Item>
          </Col>
        );
      }

      case 'SUBJECT':
      case 'SUBJECT_SHORT': {
        return (
          <SubjectCard
            key={index}
            elem={{
              column_name: field.attr_isn,
              full_name: `fullname-${field.attr_isn}`,
              label: field.attr_name,
            }}
            col={8}
            mainForm={additionalAttributesForm}
            size="large"
            onChange={onChange}
            initialValue={
              contractData?.data?.addattr_insurer?.filter(
                (item) => Number(item.attr_isn) === Number(field.attr_isn)
              )[0]?.val_n
            }
          />
        );
      }
      default:
        return null;
    }
  }

  const RenderFields = ({ fieldsList }) => {
    return fieldsList?.map((field, index) => {
      if (Number(productIsn) === 221711 || Number(productIsn) === 471391) {
        return renderFieldByType(field, index);
      } else {
        switch (field?.attr_type) {
          case 'SQL':
          case 'TEXT':
            if (
              fields.filter(
                (item) => Number(item.attr_isn) === Number(field.attr_isn)
              )[0]?.val_c
            ) {
              return renderFieldByType(field, index);
            } else {
              break;
            }
          case 'DOCS':
          case 'DEPTEMPL':
          case 'DICTI_CODENAME':
          case 'DICTI':
          case 'NUMBER':
          case 'INTEGER':
            if ([2584231, 5748101].includes(Number(field.attr_isn))) {
              return renderFieldByType(field, index);
            } else if (
              fields.filter(
                (item) => Number(item.attr_isn) === Number(field.attr_isn)
              )[0]?.value
            ) {
              return renderFieldByType(field, index);
            } else {
              break;
            }
          case 'CHECKBOX':
            return renderFieldByType(field, index);
          default:
            return null;
        }
      }
    });
  };

  return (
    <Form className="c-form" layout="vertical" form={additionalAttributesForm}>
      {!isLoadingColleaguesKiasList && (
        <CTabs
          className="mt-5 quotation-tabs"
          type="card"
          items={[
            {
              label: <span>Раздел страховщика</span>,
              key: '1',
              children: (
                <Card>
                  <Row gutter={18}>
                    <RenderFields
                      fieldsList={
                        quotationData?.data[0].attributes[0]
                          ? quotationData?.data[0].attributes[0]
                          : fields
                      }
                    />
                  </Row>
                </Card>
              ),
            },
            {
              label: <span>Раздел андеррайтера</span>,
              key: '2',
              children: (
                <Card>
                  <RenderFields fieldsList={underData} />
                </Card>
              ),
            },
          ]}
        />
      )}
    </Form>
  );
};

export default MainContractAdditionalAttributes;
