import { IDictiItem } from '@modules/Quotation/Interfaces/SearchPerson/Kias.interface';
import api from '@modules/Quotation/services';
import catchErrorCustomAxios from '@services/api/catchErrors';
import { useCallback, useState } from 'react';

const useCargo = () => {
  const [packageCargo, setPackageCargo] = useState<IDictiItem[]>([]);
  const [measureVolume, setMeasureVolume] = useState<IDictiItem[]>([]);
  const [transport, setTransport] = useState<IDictiItem[]>([]);
  const [vidTransportFullList, setVidTransportFullList] = useState<
    IDictiItem[]
  >([]);

  const fetchCargoDicti = useCallback(async () => {
    try {
      const response = await api.cargo.getCargo();
      setVidTransportFullList(response.data.data.children);

      const { data } = await api.cargo.getCargoDicties();
      setTransport(
        data?.data?.children?.filter(
          (element) => Number(element.foreign_id) === 2040
        )
      );
      setMeasureVolume(
        data?.data?.children?.filter(
          (element) => Number(element.foreign_id) === 2041
        )
      );
      setPackageCargo(
        data?.data?.children?.filter(
          (element) => Number(element.foreign_id) === 2042
        )
      );
    } catch (error) {
      let message;
      catchErrorCustomAxios(error, message);
    }
  }, []);

  return {
    packageCargo,
    measureVolume,
    transport,
    vidTransportFullList,
    fetchCargoDicti,
  };
};

export default useCargo;
