import './NotificationsModal.scss'
import { Typography } from 'antd';

const { Text } = Typography;

const ItemModal = ({ message, comments, status, dateTime }) => {
  return (
    <div className="item">
      <Text className="datetime">{dateTime}</Text>
      <Text className={`message ${status}`}>{message}</Text>
      <Text className="comments">{comments}</Text>
    </div>
  )
};

export default ItemModal;