import React, { useEffect, useState } from 'react';
import { Collapse, notification, Popconfirm, Row, Button, Space } from 'antd';
import { Typography } from 'antd';

import EventItemHeading from '../EventItemHeading';
import EventItemContent from '../EventItemContent';

import './EventsList.scss';
import { DeleteOutlined } from '@ant-design/icons';
import useEvent from '../../../hooks/useEvent';
import { IBooking } from '../../../pages/Talk/Talk';
import useAuth from '../../../hooks/useAuth';
import useCounter from '@hooks/useCounter';
import api from '../services/api';

const { Panel } = Collapse;
const { Title } = Typography;

const EventsList = () => {
  const [filteredBooking, setFilteredBooking] = useState<any[]>([]);

  const { getUserBookingMethod, totalList } = useEvent();

  const { getCountEvent } = useCounter();

  const auth = useAuth();

  const handleRevokeEvent = async (bookingId) => {
    try {
      const response = await api.delUserBooking(bookingId, { status: 0 });
      if (
        response.status === 200 &&
        response.statusText.toLowerCase() === 'ok'
      ) {
        getUserBookingMethod();
        getCountEvent();
      } else {
        throw new Error('Попробуйте еще раз');
      }
    } catch (error: any) {
      notification.info({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
    }
  };

  const handleRemoveEvent = async (bookingId) => {
    try {
      const response = await api.deleteBooking(bookingId);
      if (
        response.status === 200 &&
        response.statusText.toLowerCase() === 'ok'
      ) {
        getUserBookingMethod();
        getCountEvent();
      } else {
        throw new Error('Попробуйте еще раз');
      }
    } catch (error: any) {
      notification.info({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
    }
  };
  useEffect(() => {
    setFilteredBooking(
      totalList.map((element) =>
        element.booking[0].booking_user.filter(
          (item) =>
            Number(item.user_id) === auth.user?.data?.id &&
            Number(item.status) === 2
        )
      )
    );
  }, [totalList]);

  return (
    <div className="events-list">
      <Title className="mb-5" level={3} type="secondary">
        Мои встречи
      </Title>
      <Collapse accordion expandIconPosition="end">
        {filteredBooking?.map((element: IBooking) => {
          if (element[0]?.booking_id) {
            return (
              <React.Fragment key={element[0].booking_id}>
                {totalList.map((item, index) => {
                  if (item.booking_id === element[0]?.booking_id) {
                    return (
                      <React.Fragment key={Math.random()}>
                        <Panel
                          header={
                            <EventItemHeading
                              company={
                                item.booking[0].building_name
                              }
                              title={item.booking[0].title}
                              totalList={totalList}
                            />
                          }
                          key={Math.random()}
                          extra={
                            <Row align="middle">
                              <Space wrap>
                                {item.is_author ? (
                                  <Popconfirm
                                    title="Хотите отменить встречу?"
                                    onCancel={(event) => {
                                      event?.stopPropagation();
                                    }}
                                    onConfirm={(event) => {
                                      event?.stopPropagation();
                                      handleRemoveEvent(element[0].booking_id);
                                    }}
                                    okText="Да"
                                    cancelText="Нет"
                                  >
                                    <Button
                                      type="primary"
                                      danger
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      Отменить встречу
                                    </Button>
                                  </Popconfirm>
                                ) : (
                                  ''
                                )}
                                <Popconfirm
                                  title="Хотите отказаться от встречи?"
                                  onCancel={(event) => {
                                    event?.stopPropagation();
                                  }}
                                  onConfirm={(event) => {
                                    event?.stopPropagation();
                                    handleRevokeEvent(element[0].booking_id);
                                  }}
                                  okText="Да"
                                  cancelText="Нет"
                                >
                                  <Button
                                    type="primary"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                  >
                                    Не смогу придти
                                  </Button>
                                  {/* <DeleteOutlined
                                  style={{ float: 'right' }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                /> */}
                                </Popconfirm>
                              </Space>
                            </Row>
                          }
                        >
                          <EventItemContent
                            {...item}
                            deadline={item.booking[0].begin}
                            range={
                              Date.now() +
                              (new Date(item.booking[0].end).getTime() -
                                new Date(item.booking[0].begin).getTime()) -
                              Date.now()
                            }
                          />
                        </Panel>
                      </React.Fragment>
                    );
                  }
                })}
              </React.Fragment>
            );
          }
        })}
      </Collapse>
    </div>
  );
};

export default EventsList;
