import api from "../services/api";

const getApplicationById = async (id: number) => {
  const { data } = await api.getApplicationById(id);
  return data?.data;
};

export const setApplicationById = async (
  id: number,
  setFetchApplicationById
) => {
  getApplicationById(id).then((values) => {
    if (values) {
      setFetchApplicationById(values);
    }
  });
};
