export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const ADD_INVOICE_AMOUNT_ISN = 'ADD_INVOICE_AMOUNT_ISN';

export function updateContract(data: number) {
  return {
    type: UPDATE_CONTRACT as typeof UPDATE_CONTRACT,
    payload: data,
  };
}

export function addInvoiceAmount(data: any) {
  return {
    type: ADD_INVOICE_AMOUNT_ISN as typeof ADD_INVOICE_AMOUNT_ISN,
    payload: data,
  };
}