import { Avatar, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { IUser } from './type';

const { Title, Text } = Typography;

type BdayItemProps = IUser;

function BdayItem({ id, avatar2, full_name, department }: BdayItemProps) {
  return (
    <div className="bday__item" role="bday_item">
      <Link to={`/colleagues/${id}`} className="bday__link" role="bday_link">
        <Avatar src={`https://mycent.kz/${avatar2?.link}`} size={50} />
        <div className="bday__content" role='bday_content'>
          <Title level={5} className="mb-1" role="title">
            {full_name}
          </Title>
          <Text style={{ color: '#545454' }} role='full_name'>{department?.full_name}</Text>
        </div>
      </Link>
    </div>
  );
}

export default BdayItem;
