import axios from '@services/api/axios';

const api = {
	getHierarchyUseful: (id) => axios.get(`online-library/category-hierarchy/${id}`),
	getCategoriesUseful: () => axios.get('online-library/categories'),
	getCategoryUseful: (id) => axios.get(`online-library/categories/${id}`),
	getSubCategoriesUseful: (id) => axios.get(`online-library/get-file-list-by-category-id/${id}`),
	deleteCategoryUseful: (id, userId) => axios.delete(`online-library/categories/${id}?deleted_by=${userId}`),
	deleteDocumentUseful: (uuid, useId) => axios.delete(`online-library/file/delete/${uuid}?deleted_by=${useId}`),
	editCategoryUseful: (id, data) => axios.put(`online-library/categories/${id}`, data),
	searchDocumentsUseful: (value) => axios.get(`online-library/search?query=${value}&root_category_id=783`),
	addCategoryUseful: (requestData) => axios.post(`online-library/categories`, requestData),
	readDocumentUseful: (uuid, userId) => axios.get(`online-library/get-raw-file/${uuid}?user_id=${userId}`),
	downloadDocumentUseful: (uuid) => axios.get(`online-library/download/${uuid}`)
};

export default api;
