import {
  Card,
  Form,
  Col,
  Row,
  Space,
  Button,
  Typography,
  notification,
} from 'antd';
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import CButton from '@components/UI/button/CButton';
import React, { useEffect } from 'react';
import CInput from '@components/UI/input/CInput';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import api from '@modules/Quotation/services';
import { TypeAction } from '@modules/Quotation/providers/DateReducer';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';

function InsurerQuotation({
  agrIsn,
  userForeignId,
  searchContractor,
  contractorInfo,
  handleRemoveContractorInfo,
  AdvancedContractorSearch,
  selectContractor,
  productIsn,
  old_agr_isn,
}) {
  const { Title } = Typography;
  const { stateDateQuotation, setAgrContractor, agrContractor, dispatchDate } =
    useQuotation();
  const onChange = (event) => {
    if (event.target.value.length === 12) {
      setTimeout(async () => {
        const contractorData = await searchContractor();
        if (!contractorData) return;
        // При смене Страхователя отправляем повторно данные формуляра чтобы сохранить в котировке

        setAgrContractor({
          agr_isn: agrContractor.id,
          bonus_malus_isn: null,
          class_isn: '2103',
          isn: null,
          order_no: null,
          remark: null,
          user_isn: agrContractor.user_isn,
          client_birthday: contractorData.BIRTHDAY,
          client_id: contractorData.ISN,
          client_name: contractorData.FULLNAME,
          client_iin: contractorData.IIN,
        });

        const requestBody = {
          connection: 'centras',
          agr_isn: agrIsn,
          user_isn: userForeignId,
          client_id: String(contractorData?.ISN),
          date_sign: stateDateQuotation.date_sign,
          date_beg: stateDateQuotation.date_beg,
          date_end: stateDateQuotation.date_end,
          status_isn: stateDateQuotation.status_isn,
          remark: stateDateQuotation.remark,
        };

        const { data } = await api.quotation.saveCounterpartyInsurant(
          requestBody
        );
        if (data.success === true) {
          /*
          notification.success({
            message: 'Сообщение',
            description: data.message,
          });
          */
          // Прокидываем в stateDateQuotation
          dispatchDate({
            type: TypeAction.USER_ISN,
            payload: userForeignId,
          });
          dispatchDate({
            type: TypeAction.CLIENT_ID,
            payload: contractorData?.ISN,
          });
        } else {
          notification.info({
            message: 'Ошибка',
            description: data.error,
          });
        }
      }, 300);
    }
  };

  return (
    <Card>
      <Title className="main-quotation__section-title" level={3}>
        Страхователь
      </Title>
      <Col>
        {contractorInfo ? (
          <Row gutter={18} align={'middle'} justify={'space-between'}>
            <Col span={6}>{contractorInfo[0]?.FULLNAME}</Col>
            <Col span={6} className="text-center">
              {contractorInfo[0]?.IIN}
            </Col>
            <Col span={6} className="text-center">
              {contractorInfo[0]?.BIRTHDAY}
            </Col>
            {!old_agr_isn && (
              <Col
                span={6}
                className="d-flex justify-space-between align-center"
              >
                <CButton
                  type="primary"
                  className="c-button_small"
                  onClick={() => {
                    selectContractor(contractorInfo[0]);
                  }}
                  disabled={checkDisabledField(stateDateQuotation.status_isn)}
                >
                  <EditOutlined /> Изменить
                </CButton>
                <Button
                  type="primary"
                  danger
                  onClick={handleRemoveContractorInfo}
                  disabled={checkDisabledField(stateDateQuotation.status_isn)}
                >
                  <DeleteOutlined /> Удалить
                </Button>
              </Col>
            )}
          </Row>
        ) : (
          <Form.Item
            className="mb-0 mt-5"
            rules={[
              {
                required: true,
                message: 'Введите ИИН/БИН!',
              },
              {
                len: 12,
                message: 'Введите корректный ИИН/БИН!',
              },
            ]}
            name="iin"
          >
            <Row gutter={[18, 10]}>
              <Col xs={24} md={16} xl={17} xxl={19}>
                <CInput
                  type="number"
                  controls={false}
                  onChange={onChange}
                  placeholder="ИИН / БИН"
                  maxLength={12}
                  minLength={12}
                  size="large"
                />
              </Col>
              <Col span={8} xs={24} md={8} xl={7} xxl={5}>
                <CButton
                  type="primary"
                  className="c-button_left"
                  block
                  onClick={AdvancedContractorSearch}
                >
                  <SearchOutlined />
                  Расширенный поиск
                </CButton>
              </Col>
            </Row>
          </Form.Item>
        )}
      </Col>
    </Card>
  );
}
export default InsurerQuotation;
