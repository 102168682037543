/* eslint-disable @typescript-eslint/no-shadow */
import { TreeSelect } from 'antd';
import { Colleagues, ColleaguesListType } from '../../hooks/useColleagues';

const { TreeNode } = TreeSelect;

interface Props {
  colleaguesList: ColleaguesListType;
  disabled?: boolean;
  readonly?: boolean;
  selectDepartment?: boolean;
  value?: number;
  onChange?: (value: number | undefined) => void;
  onSelectUser?: (user) => void;
  placeHolder?: string;
  multiply?: boolean;
  large?: boolean
}

/**
 * TODO: Использовать во всех компонентах
 * @param colleaguesList список департаментов и сотрудников для TreeSelect
 * @param disabled атрибут для TreeSelect
 * @param selectDepartment если true - можно выбрать департамент, иначе сотрудника
 * @param value необязательное начальное значение из родителя Form.Item
 * @param onChange колбек для получения значения из дочернего компонента для родителя Form.Item
 */

function renderTreeNodes(item: Colleagues, selectDepartment: boolean) {
  const valueTreeNode = selectDepartment
    ? item.id
    : item.id + '-' + item.full_name;

  return (
    <TreeNode
      key={valueTreeNode}
      value={valueTreeNode}
      title={item.full_name}
      selectable={selectDepartment}
      checkable={false}
    >
      {item?.users?.map((user) => {
        const valueTreeNode = !selectDepartment
          ? user.id
          : user.id + '-' + user.full_name;

        return (
          <TreeNode
            value={valueTreeNode}
            key={valueTreeNode}
            title={user.full_name}
            user={user}
            selectable={!selectDepartment}
          />
        );
      })}
      {item?.child?.map((item) => renderTreeNodes(item, selectDepartment))}
    </TreeNode>
  );
}
function TreeSelectCollegues(props: Props) {
  const {
    disabled = false,
    readonly = false,
    colleaguesList = [],
    selectDepartment = false,
    onChange,
    value,
    onSelectUser = () => {},
    placeHolder,
    multiply,
    large,
  } = props;

  const handleChange = (value) => {
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  const handleSelect = (_, node) => {
    onSelectUser(node?.user);
  };
  return (
    <TreeSelect
      onChange={handleChange}
      onSelect={handleSelect}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeLine={{ showLeafIcon: false }}
      treeNodeFilterProp="title"
      allowClear={true}
      showSearch={true}
      disabled={disabled || readonly}
      value={value}
      placeholder={placeHolder}
      treeCheckable={multiply}
      size={large ? 'large' : 'middle'}
    >
      {colleaguesList.map((item) => renderTreeNodes(item, selectDepartment))}
    </TreeSelect>
  );
}

export default TreeSelectCollegues;
