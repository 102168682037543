import React from 'react';
import { Select } from 'antd';
import './CSelect.scss';
const CSelect = (props) => {
  return (
    <Select {...props} className={`c-select ${props.className || ''}`}>
      {props.children}
    </Select>
  );
};

export default CSelect;
