import React, { useEffect, useState } from 'react';
import LibraryData from './LibraryData';
import {LibraryDataType, CategoryType, DocumentsType} from './LibraryType/LibraryType';
import { Radio } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import './Library.scss';
import orgStructImage from './image/org_struct.svg';

export default function Library() {

    // Состояние
    const listLibrary: any = LibraryData;
    const [lists, setLists] = useState<LibraryDataType>();
    const [category, setDocuments] = useState<CategoryType | null>();
    const [orgStruct, setOrgStruct] = useState<string | null>();

    /**
     * получаем список категорий
     */
    const onSetListProducts: any = (type: string) => {
        if(type === 'org_struct') {
            setOrgStruct(type);
            return;
        };
        setLists(listLibrary[type]);
        setDocuments(listLibrary[type].category[0]);
        setOrgStruct(null);
    };

    /**
     * получаем документы/файлы
     */
    const onViewDocuments: any = (category: CategoryType) => {
        setDocuments(category);
    };

    /**
     * Список главных категорий
     */
    const mainCategory = Object.keys(listLibrary).map((type) => {
        return (
            <li className="main__item" onClick={() => onSetListProducts(type)}>
                {/* название главных категорий (Внутренние нормативные документы, Бизнес процессы, ...) */}
                { listLibrary[type].name }
            </li>
        )
    });

    /**
     * список категорий
     */
    const CategoryLists = lists?.category.map((item: CategoryType) => {
        return (
            <Radio.Button
                key={item.id}
                onClick={() => onViewDocuments(item)}
                className="list-button"
            >
                {item.name}
            </Radio.Button>
        )
    });

    /**
     * Проверка на тип документа
     */
    const TypeDocuments = (document: DocumentsType) => {
        const { path, name } = document;
        const extension = document.path.split('.').pop();
        const commonProps = {
            className: 'document-item__link',
            href: path,
            target: '_blank',
        }
        switch(extension) {
            case 'pdf':
            case 'mp4':
            case 'png':
                return <a {...commonProps}>{name}</a>;
            default:
                return <a {...commonProps}download>{name}</a>;
        }
    }

    /**
     * список документов
     */
    const documentLists = category?.documents.map((item: DocumentsType, index: number) => {
        if(!item.name) return;
        const document = TypeDocuments(item);
        return (
            <div
                key={index}
                className="document-item"
            >
                <FileOutlined />
                {
                    document
                }
            </div>
        )
    });

    useEffect(() => {
        onSetListProducts('org_struct');
    }, [listLibrary]);
    
    return (
        <main className="main">
            <header className="main__header">
                <nav className="nav">
                    <ul className="main__menu">      
                        <li className="main__item" onClick={() => onSetListProducts('org_struct')}>
                            Орг. структура
                        </li>
                        {
                            mainCategory
                        }
                    </ul>
                </nav>
            </header>
            <div className="container">
                <div className="main__category">
                    {
                        orgStruct ? (
                            <div className="main__org-struct">
                                    <img src={orgStructImage} alt="Орг. структура"/>
                            </div>
                        ) : (
                            <>
                                <div className="main__list">
                                    {
                                        CategoryLists
                                    }
                                </div>
                                <div className="main__content">
                                    {
                                        documentLists
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </main>
    )
}
