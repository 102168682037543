import { Button, Col, Divider, Form, Input, notification, Row } from 'antd';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import React, { useEffect } from 'react';
import { EditTwoTone, MinusCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import api from '@modules/Edo/services/api';
import axios from 'axios';

const DynamicForm = ({
	                     item,
	                     colleaguesList,
	                     formName,
	                     approverCoordinationForm,
	                     name,
	                     type,
	                     docEdoId,
	                     classId,
	                     docClassCoordination
                     }) => {
	const addItem = (add) => {
		setTimeout(() => {
			add();
			let allData = approverCoordinationForm.getFieldsValue()[formName];
			allData.splice(allData.length - 1, 1);
			approverCoordinationForm.setFieldValue(
				[formName],
				[
					...allData,
					{
						class_id: classId,
						document_id: docEdoId,
						group: type,
						group_name: name,
						step: 1,
						queue: 1,
					},
				]
			);
		}, 50);
	};

	const sendDocClassCoordinationSample = () => {
		const sendData = item.map((val, index) => {
			return {
				class_id: classId,
				document_id: docEdoId,
				employee_id: val.employee_id,
				group: val.group,
				group_name: val.group_name,
				step: val.step,
				queue: val.queue,
				id: val.id ? val.id : null,
			};
		});

		approverCoordinationForm.setFieldValue(formName, sendData);
	};

	const sendSample = () => {
		const sendData = item.map((val, index) => {
			return {
				class_id: classId,
				document_id: docEdoId,
				employee_id: val.user_id,
				group: val.type,
				group_name: val.name,
				step: 1,
				queue: 1,
			};
		});

		approverCoordinationForm.setFieldValue(formName, sendData);
	}

	const deleteApprover = async (remove, fieldName, id) => {
		try {
			remove(fieldName);
			if (id) await api.deleteDocClassCoordination(id);
		} catch (error) {
			let message;
			if (axios.isAxiosError(error) && error.response) {
				message = error.message;
			} else {
				message = String(error);
			}
			notification.info({
				message: 'Ошибка',
				description: message,
			});
		}
	};

	const editApprover = async (data) => {
		try {
			if(data.id) {
				await api.putDocClassCoordination(data.id, data);
				notification.info({
					message: 'Информация',
					description: 'Вы успешно изменили согласующего',
				});
			}
		} catch (error) {
			let message;
			if (axios.isAxiosError(error) && error.response) {
				message = error.message;
			} else {
				message = String(error);
			}
			notification.info({
				message: 'Ошибка',
				description: message,
			});
		}
	};

	useEffect(() => {
		docClassCoordination.length ? sendDocClassCoordinationSample() : sendSample();
	}, [item]);

	return (
		<>
			<Form.List name={formName}>
				{(fields, { add, remove }, { errors }) => {
					return (
						<div>
							{fields.map((field, name, index) => (
								<Row
									key={field.key}
									style={{ width: '40%' }}
								>
									<Form.Item
										name={[field.name, 'employee_id']}
										style={{ width: '88%', marginBottom: '10px' }}
									>
										{colleaguesList ? (
											<TreeSelectCollegues colleaguesList={colleaguesList}/>
										) : (
											<Input disabled/>
										)}
									</Form.Item>
									<Form.Item
										name={[field.name, 'group_name']}
										style={{ width: '88%', marginBottom: '10px' }}
										hidden
									>
										<Input disabled/>
									</Form.Item>
									<Form.Item
										name={[field.name, 'group']}
										style={{ width: '88%', marginBottom: '10px' }}
										hidden
									>
										<Input disabled/>
									</Form.Item>
									<Form.Item
										name={[field.name, 'document_id']}
										style={{ width: '88%', marginBottom: '10px' }}
										hidden
									>
										<Input disabled/>
									</Form.Item>
									<Form.Item
										name={[field.name, 'step']}
										style={{ width: '88%', marginBottom: '10px' }}
										hidden
									>
										<Input disabled/>
									</Form.Item>
									<Form.Item
										name={[field.name, 'queue']}
										style={{ width: '88%', marginBottom: '10px' }}
										hidden
									>
										<Input disabled/>
									</Form.Item>
									<Form.Item
										name={[field.name, 'class_id']}
										style={{ width: '88%', marginBottom: '10px' }}
										hidden
									>
										<Input disabled/>
									</Form.Item>
									{docClassCoordination.length >= 0 && <Form.Item
                      name={[field.name, 'id']}
                      style={{ width: '70%', marginBottom: '10px' }}
                      hidden
                  >
                      <Input disabled/>
                  </Form.Item>}
									{docClassCoordination.length >= 1 && <Col>
                      <EditTwoTone
                          style={{ marginTop: '10px', marginLeft: '10px' }}
                          onClick={() => editApprover(approverCoordinationForm.getFieldsValue()[formName][name])}
                      />
                  </Col>}
									{name !== 0 && <Col>
                      <MinusCircleTwoTone
                          style={{ marginTop: '10px', marginLeft: '10px' }}
                          onClick={() =>
														docClassCoordination.length
															? deleteApprover(remove, field.name, approverCoordinationForm.getFieldsValue()[formName][name].id)
															: remove(field.name)
													}
                          twoToneColor="#eb2f96"
                      />
                  </Col>}
								</Row>
							))}
							{/*<Form.Item>*/}
							{/*	<Button*/}
							{/*		type="link"*/}
							{/*		onClick={() => addItem(add)}*/}
							{/*		style={{ width: "10%" }}*/}
							{/*	>*/}
							{/*		<PlusOutlined/> Добавить согласующего*/}
							{/*	</Button>*/}
							{/*</Form.Item>*/}
							<Divider/>
						</div>
					);
				}}
			</Form.List>
		</>
	);
};

export default DynamicForm;