import { Navigate, Route, Routes } from 'react-router-dom';

import RecruitingPage from '@pages/Recruiting';
import { PageParse } from '@pages/Parse';
import Colleague from '@pages/Colleague/Colleague';
import Colleagues from '@pages/Colleague/Colleagues';
import EdsPage from '@pages/Eds';
import OnProcess from '@pages/OnProcess/OnProcess';
import Talk from '@pages/Talk/Talk';
import AboutMe from '@pages/AboutMe';
import AgreementPage from '@pages/Agreement';
import EdoPage from '@pages/Edo';
import Index from '@pages/Index';
import Login from '@pages/Login';
import NotFound404 from '@pages/NotFound404';
import Registration from '@pages/Registration';
import DocumentPage from '@pages/Edo/EdoDocument';
import Quotation from '@pages/Quotation/Quotation';
import ExpressQuotation from '@pages/Quotation/ExpressQuotation';
import Library from '@pages/Library/Library';
import ReadDocs from '@pages/ReadDocs/ReadDocs';

import DefaultLayout from '@layouts/Default';
import PageLayoutBitrix from '@layouts/PageLayoutBitrix';

import CreateQuotation from '@modules/Quotation/CreateQuotation';
import ExpressProduct from '@modules/Quotation/ExpressQuotation/ExpressProduct';
import MainQuotation from '@modules/Quotation/MainQuotation';
import BitrixQuotation from '@modules/Quotation/MainQuotation/BitrixQuotation';
import Settings from '@modules/Settings/index';

import PrivateRoute from '@modules/PrivateRoute';
import ProtectedRoute from '@modules/ProtectedRoute';
import GuestRoute from '@modules/GuestRoute';
import ApplicationRecruiting from '@modules/Recruiting/Application';
import CandidateRecruiting from '@modules/Recruiting/Candidate';
import InterviewRecruiting from '@modules/Recruiting/Interview';
import NewApplicationRecruiting from '@modules/Recruiting/NewApplication';
import ResultRecruiting from '@modules/Recruiting/Result';
import QuotationClassObjectProvider from '@modules/Quotation/providers/QuotationClassObjectProvider';
import QuotationProvider from '@modules/Quotation/providers/QuotationProvider';
import { PUBLIC_API_SPEAKER } from '@modules/Speaker/index';
import ISpring from '@pages/ISpring';
import MainContract from '@modules/Quotation/MainContract';
import OnlineLibrary from '@modules/OnlineLibrary';
import LibraryProvider from '@modules/OnlineLibrary/LibraryProvider/LibraryProvider';
import ReaderFileLibrary from '@modules/OnlineLibrary/ReaderFileLibrary/ReaderFileLibrary';
import UsefulProvider from '@modules/UsefulSection/UsefulProvider/UsefulProvider';
import UsefulSection from '@modules/UsefulSection';
import FileExchange from '@modules/FileExchange';
import OuterClient from '@modules/FileExchange/OuterClient/OuterClient';
import AdminPanel from "@modules/FileExchange/AdminPanel";
import Audit from "@modules/FileExchange/Audit";

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <DefaultLayout/>
          </PrivateRoute>
        }
      >
        <Route index element={<Index title="Новости"/>}/>
        <Route path="news" element={<Index title="Новости"/>}/>
        <Route path="colleagues" element={<Colleagues title="Коллеги"/>}>
          <Route path=":userId" element={<Colleague/>}/>
        </Route>
        <Route
          path="agreement"
          element={<AgreementPage title="Согласование"/>}
        />
        <Route
          path="agreement/:status"
          element={<AgreementPage title="Согласование"/>}
        />
        <Route path="contract/:contractIsn" element={<MainContract/>}/>
        <Route
          path="studycenter"
          element={<OnProcess title="Центр знаний"/>}
        />
        <Route path="talks" element={<Talk title="На переговоры"/>}/>
        <Route path="about-me" element={<AboutMe title="Обо мне"/>}/>
        <Route
          path="express-quotation"
          element={<ExpressQuotation title="Экспресс Котировка"/>}
        ></Route>
        <Route
          path="express-quotation/:quotationId"
          element={<ExpressProduct/>}
        />
        <Route
          path="quotation/:quotationId"
          element={
            <QuotationProvider>
              <QuotationClassObjectProvider>
                <MainQuotation/>
              </QuotationClassObjectProvider>
            </QuotationProvider>
          }
        />
        <Route path="quotation" element={<Quotation title="Котировки"/>}>
          <Route path=":journalQuotationId" element={<MainQuotation/>}/>
        </Route>

        <Route
          path="quotation/product"
          element={
            <QuotationProvider>
              <QuotationClassObjectProvider>
                <CreateQuotation/>
              </QuotationClassObjectProvider>
            </QuotationProvider>
          }
        >
          <Route path=":productIsn" element={<MainQuotation/>}/>
        </Route>
        <Route path="document" element={<EdoPage title="ЭДО"/>}>
          <Route path=":documentID" element={<DocumentPage/>}/>
        </Route>

        <Route path="recruiting" element={<RecruitingPage/>}>
          <Route index element={<InterviewRecruiting title="Интервью"/>}/>
          <Route
            path="new-application"
            element={<NewApplicationRecruiting title="Новая заявка"/>}
          />
          <Route
            path="result"
            element={<ResultRecruiting title="Результат"/>}
          />
          <Route
            path="candidate/:id"
            element={<CandidateRecruiting title="Кандидат"/>}
          />
          <Route
            path="application/:id"
            element={<ApplicationRecruiting title="Заявка"/>}
          />
        </Route>

        <Route path="/speaker" element={<PUBLIC_API_SPEAKER.Speaker/>}/>
        <Route path="/settings" element={<Settings/>}/>
        <Route path="/game" element={<iframe src="/game.html" style={{width: '100%', height: '100vh'}}></iframe>}/>
      </Route>

      <Route
        path="/parse"
        element={
          <ProtectedRoute>
            <DefaultLayout/>
          </ProtectedRoute>
        }
      >
        <Route index element={<PageParse title="Parse"/>}/>
      </Route>

      <Route
        path="document/coordination/:id"
        element={<EdsPage title="Проверка ЭЦП подписи"/>}
      ></Route>

      <Route
        path="/quotation/product/bitrix"
        element={
          <GuestRoute>
            <PageLayoutBitrix/>
          </GuestRoute>
        }
      >
        <Route
          path="/quotation/product/bitrix"
          element={
            <QuotationProvider>
              <QuotationClassObjectProvider>
                <BitrixQuotation/>
              </QuotationClassObjectProvider>
            </QuotationProvider>
          }
        />
      </Route>

      <Route path="/library" element={<Library/>}/>
      <Route
        path="/onlineLibrary"
        element={
          <PrivateRoute>
            <LibraryProvider>
              <OnlineLibrary/>
            </LibraryProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/useful"
        element={
          <PrivateRoute>
            <UsefulProvider>
              <UsefulSection/>
            </UsefulProvider>
          </PrivateRoute>
        }
      />
      <Route path="/read-docs" element={<ReadDocs/>}/>
      <Route path="/read-file-library" element={<ReaderFileLibrary/>}/>
      <Route
        path="/ispring"
        element={
          <PrivateRoute>
            <ISpring/>
          </PrivateRoute>
        }
      />

        <Route
            path="file-exchange"
            element={
                <PrivateRoute>
                    <FileExchange/>
                </PrivateRoute>
            }
        />
        <Route
            path="file-exchange/admin-panel"
            element={
                <PrivateRoute>
                    <AdminPanel/>
                </PrivateRoute>
            }
        />
        <Route
            path="file-exchange/audit"
            element={
                <PrivateRoute>
                    <Audit/>
                </PrivateRoute>
            }
        />
        <Route path="/file-exchange/:uuid" element={<OuterClient/>}/>

      <Route
        path="/login"
        element={
          <GuestRoute>
            <Login title="Авторизация"/>
          </GuestRoute>
        }
      />

      <Route
        path="/auth/:token/:email/:reset"
        element={
          <GuestRoute>
            <Registration title="Регистрация"/>
          </GuestRoute>
        }
      />
      <Route path="*" element={<Navigate to="/not-found"/>}/>
      <Route path="not-found" element={<NotFound404/>}/>
    </Routes>
  );
};

export default AppRouter;
