import {
  CheckSquareOutlined,
  EyeOutlined,
  FolderOutlined,
  AuditOutlined,
  TeamOutlined,
  UserOutlined,
  UploadOutlined,
  NotificationOutlined,
  BulbOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import { Badge, Button, Form, Menu, Modal, notification, Upload } from 'antd';
import type { MenuProps } from 'antd/es/menu';
import { Link, useLocation } from 'react-router-dom';

import useAuth from '@hooks/useAuth';
import useCounter from '@hooks/useCounter';

import './MainMenu.scss';
import React, { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import MySelect from '@modules/common/MainMenu/MySelect';
import api from '@modules/Recruiting/services/api';
import axios from 'axios';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

interface IPermission {
  document_designer_admin?: number;
  recruiting?: boolean;
  is_speaker?: boolean;
}

interface Props {
  onSelect?: () => void;
}

export default function MainMenu(props: Props) {
  const { onSelect = () => {} } = props;
  const location = useLocation();
  const auth = useAuth();

  const isRecruiter = auth.user?.data?.permission?.recruiting;

  const { countAgreement, countEvent } = useCounter();

  const checkRecruiting = (isRecruiter: boolean, action) =>
    isRecruiter ? action : null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openFeedbackModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [ideaTheme, setIdeaTheme] = useState({
    value: '0',
    label: '',
    custom: '',
  });

  const requiredFieldText = 'Обязательное поле для заполнения';

  const handleThemeChange = (newTheme) => {
    setIdeaTheme(newTheme);
  };

  const checkPrice = (_: any, value: any) => {
    if (
      value &&
      value.value > 0 &&
      (value.value !== '5' ||
        (value.value === '5' && value?.custom?.length > 0))
    ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(requiredFieldText));
  };

  const [modalForm] = Form.useForm();

  const handleFormSubmit = async () => {
    modalForm
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();

        const { file, remark } = values;

        if (file && file.fileList.length) {
          formData.append('file', file.fileList[0]?.originFileObj);
        }

        formData.append(
          'topic',
          ideaTheme.value === '5'
            ? `${ideaTheme.label}: ${ideaTheme.custom}`
            : ideaTheme.label
        );

        formData.append('remark', remark);

        try {
          const { data } = await api.postData(formData);

          if (data?.success === true) {
            notification.info({
              message: 'Информация',
              description: data?.message,
            });
            handleCancel();
          }
        } catch (error) {
          let message;

          if (axios.isAxiosError(error)) {
            message = error.message;
          } else {
            message = String(error);
          }

          notification.info({
            message: 'Ошибка',
            description: message,
          });
        }
      })
      .catch(() => {});
  };

  const items: MenuItem[] = [
    getItem(
      <Link to="/about-me">Обо мне</Link>,
      'about-me',
      <UserOutlined style={{ fontSize: '16px' }} />
    ),
    getItem(
      <Link to="/">Новости</Link>,
      'news',
      <NotificationOutlined style={{ fontSize: '16px' }} />
    ),
    getItem(
      <Link to="/colleagues">Коллеги</Link>,
      'colleagues',
      <TeamOutlined style={{ fontSize: '16px' }} />
    ),
    getItem(
      <Link to="/document">ЭДО</Link>,
      'document',
      <FolderOutlined style={{ fontSize: '16px' }} />
    ),
    getItem(
      <Link to="/agreement">
        Согласование{' '}
        <Badge
          className="site-badge-count-109"
          count={countAgreement ? countAgreement : ''}
          style={{ backgroundColor: '#7449EF' }}
        />
      </Link>,
      'agreement',
      <CheckSquareOutlined style={{ fontSize: '16px' }} />
    ),
    getItem(
      <Link to="/talks">
        На переговоры{' '}
        <Badge
          className="site-badge-count-109"
          count={countEvent ? countEvent : ''}
          style={{ backgroundColor: '#7449EF' }}
        />
      </Link>,
      'talks',
      <EyeOutlined style={{ fontSize: '16px' }} />
    ),
    getItem(
      'Котировки',
      'quotations',
      <CheckSquareOutlined style={{ fontSize: '16px' }} />,
      [
        getItem(
          <Link to="/quotation" role="quotation">
            Котировки
          </Link>,
          'quotation'
        ),
        getItem(
          <Link to="/express-quotation" role="express-quotation">
            Экспресс Котировка
          </Link>,
          'express-quotation'
        ),
      ]
    ),

    getItem('HR', 'hr', <AuditOutlined style={{ fontSize: '16px' }} />, [
      checkRecruiting(
        isRecruiter,
        getItem(
          <Link to="/recruiting" role="recruiting" id="recruitung">
            Подбор персонала
          </Link>,
          'recruiting'
        )
      ),
      getItem(
        <Link to="/speaker" role="speaker">
          Спикеры
        </Link>,
        'speaker'
      ),
    ]),
    getItem(
      <Link to="/game">Игры</Link>,
      'game',
      <RocketOutlined style={{ fontSize: '16px' }} />
    ),
    getItem(
      <span onClick={openFeedbackModal} role="ideas">
        Идеи и предложения
      </span>,
      'ideas-and-suggestions',
      <BulbOutlined style={{ fontSize: '16px' }} />
    ),
  ];

  return (
    <>
      <Menu
        className="main-menu"
        items={items}
        theme="light"
        mode="inline"
        onSelect={onSelect}
        selectedKeys={[location.pathname.split('/').slice(1, 2).join('')]}
      />
      <Modal
        title="Идеи и предложения"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" onClick={handleFormSubmit}>
            Отправить
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Отмена
          </Button>,
        ]}
      >
        <Form
          form={modalForm}
          layout="vertical"
          validateMessages={{ required: requiredFieldText }}
        >
          <Form.Item
            label="Раздел идеи"
            name="topic"
            valuePropName="label"
            rules={[{ required: true, validator: checkPrice }]}
          >
            <MySelect onChange={handleThemeChange} />
          </Form.Item>
          <Form.Item
            name="remark"
            label="Примечание"
            rules={[{ required: true }]}
          >
            <TextArea rows={6} allowClear />
          </Form.Item>
          <Form.Item name="file" valuePropName="file">
            <Upload beforeUpload={() => false} maxCount={1}>
              <Button block icon={<UploadOutlined />}>
                Загрузить файл
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
