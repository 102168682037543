import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Select,
  TimePicker,
} from 'antd';
import CSelect from '@components/UI/select/CSelect';
import React, { useEffect, useState } from 'react';
import api from '@modules/Quotation/services/index';
import axios from 'axios';
import { DeleteFilled } from '@ant-design/icons';

const { Option } = Select;

const RenderInputsForEdit = ({ fieldsList, editForm }) => {
  const [dicti, setDicti] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getContractorList = async (event) => {
    try {
      if (event.target.value.length === 12) {
        setIsLoading(true);
        const response = await api.contractor.getContractorByIIN({
          iin: event.target.value,
        });
        editForm.setFieldValue('VALN1_VALUE', response.data.data[0].FULLNAME);
        editForm.setFieldValue('VALN1', response.data.data[0].ISN);
      }
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fieldsList) {
      let list;
      fieldsList.map(async (item) => {
        if (item.code === 'DICTI') {
          const { data } = await api.auto.getDictiByIsn({
            isn: item.dictiisn,
          });
          if (list) {
            list = { ...list, [item.dictiisn]: data.data };
          } else {
            list = { [item.dictiisn]: data.data };
          }
          setDicti(list);
        }
      });
    }
  }, [fieldsList]);

  return fieldsList?.map((field, index) => {
    switch (field?.code) {
      case 'TEXT':
      case 'SQL':
        if (field.fieldname === 'Контактный телефон') {
          return (
            <Col key={index} lg={8} xs={24}>
              <Form.Item
                label={field.fieldname}
                name={`${field.rowcolumn.toUpperCase()}_VALUE`}
                rules={[
                  {
                    required: field.required === 'Y',
                    message: 'Обязательное поле',
                  },
                ]}
              >
                <Input maxLength={12} minLength={12} />
              </Form.Item>
            </Col>
          );
        } else {
          return (
            <Col key={index} lg={8} xs={24}>
              <Form.Item
                label={field.fieldname}
                name={`${field.rowcolumn.toUpperCase()}_VALUE`}
                rules={[
                  {
                    required: field.required === 'Y',
                    message: 'Обязательное поле',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          );
        }
      case 'DICTI':
        return (
          <Col key={index} lg={8} xs={24}>
            <Form.Item
              label={field.fieldname}
              name={field.rowcolumn.toUpperCase()}
              rules={[
                {
                  required: field.required === 'Y',
                  message: 'Обязательное поле',
                },
              ]}
            >
              <CSelect>
                {dicti
                  ? dicti[field.dictiisn]?.children?.map((val) => (
                      <Option
                        value={String(val.foreign_id)}
                        key={val.foreign_id}
                      >
                        {val.full_name}
                      </Option>
                    ))
                  : ''}
              </CSelect>
            </Form.Item>
          </Col>
        );
      case 'CHECKBOX':
        return (
          <Col key={index} lg={8} xs={24}>
            <Form.Item
              label={field.fieldname}
              name={`${field.rowcolumn.toUpperCase()}_VALUE`}
              rules={[
                {
                  required: field.required === 'Y',
                  message: 'Обязательное поле',
                },
              ]}
            >
              <CSelect>
                <Option value={'1'} key={1}>
                  Да
                </Option>
                <Option value={'0'} key={2}>
                  Нет
                </Option>
              </CSelect>
            </Form.Item>
          </Col>
        );
      case 'TIME':
        return (
          <Col key={index} lg={8} xs={24}>
            <Form.Item
              label={field.fieldname}
              name={field.rowcolumn.toUpperCase()}
              rules={[
                {
                  required: field.required === 'Y',
                  message: 'Обязательное поле',
                },
              ]}
            >
              <TimePicker style={{ width: '100%' }} format="HH:mm:ss" />
            </Form.Item>
          </Col>
        );
      case 'DATE':
        return (
          <Col key={index} lg={8} xs={24}>
            <Form.Item
              label={field.fieldname}
              name={field.rowcolumn.toUpperCase()}
              rules={[
                {
                  required: field.required === 'Y',
                  message: 'Обязательное поле',
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format="DD.MM.YYYY"
                className="dateMask"
              />
            </Form.Item>
          </Col>
        );
      case 'SUBJECT':
        if (field.rowcolumn.toUpperCase() === 'VALN1') {
          return (
            <Col key={index} lg={8} xs={24}>
              <Form.Item name={field.rowcolumn.toUpperCase()} hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={field.fieldname}
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues !== currentValues
                }
              >
                {({ getFieldValue }) => {
                  const ValN1 = getFieldValue(field.rowcolumn.toUpperCase());
                  if (typeof ValN1 !== 'undefined' && ValN1 !== null) {
                    return (
                      <Input.Group compact>
                        <Form.Item
                          style={{
                            width: 'calc(100% - 46px)',
                            marginBottom: 0,
                          }}
                          name={`${field.rowcolumn.toUpperCase()}_VALUE`}
                        >
                          <Input readOnly />
                        </Form.Item>
                        <Button
                          type="primary"
                          icon={<DeleteFilled />}
                          style={{ width: '46px' }}
                          onClick={() => {
                            editForm.setFieldsValue({
                              VALN1: undefined,
                              VALN1_VALUE: undefined,
                            });
                          }}
                          danger
                          htmlType="button"
                        />
                      </Input.Group>
                    );
                  } else {
                    return (
                      <Input
                        disabled={isLoading}
                        maxLength={12}
                        onInput={getContractorList}
                        placeholder="Введите ИИН"
                      />
                    );
                  }
                }}
              </Form.Item>
            </Col>
          );
        } else {
          return null;
        }
      case 'INTEGER':
        return (
          <Col key={index} lg={8} xs={24}>
            <Form.Item
              label={field.fieldname}
              name={`${field.rowcolumn.toUpperCase()}_VALUE`}
              rules={[
                {
                  required: field.required === 'Y',
                  message: 'Обязательное поле',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        );
      default:
        return null;
    }
  });
};

export default RenderInputsForEdit;
