import { Col, message, Row, Typography } from 'antd';
import './FoundItem.scss';
import axios from 'axios';
import { base64toFile } from '@modules/OnlineLibrary/helpers/base64toFile';
import api from '@modules/UsefulSection/services/api';

interface IPropsFoundItem {
  title: string;
  uuid: string;
  searchTerm: string;
  getHierarchy: () => void;
  getSubCategories: () => void;
  setCategory: () => void;
}


const { Text } = Typography;
const FoundItem = ({ title, uuid, getHierarchy, getSubCategories, setCategory, searchTerm }: IPropsFoundItem) => {
  const downloadDocument = async () => {
    try {
      const response = await api.downloadDocumentUseful(uuid);
      const data = response.data.content.slice(2, -1);
      const file = base64toFile(data, response.data.filename, response.headers['content-type']);

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = response.data.filename;
      downloadLink.click();
      URL.revokeObjectURL(downloadLink.href);
    } catch (e) {
      message.error('Не удалось скачать файл');
    }
  };

  const getHighlightedText = (text: string, searchTerm: string) => {
    const researchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const parts = text.split(new RegExp(`(${researchTerm})`, 'gi'));
    return <span> {parts.map((part, i) =>
      <span key={i} className={part.toLowerCase() === researchTerm.toLowerCase() ? 'highlighted-text-found' : ''}>
            {part}
        </span>)
    } </span>;
  };

  return (
    <Row gutter={[24, 24]} className="d-flex align-center mt-3">
      <Col span={4}>
        <i className="icon-document-found"/>
      </Col>
      <Col span={15} className="title-item-found">
        <Text strong>
          {getHighlightedText(title, searchTerm)}
        </Text>
      </Col>
      <Col span={2}>
        <i className="icon-get-hierarchy-found" onClick={() => {
          setCategory();
          getHierarchy();
          getSubCategories();
        }}/>
      </Col>
      <Col span={2}>
        <i className="icon-download-found" onClick={downloadDocument}/>
      </Col>
    </Row>
  )
};

export default FoundItem;