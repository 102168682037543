import React, { useEffect } from 'react';
import { openLearningPage } from '@pages/ISpring/utils';
import { Spin } from 'antd';
import './ISpring.scss';
import CButton from '@components/UI/button/CButton';

const ISpring = () => {
  const params = new URLSearchParams(window.location.search);
  const logoutParam = params.get('logout');

  useEffect(() => {
    document.body.classList.add('ispring-page');
    if (logoutParam) {
      window.location.href = 'https://centrasinsurance.ispringlearn.ru/';
    } else {
      openLearningPage(false);
    }

    return () => {
      document.body.classList.remove('ispring-page');
    };
  }, []);
  return (
    <div className="d-flex justify-center align-center flex-column">
      <Spin tip="Сейчас вы будете перенаправлены на iSpring..."></Spin>
      {!logoutParam ? (
        <>
          <div className="mt-5">
            Если перенаправление не произошло автоматически нажмите эту кнопку
          </div>
          <CButton
            className="mt-5"
            type="primary"
            onClick={() => openLearningPage(false)}
          >
            Перейти на iSpring
          </CButton>
        </>
      ) : null}
    </div>
  );
};

export default ISpring;
