import React, { useCallback, useMemo, useState } from 'react';

import {
  Typography,
  Button,
  TreeSelect,
  Form,
  notification,
  Row,
  Col,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import EventsInviteList from './EventsInviteList';
import EventCalendar from './EventCalendar';
import './Events.scss';
import useAuth from '../../hooks/useAuth';
import useEvent from '../../hooks/useEvent';
import EventFields from './EventFields';
import useCounter from '@hooks/useCounter';
import dayjs from 'dayjs';
import api from './services/api';

const { Title } = Typography;
const { TreeNode } = TreeSelect;

export interface IAccessList {
  address: string;
  created_at: string;
  id: number;
  name: string;
  updated_at: string;
}

export interface IRoomList {
  booking_building_id: number;
  created_at: string;
  id: number;
  room: string;
  updated_at: string;
}

const Events = () => {
  const [visibleEventsForm, setVisibleEventsForm] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [selected, setSelected] = useState<undefined | []>();

  const [state, setState] = useState({
    company_id: '',
    room_id: '',
    title: '',
    date: null,
    timeBegin: dayjs(new Date()).format('HH:mm:ss'),
    timeEnd: dayjs(new Date()).add(1, 'hour').format('HH:mm:ss'),
    description: '',
  });

  const auth = useAuth();

  const [form] = Form.useForm();

  const { getUserBookingMethod, isLoading } = useEvent();

  const { getCountEvent } = useCounter();

  const handleShowModal = () => {
    setVisibleModal(true);
  };

  function handleRemoveFile(file: any) {
    setFileList((fileList) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      return newFileList;
    });
  }

  const handleCancelModal = () => {
    setVisibleModal(false);
  };

  const handleOkModal = () => {
    setVisibleModal(false);
    setSelected(selected);
  };

  function addDefaultValues() {
    const formData = new FormData();
    formData.append('description', state.description);
    formData.append('room_id', state.room_id);
    formData.append('title', state.title);
    formData.append('begin', state.date + ' ' + state.timeBegin);
    formData.append('end', state.date + ' ' + state.timeEnd);
    return formData;
  }

  function addFilesToFormData(formData) {
    if (!fileList.length) return;
    fileList?.map((file: any, index: any) => {
      formData.append(`files[${index}]`, file);
    });
    return formData;
  }

  function usersChooseAndAppendToFormData(formData) {
    if (typeof selected === 'undefined') return;
    selected.map((user: any, index: any) => {
      if (user == 1) {
        return (user = null);
      } else {
        const [id, name, img, user_id] = user.split('-');
        formData.append(`users[${user_id}]`, id);
      }
    });
    return formData;
  }

  function addUserWhoCreatedEvent(formData) {
    const meClientID = auth.user.data?.user_info.client_id;
    const meId = auth.user?.data.id;

    formData.append(`users[${meClientID}]`, meId);
    return formData;
  }

  function clearAllFileds() {
    setVisibleEventsForm(false);
    setSelected(undefined);
    setFileList([]);
    setState(initialState);
    form.resetFields();
  }

  async function sendEventIfSuccessMakeLogic(formData) {
    const { data } = await api.sendBookingEvent(formData);
    if (data?.success == false) {
      notification.error({
        message: 'Ошибка',
        description: 'На это время уже занято',
        duration: 5,
      });
    } else if (data?.success == true) {
      clearAllFileds();
      getUserBookingMethod();
      getCountEvent();
    }
  }

  const handleSubmitForm = async () => {
    const checkNotNullableObjectsFileEmpty = state && !fileList.length;
    if (checkNotNullableObjectsFileEmpty) {
      const formData = addDefaultValues();
      usersChooseAndAppendToFormData(formData);
      addUserWhoCreatedEvent(formData);
      sendEventIfSuccessMakeLogic(formData);
    }

    const checkNotNullableObjects = selected && state && fileList.length;
    if (checkNotNullableObjects) {
      const formData = addDefaultValues();
      addFilesToFormData(formData);
      usersChooseAndAppendToFormData(formData);
      addUserWhoCreatedEvent(formData);
      sendEventIfSuccessMakeLogic(formData);
    }
  };

  const initialState = {
    company_id: '',
    room_id: '',
    title: '',
    date: null,
    timeBegin: dayjs(new Date()).format('HH:mm:ss'),
    timeEnd: dayjs(new Date()).add(1, 'hour').format('HH:mm:ss'),
    description: '',
  };

  const handleCancelForm = () => {
    setVisibleEventsForm(false);
    setSelected(undefined);
    setFileList([]);
  };

  const beforeUploadFile = (file: any, newFilesList: any) => {
    setFileList([...fileList, ...newFilesList]);
    return false;
  };

  const userSelectedTimeBegin = (timeBegin: any) => {
    setState({
      ...state,
      timeBegin: dayjs(timeBegin).format('HH:mm:ss'),
    });
  };

  const userSelectedTimeEnd = (timeEnd: any) => {
    setState({
      ...state,
      timeEnd: dayjs(timeEnd).format('HH:mm:ss'),
    });
  };

  const userSelectedDate = (value: any, dateString: any) => {
    setState({
      ...state,
      date: dateString,
    });
  };

  const handleEventChange = (e: { target: { name: any; value: any } }) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const disabledTime = () => {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23, 24],
    };
  };

  return (
    <div className="events">
      {visibleEventsForm ? (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          id="formElem"
          form={form}
          onFinish={handleSubmitForm}
        >
          {/* TODO
          ПЕРЕДАЛАТЬ НА FORM вместо USESTATE
        */}
          <EventFields
            state={state}
            setState={setState}
            handleEventChange={handleEventChange}
            userSelectedDate={userSelectedDate}
            userSelectedTimeBegin={userSelectedTimeBegin}
            disabledTime={disabledTime}
            userSelectedTimeEnd={userSelectedTimeEnd}
            visibleModal={visibleModal}
            handleOkModal={handleOkModal}
            beforeUploadFile={beforeUploadFile}
            fileList={fileList}
            handleRemoveFile={handleRemoveFile}
            handleShowModal={handleShowModal}
            handleCancelModal={handleCancelModal}
            setSelected={setSelected}
            selected={selected}
            handleCancelForm={handleCancelForm}
          />
        </Form>
      ) : (
        <>
          <div className="events__row">
            <EventsInviteList />
          </div>
          <div className="events__row events__create-event">
            <Title className="mb-5" level={3} type="secondary">
              На переговоры
            </Title>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={() => setVisibleEventsForm(true)}
              disabled={isLoading}
            >
              Создать событие
            </Button>
          </div>
          <div className="events__row">
            <EventCalendar />
          </div>
        </>
      )}
    </div>
  );
};

export default Events;
