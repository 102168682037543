import { useEffect, useState } from 'react';
import ColleagueInfo from './ColleagueInfo';
import { useParams } from 'react-router-dom';
import api from '../../services/api';

const Colleague = () => {
  const { userId } = useParams();
  const [showColleague, setShowColleague] = useState<boolean>(false);
  const [colleagueInfo, setColleagueInfo] = useState<any>();

  useEffect(() => {
    if (userId) {
      getColleague(userId as string);
    }
  }, [userId]);

  async function getColleague(id: string) {
    const { data } = await api.colleagues.getColleagueInfo(id);
    setColleagueInfo(data?.data);
    setShowColleague(true);
  }

  return (
    <>
      {showColleague ? <ColleagueInfo colleagueInfo={colleagueInfo} /> : null}
    </>
  );
};

export default Colleague;
