import api from '@modules/Quotation/services';

import { notification } from 'antd';
import { Dispatch, SetStateAction } from 'react';

export const sendCo = async (
  form: any,
  setIsLoadingCOButton: Dispatch<SetStateAction<boolean>>,
  agrIsn: number | undefined,
  userForeignId: number,
  node: number
) => {
  let textUnderwriting = form.getFieldsValue([
    'applicationTextSendUnderwritingDepartment',
  ]);
  textUnderwriting = textUnderwriting.applicationTextSendUnderwritingDepartment;

  const payloadData = {
    agr_isn: agrIsn,
    user_isn: userForeignId,
    text:
      textUnderwriting === undefined || textUnderwriting === ''
        ? null
        : textUnderwriting,
    node: node,
  };

  try {
    setIsLoadingCOButton(true);
    const { data } = await api.quotation.sendToDa(payloadData);
    if (data?.success === true) {
      notification.info({
        message: 'Уведомление',
        description: 'Отправлено в ЦО',
      });
    }
  } finally {
    setIsLoadingCOButton(false);
  }
};
