import CInput from '@components/UI/input/CInput';
import CRadio from '@components/UI/radio/CRadio';
import CSelect from '@components/UI/select/CSelect';
import {
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Checkbox,
  Button,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import api from '@modules/Quotation/services';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import dayjs from 'dayjs';

const { Option } = Select;
const ObjectCardUnderDynamic = ({
  agrIsn,
  objAttributesList,
  userForeignId,
  objAttrData,
  productIsn,
  additionalUnderForm,
  selectedObj,
  dicti,
}) => {
  const [loading, setLoading] = useState(false);
  const { stateDateQuotation } = useQuotation();
  const saveObjAttr = async () => {
    setLoading(true);
    const all = Object.keys(additionalUnderForm.getFieldsValue())
      .map((key) => additionalUnderForm.getFieldsValue()[key])
      .filter((item) => item.value || item.val_n || item.val_c)
      .map((elem) => {
        return { ...elem, fid_obj: selectedObj.fid_obj };
      });
    await api.saveQuotation.attribute_obj_save({ agr_obj_attributes: all });
    setLoading(false);
  };
  useEffect(() => {
    if (agrIsn) {
      additionalUnderForm.setFieldValue('agr_isn', agrIsn);
    }
  }, [agrIsn]);
  useEffect(() => {
    if (objAttrData) {
      objAttrData.attributes_object_insurer.map((item) => {
        if (item.attr_isn_code === 'DICTI') {
          additionalUnderForm.setFieldsValue({
            [item.attr_isn]: {
              ...item,
              val_n: Number(item.val_n),
              agr_isn: agrIsn,
            },
          });
        } else if (item.attr_isn_code === 'DATE') {
          additionalUnderForm.setFieldsValue({
            [item.attr_isn]: {
              ...item,
              val_d: dayjs(item.val_d),
              agr_isn: agrIsn,
            },
          });
        } else {
          additionalUnderForm.setFieldsValue({
            [item.attr_isn]: { ...item, agr_isn: agrIsn },
          });
        }
      });
    }
  }, [objAttrData]);
  return (
    <CustomLoader spinning={loading}>
      <Form
        className="c-form"
        layout="vertical"
        name="objAttributes"
        form={additionalUnderForm}
      >
        <Row gutter={6}>
          {objAttributesList.under?.map((item) => {
            if (item.active_mycent) {
              switch (item.attr_type) {
                case 'DICTI':
                  return (
                    <Col span={12}>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      {/* <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item> */}
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'val_n']}
                        label={item.attr_name}
                      >
                        <CSelect
                          placeholder={item.attr_name}
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        >
                          {dicti &&
                            dicti[item.attr_isn].children?.map((dicti) => (
                              <Option value={dicti.isn} key={dicti.isn}>
                                {dicti.full_name}
                              </Option>
                            ))}
                        </CSelect>
                      </Form.Item>
                    </Col>
                  );
                case 'NUMBER':
                case 'INTEGER':
                case 'FLOAT': {
                  return (
                    <Col span={12}>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        // initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'val_n']}
                        label={item.attr_name}
                      >
                        <CInput
                          placeholder={item.attr_name}
                          className={
                            item.attr_isn === 475611 ? 'numericMask' : null
                          }
                          disabled={
                            checkDisabledField(stateDateQuotation.status_isn) ||
                            item.attr_isn === 2668771
                          }
                        />
                      </Form.Item>
                    </Col>
                  );
                }
                case 'CHECKBOX': {
                  return (
                    <Col span={12}>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        // initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item name={[item.attr_isn, 'val_n']}>
                        <Checkbox
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        >
                          {item.attr_name}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  );
                }
                case 'SQL': {
                  return (
                    <Col span={12}>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        // initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'val_c']}
                        label={item.attr_name}
                      >
                        <CInput
                          placeholder={item.attr_name}
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                    </Col>
                  );
                }
              }
            }
          })}
        </Row>
        <Col span={24}>
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={saveObjAttr}
          >
            Сохранить
          </Button>
        </Col>
      </Form>
    </CustomLoader>
  );
};
export default ObjectCardUnderDynamic;
