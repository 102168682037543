import { ICityData } from '@modules/Quotation/hooks/useCityQuotation';
import { TreeSelect } from 'antd';
import CTreeSelect from '@components/UI/treeselect/CTreeSelect';

const { TreeNode } = TreeSelect;

interface Props {
  citiesList: ICityData[];
  disabled?: boolean;
  readonly?: boolean;
  selectCities?: boolean;
  value?: number;
  onChange?: (value: number | undefined) => void;
  onSelectUser?: (user) => void;
  placeholder: string;
}

function renderTreeNodes(item: ICityData, selectCities: boolean) {
  return (
    <TreeNode
      key={item.region_isn}
      value={item.region_isn}
      title={item.name}
      selectable={selectCities}
    >
      {item?.child?.map((user) => {
        return (
          <TreeNode
            value={user.city_isn}
            key={user.city_isn}
            title={user.name}
            selectable={!selectCities}
          />
        );
      })}
    </TreeNode>
  );
}
function TreeSelectCities(props: Props) {
  const {
    disabled = false,
    citiesList = [],
    selectCities = false,
    onChange,
    value,
    placeholder,
  } = props;

  return (
    <CTreeSelect
      onChange={onChange}
      // size="large"
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeLine={{ showLeafIcon: false }}
      treeNodeFilterProp="title"
      allowClear={true}
      showSearch={true}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
    >
      {citiesList.map((item) => renderTreeNodes(item, selectCities))}
    </CTreeSelect>
  );
}

export default TreeSelectCities;
