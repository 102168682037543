import axios from '@services/api/axios';
import { notification } from 'antd';

export interface ISaveSpeaker {
  user_id: number;
  topic: string;
  date_time: string;
  university_name: string;
  specialization: string;
  address: string;
  quantity: string;
  course: string;
}

export interface IGetSpeakers {
  id: number;
  user_id: {
    id: number;
    full_name: string;
  };
  avatar: IAvatar;
  department: {
    full_name: string;
  };
  topic: string;
  university_name: string;
  specialization: string;
  address: string;
  date_time: string;
  quantity: number;
  course: string;
  created_at: string;
  updated_at: string;
  hr: boolean;
}

interface IAvatar {
  id: number;
  client_id: number;
  link: string;
  created_at: string;
  updated_at: string;
  type: number;
  created_by: number;
  updated_by: number;
}

export interface IGetSpeakersFilters {
  user_id?: number;
  date_begin?: string;
  date_end?: string;
}

export const api = {
  getSpeakers: () =>
    axios.get('/recruiting-speaker').then((values) => {
      if (values?.data?.success === false) {
        notification.error({
          message: 'Ошибка',
          description: values?.data?.message,
        });
      }
      return values?.data?.data;
    }),
  getSpeakerById: (id: number) =>
    axios.get(`/recruiting-speaker/${id}`).then((values) => values?.data?.data),

  getFilterSearch: (values: IGetSpeakersFilters) =>
    axios
      .get(
        `/recruiting-speaker?${
          values.user_id && values.user_id !== null
            ? `user_id=${values.user_id}`
            : 'user_id=null'
        }&${
          values.date_begin && values.date_begin !== null
            ? `date_begin=${values.date_begin}`
            : 'date_begin=null'
        }&${
          values.date_end && values.date_end !== null
            ? `date_end=${values.date_end}`
            : `date_end=null`
        }`
      )
      .then((values) => values.data?.data),

  getFilterSearchFile: (data: IGetSpeakersFilters) =>
    axios
      .post('/recruiting-speaker/download', data, { responseType: 'blob' })
      .then((values) => values.data),

  saveSpeaker: (values: ISaveSpeaker) =>
    axios.post('/recruiting-speaker', values).then((values) => {
      if (values?.data?.success === false) {
        notification.error({
          message: 'Ошибка',
          description: values?.data?.message,
        });
      }
      return values.data;
    }),

  updateSpeakerById: (values: ISaveSpeaker, id: number) =>
    axios.put(`/recruiting-speaker/${id}`, values).then((values) => values),

  deleteSpeakerById: (id: number) =>
    axios.delete(`/recruiting-speaker/${id}`).then((values) => values),
};
