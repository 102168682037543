import { useEffect } from 'react';
import { Card, Typography, notification } from 'antd';
import { useImmerReducer } from 'use-immer';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { AgreementFull, AgreementShort, AgreementType } from './type';

import AgreementModal from './AgreementModal';
import useCounter from '../../hooks/useCounter';
import useIsFirstRender from '../../hooks/useIsFirstRender';
import AgreementList from './components/AgreementList';
import api from './services/api';
import { getDocData } from './getDocData';
import { IDynamicTable } from '@modules/common/DynamicTable/data.type';
import ModalSuccess from '@modules/Agreement/components/ModalSuccess';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const { Title } = Typography;
interface AgreementProps {
  title: string;
}
interface Action {
  type:
    | 'getAgreementList'
    | 'getAgreement'
    | 'loading'
    | 'hideModal'
    | 'showModal'
    | 'getDocInfo';
  payload?: any;
}
interface State {
  agreementList: AgreementType;
  isLoading: boolean;
  isVisibleModal: boolean;
  currentAgreementID: number | null;
  currentAgreementType: string;
  currentAgreement: AgreementFull | null;
  currentDocInfo: IDynamicTable | null;
}

const initialState: State = {
  agreementList: {},
  isLoading: true,
  isVisibleModal: false,
  currentAgreementID: null,
  currentAgreementType: '',
  currentAgreement: null,
  currentDocInfo: null,
};

const reducer = (draft: State, action: Action) => {
  switch (action.type) {
    case 'getAgreementList': {
      draft.agreementList = action.payload;
      break;
    }
    case 'getAgreement': {
      draft.currentAgreement = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
    case 'hideModal': {
      draft.isVisibleModal = false;
      draft.currentAgreementID = null;
      draft.currentAgreementType = '';
      draft.currentAgreement = null;
      break;
    }
    case 'showModal': {
      draft.isVisibleModal = true;
      draft.currentAgreementID = action.payload.documentID;
      draft.currentAgreementType = action.payload.type;
      break;
    }
    case 'getDocInfo': {
      draft.currentDocInfo = action.payload;
      break;
    }
    default: {
      throw new Error(`Unknown action type: ${action.type}`);
    }
  }
};

function Agreement({ title }: AgreementProps) {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const { getCountAgreement } = useCounter();
  const isFirst = useIsFirstRender();
  const { status } = useParams();

  const handleAgreementIDSelect = (documentID: number, type: string): void =>
    dispatch({ type: 'showModal', payload: { documentID, type } });

  const handleCancel = (): void => dispatch({ type: 'hideModal' });

  const handleAgreementListGet = (newAgreementList): void => {
    dispatch({ type: 'getAgreementList', payload: newAgreementList });
  };

  const handleAgreementGet = (agreement): void => {
    dispatch({ type: 'getAgreement', payload: agreement });
    if (
      agreement?.docsform &&
      agreement?.docsform.length &&
      agreement.docsform[0]?.REFDOCISN !== null
    ) {
      handleDocInfo(agreement);
    }
  };

  useEffect(() => {
    (async function fetchAgreementList() {
      try {
        dispatch({ type: 'loading', payload: true });
        const { data } = await api.getData();

        dispatch({
          type: 'getAgreementList',
          payload: data,
        });
      } catch (error) {
        let message;

        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }

        notification.info({
          message: 'Ошибка',
          description: message,
        });
      } finally {
        dispatch({ type: 'loading', payload: false });
      }
    })();
  }, []);

  useEffect(() => {
    if (isFirst) return;

    getCountAgreement();
  }, [state.agreementList]);

  const handleDocInfo = (agreement) => {
    getDocData(agreement.docsform[0]?.REFDOCISN)
      .then((values) => dispatch({ type: 'getDocInfo', payload: values }))
      .catch(() => {
        notification.info({
          message: 'Уведомление',
          description: 'Не найдены документы по этому ЛС',
        });
      });
  };

  const resetDocInfo = () => {
    dispatch({ type: 'getDocInfo', payload: null });
  };
  return (
    <>
      {status === 'success' ? <ModalSuccess /> : null}
      {state.isLoading ? (
        <CustomLoader spinning={state.isLoading} />
      ) : (
        <Card>
          <Title level={5} type="secondary" className="mb-5">
            {title}
          </Title>
          <AgreementList
            agreementList={state.agreementList}
            onAgreementIDSelect={handleAgreementIDSelect}
          />
        </Card>
      )}

      {state.currentAgreementID !== null && (
        <AgreementModal
          agreement={state.currentAgreement}
          agreementID={state.currentAgreementID}
          agreementType={state.currentAgreementType}
          agreementDocument={state.currentDocInfo}
          visible={state.isVisibleModal}
          onCancel={handleCancel}
          onAgreementListGet={handleAgreementListGet}
          onAgreementGet={handleAgreementGet}
          resetDocInfo={resetDocInfo}
          docList={state.agreementList.RE?.doc_list}
        />
      )}
    </>
  );
}

export default Agreement;
