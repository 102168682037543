import Title from "antd/es/typography/Title";
import {Button, Col, Form, Input, message, Modal, notification, Row, Select} from "antd";
import React, {useEffect} from "react";
import useAuth from "@hooks/useAuth";
import api from "@modules/FileExchange/services/api";
import axios from "axios";
import TreeSelectCollegues from "@modules/TreeSelect/TreeSelectCollegues";
import useColleagues from "@hooks/useColleagues";

interface IUser {
    first_name: string;
    last_name: string;
    email: string;
    external_id: number;
    id: number;
    role: string;
}

interface IAdminPanelModal {
    isModalOpen: boolean;
    onCancel: () => void;
    getUsers: () => void;
    currentUser: IUser | null;
}

export const getErrorMessage = (errorMessage: number) => {
    const classMap: { [key: number]: string } = {
        403: 'У пользователя нет прав на созданиe',
        400: 'Пользователь с таким айди или email уже существует',
    };

    return classMap[errorMessage] || 'Ошибка на сервере, попробуйте позже';
};

const AdminPanelModal = ({getUsers, isModalOpen, onCancel, currentUser}: IAdminPanelModal) => {
    const {user} = useAuth();
    const [addUserForm] = Form.useForm();
    const {colleaguesList} = useColleagues();

    const onSubmit = async (event: Event) => {
        const requestData = {
            first_name: event['first_name'],
            last_name: event['last_name'],
            email: event['email'],
            role: event['role'],
            external_id: event['external_id'],
        };

        try {
            if(currentUser) {
                await api.editUser(currentUser.id, requestData);
                message.success("Вы успешно изменили пользователя");
            } else  {
                await api.createUser(requestData);
                message.success("Вы успешно создали пользователя");
            }

            getUsers();
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const checkError = error.response.status;

                notification.error({
                    message: 'Ошибка',
                    description: getErrorMessage(checkError),
                });
            }
        } finally {
            addUserForm.resetFields();
            onCancel();
        }
    };

    useEffect(() => {
        if (currentUser) {
            addUserForm.setFieldsValue({
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
                external_id: currentUser.external_id,
                email: currentUser.email,
                role: currentUser.role,
            });
        } else {
            addUserForm.resetFields();
        }
    }, [currentUser, addUserForm]);

    return (
        <Modal
            title={<Title level={4}>{currentUser  ? "Редактировать пользвователя" : "Создать нового пользователя"}</Title>}
            open={isModalOpen}
            footer={null}
            onCancel={onCancel}
            className="add-modal-file-exchange"
            centered
        >
            <Form form={addUserForm} onFinish={onSubmit} layout="vertical">
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={<Title level={5}>Найдите пользователя</Title>}
                            name="external_id"
                            rules={[{ required: true, message: 'Пожалуйста, найдите пользователя' }]}
                        >
                            <TreeSelectCollegues
                                disabled={!!currentUser}
                                colleaguesList={colleaguesList}
                                large={true}
                                placeHolder="Найдите пользователя"
                                onSelectUser={(user) => {
                                    addUserForm.setFieldsValue({
                                        first_name: user.first_name,
                                        last_name: user.last_name,
                                    });
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={<Title level={5}>Введите имя пользователя</Title>}
                            name="first_name"
                            rules={[{ required: true, message: 'Пожалуйста, введите имя' }]}
                            hidden={true}
                        >
                            <Input
                                placeholder="Введите имя пользователя"
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={<Title level={5}>Введите фамилию пользователя</Title>}
                            name="last_name"
                            rules={[{ required: true, message: 'Пожалуйста, введите фамилию' }]}
                            hidden={true}
                        >
                            <Input
                                disabled={true}
                                placeholder="Введите фамилию пользователя"
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={<Title level={5}>Введите email пользователя</Title>}
                            name="email"
                            rules={[{ required: true, message: 'Пожалуйста, введите email пользователя' }]}
                        >
                            <Input
                                disabled={!!currentUser}
                                placeholder="Введите email пользователя"
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={<Title level={5}>Выберите роль пользователя</Title>}
                            name="role"
                            rules={[{ required: true, message: 'Пожалуйста, выберите роль пользователя' }]}
                        >
                            <Select
                                placeholder="Выберите роль пользователя"
                                size="large"
                                options={[
                                    { value: 'manager', label: 'Менеджер' },
                                    { value: 'admin', label: 'Администратор' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="d-flex align-end justify-start">
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                block
                                type="primary"
                                size="large"
                                className="create-button-file-exchange"
                            >
                                {currentUser ? "Редактировать" : "Создать +"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default AdminPanelModal;