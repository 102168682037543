import { Col, DatePicker, Divider, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';

const InfoDocument = ({ contractInfo }) => {
  const [infoForm] = Form.useForm();

  useEffect(() => {
    infoForm.setFieldsValue({
      date_beg: dayjs(contractInfo.data.agreement[0].datebeg),
      date_end: dayjs(contractInfo.data.agreement[0].dateend),
      date_sign: dayjs(contractInfo.data.agreement[0].datesign),
    })
  }, []);

  return (
    <>
      <Divider orientation="left">Сроки действия договора</Divider>
      <Form
        layout="vertical"
        form={infoForm}
      >
        <Row className="d-flex" gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Дата подписания"
              name="date_beg"
            >
              <DatePicker
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Дата Начала"
              name="date_sign"
            >
              <DatePicker
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Дата окончания"
              name="date_end"
            >
              <DatePicker
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider/>
    </>
  )
};

export default InfoDocument;