import { Button, Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';

interface IPropsDeleteModalFolder {
  isModalOpen: boolean;
  onCancel: () => void;
  deleteFolder: () => void;
  isLoading: boolean;
}
const DeleteModalFolder = ({ isModalOpen, onCancel, deleteFolder, isLoading }: IPropsDeleteModalFolder) => {
  return (
    <Modal
      title={<Title level={4}>Вы уверены что хотите удалить?</Title>}
      open={isModalOpen}
      footer={null}
      onCancel={onCancel}
      className="add-modal-online-library"
      centered
    >
      <Row className="d-flex justify-space-between mt-8">
        <Col span={11}>
          <Button
            loading={isLoading}
            block
            size="large"
            className="delete-btn-online-library"
            onClick={deleteFolder}
          >
            Да
          </Button>
        </Col>
        <Col span={11}>
          <Button
            block
            size="large"
            className="reject-btn-online-library"
            onClick={onCancel}
          >
            Отмена
          </Button>
        </Col>
      </Row>
    </Modal>
  )
};

export default DeleteModalFolder;