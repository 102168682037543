import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Typography,
} from 'antd';
import { DeleteFilled, SearchOutlined } from '@ant-design/icons';

import { HandbookListType } from '../../../hooks/useHandbook';
import { ColleaguesListType } from '../../../hooks/useColleagues';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import TreeSelectHandbook from '@modules/TreeSelect/TreeSelectHandook';
import api from '../services/api';
import useAuth from '@hooks/useAuth';
import dayjs from 'dayjs';

const { Title } = Typography;
const { Search } = Input;

const { RangePicker } = DatePicker;

interface EdoSearchFormProps {
  handbookList: HandbookListType;
  handbookStatusList: HandbookListType;
  colleaguesList: ColleaguesListType;
  departmentList: ColleaguesListType;
  handBookStageList: HandbookListType;
  onSetSearchResult: (searchResult, paramSearch) => void;
  onToggleSearch: (isSearching: boolean) => void;
}

const PAGE_NUMBER_DEFAULT = 1;

function EdoSearchForm(props: EdoSearchFormProps) {
  const {
    handbookList = [],
    handbookStatusList = [],
    colleaguesList = [],
    departmentList = [],
    handBookStageList = [],
    onSetSearchResult = () => {},
    onToggleSearch = () => {},
  } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClient, setIsLoadingClient] = useState(false);

  /**
   * Если в sessionStorage есть сохранение полей поиска документов -
   * подставляем значения и отправляем запрос
   */
  useEffect(() => {
    if (sessionStorage) {
      const searchData = sessionStorage.getItem('searchData');
      if (searchData) {
        const formData = JSON.parse(searchData);
        formData.date_range = formData?.date_range?.map((date) => dayjs(date));

        // Чтобы не перезаписывать sessionStorage при повторном запросе
        formData.once = true;
        form.setFieldsValue(formData);
        handleFinishForm(formData);
      }
    }
  }, [form, sessionStorage]);

  /**
   * При перезагрузке страницы чистим поля поиска документа
   */
  useEffect(() => {
    window.addEventListener('beforeunload', clearSearchData);
    return () => {
      window.removeEventListener('beforeunload', clearSearchData);
    };
  }, []);

  /**
   * Удаляет значения полей поиска документов
   */
  const clearSearchData = () => {
    sessionStorage.removeItem('searchData');
  };

  const auth = useAuth();

  const handleFinishForm = async (values) => {
    try {
      setIsLoading(true);

      const sendData = {
        class_id: values.class_id,
        document_id: values.document_id,
        status_id: values.status_id,
        stage_id: values.stage_id,
        employee_id: parseInt(values.employee_id),
        department_id: values.department_id,
        client_id: values.client_id,
        document_date_start: values.date_range?.[0],
        document_date_end: values.date_range?.[1],
      };

      const { data } = await api.searchDocuments(PAGE_NUMBER_DEFAULT, sendData);

      if (!values.once) {
        // Записываем значения полей поиска документов в sessionStorage, чтобы при возврате назад поиск не сбрасывался
        sessionStorage.setItem('searchData', JSON.stringify(values));
      }

      onSetSearchResult(data, sendData);
    } catch (error) {
      let message;

      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchClient = async (iin, event) => {
    if (event?.currentTarget.nodeName === 'INPUT' || iin.length === 0) {
      return;
    }

    try {
      setIsLoadingClient(true);

      const { data } = await api.getContractor({
        iin: parseInt(iin),
      });

      form.setFieldsValue({
        client_id: data.data.user_id,
        client_short_name: data.data.short_name,
      });
    } catch (error) {
      let message;

      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoadingClient(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      onToggleSearch(true);
    } else {
      onToggleSearch(false);
    }
  }, [isLoading, onToggleSearch]);

  return (
    <Card data-testid="edo-search-form">
      <Title level={5} type="secondary" className="mb-5">
        Поиск документов
      </Title>
      <Form layout="vertical" onFinish={handleFinishForm} form={form}>
        <Row gutter={24}>
          <Col span={8} xs={24} xl={8}>
            <Form.Item label="Тип документа" name="class_id">
              <TreeSelectHandbook
                handbookList={handbookList}
                disabled={isLoading}
              />
            </Form.Item>
          </Col>
          <Col span={8} xs={24} xl={8}>
            <Form.Item label="Номер документа №" name="document_id">
              <Input disabled={isLoading} />
            </Form.Item>
          </Col>
          {/* <Col span={8}> */}
          <Form.Item label="Статус" name="status_id" hidden noStyle>
            <Select
              options={handbookStatusList}
              showSearch
              allowClear
              fieldNames={{
                label: 'full_name',
                value: 'foreign_id',
                options: 'children',
              }}
              disabled={isLoading}
            />
          </Form.Item>
          {/* </Col> */}
          <Col span={8} xs={24} xl={8}>
            <Form.Item label="Статусы" name="stage_id">
              <Select
                options={handBookStageList}
                showSearch
                allowClear
                fieldNames={{
                  label: 'name',
                  value: 'id',
                  options: 'children',
                }}
                disabled={isLoading}
              />
            </Form.Item>
          </Col>
          <Col span={8} xs={24} xl={8}>
            <Form.Item
              label="Куратор"
              name="employee_id"
              initialValue={auth?.user?.data?.id}
            >
              <TreeSelectCollegues
                colleaguesList={colleaguesList}
                disabled={isLoading}
              />
            </Form.Item>
          </Col>
          <Col span={8} xs={24} xl={8}>
            <Form.Item label="Подразделения" name="department_id">
              <TreeSelectCollegues
                colleaguesList={departmentList}
                selectDepartment={true}
                disabled={isLoading}
              />
            </Form.Item>
          </Col>
          <Col span={8} xs={24} xl={8}>
            <Form.Item label="За период" name="date_range">
              <RangePicker
                style={{ width: '100%' }}
                format={'DD.MM.YYYY'}
                disabled={isLoading}
              />
            </Form.Item>
          </Col>
          <Col span={8} xs={24} xl={8}>
            <Form.Item name="client_id" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Контрагент"
              shouldUpdate={(prevValues, currentValues) =>
                prevValues !== currentValues
              }
            >
              {({ getFieldValue }) => {
                const clientID = getFieldValue('client_id');

                if (typeof clientID !== 'undefined' && clientID !== null) {
                  return (
                    <Input.Group compact>
                      <Form.Item
                        style={{
                          width: 'calc(100% - 46px)',
                          marginBottom: 0,
                        }}
                        name="client_short_name"
                      >
                        <Input readOnly disabled={isLoading} />
                      </Form.Item>
                      <Button
                        type="primary"
                        icon={<DeleteFilled />}
                        style={{ width: '46px' }}
                        onClick={() => {
                          form.setFieldsValue({
                            client_id: undefined,
                            client_short_name: '',
                          });
                        }}
                        danger
                        htmlType="button"
                        disabled={isLoadingClient || isLoading}
                      />
                    </Input.Group>
                  );
                } else {
                  return (
                    <Search
                      placeholder="Введите ИИН"
                      loading={isLoadingClient}
                      enterButton
                      allowClear
                      onSearch={handleSearchClient}
                      disabled={isLoading}
                    />
                  );
                }
              }}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              htmlType="submit"
              type="primary"
              icon={<SearchOutlined />}
              loading={isLoading || isLoadingClient}
            >
              {isLoading ? 'Поиск' : 'Найти'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default EdoSearchForm;
