import axios from "@services/api/axios";

const endpoints = {
  createInvoice: (data) => axios.post('/contract/invoice' , data),
  getInvoice: (data) => axios.get(`/contract/invoice/${data}`),
  updateInvoice: (invoiseIsn, data) => axios.put(`/contract/invoice/${invoiseIsn}`, data),
  getAccrual: (agr_isn) => axios.get(`/contract/accrual/${agr_isn}`),
  getStatus: () => axios.get(`/dicti/card/status`),
};

export default endpoints;
