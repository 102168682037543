import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

type AddCandidateButtonProps = {
  showModalMethod: () => void
}

function AddCandidateButton({showModalMethod }: AddCandidateButtonProps) {
  return (
    <>
      <Button block icon={<PlusOutlined />} onClick={showModalMethod}>
        Добавить кандидата
      </Button>
    </>
  );
}

export default AddCandidateButton;
