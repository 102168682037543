import QuotationComponent from '@modules/Quotation';
import QuotationClassObjectProvider from '@modules/Quotation/providers/QuotationClassObjectProvider';
import QuotationJournalProvider from '@modules/Quotation/providers/QuotationJournalProvider';
import QuotationProvider from '@modules/Quotation/providers/QuotationProvider';
import { useEffect } from 'react';

import './Quotation.scss';

const Quotation = ({ title = '' }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <QuotationProvider>
      <QuotationJournalProvider>
        <QuotationClassObjectProvider>
          <QuotationComponent />
        </QuotationClassObjectProvider>
      </QuotationJournalProvider>
    </QuotationProvider>
  );
};

export default Quotation;

/*
 * Для корректной работы котировок, требуется стянуть с Киас в таблицу dictis эти ИСН
 * 13       -- страховые продукты
 * 25100    -- фабрика продуктов
 * 6        -- правила страхования
 * 11       -- ограничения и оговорки
 * 15       -- страховые риски
 * 16       -- тарификатор системы
 * 19       -- Участники договора
 * 220579   -- дополнительные атрибуты
 * 223373   -- стадия полной котировки
 * 225326   -- тарифные факторы
 * 221576   -- классы страхования
 * 9434     -- страны
 * 9435     -- валюты
 * 220602   -- виды котировки
 * 223366   -- форма
 * 221726   -- типы документов
 * 11110    -- канал продаж
 * 2999     -- порядок оплаты
 *
 *TS
 * 848581   -- прочие
 * 515241   -- Микроавтобусы, бортовые, грузопассажирские газели, фургоны, автобусы до 20 посадочных мест
 * 3363     -- Автобусы, до 16 пассажирских мест
 * 3364     -- Грузовые автомобили --- сломалось
 * 3365     -- Спецтехника
 * 3366     -- Легковые автомобили --- сломалось
 * 3367     -- Мотоциклы и мотороллеры
 * 3368     -- Прицеп к грузовой а/м --- сломалось
 * 3369     -- Прицеп к легковой а/м
 * 3370     -- Прицепы к Спецтехнике
 * 3371     -- Трамваи
 * 3372     -- Троллейбусы
 * 223352   -- Автобусы, свыше 16 пассажирских мест
 *
 * Типы объектов договора
 * 10825    -- Объекты на открытых площадках
 * 2117     -- Авиация и космонавтика                                       AGROBJAVIA
 * 2118     -- Автотранспорт   ---сломалось                                 AGROBJCAR
 * 2119     -- Водный транспорт                                             AGROBJMARINE
 * 2120     -- Груз                                                         AGROBJCARGO AGROBJADDR
 * 2121     -- Группа лиц
 * 224103   -- Объекты ОСГОП
 * 222660   -- Внеуличный транспорт                                         AGROBJMETRO
 * 221713   -- Ответственность
 * 220067   -- Секция договора входящего перестрахования                    AGROBJAGRRE
 * 2123     -- Дополнительное оборудование транспортных средств
 * 2124     -- Другие объекты
 * 2125     -- Железнодорожный транспорт                                    AGROBJRAIL
 * 2127     -- Имущество физ лиц                                            AGROBJADDR
 * 2128     -- Коммерческая деятельность
 * 2129     -- Оборудование и материалы
 * 2130     -- Объекты строительства и монтажа
 * 2131     -- Профессиональная деятельность
 * 2132     -- Сельскохозяйственные объекты
 * 2133     -- Товарные запасы
 * 2134     -- Финансы
 * 2135     -- Человек
 * 2122     -- Договор (группа договоров)                                    AGROBJAGR
 * 221803   -- Опасные производственные объекты                              AGROBJOPO
 * 147620   -- ТМЦ в обороте (в зданиях)                                        AGROBJADDR
 * 147630   -- ТМЦ в обороте на открытых площадках                              AGROBJADDR
 * 147590   -- Здания, помещения целиком                                        AGROBJADDR
 * 147600   -- Отделка и инженерные коммуникации здания, помещения
 * 147610   -- Имущество в здании (кроме ТМЦ в обороте)                         AGROBJADDR
 * 2019     -- Право владения
 * 2120     -- Вид груза                                                        AGROBJCARGO
 * 2120     -- Тип груза                                                        AGROBJCARGO
 * 2672     -- Цель пользования
 * 2028     -- Цвет
 * 2018     -- Категория ТС
 * 2042     -- Упаковка
 * 2041     -- Ед.измерения
 * 2040     -- Транспорт
 * 221002   -- Тип судна                                                            AGROBJMARINE
 * 2119     -- Вид судна                                                            AGROBJMARINE
 * 0        -- Флаг(страна)
 * 2117     -- модель
 * 2125     -- тип(модель)
 */
