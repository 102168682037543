import { Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd';

const ModalSuccess = () => {
    const [modalOpen, setModalOpen] = useState(true);
    const navigate = useNavigate();

    return (
        <Modal
            title="Подпись завершена!"
            centered
            open={modalOpen}
            footer={null}
            onCancel={() => {
                setModalOpen(false);
                navigate("/agreement");
            }}
        >
            <Alert message="Поздравляем! Ваш документ был успешно подписан!" type="success" showIcon/>
        </Modal>
    );
};


export default ModalSuccess;