import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Tabs,
} from 'antd';
import ObjectCardMainDynamic from './ObjectCardMainDynamic';
import PrimaryConditionsDynamic from './PrimaryConditionsDynamic';
import ObjectCardAdditionalDynamic from './ObjectCardAdditionalDynamic';
import ObjectCardUnderDynamic from './ObjectCardUnderDynamic';
import type { TabsProps } from 'antd';
import { useImmerReducer } from 'use-immer';
import api from '@modules/Quotation/services';
import { useEffect, useState } from 'react';

const ObjectCardModalDynamic = ({
  isHaveDicti,
  closeObjectModal,
  isLoading,
  agrIsn,
  objAttributesList,
  userForeignId,
  saveObject,
  objAttrData,
  productIsn,
  AdvancedContractorSearch,
  modalSetting,
  saveObjectCond,
  productSelectedQuotation,
  mainForm,
  selectedObj,
  primaryCondForm,
  additionalObjAttributesForm,
  additionalUnderForm,
  agrCond,
  dicti,
}) => {
  const [items, setItems] = useState<TabsProps['items']>([]);
  // useEffect(() => {
  //   if (dicti) {
  //     setItems([
  //       {
  //         key: '1',
  //         label: 'Основное',
  //         children: (
  //           <ObjectCardMainDynamic
  //             agrIsn={agrIsn}
  //             dicti={dicti}
  //             saveObject={saveObject}
  //             AdvancedContractorSearch={AdvancedContractorSearch}
  //             modalSetting={modalSetting}
  //             mainForm={mainForm}
  //             selectedObj={selectedObj}
  //           />
  //         ),
  //       },
  //     ]);
  //   }
  // }, [dicti]);
  useEffect(() => {
    if (items && selectedObj) {
      if (
        (!isHaveDicti || dicti) &&
        objAttributesList.additional &&
        objAttributesList.additional.length > 0 &&
        (!objAttributesList.under || objAttributesList.under.length === 0)
      ) {
        setItems([
          {
            key: '1',
            label: 'Основное',
            children: (
              <ObjectCardMainDynamic
                agrIsn={agrIsn}
                dicti={dicti}
                saveObject={saveObject}
                AdvancedContractorSearch={AdvancedContractorSearch}
                modalSetting={modalSetting}
                mainForm={mainForm}
                selectedObj={selectedObj}
              />
            ),
          },
          // {
          //   key: '2',
          //   label: 'Первичные условия',
          //   children: (
          //     <PrimaryConditionsDynamic
          //       modalSetting={modalSetting}
          //       saveObjectCond={saveObjectCond}
          //       productSelectedQuotation={productSelectedQuotation}
          //       primaryCondForm={primaryCondForm}
          //       selectedObj={selectedObj}
          //       agrCond={agrCond}
          //       carForm={mainForm}
          //     />
          //   ),
          // },
          {
            key: '3',
            label: 'Раздел страховщика',
            children: (
              <ObjectCardAdditionalDynamic
                agrIsn={agrIsn}
                objAttributesList={objAttributesList}
                userForeignId={userForeignId}
                objAttrData={objAttrData}
                additionalObjAttributesForm={additionalObjAttributesForm}
                selectedObj={selectedObj}
                dicti={dicti}
              />
            ),
          },
        ]);
      } else if (
        dicti &&
        (!objAttributesList.additional ||
          objAttributesList.additional.length === 0) &&
        objAttributesList.under &&
        objAttributesList.under > 0
      ) {
        setItems([
          {
            key: '1',
            label: 'Основное',
            children: (
              <ObjectCardMainDynamic
                agrIsn={agrIsn}
                dicti={dicti}
                saveObject={saveObject}
                AdvancedContractorSearch={AdvancedContractorSearch}
                modalSetting={modalSetting}
                mainForm={mainForm}
                selectedObj={selectedObj}
              />
            ),
          },
          // {
          //   key: '2',
          //   label: 'Первичные условия',
          //   children: (
          //     <PrimaryConditionsDynamic
          //       modalSetting={modalSetting}
          //       saveObjectCond={saveObjectCond}
          //       productSelectedQuotation={productSelectedQuotation}
          //       primaryCondForm={primaryCondForm}
          //       selectedObj={selectedObj}
          //       agrCond={agrCond}
          //       carForm={mainForm}
          //     />
          //   ),
          // },
          {
            key: '4',
            label: 'Раздел андеррайтера',
            children: (
              <ObjectCardUnderDynamic
                agrIsn={agrIsn}
                objAttributesList={objAttributesList}
                userForeignId={userForeignId}
                objAttrData={objAttrData}
                productIsn={productIsn}
                additionalUnderForm={additionalUnderForm}
                selectedObj={selectedObj}
                dicti={dicti}
              />
            ),
          },
        ]);
      } else if (
        dicti &&
        objAttributesList.additional &&
        objAttributesList.additional.length > 0 &&
        objAttributesList.under &&
        objAttributesList.under.length > 0
      ) {
        setItems([
          {
            key: '1',
            label: 'Основное',
            children: (
              <ObjectCardMainDynamic
                agrIsn={agrIsn}
                dicti={dicti}
                saveObject={saveObject}
                AdvancedContractorSearch={AdvancedContractorSearch}
                modalSetting={modalSetting}
                mainForm={mainForm}
                selectedObj={selectedObj}
              />
            ),
          },
          // {
          //   key: '2',
          //   label: 'Первичные условия',
          //   children: (
          //     <PrimaryConditionsDynamic
          //       modalSetting={modalSetting}
          //       saveObjectCond={saveObjectCond}
          //       productSelectedQuotation={productSelectedQuotation}
          //       primaryCondForm={primaryCondForm}
          //       selectedObj={selectedObj}
          //       agrCond={agrCond}
          //       carForm={mainForm}
          //     />
          //   ),
          // },
          {
            key: '3',
            label: 'Раздел страховщика',
            children: (
              <ObjectCardAdditionalDynamic
                agrIsn={agrIsn}
                objAttributesList={objAttributesList}
                userForeignId={userForeignId}
                objAttrData={objAttrData}
                additionalObjAttributesForm={additionalObjAttributesForm}
                selectedObj={selectedObj}
                dicti={dicti}
              />
            ),
          },
          {
            key: '4',
            label: 'Раздел андеррайтера',
            children: (
              <ObjectCardUnderDynamic
                agrIsn={agrIsn}
                objAttributesList={objAttributesList}
                userForeignId={userForeignId}
                objAttrData={objAttrData}
                productIsn={productIsn}
                additionalUnderForm={additionalUnderForm}
                selectedObj={selectedObj}
                dicti={dicti}
              />
            ),
          },
        ]);
      } else {
        setItems([
          {
            key: '1',
            label: 'Основное',
            children: (
              <ObjectCardMainDynamic
                agrIsn={agrIsn}
                dicti={dicti}
                saveObject={saveObject}
                AdvancedContractorSearch={AdvancedContractorSearch}
                modalSetting={modalSetting}
                mainForm={mainForm}
                selectedObj={selectedObj}
              />
            ),
          },
          // {
          //   key: '2',
          //   label: 'Первичные условия',
          //   children: (
          //     <PrimaryConditionsDynamic
          //       modalSetting={modalSetting}
          //       saveObjectCond={saveObjectCond}
          //       productSelectedQuotation={productSelectedQuotation}
          //       primaryCondForm={primaryCondForm}
          //       selectedObj={selectedObj}
          //       agrCond={agrCond}
          //       carForm={mainForm}
          //     />
          //   ),
          // },
        ]);
      }
    } else {
      setItems([
        {
          key: '1',
          label: 'Основное',
          children: (
            <ObjectCardMainDynamic
              agrIsn={agrIsn}
              dicti={dicti}
              saveObject={saveObject}
              AdvancedContractorSearch={AdvancedContractorSearch}
              modalSetting={modalSetting}
              mainForm={mainForm}
              selectedObj={selectedObj}
            />
          ),
        },
        // {
        //   key: '2',
        //   label: 'Первичные условия',
        //   children: (
        //     <PrimaryConditionsDynamic
        //       modalSetting={modalSetting}
        //       saveObjectCond={saveObjectCond}
        //       productSelectedQuotation={productSelectedQuotation}
        //       primaryCondForm={primaryCondForm}
        //       selectedObj={selectedObj}
        //       agrCond={agrCond}
        //       carForm={mainForm}
        //     />
        //   ),
        // },
      ]);
    }
  }, [selectedObj, dicti]);
  return (
    <Modal
      width={'1000px'}
      title={
        <span
          style={{
            color: '#7449ef',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          Добавить {modalSetting.item.full_name?.toLowerCase()}
        </span>
      }
      open={true}
      onCancel={closeObjectModal}
      footer={<></>}
    >
      {/* <CustomLoader spinning={isLoading || isLoadingDicti}> */}
      <Tabs defaultActiveKey="1" items={items} type="card" />
      {/* </CustomLoader> */}
    </Modal>
  );
};
export default ObjectCardModalDynamic;
