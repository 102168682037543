import { Checkbox, Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import './FolderItem.scss';

interface IPropsFolderItem {
  title: string;
  id: number;
  onClickFolder: () => void;
}

const FolderItem = ({ title, id, onClickFolder }: IPropsFolderItem) => {
  return (
    <Col xs={24} lg={6}>
      <Row className="item-folder-file-exchange" onClick={onClickFolder}>
        <Row className="d-flex justify-space-between width">
          <i className="main-folder-icon-file-exchange"/>
          <Checkbox value={id} onClick={(e) => e.stopPropagation()}/>
        </Row>
        <Row className="d-flex justify-space-between width">
          <Title level={5} className="title-folder-file-exchange">
            {title}
          </Title>
        </Row>
      </Row>
    </Col>
  )
};

export default FolderItem;