import { notification } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import api from '@modules/Quotation/services';

import {
  IAgrAttributeItem,
  IAgrClauseItem,
  IAgrCondItem,
  IAgrObjAttributeItem,
  IAgrObjCarGoItem,
  IAgrObjectCarItem,
  IAgrobjectItem,
} from '../../Interfaces/MainQuotation/index.interface';

type saveQuotationProps = {
  agrClauses: IAgrClauseItem[];
  agrObj: IAgrobjectItem[];
  agrAttributes: IAgrAttributeItem[];
  agrObjCar: IAgrObjectCarItem[];
  agrObjAttributes: IAgrObjAttributeItem[];
  agrCond: IAgrCondItem[];
  agrObjCarGo: IAgrObjCarGoItem[];
  clauseValueFilterPickLast: (agrClauses) => typeof agrClauses;
  objectValueFilterPickLast: (agrObj) => typeof agrObj;
  agrAttributesCheckEmptyOrNot: (agrAttributes) => typeof agrAttributes;
  objectCarFilterPickLast: (agrObjCar) => typeof agrObjCar;
  agrObjectAttributesCheckEmptyOrNot: (
    agrObjAttributes
  ) => typeof agrObjAttributes;
  agrCondFilterPickLast: (agrCond) => typeof agrCond;
  objectCargoFilterPickLast: (agrObjCarGo) => typeof agrObjCarGo;
  fileListSend: (fileList: any, userForeignId: number, agrIsn: any) => FormData;
  userForeignId: number;
  agrIsn: number | undefined;
  fileList: any;
  setIsLoadingSaveSections: Dispatch<SetStateAction<boolean>>;
};

type saveOtherSectionsProps = Pick<
  saveQuotationProps,
  | 'agrObjCar'
  | 'agrObjAttributes'
  | 'agrCond'
  | 'agrObjCarGo'
  | 'objectCarFilterPickLast'
  | 'agrObjectAttributesCheckEmptyOrNot'
  | 'agrCondFilterPickLast'
  | 'objectCargoFilterPickLast'
  | 'setIsLoadingSaveSections'
  | 'userForeignId'
  | 'agrIsn'
>;

/**
 * Отправляет все блоки на бэк
 * @param props
 */
export const saveQuotation = async (props: saveQuotationProps) => {
  const { agrClauses, agrObj, agrAttributes } = props;

  const {
    clauseValueFilterPickLast,
    objectValueFilterPickLast,
    agrAttributesCheckEmptyOrNot,
  } = props;

  const { agrObjCar, agrObjAttributes, agrCond, agrObjCarGo } = props;

  const {
    objectCarFilterPickLast,
    agrObjectAttributesCheckEmptyOrNot,
    agrCondFilterPickLast,
    objectCargoFilterPickLast,
  } = props;

  const { userForeignId, agrIsn, fileListSend, fileList } = props;

  const { setIsLoadingSaveSections } = props;

  const callSaveOtherSections = () => {
    saveOtherSections({
      agrObjCar,
      agrObjAttributes,
      agrCond,
      agrObjCarGo,
      objectCarFilterPickLast,
      agrObjectAttributesCheckEmptyOrNot,
      agrCondFilterPickLast,
      objectCargoFilterPickLast,
      setIsLoadingSaveSections,
      userForeignId,
      agrIsn,
    });
  };

  notification.info({
    message: 'Уведомление',
    description: 'Идет загрузка с сервера, ожидайте...',
    duration: 0,
    key: 'saveSections',
  });
  setIsLoadingSaveSections(true);
  Promise.all([
    api.saveQuotation.clauseSave(clauseValueFilterPickLast(agrClauses)),
    api.saveQuotation.objSave(objectValueFilterPickLast(agrObj)),
    agrAttributesCheckEmptyOrNot(agrAttributes).agr_attributes !== null
      ? api.saveQuotation.attributeSave(
          agrAttributesCheckEmptyOrNot(agrAttributes)
        )
      : null,
    fileList.length &&
      api.saveQuotation.fileSave(fileListSend(fileList, userForeignId, agrIsn)),
  ])
    .then(([agrClauses, agrObj, agrAttributes]) => {
      if (agrObj.data?.success === true) {
        callSaveOtherSections();
      }
    })
    .catch((error) => {
      notification.info({
        message: 'Ошибка',
        description: error.response.data.error,
        duration: 0,
      });

      notification.destroy('saveSections');
      setIsLoadingSaveSections(false);
    })
    .finally(() => {
      notification.destroy('saveSections');
    });
};

/**
 * Отправлеяе все разделы на бэк
 * @param props
 */
export const sendAllSectionsQuotation = async (props) => {
  const {
    agrObj,
    agrCond,
    setAgrCond,
    // agrClauses,
    stateDateQuotation,
    agrAttributes,
    setIsLoadingSendAllSectionsQuotation,
    productIsn
  } = props;

  const agrObjCond = {
    agr_obj: agrObj[0],
    agr_cond: agrCond[0],
  };
  setIsLoadingSendAllSectionsQuotation ? setIsLoadingSendAllSectionsQuotation(true) : null;
  
  Promise.all([
    stateDateQuotation ? api.quotation.saveCounterpartyInsurant(stateDateQuotation) : null, // Отправляем Формуляр
    agrAttributes ? api.saveQuotation.attributeSave(agrAttributes) : null, // Отправляем Раздел страховщика (доп. атрибуты)
    // api.saveQuotation.clauseSave(agrClauses), // Отправляем Ограничения/Оговорки
    
    productIsn === 471541 && agrObj.map(item => {agrCond.filter(elem => elem.obj_isn === item.isn && api.saveQuotation.objectCondSave({agr_obj: item, agr_cond: elem }))}),
    // })api.saveQuotation.objectCondSave({agr_obj: agrObj, agr_cond: agrCond}),
    // productIsn === 471541 &&  api.quotation.setAgrCond({agr_cond: agrCond}), // Отправляем Условия Объектов
    productIsn !== 471541 && api.saveQuotation.objectCondSave(agrObjCond), // Отправляем Объекты и Условия
  ])
    .then((values) => {
      // Вытаскиваем ответ по объектам чтобы проставить значение тарифа
      const agrCondItem = values.find((item) => item?.data?.agr_cond);
      if (agrCondItem) {
        const tariff = agrCondItem.data.agr_cond?.data[0]?.TARIFF;
        if (tariff) {
          setAgrCond([
            {
              ...agrCond[0],
              tariff: Number(tariff),
            },
          ]);
        }
      }
      /*
      notification.success({
        message: '',
        description: '',
        duration: 0,
      });
      */
    })
    .catch((error) => {
      notification.info({
        message: 'Ошибка',
        description: error.response.data.error,
        duration: 0,
      });

      notification.destroy('saveSections');
    })
    .finally(() => {
      notification.destroy('saveSections');
      setIsLoadingSendAllSectionsQuotation ? setIsLoadingSendAllSectionsQuotation(false) : null;
    });
};

const saveOtherSections = async (props: saveOtherSectionsProps) => {
  const { agrObjCar, agrObjAttributes, agrCond, agrObjCarGo, userForeignId, agrIsn, } = props;


  const {
    objectCarFilterPickLast,
    agrObjectAttributesCheckEmptyOrNot,
    agrCondFilterPickLast,
    objectCargoFilterPickLast,
  } = props;

  const { setIsLoadingSaveSections } = props;

  Promise.all([
    objectCarFilterPickLast(agrObjCar).agr_obj_car !== null
      ? api.saveQuotation.carSave(objectCarFilterPickLast(agrObjCar))
      : null,
    agrObjectAttributesCheckEmptyOrNot(agrObjAttributes).agr_obj_attributes !==
    null
      ? api.saveQuotation.attribute_obj_save(
          agrObjectAttributesCheckEmptyOrNot(agrObjAttributes)
        )
      : null,
    agrCondFilterPickLast(agrCond).agr_cond !== null
      ? api.quotation.setAgrCond(agrCondFilterPickLast(agrCond))
      : null,
    objectCargoFilterPickLast(agrObjCarGo).agr_obj_cargo !== null
      ? api.saveQuotation.cargoSave(objectCargoFilterPickLast(agrObjCarGo))
      : null,
    // api.quotation.setAgrCalculate({ user_isn: userForeignId, agr_isn: agrIsn }),
    /*
    api.saveQuotation.createAgrFromCalc({
      user_isn: userForeignId,
      agr_isn: agrIsn,
    }),
    */
  ])
    .then(() => {
      notification.success({
        message: 'Уведомление',
        description: 'Договор успешно рассчитан',
      });
    })
    .catch((error) => {
      notification.info({
        message: 'Ошибка',
        description: error.response.data.error,
        duration: 0,
      });
    })
    .finally(() => {
      notification.destroy('saveSections');
      setIsLoadingSaveSections(false);
    });
};
