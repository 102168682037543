import { notification } from 'antd';
import axios from 'axios';
import api from '../services/api';

export const getClientFileUrl = async (foreignId: number, agreementID: number) => {
  try {
    const { data } = await api.getClientFileUrl(foreignId, agreementID);
    return data
  } catch (error) {
    let message;

    if (axios.isAxiosError(error)) {
      message = error.message;
    } else {
      message = String(error);
    }

    notification.info({
      message: 'Ошибка',
      description: message,
    });
  }
};
