import { Col, Form, Row, Input } from 'antd';
import React from 'react';
import { fieldNamesRecruiting } from './fieldNames';

const { TextArea } = Input;

function CandidateFields(props) {
  return (
    <>
      <Row gutter={24} align="bottom">
        <Col span={24}>
          <Form.Item
            label="Личностные компетенции кандидата"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.candidates_trait}
          >
            <TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Этапы интервью с кандидатом в департаменте"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.interview_stage}
          >
            <TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default CandidateFields;
