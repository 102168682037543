import axios from '@services/api/axios';

const endpoints = {
  priceFromKolesa: (agrIsn: number) => axios.get(`contract/getKolesaPrice/${agrIsn}`),
  clauseSave: (data: any) => axios.post('/quotation/clause/save', data),
  objSave: (data: any) => axios.post('/quotation/agr_object/save', data),
  objectCondSave: (data: any) => axios.post('/quotation/object-cond', data),
  attributeSave: (data: any) => axios.post('/quotation/attribute/save', data),
  carSave: (data: any) => axios.post('/quotation/car/save', data),
  cargoSave: (data: any) => axios.post('/quotation/cargo/save', data),
  attribute_obj_save: (data: any) =>
    axios.post('/quotation/attribute_object/save', data),
  fileSave: (data: any) => axios.post('/save/kias/file',data),
  fileArchive: (data: any) => axios.post('/quotation/file/archive',data),
  fileDownload: (data: any) => axios.post('/quotation/file/get', data),
  fileQuotation: (file_path: any) => axios.get(`/quotation/file/show/${file_path}`),
  changeStatus: (data: any) => axios.post('/quotation/update-status', data),
  authorizeByBitrixUrl: (data: any) => axios.post('/quotation/bitrix', data),
  createAgrFromCalc: (data: any) => axios.post('/contract/createAgrFromCalc', data),
  getCarObjByIsn: (object_isn: any) => axios.get(`/quotation/object/${object_isn}`),
  saveObjCond: (data: any) => axios.post('/quotation/cond' , data),
  deleteObj : (data: any) => axios.post('/quotation/agr_object/delete' , data),
  sendNshd: (data: any) => axios.post('/kias/doc/nshd' , data),
  contractorFileArchive: (data: any) => axios.post('contractorCard/fileArchive', data),
  saveCard: (data: any) => axios.post('/quotation/cargo/setAgrObjCard', data)
};

export default endpoints;
