import { Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

function NoAccess() {
    return (
        <Title level={4} className="mt-10">Нету доступа</Title>
    );
}

export default NoAccess;