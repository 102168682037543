import { Button, Card, Divider, Form, Input, message, Typography } from 'antd';
import './Settings.scss';
import api from '@services/api/index';
import Cookies from 'js-cookie';
import { domain } from '@services/api/axios';
import React, { useEffect } from 'react';
import useAuth from '@hooks/useAuth';

const { Title } = Typography;
const Settings = () => {
  const auth = useAuth();
  const [formResetPassword] = Form.useForm();
  const token = Cookies.get('token', domain) || Cookies.get('tokenBitrix');

  const changePassword = async () => {
    const data = {
      username: formResetPassword.getFieldValue('username'),
      password: formResetPassword.getFieldValue('password'),
      confirmPassword: formResetPassword.getFieldValue('confirmPassword'),
      login: false,
    }

    await api.auth.registration({ ...data, token }).then((data) => {
      if (data.status !== 200) {
        message.error('Что-то пошло не так');
      } else {
        message.success(data.data.message);
        auth.logOut();
      }
    });
  };

  useEffect(() => {
    formResetPassword.setFieldValue('username', auth?.user?.data?.email?.split('@')[0]);
  }, [auth]);


  return (
    <Card>
      <Divider orientation="left">
        <Title className="mb-0" level={4}>
          Сменить пароль
        </Title>
      </Divider>
      <Form
        form={formResetPassword}
        layout="vertical"
        className="form-settings"
        onFinish={changePassword}
      >
        <Form.Item
          label="Логин"
          name="username"
          rules={[{ required: true }]}
        >
          <Input size="large" disabled={true}/>
        </Form.Item>
        <Form.Item
          label="Введите новый пароль"
          name="password"
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                if (
                  !value ||
                  value.match(
                    /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
                  )
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    'Пароль должен состоять из восьми или более символов латинского алфавита, содержать заглавные и строчные буквы, цифры'
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password size="large"/>
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Подтвердите пароль"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Подтверждение пароля не совпадает')
                );
              },
            }),
          ]}
        >
          <Input.Password size="large"/>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
          >
            Изменить пароль
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
};

export default Settings;