import { IRowColumnEditor } from '@modules/Edo/types';

import { HandbookListType } from '../../../../hooks/useHandbook';
import { Form, Select } from 'antd';
import { useEffect, useState } from 'react';
import { IUser } from './FieldUser';

const { Option } = Select;
interface IDepartmentUser {
  settings: IRowColumnEditor;
  handbookList: HandbookListType;
  name: number;
  isDisabledForm: boolean;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}
function DutyUser(props: IDepartmentUser) {
  const [user, setUser] = useState<IUser | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    settings,
    handbookList,
    name,
    isDisabledForm = false,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;

  const form = Form.useFormInstance();

  const isRowColumnEditor = typeof name === 'number';

  const propertyValue = Form.useWatch(['row_column_editor', name], form);
  const onChangeDuty = (value) => {
    let duty_name = handbookList.filter((item) => item.id == value)[0];
    if (isRowColumnEditor) {
      const { row_column_editor } = form.getFieldsValue();
      Object.assign(row_column_editor[name][`id-${settings.id}`], {
        duty: duty_name.full_name,
        num_value: value,
      });
      form.setFieldsValue({ row_column_editor });
    }
  };
  useEffect(() => {
    if (propertyValue) {
      const formValues = Object.values(propertyValue);
      formValues.map((element: any) => {
        if (
          element.display_no + 2 === settings.display_no &&
          element.type == 'DEPTEMPL'
        ) {
          setUser(element);
          setIsDisabled(true);
        }
        // else if (element.type == 'SUBJECT') {
        //   setUser(element);
        // }
      });
    }
  }, [propertyValue]);

  useEffect(() => {
    if (user) {
      if (isRowColumnEditor) {
        const { row_column_editor } = form.getFieldsValue();
        if (
          row_column_editor[name][`id-${settings.id}`] &&
          user?.display_no == settings.display_no - 2 &&
          user.type == 'DEPTEMPL' &&
          user.duty_id
        ) {
          Object.assign(row_column_editor[name][`id-${settings.id}`], {
            duty: user.duty,
            duty_id: user.duty_id,
            num_value: user.duty_id,
          });
          // if (isDisabledStatus && isAdmin && onChangeByTd) {
          //   onChangeByTd(settings.id, name);
          // }
        } else if (
          row_column_editor[name][`id-${settings.id}`] &&
          user.duty_id
        ) {
          Object.assign(row_column_editor[name][`id-${settings.id}`], {
            duty: user.duty,
            duty_id: user.duty_id,
            num_value: user.duty_id,
          });
        } else if (row_column_editor[name][`id-${settings.id}`] && !user.duty) {
          Object.assign(row_column_editor[name][`id-${settings.id}`], {
            duty: null,
            // duty_id: undefined,
            // num_value: undefined,
          });
        }
        // form.setFieldsValue({
        //   [`id-${settings.id}`]: {
        //     duty_id: user.duty_id,
        //     duty: user.duty,
        //     num_value: user.duty_id,
        //   },
        // });

        form.setFieldsValue({ row_column_editor });
      } else {
        form.setFieldsValue({
          [`id-${settings.id}`]: {
            duty_id: user.duty_id,
            duty: user.duty,
            num_value: user.duty_id,
          },
        });
      }
    }
  }, [user]);

  return (
    <Form.Item
      label={settings.field_name}
      name={
        isRowColumnEditor
          ? [name, `id-${settings.id}`, 'duty']
          : [`id-${settings.id}`, 'duty']
      }
      rules={[{ required: settings.required }]}
    >
      <Select
        options={handbookList}
        showSearch={true}
        style={{ width: '100%' }}
        fieldNames={{ label: 'full_name', value: 'id' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        onChange={onChangeDuty}
        allowClear={true}
        disabled={isDisabledForm ? isDisabledForm : isDisabled}
      />
    </Form.Item>
  );
}

export default DutyUser;
