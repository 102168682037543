import axios from "@services/api/axios";

const endpoints = {
    searchCar: (data) => axios.post('/search/car' , data),
    getDictiCarAuto: () => axios.get('/dicti/card/auto'),
    getDictiCarCategory: () => axios.get('/dicti/card/category'),
    getDictiCarOwner: () => axios.get('/dicti/card/owner'),
    getDictiCarPurpose: () => axios.get('/dicti/card/purpose'),
    getDictiCarColor: () => axios.get('/dicti/card/color'),
    getDictiCarOwnerShip: () => axios.get('/dicti/card/ownership'),
    getDictiCarCountry: () => axios.get('/dicti/card/country'),
    getDictiByIsn: (data) => axios.post(`/dictiByIsn`, data),
    getFirstDictiByIsn: (isn) => axios.get(`/dictiFirstByIsn/${isn}`),
}

export default endpoints;