import { Col, Form, Row, Card } from 'antd';
import React, { useEffect } from 'react';
import DynamicFields from '../DynamicFieldsAttributes';
import useQuotation from '../hooks/useQuotation';

type UnderWriterFieldsProps = {
  userForeignId: number | undefined;
  agrIsn: number | undefined;
  dicti: any;
};

function UnderWriterFields({
  userForeignId,
  agrIsn,
  dicti,
}: UnderWriterFieldsProps) {
  const {
    productSelectedQuotation,
    setAgrAttributes,
    setAgrAttributesUnder,
    agrAttributes,
    agrAttributesUnder,
  } = useQuotation();
  const additionalAttributesForm = Form.useFormInstance();

  useEffect(() => {
    if (agrAttributesUnder) {
      agrAttributesUnder.map((item) => {
        if (item.attr_isn) {
          additionalAttributesForm.setFieldValue(item.attr_isn, item.value);
        }
      });
    }
  }, [agrAttributesUnder]);
  return (
    <Card>
      <Row gutter={24} align="bottom" justify={{ lg: 'start' }}>
        <React.Fragment key="underWriter">
          {dicti &&
            productSelectedQuotation[0]['attributes'][1].map((ele) => (
              <Col span={8} key={ele.attr_isn} xs={24} xl={8}>
                <DynamicFields
                  type={ele.attr_type.toLowerCase()}
                  name={ele.attr_isn}
                  setAgrAttributes={setAgrAttributes}
                  agrAttributes={agrAttributes}
                  data={ele}
                  userForeignId={userForeignId}
                  agrIsn={agrIsn}
                  label={ele.attr_name}
                  required={ele.required}
                  form={additionalAttributesForm}
                  disabled={true}
                  dicti={dicti[ele.attr_isn]}
                />
              </Col>
            ))}
        </React.Fragment>
      </Row>
    </Card>
  );
}

export default UnderWriterFields;
