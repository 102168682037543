import { Col, DatePicker, Form, Input, Select } from 'antd';
import React from 'react';
import { fieldNamesRecruiting } from '../Common/fieldNames';
import SubmitButton from '../Common/SubmitButton';
import useRecruiting from '../hooks/useRecruiting';

const { Option } = Select;
const { TextArea } = Input;

function ResultInterviewFields({
  hasPermission,
  hasSecurityPermission,
  hasDirPermission,
}) {
  const { state } = useRecruiting();

  const { security_service_result } = state.selectValues;

  const showSubmitButton = () => {
    return hasPermission === true || hasDirPermission === true ? (
      <SubmitButton buttonText="Сохранить" />
    ) : null;
  };

  const showSubmitButtonSecurity = () => {
    return hasSecurityPermission === true ? (
      <SubmitButton buttonText="Сохранить" />
    ) : null;
  };

  return (
    <>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.interview_result !== curValues.interview_result
        }
        noStyle
      >
        {({ getFieldValue }) => {
          const interviewResultValue = getFieldValue(
            fieldNamesRecruiting.interview_result
          );

          if (interviewResultValue === 5961) {
            return (
              <>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label="Дата заключения ДОУ"
                    name={fieldNamesRecruiting.date_of_conclusion_dou}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      name="date"
                      onChange={(e, date) => {}}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Комментарий"
                    name={fieldNamesRecruiting.commentary}
                  >
                    <TextArea autoSize={{ minRows: 3 }} />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={{ span: 8, offset: 16 }}>
                  <Form.Item label=" ">{showSubmitButton()}</Form.Item>
                </Col>
              </>
            );
          } else if (interviewResultValue === 5959) {
            return (
              <Col xs={24} lg={{ span: 8 }}>
                <Form.Item label=" ">{showSubmitButton()}</Form.Item>
              </Col>
            );
          } else if (interviewResultValue === 5960) {
            return (
              <>
                <Col span={24}>
                  <Form.Item
                    label="Комментарий"
                    name={fieldNamesRecruiting.commentary}
                  >
                    <TextArea autoSize={{ minRows: 3 }} />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={{ span: 8, offset: 16 }}>
                  <Form.Item label=" ">{showSubmitButton()}</Form.Item>
                </Col>
              </>
            );
          } else if (interviewResultValue === 7671) {
            return (
              <Col xs={24} lg={8}>
                <Form.Item label=" ">{showSubmitButton()}</Form.Item>
              </Col>
            );
          } else if (interviewResultValue === 7672) {
            return (
              <>
                <Col xs={24} xl={8}>
                  <Form.Item
                    name={fieldNamesRecruiting.security_service_result}
                    label="Результат проверки СБ"
                  >
                    <Select>
                      {security_service_result.length &&
                        security_service_result.map((element) => (
                          <Option value={element.id} key={element.id}>
                            {element.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Комментарий"
                    name={fieldNamesRecruiting.commentary}
                  >
                    <TextArea autoSize={{ minRows: 3 }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Комментарий СБ"
                    name={fieldNamesRecruiting.security_service_comment}
                  >
                    <TextArea autoSize={{ minRows: 3 }} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item label=" ">{showSubmitButton() || showSubmitButtonSecurity()}</Form.Item>
                </Col>
              </>
            );
          } else if (interviewResultValue === 7673) {
            return (
              <Col xs={24} lg={8}>
                <Form.Item label=" ">{showSubmitButton()}</Form.Item>
              </Col>
            );
          } 
        }}
      </Form.Item>
    </>
  );
}

export default ResultInterviewFields;
