import { FileTwoTone } from '@ant-design/icons';
import {
  IDocspict,
  IDynamicTable,
} from '@modules/common/DynamicTable/data.type';
import api from '@services/api';
import { Row, Col, Space, Button, Typography } from 'antd';
import { useState } from 'react';

interface Props {
  agreementDocument: IDynamicTable | null;
}

const { Title } = Typography;

function KiasFiles(props: Props) {
  const { agreementDocument } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const installFile = async (file: IDocspict) => {
    setIsLoading(true);
    const payloadData = {
      file_path: file.file_path,
      file_name: file.file_name,
    };

    const { data } = await api.doc.installFile(payloadData);
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', file.file_name);
    link.click();
    link.remove();
    setIsLoading(false);
  };

  return (
    <Row className="mb-5">
      <Col span="24">
        <Title level={5} type="secondary" className="mb-5">
          Вложенные документы к ЛС
        </Title>
        <Space direction="vertical">
          {agreementDocument &&
            agreementDocument?.Docs?.docspict?.length > 0 &&
            agreementDocument?.Docs?.docspict?.map((element: IDocspict) => (
              <Button
                target="_blank"
                key={element.file_name}
                type="text"
                icon={<FileTwoTone />}
                onClick={() => installFile(element)}
                disabled={isLoading}
              >
                {element.file_name}
              </Button>
            ))}
        </Space>
      </Col>
    </Row>
  );
}

export default KiasFiles;
