import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  TreeSelect,
  notification,
  Table,
} from 'antd';
import axios from 'axios';

import './Quotation.scss';
import { useNavigate } from 'react-router-dom';
import useColleagues, { Colleagues } from '../../hooks/useColleagues';
import useAuth from '../../hooks/useAuth';
import api from '@modules/Quotation/services';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

interface Express {
  title: string;
}
export interface IProductList {
  id: number;
  full_name: string;
  product_id: string;
}

export interface IProduct {
  id: any;
  product_id: any;
  company_id: any;
  full_name: any;
  short_name: any;
  ordinal: any;
  updated_by: any;
  created_by: 1;
  data: IProductItem[];
}

export interface IProductItem {
  filter(arg0: (item: any) => boolean);
  attr_isn: number;
  attr_name: String;
  company_id: String;
  product_id: number;
  active: number;
  attr_type: String;
  readonly: number;
  def_vald: String;
  def_valc: String;
  def_valn: number;
  def_sql_text: String;
  created_by: number;
  updated_by: number;
  text_code: String;
  required: boolean;
  child: IProductItemChild[];
}

export interface IProductItemChild {
  foreign_id: number;
  parent_foreign_id: number;
  full_name: String;
  short_name: String;
  parent_id: number;
  has_child: number;
  product_id: number;
  param_attr_isn: number;
  company_id: String;
  created_by: number;
  updated_by: number;
  active: number;
  num_code: number;
  text_code: String;
}

interface ISearchExpress {
  id: number;
  user: object;
  // subj_isn: number;
  // department_id: number;
  company_id: number;
  // class_id: number;
  product_id: number;
  quotation_id: string;
  // premium_sum: string;
  date_calc: string;
  // tariff: string;
  // created_at: string;
  // updated_at: string;
  // created_by: number;
  // updated_by: number;
  // agr_isn: null;
  // data: object;
  // front_node_id: null;
  // id: 22112;
  // premium_sum: "0";
  // prev_isn: null;
  // product_id: 471571;
  // quotation_id: "00021046";
  // remark: null;
  // subj_isn: null;
  // system_id: "2647221";
  // tariff: "0";
  // tariff_str: null;
  // updated_at: "2022-07-20T02:43:46.000000Z";
  // updated_by: 1287408;
  // user_id: 1287408  ;
}
export interface IProductList {
  key: number;
  full_name: string;
  foreign_id: string;
  product_id: string;
}
const { Title } = Typography;
const { Option } = Select;
const { TreeNode } = TreeSelect;

const ExpressQuotation: React.FC<Express> = ({ title = '' }) => {
  //   const [addProduct, setAddProduct] = useState(true);
  const [filterProduct, setFilterProduct] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expressIsn, setExpressIsn] = useState();
  const [searchData, setSearchData] = useState({});
  const [fetchData, setFetchData] = useState<ISearchExpress[] | undefined>();
  const [, forceUpdate] = useState({});
  const auth = useAuth();
  const { colleaguesList, isLoadingColleaguesList } = useColleagues();
  const [product, setProduct] = useState<IProduct | undefined | any>(undefined);
  const [productAttributes, setProductAttributes] = useState<IProductItem[]>(
    []
  );
  const [selectedProductId, setSelectedProductId] = useState<
    IProduct[] | undefined
  >([]);
  // const [expressQuotation, setExpressQuotation] = useState<
  //   IEmptyQuotationItem | undefined
  // >(undefined);
  const navigate = useNavigate();
  const renderColleguesTreeNodes = (item: Colleagues, selectable) => {
    return (
      <TreeNode
        value={
          item.full_name + ' id:' + item.id + ' foreign_id:' + item.foreign_id
        }
        title={item.full_name}
        selectable={!selectable}
      >
        {item.users.map((item) => (
          <TreeNode
            value={
              item.full_name +
              ' id:' +
              item.id +
              ' foreign_id:' +
              item.foreign_id
            }
            title={item.full_name}
            selectable={selectable}
          />
        ))}
        {item.child.map((item) => renderColleguesTreeNodes(item, selectable))}
      </TreeNode>
    );
  };
  const { Title } = Typography;
  const goToExpress = async (record) => {
    if (record !== undefined) {
      try {
        const response = await api.expressQuotation.getSelectedExpressQuotation(
          record.id
        );
        // setExpressQuotation(response.data);
        const responseProduct =
          await api.expressQuotation.getSelectedExpressProduct(
            response.data.product_id
          );
        setProduct(responseProduct.data?.data);
        // setProduct(response.data);
        // navigate(`/express-quotation/${selectedProductId}`);
        setProductAttributes(responseProduct?.data?.data?.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
      // setShowQuotation(true);
      navigate(`/express-quotation/` + record.id);
    }
  };
  const columns = [
    {
      width: '15%',
      title: () => {
        return <span style={{ color: '#815CED' }}>№</span>;
      },
      dataIndex: 'quotation_id',
      key: 'quotation_id',
    },
    {
      width: '15%',
      title: () => {
        return <span style={{ color: '#815CED' }}>Куратор</span>;
      },
      dataIndex: 'user',
      key: 'user',
    },
    {
      width: '20%',
      title: () => {
        return <span style={{ color: '#815CED' }}>Продукт</span>;
      },
      dataIndex: 'product_name',
    },
    {
      width: '25%',
      title: () => {
        return <span style={{ color: '#815CED' }}>ИСН</span>;
      },
      dataIndex: 'id',
      key: 'id',
    },
    {
      width: '25%',
      title: () => {
        return <span style={{ color: '#815CED' }}>Дата подписания</span>;
      },
      dataIndex: 'date_calc',
    },
    {
      render: (_: any, record: ISearchExpress) =>
        fetchData ? (
          fetchData.length >= 1 ? (
            <span>
              <Button onClick={() => goToExpress(record)}>Перейти</Button>
              {/* <Popconfirm
                title="Перейти?"
                cancelText="Отмена"
                onConfirm={() => goToExpress(record)}
              > */}
              {/* <a>Перейти</a> */}
              {/* </Popconfirm> */}
            </span>
          ) : null
        ) : (
          ''
        ),
    },
  ];

  const [formCreate] = Form.useForm();
  const [formShow] = Form.useForm();
  formShow.setFieldsValue({
    user_id:
      auth.user?.data?.user_info?.full_name +
      ' id:' +
      auth.user?.data?.user_info?.id +
      ' foreign_id:' +
      auth.user?.data?.user_info?.foreign_id,
  });

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const FilterProductSearch = async (values) => {
    setFetchData([]);
    setFilterProduct(false);
    setSearchData(values);
    try {
      setIsLoading(true);
      if (values.user_id) {
        var foreign_id = values.user_id.split('foreign_id:');
      }
      if (values.product_id) {
        var product_id = values.product_id.split(':');
      }

      const responseSearch = {
        quotation_id: values.quotation_id ? values.quotation_id : '',
        date_beg: values.date_begin ? values.date_begin : '',
        date_end: values.date_end ? values.date_end : '',
        user_id: values.user_id ? foreign_id[1] : '',
        product_id: values.product_id ? product_id[1] : '',
      };
      const response = await api.expressQuotation.searchExpressQuotation(
        responseSearch
      );
      if (response.data.data.length === 0) {
        notification.info({
          message: 'Ошибка',
          description: 'Данные не найдены!',
        });
      }
      if (response.data.data.length > 0) {
        setFetchData(JSON.parse(JSON.stringify(response.data.data)));
        setFilterProduct(true);
      }
      // setFetchData((response.data.data));
    } catch (error) {
      let message;
      // if (error?aut === 401) {
      //   navigate("/login");
      // }
      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fetchData === undefined) {
      return;
    }
  }, [fetchData]);

  useEffect(() => {
    if (searchData == undefined) return;
  }, [searchData]);

  const SearchExpress = () => {
    console.log();
  };
  const [productList, setProductList] = useState<IProductList[]>();

  useEffect(() => {
    if (productList !== undefined) {
      return;
    }
    (async () => {
      setIsLoading(true);
      try {
        const responseList =
          await api.expressQuotation.getListExpressQuotation();
        setProductList(responseList.data.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [productList]);

  const createQuotation = () => {
    // navigate(`/express-quotation/product`);
    return setIsShowForm(true);
  };
  const newExpressQuotation = async (values) => {
    const responseData = {
      user_isn: auth.user?.data?.user_info?.foreign_id,
      product_isn: values.productSelectMain,
      connection: 'centras',
    };
    // if (expressQuotation !== undefined) {
    try {
      if (values.productSelectMain) {
        const response = await api.expressQuotation.newExpress(responseData);
        navigate(`/express-quotation/${response.data?.data?.id}`);
      }
      // setExpressQuotation(response.data.data);
    } catch (error) {
      let message;
      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
    }
    // }
  };
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       "selectedRows: ",
  //       selectedRows
  //     );
  //   },
  // };

  return (
    <>
      {/* {showQuotation === false ? ( */}
      <>
        <Card className="quotation-page__card">
          <Title
            style={{ color: '#0057FF', marginTop: 20, marginBottom: 20 }}
            level={4}
          >
            Создать экспресс котировку
          </Title>
          <Form
            form={formCreate}
            onFinish={newExpressQuotation}
            name="basic"
            layout="vertical"
            className="c-form"
          >
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <CustomLoader spinning={isLoading}>
                  <Form.Item
                    name="productSelectMain"
                    label="Продукт"
                    rules={[{ required: true, message: 'Выберите продукт!' }]}
                  >
                    <Select
                      placeholder="Выберите продукт"
                      onSelect={(el) => {
                        setSelectedProductId(el);
                      }}
                    >
                      {productList?.map((el) => (
                        <Option
                          key={el.product_id}
                          value={el.product_id}
                          product={el}
                        >
                          {el.full_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </CustomLoader>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  style={{ marginTop: 30 }}
                  loading={isLoading}
                  onClick={createQuotation}
                >
                  Создать котировку
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        <Row>
          <Col span={24}>
            <Card className="quotation-page__card">
              <Title
                style={{ color: '#0057FF' }}
                level={4}
                className="quotation-page__title"
              >
                Журнал экспресс котировок
              </Title>
              <Form
                form={formShow}
                onFinish={FilterProductSearch}
                name="basic"
                layout="vertical"
              >
                <Row gutter={[32, 32]}>
                  <Col span={6}>
                    <Form.Item label="Номер котировки" name="quotation_id">
                      <Input placeholder="Введите номер котировки" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Дата с" name="date_begin">
                      <DatePicker
                        format={'DD.MM.YYYY'}
                        style={{ width: '100%' }}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Дата по" name="date_end">
                      <DatePicker
                        format={'DD.MM.YYYY'}
                        style={{ width: '100%' }}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 32]}>
                  <Col span={6}>
                    <Form.Item
                      name={'user_id'}
                      label={`Куратор`}
                      // initialValue={
                      // }
                    >
                      <TreeSelect
                        style={{ width: '100%' }}
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: 'auto',
                        }}
                        treeLine={{ showLeafIcon: false }}
                        allowClear={true}
                        disabled={isLoadingColleaguesList}
                        showSearch={true}
                        dropdownMatchSelectWidth={650}
                        treeDataSimpleMode={{ pId: 'parent_id' }}
                      >
                        {colleaguesList.map((item) =>
                          renderColleguesTreeNodes(item, true)
                        )}
                      </TreeSelect>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Страховой продукт" name="product_id">
                      <Select placeholder="Выберите продукт">
                        {productList?.map((el) => (
                          <Option
                            value={
                              el.full_name + ' ' + 'product_id:' + el.product_id
                            }
                          >
                            {el.full_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginTop: 30 }}
                        block
                        disabled={isLoading}
                        onClick={SearchExpress}
                      >
                        Поиск
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
              </Form>
            </Card>
          </Col>
        </Row>
        {filterProduct && (
          <Row>
            <Col span={24}>
              <Card
                style={{
                  marginTop: 30,
                  marginBottom: 400,
                }}
              >
                <Title style={{ color: '#0057FF' }} level={4}>
                  Результаты
                </Title>
                <Divider />
                <Table
                  columns={columns}
                  dataSource={fetchData}
                  // rowSelection={{
                  //   ...rowSelection,
                  // }}
                  loading={isLoading}
                />
              </Card>
            </Col>
          </Row>
        )}
      </>
    </>
  );
};

export default ExpressQuotation;
