import { Button, Checkbox, CheckboxProps, Empty, notification, Table } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import EdoSearchModal from '@modules/Edo/EdoSearch/EdoSearchModal';
import api from '@modules/Edo/services/api';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { ContextType } from '@pages/Edo';


const ShowInvestDocuments = ({ data }) => {
	const { docEdo } = useOutletContext<ContextType>();
	const [isVisibleModal, setIsVisibleModal] = useState(false);
	const [modalData, setModalData] = useState();
	const [checkedDocuments, setCheckedDocuments] = useState<number[]>([]);

	const onChange: CheckboxProps['onChange'] = (e) => {
		setCheckedDocuments((prevState) => {
			const newValue = e.target.value;
			if (prevState.includes(newValue)) {
				return prevState.filter(id => id !== newValue);
			} else {
				return [...prevState, newValue];
			}
		});
	};

	const columns = [
		{
			width: '20%',
			title: () => {
				return <span>Тип документа</span>;
			},
			dataIndex: 'class',
			key: 'class',
			render: (_, record) => (
				<Button
					type="link"
					href={`/document/${record.id.toString()}`}
					target="_blank"
					style={{ fontSize: '12px' }}
				>
					{record.class?.full_name}
				</Button>
			),
		},
		{
			width: '20%',
			dataIndex: 'conc',
			key: 'conc',
			title: () => {
				return <span>Номер документа</span>;
			},
			render: (text, record) => {
				return text ? text : record.document_id;
			},

		},
		{
			width: '15%',
			dataIndex: 'date',
			key: 'date',
			title: () => {
				return <span>Дата</span>;
			},
			render: (_, record) =>
				dayjs(record.date).isValid()
					? dayjs(record.date).format('DD.MM.YYYY')
					: '',
		},
		{
			width: '15%',
			dataIndex: 'status',
			key: 'status',
			title: () => {
				return <span>Статус</span>;
			},
			render: (_, record) => record.stage?.name,
		},
		{
			width: '15%',
			dataIndex: 'ACTION_DOC',
			key: 'ACTION_DOC',
			title: () => {
				return <span>Действия</span>;
			},
			render: (_, record) =>
				record.coordination.length > 0 ? (
					<a onClick={(e) => showModal(record)}>Согласующие</a>
				) : (
					''
				),
		},
		{
			width: '30%',
			dataIndex: 'ADD_DOC',
			key: 'ADD_DOC',
			title: () => {
				return <span>Выбор инвест. решения</span>;
			},
			render: (_, record) =>
				<Checkbox value={record.id} onChange={onChange} key={record.id}/>
		}
	];

	const handleOk = () => {
		setIsVisibleModal(false);
	};
	const handleCancel = () => {
		setIsVisibleModal(false);
		setCheckedDocuments([]);
	};
	const showModal = (data) => {
		setIsVisibleModal(true);
		setModalData(data);
	};

	const addSecuritiesDocument = async () => {
		try {
			const requestData = {
				document_ids: checkedDocuments,
				id: docEdo.data.id,
				order_no: 1,
				group_column: 1,
			};

			await api.createSecuritiesDocuments(requestData);

			notification.info({
				message: 'Информация',
				description: 'Вы успешно добавили привязку инвестиционных решений к документу',
			});

			// window.location.reload();
		} catch (error) {
			let message;
			if (axios.isAxiosError(error) && error.response) {
				message = error.response.data.message;
			} else {
				message = String(error);
			}
			notification.info({
				message: 'Ошибка',
				description: message,
			});
		}
	};

	return (
		<>
			<Table
				columns={columns}
				dataSource={data}
				rowKey="ID"
				locale={{
					emptyText: (
						<Empty description="Не найдены инвест решения с выбранными фильтрами"/>
					),
				}}
				className="c-table"
				scroll={{ x: 1000 }}
			/>
			<Button
				type="primary"
				className="mt-5"
				onClick={addSecuritiesDocument}
			>
				Добавить инвест. решения к документу
			</Button>
			<EdoSearchModal
				visible={isVisibleModal}
				handleOk={handleOk}
				handleCancel={handleCancel}
				data={modalData}
			/>
		</>
	)
};

export default ShowInvestDocuments;