import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Row, Col, Input, Form, Button, Upload } from 'antd';
import React from 'react';
import { fieldNamesRecruiting } from '../Common/fieldNames';
import { normFile } from '../Common/normFile';
import SubmitButton from '../Common/SubmitButton';
import useRecruiting from '../hooks/useRecruiting';
import type { UploadProps } from 'antd';
import '../Common/UploadFile.scss';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import api from '../services/api';

type CandidateFieldsProps = {
  hasPermission?: boolean;
  fileName?: string;
  candidateId?: number;
  recruitingId?: number;
  fieldIINDisabled?: boolean;
};

function CandidateFields({
  hasPermission,
  fileName,
  candidateId,
  recruitingId,
  fieldIINDisabled,
}: CandidateFieldsProps) {
  const { departmentList } = useRecruiting();

  const installFile = async () => {
    const payloadData = {
      id: candidateId,
      recruiting_id: recruitingId,
      fileName: fileName,
    };

    const { data } = await api.getFile(payloadData);
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `Резюме ${fileName}`);
    link.click();
    link.remove();
  };

  const props: UploadProps = {
    maxCount: 1,
    beforeUpload() {
      return false;
    },
    iconRender(file) {
      if (fileName) {
        return <DownloadOutlined onClick={installFile} />;
      }
    },
  };

  return (
    <>
      <Row gutter={24} align="top" justify={{ lg: 'start' }}>
        <Col xs={24} xl={8}>
          <Form.Item
            label="ФИО кандидата"
            name={fieldNamesRecruiting.candidate_fullname}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} lg={{ span: 8 }}>
          <Form.Item
            label="Структурное подразделение"
            name={fieldNamesRecruiting.department_to}
          >
            <TreeSelectCollegues
              colleaguesList={departmentList}
              selectDepartment={true}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={{ span: 8 }}>
          <Form.Item
            label="Контактный номер кандидата"
            name={fieldNamesRecruiting.candidate_phone_number}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} lg={{ span: 8 }}>
          <div className="recruiting-file-overwrite">
            <Form.Item
              label="Резюме кандидата"
              name={fieldNamesRecruiting.file}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload {...props}>
                <Button block icon={<UploadOutlined />}>
                  Загрузить файл
                </Button>
              </Upload>
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} lg={{ span: 8 }}>
          <Form.Item label="ИИН" name={fieldNamesRecruiting.candidate_iin}>
            <Input disabled={!fieldIINDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          {hasPermission === true ? (
            <Form.Item label=" ">
              <SubmitButton buttonText="Сохранить" />
            </Form.Item>
          ) : null}
        </Col>
      </Row>
    </>
  );
}

export default CandidateFields;
