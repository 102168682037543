import { useEffect, useState } from 'react';
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  CloseCircleTwoTone,
  FileTwoTone,
} from '@ant-design/icons';
import { Col, Form, Input, Row, Table, Typography, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ApprovementForm from './ApprovementForm';
import { ColleaguesListType } from '../../hooks/useColleagues';
import { IUserGroup, IUser, CoordinationType } from '@modules/Agreement/type';
import AgreementModalFiles from '@modules/Agreement/AgreementModal/AgreementModalFiles';
import dayjs from 'dayjs';
import api from '@modules/Agreement/services/api';

const { Title } = Typography;

interface IFile {
  file_path: string;
  id: string | number;
  file_name: string;
}

interface ApproversProps {
  colleaguesList?: ColleaguesListType;
  userList: CoordinationType | any;
  isViewTable?: boolean;
  documentID?: number | string;
  documentApproversForm?: any;
  onChangeApprovers?: () => void;
  readonly?: boolean;
  classConstant?: string;
}

enum UserProps {
  EMPLOYEE = 'employee',
  SHORT_NAME = 'short_name',
  SOLUTION = 'solution',
  REMARK = 'remark',
  FILES = 'files',
  FULL_NAME = 'full_name',
  DEPARTMENT = 'department',
  DATE_SOLUTION = 'date_solution',
  DUTY = 'duty',
  ID = 'id',
  NAME = 'name',
  KEY = 'key',
}

const columns: ColumnsType<IUser> = [
  {
    title: 'Этап',
    render(text) {
      if (text.type_name) {
        return <span>{text.type_name}</span>;
      } else if (text.name) {
        return <span>{text.name}</span>;
      } else {
        return <span>Согласующий</span>;
      }
    },
  },
  {
    title: 'Этап 2',
    render(data) {
      if (data?.step) {
        return <span>{data?.step}</span>;
      } else if (data.queue) {
        return <span>{data.queue + 1}</span>;
      } else {
        return <span></span>;
      }
    },
  },
  {
    title: 'ФИО',
    render(data) {
      if (data?.full_name) {
        return <span>{data?.full_name}</span>;
      } else {
        return <span>{data.employee.short_name}</span>;
      }
    },
  },
  {
    title: 'Виза',
    dataIndex: UserProps.SOLUTION,
    render: (solution: string) => {
      switch (parseInt(solution, 10)) {
        case 1:
          return <CheckCircleTwoTone twoToneColor="#38C43E" />;
        case 0:
          return <CloseCircleTwoTone twoToneColor="#EB5757" />;
        default:
          return <ClockCircleTwoTone />;
      }
    },
  },
  Table.EXPAND_COLUMN,
  {
    title: 'Должность',
    render(data) {
      if (data?.duty) {
        return <span>{data?.duty}</span>;
      } else {
        return <span>{data.employee.duty}</span>;
      }
    },
  },
  {
    title: 'Подразделение',
    render(data) {
      if (data?.department) {
        return <span>{data?.department}</span>;
      } else {
        return <span>{data.employee?.department}</span>;
      }
    },
  },
  {
    title: 'Дата',
    dataIndex: UserProps.DATE_SOLUTION,
    render: (text: Date) =>
      dayjs(text).isValid() ? dayjs(text).format('DD.MM.YYYY') : null,
  },
];

function Approvers({
  userList = [],
  isViewTable = true,
  documentID,
  colleaguesList,
  onChangeApprovers = () => {},
  documentApproversForm,
  readonly,
  classConstant
}: ApproversProps) {
  const [state, setState] = useState<any[]>([]);
  const [signatoryUsers, setSignatoryUsers] = useState<any[]>([]);
  const isFromMycent = (users: any): users is IUserGroup[] => {
    return users.length > 0 && users[0]?.full_name;
  };

  const installFile = async (file_name) => {
    const payloadData = {
      document_id: documentID,
      file_name,
    };

    const { data } = await api.getAgreementFile(payloadData);
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${file_name}`);
    link.click();
    link.remove();
  };

  useEffect(() => {
    if (isFromMycent(userList)) {
      const data: any = {};
      const tableData: any[] = [];
      const signatory: any[] = [];
      let signatoryData: any = {};
      const bypass: any[] = [];
      const hr: any[] = [];
      const destination: any[] = [];
      const newUserList = userList.map((item, key) => {
        if (isViewTable) {
          return item;
        } else {
          if (item.type == 'signatory') {
            signatory.push(item);
          } else if (item.type == 'bypass') {
            tableData.push(item);
          } else if (item.type == 'hr') {
            tableData.push(item);
          } else if (item.type == 'destination') {
            tableData.push(item);
          }
        }
      });
      if (isViewTable) {
        setState(userList);
      } else {
        setSignatoryUsers(signatory);
      }
    } else {
      setState([...userList]);
    }
  }, [userList]);

  useEffect(() => {
    if (documentID && documentApproversForm) {
      documentApproversForm.setFieldValue('approvers_document_id', documentID);
    }
  }, [documentID]);

  /**
   * Формаирование по колонкам
   * Создаем пустой объект formatedUserList для группировки пользователей
   */
  const formatedUserList = userList.reduce((acc: any, user: any) => {
    // Если тип пользователя еще не существует в (acc), создаем его
    const { type, type_name } = user;
    if (!acc[type]) {
      acc[type] = {
        name: '',
        users: [],
      };
    }
    acc[type].name = type_name;
    acc[type].users.push(user);
    return acc;
  }, {});

  /**
   * Преобразуем объект formatedUserList в массив новых данных newFormatedList
   */
  const newFormatedList = Object.entries(formatedUserList);

  return isViewTable ? (
    <>
      <Title level={5} type="secondary" className="mb-5 mt-5">
        Согласующие
      </Title>
      <Table
        columns={columns}
        dataSource={state}
        rowKey={(record) => `${record.key}-${record.id}`}
        expandable={{
          rowExpandable: (record) =>
            (record.remark !== null && record.remark?.length !== 0) ||
            (record.files && record.files?.length > 0),
          expandedRowRender: (record) => {
            let returnRemark;
            let returnFile;
            if (record.remark && record.remark?.length > 0) {
              returnRemark = (
                <p style={{ margin: 0 }}>Примечание: {record.remark}</p>
              );
            }
            if (record.files?.length > 0) {
              returnFile = (
                <div className="mt-5">
                  <Title level={5} type="secondary" className="mb-5">
                    Вложенные документы
                  </Title>
                  {record.files.map(({ file_path, id, file_name }: IFile) => {
                    return (
                      <Button
                        onClick={() => installFile(file_name)}
                        type="text"
                        icon={<FileTwoTone />}
                        disabled={false}
                      >
                        {file_name}
                      </Button>
                    );
                  })}
                </div>
              );
            }
            return (
              <div>
                {returnRemark}
                {returnFile}
              </div>
            );
          },
          columnTitle: 'Примечание',
        }}
        scroll={{ x: 1000 }}
      />
    </>
  ) : (
    <Form
      form={documentApproversForm}
      name={'document_approvers'}
      style={{ width: '100%' }}
      layout="vertical"
    >
      <Row gutter={24}>
        {newFormatedList.map((category: any, index) => {
          return (
            <Col span={8} xs={24} xl={8} key={index}>
              <p>{category[1].name}</p>
              <ApprovementForm
                readonly={readonly}
                key={`approvers-${category[1].users.length}`}
                item={category[1].users}
                name={category[1].name}
                type={category[0]}
                colleaguesList={colleaguesList}
                documentCoordinationForm={documentApproversForm}
                changeApprovers={onChangeApprovers}
                keyVal={category[0]}
                classConstant={classConstant}
              />
            </Col>
          );
        })}
      </Row>
    </Form>
  );
}

export default Approvers;
