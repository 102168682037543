import { useDynamicFieldsAttributesProps } from '@modules/Quotation/Interfaces/DynamicFieldsAttributes/index.interface';
import React from 'react';

export enum Types {
  CHECKBOX = 'checkbox',
  DICTI = 'dicti',
  DICTI_CODENAME = 'dicti_codename',
  NUMBER = 'number',
  INTEGER = 'integer',
  TEXT = 'text',
  AGREEMENTCALC = 'agreementcalc',
  AGRCALC = 'agrcalc',
  DEPTEMPL = 'deptempl',
  EMPL = 'empl',
  DEPT = 'dept',
  DATE = 'date',
  DOCS = 'docs',
  AGREEMENT = 'agreement',
  SQL = 'sql',
}

function useDynamicFieldsAttributes({
  setAgrAttributes,
  agrAttributes = [],
  data,
  userForeignId,
  agrIsn,
  id_obj,
  setAgrObjAttributes,
  agrObjAttributes = [],
}: useDynamicFieldsAttributesProps) {
  function insertDefaultValuesAgrAttributes() {
    return {
      obj_isn: agrIsn,
      class_isn: 220565,
      user_isn: userForeignId,
      updated_by: userForeignId,
    };
  }

  function insertDefaultValuesAgrAttributesObject() {
    return {
      agr_isn: agrIsn,
      class_isn: 220596,
      user_isn: userForeignId,
      id_obj: id_obj,
      fid: id_obj !== undefined ? ++id_obj : null,
      fid_obj: id_obj !== undefined ? id_obj : null,
      updated_by: userForeignId,
    };
  }

  function iterateThroughObjectByField(value, valField, agrAttributes) {
    if (typeof setAgrAttributes === 'undefined' || !data) return;

    let newAgrAttributes: any = [];

    if (agrAttributes.find((item) => item.attr_isn === data.attr_isn)) {
      newAgrAttributes = agrAttributes.map((item) =>
        item.attr_isn === data.attr_isn
          ? { ...item, [valField]: value }
          : { ...item }
      );
    } else {
      const newItem = {
        attr_isn: data.attr_isn,
        [valField]: value,
        ...insertDefaultValuesAgrAttributes(),
      };
      newAgrAttributes = [...agrAttributes, newItem];
    }

    return newAgrAttributes;
  }

  function insertDataAgrAttributes(type, value) {
    if (typeof setAgrAttributes === 'undefined' || !data) return;
    switch (type) {
      case Types.CHECKBOX:
        setAgrAttributes((agrAttributes) => {
          return iterateThroughObjectByField(
            value === true ? 1 : 0,
            'val_n',
            agrAttributes
          );
        });
        break;
      case Types.DICTI:
        setAgrAttributes((agrAttributes) => {
          return iterateThroughObjectByField(value, 'val_n', agrAttributes);
        });
        break;
      case Types.NUMBER || Types.INTEGER:
        setAgrAttributes((agrAttributes) => {
          return iterateThroughObjectByField(value, 'val_n', agrAttributes);
        });
        break;
      case Types.TEXT ||
        Types.AGREEMENTCALC ||
        Types.AGRCALC ||
        Types.AGREEMENT:
        setAgrAttributes((agrAttributes) => {
          return iterateThroughObjectByField(value, 'val_c', agrAttributes);
        });
        break;
      case Types.DEPTEMPL || Types.EMPL || Types.DEPT:
        setAgrAttributes((agrAttributes) => {
          return iterateThroughObjectByField(value, 'val_c', agrAttributes);
        });
        break;
      case Types.DATE:
        setAgrAttributes((agrAttributes) => {
          return iterateThroughObjectByField(value, 'val_d', agrAttributes);
        });
        break;
      case Types.SQL:
        setAgrAttributes((agrAttributes) => {
          return iterateThroughObjectByField(value, 'val_n', agrAttributes);
        });
        setAgrAttributes((agrAttributes) => {
          return iterateThroughObjectByField(value, 'val_c', agrAttributes);
        });
        break;
      case Types.DOCS:
        setAgrAttributes((agrAttributes) => {
          return iterateThroughObjectByField(value, 'val_n', agrAttributes);
        });
        break;
      default:
        break;
    }
  }

  function iterateThroughObjectByFieldAndId(value, valField, agrObjAttributes) {
    if (typeof setAgrObjAttributes === 'undefined' || !data) return;

    let newAgrObjAttributes: any = [];

    if (
      agrObjAttributes.find(
        (item) => item.attr_isn === data.attr_isn && item.id_obj === id_obj
      )
    ) {
      newAgrObjAttributes = agrObjAttributes.map((item) =>
        item.attr_isn === data.attr_isn && item.id_obj === id_obj
          ? { ...item, [valField]: value }
          : { ...item }
      );
    } else {
      const newItem = {
        attr_isn: data.attr_isn,
        [valField]: value,
        ...insertDefaultValuesAgrAttributesObject(),
      };
      newAgrObjAttributes = [...agrObjAttributes, newItem];
    }

    return newAgrObjAttributes;
  }

  function insertDataAgrAttributesObject(type, value) {
    if (typeof setAgrObjAttributes === 'undefined' || !data) return;
    switch (type) {
      case Types.CHECKBOX:
        setAgrObjAttributes((agrObjAttributes) => {
          return iterateThroughObjectByFieldAndId(
            value === true ? 1 : 0,
            'val_n',
            agrObjAttributes
          );
        });
        break;
      case Types.DICTI:
        setAgrObjAttributes((agrObjAttributes) => {
          return iterateThroughObjectByFieldAndId(
            value,
            'val_n',
            agrObjAttributes
          );
        });
        break;
      case Types.NUMBER || Types.INTEGER:
        setAgrObjAttributes((agrObjAttributes) => {
          return iterateThroughObjectByFieldAndId(
            value,
            'val_n',
            agrObjAttributes
          );
        });
        break;
      case Types.TEXT ||
        Types.AGREEMENTCALC ||
        Types.AGREEMENT ||
        Types.AGRCALC:
        setAgrObjAttributes((agrObjAttributes) => {
          return iterateThroughObjectByFieldAndId(
            value,
            'val_c',
            agrObjAttributes
          );
        });
        break;
      case Types.DEPTEMPL || Types.EMPL || Types.DEPT:
        setAgrObjAttributes((agrObjAttributes) => {
          return iterateThroughObjectByFieldAndId(
            value,
            'val_c',
            agrObjAttributes
          );
        });
        break;
      case Types.DATE:
        setAgrObjAttributes((agrObjAttributes) => {
          return iterateThroughObjectByFieldAndId(
            value,
            'val_d',
            agrObjAttributes
          );
        });
        break;
      case Types.DOCS:
        setAgrObjAttributes((agrObjAttributes) => {
          return iterateThroughObjectByFieldAndId(
            value,
            'val_d',
            agrObjAttributes
          );
        });
        break;
      case Types.SQL:
        setAgrObjAttributes((agrObjAttributes) => {
          return iterateThroughObjectByFieldAndId(
            value,
            'val_n',
            agrObjAttributes
          );
        });
        break;
      default:
        break;
    }
  }

  return {
    insertDataAgrAttributes,
    insertDataAgrAttributesObject,
    Types,
  };
}

export default useDynamicFieldsAttributes;
