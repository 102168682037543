import { useState, useEffect } from 'react';
import axios from 'axios';
import { notification } from 'antd';

import api from '../services/api';

interface User {
  avatar: any;
  birthday?: Date;
  client_id: number;
  first_name: string;
  full_name: string;
  id: number;
  last_name: string;
  parent_name: string;
  short_name: string;
  foreign_id: number;
}

type UsersListType = User[];

export interface Colleagues {
  child: ColleaguesListType;
  full_name: string;
  id: number;
  users: UsersListType;
  client_id: undefined;
  foreign_id: number;
  isn: number;
}

export type ColleaguesListType = Colleagues[];

const useColleagues = () => {
  const [colleaguesList, setColleaguesList] = useState<ColleaguesListType>([]);
  const [isLoadingColleaguesList, setIsLoadingColleaguesList] = useState(false);
  const [departmentList, setDepartmentList] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingColleaguesList(true);

        const { data } = await api.colleagues.getColleagues();
        const { data: dataDepartments } = await api.colleagues.getDepartments();

        setColleaguesList(data.data);
        setDepartmentList(dataDepartments.data);
      } catch (error) {
        let message;

        if (axios.isAxiosError(error) && error.response) {
          message = error.response.data.message;
        } else {
          message = String(error);
        }

        notification.info({
          message: 'Ошибка',
          description: message,
        });
      } finally {
        setIsLoadingColleaguesList(false);
      }
    })();
  }, []);

  return { colleaguesList, isLoadingColleaguesList, departmentList };
};

export default useColleagues;
