import { IAccessList, IRoomList } from '@modules/Events';
import { IBooking } from '@pages/Talk/Talk';
import { createContext, Dispatch, SetStateAction } from 'react';

interface EventContextInterface {
  totalList: IBooking[];
  setTotalList: Dispatch<SetStateAction<IBooking[]>>;
  companiesList: IAccessList[];
  isLoading: boolean;
  isLoadingCompanyList: boolean;
  roomsList: IRoomList[];
  fetchedColleagues: any;
  getUserBookingMethod: any;
}

const EventContext = createContext<EventContextInterface>({
  totalList: [],
  setTotalList: () => {},
  companiesList: [],
  isLoading: false,
  isLoadingCompanyList: false,
  roomsList: [],
  fetchedColleagues: [],
  getUserBookingMethod: () => {},
});

export default EventContext;
