import {
  IAgrClauseItem,
  IAgrObjAttributeItem,
  IAgrobjectItem,
  IAgrObjectCarItem,
  IAgrObjCarGoItem,
  IAgrCondItem,
  IUserProductSelectedItem,
  IAgrAttributeItem,
} from '@modules/Quotation/Interfaces/MainQuotation/index.interface';
import QuotationContext from '@contexts/QuotationContext/QuotationContext';
import { useMemo, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { IInitialState, Action, initialState, reducer } from './DateReducer';
import useCompanyTree from '../hooks/CommonMethods/MainComponents/useCompanyTree';
import useDocTypesList from '../hooks/CommonMethods/MainComponents/useDocTypesList';
import useProductListApi from '../hooks/CommonMethods/MainComponents/useProductListApi';

interface IQuotationProvider {
  children: React.ReactNode;
}

const QuotationProvider: React.FC<IQuotationProvider> = (props) => {
  const [productSelectedQuotation, setProductSelectedQuotation] = useState<
    IUserProductSelectedItem[]
  >([]);
  const { colleaguesKiasList, isLoadingColleaguesKiasList } = useCompanyTree();
  const { productList } = useProductListApi();
  const { docTypesList } = useDocTypesList();

  const [agrClauses, setAgrClauses] = useState<IAgrClauseItem[]>([]);
  const [agrAttributesUnder, setAgrAttributesUnder] = useState<
    IAgrAttributeItem[]
  >([]);
  const [agrAttributes, setAgrAttributes] = useState<IAgrAttributeItem[]>([]);
  const [agrObjAttributes, setAgrObjAttributes] = useState<
    IAgrObjAttributeItem[]
  >([]);
  // TODO: Вынести
  const [agrObj, setAgrObj] = useState<IAgrobjectItem[]>([
    {
      address: '',
      agr_isn: 0,
      cityisn: 291904,
      class_isn: 221713,
      ext_system_key: null,
      fid: 1,
      fid_obj: 1,
      id_obj: 0,
      obj_count: '1',
      obj_name: '',
      order_no: '1',
      parent_isn: null,
      remark: null,
      sub_class_isn: 471711,
      subj_isn: null,
      user_isn: 0,
    },
  ]);
  const [agrObjCar, setAgrObjCar] = useState<IAgrObjectCarItem[]>([]);
  const [agrObjCarGo, setAgrObjCarGo] = useState<IAgrObjCarGoItem[]>([]);
  // TODO: Вынести
  const [agrCond, setAgrCond] = useState<IAgrCondItem[]>([
    {
      agr_isn: 0,
      cond_name: '',
      risk_isn: 499541,
      obj_isn: '',
      risk_name:
        'Имущественный ущерб третьим лицам,\r\nФизический ущерб третьим лицам,\r\nПричинение вреда окружающей среде.\r\n',
      ins_class_isn: 438931,
      addendum_sum: 0,
      date_sign: '',
      date_beg: '',
      date_end: '',
      date_denounce: '',
      duration: '365', // Возможно должен считать кол-во дней исходя из beg/end
      curr_isn: 9813,
      curr_sum_isn: 9813,
      limit_sum: 0,
      limit_sum_ext: '',
      limit_sum_type: 'А',
      discount: '0',
      tariff: null, // Меняю, может null
      tariff_str: null,
      premium_sum: undefined,
      premium_sum_tariff: null,
      franch_type: '-',
      franch_tariff: '',
      franch_sum: 0,
      comission_proc: '',
      comission_sum: 0,
      fid: 1,
      fid_obj: 1,
      id_obj: 0,
      parent_isn: 0,
      payment_koeff: '1',
      reason_cancel_isn: '',
      prev_isn: '',
      user_isn: 0,
      remark: '',
    },
  ]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [agrContractor, setAgrContractor] = useState<any>([]);
  const [agrMembers, setAgrMembers] = useState<any>([]);

  const [stateDateQuotation, dispatchDate] = useImmerReducer<
    IInitialState,
    Action
  >(reducer, initialState);
  const [status, setStatus] = useState(0);
  const [isCustomTemplate, setIsCustomTemplate] = useState(0);
  const [nshdFileList, setNshdFileList] = useState<any[]>([]);
  const [memberSettings, setMemberSettings] = useState<any[]>([]);

  const contextValue = useMemo(
    () => ({
      colleaguesKiasList,
      isLoadingColleaguesKiasList,
      productList,
      docTypesList,
      productSelectedQuotation,
      setProductSelectedQuotation,
      agrClauses,
      setAgrClauses,
      agrAttributesUnder,
      setAgrAttributesUnder,
      agrAttributes,
      setAgrAttributes,
      agrObjAttributes,
      setAgrObjAttributes,
      agrObj,
      setAgrObj,
      agrObjCar,
      setAgrObjCar,
      agrObjCarGo,
      setAgrObjCarGo,
      agrCond,
      setAgrCond,
      fileList,
      setFileList,
      stateDateQuotation,
      dispatchDate,
      agrContractor,
      setAgrContractor,
      agrMembers,
      setAgrMembers,
      memberSettings,
      setMemberSettings,
      status,
      setStatus,
      isCustomTemplate,
      setIsCustomTemplate,
      nshdFileList,
      setNshdFileList,
    }),
    [
      colleaguesKiasList,
      isLoadingColleaguesKiasList,
      productList,
      docTypesList,
      productSelectedQuotation,
      setProductSelectedQuotation,
      agrClauses,
      setAgrClauses,
      agrAttributes,
      setAgrAttributes,
      agrObjAttributes,
      setAgrObjAttributes,
      agrObj,
      setAgrObj,
      agrObjCar,
      setAgrObjCar,
      agrObjCarGo,
      setAgrObjCarGo,
      agrCond,
      setAgrCond,
      fileList,
      setFileList,
      stateDateQuotation,
      dispatchDate,
      agrContractor,
      setAgrContractor,
      agrMembers,
      setAgrMembers,
      memberSettings,
      setMemberSettings,
      status,
      setStatus,
      isCustomTemplate,
      setIsCustomTemplate,
      nshdFileList,
      setNshdFileList,
    ]
  );

  return (
    <QuotationContext.Provider value={contextValue}>
      {props.children}
    </QuotationContext.Provider>
  );
};

export default QuotationProvider;
