import BdayItem from './BdayItem';

import { IUser } from './type';

interface BdayListProps {
  userList: IUser[];
}

function BdayList({ userList = [] }: BdayListProps) {
  return (
    <>
      {userList.map((user) => (
        <BdayItem key={user.id} {...user} />
      ))}
    </>
  );
}

export default BdayList;
