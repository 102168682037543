import React, { useEffect, useState } from 'react';

import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Row,
  Select,
  Typography,
} from 'antd';
// import { UserProductSelectedProps } from "../InfoProduct";
import { IInnItem } from './ExpressProduct';
import api from '@modules/Quotation/services';

// interface IMemberProduct extends UserProductSelectedProps {
//   form;
//   docTypesList;
//   // treeSelectUsersData: Colleagues[];
//   clientIin: string | null;
// }

const { Title } = Typography;
const { Option } = Select;
interface IInsurantAgentModal {
  visible: boolean;
  universalDataModal: IInnItem | null;
  setUniversalModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  universalNameModal: string;
  setUniversalDataModal: React.Dispatch<React.SetStateAction<IInnItem | null>>;
}

interface Contragent {
  LASTNAME: any;
  FIRSTNAME: any;
  PARENTNAME: any;
  FIO: any;
  BIRTHDAY: any;
  DOCNO: any;
  DOCDATE: any;
  DOCNAME: any;
  ISN: any;
  GENDER: any;
  ADDRESS: any;
  EMAIL: any;
  PHONENUMBER: any;
  RESIDENT: any;
  IIN: any;
  JURIDICAL: any;
}

const ContragentModal: React.FC<IInsurantAgentModal> = ({
  visible,
  universalDataModal,
  setUniversalModalVisible,
  universalNameModal,
  setUniversalDataModal,
}) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [contragentList, setContragentList] = useState<
    Contragent[] | undefined
  >([]);
  useEffect(() => {
    if (universalDataModal === null) {
      return;
    }
    var selected = '';
    selected = universalDataModal.FIO + ' ' + universalDataModal.IIN;
    // if (
    //   universalDataModal.LASTNAME !== null &&
    //   universalDataModal.PARENTNAME !== null &&
    //   universalDataModal.FIRSTNAME !== null
    // ) {
    //   selected =
    //     universalDataModal.LASTNAME +
    //     ' ' +
    //     universalDataModal.PARENTNAME +
    //     ' ' +
    //     universalDataModal.FIRSTNAME + ' ' + universalDataModal.IIN;
    // } else if (
    //   universalDataModal.LASTNAME !== null &&
    //   universalDataModal.PARENTNAME === null &&
    //   universalDataModal.FIRSTNAME !== null
    // ) {
    //   selected =
    //     universalDataModal.LASTNAME + ' ' + universalDataModal.FIRSTNAME + ' ' + universalDataModal.IIN;
    // }
    form2.setFieldsValue({
      selected_contragent: selected,
    });
    if (universalDataModal.JURIDICAL === 'Y') {
      form2.setFieldsValue({
        universal_fio: universalDataModal.FIO,
        universal_iin: universalDataModal.IIN,
        universal_clientISN: universalDataModal.ISN,
        universal_addressModal: universalDataModal.ADDRESS,
        universal_emailModal:
          universalDataModal.EMAIL === 0 ? '' : universalDataModal.EMAIL,
        universal_phoneNumber: universalDataModal.PHONENUMBER,
      });
      form.setFieldsValue({
        phys: 'jur',
      });
    } else {
      form2.setFieldsValue({
        universal_surnameModal: universalDataModal.LASTNAME,
        universal_nameModal: universalDataModal.FIRSTNAME,
        universal_patronymicModal: universalDataModal.PARENTNAME,
        universal_fio: universalDataModal.FIO,
        universal_iin: universalDataModal.IIN,
        universal_dateBirth: universalDataModal.BIRTHDAY,
        universal_documentName: universalDataModal.DOCNAME,
        universal_numberDocument:
          universalDataModal.DOCNO.charAt(0).toLowerCase() === 'n'
            ? universalDataModal.DOCNO.slice(1)
            : universalDataModal.DOCNO,
        universal_documentIssueDateModal: universalDataModal.DOCDATE,
        universal_clientISN: universalDataModal.ISN,
        universal_sex: universalDataModal.GENDER === '2' ? 'Ж' : 'М',
        universal_addressModal: universalDataModal.ADDRESS,
        universal_emailModal:
          universalDataModal.EMAIL === 0 ? '' : universalDataModal.EMAIL,
        universal_phoneNumber: universalDataModal.PHONENUMBER,
      });
      form.setFieldsValue({
        phys: 'phys',
      });
    }
  }, [form, form2, universalDataModal]);
  useEffect(() => {
    if (contragentList === null) {
      return;
    }
  }, [contragentList, universalDataModal]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isIndividual, setIsIndividual] = useState<boolean>(true);

  const getAllValidatedData = async (values) => {
    if (
      values.phys === 'jur' &&
      values.contragent == undefined &&
      !values.fio == undefined
    ) {
      notification.info({
        message: 'Заполните поля!',
      });
      return;
    }
    if (
      values.phys === 'phys' &&
      values.contragent == undefined &&
      values.firstname == undefined &&
      values.lastname == undefined &&
      values.parentname == undefined
    ) {
      notification.info({
        message: 'Заполните поля!',
      });
      return;
    }
    setIsLoading(true);
    var responseData = {};
    if (values.phys === 'jur') {
      responseData = {
        iin: values.contragent,
        fullname: values.fio,
        connection: 'centras',
      };
    } else {
      responseData = {
        iin: values.contragent,
        firstName: values.firstname,
        lastName: values.lastname,
        parentName: values.parentname,
        connection: 'centras',
      };
    }
    form2.resetFields();
    message
      .loading('Идет получение с сервера, ожидайте...', 5.0)
      .then(async () => {
        try {
          const response = await api.expressQuotation.searchUser(responseData);
          if (response.data.success) {
            setUniversalDataModal(response.data.data[0]);
            setContragentList(response.data.data);
            var selected = '';
            selected =
              response.data.data[0]?.FIO + ' ' + response.data.data[0]?.IIN;
            // if (
            //   response.data.data[0]?.LASTNAME !== null &&
            //   response.data.data[0].PARENTNAME !== null &&
            //   response.data.data[0].FIRSTNAME !== null
            // ) {
            //   selected =
            //     response.data.data[0]?.LASTNAME +
            //     ' ' +
            //     response.data.data[0].PARENTNAME +
            //     ' ' +
            //     response.data.data[0].FIRSTNAME;
            // } else if (
            //   response.data.data[0]?.LASTNAME !== null &&
            //   response.data.data[0].PARENTNAME === null &&
            //   response.data.data[0].FIRSTNAME !== null
            // ) {
            //   selected =
            //     response.data.data[0]?.LASTNAME +
            //     ' ' +
            //     response.data.data[0].FIRSTNAME;
            // }
            form2.setFieldsValue({
              selected_contragent: selected,
            });
          }
        } catch (error) {
          let message;
          if (axios.isAxiosError(error)) {
            message = error.message;
          } else {
            message = String(error);
          }
          notification.info({
            message: 'Ошибка',
            description: message,
          });
        } finally {
          setIsLoading(false);
        }
      });
  };

  const getAllErrorsData = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  function handleChange(value) {
    if (isIndividual === true) {
      setIsIndividual(false);
    } else {
      setIsIndividual(true);
    }
  }
  const SearchForm = () => {
    console.log(12);
  };

  const checkIin = (fieldName, value) => {
    setIsLoading(true);
    const responseData = {
      iin: value,
      connection: 'centras',
    };
    message
      .loading('Идет получение с сервера, ожидайте...', 5.0)
      .then(async () => {
        try {
          const response = await api.expressQuotation.searchInn(responseData);

          form.resetFields([fieldName]);
          setUniversalDataModal(response.data.data[0]);
          setContragentList(response.data.data);
          form2.setFieldsValue({
            universal_surnameModal: response.data.data[0].LAST_NAME,
            universal_nameModal: response.data.data[0].FIRST_NAME,
            universal_patronymicModal: response.data.data[0].MIDDLE_NAME,
            universal_dateBirth: response.data.data[0].BORN,
            universal_numberDocument:
              response.data.data[0].DOCUMENT_NUMBER.charAt(0).toLowerCase() ===
              'n'
                ? response.data.data[0].DOCUMENT_NUMBER.slice(1)
                : response.data.data[0].DOCUMENT_NUMBER,
            universal_documentIssueDateModal:
              response.data.data[0].DOCUMENT_GIVED_DATE,
            universal_clientISN: response.data.data[0].CLIENTISN,
            universal_sex: response.data.data[0].GENDER === '2' ? 'Ж' : 'М',
            universal_addressModal: response.data.data[0].ADDRESS,
            universal_emailModal: response.data.data[0].EMAIL,
            universal_phoneNumber: response.data.data[0].PHONES,
          });
        } catch (error) {
          let message;
          if (axios.isAxiosError(error)) {
            message = error.message;
          } else {
            message = String(error);
          }
          notification.info({
            message: 'Ошибка',
            description: message,
          });
        }
        setIsLoading(false);
      });
  };

  function setContragent(e) {
    // eslint-disable-next-line array-callback-return
    contragentList?.map((el) => {
      if (parseInt(el.ISN) === parseInt(e)) {
        setUniversalDataModal(el);
        // var selected = '';
        // if (
        //   el.LAST_NAME !== null &&
        //   el.MIDDLE_NAME !== null &&
        //   el.FIRST_NAME !== null
        // ) {
        //   selected = el.LAST_NAME + ' ' + el.MIDDLE_NAME + ' ' + el.FIRST_NAME + ' ' + el.IIN;
        // } else if (
        //   el.LAST_NAME !== null &&
        //   el.MIDDLE_NAME === null &&
        //   el.FIRST_NAME !== null
        // ) {
        //   selected = el.LAST_NAME + ' ' + el.FIRST_NAME + ' ' + el.IIN;
        // }
        // form2.setFieldsValue({
        //   selected_contragent: selected,
        // });
        // if (el.JURIDICAL === 'Y') {
        //   return form2.setFieldsValue({
        //     universal_fio: el.FIO,
        //     universal_clientISN: el.ISN,
        //     universal_addressModal: el.ADDRESS,
        //     universal_emailModal: el.EMAIL,
        //     universal_phoneNumber: el.PHONENUMBER,
        //   });
        // }
        // return form2.setFieldsValue({
        //   universal_surnameModal: el.LASTNAME,
        //   universal_nameModal: el.FIRSTNAME,
        //   universal_patronymicModal: el.PARENTNAME,
        //   universal_iin: el.LASTNAME,
        //   universal_dateBirth: el.BIRTHDAY,
        //   universal_numberDocument:
        //     el.DOCNO.charAt(0).toLowerCase() === 'n'
        //       ? el.DOCNO.slice(1)
        //       : el.DOCNO,
        //   universal_documentIssueDateModal: el.DOCDATE,
        //   universal_clientISN: el.ISN,
        //   universal_sex: el.SEX_ID === '2' ? 'Ж' : 'М',
        //   universal_addressModal: el.ADDRESS,
        //   universal_emailModal: el.EMAIL,
        //   universal_phoneNumber: el.PHONENUMBER,
        // });
      }
    });
  }

  return (
    <Modal
      open={visible}
      width={1000}
      onCancel={() => {
        setUniversalModalVisible(false);
      }}
      footer={[
        <Row gutter={15}>
          {/* <Col span={12}>
            <Form.Item>
              <Button
                block
                htmlType="submit"
                type="primary"
              >
                Сохранить
              </Button>
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Button
              block
              onClick={() => {
                setUniversalModalVisible(false);
              }}
            >
              Закрыть
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Title style={{ color: '#0057FF', marginBottom: 20 }} level={4}>
        {universalNameModal}
      </Title>
      <Form
        name="basic"
        layout="vertical"
        form={form}
        onFinish={getAllValidatedData}
        onFinishFailed={getAllErrorsData}
      >
        <Row style={{ paddingBottom: '3%' }}>
          Журнал контрагента<br></br>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item name={'phys'}>
              <Select defaultValue="phys" onChange={handleChange}>
                <Option value="jur">Юридическое лицо</Option>
                <Option value="phys">Физическое лицо</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="contragent">
              <InputNumber
                name={'iin'}
                style={{ marginLeft: '20%' }}
                placeholder="ИИН/БИН"
                onKeyDown={SearchForm}
              />
            </Form.Item>
          </Col>
        </Row>
        {isIndividual ? (
          <Row>
            <Form.Item name={'lastname'} style={{ width: '30%' }}>
              <Input placeholder="Фамилия"></Input>
            </Form.Item>
            <Form.Item
              name={'firstname'}
              style={{ width: '30%', marginLeft: '3%' }}
            >
              <Input placeholder="Имя"></Input>
            </Form.Item>
            <Form.Item
              name={'parentname'}
              style={{ width: '30%', marginLeft: '3%' }}
            >
              <Input placeholder="Отчество"></Input>
            </Form.Item>
          </Row>
        ) : (
          <Form.Item name={'fio'}>
            <Input
              style={{ width: '60%' }}
              placeholder="Полное наименование"
            ></Input>
          </Form.Item>
        )}
        <Col span={7}>
          <Button
            htmlType="submit"
            type="primary"
            className="justify-content-md-center"
            style={{ marginTop: 20, marginBottom: 20 }}
            disabled={isLoading}
            onClick={SearchForm}
          >
            Поиск
          </Button>
        </Col>
      </Form>
      <Form name="basic2" layout="vertical" form={form2}>
        {contragentList?.length !== 0 ? (
          <Row>
            <Col span={8}>Результаты поиска</Col>
            <Col span={16}>
              <Form.Item name={`selected_contragent`}>
                <Select style={{ width: '100%' }} onChange={setContragent}>
                  {contragentList?.map((el) => (
                    <Option key={el.ISN} value={el.ISN}>
                      {el.FIO} {el.IIN}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {universalDataModal?.JURIDICAL === 'Y' ? (
          <Col span={12}>
            <Form.Item label="Полное наименование" name={`universal_fio`}>
              <Input readOnly />
            </Form.Item>
          </Col>
        ) : (
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item label="Фамилия" name={`universal_surnameModal`}>
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Имя" name={`universal_nameModal`}>
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Отчество" name={`universal_patronymicModal`}>
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Дата рождения" name={`universal_dateBirth`}>
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Тип документа" name={`universal_documentName`}>
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Номер документа"
                name={`universal_numberDocument`}
              >
                <Input
                  readOnly
                  // className="productInput"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Дата выдачи документа"
                name={`universal_documentIssueDateModal`}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Пол" name={`universal_sex`}>
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item label="ИИН/БИН" name={`universal_iin`}>
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email" name={`universal_emailModal`}>
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Номер телефона" name={`universal_phoneNumber`}>
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="ISN КИАС" name={`universal_clientISN`}>
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Адрес" name={`universal_addressModal`}>
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ContragentModal;
