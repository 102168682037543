import { ICompany } from './types';
import PowerBi from './components/PowerBi';

interface ILabels {
  amount_percent: string;
  amount_sum: string;
  first_date: string;
  first_proportion: string;
  second_date: string;
  second_proportion: string;
}

export interface IData {
  companies: ICompany[];
  labels: [ILabels];
  sum_payout_first: number;
  sum_payout_second: number;
  sum_premium_first: number;
  sum_premium_second: number;
}
export default function ParseCompanies() {
  return <PowerBi />;
}
