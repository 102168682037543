import { ColleaguesListType } from '@hooks/useColleagues';
import { IRowColumnEditor, IAtrributeEditor } from '@modules/Edo/types';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import { Col, Form } from 'antd';
import { useEffect, useState } from 'react';
import { object } from 'yup';
import { IUser } from './FieldUser';

interface IDepartmentUser {
  settings: IRowColumnEditor;
  departmentList: ColleaguesListType;
  name: number;
  isDisabledForm?: boolean;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}

function DepartmentUser(props: IDepartmentUser) {
  const [user, setUser] = useState<IUser | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    settings,
    departmentList,
    name,
    isDisabledForm = false,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;

  const form = Form.useFormInstance();

  const isRowColumnEditor = typeof name === 'number';

  const propertyValue = Form.useWatch(['row_column_editor', name], form);

  useEffect(() => {
    if (propertyValue) {
      const formValues = Object.values(propertyValue);
      formValues.map((element: any) => {
        if (
          element.display_no + 1 === settings.display_no &&
          element.type == 'DEPTEMPL'
        ) {
          setIsDisabled(true);
          setUser(element);
        }
      });
    }
  }, [propertyValue]);

  useEffect(() => {
    if (user) {
      if (isRowColumnEditor) {
        const { row_column_editor } = form.getFieldsValue();
        if (
          row_column_editor[name][`id-${settings.id}`] &&
          user?.display_no == settings.display_no - 1 &&
          user.type == 'DEPTEMPL' &&
          user.department_id
        ) {
          Object.assign(row_column_editor[name][`id-${settings.id}`], {
            department: user.department,
            department_id: user.department_id,
            num_value: user.department_id,
          });
        }
        form.setFieldsValue({ row_column_editor });

        // if (isDisabledStatus && isAdmin && onChangeByTd) {
        //   onChangeByTd(settings.id, name);
        // }
      } else {
        form.setFieldsValue({
          [`id-${settings.id}`]: {
            department: user.department,
            department_id: user.department_id,
            num_value: user.department_id,
          },
        });
      }
    }
  }, [user]);
  const onChangeDept = (value) => {
    if (isRowColumnEditor) {
      const { row_column_editor } = form.getFieldsValue();
      Object.assign(row_column_editor[name][`id-${settings.id}`], {
        num_value: value,
      });
      form.setFieldsValue({ row_column_editor });
      if (isDisabledStatus && isAdmin && onChangeByTd) {
        onChangeByTd(settings.id, name);
      }
    }
  };
  return (
    <Form.Item
      label={settings.field_name}
      // name={[name, `id-${settings.id}`, 'num_value']}
      name={
        isRowColumnEditor
          ? [name, `id-${settings.id}`, 'department']
          : [`id-${settings.id}`, 'department']
      }
      rules={[{ required: settings.required }]}
    >
      <TreeSelectCollegues
        colleaguesList={departmentList}
        selectDepartment={true}
        disabled={isDisabledForm ? isDisabledForm : isDisabled}
        onChange={onChangeDept}
      />
    </Form.Item>
  );
}

export default DepartmentUser;
