import { notification } from 'antd';
import axios from 'axios';
import React from 'react';

const catchErrorCustomAxios = (error, message) => {
  if (axios.isAxiosError(error)) {
    message = error.message;
  } else {
    message = String(error);
  }
  notification.info({
    message: 'Ошибка',
    description: message,
  });
};

export default catchErrorCustomAxios;
