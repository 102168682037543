import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Row, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
const { Title } = Typography;

const ObjectQuotationItem = ({ props }) => {
  const { car, openObjectModal, deleteObj, agrCond, item } = props;
  const { stateDateQuotation } = useQuotation();
  const [objCond, setObjCond] = useState<any>();

  useEffect(() => {
    if (agrCond) {
      const carCond = agrCond.filter((item) => item.objisn === car.isn)[0];
      setObjCond(carCond);
    }
  }, [agrCond]);
  return (
    <Card className="quotation-object">
      <Row justify={'space-between'}>
        <Col>
          <div className="quotation-object__breadcrumb">
            ТС / {car?.vehicle_type}
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            className="mr-2"
            onClick={() => openObjectModal({ car, item })}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => deleteObj(car)}
            disabled={checkDisabledField(stateDateQuotation.status_isn)}
          >
            <DeleteOutlined />
          </Button>
        </Col>
      </Row>
      <Title level={5} className="quotation-object__title">
        {car?.vehicle_mark} {car?.vehicle_model}
      </Title>
      <div className="quotation-object__description">{car?.reg_no}</div>
      <Row gutter={8}>
        <Col span={8} className="desc-text">
          <p>Страховая сумма</p>
          <p>{objCond?.limit_sum}</p>
        </Col>
        <Col span={8} className="desc-text">
          <p>Тариф премии</p>
          <p>{objCond?.tariff}</p>
        </Col>
        <Col span={8} className="desc-text">
          <p>Страховая премия</p>
          <p>{objCond?.premium_sum}</p>
        </Col>
      </Row>
    </Card>
  );
};

export default ObjectQuotationItem;
