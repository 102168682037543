import { useEffect, useState } from 'react';

import useAuth from '@hooks/useAuth';
import CounterContext from '@contexts/CounterContext';
import api from '@services/api';

function CounterProvider({ children }) {
  const [countAgreement, setCountAgreement] = useState(0);
  const [countEvent, setCountEvent] = useState(0);
  const { user } = useAuth();

  const getCountAgreement = async () => {
    try {
      setTimeout(async () => {
        const { data } = await api.agreement.getCount();

        setCountAgreement(data);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const getCountEvent = async () => {
    try {
      const { data } = await api.event.getCountEvents();

      setCountEvent(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      getCountAgreement();
      getCountEvent();
    }
  }, [user]);

  return (
    <CounterContext.Provider
      value={{ countAgreement, getCountAgreement, countEvent, getCountEvent }}
    >
      {children}
    </CounterContext.Provider>
  );
}

export default CounterProvider;
