import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select, Typography, Modal } from 'antd';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ru';
import './EventCalendar.scss';
import useEvent from '../../../hooks/useEvent';
import api from '../services/api';

// import { useSelector } from 'react-redux';

const { Title } = Typography;
const localizer = momentLocalizer(moment);
const messages = {
  week: 'Неделя',
  day: 'День',
  month: 'Месяц',
  previous: '<',
  next: '>',
  today: `Сегодня`,
};

interface EventCalendar {
  id: number;
  title: string;
  start: Date;
  end: Date;  
  created_by: number;
}

const { Option } = Select;

interface IBookingCalendar {
  company_id: string;
  room_id: number;
}

interface ICalendarData {
  id: number;
  begin: string;
  end: string;
  title: string;
  created_by: number;
}

const EventCalendar = () => {
  const [allEvents, setAllEvents] = useState<EventCalendar[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [calendarData, setCalendarData] = useState<ICalendarData[]>([]);
  const { totalList, companiesList, roomsList } = useEvent();
  const [createdUsers, setCreatedUsers] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // full_info user and about record 
  const [userRecord, setUserRecord] = useState('');
  const [recordTime, setRecordTime] = useState('');

  useEffect(() => {
    if (totalList.length) {
      setAllEvents(
        totalList.map((booking) => ({
          id: booking.id,
          title: `Тема : ${booking.booking[0].title}`,
          start: new Date(booking.booking[0].begin),
          end: new Date(booking.booking[0].end),          
          created_by: booking[0]?.created_by,
        }))
      );
    }
  }, [totalList]);

  useEffect(() => {
    if (calendarData.length && createdUsers.length) {
      setAllEvents(() => {
        const formattedCalendarData = calendarData.reduce((acc: any, booking) => {
          const user = createdUsers.find((user) => user.id === booking.created_by);
          if (user) {
            const event = {
              id: booking.id,
              title: user.first_name && user.last_name ?
                `${user.first_name} ${user.last_name}, Тема 
                : ${booking.title}` : `${user.FIO}, Тема : ${booking.title}`,
              start: new Date(booking.begin),
              end: new Date(booking.end),
              created_by: booking.created_by,
            };

            if (!acc.some((existingEvent) => existingEvent.id === event.id)) {
              acc.push(event);
            }
          }
  
          return acc;
        }, []);

        return formattedCalendarData;
      });
    }
  }, [calendarData, createdUsers]);

  const getCalendarRecord = async (room_id: any) => {
    try {
      setIsLoading(true);
      const { data } = await api.getBookingCalendar(room_id);
      // доп проверка
      if(data.length){
        data.map(async (item) => {
          if(item.source === 'myCent') {
            // при map мы можем получить item.created_by - это id юзера который создал встречу
            const response = await api.getColleagueInfo(item?.created_by);
            // делаем push юзеров которые создали встречу
            setCreatedUsers((prevUsers) => [...prevUsers, response.data.data.user_info]);
          } else {
            // Если пользователь создал встречу из Ботана.
            const response = await api.getUserInfoFromBotan(item?.created_by);
            setCreatedUsers((prevUsers) => [...prevUsers, response.data.data]);
          }
        });
      }      
      if(!data.length) {
        setAllEvents([]);
      }
      setCalendarData(data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const currentEvent = (event: any) => {
    let hours_start = event.start.getHours(),
        minuts_start = event.start.getMinutes(),
        hours_end = event.end.getHours(),
        minuts_end = event.end.getMinutes();

    const startHours = `${+hours_start < 10 ? '0' + hours_start : hours_start} : ${+minuts_start < 10 ? '0' + minuts_start : minuts_start}`;
    const endHours = `${+hours_end < 10 ? '0' + hours_end : hours_end} : ${+minuts_end < 10 ? '0' + minuts_end : minuts_end}`;

    setUserRecord(event.title);
    setRecordTime(`${startHours} - ${endHours}`);
    setIsModalOpen(true);
  }
  
  return (
    <div className="event-calendar">
      <Title className="mb-10" level={4} type="secondary">
        Календарь событий
      </Title>

      <Form disabled={isLoading}>
        <Row gutter={24} className="mb-6">
          <Col xs={24} xl={8}>
            <Form.Item
              name="company_id"
              rules={[
                {
                  required: true,
                  message:
                    'Поле "Выберите компанию" обязательное для заполнения',
                },
              ]}
            >
              <Select
                placeholder="Выберите компанию"
                dropdownMatchSelectWidth={false}
                style={{ width: '100%' }}
              >
                {companiesList.map((company) => {
                  return (
                    <Option value={company.id} key={company.id}>
                      {company.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>

          <Form.Item
              shouldUpdate={(prevValues, curValues) => {
              return prevValues.company_id !== curValues.company_id;
            }}
            noStyle
          >
            {({ getFieldValue }) => {
              const showCompany = getFieldValue('company_id');     
              if (showCompany || true) {
                return (
                  <Col xs={24} xl={8}>
                    <Form.Item name="room_id">
                      <Select
                        placeholder="Выберите конференц зал"
                        dropdownMatchSelectWidth={false}
                        style={{ width: '100%' }}
                        onChange={(selectedRoomId) => { getCalendarRecord(selectedRoomId)}}
                        notFoundContent={
                          <>
                            {showCompany === undefined ? (
                              <span>Выберите компанию</span>
                            ) : (
                              <span>Отсутствует конференц зал</span>
                            )}
                          </>
                        }
                      >
                        {roomsList
                          .filter(
                            (item) => item.booking_building_id === parseInt(showCompany)
                          )
                          .map((room) => (
                            <Option value={room.id} key={String(room.id)}>
                              {room.room}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                );
              }
            }}
          </Form.Item>
        </Row>
      </Form>

      <Calendar
        localizer={localizer}
        defaultView={Views.WEEK}
        views={['day', 'week', 'month']}
        min={new Date(1972, 0, 1, 8, 0, 0, 0)}
        max={new Date(1972, 0, 1, 19, 0, 0)}
        step={15}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 650 }}
        messages={messages}
        onSelectEvent={(event) => currentEvent(event)}
      />

      <Modal title="Забранировано" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Время: {recordTime}</p>
        <p>{userRecord}</p>
      </Modal>

    </div>
  );
};

export default EventCalendar;