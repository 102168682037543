import api from '@modules/Quotation/services';

import { useEffect, useState } from 'react';

export interface IMembers {
  id: number;
  label: string;
}

function useMembersQuotation() {
  const [members, setMembers] = useState<IMembers[]>([]);

  useEffect(() => {
    getMembersData().then((values) => {
      setMembers(values);
    });
  }, []);

  /**
   * Подтягиваем список ролей с бэка
   */
  const getMembersData = async () => {
    const { data } = await api.quotation.getMembers();
    return data.data;
  };

  return { members , setMembers};
}

export default useMembersQuotation;
