import React from 'react';
import { TreeSelect } from 'antd';
import './CTreeSelect.scss';

const CTreeSelect = (props) => {
  return (
    <TreeSelect {...props} className={`c-treeselect ${props.className || ''}`}>
      {props.children}
    </TreeSelect>
  );
};

export default CTreeSelect;
