
import { Dispatch, SetStateAction } from 'react';
import api from '../services/api';
import { DataType } from './ApplicationTable';

const getMyApplication = async () => {
  const { data } = await api.getMyApplicationData();
  return data?.data;
};

export const setApplication = async (
  setFetchApplication: Dispatch<SetStateAction<DataType[]>>
) => {
  getMyApplication().then((values) => {
    if (values) {
      setFetchApplication(values);
    }
  });
};
