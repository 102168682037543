import { useEffect, useState } from 'react';
import { Button, Col } from 'antd';
import ModalDynamicDocument from '@modules/Quotation/ContractDocuments/DynamicDocuments/ModalDynamicDocument/ModalDynamicDocument';

interface IPropsDynamicDocuments {
  agreement: {
    doc_classisn_name: string;
    doc_list: {
      doc_classisn: string;
      doc_classisn_name: string;
      doc_date: string;
      doc_emplisn: string;
      doc_emplisn_name: string;
      doc_isn: string;
      doc_stageisn: string;
      doc_stageisn_name: string;
      doc_statusisn: string;
      doc_statusisn_name: string;
      doc_subjisn: string;
      doc_subjisn_name: string;
    };
  };
  productIsn: number | undefined;
  userForeignId?: number;
}

interface IDocument {
  doc_classisn_name: string;
  doc_list: {
    doc_classisn: string;
    doc_classisn_name: string;
    doc_date: string;
    doc_emplisn: string;
    doc_emplisn_name: string;
    doc_isn: string;
    doc_stageisn: string;
    doc_stageisn_name: string;
    doc_statusisn: string;
    doc_statusisn_name: string;
    doc_subjisn: string;
    doc_subjisn_name: string;
  };
}

const DynamicDocuments = ({
  agreement,
  productIsn,
  userForeignId,
}: IPropsDynamicDocuments) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [agreements, setAgreements] = useState<IDocument[]>([]);
  const [currentDocIsn, setCurrentDocIsn] = useState<string>('');

  useEffect(() => {
    const newAgreements: IDocument[] = [];
    for (let agr in agreement) {
      if (agreement.hasOwnProperty(agr)) {
        newAgreements.push(agreement[agr]);
      }
    }

    setAgreements(newAgreements);
  }, [agreement]);

  const renderDocuments =
    agreements.length >= 1 &&
    agreements.map((agr) => {
      return (
        <Col span={24} key={agr.doc_list.doc_classisn}>
          <Button
            key={agr.doc_list.doc_classisn}
            size="large"
            block
            className="mb-5"
            onClick={() => {
              setCurrentDocIsn(agr.doc_list.doc_isn);
              setIsOpenModal(true);
            }}
          >
            {agr.doc_classisn_name}
          </Button>
        </Col>
      );
    });

  return (
    <>
      {isOpenModal && (
        <ModalDynamicDocument
          currentDocIsn={currentDocIsn}
          isOpenModal={isOpenModal}
          productIsn={productIsn}
          setIsOpenModal={() => setIsOpenModal(false)}
          userForeignId={userForeignId}
        />
      )}
      {renderDocuments}
    </>
  );
};

export default DynamicDocuments;
