import React, { useEffect } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { fieldNamesServiceCenter } from './ServiceCenterFields';
import { setFieldsMethod } from './setFieldsMethod';
import useQuotationJournal from '../hooks/useQuotationJournal';

const { TextArea } = Input;

type ApplicationMessageProps = {
  textApplication: string;
};

function ApplicationMessage({ textApplication }: ApplicationMessageProps) {
  const form = Form.useFormInstance();

  const { state } = useQuotationJournal();

  useEffect(() => {
    setFieldsMethod(
      form,
      fieldNamesServiceCenter.operatorremark,
      textApplication
    );
  }, [textApplication]);

  useEffect(() => {
    if (state.passingApplicationData.length) {
      const { operatorremark } = state.passingApplicationData[0];
      setFieldsMethod(
        form,
        fieldNamesServiceCenter.operatorremark,
        operatorremark
      );
    }
  }, [state.passingApplicationData]);

  return (
    <Row gutter={[24, 24]} className="mb-10">
      <Col span={24}>
        <Form.Item label=" " name={fieldNamesServiceCenter.operatorremark}>
          <TextArea autoSize={{ minRows: 3 }} readOnly />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default ApplicationMessage;
