import React, { Dispatch, SetStateAction } from 'react';
import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import useQuotationJournal from '../hooks/useQuotationJournal';

interface DataType {
  orderno: string;
  nodename: string;
  flow_statusisn_name: string;
  updatedby_name: string;
  updated: string;
  operatorremark: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '№ п/п',
    dataIndex: 'orderno',
    key: 'orderno',
  },
  {
    title: 'Центр обслуживания',
    dataIndex: 'nodename',
    key: 'nodename',
  },
  {
    title: 'Состояние',
    dataIndex: 'flow_statusisn_name',
    key: 'flow_statusisn_name',
  },
  {
    title: 'Руководитель',
    dataIndex: 'updatedby_name',
    key: 'updatedby_name',
  },
  {
    title: 'Исполнена',
    dataIndex: 'updated',
    key: 'updated',
  },
];

type PassingApplicationTableProps = {
  setTextApplication: Dispatch<SetStateAction<string>>
}

function PassingApplicationTable({setTextApplication}: PassingApplicationTableProps) {

  const { state } = useQuotationJournal();

  return (
    <>
      <Table
        columns={columns}
        dataSource={state.passingApplicationData}
        onRow={(record) => {
          return {
            onClick: () => {
              setTextApplication(record.operatorremark)
            },
          };
        }}
        rowKey="orderno"
        scroll={{ x: 1000 }}
      />
    </>
  );
}

export default PassingApplicationTable;
