import React, { useEffect } from 'react';
import { Col, Layout, Row } from 'antd';
import Events from '@modules/Events';
import EventsList from '@modules/Events/EventsList';
import EventProvider from '@modules/Events/Providers/EventProvider';

interface TalkProps {
  title: string;
}

export interface IBooking {
  id: any;
  user_id: any;
  booking_id: any;
  status: any;
  deadline: any;
  range: any;
  booking: IBookingItem[];
  is_author?: boolean;
}

interface IClients {
  id: any;
  full_name: any;
  avatar: {
    link: any;
  };
  status: string;
}

interface IRoomsName {
  id: any;
  name: any;
}

export interface IBookingItem {
	building_name: string;
  id: any;
  deadline: any;
  range: any;
  title: any;
  status: any;
  company_id: any;
  room_id: any;
  begin: any;
  end: any;
  description: any;
  created_by: any;
  updated_by: any;
  rooms_name: IRoomsName[];
  room_name: string;
  company_name: ICompanyName[];
  booking_user: IBookingUser[];
  files: IFiles[];
  permission: IPermission;
  clients: IClients[];
}

interface ICompanyName {
  id: any;
  short_name: any;
  full_name: any;
}

interface IBookingUser {
  id: any;
  user_id: any;
  booking_id: any;
  created_at: any;
  updated_at: any;
  status: any;
  client_id: any;
}

interface IFiles {
  booking_id: any;
  file: any;
}

interface IPermission {
  update: any;
  crate: any;
  delete: any;
}

const Talk: React.FC<TalkProps> = ({ title = '' }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <EventProvider>
      <Row gutter={[30, 30]}>
        <Col span={24} order={2} xxl={{ span: 16, order: 1 }}>
          <Events />
        </Col>
        <Col span={24} order={1} xxl={{ span: 8, order: 2 }}>
          <EventsList />
        </Col>
      </Row>
    </EventProvider>
  );
};

export default Talk;
