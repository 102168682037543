import { Dispatch, SetStateAction } from "react";
import api from "../services/api";
import { IFetchInterview } from "./interface";

const getInterview = async () => {
  const { data } = await api.getInterviewCandidate();
  return data?.data;
};

export const setInterview = async (setFetchInterview: Dispatch<SetStateAction<IFetchInterview[]>>) => {
    getInterview().then((values) => {
      if (values) {
        setFetchInterview(values)
      }
    });
  }