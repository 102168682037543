import axios from "@services/api/axios";

const api = {
    getContractor: (data: { iin: number }) => axios.post("client/iin", data),
    getContractorInfo: (data) => axios.post("contractorCard/search" , data),
    saveContractorKias: (data) => axios.post('contractorCard' , data),
    searchInn: (data: { iin: number }) => axios.post('search/iin', data),
    searchAgreement: (data: {doc_num : string}) => axios.post(`kias/doc/number` , data),
    searchDocByUserIsn: (data: {isn: number}) => axios.post('/searchDocByUserIsn' , data),
    getContractorByISN: (isn) => axios.get(`contractorCard/${isn}`),
    getDocument: (documentID: any) => axios.get(`document/${documentID}`),
}

export default api