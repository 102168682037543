import { Button } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useImmerReducer } from 'use-immer';

import api from './api';
import { getMessageError } from './utils';
import ParseFilter from './components/Filter';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ParseProductList from './components/ProductList';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

export interface IProduct {
  category_id: number;
  id: number;
  name: string;
  parse: IParse[];
}

interface IParse {
  first_premium: number | string;
  second_premium: number | string;
  first_payout: number | string;
  second_payout: number | string;
  amount_premium: number | string;
  amount_payout: number | string;
  proportion_premium_first: number | string;
  proportion_premium_second: number | string;
  proportion_payout_first: number | string;
  proportion_payout_second: number | string;
}

export interface ICategory {
  id: number;
  name: string;
  products: IProduct[];
}

interface ISelectedParams {
  first_date: dayjs.Dayjs;
  second_date: dayjs.Dayjs;
  dateType: 'rise' | 'year' | 'month' | 'quarter';
}
export interface ILabels {
  amount_percent: string;
  amount_sum: string;
  first_date: string;
  first_proportion: string;
  second_date: string;
  second_proportion: string;
}

export interface IData {
  category: ICategory[];
  labels: [ILabels];
}

interface IState {
  data: IData | null;
  isLoading: boolean;
  selectedParams: ISelectedParams;
}

const initialState: IState = {
  data: null,
  isLoading: true,
  selectedParams: {
    first_date: dayjs().startOf('year'),
    second_date: dayjs().startOf('year').subtract(1, 'year'),
    dateType: 'rise',
  },
};

const reducer = (state: IState, action) => {
  switch (action.type) {
    case 'setCompany': {
      state.data = action.payload;
      break;
    }
    case 'loading': {
      state.isLoading = action.payload;
      break;
    }
    case 'updateDateRange': {
      state.selectedParams.first_date = dayjs(action.payload.first_date);
      state.selectedParams.second_date = dayjs(action.payload.second_date);
      break;
    }
    case 'updateDateType': {
      state.selectedParams.dateType = action.payload;
      break;
    }
    default: {
      throw new Error(`Unknown action type: ${action.type}`);
    }
  }
};

export default function ParseCompany() {
  const { state: stateRoute } = useLocation();
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  let { companyID } = useParams();
  const navigate = useNavigate();

  const handleUpdateDateRange = ([first_date, second_date]) => {
    dispatch({
      type: 'updateDateRange',
      payload: {
        first_date,
        second_date,
      },
    });
  };

  const handleUpdateDateType = (dateType) => {
    dispatch({ type: 'updateDateType', payload: dateType });
  };

  const getCompany = () => {
    dispatch({ type: 'loading', payload: true });
    const FormatDate =
      state.selectedParams.dateType === 'quarter' ? 'Q.YY' : 'DD.MM.YY';

    api
      .fetchCompany(companyID, {
        dateType: state.selectedParams.dateType,
        first_date: dayjs(state.selectedParams.first_date).format(FormatDate),
        second_date: dayjs(state.selectedParams.second_date).format(FormatDate),
      })
      .then((company) => {
        dispatch({ type: 'setCompany', payload: company });
      })
      .catch(getMessageError)
      .finally(() => {
        dispatch({ type: 'loading', payload: false });
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    document.title = stateRoute.title;
  }, []);

  return (
    <CustomLoader spinning={state.isLoading}>
      <Button
        className="mb-5"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate('/parse')}
      >
        Список компаний
      </Button>
      <ParseFilter
        title={stateRoute.title || ''}
        selected={state.selectedParams}
        onUpdateDateType={handleUpdateDateType}
        onUpdateDateRange={handleUpdateDateRange}
        onFilter={getCompany}
      />
      {state.data && <ParseProductList data={state.data} />}
    </CustomLoader>
  );
}
