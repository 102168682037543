import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
} from 'antd';
import { ColleaguesListType } from '../../../hooks/useColleagues';
import { HandbookListType } from '../../../hooks/useHandbook';
import { IAtrributeEditor } from '../types';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import TreeSelectHandbook from '@modules/TreeSelect/TreeSelectHandook';
import FieldUser from '@modules/common/Edo/Field/FieldUser';
import FieldUserCard from '@modules/common/Edo/Field/FieldUserCard';

interface Props {
  settings: IAtrributeEditor;
  colleaguesList: ColleaguesListType;
  departmentList: ColleaguesListType;
  handbookList: HandbookListType;
  isDisabledForm: boolean;
  id: number;
  form: any;
}

function EdoAttributeField(props: Props) {
  const {
    settings,
    colleaguesList = [],
    departmentList = [],
    handbookList = [],
    isDisabledForm = false,
    id,
    form,
  } = props;

  if (!settings || !settings.attribute_type) {
    return null; // Или что-то другое, что необходимо при отсутствии settings.attribute_type
  }

  switch (settings.attribute_type.text_code) {
    case 'SUBJECT':
    case 'SUBJECT_SHORT':
      if (
        settings.attribute_type.constant1 == 'cAttrDestination' ||
        settings.attribute_type.constant1 == 'cAttrExecutor'
      ) {
        return (
          <Col span={8} xs={24} xl={8}>
            <FieldUser
              colleaguesList={colleaguesList}
              property="number_value"
              settings={settings}
              isDisabledForm={isDisabledForm}
              id={id}
              attrForm={form}
            />
          </Col>
        );
      } else {
        return (
          <Col span={8} xs={24} xl={8}>
            <FieldUserCard
              title={settings.attribute_type.full_name}
              fieldID={`id-${settings.id}`}
              fieldName="number_value"
              initialValue={settings.def_val}
              required={settings.required}
            />
          </Col>
        );
      }
    case 'EMPL':
    case 'DEPTEMPL':
      return (
        <Col span={8} xs={24} xl={8}>
          <FieldUser
            colleaguesList={colleaguesList}
            property="number_value"
            settings={settings}
            isDisabledForm={isDisabledForm}
          />
        </Col>
      );
    case 'DEPT':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'number_value']}
            rules={[{ required: settings.required }]}
          >
            <TreeSelectCollegues
              colleaguesList={departmentList}
              selectDepartment={true}
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
            />
          </Form.Item>
        </Col>
      );
    case 'DOCS':
    case 'DICTI':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'number_value']}
            rules={[{ required: settings.required }]}
          >
            <TreeSelectHandbook
              handbookList={handbookList}
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
            />
          </Form.Item>
        </Col>
      );
    case 'DUTY':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'number_value']}
            rules={[{ required: settings.required }]}
          >
            <Select
              options={handbookList}
              showSearch
              fieldNames={{ label: 'full_name', value: 'id' }}
              optionFilterProp="full_name"
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
            />
          </Form.Item>
        </Col>
      );
    case 'CHECKBOX':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            // label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'number_value']}
            rules={[{ required: settings.required }]}
            valuePropName="checked"
          >
            <Checkbox
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
            >
              {settings.attribute_type.full_name}
            </Checkbox>
          </Form.Item>
        </Col>
      );

    case 'INTEGER':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'number_value']}
            rules={[{ required: settings.required }]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
              parser={(x) => parseFloat(`${x}`.replace(/\s/g, ''))}
              style={{ width: '100%' }}
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
            />
          </Form.Item>
        </Col>
      );

    case 'NUMBER':
    case 'FLOAT':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'number_value']}
            rules={[{ required: settings.required }]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
              parser={(x) => parseFloat(`${x}`.replace(/\s/g, ''))}
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      );
    case 'TIME':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'date_value']}
            rules={[{ required: settings.required }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              picker="time"
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
            />
          </Form.Item>
        </Col>
      );
    case 'DATE':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'date_value']}
            rules={[{ required: settings.required }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="DD.MM.YYYY"
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
            />
          </Form.Item>
        </Col>
      );
    case 'DATETIME':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'date_value']}
            rules={[{ required: settings.required }]}
          >
            <DatePicker
              showTime
              style={{ width: '100%' }}
              format="DD.MM.YYYY hh:mm:ss"
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
            />
          </Form.Item>
        </Col>
      );
    case 'TEXT':
      return (
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label={settings.attribute_type.full_name}
            name={[`id-${settings.id}`, 'char_value']}
            rules={[{ required: settings.required }]}
          >
            <Input
              disabled={isDisabledForm ? isDisabledForm : settings.readonly}
            />
          </Form.Item>
        </Col>
      );
    default:
      return null;
  }
}

export default EdoAttributeField;
