import { Card, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { TableColumnsType } from 'antd';
import { ICategory, IData, IProduct } from '../Company';
import { formatValue } from '../utils';

const { Title, Text } = Typography;
interface Props {
  data: IData;
}

const y1 = ({ a, b }: { a: number; b: string }) => {};

const renderTotal = ({
  record,
  propName,
  isProportion = false,
}: {
  record: ICategory;
  propName: string;
  isProportion?: boolean;
}) => {
  const total = record.products.find(
    (item) => item.name.toLowerCase() === 'итого'
  );

  if (total !== undefined) {
    const { parse } = total;
    const [parseList] = parse;

    return formatValue(parseList[propName]) + (isProportion ? '%' : '');
  }

  // return null;
};

export default function ParseProductList(props: Props) {
  const { data } = props;
  let labels = data.labels[0];

  const columns: ColumnsType<ICategory> = [
    {
      title: 'Классы',
      dataIndex: 'name',
      render: (title: string) => <Text strong>{title}</Text>,
      width: 240,
    },
    {
      title: 'Премии',
      children: [
        {
          title: labels.first_date,
          width: 140,
          render: (_, record) =>
            renderTotal({
              record: record,
              propName: 'first_premium',
            }),
        },
        {
          title: labels.second_date,
          width: 140,
          render: (_, record) =>
            renderTotal({ record, propName: 'second_premium' }),
        },
        {
          title: labels.first_proportion,
          width: 120,
          render: (_, record) =>
            renderTotal({
              record,
              propName: 'proportion_premium_first',
              isProportion: true,
            }),
        },
        {
          title: labels.second_proportion,
          width: 120,
          render: (_, record) =>
            renderTotal({
              record,
              propName: 'proportion_premium_second',
              isProportion: true,
            }),
        },
        {
          title: labels.amount_sum,
          width: 140,
          render: (_, record) =>
            renderTotal({ record, propName: 'amount_premium' }),
        },
      ],
    },
    {
      title: 'Выплаты',
      children: [
        {
          title: labels.first_date,
          width: 140,
          render: (_, record) =>
            renderTotal({ record, propName: 'first_payout' }),
        },
        {
          title: labels.second_date,
          width: 140,
          render: (_, record) =>
            renderTotal({ record, propName: 'second_payout' }),
        },
        {
          title: labels.first_proportion,
          width: 120,
          render: (_, record) =>
            renderTotal({
              record,
              propName: 'proportion_payout_first',
              isProportion: true,
            }),
        },
        {
          title: labels.second_proportion,
          width: 120,
          render: (_, record) =>
            renderTotal({
              record,
              propName: 'proportion_payout_second',
              isProportion: true,
            }),
        },
        {
          title: labels.amount_sum,
          width: 140,
          render: (_, record) =>
            renderTotal({ record, propName: 'amount_payout' }),
        },
      ],
    },
  ];

  const expandedRowRender = (record) => {
    const columnsA: TableColumnsType<IProduct> = [
      { dataIndex: 'name', width: 240 },
      {
        dataIndex: ['parse', 0, 'first_premium'],
        width: 140,
        render: (text) => formatValue(text),
      },
      {
        dataIndex: ['parse', 0, 'second_premium'],
        width: 140,
        render: (text) => formatValue(text),
      },
      {
        dataIndex: ['parse', 0, 'proportion_premium_first'],
        width: 120,
        render: (text) => text + '%',
      },
      {
        dataIndex: ['parse', 0, 'proportion_premium_second'],
        width: 120,
        render: (text) => text + '%',
      },
      {
        dataIndex: ['parse', 0, 'amount_premium'],
        width: 140,
        render: (text) => formatValue(text),
      },

      {
        dataIndex: ['parse', 0, 'first_payout'],
        width: 140,
        render: (text) => formatValue(text),
      },
      {
        dataIndex: ['parse', 0, 'second_payout'],
        width: 140,
        render: (text) => formatValue(text),
      },
      {
        dataIndex: ['parse', 0, 'proportion_payout_first'],
        width: 120,
        render: (text) => text + '%',
      },
      {
        dataIndex: ['parse', 0, 'proportion_payout_second'],
        width: 120,
        render: (text) => text + '%',
      },
      {
        dataIndex: ['parse', 0, 'amount_payout'],
        width: 140,
        render: (text) => formatValue(text),
      },
    ];

    return (
      <Table
        showHeader={false}
        columns={columnsA}
        dataSource={record.products.filter(
          (item) => item.name.toLowerCase() !== 'итого'
        )}
        pagination={false}
      />
    );
  };

  return (
    <Card>
      <Table
        dataSource={data.category.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
        bordered
        // 50px expand icon
        scroll={{ x: 'calc(1560px + 50px)' }}
        pagination={false}
        expandable={{ expandedRowRender, defaultExpandAllRows: true }}
      />
    </Card>
  );
}
