import React, { useEffect } from 'react';
import { Layout } from 'antd';

import onProcess from '../../assets/onProcess.jpg';
import Bday from '@modules/Bday';

const { Sider, Content } = Layout;

const OnProcess = ({ title = 'Mycent.kz' }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Content>
        <img src={onProcess} />
      </Content>
      <Sider
        className="sbday"
        style={{
          backgroundColor: '#f0f2f5',
        }}
        width={399}
      >
        <Bday />
      </Sider>
    </>
  );
};

export default OnProcess;
