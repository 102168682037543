import { CheckCircleTwoTone, CloseCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { Col, Divider, List, Row, Space, Typography } from 'antd';

interface IVoting {
	id: number,
	name: string,
	duty: null | string,
	type: string,
	voice: number,
}
interface IVotingList {
	dataTable: IVoting[]
}

const { Title } = Typography;
const VotingList = ({ dataTable }: IVotingList) => {

	const getStatus = (voice : number) => {
		switch (voice) {
			case 0:
				return (
					<Space>
						Отказал
						<CloseCircleTwoTone twoToneColor="#EB5757" />
					</Space>
				)
			case 1:
				return (
					<Space>
						Согласовал
						<CheckCircleTwoTone twoToneColor="#38C43E" />
					</Space>
				)
			case 2:
				return (
					<Space>
						Воздержался
						<WarningTwoTone twoToneColor="#808080"/>
					</Space>
				)
			default:
				break;
		}
	};

	return (
		<>
			<Divider/>
			<List
				size="small"
				header={<Title level={5}>Результаты голосования</Title>}
				footer={null}
				bordered
				className="w-100"
				dataSource={dataTable}
				renderItem={(item) => <List.Item>
					<Row className="w-100">
						<Col span={6}>{item?.name}</Col>
						<Col>{getStatus(item.voice)}</Col>
					</Row>
				</List.Item>}
			/>
			<Divider/>
		</>
	);
};

export default VotingList;