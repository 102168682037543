import { useCallback, useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import axios from 'axios';
import { IRoomList } from '@modules/Events';
import api from '../services/api';
import EventContext from '@contexts/EventContent';
import useAuth from '@hooks/useAuth';
import { IBooking } from '@pages/Talk/Talk';

interface IEventProvider {
  children: React.ReactNode;
}

const EventProvider: React.FC<IEventProvider> = (props) => {
  const auth = useAuth();

  const [totalList, setTotalList] = useState<IBooking[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompanyList, setIsLoadingCompanyList] = useState(false);
  const [companiesList, setCompaniesList] = useState<[]>([]);
  const [roomsList, setRoomsList] = useState<IRoomList[]>([]);
  const [fetchedColleagues, setFetchedColleagues] = useState<any>();

  const getUserBookingMethod = useCallback(async () => {
    setIsLoading(true);
    try {
      const getUserBooking = await api.getUserBooking(auth.user?.data?.id);
      setTotalList(getUserBooking?.data?.data);
    } catch (error) {
      let message;
      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (auth?.user?.data?.id) {
      getUserBookingMethod();
    }
  }, [getUserBookingMethod]);

  const getRoomList = async () => {
    setIsLoadingCompanyList(true);
    try {
      const { data } = await api.getRoomList();
      setRoomsList(data?.rooms);
      setCompaniesList(data?.buildings);
    } catch {
    } finally {
      setIsLoadingCompanyList(false);
    }
  };

  useEffect(() => {
    getRoomList();
  }, []);

  const getColleagues = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.getColleagues();
      setFetchedColleagues(data?.data);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getColleagues();
  }, []);

  const contextValue = useMemo(
    () => ({
      totalList,
      setTotalList,
      companiesList,
      isLoadingCompanyList,
      isLoading,
      roomsList,
      getUserBookingMethod,
      fetchedColleagues,
    }),
    [
      getUserBookingMethod,
      companiesList,
      roomsList,
      fetchedColleagues,
      totalList,
    ]
  );

  return (
    <EventContext.Provider value={contextValue}>
      {props.children}
    </EventContext.Provider>
  );
};

export default EventProvider;
