import './CustomLoader.scss';
import * as React from 'react';

interface SpinProps {
  spinning?: boolean;
  children?: React.ReactNode;
}

const CustomLoader: React.FC<SpinProps> = ({ spinning = true, children }) => {
  return (
    spinning ? <>
        <div data-testid="custom-loader" className="content-container">
          <div className="backdrop"></div>
          <div className="container-loader">
            <span className="loader"></span>
          </div>
          {children}
        </div>
      </> :
      children ? <div>{children}</div> : null
  )
};

export default CustomLoader;