import axios from '@services/api/axios';

const api = {
  getBooking: () => axios.get('/booking'),
  getCompanyList: () => axios.get('/booking/users'),
  getRoomList: () => axios.get('booking-rooms'),
  getColleagues: () => axios.get('/client/tree'),
  sendBookingEvent: (data) => axios.post('/booking', data),
  sendBookingFile: (data) =>
    axios.post('/booking', data, {
      headers: { 'Content-type': 'multipart/form-data' },
    }),
  updBooking: (userId, data) => axios.put(`/booking/users/${userId}`, data),
  getUserBooking: (userId) => axios.get(`/booking/users/${userId}`),
  delUserBooking: (userId, data) => axios.put(`/booking/users/${userId}`, data),
  getCountEvents: () => axios.get('booking-users-count'),
  getBookingCalendar: (roomId: number) => axios.get(`booking/room/${roomId}`),
  deleteBooking: (id) => axios.delete(`booking/${id}`),
  getColleagueInfo: (id: number) => axios.get('/user/info/' + id),
  getUserInfoFromBotan: (id) => axios.get(`/booking/botan/${id}`),
};

export default api;
