import { Card, Typography, Collapse } from 'antd';
import axios from '@services/api/endpoints/parse';
import './PowerBi.scss';
import { useEffect, useState } from 'react';
const { Title } = Typography;


function PowerBi() {

  const collapsePanelStyle: any = { maxHeight: '1000px', overflowY: 'hidden' };
  // const lastPagePanel: any = { maxHeight: '1500px', overflowY: 'hidden' };

  const [parse, setParse] = useState<any>([]);

  useEffect(() => {
    // для начало получаем самого юзера и получаем его id
    axios.getProfile().then((response) => {
      if(response.status === 200) {
        // вызываем доступные графики для этого юзера
        axios.getParseBytUser((response.data.data.id)).then((response) => {
          // сохраняем графики в state
          setParse(response.data.data);
        }).catch(() => {
          setParse([]);
        });
      }
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  useEffect(() => {
    // для проверки
    console.log('parse', parse);
  }, [parse]);
  
  return (
    <Card className="mb-6">
      <Title type="secondary" level={5}>
        Аналитические графики
      </Title>
      <Collapse accordion expandIconPosition="right">
        {
          parse.length ? parse.map((item: any) => {
            return (
              <Collapse.Panel header={item.title} key={item.id} style={collapsePanelStyle}>
                <iframe
                  title={item.title}
                  width="100%"
                  height={1060}
                  src={item.url}
                  className="frame-border"
                ></iframe>
              </Collapse.Panel>
            )
          }) : <div style={{padding: '20px'}}>Нет доступных аналитических графиков!</div>
        }
        {/* Временно  */}
        {/* <Collapse.Panel header="Отчет по выплатам" key="12" style={lastPagePanel}> 
          <iframe
            title="Budget 2023 20230315 1 - Budget"
            width="100%"
            height={1060}
            src="https://app.powerbi.com/view?r=eyJrIjoiMjQ3OWQ2NDItYmNjMS00ZTc0LWFiNGMtMDAyZjM2OTcxMTE3IiwidCI6IjhlZDIxNWNhLWU2NjktNGEyOC04ZDcwLTdjMGE0MWVmMGY5MiIsImMiOjl9"
            className="frame-border"
          ></iframe>
        </Collapse.Panel> */}
      </Collapse>
    </Card>
  );
}

export default PowerBi;
