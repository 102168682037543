import { notification } from 'antd';
import axios from 'axios';
import api from '../services/api';
import { Dispatch, SetStateAction } from 'react';

const submitForm = async (
  data,
  setLocaleStorage,
  navigateToMainPage: () => void,
  setIsLoadingSubmitForm: Dispatch<SetStateAction<boolean>>
) => {
  try {
    setIsLoadingSubmitForm(true)
    const response = await api.saveNewApplication(data);
    if (response?.data?.success === true) {
      notification.success({
        message: 'Уведомление',
        description: 'Заявка успешно создана',
        duration: 5,
      });
      setLocaleStorage({});
      navigateToMainPage();
    }
  } catch (error) {
    let message;

    if (axios.isAxiosError(error)) {
      message = error.message;
    } else {
      message = String(error);
    }

    notification.info({
      message: 'Ошибка',
      description: message,
    });
  } finally {
    setIsLoadingSubmitForm(false)
  }
};

export default submitForm;
