import { Button, Empty, Table, Collapse } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect } from 'react';

import { AgreementType, AgreementShort } from '../type';

const { Panel } = Collapse;

interface AgreementListProps {
  agreementList: AgreementType;
  onAgreementIDSelect: (agreementID: number, type: string) => void;
}

enum FieldList {
  INFO = 'info',
  TYPE = 'type',
  DOCUMENT_ID = 'document_id',
  CREATED_AT = 'created_at',
  EMPLOYEE = 'employee',
  FULLNAME = 'full_name',
  DUTY = 'duty',
  DEPARTMENT = 'department',
}

const sortByDate = (itemA: AgreementShort, itemB: AgreementShort) =>
  new Date(itemB.info.created_at).getTime() -
  new Date(itemA.info.created_at).getTime();

export default function AgreementList(props: AgreementListProps) {
  const { agreementList, onAgreementIDSelect = () => {} } = props;
  const handleSelectAgreementID = (documentID: number, type: string): void =>
    onAgreementIDSelect(documentID, type);
  const columns: ColumnsType<AgreementShort> = [
    {
      title: 'Источник',
      dataIndex: FieldList.TYPE,
      filters: [
        {
          text: 'mycent',
          value: 'mycent',
        },
        {
          text: 'kias',
          value: 'kias',
        },
      ],
      onFilter: (value: any, record) => record.type.indexOf(value) === 0,
    },
    {
      title: 'Тип документа',
      dataIndex: [FieldList.INFO, FieldList.FULLNAME],
      render: (value: string, record) => (
        <Button
          type="link"
          onClick={() => {
            handleSelectAgreementID(record.document_id, record.type);
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      title: '№',
      dataIndex: [FieldList.INFO, FieldList.DOCUMENT_ID],
    },
    {
      title: 'Дата',
      dataIndex: [FieldList.INFO, FieldList.CREATED_AT],
      render: (value) => dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: 'Куратор',
      dataIndex: [FieldList.EMPLOYEE, FieldList.FULLNAME],
    },
    {
      title: 'Подразделение',
      dataIndex: [FieldList.EMPLOYEE, FieldList.DEPARTMENT],
    },
  ];
  const onChange = (key: string | string[]) => {};
  useEffect(() => {}, [agreementList]);
  if (!Object.keys(agreementList).length) {
    return <Empty description="Нет документов на согласование" />;
  }
  return (
    <Collapse onChange={onChange}>
      {Object.keys(agreementList).map((item, index) => (
        <Panel header={agreementList[item].doc_name} key={index + 1}>
          <Table
            columns={columns}
            dataSource={
              agreementList[item].doc_list
                ? [...agreementList[item].doc_list].sort(sortByDate)
                : []
            }
            scroll={{ x: 1000 }}
            rowKey="id"
          />
        </Panel>
      ))}
    </Collapse>
  );
}
