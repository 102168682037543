import { useLocation } from 'react-router-dom';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import './ReaderFileLibrary.scss';
import { useEffect } from 'react';
import useAuth from '@hooks/useAuth';

const ReaderFileLibrary = () => {
  const {user} = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search.replace(/\+/g, '%2B'));
  const uuid = searchParams.get("uuid");
  const contentType = searchParams.get("contentType");
  const fullPath = `https://mycent.kz/api/online-library/get-raw-file/${uuid}?user_id=${user?.data?.id}`;

  const getExtensionFromMimeType = (mimeType) => {
    switch (mimeType) {
      case 'application/msword':
        return 'doc';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'application/pdf':
        return 'pdf';
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpeg';
      case 'image/jpg':
        return 'jpg';
      case 'image/webp':
        return 'webp';
      case 'image/gif':
        return 'gif';
      case 'image/tiff':
        return 'tiff';
      case 'application/vnd.ms-excel':
        return 'xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        return 'xlsm';
      case 'video/mp4':
        return 'mp4'
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'pptx'
      default:
        return '';
    }
  }

  const docs = [
    {
      uri: fullPath,
      fileType: getExtensionFromMimeType(contentType),
    },
  ];

  const extensions = {
    png: 'png',
    jpg: 'jpg',
    jpeg: 'jpeg',
    webp: 'webp',
  };

  useEffect(() => {
    document.title = "Онлайн библиотека";
  }, []);


  if (extensions[getExtensionFromMimeType(contentType)]) {
    return (
      <img src={fullPath} width="100%" height="100%" alt="image"/>
    )
  }

  if (getExtensionFromMimeType(contentType) === 'pdf' || getExtensionFromMimeType(contentType) === 'mp4') {
    return (
      <div className="pdf-viewer">
        <iframe
          src={fullPath}
          title="Viewer"
          width="100%"
          height="100%"
          className="iframe"
        />
      </div>
    )
  } else {
    return (
      <div className="doc-viewer">
        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
        />
      </div>
    );
  }
};

export default ReaderFileLibrary;