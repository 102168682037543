import CButton from '@components/UI/button/CButton';
import CInput from '@components/UI/input/CInput';
import { agrCalcMethod } from '@modules/Quotation/MainButtons/utils/agrCalcMethod';
import { Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';

function MainContractButtons({ userForeignId, agrIsn, premiumSum }) {
  const [premiuimForm] = Form.useForm();
  const [isLoadingCalcButton, setIsLoadingCalcButton] = useState(false);
  const [premiumTotal, setPremiumTotal] = useState(0);
  const callSaveQuotation = () => {
    try {
      agrCalcMethod(
        (userForeignId, agrIsn) => {
          return { user_isn: userForeignId, agr_isn: agrIsn };
        },
        setPremiumTotal,
        userForeignId,
        agrIsn,
        setIsLoadingCalcButton
      );
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (premiumTotal === 0) return;
    premiuimForm.setFieldsValue({
      agrCalculateData: premiumTotal,
    });
  }, [premiumTotal]);
  useEffect(() => {
    if (premiumSum === 0) return;
    premiuimForm.setFieldsValue({
      agrCalculateData: premiumSum,
    });
  }, [premiumSum]);

  return (
    <Form
      name="restQuotation"
      form={premiuimForm}
      layout="vertical"
      className="c-form"
    >
      <Row className="mt-3">
        <Col xs={24} md={6} className="mr-3">
          <CButton
            type="primary"
            block
            onClick={callSaveQuotation}
            loading={isLoadingCalcButton}
            htmlType="submit"
            // disabled={stateDateQuotation?.status_isn !== 223368}
          >
            Рассчитать стоимость
          </CButton>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            name="agrCalculateData"
            label="Сумма общей премии"
            // initialValue={agrContractor?.premium_sum_total}
          >
            <CInput
              className="numericMask"
              readOnly
              theme="light"
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
export default MainContractButtons;
