import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fieldNamesRecruiting } from '../Common/fieldNames';
import InterviewFields from './InterviewFields';
import useRecruiting from '../hooks/useRecruiting';
import ResultInterviewFields from './ResultInterviewFields';
import CandidateFields from './CandidateFields';
import { updateForm } from './updateForm';
import dayjs from 'dayjs';
import api from '../services/api';

const { Title } = Typography;

const { Option } = Select;

function CandidateRecruiting(props) {
  const [recruitingId, setRecruitingId] = useState<number>(0);
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [hasDirPermission, setHasDirPermission] = useState<boolean>(false);
  const [fieldIINDisabled, setFieldIINDisabled] = useState<boolean>(false);
  const [hasSecurityPermission, setHasSecurityPermission] =
    useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [candidateId, setCandidateId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { title } = props;

  const navigate = useNavigate();

  const { state } = useRecruiting();

  const { selectValues } = state;

  const [formCandidate] = Form.useForm();

  const [formRest] = Form.useForm();

  const { id } = useParams();

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (id) {
      getCandidate(id).then((values) => {
        setCandidateFields(values);
        setRestFields(values);
        setRecruitingId(values?.recruiting_id?.id);
        setCandidateId(values?.id);
        setHasPermission(values?.permission);
        setHasDirPermission(values?.dir_permission);
        setHasSecurityPermission(values?.security_service_permission);
        if (values?.cv !== null) {
          setFileName(values.cv);
        }
        if (values.candidate_iin === null) {
          setFieldIINDisabled(true);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (fileName !== '') {
      const fileData = {
        uid: '1',
        name: fileName,
      };

      setFormFields(formCandidate, fieldNamesRecruiting.file, [fileData]);
    }
  }, [fileName]);

  const getCandidate = async (id: string) => {
    const { data } = await api.getCandidateById(id);
    return data?.data;
  };

  const setCandidateFields = (values) => {
    setFormFields(
      formCandidate,
      fieldNamesRecruiting.candidate_fullname,
      values.candidate_fullname
    );
    setFormFields(
      formCandidate,
      fieldNamesRecruiting.department_to,
      values.recruiting_id.department_to
    );
    setFormFields(
      formCandidate,
      fieldNamesRecruiting.candidate_phone_number,
      values.candidate_phone_number
    );
    setFormFields(
      formCandidate,
      fieldNamesRecruiting.candidate_iin,
      values.candidate_iin
    );
  };

  const setRestFields = (values) => {
    setFormFields(
      formRest,
      fieldNamesRecruiting.name_of_post_id,
      values.recruiting_id.name_of_post_id
    );
    setFormFields(
      formRest,
      fieldNamesRecruiting.quantity_people,
      values.recruiting_id.quantity_people
    );
    setFormFields(
      formRest,
      fieldNamesRecruiting.interview_result,
      values.interview_result
    );
    setFormFields(formRest, fieldNamesRecruiting.commentary, values.commentary);
    setFormFields(
      formRest,
      fieldNamesRecruiting.security_service_comment,
      values.security_service_comment
    );
    setFormFields(
      formRest,
      fieldNamesRecruiting.security_service_result,
      values.security_service_result
    );

    setDateFields(
      values.date_of_conclusion_dou,
      fieldNamesRecruiting.date_of_conclusion_dou
    );
    setDateFields(values.interview_date, fieldNamesRecruiting.interview_date);
    setDateFields(
      values.interview_time,
      fieldNamesRecruiting.interview_time,
      'HH:mm'
    );
  };

  const setDateFields = (
    value: null | string,
    fieldName: string,
    format?: string
  ) => {
    if (typeof value === 'string' && !format) {
      setFormFields(formRest, fieldName, dayjs(value));
    } else if (typeof value === 'string' && format) {
      setFormFields(formRest, fieldName, dayjs(value, format));
    }
  };

  const setFormFields = (form, field, value) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  return (
    <>
      <Card style={{ marginTop: 25, marginBottom: 300 }}>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          type="default"
          className="mb-3"
        >
          Назад
        </Button>

        <Title level={4} style={{ color: '#0057FF', marginBottom: 30 }}>
          Кандидат
        </Title>

        <Form
          layout="vertical"
          validateMessages={{ required: 'Обязательное поле для заполнения' }}
          name="candidate"
          form={formCandidate}
          onFinish={(values) => {
            updateForm(id, recruitingId, values, setIsLoading);
          }}
          style={{ fontWeight: 'initial' }}
          disabled={isLoading}
        >
          <CandidateFields
            hasPermission={hasPermission}
            fileName={fileName}
            candidateId={candidateId}
            recruitingId={recruitingId}
            fieldIINDisabled={fieldIINDisabled}
          />
        </Form>

        <Divider />

        <Title level={4} style={{ color: '#0057FF', marginBottom: 30 }}>
          Остальные данные
        </Title>

        <Form
          layout="vertical"
          validateMessages={{ required: 'Обязательное поле для заполнения' }}
          name="rest"
          form={formRest}
          onFinish={(values) => {
            updateForm(id, recruitingId, values, setIsLoading);
          }}
          style={{ fontWeight: 'initial' }}
          disabled={isLoading}
        >
          <Row gutter={24} align="bottom" justify={{ lg: 'start' }}>
            <Col xs={24} lg={{ span: 8 }}>
              <Form.Item
                label="Вакансия"
                name={fieldNamesRecruiting.name_of_post_id}
              >
                <Select style={{ width: '100%' }} disabled>
                  {selectValues.name_of_post_id.length &&
                    selectValues.name_of_post_id.map((element) => (
                      <Option value={element.id} key={element.id}>
                        {element.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <InterviewFields disabled />

            <ResultInterviewFields
              hasPermission={hasPermission}
              hasDirPermission={hasDirPermission}
              hasSecurityPermission={hasSecurityPermission}
            />
          </Row>
        </Form>
      </Card>
    </>
  );
}

export default CandidateRecruiting;
