import { Layout, FloatButton } from 'antd';
import { Outlet } from 'react-router-dom';
import { UpOutlined } from '@ant-design/icons';

import LayoutContainer from '@layouts/Container';
import PageHeader from '@modules/common/PageHeader';
import PageSider from '@modules/common/PageSider';
import { useEffect, useState } from 'react';

const { Content } = Layout;

export default function LayoutDefault() {
  const [showSide, setShowSide] = useState(true);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('showSide') === 'false') {
      setShowSide(false);
    }
  }, []);
  return (
    <Layout hasSider>
      {showSide && <PageSider />}
      <LayoutContainer>
        {showSide && <PageHeader />}
        <Content>
          <Outlet />
        </Content>
      </LayoutContainer>
      <FloatButton.BackTop>
        <div className="back-top">
          <UpOutlined />
        </div>
      </FloatButton.BackTop>
    </Layout>
  );
}
