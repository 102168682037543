import axios from '@services/api/axios';

const api = {
  getHierarchy: (id) => axios.get(`online-library/category-hierarchy/${id}`),
  getCategories: () => axios.get('online-library/categories'),
  getCategory: (id) => axios.get(`online-library/categories/${id}`),
  getSubCategories: (id) => axios.get(`online-library/get-file-list-by-category-id/${id}`),
  deleteCategory: (id, userId) => axios.delete(`online-library/categories/${id}?deleted_by=${userId}`),
  deleteDocument: (uuid, useId) => axios.delete(`online-library/file/delete/${uuid}?deleted_by=${useId}`),
  editCategory: (id, data) => axios.put(`online-library/categories/${id}`, data),
  searchDocuments: (value) => axios.get(`online-library/search?query=${value}`),
  addCategory: (requestData) => axios.post(`online-library/categories`, requestData),
  readDocument: (uuid, userId) => axios.get(`online-library/get-raw-file/${uuid}?user_id=${userId}`),
  downloadDocument: (uuid) => axios.get(`online-library/download/${uuid}`)
};

export default api;
