import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Card, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import api from '@modules/Quotation/services';

import CreateQuotation from './CreateQuotation';
import TableQuotation from './TableQuotation';
import MainQuotation from './MainQuotation';
import JournalQuotation from './JournalQuotation/JournalQuotation';
import useQuotationJournal from './hooks/useQuotationJournal';
import useQuotation from './hooks/useQuotation';
import { AgrDateT } from './providers/QuotationJournalProvider';
import { TypeAction } from './providers/JournalReducer';
import useAuth from '@hooks/useAuth';
import CSegmented from '@components/UI/segmented/CSegmented';
import CButton from '@components/UI/button/CButton';
import JournalAgreement from '@modules/Quotation/JournalAgreement/JournalAgreement';
import TableContract from '@modules/Quotation/TableContract';

const QuotationComponent = () => {
  const [isShowForm, setIsShowForm] = useState(false);
  const [transitionQuotation, setTransitionQuotation] = useState(false);
  const [filterQuotation, setFilerQuotation] = useState(false);
  const [filterAgreement, setFilerAgreement] = useState(false);
  const [dataAfterSubmitAndClear, setDataAfterSubmitAndClear] = useState(null);
  const [dataAfterSubmitAndClear2, setDataAfterSubmitAndClear2] =
    useState(null);

  const [quotationId, setQuotationId] = useState('');
  const [agrIsn, setAgrIsn] = useState(0);
  const [productName, setProductName] = useState('');
  const [activePage, setActivePage] = useState('quotation');

  const auth = useAuth();

  const navigate = useNavigate();
  let location = useLocation();
  const {
    fetchAllSections,
    dispatch,
    setJournalData,
    clearJournalStateMethod,
  } = useQuotationJournal();

  const { setProductSelectedQuotation } = useQuotation();

  useEffect(() => {
    const { pathname } = location;

    if (pathname === '/quotation') {
      setTransitionQuotation(false);
    }
  }, [location]);

  const { journalQuotationId } = useParams();

  function createNewQuotation() {
    navigate(`/quotation/product`);
    setIsShowForm(true);
  }

  async function transitionQuotationMethod(quotationId) {
    navigate(quotationId);
  }

  async function setFullQuotation(product_id: number) {
    const { data } = await api.quotation.getFullQuotation(product_id);
    setProductSelectedQuotation(data.data);
  }

  const setFullQuotationMethod = (
    fullQuotation: AgrDateT[],
    journalQuotationId: string | undefined
  ) => {
    if (fullQuotation.length) {
      setProductName(fullQuotation[0].product_name);
      setAgrIsn(fullQuotation[0].id);

      dispatch({ type: TypeAction.agrDate, payload: fullQuotation[0] });

      fetchAllSections(
        fullQuotation[0].id,
        fullQuotation[0].user_id,
        journalQuotationId
      );
      setFullQuotation(fullQuotation[0].product_id);
    }
  };

  useEffect(() => {
    (async () => {
      if (typeof journalQuotationId === 'undefined') return;

      const userIsn = auth.user.data.user_info.foreign_id;

      const payloadData = {
        user_isn: userIsn,
        quotation_id: journalQuotationId,
      };
      const { data } = await api.quotationJournal.getFullQuotationJournal(
        payloadData
      );

      await api.quotation.setGraph(payloadData);
      if (Object.keys(data?.data).length) {
        const sectionsData = data.data;

        const { full_quotation, orderDa } = sectionsData;

        setQuotationId(journalQuotationId);

        setFullQuotationMethod(full_quotation, journalQuotationId);

        setJournalData(sectionsData);

        if (orderDa.length) {
          dispatch({
            type: TypeAction.passingApplicationData,
            payload: orderDa,
          });
        }
      }
      setTransitionQuotation(true);
    })();

    return () => {
      clearJournalStateMethod();
    };
  }, [journalQuotationId]);

  /**
   * Переключает вкладки котировки/договоры
   * @param e {string} - выбранная вкладка 'quotation' или 'agreement'
   */
  const switchTab = (e) => {
    setActivePage(e);
  };

  return (
    <>
      {!transitionQuotation && !isShowForm ? (
        <>
          <Row gutter={[24, 32]}>
            <Col span={8} xs={24} xl={14}>
              <CSegmented
                options={[
                  {
                    label: 'Журнал котировок',
                    value: 'quotation',
                  },
                  {
                    label: 'Журнал договоров',
                    value: 'agreement',
                  },
                ]}
                size="large"
                className="w-100 bold"
                onChange={switchTab}
              />
            </Col>
            <Col xs={24} xl={{ span: 8, offset: 2 }}>
              <CButton
                type="primary"
                icon={<PlusOutlined />}
                onClick={createNewQuotation}
                block
                size="large"
                className="c-button_between"
              >
                Новая полная котировка
              </CButton>
            </Col>

            <Col span={8} xs={24} xl={24}>
              <Card>
                <div
                  style={{
                    display: activePage === 'quotation' ? 'block' : 'none',
                  }}
                >
                  <JournalQuotation
                    setFilerProduct={setFilerQuotation}
                    setDataAfterSubmitAndClear={setDataAfterSubmitAndClear}
                  />
                </div>
                <div
                  style={{
                    display: activePage === 'agreement' ? 'block' : 'none',
                  }}
                >
                  <JournalAgreement
                    setFilerProduct={setFilerAgreement}
                    setDataAfterSubmitAndClear={setDataAfterSubmitAndClear2}
                  />
                </div>
              </Card>
            </Col>
          </Row>

          <div
            style={{
              display:
                filterQuotation && activePage === 'quotation'
                  ? 'block'
                  : 'none',
            }}
          >
            <TableQuotation
              dataAfterSubmitAndClear={dataAfterSubmitAndClear}
              transitionQuotationMethod={transitionQuotationMethod}
            />
          </div>
          <div
            style={{
              display:
                filterAgreement && activePage === 'agreement'
                  ? 'block'
                  : 'none',
            }}
          >
            <TableContract dataAfterSubmitAndClear={dataAfterSubmitAndClear2} />
          </div>
        </>
      ) : (
        <>
          <CreateQuotation transitionQuotation={transitionQuotation} />
        </>
      )}
      {transitionQuotation && (
        <MainQuotation
          agrIsn={agrIsn}
          quotationId={quotationId}
          productName={productName}
        />
      )}
    </>
  );
};

export default QuotationComponent;
