import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import { Button, Card, Col, DatePicker, Form, Row } from 'antd';
import useColleagues from '@hooks/useColleagues';

import '../../common/width.scss';
import SpeakerModal from './SpeakerModal';
import { useImmerReducer } from 'use-immer';
import { initialState, reducer } from '../reducer/reducer';
import {
  DownloadOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useEffect } from 'react';
import { api, IGetSpeakersFilters } from '../api';
import { getMessageError } from '../api/catchError';
import { SpeakerTable } from './SpeakerTable';
import dayjs from 'dayjs';

export const getSpeakers = async (dispatch) => {
  try {
    dispatch({ type: 'isLoading', payload: true });
    const data = await api.getSpeakers();
    dispatch({ type: 'speakers', payload: data });
  } finally {
    dispatch({ type: 'isLoading', payload: false });
  }
};

const FILTER_SEARCH = 1;

const FILTER_DOWNLOAD = 2;

function Speaker() {
  const { colleaguesList, isLoadingColleaguesList } = useColleagues();

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  const { isLoading } = state;

  const [form] = Form.useForm();

  useEffect(() => {
    getSpeakers(dispatch).catch(getMessageError);
    return () => {
      dispatch({ type: 'reset', payload: initialState });
    };
  }, []);

  const onFilterSubmit = (id: 1 | 2) => {
    const values: IGetSpeakersFilters = form.getFieldsValue();

    let { date_begin, date_end, user_id } = values;

    if (date_begin && date_begin !== null) {
      values.date_begin = dayjs(date_begin).format('YYYY-MM-DD');
    }

    if (date_end && date_end !== null) {
      values.date_end = dayjs(date_end).format('YYYY-MM-DD');
    }

    if (id === FILTER_SEARCH) {
      filterSearch(values);
    } else if (id === FILTER_DOWNLOAD) {
      filterSearchFile(values);
    }
  };

  const filterSearch = async (values: IGetSpeakersFilters) => {
    try {
      dispatch({ type: 'isLoading', payload: true });
      const data = await api.getFilterSearch(values);
      dispatch({ type: 'speakers', payload: data });
    } finally {
      dispatch({ type: 'isLoading', payload: false });
    }
  };

  const filterSearchFile = async (values: IGetSpeakersFilters) => {
    try {
      dispatch({ type: 'isLoading', payload: true });
      const payloadData = {};
      if (values.date_begin && values.date_begin !== null) {
        payloadData['date_begin'] = values.date_begin;
      }
      if (values.date_end && values.date_end !== null) {
        payloadData['date_end'] = values.date_end;
      }
      if (values.user_id && values.user_id !== null) {
        payloadData['user_id'] = values.user_id;
      }
      const data = await api.getFilterSearchFile(payloadData);
      downloadFile(data);
    } finally {
      dispatch({ type: 'isLoading', payload: false });
    }
  };

  const downloadFile = (data: Blob) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Спикеры.xlsx');
    link.click();
    link.remove();
  };

  return (
    <>
      <Card>
        <Form
          name="speaker"
          layout="vertical"
          validateMessages={{ required: 'Обязательное поле для заполнения' }}
          disabled={isLoading}
          className="mb-6"
          form={form}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} xl={4} xxl={6}>
              <Form.Item name="date_begin" label="Период с">
                <DatePicker className="width" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={4} xxl={6}>
              <Form.Item name="date_end" label="Период до">
                <DatePicker className="width" />
              </Form.Item>
            </Col>{' '}
            <Col xs={24} xl={4} xxl={6}>
              <Form.Item name="user_id" label="Спикер">
                <TreeSelectCollegues
                  colleaguesList={colleaguesList}
                  placeHolder="Выбрать спикера"
                  disabled={isLoadingColleaguesList}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={6} xxl={3}>
              <Form.Item label=" ">
                <Button
                  block
                  icon={<SearchOutlined />}
                  onClick={() => onFilterSubmit(FILTER_SEARCH)}
                >
                  Поиск
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} xl={6} xxl={3}>
              <Form.Item label=" ">
                <Button
                  block
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => onFilterSubmit(FILTER_DOWNLOAD)}
                >
                  Скачать
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} xl={6}>
              <Form.Item label=" ">
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    dispatch({ type: 'isOpenModal', payload: true });
                  }}
                  icon={<PlusOutlined />}
                >
                  Добавить спикера
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <SpeakerTable state={state} dispatch={dispatch} />
      </Card>
      <SpeakerModal
        state={state}
        dispatch={dispatch}
        colleaguesList={colleaguesList}
        isLoadingColleaguesList={isLoadingColleaguesList}
      />
    </>
  );
}

export default Speaker;
