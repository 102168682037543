/* eslint-disable @typescript-eslint/no-shadow */
import { TreeSelect } from 'antd';
import CTreeSelect from '@components/UI/treeselect/CTreeSelect';
const { TreeNode } = TreeSelect;

interface QuotationTreeSelectItem {
  children: QuotationTreeSelectList;
  full_name: string;
  id: number;
  isn: number;
  foreign_id: number;
}

type QuotationTreeSelectList = QuotationTreeSelectItem[];

interface Props {
  list: QuotationTreeSelectList;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  onChange?: any;
  size: string;
  value?: number;
}

/**
 * TODO: Использовать во всех компонентах
 * @param QuotationTreeSelectList данные в справочниках для TreeSelect
 * @param disabled атрибут для TreeSelect
 */

function renderTreeNodes(item: QuotationTreeSelectItem) {
  return (
    <TreeNode
      key={item.foreign_id ? item.foreign_id : item.isn}
      value={item.foreign_id ? item.foreign_id : item.isn}
      title={item.full_name}
    >
      {item?.children?.map((child) => renderTreeNodes(child))}
    </TreeNode>
  );
}
function QuotationTreeSelect(props: Props) {
  const {
    disabled = false,
    readonly = false,
    list = [],
    placeholder,
    onChange,
    size,
    value,
  } = props;

  return (
    <CTreeSelect
      style={{ width: '100%' }}
      size={size}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeLine={{ showLeafIcon: false }}
      treeNodeFilterProp="title"
      allowClear={true}
      showSearch={true}
      disabled={disabled || readonly}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    >
      {list.map((item) => renderTreeNodes(item))}
    </CTreeSelect>
  );
}

export default QuotationTreeSelect;
