import React, { useEffect, useState } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
  Button,
  Calendar,
  Card,
  Col,
  Progress,
  ProgressProps,
  Row,
  Space,
  Typography,
  Tooltip,
} from 'antd';
import dayLocaleData from 'dayjs/plugin/localeData';
import Title from 'antd/es/typography/Title';

import './CalendarSales.scss';
import {
  IDataSeller,
  IMonthData,
  IOtherMonth,
} from '@modules/common/SalesmanDetails/IDataSeller';
import { QuestionCircleOutlined } from '@ant-design/icons';

dayjs.extend(dayLocaleData);

const { Text } = Typography;

interface ICalendarSales {
  yearPlan: boolean;
  dataSeller: IDataSeller | null;
  type: string;
}

interface IAllSellerData {
  created_at?: string;
  emplisn?: number;
  id?: string;
  updated_at?: string;
  fact: number | string;
  nach: number | string;
  period: number | string;
  plan: number | string;
  insurance_income?: number | string;
  agent_reward?: number | string;
  netto?: number | string;
  rating?: number | string;
  financial_result?: number | string;
  total_nach?: number;
  month_percent: {
    percent_insurance: string | number;
    percent_agent: string | number;
    percent_rating: string | number;
    percent_netto: string | number;
    percent_fin_result: string | number;
    percent_fact: string | number;
    percent_nach: string | number;
  };
}

const redColor = '#ff0000';
const yellowColor = '#ffff00';
const greenColor = '#52c41a';

const CalendarSales = ({ yearPlan, dataSeller, type }: ICalendarSales) => {
  const TitleName = (): string => {
    if (type === 'brutto') {
      return '';
    } else if (type === 'nach') {
      return 'Премии начисленные';
    } else {
      return 'Премии оплачены';
    }
  };

  const wrapperStyle: React.CSSProperties = {
    padding: '10px',
    width: type === 'brutto' ? '100%' : '49%',
    border: 'none',
    boxShadow: '-1px 2px 15px 0px rgba(0,0,0,0.24)',
    overflow: 'hidden',
  };

  const [month, setMonth] = useState(dayjs().month());
  const [year] = useState(dayjs().year());
  const [selectDate, setSelectDate] = useState<Dayjs>(dayjs());
  const [isSelectMonth, setIsSelectMonth] = useState(false);
  const [isYearPlan, setIsYearPlan] = useState(true);
  const [currentMonthData, setCurrentMonthData] =
    useState<IAllSellerData | null>(null);
  const [allSellerData, setAllSellerData] = useState<IAllSellerData[]>([]);
  const isCorrectMonth = (dateString, targetMonth) =>
    dayjs(dateString).month() === targetMonth;
  const correctMonthData = allSellerData.find(
    (item) => item.period && isCorrectMonth(item.period, selectDate.month())
  );

  const getStrokeColor = (percent) => {
    if (percent < 80) {
      return redColor;
    } else if (percent < 100) {
      return yellowColor;
    } else {
      return greenColor;
    }
  };

  const convertArraysMonths = (months) => {
    const arrayOfObj = Object.entries(months).map((e) => ({ [e[0]]: e[1] }));
    const flattenedArray: any = arrayOfObj.map((obj) => Object.values(obj)[0]);
    return flattenedArray;
  };

  const formatingNumber = (total) => {
    const numberFormat = new Intl.NumberFormat('ru-RU');
    const str = total.toString();
    const length = str.length;

    if (length === 1 && str[0] === '0') {
      return '0';
    } else if (length === 9) {
      // Для чисел типа 100 000 000
      return numberFormat.format(Number(str.substring(0, 6)));
    } else if (length === 8) {
      // Для чисел типа 10 000 000
      return numberFormat.format(Number(str.substring(0, 5)));
    } else if (length === 7) {
      // Для чисел типа 1 000 000
      return numberFormat.format(Number(str.substring(0, 4)));
    } else if (length === 6) {
      // Для чисел типа 100 000
      return str.substring(0, 3);
    } else if (length === 5) {
      // Для чисел типа 10 000
      return str.substring(0, 2);
    } else if (length === 4) {
      // Для чисел типа 1 000
      return str;
    }
    return str;
  };

  const formatingNumberString = (total): string => {
    const str = total.toString();
    const length = str.length;

    if (length === 1 && str[0] === '0') {
      return '0';
    } else if (length === 9) {
      // Для чисел типа 100 000 000
      return ', тыс';
    } else if (length === 7) {
      // Для чисел типа 1 000 000
      return ', тыс';
    } else if (length === 8) {
      // Для чисел типа 10 000 000
      return ', тыс';
    } else if (length === 6) {
      // Для чисел типа 100 000
      return ', тыс';
    } else if (length === 5) {
      // Для чисел типа 10 000
      return ', тыс';
    } else if (length === 4) {
      return str;
    }
    return str;
  };

  useEffect(() => {
    if (dataSeller && dataSeller?.all_months !== undefined) {
      // Test
      setCurrentMonthData(
        convertArraysMonths(dataSeller && dataSeller.all_months)
      );
      setAllSellerData(
        convertArraysMonths(dataSeller && dataSeller.all_months)
      );
    }
  }, [dataSeller]);

  useEffect(() => {
    if (isYearPlan && correctMonthData !== undefined) {
      setCurrentMonthData(correctMonthData);
    }
  }, [currentMonthData, selectDate, isYearPlan]);

  return (
    <Card style={wrapperStyle}>
      {isSelectMonth && (
        <Calendar
          disabledDate={(current) => dayjs().month() < current.month()}
          onSelect={(value) => {
            setSelectDate(dayjs().set('month', value.get('month')));
            setMonth(dayjs(value).month());
            setIsSelectMonth(false);
            setIsYearPlan(true);
          }}
          value={selectDate}
          mode="year"
          fullscreen={false}
          headerRender={() => (
            <Title level={5} className="calendar-salesman__title">
              Выберите месяц
            </Title>
          )}
          monthFullCellRender={(value) => (
            <Col className="ant-picker-cell-inner ant-picker-cell-in-view">
              {value.format('MMMM')}
            </Col>
          )}
        />
      )}
      {!isSelectMonth && (
        <>
          <Row className="d-flex justify-space-between align-center">
            <Col>
              <Title level={5} className="calendar-salesman__title">
                {TitleName()}
              </Title>
            </Col>
            <Col>
              {yearPlan === true && (
                <Button
                  type="primary"
                  size="large"
                  ghost={isYearPlan}
                  onClick={() => {
                    setIsYearPlan(!isYearPlan);
                    if (
                      isYearPlan &&
                      dataSeller?.total_percentage !== undefined
                    ) {
                      setCurrentMonthData({
                        fact: dataSeller.total_percentage.percent_fact,
                        nach: dataSeller.total_percentage.total_nach,
                        period: 'Year',
                        plan: dataSeller.total_percentage.total_plan,
                        insurance_income:
                          dataSeller.total_percentage.total_insurance,
                        netto: dataSeller.total_percentage.total_netto,
                        agent_reward: dataSeller.total_percentage.total_agent,
                        rating: dataSeller.total_percentage.total_rating,
                        financial_result:
                          dataSeller.total_percentage.total_financial,
                        month_percent: {
                          percent_insurance:
                            type === 'brutto'
                              ? dataSeller.total_percentage.percent_insurance
                              : '0',
                          percent_agent:
                            type === 'brutto'
                              ? dataSeller.total_percentage.percent_agent
                              : '0',
                          percent_rating:
                            type === 'brutto'
                              ? dataSeller.total_percentage.percent_rating
                              : '0',
                          percent_netto:
                            type === 'brutto'
                              ? dataSeller.total_percentage.percent_netto
                              : '0',
                          percent_fin_result:
                            type === 'brutto'
                              ? dataSeller.total_percentage.percent_fin_result
                              : '0',
                          percent_fact:
                            type === 'brutto'
                              ? dataSeller.total_percentage.percent_fact
                              : '0',
                          percent_nach: `${dataSeller.total_percentage.percent_nach}`,
                        },
                      });
                    }
                  }}
                >
                  Годовой план
                </Button>
              )}
              <Button
                type="primary"
                size="large"
                className="calendar-salesman__button ml-5"
                ghost={!isYearPlan}
                onClick={() => {
                  setIsSelectMonth(true);
                }}
              >
                {dayjs().set('month', month).format('MMMM')} {year}
              </Button>
            </Col>
          </Row>
          {type === 'fact' || type === 'nach' ? (
            <>
              <Row className="d-flex justify-center align-center mt-10">
                <Progress
                  strokeColor={getStrokeColor(
                    yearPlan
                      ? Number(currentMonthData?.month_percent?.percent_nach)
                      : Number(currentMonthData?.month_percent?.percent_fact)
                  )}
                  type="circle"
                  percent={
                    yearPlan
                      ? Number(currentMonthData?.month_percent?.percent_nach)
                      : Number(currentMonthData?.month_percent?.percent_fact)
                  }
                  width={180}
                  strokeWidth={8}
                  format={() => (
                    <Space direction="vertical" align="center">
                      <Title className="calendar-salesman__progress-title">
                        {yearPlan
                          ? currentMonthData?.month_percent?.percent_nach
                          : currentMonthData?.month_percent?.percent_fact}
                        %
                      </Title>
                      <Text
                        type="secondary"
                        className="calendar-salesman__progress-subtitle"
                      >
                        Исполнено плана
                      </Text>
                    </Space>
                  )}
                />
              </Row>
              <Row className="d-flex justify-space-around mt-10">
                <Col className="d-flex align-center justify-start" span={5}>
                  <Row className="d-flex align-center justify-center flex-column">
                    <i className="calendar-salesman__paid-icon" />
                    <Title
                      level={5}
                      className="calendar-salesman__progress-subtitle"
                    >
                      {yearPlan ? 'Начислено' : 'Оплачено'}
                    </Title>
                    <Text className="calendar-salesman__progress-sum">
                      {!yearPlan
                        ? Math.round(
                            Number(currentMonthData?.fact)
                          ).toLocaleString()
                        : Math.round(
                            Number(currentMonthData?.nach)
                          ).toLocaleString()}
                    </Text>
                  </Row>
                </Col>
                <Col span={2} className="d-flex align-center justify-center">
                  <Row className="calendar-salesman__vertical-divider" />
                </Col>
                <Col className="d-flex align-center justify-end" span={5}>
                  <Row className="d-flex align-center justify-center flex-column">
                    <i className="calendar-salesman__plan-icon" />
                    <Title
                      level={5}
                      className="calendar-salesman__progress-subtitle"
                    >
                      План
                    </Title>
                    <Text className="calendar-salesman__progress-sum">
                      {Number(currentMonthData?.plan).toLocaleString()}
                    </Text>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <Row className="calendar-salesman__brutto-content">
              <Col className="calendar-salesman__brutto-item">
                <div>
                  <Title className="calendar-salesman__progress-title">
                    {formatingNumber(
                      Math.round(
                        Number(currentMonthData && currentMonthData?.nach)
                      )
                    )}
                  </Title>
                  <Text
                    className="calendar-salesman__progress-brutto-subtitle"
                    style={{ marginTop: '10px', display: 'block' }}
                  >
                    <div className="d-flex align-center justify-center">
                      <span>
                        {`Брутто-премия${
                          formatingNumberString(
                            Math.round(
                              Number(currentMonthData && currentMonthData?.nach)
                            )
                          ).length >= 5
                            ? ', тыс.'
                            : ''
                        }`}
                      </span>
                      <Tooltip
                        title={
                          'Сумма страховых премий по договорам страхования'
                        }
                      >
                        <QuestionCircleOutlined className="question-icon" />
                      </Tooltip>
                    </div>
                  </Text>
                </div>
              </Col>
              <Col className="calendar-salesman__brutto-item">
                <div>
                  <Title className="calendar-salesman__progress-title">
                    {formatingNumber(
                      Math.round(
                        Number(
                          currentMonthData && currentMonthData.insurance_income
                        )
                      )
                    )}
                  </Title>
                  <Text
                    className="calendar-salesman__progress-brutto-subtitle"
                    style={{ marginTop: '10px', display: 'block' }}
                  >
                    <div className="d-flex align-center justify-center">
                      <span>
                        {`ДСД (Доход от страховой деятельности)${
                          formatingNumberString(
                            Number(
                              currentMonthData &&
                                currentMonthData?.insurance_income
                            )
                          ).length >= 5
                            ? ', тыс.'
                            : ''
                        }`}
                      </span>
                      <Tooltip
                        title={
                          'Сумма страховых премий за вычетом чистых расходов на перестрахование'
                        }
                      >
                        <QuestionCircleOutlined
                          className="question-icon"
                          style={{ transform: 'translateX(-30px)' }}
                        />
                      </Tooltip>
                    </div>
                  </Text>
                </div>
                <div className="calendar-salesman__horizontal-divider"></div>
                <Space
                  direction="horizontal"
                  align="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Text
                    type="secondary"
                    className="calendar-salesman__progress-brutto-subtitle"
                  >
                    ДСД к Брутто-премии:
                  </Text>
                  <Title className="calendar-salesman__progress-title">
                    {(currentMonthData &&
                      currentMonthData?.month_percent?.percent_insurance) ||
                      '0'}
                    %
                  </Title>
                </Space>
              </Col>
              <Col className="calendar-salesman__brutto-item">
                <div>
                  <Title className="calendar-salesman__progress-title">
                    {formatingNumber(
                      Math.round(
                        Number(
                          currentMonthData && currentMonthData?.agent_reward
                        )
                      )
                    )}
                  </Title>
                  <Text
                    className="calendar-salesman__progress-brutto-subtitle"
                    style={{ marginTop: '10px', display: 'block' }}
                  >
                    <div className="d-flex align-center justify-center">
                      <span>
                        {`Агентское вознаграждение${
                          formatingNumberString(
                            Number(
                              currentMonthData && currentMonthData?.agent_reward
                            )
                          ).length >= 5
                            ? ', тыс.'
                            : ''
                        }`}
                      </span>
                      <Tooltip
                        title={
                          'Выплаты агенту за исполнение обязательств по договору'
                        }
                      >
                        <QuestionCircleOutlined className="question-icon" />
                      </Tooltip>
                    </div>
                  </Text>
                </div>
                <div className="calendar-salesman__horizontal-divider"></div>
                <Space
                  direction="horizontal"
                  align="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Text
                    type="secondary"
                    className="calendar-salesman__progress-brutto-subtitle"
                  >
                    АВ к Брутто-премии:
                  </Text>
                  <Title className="calendar-salesman__progress-title">
                    {(currentMonthData &&
                      currentMonthData?.month_percent?.percent_agent) ||
                      '0'}
                    %
                  </Title>
                </Space>
              </Col>
              <Col className="calendar-salesman__brutto-item">
                <div>
                  <Title className="calendar-salesman__progress-title">
                    {formatingNumber(
                      Math.round(Number(currentMonthData?.netto))
                    )}
                  </Title>
                  <Text
                    className="calendar-salesman__progress-brutto-subtitle"
                    style={{ marginTop: '10px', display: 'block' }}
                  >
                    <div className="d-flex align-center justify-center">
                      <span>
                        {`Нетто выплаты${
                          formatingNumberString(
                            Number(currentMonthData && currentMonthData.netto)
                          ).length >= 5
                            ? ', тыс.'
                            : ''
                        }`}
                      </span>
                      <Tooltip
                        title={
                          'Сумма выплаты по страховым случаям за вычетом доли перестраховщика'
                        }
                      >
                        <QuestionCircleOutlined className="question-icon" />
                      </Tooltip>
                    </div>
                  </Text>
                </div>
                <div className="calendar-salesman__horizontal-divider"></div>
                <Space
                  direction="horizontal"
                  align="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Text
                    type="secondary"
                    className="calendar-salesman__progress-brutto-subtitle"
                  >
                    Нетто выплаты к ДСД:
                  </Text>
                  <Title className="calendar-salesman__progress-title">
                    {currentMonthData?.month_percent?.percent_netto || '0'}%
                  </Title>
                </Space>
              </Col>
              <Col className="calendar-salesman__brutto-item">
                <div>
                  <Title className="calendar-salesman__progress-title">
                    {formatingNumber(
                      Math.round(Number(currentMonthData?.rating))
                    )}
                  </Title>
                  <Text
                    className="calendar-salesman__progress-brutto-subtitle"
                    style={{ marginTop: '10px', display: 'block' }}
                  >
                    {`Рейтинг${
                      formatingNumberString(
                        Number(currentMonthData && currentMonthData.rating)
                      ).length >= 5
                        ? ', тыс.'
                        : ''
                    }`}
                  </Text>
                </div>
                <div className="calendar-salesman__horizontal-divider"></div>
                <Space
                  direction="horizontal"
                  align="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Text
                    type="secondary"
                    className="calendar-salesman__progress-brutto-subtitle"
                  >
                    Рейтинг к Брутто-премии:
                  </Text>
                  <Title className="calendar-salesman__progress-title">
                    {currentMonthData?.month_percent?.percent_rating || '0'}%
                  </Title>
                </Space>
              </Col>
              <Col className="calendar-salesman__brutto-item">
                <div>
                  <Title className="calendar-salesman__progress-title">
                    {formatingNumber(
                      Math.round(
                        Number(
                          currentMonthData && currentMonthData?.financial_result
                        )
                      )
                    )}
                  </Title>
                  <Text
                    className="calendar-salesman__progress-brutto-subtitle"
                    style={{ marginTop: '10px', display: 'block' }}
                  >
                    <div className="d-flex align-center justify-center">
                      <span>
                        {`Финрез${
                          formatingNumberString(
                            Number(
                              currentMonthData &&
                                currentMonthData?.financial_result
                            )
                          ).length >= 5
                            ? ', тыс.'
                            : ''
                        }`}
                      </span>
                      <Tooltip
                        title={'Финансовый результат от страховой деятельности'}
                      >
                        <QuestionCircleOutlined className="question-icon" />
                      </Tooltip>
                    </div>
                  </Text>
                </div>
                <div className="calendar-salesman__horizontal-divider"></div>
                <Space
                  direction="horizontal"
                  align="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                  wrap={true}
                >
                  <Text
                    type="secondary"
                    className="calendar-salesman__progress-brutto-subtitle"
                  >
                    <div className="d-flex align-center justify-center">
                      <span>Рентабельность:</span>
                      <Tooltip
                        title={
                          'Процентное соотношение финансового результата к доходу от страховой деятельности'
                        }
                      >
                        <QuestionCircleOutlined className="question-icon" />
                      </Tooltip>
                    </div>
                  </Text>
                  <Title className="calendar-salesman__progress-title">
                    {currentMonthData?.month_percent?.percent_fin_result || '0'}
                    %
                  </Title>
                </Space>
              </Col>
            </Row>
          )}
        </>
      )}
    </Card>
  );
};

export default CalendarSales;
