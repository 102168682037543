import Logo from '@modules/common/Logo';
import React, { useEffect, useState } from 'react';
import { Button, Col, Empty, Input, message, Row, Typography, Upload, UploadProps } from 'antd';
import { ICategory } from '@modules/OnlineLibrary/interfaces/ICategory';
import { MenuProps } from 'antd/es/menu';
import MenuCategories from '@modules/UsefulSection/MenuCategories/MenuCategories';
import AddCategoryModal from '@modules/UsefulSection/AddCategoryModal/AddCategoryModal';
import DeleteCategoryModal from '@modules/UsefulSection/DeleteCategoryModal/DeleteCategoryModal';
import DocumentItem from '@modules/UsefulSection/DocumentItem/DocumentItem';
import InfoItem from '@modules/UsefulSection/InfoItem/InfoItem';
import EditCategoryModal from '@modules/UsefulSection/EditCategoryModal/EditCategoryModal';
import FolderItem from '@modules/UsefulSection/FolderItem/FolderItem';
import FoundItem from '@modules/UsefulSection/FoundItem/FoundItem';
import { IFileLibrary } from '@modules/OnlineLibrary/interfaces/IFileLibrary';
import { IFolderInfo } from '@modules/OnlineLibrary/interfaces/IFoldreItem';
import { IStateSelectedCategories } from '@modules/OnlineLibrary/interfaces/IStateSelectedCategories';
import useUseful from '@hooks/useUseful';
import './UsefulSection.scss';
import axios from 'axios';
import { IRequestData } from '@modules/OnlineLibrary/interfaces/IRequestData';
import useAuth from '@hooks/useAuth';
import { SearchOutlined } from '@ant-design/icons';
import { getAccessUseful } from '@modules/UsefulSection/helpers/getAccessUseful';
import { getExtension } from '@modules/UsefulSection/helpers/getExtension';
import api from '@modules/UsefulSection/services/api';
import Cookies from 'js-cookie';

const { Title } = Typography;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const OnlineLibrary = () => {
  let timeout: string | number | NodeJS.Timeout | undefined;
  const { setDeleteCategoryId, setEditCategoryId, setInfoCategoryId } = useUseful();
  const { user } = useAuth();

  const [categoriesItems, setCategoriesItems] = useState<ICategory[]>([]);
  const [mainCategories, setMainCategories] = useState<IFolderInfo[]>([]);
  const [files, setFiles] = useState<IFileLibrary[]>([]);
  const [searchedFiles, setSearchedFiles] = useState<IFileLibrary[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pathCategory, setPathCategory] = useState<string>('');
  const [partenId, setParentId] = useState<null | number>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);

  const [selectedAddCategory, setSelectedAddCategory] = useState<IStateSelectedCategories>({
    rootCategory: '',
    subCategory: '',
    selectedCategoryForModal: ''
  });

  const getHierarchy = async (id: string) => {
    try {
      const response = await api.getHierarchyUseful(id);
      setPathCategory(response.data.current_hierarchy);
      setParentId(response.data.parent_id);
    } catch (e) {
      message.error("Что-то пошло не так");
    }
  };

  const getCategories = async () => {
    try {
      const response = await api.getSubCategoriesUseful(783);
      setCategoriesItems(response.data.children);
    } catch (e) {
      message.error("Не удалось получить категории");
    }
  };

  const getSubCategories = async (id: string | number) => {
    try {
      const response = await api.getSubCategoriesUseful(id);
      setMainCategories(response.data.children);
      setFiles(response.data.files);
    } catch (e) {
      message.error("Не удалось получить категории");
    }
  };

  useEffect(() => {
    document.title = 'Полезное';
    getCategories();
  }, []);

  const handleClick = async (id: string | number) => {
    setSelectedAddCategory(prevState => ({
      ...prevState,
      subCategory: String(id)
    }));

    await getHierarchy(String(id));
    await getSubCategories(String(id));
  };

  const showAddModal = () => {
    setIsAddModalOpen(true);
  };

  const showDeleteModal = (e) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };

  const showEditModal = (e) => {
    e.stopPropagation();
    setIsEditModalOpen(true);
  };

  const addRootCategory = async () => {
    showAddModal();
    setSelectedAddCategory(prevState => ({
      ...prevState,
      selectedCategoryForModal: 'root'
    }));
  };

  const addSubCategory = async () => {
    showAddModal();
    setSelectedAddCategory(prevState => ({
      ...prevState,
      selectedCategoryForModal: 'sub'
    }));
  };

  const deleteCategory = async (id: string) => {
    try {
      await api.deleteCategoryUseful(id, user?.data?.id);
      message.success('Вы успешно удалили категорию');
    } catch (e) {
      message.error("Не удалось удалить категорию");
    } finally {
      window.location.reload();
    }
  };

  const deleteDocument = async (uuid: string | number) => {
    try {
      await api.deleteDocumentUseful(uuid, user?.data?.id);
    } catch (e) {
      message.error('Не удалось удалить документ');
    } finally {
      await getSubCategories(selectedAddCategory.subCategory);
    }
  };

  const editCategory = async (id: string, data: IRequestData) => {
    try {
      await api.editCategoryUseful(id, data);
      message.success('Вы успешно изменили категорию');
    } catch (e) {
      message.error("Не удалось изменить категорию");
    } finally {
      window.location.reload();
    }
  };

  const onClickBackBtn = async () => {
    if (partenId !== null) await getSubCategories(partenId);
    await getHierarchy(String(partenId));
  };

  const onSearchInput = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchTerm(value);
    clearTimeout(timeout);

    timeout = setTimeout(async () => {
      try {
        if (value.length >= 3) {
          setIsInfoModalOpen(false);
          const response = await api.searchDocumentsUseful(value);
          setSearchedFiles(response.data.results);
        } else {
          setSearchedFiles([]);
        }
      } catch (e) {
        message.error('Не удалось обработать запрос на поиск файлов');
      }
    }, 600);
  };

  const token = Cookies.get('token');
  const props: UploadProps = {
    name: 'file',
    action: 'https://mycent.kz/api/online-library/files',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {
      category_id: selectedAddCategory.subCategory,
      created_by: user?.data?.id,
      root_category_id: 783,
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} Файл успешно загружен`);
        getSubCategories(selectedAddCategory.subCategory);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} Не удалось загрузить файл.`);
      }
    },
    showUploadList: false,
    multiple: false,
    accept: ".docx, .pdf, .png, .jpeg, .jpg, .webp, .gif, .tiff, .doc, .xlsx, .xls, .xlsm, .pptx"
  };

  const itemsCategories = categoriesItems.length > 0 ? categoriesItems.map((category: ICategory) => {
    return getItem(category.name, category.id, <i className="folder-icon-useful"/>)
  }) : [];

  const folderItems = mainCategories.length > 0 && mainCategories.map((folder) => {
    return <FolderItem
      title={folder.name}
      key={folder.id}
      onClickEvent={() => handleClick(folder.id)}
      deleteCategory={(e) => showDeleteModal(e)}
      editCategory={(e) => showEditModal(e)}
      showInfo={() => setIsInfoModalOpen(true)}
      getDeleteCategoryId={() => setDeleteCategoryId(String(folder.id))}
      getEditCategoryId={() => setEditCategoryId(String(folder.id))}
      getInfoCategoryId={() => setInfoCategoryId(String(folder.id))}
    />
  });

  const documentsItems = files.length > 0 && files.map((file: IFileLibrary) => {
    return <DocumentItem
      deleteDocument={showDeleteModal}
      showInfo={() => setIsInfoModalOpen(true)}
      title={file.filename}
      key={file.file_uuid}
      uuid={file.file_uuid}
      extension={getExtension(file.filename)}
    />
  });

  const searchedDocumentsItems = searchedFiles.length > 0 && searchedFiles.map((file: IFileLibrary) => {
    return <FoundItem
      searchTerm={searchTerm}
      getHierarchy={() => getHierarchy(String(file.category_id))}
      getSubCategories={() => getSubCategories(String(file.category_id))}
      key={file.file_uuid}
      title={file.filename}
      uuid={file.file_uuid}
      setCategory={() => {
        setSelectedAddCategory(prevState => ({
          ...prevState,
          subCategory: String(file.category_id)
        }));
      }}
    />
  });

  return (
    <div className="content-useful">
      <div className="container-useful">
        <AddCategoryModal
          onCancel={() => setIsAddModalOpen(false)}
          isModalOpen={isAddModalOpen}
          addSelectedCategory={selectedAddCategory}
          getCategories={getCategories}
          getAllMainSubCategories={getSubCategories}
        />
        <DeleteCategoryModal
          deleteDocument={deleteDocument}
          deleteCategory={deleteCategory}
          onCancel={() => setIsDeleteModalOpen(false)}
          isModalOpen={isDeleteModalOpen}
        />
        <EditCategoryModal
          editCategory={editCategory}
          onCancel={() => setIsEditModalOpen(false)}
          isModalOpen={isEditModalOpen}
        />
        <Row className="header-useful">
          <Logo></Logo>
        </Row>
        <Row className="d-flex align-start justify-space-between">
          <Col xs={{ span: 24, order: 1 }} xl={{ span: 5, order: 1 }}>
            <MenuCategories
              items={itemsCategories}
              onClickCategory={(e) => handleClick(e.key)}
              addCategory={() => addRootCategory()}
              deleteCategory={showDeleteModal}
              editCategory={showEditModal}
            />
          </Col>
          <Col xs={{ span: 24, order: 3 }} xl={{ span: 13, order: 2 }}>
            <div className="sector-useful">
              <Row className="d-flex justify-space-between">
                <Title level={4} className="path-category-useful">{pathCategory.replace(/\//g, " > ")}</Title>
                <Row gutter={[24, 24]}>
                  {partenId !== 783 && partenId && <Col>
                      <Button
                          className="back-btn-useful"
                          size="large"
                          onClick={onClickBackBtn}
                      >
                          Назад
                      </Button>
                  </Col>}
                  {getAccessUseful('create', user) && <>
                      <Col>
                          <Button
                              className="add-folder-useful"
                              size="large"
                              onClick={addSubCategory}
                          >
                              + Создать
                          </Button>
                      </Col>
                      <Col>
                          <Upload {...props}>
                              <Button
                                  className="document-btn-useful"
                                  size="large"
                                  icon={<i className="download-document-icon-useful"/>}
                              >
                                  Загрузить
                              </Button>
                          </Upload>
                      </Col>
                  </>}
                </Row>
              </Row>
              <Row gutter={[24, 24]}
                   className={mainCategories.length > 0 || files.length > 0 ? "mt-5" : "align-center justify-center"}>
                {mainCategories.length > 0 || files.length > 0 ? (
                  <>
                    {folderItems}
                    {documentsItems}
                  </>
                ) : (
                  <Empty description="Выберите категорию" className="mt-5"/>
                )}
              </Row>
            </div>
          </Col>
          <Col xs={{ span: 24, order: 2 }} xl={{ span: 5, order: 3 }}>
            <Row className="sector-useful">
              <>
                <Input
                  suffix={<SearchOutlined className="search-icon-useful"/>}
                  placeholder="Искать..."
                  className="search-input-useful"
                  onInput={async (e) => onSearchInput(e)}
                />
                {searchedFiles.length > 0 && searchedDocumentsItems}
              </>
            </Row>
            {isInfoModalOpen && <InfoItem
                categories={mainCategories}
                fileList={files}
                closeInfoModal={() => setIsInfoModalOpen(false)}
            />}
          </Col>
        </Row>
      </div>
    </div>
  )
};

export default OnlineLibrary;