import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  Upload,
} from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fieldNamesRecruiting } from '../Common/fieldNames';
import { normFile } from '../Common/normFile';
import SubmitButton from '../Common/SubmitButton';
import { IFetchedApplicationById } from './interface';
import submitForm from './submitForm';

const { Title } = Typography;

type CandidateModalProps = {
  isOpenCandidateModal: boolean;
  setIsOpenCandidateModal: Dispatch<SetStateAction<boolean>>;
  fetchApplicationById: IFetchedApplicationById | undefined;
};

function AddCandidateModal(props: CandidateModalProps) {
  const [isLoadingSubmitForm, setIsLoadingSubmitForm] = useState<boolean>(false);

  const {
    isOpenCandidateModal,
    setIsOpenCandidateModal,
    fetchApplicationById,
  } = props;

  const [form] = Form.useForm();

  const { id } = useParams();

  useEffect(() => {
    if (fetchApplicationById) {
      const { department_to } = fetchApplicationById;
      setFieldMethod(fieldNamesRecruiting.department_to, department_to.name);
    }
  }, [fetchApplicationById]);

  const setFieldMethod = (field: string, value: number | string) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  const handleCancel = () => {
    setIsOpenCandidateModal(false);
    form.resetFields();
    if (fetchApplicationById) {
      const { department_to } = fetchApplicationById;
      setFieldMethod(fieldNamesRecruiting.department_to, department_to.name);
    }
  };

  return (
    <>
      <Modal
        title={
          <Title level={4} style={{ color: '#0057FF' }}>
            Добавление кандидата
          </Title>
        }
        open={isOpenCandidateModal}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          layout="vertical"
          validateMessages={{ required: 'Обязательное поле для заполнения' }}
          name="AddcandidateModal"
          onFinish={(values) => {
            submitForm(values, id, handleCancel, setIsLoadingSubmitForm);
          }}
          form={form}
          style={{ fontWeight: 'initial' }}
          disabled={isLoadingSubmitForm}
        >
          <Row gutter={24} align="top" style={{ marginTop: 30 }}>
            <Col xs={24} xl={12}>
              <Form.Item
                label="ФИО кандидата"
                name={fieldNamesRecruiting.candidate_fullname}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label="ИИН" name={fieldNamesRecruiting.candidate_iin}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Структурное подразделение"
                name={fieldNamesRecruiting.department_to}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Контактный номер кандидата"
                rules={[{ required: true }]}
                name={fieldNamesRecruiting.candidate_phone_number}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <div className="recruiting-file-overwrite">
                <Form.Item
                  label="Резюме кандидата"
                  name={fieldNamesRecruiting.file}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload beforeUpload={() => false} maxCount={1}>
                    <Button block icon={<UploadOutlined />}>
                      Загрузить файл
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label=" ">
                <SubmitButton buttonText={'Сохранить'} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Divider />
      </Modal>
    </>
  );
}

export default AddCandidateModal;
