import { createContext, Dispatch, SetStateAction } from 'react';

interface UsefulContextInterface {
  deleteCategoryId: string;
  editCategoryId: string;
  infoCategoryId: string;
  isDocument: boolean;
  currentFileUuid: string;
  setDeleteCategoryId: Dispatch<SetStateAction<string>>;
  setEditCategoryId: Dispatch<SetStateAction<string>>;
  setInfoCategoryId: Dispatch<SetStateAction<string>>;
  setIsDocument: Dispatch<SetStateAction<boolean>>;
  setCurrentFileUuid: Dispatch<SetStateAction<string>>;
}

const UsefulContext = createContext<UsefulContextInterface>({
  deleteCategoryId: '',
  editCategoryId: '',
  infoCategoryId: '',
  isDocument: false,
  currentFileUuid: '',
  setDeleteCategoryId: () => {},
  setEditCategoryId: () => {},
  setInfoCategoryId: () => {},
  setIsDocument: () => {},
  setCurrentFileUuid: () => {},
});

export default UsefulContext;
