import axios from "@services/api/axios";

const endpoints = {
  getListExpressQuotation: () => axios.get('/express-product'),
  getSelectedExpressProduct: (selectedProductId) =>
    axios.get(`/express-product/${selectedProductId}`),
  getSelectedExpressQuotation: (selectedProductId) =>
    axios.get(`/express-quotation/${selectedProductId}`),
  getAgrCalc: (data) => axios.post('/express-quotation/get-agr-calc', data),
  getExpressQuotationByRecordId: (recordId) =>
    axios.get('/express-quotation/' + recordId),
  searchExpressQuotation: (data) =>
    axios.post('/search-express-quotation', data),


  newExpress: (data) => axios.post('/express-product/new-express', data),
  searchInn: (data) => axios.post('/search/iin', data),
  searchUser: (data) => axios.post('/search-contragent', data),
  setExpress: (data) => axios.post('/express-quotation/set-express', data),
  calcExpress: (data) => axios.post('/express-quotation/calc-express', data),
  sendToFullQuotation: (data) => axios.post('/create-by-express' , data),
};

export default endpoints;
