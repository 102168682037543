import { Radio, Typography, Row, Col } from 'antd';
import type { RadioChangeEvent } from 'antd';
import './CRadio.scss';
const { Title } = Typography;
const CRadio = (props) => {
  const {
    title,
    size,
    options,
    onChange,
    value,
    optionType,
    buttonStyle,
    titleSpan,
    disabled,
  } = props;

  const cssStyle = (size) => {
    if (!size) return;
    let className;
    switch (size) {
      case 'large':
        className = 'ant-radio-group-lg';
        break;
      case 'small':
        className = 'ant-radio-group-sm';
        break;
    }
    return className;
  };

  return (
    <Row gutter={6} align="middle" className="c-radio">
      {title ? (
        <Col span={titleSpan ? titleSpan : 12}>
          <Title level={5}>{title}</Title>
        </Col>
      ) : (
        ''
      )}
      <Col span={title ? (titleSpan ? 24 - titleSpan : 12) : 24}>
        <Radio.Group
          disabled={disabled}
          className={[props.className, cssStyle(size)].join(' ')}
          options={options}
          onChange={onChange}
          value={value}
          optionType={optionType}
          buttonStyle={buttonStyle}
        />
      </Col>
    </Row>
  );
};

export default CRadio;
