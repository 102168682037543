import { createContext, Dispatch, SetStateAction } from 'react';
import { IAutoDictiItem } from '../../modules/Quotation/Interfaces/ObjectQuotation/Auto.interface';
import { IDictiItem } from '../../modules/Quotation/Interfaces/SearchPerson/Kias.interface';

interface ClassObjectQuotationInterface {
  autoData: IAutoDictiItem[];
  autoCategory: IAutoDictiItem[];
  autoOwner: IAutoDictiItem[];
  autoPurpose: IAutoDictiItem[];
  autoColor: IAutoDictiItem[];
  autoOwnerShip: IAutoDictiItem[];
  autoCountry: IAutoDictiItem[];
  identifyClassObject: (classObjectId: number) => void;
  packageCargo: IDictiItem[];
  measureVolume: IDictiItem[];
  transport: IDictiItem[];
  vidTransportFullList: IDictiItem[];
}

const ClassObjectQuotationContext =
  createContext<ClassObjectQuotationInterface>({
    autoData: [],
    autoCategory: [],
    autoOwner: [],
    autoPurpose: [],
    autoColor: [],
    autoOwnerShip: [],
    autoCountry: [],
    identifyClassObject: () => {},
    packageCargo: [],
    measureVolume: [],
    transport: [],
    vidTransportFullList: [],
  });

export default ClassObjectQuotationContext;
