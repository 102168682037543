import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  notification,
  TreeSelect,
  Typography,
  Upload,
  message,
} from 'antd';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import api from '@modules/Quotation/services';

// import { IEmptyQuotationItem, IProduct } from './NewExpressQuotation';
import ContragentModal from './ContragentModel';
import useColleagues, { Colleagues } from '../../../hooks/useColleagues';
import useAuth from '../../../hooks/useAuth';
import { ChildProcess } from 'child_process';
import {
  IProduct,
  IProductItem,
} from '../../../pages/Quotation/ExpressQuotation';
import dayjs from 'dayjs';

const { Search } = Input;

const { Option } = Select;
const { Title } = Typography;
const { Dragger } = Upload;
const { TextArea } = Input;
const { TreeNode } = TreeSelect;
export interface IEmptyQuotationItem {
  id: number;
  department_id: string;
  user_id: string;
  class_id: string;
  tariff: string;
  product_id: string;
  product_name: string;
  quotation_id: string;
  tariff_str: null;
  premium_sum: string;
  franch: string;
  agr_isn: string;
  prev_isn: string;
  subj_isn: string;
  date_calc: string;
  remark: string;
  front_node_id: string;
  system_id: string;
  created_by: string;
  updated_by: string;
  updated_at: string;
  created_at: string;
  data: DataChild;
}

export interface DataChild {
  agr_attributes?: [];
  contragent?: [];
}
export interface IInnItem {
  FIRSTNAME: string;
  PARENTNAME: string;
  LASTNAME: string;
  FIO: string;
  BIRTHDAY: string;
  DOCNO: string;
  DOCDATE: string;
  DOCNAME: string;
  EMAIL: any;
  PHONENUMBER: string;
  IIN: string;
  GENDER: string;
  ISN: string;
  ADDRESS: string;
  JURIDICAL: string;
}
export interface IUserProductSelectedItem {
  attributes: IAttributeItem[];
}

interface IAttributeItem {
  attr_isn: number;
  attr_name: number;
  company_id: String;
  product_id: number;
  active: number;
  active_mycent: boolean;
  attr_type: String;
  readonly: number;
  def_vald: String;
  def_valc: String;
  def_valn: number;
  def_sql_text: String;
  created_by: number;
  updated_by: number;
  text_code: String;
  dictis: IAttributeItemDictis[];
}

interface IAttributeItemDictis {
  foreign_id: number;
  parent_foreign_id: number;
  full_name: String;
  short_name: String;
  parent_id: number;
  has_child: number;
  product_id: number;
  param_attr_isn: number;
  company_id: String;
  created_by: number;
  updated_by: number;
  active: number;
  num_code: number;
  text_code: String;
}

export interface UserProductSelectedProps {
  id: any;
  product_id: any;
  company_id: any;
  full_name: any;
  short_name: any;
  ordinal: any;
  updated_by: any;
  created_by: 1;
  data: IUserProductSelectedItem[];
}

interface IInfoProduct {
  product: IProduct | undefined;
  productList;
  attributes: IProductItem[] | null;
  setProductAttributes: any;
}

export interface IAgrAttributeItem {
  attr_isn: number;
  obj_isn?: number;
  val_n?: number;
  val_d?: string;
  val_c?: boolean | string;
  class_isn: number;
  user_isn?: number;
}

const ExpressProduct: React.FC = () => {
  const navigate = useNavigate();

  const [fileList, setFileList] = useState<any[]>([]);
  const [universalDataModal, setUniversalDataModal] = useState<IInnItem | null>(
    null
  );
  const [universalModalVisible, setUniversalModalVisible] = useState(false);
  const [universalNameModal, setUniversalNameModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [attributes, setProductAttributes] = useState<IProductItem[] | null>();
  const [form] = Form.useForm();
  const { quotationId } = useParams();
  const [contragentFio, setContragentFio] = useState<string>('');
  const [expressQuotation, setExpressQuotation] = useState<
    IEmptyQuotationItem | undefined
  >(undefined);
  const [product, setProduct] = useState<IProduct | undefined | any>(undefined);
  const { colleaguesList, isLoadingColleaguesList } = useColleagues();
  const [agrAttributes, setAgrAttributes] = useState<IAgrAttributeItem[]>([]);
  const [expressSum, setExpressSum] = useState('');
  const [expressInfo, setExpressInfo] = useState<IEmptyQuotationItem[]>([]);
  const [expressInfoTariff, setExpressInfoTariff] = useState('');
  const [expressInfoTariffStr, setExpressInfoTariffStr] = useState('');
  const [expressInfoDate, setExpressInfoDate] = useState('');
  const renderColleguesTreeNodes = (item: Colleagues, selectable) => {
    return (
      <TreeNode value={item.id} title={item.full_name} selectable={!selectable}>
        {item.users.map((item) => (
          <TreeNode
            value={item.id + item.full_name}
            title={item.full_name}
            selectable={selectable}
          />
        ))}
        {item.child.map((item) => renderColleguesTreeNodes(item, selectable))}
      </TreeNode>
    );
  };
  const auth = useAuth();
  useEffect(() => {
    // @ts-ignore
    if (attributes !== null && attributes !== undefined) {
      return;
    }
    (async () => {
      try {
        const response = await api.expressQuotation.getSelectedExpressQuotation(
          quotationId
        );

        if (response.data.data) {
          let agr_attributes = response.data.data.agr_attributes?.reduce(
            (acc, cur) => {
              if (typeof cur.val_d !== 'undefined') {
                acc[`val_d: ${cur.attr_isn}`] = dayjs(cur.val_d);
              } else if (typeof cur.val_n !== 'undefined') {
                acc[`val_n: ${cur.attr_isn}`] = cur.val_n;
              } else {
                acc[`val_c: ${cur.attr_isn}`] = cur.val_c;
              }
              return acc;
            },
            {}
          );

          if (quotationId) {
            agr_attributes[`val_n: 822261`] = getQuotationId();
          }
          if (response.data.data.contragent?.information) {
            var contragentDop = response.data.data.contragent?.information;
            setUniversalDataModal(response.data.data.contragent?.information);
          }

          form.setFieldsValue({
            agr_attributes,
          });

          if (response.data.premium_sum) {
            setExpressInfoTariff(response.data.tariff);
            setExpressInfoTariffStr(response.data.tariff_str);
            setExpressSum(response.data.premium_sum);
            setExpressInfoDate(response.data.date_calc);
          }

          // form.setFieldsValue({
          //   universal_contragentModal: 1111,
          //   lala: contragentFio,
          // });
        }

        setExpressQuotation(response.data);
        const responseProduct =
          await api.expressQuotation.getSelectedExpressProduct(
            response.data.product_id
          );
        setProduct(responseProduct.data?.data);
        setProductAttributes(responseProduct?.data?.data?.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [attributes, quotationId, expressSum]);

  useEffect(() => {
    if (universalDataModal === null) return;
    setContragentFio(universalDataModal?.FIO);
    // setContragentFio(
    //   universalDataModal?.LASTNAME !== undefined ||
    //     universalDataModal?.LASTNAME !== null
    //     ? universalDataModal?.LASTNAME +
    //         ' ' +
    //         universalDataModal.PARENTNAME +
    //         ' ' +
    //         universalDataModal.FIRSTNAME
    //     : ''
    // );
  }, [universalDataModal]);

  useEffect(() => {
    form.setFieldsValue({
      universal_contragentModal: 1111,
      lala: contragentFio,
    });
  }, [contragentFio]);
  useEffect(() => {
    if (expressQuotation?.data === undefined) {
      return;
    }
    if (
      expressQuotation?.data?.agr_attributes !== undefined ||
      expressQuotation?.data?.agr_attributes
    ) {
      // form.setFieldsValue({
      //   agr_attributes.: {},
      //   lala: contragentFio,
      // });
    }
  }, [attributes, expressQuotation, product]);

  const getAllValidatedData = async (values) => {
    const agrAttributesDataTemp = Object.entries(values.agr_attributes).map(
      (item) => {
        if (item != undefined && item[1]) {
          let val = item[0].split(' ')[0];
          return {
            attr_isn: parseInt(item[0].split(' ')[1]),
            obj_isn: expressQuotation?.id,
            [val.split(':')[0]]: item[1],
            user_isn: auth.user.data.user_info.foreign_id,
            class_isn: 220575,
          };
        }
      }
    );
    const agrAttributesData = agrAttributesDataTemp.filter(function (element) {
      return element !== undefined;
    });

    const responseData = {
      contragent: {
        iin: universalDataModal?.IIN,
        isn: universalDataModal?.ISN,
        obj_isn: expressQuotation?.id,
        user_isn: auth.user.data.user_info.foreign_id,
        information: universalDataModal,
      },
      agr_attributes: agrAttributesData,
      connection: 'centras',
    };
    const response = await api.expressQuotation.setExpress(responseData);
    if (response.data.success) {
      const responseCalc = await api.expressQuotation.calcExpress({
        userIsn: auth.user.data.user_info.foreign_id,
        calcIsn: expressQuotation?.id,
        connection: 'centras',
      });
      if (responseCalc.data.success === false) {
        notification.info({
          message: 'Ошибка',
          description: responseCalc.data.message,
        });
        return;
      }
      if (responseCalc && expressQuotation?.id) {
        const responseInfo = await api.expressQuotation.getAgrCalc({
          obj_isn: expressQuotation?.id,
          connection: 'centras',
        });
        setExpressInfoTariff(responseInfo.data.data.tariff);
        setExpressInfoTariffStr(responseInfo.data.data.tariff_str);
        setExpressSum(responseInfo.data.data.premium_sum);
        setExpressInfoDate(responseInfo.data.data.date_calc);
      }
    }
    // } catch (error) {
    //   let message;
    //   if (axios.isAxiosError(error)) {
    //     message = error.message;
    //   } else {
    //     message = String(error);
    //   }
    //   notification.info({
    //     message: 'Ошибка',
    //     description: message,
    //   });
    // }
  };
  // function changeInput(e, ele) {
  //   agrAttributes.forEach((element, key) => {
  //     if(element.attr_isn === ele.attr_isn) {
  //       let updatedValue = {attr_isn: ele.attr_isn,
  //             obj_isn: expressQuotation?.id,
  //             val_n: e,
  //             class_isn: 220575,
  //             user_isn: auth.user.data.user_info.foreign_id
  //       };
  //       // setAgrAttributes([...agrAttributes[key], {
  //       //   element.attr_isn = ele.attr_isn,
  //       //   element.obj_isn = expressQuotation?.id,
  //       //   element.val_n = e,
  //       //   element.class_isn = 220575,
  //       //   element.user_isn = auth.user.data.user_info.foreign_id,}
  //       // ]);
  //     }
  //   });
  //   // setAgrAttributes([
  //   //   ...agrAttributes,
  //   //   {
  //   //     attr_isn: ele.attr_isn,
  //   //     obj_isn: expressQuotation?.id,
  //   //     val_n: e,
  //   //     class_isn: 220575,
  //   //     user_isn: auth.user.data.user_info.foreign_id
  //   //   },
  //   // ]);
  // }

  const getAllErrorsData = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function calcSum() {
    console.log();
  }

  async function goToQuotation() {
    const data = await api.expressQuotation.sendToFullQuotation({
      id: quotationId,
      foreign_id: universalDataModal?.ISN,
    });

    if (data.data.success == 'true') {
      navigate(`/quotation/${data.data.data.full_quotation_id}`);
    } else if (data.data.success == 'false') {
      message.error(data.data.message);
    }

    // if (getQuotationId()) {
    // }
  }

  function getData() {
    setUniversalModalVisible(true);
  }

  const getFull = () => {
    if (product === undefined) {
      return expressQuotation?.product_name;
    }
    return product?.full_name;
  };

  const getQuotationId = () => {
    const copyNewCreatedQuotation = Object.assign({}, expressQuotation);
    let idQuotation = copyNewCreatedQuotation.quotation_id
      ? copyNewCreatedQuotation.quotation_id
      : copyNewCreatedQuotation.id;
    return idQuotation;
  };

  return (
    <>
      <Card
        style={{
          marginTop: 20,
        }}
      >
        <Title level={4}>
          Экспресс котировка по продукту {getFull()} <br></br>ID Котировки{' '}
          {getQuotationId()}
        </Title>
      </Card>
      <Form
        name="basic"
        layout="vertical"
        form={form}
        onFinish={getAllValidatedData}
        onFinishFailed={getAllErrorsData}
      >
        <Card
          style={{
            marginTop: 20,
          }}
          className="quotation-page__card"
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                label="Контрагент"
                name={`lala`}
                rules={[
                  {
                    required: true,
                    message: 'Контрагент не выбран!',
                  },
                ]}
              >
                <Search
                  placeholder="Поиск"
                  onSearch={getData}
                  onClick={getData}
                  style={{}}
                  readOnly
                />
              </Form.Item>
              <Form.Item hidden name={`universal_contragentModal`}>
                <Input onClick={getData} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Form.List name="agr_attributes">
              {(fields) => (
                <>
                  <Row gutter={15}>
                    <>
                      {attributes?.map((ele, index) => {
                        return (
                          <Col span={8}>
                            {ele.attr_isn == 822261 && quotationId ? (
                              <Form.Item
                                // name={`${ele.attr_isn}[1]`}
                                name={[`val_n: ${ele.attr_isn}`]}
                                label={`${ele.attr_name}`}
                                rules={[
                                  {
                                    required:
                                      ele.required === true ? true : false,
                                    message: `${ele.attr_name} не выбран!`,
                                  },
                                ]}
                              >
                                <Input
                                  style={{ width: '100%' }}
                                  value={quotationId}
                                  disabled={ele.attr_isn == 822261}
                                ></Input>
                              </Form.Item>
                            ) : ele?.attr_type?.toLowerCase() === 'checkbox' ? (
                              <Form.Item
                                name={[`val_n: ${ele.attr_isn}`]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  style={{ marginTop: 30 }}
                                  // onChange={(e) => {
                                  //   setAgrAttributes([
                                  //     ...agrAttributes,
                                  //     {
                                  //       attr_isn: ele.attr_isn,
                                  //       obj_isn: expressQuotation?.id,
                                  //       val_n:
                                  //         e.target.checked === true ? 1 : 0,
                                  //       class_isn: 220575,
                                  //       user_isn:
                                  //         auth.user.data.user_info.foreign_id,
                                  //     },
                                  //   ]);
                                  // }}
                                >
                                  {ele.attr_name}
                                </Checkbox>
                              </Form.Item>
                            ) : ele?.attr_type?.toLowerCase() === 'dicti' ? (
                              <Form.Item
                                name={[`val_n: ${ele.attr_isn}`]}
                                label={`${ele.attr_name}`}
                                rules={[
                                  {
                                    required:
                                      ele.required === true ? true : false,
                                    message: `${ele.attr_name} не выбран!`,
                                  },
                                ]}
                              >
                                <Select
                                // onSelect={(e) => {
                                //   setAgrAttributes([
                                //     ...agrAttributes,
                                //     {
                                //       attr_isn: ele.attr_isn,
                                //       obj_isn: expressQuotation?.id,
                                //       val_n: e,
                                //       class_isn: 220575,
                                //       user_isn:
                                //         auth.user.data.user_info.foreign_id,
                                //     },
                                //   ]);
                                // }}
                                >
                                  {ele.child?.map((el) => (
                                    <Option
                                      value={el.foreign_id.toString()}
                                      key={el.foreign_id.toString()}
                                    >
                                      {el.full_name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            ) : ele?.attr_type?.toLowerCase() === 'number' ||
                              ele?.attr_type?.toLowerCase() === 'integer' ||
                              ele?.attr_type?.toLowerCase() === 'number' ||
                              ele?.attr_type?.toLowerCase() === 'float' ? (
                              <Form.Item
                                // name={`${ele.attr_isn}[1]`}
                                name={[`val_n: ${ele.attr_isn}`]}
                                label={`${ele.attr_name}`}
                                rules={[
                                  {
                                    required:
                                      ele.required === true ? true : false,
                                    message: `${ele.attr_name} не выбран!`,
                                  },
                                ]}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                  // onChange={(e) => {
                                  //   setAgrAttributes([
                                  //     ...agrAttributes,
                                  //     {
                                  //       attr_isn: ele.attr_isn,
                                  //       obj_isn: expressQuotation?.id,
                                  //       val_n: e,
                                  //       class_isn: 220575,
                                  //       user_isn:
                                  //         auth.user.data.user_info.foreign_id,
                                  //     },
                                  //   ]);
                                  // }}
                                ></InputNumber>
                              </Form.Item>
                            ) : ele?.attr_type?.toLowerCase() === 'text' ||
                              ele?.attr_type?.toLowerCase() === 'agreement' ||
                              ele?.attr_type?.toLowerCase() === 'agrcalc' ||
                              ele?.attr_type?.toLowerCase() ===
                                'agreementcalc' ? (
                              <Form.Item
                                name={[`val_c: ${ele.attr_isn}`]}
                                label={`${ele.attr_name}`}
                                rules={[
                                  {
                                    required:
                                      ele.required === true ? true : false,
                                    message: `${ele.attr_name} не выбран!`,
                                  },
                                ]}
                              >
                                <Input
                                // onChange={(e) => {
                                //   setAgrAttributes([
                                //     ...agrAttributes,
                                //     {
                                //       attr_isn: ele.attr_isn,
                                //       obj_isn: expressQuotation?.id,
                                //       val_c: e.target.value,
                                //       class_isn: 220575,
                                //       user_isn:
                                //         auth.user.data.user_info.foreign_id,
                                //     },
                                //   ]);
                                // }}
                                />
                              </Form.Item>
                            ) : ele?.attr_type?.toLowerCase() === 'deptempl' ? (
                              <Form.Item
                                name={[`val_c: ${ele.attr_isn}`]}
                                label={`${ele.attr_name}`}
                                rules={[
                                  {
                                    required:
                                      ele.required === true ? true : false,
                                    message: `${ele.attr_name} не выбран!`,
                                  },
                                ]}
                              >
                                <TreeSelect
                                  style={{ width: '100%' }}
                                  dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                  }}
                                  treeLine={{ showLeafIcon: false }}
                                  allowClear={true}
                                  disabled={isLoadingColleaguesList}
                                  showSearch={true}
                                  // onChange={(e) => {
                                  //   setAgrAttributes([
                                  //     ...agrAttributes,
                                  //     {
                                  //       attr_isn: ele.attr_isn,
                                  //       obj_isn: expressQuotation?.id,
                                  //       val_c: e,
                                  //       class_isn: 220575,
                                  //       user_isn:
                                  //         auth.user.data.user_info.foreign_id,
                                  //     },
                                  //   ]);
                                  // }}
                                >
                                  {colleaguesList.map((item) =>
                                    renderColleguesTreeNodes(item, true)
                                  )}
                                </TreeSelect>
                              </Form.Item>
                            ) : ele?.attr_type?.toLowerCase() === 'empl' ? (
                              <Form.Item
                                name={[`val_c: ${ele.attr_isn}`]}
                                label={`${ele.attr_name}`}
                                rules={[
                                  {
                                    required:
                                      ele.required === true ? true : false,
                                    message: `${ele.attr_name} не выбран!`,
                                  },
                                ]}
                              >
                                <TreeSelect
                                // onChange={(e) => {
                                //   setAgrAttributes([
                                //     ...agrAttributes,
                                //     {
                                //       attr_isn: ele.attr_isn,
                                //       obj_isn: expressQuotation?.id,
                                //       val_c: e,
                                //       class_isn: 220575,
                                //       user_isn:
                                //         auth.user.data.user_info.foreign_id,
                                //     },
                                //   ]);
                                // }}
                                >
                                  <TreeNode
                                    value={`${ele.attr_name}`}
                                    title={`${ele.attr_name}`}
                                    selectable={false}
                                  >
                                    <TreeNode
                                      value={`${ele.attr_name}`}
                                      title={`${ele.attr_name}`}
                                    />
                                  </TreeNode>
                                </TreeSelect>
                              </Form.Item>
                            ) : ele?.attr_type?.toLowerCase() === 'date' ? (
                              <Form.Item
                                name={[`val_d: ${ele.attr_isn}`]}
                                label={`${ele.attr_name}`}
                                rules={[
                                  {
                                    required:
                                      ele.required === true ? true : false,
                                    message: `${ele.attr_name} не выбран!`,
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: '100%' }}
                                  format="DD.MM.YYYY"
                                  onChange={(e, dateString) => {
                                    setAgrAttributes([
                                      ...agrAttributes,
                                      {
                                        attr_isn: ele.attr_isn,
                                        obj_isn: expressQuotation?.id,
                                        val_d: dateString,
                                        class_isn: 220575,
                                        user_isn:
                                          auth.user.data.user_info.foreign_id,
                                      },
                                    ]);
                                  }}
                                ></DatePicker>
                              </Form.Item>
                            ) : ele?.attr_type?.toLowerCase() === 'docs' ? (
                              <Form.Item
                                name={[`val_n: ${ele.attr_isn}`]}
                                label={`${ele.attr_name}`}
                                rules={[
                                  {
                                    required:
                                      ele.required === true ? true : false,
                                    message: `Введите ${ele.attr_name}`,
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  style={{ width: '100% ' }}
                                  // onChange={(e) => {
                                  //   setAgrAttributes([
                                  //     ...agrAttributes,
                                  //     {
                                  //       attr_isn: ele.attr_isn,
                                  //       obj_isn: expressQuotation?.id,
                                  //       val_n: e,
                                  //       class_isn: 220575,
                                  //       user_isn:
                                  //         auth.user.data.user_info.foreign_id,
                                  //     },
                                  //   ]);
                                  // }}
                                />
                              </Form.Item>
                            ) : (
                              ''
                            )}
                          </Col>
                        );
                      })}
                    </>
                  </Row>
                </>
              )}
            </Form.List>
          </Row>
        </Card>
        {expressSum && (
          // <>
          <Card style={{ marginTop: 20, marginBottom: 20 }}>
            <Row>
              <Col span={4}>
                Тариф<br></br>
                <InputNumber
                  value={expressInfoTariff + '%'}
                  style={{ width: '80% ' }}
                  readOnly
                ></InputNumber>
              </Col>
              <Col span={4}>
                Премия<br></br>
                <Input
                  value={expressSum}
                  style={{ width: '80% ' }}
                  readOnly
                ></Input>
              </Col>
              {/* <Col span={8}>На дату
                <DatePicker
                  locale={locale}
                  style={{ width: "100%" }}
                  value={expressInfoDate}
                ></DatePicker>
              </Col> */}
              <Col span={16}>
                Расчетные коэффициенты
                <Input
                  value={expressInfoTariffStr}
                  style={{ width: '100% ' }}
                  readOnly
                ></Input>
              </Col>
            </Row>
          </Card>
        )}
        <Row>
          <Col md={{ span: 3, offset: 7 }}>
            <Button
              htmlType="submit"
              type="primary"
              className="justify-content-md-center"
              style={{ marginTop: 20, marginBottom: 20 }}
              onClick={calcSum}
            >
              Рассчитать стоимость
            </Button>
          </Col>
          {expressInfoTariff &&
          expressSum &&
          parseFloat(expressSum) > 0 &&
          expressInfoTariffStr ? (
            <Col md={{ span: 3, offset: 3 }}>
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginTop: 20, marginBottom: 20 }}
                onClick={goToQuotation}
              >
                Переход на полную котировку
              </Button>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Form>
      <ContragentModal
        universalDataModal={universalDataModal}
        visible={universalModalVisible}
        setUniversalModalVisible={setUniversalModalVisible}
        universalNameModal={universalNameModal}
        setUniversalDataModal={setUniversalDataModal}
      />
    </>
  );
};

export default ExpressProduct;
