import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
} from 'antd';
import apiQuotation from '@modules/Quotation/services';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import CInput from '@components/UI/input/CInput';
import MainContractQuatations from './MainContractQuatations';
import { useDispatch } from 'react-redux';

interface StatusType {
  doc_status: boolean;
  field_status: boolean;
}

function MainInvoiceModal({ invoiseIsn, isOpen, setInvoiceIsn, onClick }) {
  // все поля Form
  const [invoiceForm] = Form.useForm();

  // состояние для поле 'Дата оплаты:, Сумма:, Cчет в каспи:, Назначение платежа:'
  const [statusField, setStatusField] = useState<StatusType>({
    doc_status: false,
    field_status: false,
  });

  // номер телефона
  const [phone, setPhone] = useState<string>('');

  // состояние для статусов для выборки (Аннулирован, В работе, Подписан, Оплачен, На подписи)
  const [statusList, setStatusList] = useState<any>([]);

  const [agrsums, setAgrsums] = useState<any>();

  const [editAgrsums, setEditAgrsums] = useState<any>();

  const dispatch = useDispatch();

  /**
   * метод для получение данных по счетам
   */
  const getInvoiceData = () => {
    if (invoiseIsn) {
      apiQuotation.invoice
        .getInvoice(invoiseIsn)
        .then((response) => {
          if (response.status === 200) {
            const statusesList = response.data.data.STATUSES.children;
            const statusName = response.data.data.VAL.doc_status;
            const fieldsData = response.data.data.VAL;

            setStatusList(statusesList);
            setAgrsums(response.data.data.VAL.agrsum);

            // если статус (Оплачен) то блокируем все поля
            if (statusName === 'Оплачен') {
              setStatusField({ doc_status: true, field_status: true });
            } else {
              setStatusField({ doc_status: false, field_status: false });
            }

            // если статус (Подписан) то блокируем все поля кроме (Статус:)
            if (statusName === 'Подписан') {
              setStatusField({ doc_status: false, field_status: true });
            }

            // Формируем поля для Form , Form.Item
            invoiceForm.setFieldsValue({
              ...fieldsData,
              doc_date: dayjs(fieldsData.doc_date),
              doc_paydate: dayjs(fieldsData.doc_paydate),
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  /**
   *  наблюдение счетов
   */
  useEffect(() => {
    getInvoiceData();
  }, [invoiseIsn]);

  /**
   * редактируем статусы по options antd (для селекта)
   */
  const optionsStatuses = statusList.reduce((acc, item) => {
    const form = {
      id: item.foreign_id,
      value: `${item.foreign_id}`,
      label: item.full_name,
    };
    acc.push(form);
    return acc;
  }, []);

  /**
   * редактируем существующий счет
   */
  const updateInvoiceFunc = async () => {
    const doc_paydate: string = dayjs(
      invoiceForm.getFieldValue('doc_paydate')
    ).format('DD.MM.YYYY');
    const amount_curr: string = invoiceForm.getFieldValue('amount_curr');
    const doc_statusisn: string = invoiceForm.getFieldValue('doc_status');
    const remark: string = invoiceForm.getFieldValue('remark');

    const fieldsUpdate: Object = {
      doc_paydate: doc_paydate, // Дата оплаты
      amount_curr: amount_curr, // Сумма
      doc_statusisn: doc_statusisn, // Статуса документа
      attr_mobile_number: phone.replace(/\D/g, '').slice(1) || '', // добавил сюда регулярку нужно оставить только цифры
      remark: remark || '',
      agrsum: editAgrsums || null,
    };

    apiQuotation.invoice
      .updateInvoice(invoiseIsn, fieldsUpdate)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: 'UPDATE_CONTRACT', payload: 'updated' });
          getInvoiceData();
          notification['success']({
            message: 'Данные успешно сохранены',
            duration: 2,
          });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Данные не сохранены',
          duration: 2,
        });
      });
  };

  const closeModal = () => {
    setInvoiceIsn(null);
  };

  const setPhoneNumber = (event) => {
    setPhone(event);
  };

  const changedAgrsums = (data) => {
    setEditAgrsums(data);
  };

  // функция для поле Статус:
  const handleChangeStatus = (isnSatus: string) => {
    // 2518 - isn статуса подписан
    if (isnSatus === '2518') {
      setStatusField({ doc_status: false, field_status: true });
    } else {
      setStatusField({ doc_status: false, field_status: false });
    }
  };

  return (
    <Modal
      title={
        <span
          style={{
            color: '#7449ef',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          Счет
        </span>
      }
      open={isOpen}
      onCancel={closeModal}
      onOk={updateInvoiceFunc}
      okText={'Сохранить'}
      width="1000px"
      className="mb-3"
    >
      <Form name="invoiceForm" form={invoiceForm} layout="vertical">
        <Row className="mt-3" gutter={6}>
          <Col span={8}>
            <Form.Item name="doc_id" label="Номер:">
              <Input placeholder="Номер счета" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="doc_date" label="Дата создания:">
              <DatePicker
                placeholder="Дата создания:"
                // value={dayjs(edit.DATE_PAY)}
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="doc_paydate" label="Дата оплаты:">
              <DatePicker
                placeholder="Дата оплаты"
                // value={dayjs(edit.DATE_PAY)}
                format="DD.MM.YYYY"
                disabled={statusField.field_status}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="curr_code" label="Валюта:">
              <Input placeholder="Валюта" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="doc_status" label="Статус:">
              <Select
                onChange={handleChangeStatus}
                options={optionsStatuses}
                disabled={statusField.doc_status}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount_curr" label="Сумма:">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Сумма"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="subj_fullname" label="Плательщик:">
              <Input placeholder="Плательщик" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="recipient_fullname" label="Получатель:">
              <Input placeholder="Получатель" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="attr_mobile_number" label="Cчет в каспи:">
              <CInput
                placeholder="Cчет в каспи"
                disabled={statusField.field_status}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="phoneMask" // как получить value отсюда
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="recipient_subaccname"
              label="Банковские реквизиты получателя:"
            >
              <Input placeholder="Банковские реквизиты получателя" disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            {/* все котировки счетов */}
            <MainContractQuatations
              status={statusField}
              agrsums={agrsums}
              getAgrsumsList={changedAgrsums}
            />
          </Col>
          <Col span={24}>
            <Form.Item name="remark" label="Назначение платежа:">
              <TextArea
                placeholder="Назначение платежа"
                autoSize={false}
                disabled={statusField.field_status}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default MainInvoiceModal;
