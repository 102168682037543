import CInput from '@components/UI/input/CInput';
import CRadio from '@components/UI/radio/CRadio';
import CSelect from '@components/UI/select/CSelect';
import type { TreeSelectProps } from 'antd';
import {
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Checkbox,
  Button,
  notification,
  TreeSelect,
} from 'antd';
import { useEffect, useState } from 'react';
import api from '@modules/Quotation/services';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import CDatePicker from '@components/UI/datepicker/CDatePicker';
import { TreeNode } from 'antd/es/tree-select';
import QuotationTreeSelect from '@modules/Quotation/MainQuotation/QuotationTreeSelect';
import DateMask from '@modules/Edo/DateMask';
import dayjs from 'dayjs';

const { Option } = Select;
const ObjectCardAdditionalDynamic = ({
  agrIsn,
  objAttributesList,
  userForeignId,
  additionalObjAttributesForm,
  selectedObj,
  dicti,
  objAttrData,
}) => {
  const [loading, setLoading] = useState(false);
  const { stateDateQuotation } = useQuotation();
  const saveObjAttr = async () => {
    additionalObjAttributesForm.validateFields().then(async (value) => {
      setLoading(true);
      const all = Object.keys(additionalObjAttributesForm.getFieldsValue())
        .map((key) => additionalObjAttributesForm.getFieldsValue()[key])
        .map((item) => {
          if (item.value !== undefined) {
            return {
              ...item,
              value: item.value ? '1' : '0',
              fid_obj: selectedObj.fid_obj,
              user_isn: userForeignId,
              obj_isn: selectedObj.isn,
            };
          } else {
            return {
              ...item,
              fid_obj: selectedObj.fid_obj,
              user_isn: userForeignId,
              obj_isn: selectedObj.isn,
            };
          }
        });
      all.map(async (item) => {
        await api.saveQuotation.attribute_obj_save({
          agr_obj_attributes: item,
        });
      });
      notification.success({
        message: 'Данные успешно сохранены',
      });
      setLoading(false);
    });
  };
  useEffect(() => {
    if (agrIsn) {
      Object.keys(additionalObjAttributesForm.getFieldsValue()).map((key) => {
        additionalObjAttributesForm.setFieldsValue({
          [key]: {
            ...additionalObjAttributesForm.getFieldsValue()[key],
            agr_isn: agrIsn,
          },
        });
      });

      // additionalObjAttributesForm.setFieldValue('agr_isn', agrIsn);
    }
  }, [agrIsn]);
  useEffect(() => {
    if (objAttrData && objAttrData.attributes_object_insurer.length) {
      objAttrData.attributes_object_insurer.map((item) => {
        switch (item.attr_isn_code) {
          case 'DICTI':
            additionalObjAttributesForm.setFieldsValue({
              [item.attr_isn]: {
                ...item,
                val_n: item.val_n ? Number(item.val_n) : null,
                agr_isn: agrIsn,
              },
            });
            break;
          case 'DATE':
            additionalObjAttributesForm.setFieldsValue({
              [item.attr_isn]: {
                ...item,
                val_d: item.value ? dayjs(item.value) : null,
                agr_isn: agrIsn,
              },
            });
            break;
          case 'CHECKBOX':
            additionalObjAttributesForm.setFieldsValue({
              [item.attr_isn]: {
                ...item,
                agr_isn: agrIsn,
                value: item.val_c === 1,
              },
            });
            break;
          default:
            additionalObjAttributesForm.setFieldsValue({
              [item.attr_isn]: { ...item, agr_isn: agrIsn },
            });
        }
      });
    }
  }, [objAttrData]);
  DateMask();

  return (
    <CustomLoader spinning={loading}>
      <Form
        className="c-form"
        layout="vertical"
        name="objAttributes"
        form={additionalObjAttributesForm}
      >
        <Row gutter={6}>
          {objAttributesList.additional?.map((item) => {
            if (item.active_mycent) {
              switch (item.attr_type) {
                case 'DICTI':
                case 'SQL':
                  return (
                    <Col span={8} key={`${item.attr_isn}-${item.attr_name}`}>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'val_n']}
                        label={item.attr_name}
                        rules={[
                          {
                            required: item.required,
                            message: 'Обязательное поле',
                          },
                        ]}
                      >
                        {dicti && dicti[item.attr_isn]?.children ? (
                          dicti[item.attr_isn]?.children[0]?.n_children ? (
                            <QuotationTreeSelect
                              placeholder={item.attr_name}
                              list={dicti[item.attr_isn]?.children}
                              size="middle"
                            />
                          ) : (
                            <CSelect
                              placeholder={item.attr_name}
                              disabled={checkDisabledField(
                                stateDateQuotation.status_isn
                              )}
                            >
                              {dicti[item.attr_isn]?.children?.map((dicti) => (
                                <Option
                                  value={
                                    dicti.foreign_id
                                      ? dicti.foreign_id
                                      : dicti.isn
                                  }
                                  key={
                                    dicti.foreign_id
                                      ? dicti.foreign_id
                                      : dicti.isn
                                  }
                                >
                                  {dicti.full_name}
                                </Option>
                              ))}
                            </CSelect>
                          )
                        ) : (
                          <CSelect
                            placeholder={item.attr_name}
                            disabled={checkDisabledField(
                              stateDateQuotation.status_isn
                            )}
                          >
                            {dicti[item.attr_isn]?.map((dicti) => (
                              <Option
                                value={
                                  dicti.foreign_id
                                    ? dicti.foreign_id
                                    : dicti.isn
                                }
                                key={
                                  dicti.foreign_id
                                    ? dicti.foreign_id
                                    : dicti.isn
                                }
                              >
                                {dicti.full_name}
                              </Option>
                            ))}
                          </CSelect>
                        )}
                      </Form.Item>
                    </Col>
                  );
                case 'NUMBER':
                case 'INTEGER':
                case 'FLOAT': {
                  return (
                    <Col span={8} key={`${item.attr_isn}-${item.attr_name}`}>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        // initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'val_n']}
                        label={item.attr_name}
                        rules={[
                          {
                            required: item.required,
                            message: 'Обязательное поле',
                          },
                        ]}
                      >
                        <CInput
                          placeholder={item.attr_name}
                          className={
                            item.attr_isn === 475611 ? 'numericMask' : null
                          }
                          disabled={
                            checkDisabledField(stateDateQuotation.status_isn) ||
                            item.attr_isn === 2668771
                          }
                        />
                      </Form.Item>
                    </Col>
                  );
                }
                case 'CHECKBOX': {
                  return (
                    <Col span={8} key={`${item.attr_isn}-${item.attr_name}`}>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        // initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'obj_isn']}
                        // initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'value']}
                        valuePropName="checked"
                        rules={[
                          {
                            required: item.required,
                            message: 'Обязательное поле',
                          },
                        ]}
                      >
                        <Checkbox
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        >
                          {item.attr_name}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  );
                }
                case 'DOCS':
                case 'TEXT': {
                  return (
                    <Col span={8} key={`${item.attr_isn}-${item.attr_name}`}>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        // initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'val_c']}
                        label={item.attr_name}
                        rules={[
                          {
                            required: item.required,
                            message: 'Обязательное поле',
                          },
                        ]}
                      >
                        <CInput
                          placeholder={item.attr_name}
                          disabled={
                            checkDisabledField(stateDateQuotation.status_isn) ||
                            item.attr_isn === 2668771
                          }
                        />
                      </Form.Item>
                    </Col>
                  );
                }
                case 'DATE': {
                  return (
                    <Col span={8} key={`${item.attr_isn}-${item.attr_name}`}>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        // initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'val_d']}
                        label={item.attr_name}
                        rules={[
                          {
                            required: item.required,
                            message: 'Обязательное поле',
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          format={'DD.MM.YYYY'}
                          placeholder={item.attr_name}
                          className="dateMask"
                          // size="middle"
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                    </Col>
                  );
                }
              }
            }
          })}
        </Row>
        <Col span={24}>
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={saveObjAttr}
          >
            Сохранить
          </Button>
        </Col>
      </Form>
    </CustomLoader>
  );
};
export default ObjectCardAdditionalDynamic;
