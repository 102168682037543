import React from 'react';

import { Row, Col, Statistic, Avatar, Input } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, CrownFilled, FolderOpenOutlined } from '@ant-design/icons';

import './EventItemContent.scss';
import { IBooking } from '../../../pages/Talk/Talk';
import dayjs from 'dayjs';
const { Countdown } = Statistic;

const { TextArea } = Input;

const EventItemContent: React.FC<IBooking> = ({ deadline, booking = [] }) => {
  return (
    <div className="event-item-content">
      <Row gutter={[25, 25]}>
        <Col span="12">
          {booking?.map((client, index) => {
            return (
              <Row gutter={25} key={client.deadline + index}>
                <Col span="24">
                  <h3 className="event-item-content__title">Дата встречи</h3>
                  <p>{dayjs(client.begin).format('D MMMM YYYY')}</p>
                </Col>
                <Col span="24">
                  <h3 className="event-item-content__title">
                    Время начала / время окончания события
                  </h3>
                  <p>
                    {dayjs(client.begin).format('HH:mm')} /{' '}
                    {dayjs(client.end).format('HH:mm')}
                  </p>
                </Col>
                <Col span="24">
                  <h3 className="event-item-content__title">Место встречи</h3>
                  <p>{client.room_name}</p>
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col span="12">
          <Countdown
            title="До начала осталось"
            value={deadline}
            format="D д. H ч. m мин. s сек."
          />
        </Col>
      </Row>
      <h3 className="event-item-content__title event-item-content__title-participants event-item-content__title-color">
        Участники
      </h3>
      {booking[0]?.clients.map((client, index) => {
        return (
          <Row gutter={24} key={client.full_name + index}>
            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
              <Avatar
                src={`https://mycent.kz/${client.avatar.link}`}
                alt="фото"
                size={{
                  xs: 40,
                  sm: 40,
                  md: 40,
                  lg: 40,
                  xl: 40,
                  xxl: 40,
                }}
              ></Avatar>
              <span className="ml-2">{client.full_name}</span>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} className="d-flex align-center justify-center">
              {Number(client.status) === 1 && <CheckCircleFilled className="check-icon"/>}
              {Number(client.status) === 2 && <CrownFilled className="host-icon"/>}
              {Number(client.status) === 0 && <CloseCircleFilled className="close-icon"/>}
            </Col>
          </Row>
        );
      })}
      <h4 className="event-item-content__title event-item-content__title-text">
        Описание события
      </h4>
      <Row gutter={24} className="mt-3 mb-6">
        <Col span={24}>
          <TextArea
            placeholder="Описание события"
            name="description"
            autoSize
            value={booking[0]?.description}
            readOnly
          />
        </Col>
      </Row>
      {booking[0]?.files.length > 0 && (
        <div className="event-item-content__item event-item-content__title-color mb-6">
          Файлы
        </div>
      )}
      <Row gutter={24} className="mb-8">
        {booking[0]?.files.map((file, index) => {
          return (
            <React.Fragment key={file.file + index}>
              <Col span={6}>
                <a
                  style={{ color: 'black' }}
                  target="_blank"
                  href={`https://mycent.kz/${file.file}`}
                >
                  <FolderOpenOutlined className="big-icon ml-1" />
                  <span className="ml-10 mt-3">
                    Документ
                    {index === 0 ? '' : index === 1 ? index + 1 : index + 1}
                  </span>
                </a>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </div>
  );
};

export default EventItemContent;
