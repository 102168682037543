import { createContext, Dispatch, SetStateAction } from 'react';
import { IUser } from './type';

interface AuthContextInterface {
  isLoaded: boolean;
  user: IUser;
  token: string | null;
  setUser: Dispatch<SetStateAction<IUser>>;
  setToken: Dispatch<SetStateAction<any>>;
  logOut: () => void;
}

const AuthContext = createContext<AuthContextInterface>({
  isLoaded: false,
  user: {} as IUser,
  token: null,
  setUser: () => {},
  setToken: () => {},
  logOut: () => {},
});

export default AuthContext;
