import { Dispatch } from 'react';
import {
  agrAttribute,
  agrClause,
  agrCond,
  AgrDateT,
  AgrObj,
  agrObjectAttribute,
  IAgrCargo,
  IAgreementData,
  IAttributesUnder,
  ICar,
  IMember,
  passingApplicationDataT,
} from './QuotationJournalProvider';

export type Action =
  | { type: TypeAction.agrRole; payload: IMember[] }
  | { type: TypeAction.agrClauseJournal; payload: agrClause[] }
  | { type: TypeAction.agrObject; payload: AgrObj[] }
  | { type: TypeAction.agrAttribute; payload: agrAttribute[] }
  | { type: TypeAction.agrObjectAttribute; payload: agrObjectAttribute[] }
  | { type: TypeAction.agrCond; payload: agrCond[] }
  | { type: TypeAction.agrCar; payload: ICar[] }
  | { type: TypeAction.agrCargo; payload: IAgrCargo[] }
  | {
      type: TypeAction.passingApplicationData;
      payload: passingApplicationDataT[];
    }
  | { type: TypeAction.agrDate; payload: AgrDateT }
  | { type: TypeAction.agreementData; payload: IAgreementData }
  | { type: TypeAction.attributesUnder; payload: IAttributesUnder[] };

export const enum TypeAction {
  agrRole = 'agrRole',
  agrClauseJournal = 'agrClauseJournal',
  agrObject = 'agrObject',
  agrAttribute = 'agrAttribute',
  agrObjectAttribute = 'agrObjectAttribute',
  agrCond = 'agrCond',
  agrCar = 'agrCar',
  agrCargo = 'agrCargo',
  passingApplicationData = 'passingApplicationData',
  agrDate = 'agrDate',
  agreementData = 'agreementData',
  attributesUnder = 'attributesUnder',
}

export interface IInitialState {
  agrRole: IMember[];
  agrClauseJournal: agrClause[];
  agrObject: AgrObj[];
  agrAttribute: agrAttribute[];
  agrObjectAttribute: agrObjectAttribute[];
  agrCond: agrCond[];
  agrCar: ICar[];
  agrCargo: IAgrCargo[];
  passingApplicationData: passingApplicationDataT[];
  agrDate: AgrDateT;
  agreementData: IAgreementData;
  attributesUnder: IAttributesUnder[];
}

export const initialState: IInitialState = {
  agrRole: [],
  agrClauseJournal: [],
  agrObject: [],
  agrAttribute: [],
  agrObjectAttribute: [],
  agrCond: [],
  agrCar: [],
  agrCargo: [],
  passingApplicationData: [],
  agrDate: {
    date_sign: '',
    date_beg: '',
    date_end: '',
    status_id: 0,
    user_id: 0,
    curr_id: 0,
    remark: '',
    product_id: 0,
    id: 0,
    product_name: '',
    premium_sum_total: '',
  },
  agreementData: {
    ID: '',
    STATUS: '',
    ISSUED: '',
    ISN: '',
  },
  attributesUnder: [],
};

export const reducer = (draft: IInitialState, action: Action) => {
  const { payload, type } = action;
  switch (type) {
    case TypeAction.agrRole:
      draft.agrRole = payload;
      break;
    case TypeAction.agrClauseJournal:
      draft.agrClauseJournal = payload;
      break;
    case TypeAction.agrObject:
      draft.agrObject = payload;
      break;
    case TypeAction.agrAttribute:
      draft.agrAttribute = payload;
      break;
    case TypeAction.agrObjectAttribute:
      draft.agrObjectAttribute = payload;
      break;
    case TypeAction.agrCond:
      draft.agrCond = payload;
      break;
    case TypeAction.agrCar:
      draft.agrCar = payload;
      break;
    case TypeAction.agrCargo:
      draft.agrCargo = payload;
      break;
    case TypeAction.agreementData:
      draft.agreementData = payload;
      break;
    case TypeAction.agrDate:
      draft.agrDate = payload;
      break;
    case TypeAction.attributesUnder:
      draft.attributesUnder = payload;
      break;
    case TypeAction.passingApplicationData:
      draft.passingApplicationData = payload;
      break;
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
};

interface IClearJournalState {
  dispatch: Dispatch<Action>;
}

export const clearJournalState = ({ dispatch }: IClearJournalState) => {
  dispatch({
    type: TypeAction.agrRole,
    payload: [],
  });
  dispatch({
    type: TypeAction.agrClauseJournal,
    payload: [],
  });
  dispatch({
    type: TypeAction.agrObject,
    payload: [],
  });
  dispatch({
    type: TypeAction.agrAttribute,
    payload: [],
  });
  dispatch({
    type: TypeAction.agrObjectAttribute,
    payload: [],
  });
  dispatch({
    type: TypeAction.agrCond,
    payload: [],
  });
  dispatch({
    type: TypeAction.agrCar,
    payload: [],
  });
  dispatch({
    type: TypeAction.agrCargo,
    payload: [],
  });
  dispatch({
    type: TypeAction.passingApplicationData,
    payload: [],
  });
  dispatch({
    type: TypeAction.agrDate,
    payload: {
      date_sign: '',
      date_beg: '',
      date_end: '',
      status_id: 0,
      user_id: 0,
      curr_id: 0,
      remark: '',
      id: 0,
      product_id: 0,
      product_name: '',
      premium_sum_total: '',
    },
  });
  dispatch({
    type: TypeAction.agreementData,
    payload: {
      ID: '',
      STATUS: '',
      ISSUED: '',
      ISN: '',
    },
  });
  dispatch({
    type: TypeAction.attributesUnder,
    payload: [],
  });
};
