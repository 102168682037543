import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Tabs,
} from 'antd';
import CardCarMain from './CardCarMain';
import CardCarAdditional from './CardCarAdditional';
import type { TabsProps } from 'antd';
import { useImmerReducer } from 'use-immer';
import api from '@modules/Quotation/services';
import { useEffect } from 'react';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
const { Option } = Select;
type Action = {
  type: 'setDicti' | 'loading' | 'saveObjAttrData';
  payload?: any;
};
interface State {
  categoryCar: any;
  colorCar: any;
  countryCar: any;
  typeCar: any;
  docsCar: any;
  purpOfUseCar: any;
  ownerOfCar: any;
  isLoadingDicti: boolean;
  attrData: any;
}
const initialState: State = {
  categoryCar: null,
  colorCar: null,
  countryCar: null,
  docsCar: null,
  typeCar: null,
  purpOfUseCar: null,
  ownerOfCar: null,
  isLoadingDicti: false,
  attrData: null,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'setDicti': {
      draft.categoryCar = action.payload.categoryCar;
      draft.colorCar = action.payload.colorCar;
      draft.countryCar = action.payload.countryCar;
      draft.typeCar = action.payload.typeCar;
      draft.docsCar = action.payload.docsCar;
      draft.purpOfUseCar = action.payload.purpOfUseCar;
      draft.ownerOfCar = action.payload.ownerOfCar;
      break;
    }
    case 'saveObjAttrData': {
      draft.attrData = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoadingDicti = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const CardCarModal = ({
  closeTransportModal,
  selectedCar,
  isLoading,
  agrIsn,
  objAttributesList,
  userForeignId,
  saveObject,
  objAttrData,
  productIsn,
  attrObjForm,
  carForm,
  AdvancedContractorSearch,
}) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const {
    categoryCar,
    colorCar,
    countryCar,
    isLoadingDicti,
    typeCar,
    docsCar,
    purpOfUseCar,
    ownerOfCar,
    attrData,
  } = state;

  useEffect(() => {
    (async () => {
      dispatch({ type: 'loading', payload: true });
      const car = await api.auto.getDictiCarAuto();
      // Категория транспортного средства
      const categoryCar = await api.auto.getDictiCarCategory();
      // Цвет автотранспорта
      const colorCar = await api.auto.getDictiCarColor();
      // Страны
      const countryCar = await api.auto.getDictiCarCountry();
      // Документы на транспортное средство
      const docsCar = await api.auto.getDictiByIsn(220217);
      // Цель использования ТС
      const purpOfUseCar = await api.auto.getDictiByIsn(2672);
      // Право владения ТС
      const ownerOfCar = await api.auto.getDictiByIsn(2019);
      dispatch({
        type: 'setDicti',
        payload: {
          typeCar: car.data.data?.children,
          categoryCar: categoryCar.data.data,
          colorCar: colorCar.data.data,
          countryCar: countryCar.data.data,
          docsCar: docsCar.data.data,
          purpOfUseCar: purpOfUseCar.data.data,
          ownerOfCar: ownerOfCar.data.data,
        },
      });
      dispatch({ type: 'loading', payload: false });
    })();
  }, []);
  useEffect(() => {
    dispatch({ type: 'loading', payload: true });
    if (objAttrData) {
      objAttrData.map((item: any) => {
        if (item.obj_isn == selectedCar.isn) {
          attrObjForm.setFieldValue(+item.attr_isn, {
            val_n: +item.val_n,
            agr_isn: agrIsn,
            class_isn: item.class_isn,
            fid_obj: selectedCar.fid,
            attr_isn: item.attr_isn,
            user_isn: userForeignId,
            val_c: item.val_c,
            val_d: item.val_d,
          });
        }
      });
    }
    dispatch({ type: 'loading', payload: false });
  }, [objAttrData]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Основное',
      children: (
        <CardCarMain
          agrIsn={agrIsn}
          selectedCar={selectedCar}
          dicties={{
            categoryCar,
            colorCar,
            countryCar,
            typeCar,
            docsCar,
            purpOfUseCar,
            ownerOfCar,
          }}
          saveObject={saveObject}
          carForm={carForm}
          AdvancedContractorSearch={AdvancedContractorSearch}
        />
      ),
    },
    {
      key: '2',
      label: 'Доп. аттрибуты',
      children: (
        <CardCarAdditional
          selectedCar={selectedCar}
          agrIsn={agrIsn}
          objAttributesList={objAttributesList}
          userForeignId={userForeignId}
          objAttrData={objAttrData}
          attrObjForm={attrObjForm}
          productIsn={productIsn}
        />
      ),
    },
  ];
  return (
    <Modal
      width={'1000px'}
      title={
        <span
          style={{
            color: '#7449ef',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          Добавить транспортное средство
        </span>
      }
      open={true}
      onCancel={closeTransportModal}
      footer={<></>}
    >
      <CustomLoader spinning={isLoading || isLoadingDicti}>
        <Tabs defaultActiveKey="1" items={items} type="card" />
      </CustomLoader>
    </Modal>
  );
};
export default CardCarModal;
