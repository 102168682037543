import { Button, Card, Form, Col, Row, Typography, notification } from 'antd';
import CInput from '@components/UI/input/CInput';
import { useEffect, useState } from 'react';
import ContractDocumentModal from '../ContractDocuments/ContractDocumentModal';
import AgreementModal from '@modules/Agreement/AgreementModal';
import { AgreementFull } from '@modules/Agreement/type';
import { useImmerReducer } from 'use-immer';
import { getDocData } from '@modules/Agreement/getDocData';
import { IDynamicTable } from '@modules/common/DynamicTable/data.type';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import ModalCustomTemplateForm from '@modules/Quotation/ModalCustomTemplateForm';
import apiQutation from '@modules/Quotation/services';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import MainInvoiceModal from './Invoice';
import DynamicDocuments from '../ContractDocuments/DynamicDocuments';

interface IContractDocumentProps {
  agrIsn: number | undefined;
  userForeignId: number;
  productIsn?: number | undefined;
  objData?: any[];
  condData?: any[];
  agreement?: any;
  onClick: (params: any) => void;
}
interface Action {
  type:
    | 'getAgreement'
    | 'loading'
    | 'hideModal'
    | 'showModal'
    | 'getDocInfo'
    | 'openInvoice';
  payload?: any;
}

interface State {
  isLoading: boolean;
  isVisibleAgreementModal: boolean;
  currentAgreementID: number | null;
  currentAgreementType: string;
  currentAgreement: AgreementFull | null;
  currentDocInfo: IDynamicTable | null;
  isInvoiceModalOpen: boolean;
  invoiceIsn: string | null;
}

const initialState: State = {
  isLoading: true,
  isVisibleAgreementModal: false,
  currentAgreementID: null,
  currentAgreementType: '',
  currentAgreement: null,
  currentDocInfo: null,
  isInvoiceModalOpen: false,
  invoiceIsn: null,
};

const reducer = (draft: State, action: Action) => {
  switch (action.type) {
    // case 'getAgreementList': {
    //   draft.agreementList = action.payload;
    //   break;
    // }
    case 'getAgreement': {
      draft.currentAgreement = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
    case 'hideModal': {
      draft.isVisibleAgreementModal = false;
      draft.currentAgreementID = null;
      draft.currentAgreementType = '';
      draft.currentAgreement = null;
      break;
    }
    case 'showModal': {
      draft.isVisibleAgreementModal = true;
      draft.currentAgreementID = action.payload.documentID;
      draft.currentAgreementType = action.payload.type;
      break;
    }
    case 'getDocInfo': {
      draft.currentDocInfo = action.payload;
      break;
    }
    case 'openInvoice': {
      draft.invoiceIsn = action.payload.invoiceIsn;
      draft.isInvoiceModalOpen = action.payload.open;
      break;
    }
    default: {
      throw new Error(`Unknown action type: ${action.type}`);
    }
  }
};
function MainContractDocument(props: IContractDocumentProps) {
  const {
    agrIsn,
    userForeignId,
    productIsn,
    objData,
    condData,
    agreement,
    onClick,
  } = props;
  const {
    isCustomTemplate,
    nshdFileList,
    setNshdFileList,
    stateDateQuotation,
  } = useQuotation();

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalCustomTemp, setIsModalCustomTemp] = useState<boolean>(false);
  const [nshdIsn, setNshdIsn] = useState(0);
  const [nshdReamsrk, setNshdRemark] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceNum, setInvoiceIsn] = useState(null);
  const { isInvoiceModalOpen, invoiceIsn } = state;
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (invoiceNum) {
      setIsModalOpen(false);
      dispatch({
        type: 'openInvoice',
        payload: { invoiceIsn: invoiceNum, open: true },
      });
    } else {
      dispatch({
        type: 'openInvoice',
        payload: { invoiceIsn: null, open: false },
      });
    }
  }, [invoiceNum]);

  const openAgreementModal = () => {
    if (agreement['1293521']) {
      dispatch({
        type: 'showModal',
        payload: {
          documentID: agreement['1293521'].doc_list.doc_isn,
          type: 'kias',
        },
      });
    }
  };
  const handleDocInfo = (agreement) => {
    getDocData(agreement.docsform[0]?.ISN)
      .then((values) => dispatch({ type: 'getDocInfo', payload: values }))
      .catch(() => {
        notification.info({
          message: 'Уведомление',
          description: 'Не найдены документы по этому ЛС',
        });
      });
  };
  const handleAgreementGet = (agreement): void => {
    dispatch({ type: 'getAgreement', payload: agreement });
    // if (
    //   agreement?.docsform &&
    //   agreement?.docsform.length
    //   agreement.docsform[0]?.REFDOCISN !== null
    // ) {
    //   handleDocInfo(agreement);
    // }
  };
  const handleCancelAgreement = (): void => dispatch({ type: 'hideModal' });
  const resetDocInfo = () => {
    dispatch({ type: 'getDocInfo', payload: null });
  };
  const documentForm = Form.useFormInstance();
  const { Title } = Typography;

  const onCustomTemplateClick = async () => {
    await apiQutation.saveQuotation
      .sendNshd({
        isn: String(agrIsn),
        user_isn: String(userForeignId),
        remark: '',
      })
      .then((data) => {
        setNshdRemark(data.data.data.REMARK);
        if (data.data.data.DOCSPICT !== null) {
          setNshdFileList(
            JSON.parse(data.data.data.DOCSPICT).map((fileItem) => {
              return {
                file_name: fileItem.file_name,
                file_path: fileItem.file_path,
                isn: fileItem.isn,
              };
            })
          );
        }
        setNshdIsn(data.data.data.ISN);
        setIsModalCustomTemp(true);
      })
      .catch((error) => {
        setIsModalCustomTemp(false);
        notification.error({
          message: 'Произошла ошибка, поробуйте снова.',
        });
      });
  };
  const openOrCloseCard = () => {
    const showCardValue = documentForm.getFieldValue('showCard');
    documentForm.setFieldsValue({
      ['showCard']: !showCardValue,
    });
  };

  const savedData = (params) => {
    onClick(params);
  };

  // useEffect(() => {
  //   if (agreement['1480561']) {
  //     (async () => {
  //       const { data } = await api.getAgreement();
  //     })();
  //   }
  // }, [agreement]);
  return (
    <Form className="c-form" layout="vertical">
      <Card className="mt-5">
        <Col xs={24} xl={8}>
          <Title className="main-contract__section-title" level={3}>
            Документ по договору
          </Title>
        </Col>
        <Row gutter={18} className="mt-5" style={{ marginBottom: -20 }}>
          <Col span={24} onClick={openModal}>
            <Form.Item label="График платежей">
              <CInput placeholder="График платежей" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <ContractDocumentModal
          objData={objData}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          agrIsn={agrIsn}
          userForeignId={userForeignId}
          condData={condData}
          setInvoiceIsn={setInvoiceIsn}
        />
        <MainInvoiceModal
          isOpen={isInvoiceModalOpen}
          invoiseIsn={invoiceIsn}
          setInvoiceIsn={setInvoiceIsn}
          onClick={savedData}
        />
        <DynamicDocuments
          agreement={Object.assign({}, ...agreement)}
          productIsn={productIsn}
        />
      </Card>
    </Form>
  );
}

export default MainContractDocument;
