import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import useAuth from './hooks/useAuth';
import AppRouter from './routes/AppRouter';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const App = () => {
  const auth = useAuth();

  return auth.isLoaded ? (
    <>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </>
  ) : (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CustomLoader />
    </div>
  );
};

export default App;
