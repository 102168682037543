import {
  DatePicker,
  Divider,
  Alert,
  Row,
  Col,
  Button,
  Select,
  Form,
  notification,
  Card,
} from 'antd';
import { useEffect, useState } from 'react';
import { FrownOutlined } from '@ant-design/icons';
import LogoCent from '../../assets/logo-cent.svg';
import LogoCentras from '../../assets/logo-centras.svg';
import CardSim from '../../assets/card-sim.svg';
import BgCard from '../../assets/bg-card.png';
import axios from 'axios';
import dayjs from 'dayjs';
import api from '@services/api';
import useAuth from '../../hooks/useAuth';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const { Option } = Select;

const { RangePicker } = DatePicker;
interface IHistory {
  id: number;
  quantity: number;
  total: number;
  type_id: string;
  description: string;
}
interface CentcoinsProps {
  id: number;
  centcoin?: number;
}

interface CenctoinList {
  id: number;
  name: string;
  quantity: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
}

function Centcoins({ id, centcoin = 0 }: CentcoinsProps) {
  const [history, setHistory] = useState<IHistory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [centcoinList, setCentcoinList] = useState<CenctoinList[]>();
  const [form] = Form.useForm();
  const [centcoinSelected, setCentcoinSelected] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [disableButtonShow, setDisableButtonShow] = useState(false);

  const dateFormat = 'DD.MM.YYYY';
  const firstDayOfYear = dayjs().startOf('year');
  const today = dayjs();
  const [date, setDate] = useState<string[]>([
    firstDayOfYear.format(dateFormat),
    today.format(dateFormat),
  ]);

  const auth = useAuth();
  const handleChangeDatePicker = (date: any, dateString: string[]): void => {
    if (date === null) {
      setDate([]);
      return;
    }

    setDate(dateString);
  };

  const handleClickButtonDatePicker = (): void => {
    setIsLoading(true);

    api.auth
      .getCentcoin({
        id,
        params: {
          start: date[0],
          end: date[1],
        },
      })
      .then((response) => {
        setHistory(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsShowAlert(true);
      });
  };

  const handleFinishForm = (values) => {
    if ((centcoinList !== undefined && values.selectCentcoin) !== undefined) {
      centcoinList?.map((el, key) => {
        if (key === values.selectCentcoin) {
          var selected = el;
          (async () => {
            const responseData = {
              total: selected.quantity,
              quantity: 1,
              type_id: selected.name,
              connection: 'centras',
            };
            try {
              const { data } = await api.centcoin.saveCentcoinApply(
                responseData
              );
              notification.info({
                message: '',
                description: data.message,
              });
            } catch (error) {
              let message;
              if (axios.isAxiosError(error)) {
                message = error.message;
              } else {
                message = String(error);
              }
              notification.info({
                message: 'Ошибка',
                description: message,
              });
            }
          })();
        }
      });
    }
  };

  useEffect(() => {
    (async function getCentcoinList() {
      try {
        const { data } = await api.centcoin.getListCentcoin();
        setCentcoinList(data);
      } catch (error) {
        alert(error);
      } finally {
      }
    })();
  }, []);

  useEffect(() => {
    if (date.length > 0 && (auth.user?.data.id === id || auth.user?.data.id === 1342)) {
      setDisableButtonShow(true);
    } else {
      setDisableButtonShow(false);
    }
  }, [centcoinSelected, disableButtonShow, id]);
  useEffect(() => {
    if (centcoin && centcoin >= centcoinSelected && auth.user?.data.id === id) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [centcoinSelected, disableButton]);

  function checkCentcoin(e: any) {
    if (centcoinList) {
      setCentcoinSelected(centcoinList[e].quantity);
    }
  }

  return (
    <div className="template-cenctoin">
      <CustomLoader spinning={isLoading}>
        <div className="centcoin-card">
          <Card>
            <Row gutter={24}>
              <Col span={10}>
                <div className="card-img">
                  <div className={'flex-div'}>
                    <span className={'centcoin-count'}>{centcoin}</span>
                    <img className={'cent'} src={LogoCent} alt="cent" />
                  </div>
                  <img
                    className={'insurance-logo'}
                    src={LogoCentras}
                    alt="centras"
                  />
                  <img className={'sim-card'} src={CardSim} alt="card" />
                  <img className={'img'} src={BgCard} alt="card" />
                </div>
              </Col>
              <Col span={14}>
                <Form
                  form={form}
                  onFinish={handleFinishForm}
                  layout="horizontal"
                >
                  <Row gutter={24} className="centcoin-history">
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <h3>Потратить сенткоины</h3>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="selectCentcoin">
                        <Select
                          className="select-present"
                          defaultValue="Выберите покупку"
                          dropdownMatchSelectWidth={1100}
                          style={{ maxWidth: '336px' }}
                          onChange={(e: any) => {
                            checkCentcoin(e);
                          }}
                        >
                          {centcoinList?.length &&
                            centcoinList?.map((element, key) => {
                              return (
                                <Option value={key}>
                                  {element.name} - {element.quantity} сенткоинов
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="select-centcoin-button">
                        <Button
                          className="show-btn"
                          htmlType="submit"
                          disabled={!disableButton}
                        >
                          Потратить
                        </Button>
                      </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={12} style={{ paddingTop: '50px' }}>
                      <RangePicker
                        style={{ height: '30px', width: '100%' }}
                        format={dateFormat}
                        defaultValue={[
                          dayjs(firstDayOfYear, dateFormat),
                          dayjs(today, dateFormat),
                        ]}
                        onChange={handleChangeDatePicker}
                      />
                    </Col>
                    <Col span={12} style={{ paddingTop: '50px' }}>
                      <Button
                        className="show-btn"
                        style={{ height: '30px' }}
                        onClick={handleClickButtonDatePicker}
                        disabled={!disableButtonShow}
                      >
                        <img
                          className="optic-img"
                          src="/optic-button.png"
                          alt="optic"
                        />
                        Показать
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </div>
        {history.length === 0 && isShowAlert && (
          <Alert
            message={
              <>
                В этот период, у вас накоплении не наблюдаются <FrownOutlined />
              </>
            }
            description="Хотите накопить обратитесь к сенаторам или к HR"
            type="info"
            style={{ marginTop: '20px' }}
          />
        )}
        {history.length !== 0 && (
          <div className={'history-table'}>
            <table className={'operation-history-table'}>
              <thead>
                <tr>
                  <th>№</th>
                  <th>Вид</th>
                  <th>Описание</th>
                  <th>Количество</th>
                  <th>Итого</th>
                </tr>
              </thead>
              <tbody>
                {history.map((item, key) => {
                  return (
                    <tr key={item.id}>
                      <td>{key + 1}</td>
                      <td>{item.type_id}</td>
                      <td>{item.description}</td>
                      <td>{item.quantity}</td>
                      <td>{item.total}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </CustomLoader>
    </div>
  );
}

export default Centcoins;
