import { Card, Col, Divider, Form, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CandidateFields from '../Common/CandidateFields';
import MainDataFields from '../Common/MainDataFields';
import SalaryFields from '../Common/SalaryFields';
import SubmitButton from '../Common/SubmitButton';
import useLocalStorage from '../hooks/useLocaleStorage';
import useRecruiting from '../hooks/useRecruiting';
import LanguagesFields from './LanguagesFields';
import NoAccess from './NoAccess';
import submitForm from './submitForm';
import useAuth from '../../../hooks/useAuth';

const { Title } = Typography;

function NewApplicationRecruiting(props) {
  const [isLoadingSubmitForm, setIsLoadingSubmitForm] =
    useState<boolean>(false);

  const { title } = props;

  const { state } = useRecruiting();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
  }, [title]);

  const auth = useAuth();

  const [formValues, setFormValues] = useLocalStorage('formValues', {});
  if (!formValues.department_to && auth.user.data?.department.id) {
    formValues.department_to = auth.user.data?.department.id;
  }
  const navigateToMainPage = () => {
    navigate('/recruiting');
  };

  const showNewApplication = state.selectValues.message;

  if (showNewApplication) {
    return <NoAccess />;
  }

  return (
    <Form
      layout="vertical"
      validateMessages={{ required: 'Обязательное поле для заполнения' }}
      name="newApplication"
      onFinish={(values) => {
        submitForm(
          values,
          setFormValues,
          navigateToMainPage,
          setIsLoadingSubmitForm
        );
      }}
      initialValues={formValues}
      onValuesChange={(changedValues, allValues) => {
        setFormValues(allValues);
      }}
      scrollToFirstError
      style={{ fontWeight: 'initial' }}
      disabled={isLoadingSubmitForm}
    >
      <Card style={{ marginTop: 25, marginBottom: 400 }}>
        <Title level={4} style={{ color: '#0057FF', marginBottom: 30 }}>
          Основные данные
        </Title>

        <MainDataFields />

        <Row gutter={24} align="bottom" justify={{ lg: 'start' }}>
          <LanguagesFields />
        </Row>

        <Divider />

        <Title level={4} style={{ color: '#0057FF', marginBottom: 30 }}>
          Оплата труда
        </Title>

        <SalaryFields />

        <CandidateFields />

        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 8, offset: 16 }}>
            <SubmitButton />
          </Col>
        </Row>
      </Card>
    </Form>
  );
}

export default NewApplicationRecruiting;
