import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import { Row, Col, Select, InputNumber, Input, Form } from 'antd';
import React from 'react';
import useRecruiting from '../hooks/useRecruiting';
import { fieldNamesRecruiting } from './fieldNames';

const { TextArea } = Input;
const { Option } = Select;

type MainDataFieldsProps = {
  disabled?: boolean;
};

function MainDataFields({ disabled }: MainDataFieldsProps) {
  const { departmentList, isLoadingColleaguesList, state } = useRecruiting()

  const { selectValues } = state

  return (
    <>
      <Row gutter={24} align="bottom" justify={{ lg: 'start'}}>
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Наименование структурного подразделения (укажите город и адрес филиала)"
            name={fieldNamesRecruiting.department_to}
            rules={[{ required: true }]}
          >
            <TreeSelectCollegues
              colleaguesList={departmentList}
              selectDepartment={true}
              disabled={isLoadingColleaguesList || disabled}
            />
          </Form.Item>
        </Col>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Наименование вакантной должности"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.name_of_post_id}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.name_of_post_id.length &&
                selectValues.name_of_post_id.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Общее количество необходимых сотрудников"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.quantity_people}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        {/* <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Количество сотрудников после закрытия даты ДОУ"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.reminder_candidates}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col> */}

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Причина возникновения вакансии"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.reason_to_recruiting}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.reason_to_recruiting.length &&
                selectValues.reason_to_recruiting.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Желаемый возраст"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.desired_age}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.desired_age.length &&
                selectValues.desired_age.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Пол"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.sex}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.sex.length &&
                selectValues.sex.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Образование"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.education}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.education.length &&
                selectValues.education.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Опыт работы"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.work_experience}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.work_experience.length &&
                selectValues.work_experience.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Статус заявки"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.application_status}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.application_status.length &&
                selectValues.application_status.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Функциональные обязанности"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.functional_responsibilities}
          >
            <TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Col>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Наличие подчиненных"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.is_he_was_boss}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.is_he_was_boss.length &&
                selectValues.is_he_was_boss.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Форма найма"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.type_of_hire}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.type_of_hire.length &&
                selectValues.type_of_hire.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Квалификационные требования к кандидату"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.request_to_candidate}
          >
            <TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Col>
        {/* TODO УЗНАТЬ MULTIPLE КАК БУДЕТ ПРИ ОТПРАВКЕ ИЗ ФОРМЫ*/}
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Возможности и перспективы для кандидата"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.perspective_to_candidate}
          >
            <Select style={{ width: '100%' }} mode="multiple" showArrow>
              {selectValues.perspective_to_candidate.length &&
                selectValues.perspective_to_candidate.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Уровень владения компьютерными программи"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.computer_knowing}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.computer_knowing.length &&
                selectValues.computer_knowing.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default MainDataFields;
