/* eslint-disable @typescript-eslint/no-shadow */
import { FileTwoTone } from '@ant-design/icons';
import { Button, Col, notification, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { IFile, IFileKias } from '../type';
import axios from 'axios';
import api from '../services/api';

const { Title } = Typography;

interface AgreementModalFilesProps {
  fileList: any;
  agreementType: string;
}

function AgreementModalFiles(props: AgreementModalFilesProps) {
  const { fileList = [], agreementType } = props;
  const [file, setFile] = useState<IFileKias | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickSetFile = (file: IFileKias): void => {
    setFile(file);
  };

  useEffect(() => {
    if (file !== null) {
      (async function downloadFile() {
        try {
          setIsLoading(true);

          const { data } = await api.getFile(file);
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', file.fileName);
          link.click();
          link.remove();
        } catch (error) {
          let message;

          if (axios.isAxiosError(error)) {
            message = error.message;
          } else {
            message = String(error);
          }

          notification.info({
            message: 'Ошибка',
            description: message,
          });
        } finally {
          setIsLoading(false);
          setFile(null);
        }
      })();
    }
  }, [file]);

  return (
    <Row className="mb-5">
      <Col span="24">
        <Title level={5} type="secondary" className="mb-5">
          Вложенные документы
        </Title>
        <Space direction="vertical">
          {agreementType === 'mycent' &&
            fileList.map(({ file_path, id, file_name }: IFile) => {
              return (
                <Link
                  to={`/read-docs?path=${file_path}&page=edo`}
                  target="_blank"
                >
                  <Button
                    target="_blank"
                    key={id}
                    type="text"
                    icon={<FileTwoTone />}
                  >
                    {file_name}
                  </Button>
                </Link>
              );
            })}
          {agreementType === 'kias' &&
            fileList.map((file: IFileKias) => {
              return (
                <Button
                  target="_blank"
                  key={file.fileIsn}
                  type="text"
                  icon={<FileTwoTone />}
                  onClick={() => handleClickSetFile(file)}
                  disabled={isLoading}
                >
                  {file.fileName}
                </Button>
              );
            })}
        </Space>
      </Col>
    </Row>
  );
}

export default AgreementModalFiles;
