import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import SearchPosts from '@modules/SearchPosts/SearchPosts';
import PostList from '@modules/Post/PostList';
import Bday from '@modules/Bday';

interface IndexProps {
  title: string;
}

const Index: React.FC<IndexProps> = ({ title = 'Mycent.kz' }) => {
  const [fetchedData, setFetchedData] = useState<any>();
  useEffect(() => {
    document.title = title;
    process.env.NODE_ENV === 'development'
      ? window.location.replace('http://localhost:5173/')
      : window.location.replace('https://new.mycent.kz/');

    const searchParams = new URLSearchParams(location.search);
    const logout = searchParams.get('logout');
  }, []);

  return (
    <></>
    // <Row gutter={[30, 30]}>
    //   <Col span={24} order={2} xxl={{ span: 16, order: 1 }}>
    //     <PostList
    //       fetchedData={fetchedData}
    //       setFetchedData={setFetchedData}
    //       title={'Mycent.kz'}
    //     />
    //   </Col>
    //   <Col span={0} order={1} xxl={{ span: 8, order: 2 }}>
    //     <SearchPosts setFetchedData={setFetchedData} />
    //     <Bday />
    //   </Col>
    // </Row>
  );
};

export default Index;
