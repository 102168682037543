import { createContext } from 'react';

interface ICounterContext {
  countAgreement: number;
  getCountAgreement: () => void;
  countEvent: number;
  getCountEvent: () => void;
}

const CounterContext = createContext<ICounterContext>({
  countAgreement: 0,
  getCountAgreement: () => {},
  countEvent: 0,
  getCountEvent: () => {}
});

export default CounterContext;
