import { Button, Menu, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { MenuProps } from 'antd/es/menu';
import './MenuCategories.scss'
import useUseful from '@hooks/useUseful';
import useAuth from '@hooks/useAuth';
import { getAccessUseful } from '@modules/UsefulSection/helpers/getAccessUseful';

const { Text } = Typography;
type MenuItem = Required<MenuProps>['items'][number];

interface IPropsMenuCategories {
  items: MenuItem[]
  onClickCategory: (e) => void;
  addCategory: () => void;
  deleteCategory: (e) => void;
  editCategory: (e) => void;
}

const MenuCategories = ({
                          items,
                          onClickCategory,
                          addCategory,
                          deleteCategory,
                          editCategory
                        }: IPropsMenuCategories) => {
  const { setDeleteCategoryId, setEditCategoryId, setIsDocument } = useUseful();
  const { user } = useAuth();
  const onDeleteCategory = (e, id: string) => {
    setDeleteCategoryId(id);
    setIsDocument(false);
    deleteCategory(e);
  };

  const onEditCategory = (e, id: string) => {
    setEditCategoryId(id);
    editCategory(e);
  }

  const renderButtons = (item) => (
    <>
      {getAccessUseful('update', user) && <Button
          className="edit-button-useful"
          icon={<i className="edit-icon-useful"/>}
          onClick={(e) => onEditCategory(e, item.eventKey)}
      />}
      {getAccessUseful('delete', user) && <Button
        className="close-button-useful"
        icon={<i className="close-icon-useful"/>}
        onClick={(e) => onDeleteCategory(e, item.eventKey)}
      />}
    </>
  );


  return (
    <div className="sector-useful">
      {getAccessUseful('create', user) && <Button
        icon={<PlusOutlined/>}
        className="add-category-useful"
        onClick={() => addCategory()}
      >
        <Text className="button-text-useful">Добавить папку</Text>
      </Button>}
      <Menu
        className="menu-categories-useful"
        items={items}
        onClick={(e) => onClickCategory(e)}
        itemIcon={(item) => renderButtons(item)}
      />
    </div>
  )
};

export default MenuCategories;