/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, notification, Row } from 'antd';
import { DeleteFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import api from './api';
import contractorFunctions from '@modules/common/Client/Functions';
import dayjs from 'dayjs';
import { IRowColumnEditor } from '@modules/Edo/types';
import { useImmerReducer } from 'use-immer';
import SearchClientModal from '@modules/common/Client/SearchClientModal';
import ResultClientModal from '@modules/common/Client/ResultClientModal';
import AddClientModal from '@modules/common/Client/AddClientModal';
import { useDispatch } from 'react-redux';
import { addData } from '../../../../store/Edo/actions';
import { useSelector } from 'react-redux';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const { Search } = Input;

interface Props {
  fieldID: string;
  fieldName: string;
  title: string;
  required: boolean;
  initialValue?: {
    val: any;
  };
  name?: number;
  constant?: string;
  id?: number;
  isDisabledForm: boolean;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}

// ???

interface IUser {
  department: string;
  duty: string;
  full_name: string;
  user_id: number;
}

type Action = {
  type:
    | 'nextStep'
    | 'backStep'
    | 'showSearchContractorModal'
    | 'hideContractorModal'
    | 'getContractorInfo'
    | 'searchContractor'
    | 'saveContractorList'
    | 'selectContractor'
    | 'selectRelatedContractor'
    | 'search'
    | 'openRelatedInfo'
    | 'saveQuotationData'
    | 'removeContractInfo';
  payload?: any;
};

interface State {
  step: number;
  isVisibleConstactorModal: boolean;
  contractorInfo: any[] | null;
  contractorList: [] | null;
  contractorFullInfo: {} | null;
  isLoading: boolean;
  isJurUser: boolean;
  from: any;
  isRelatedOpen: boolean;
  selectRelatedContractor: any;
  relatedInfo: any;
  quotationData: any;
}
const initialState: State = {
  step: 0,
  isVisibleConstactorModal: false,
  contractorInfo: null,
  contractorList: null,
  contractorFullInfo: null,
  isLoading: false,
  isJurUser: true,
  from: null,
  isRelatedOpen: false,
  selectRelatedContractor: null,
  relatedInfo: null,
  quotationData: null,
};

const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'saveQuotationData': {
      draft.quotationData = action.payload;
      break;
    }
    case 'showSearchContractorModal': {
      draft.step = 1;
      draft.isVisibleConstactorModal = true;
      break;
    }
    case 'searchContractor': {
      draft.step = 2;
      break;
    }
    case 'selectRelatedContractor': {
      draft.selectRelatedContractor = action.payload;
      draft.isRelatedOpen = false;
      draft.isJurUser = true;
      draft.from = null;
      break;
    }
    case 'saveContractorList': {
      draft.isJurUser = action.payload.isJurUser == 'Y';
      draft.contractorList = action.payload.data;
      break;
    }
    case 'hideContractorModal': {
      draft.step = 0;
      // draft.isVisibleConstactorModal = false;
      break;
    }
    case 'openRelatedInfo': {
      draft.isRelatedOpen = action.payload.show;
      draft.relatedInfo = action.payload.data;
      draft.isJurUser = true;
      break;
    }
    case 'selectContractor': {
      draft.isJurUser = action.payload.juridical == 'Y';
      draft.contractorFullInfo = action.payload.data;
      if (action.payload.data) {
        draft.contractorInfo = [
          {
            FULLNAME: action.payload.data.fullname,
            IIN: action.payload.data.iin,
            BIRTHDAY: action.payload.data.birthday,
            ISN: action.payload.data.isn,
          },
        ];
      }
      break;
    }
    case 'getContractorInfo': {
      draft.contractorInfo = action.payload;
      break;
    }
    case 'removeContractInfo': {
      draft.contractorInfo = null;
      draft.contractorFullInfo = action.payload;
      if (action.payload?.jur) {
        draft.isJurUser = action.payload.jur == 'Y';
      }
      break;
    }
    case 'nextStep': {
      if (action.payload.step) {
        draft.step = action.payload.step;
      } else {
        draft.step = action.payload;
      }
      if (action.payload.from) {
        draft.from = action.payload.from;
      }
      break;
    }
    case 'backStep': {
      draft.step = draft.step - 1;
      break;
    }
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

function FieldContractorCard(props: Props) {
  const {
    fieldID,
    fieldName,
    title = 'Title',
    required = false,
    initialValue,
    name,
    constant,
    id,
    isDisabledForm,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;

  //   const [isLoading, setIsLoading] = useState(false);

  const form = Form.useFormInstance();
  const [user, setUser] = useState<any | null>(null);
  const isRowColumnEditor = typeof name === 'number';
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const setDispatch = useDispatch();

  const {
    isVisibleConstactorModal,
    isRelatedOpen,
    relatedInfo,
    contractorInfo,
    step,
    contractorList,
    isLoading,
    contractorFullInfo,
    isJurUser,
    from,
    selectRelatedContractor,
    quotationData,
  } = state;

  const AdvancedContractorSearch = () => {
    dispatch({ type: 'nextStep', payload: 1 });
  };

  const getContractorList = (values) => {
    dispatch({ type: 'search', payload: true });
    contractorFunctions.getContractorList({ dispatch, values });
  };

  const searchContractor = async () => {
    dispatch({ type: 'search', payload: true });
    const { iin } = form.getFieldsValue();
    let returnData;
    try {
      if (iin && iin.length == 12) {
        let reqBody;
        const { data } = await api.getContractorInfo({
          ...reqBody,
          iin,
        });
        if (data.data.length == 1) {
          if (!data.data[0].ISN) {
            const info = await api.saveContractorKias({
              xml_isn: data.data[0].XMLISN,
              ext_system_key: data.data[0].EXTSYSTEMKEY,
            });
            dispatch({
              type: 'getContractorInfo',
              payload: [{ ISN: info.data.isn, ...data.data[0] }],
            });
            returnData = { ISN: info.data.isn, ...data.data[0] };
            if (isAdmin && isDisabledStatus && onChangeByTd) {
              onChangeByTd(fieldID, name);
            }
          } else {
            dispatch({
              type: 'getContractorInfo',
              payload: data.data,
            });
            returnData = data.data[0];
            if (isAdmin && isDisabledStatus && onChangeByTd) {
              onChangeByTd(fieldID, name);
            }
          }
        } else {
          dispatch({ type: 'nextStep', payload: 2 });
          dispatch({
            type: 'saveContractorList',
            payload: { data: data.data },
          });

          returnData = { data: data.data[0] };
        }
      } else {
        notification.error({
          message: 'Ошибка',
          description: 'Введите ИИН / БИН',
        });
      }
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.error({
        message: 'Ошибка',
        description: message,
      });

      dispatch({ type: 'showSearchContractorModal', payload: true });
    }
    dispatch({ type: 'search', payload: false });
    return returnData;
  };

  const document_class_id = useSelector((state: any) => state.data);

  const getUserDocsByIsn = async (isn) => {
    if (constant == 'cKiasObjAttrType_Contractor') {
      try {
        const result = await api.searchDocByUserIsn({
          isn,
        });

        if (result.data.success) {
          setDispatch(addData(result.data.response));

          const { row_column_editor } = form.getFieldsValue();
          let allData = JSON.parse(JSON.stringify(row_column_editor));
          allData = allData.map((column, index) => {
            if (index == name) {
              const check = Object.keys(column).map((key) => {
                if (
                  column[key].full_name == 'Договор АХД' &&
                  result.data.response.length === 1
                ) {
                  return {
                    ...column[key],
                    char_value: result.data.response[0].ID,
                    num_value: result.data.response[0].ISN,
                  };
                } else if (key === fieldID && contractorInfo) {
                  return {
                    ...column[key],
                    char_value: contractorInfo[0]['FULLNAME']
                      ? contractorInfo[0]['FULLNAME']
                      : contractorInfo[0]['JUR_NAME'],
                    full_name: contractorInfo[0]['FULLNAME']
                      ? contractorInfo[0]['FULLNAME']
                      : contractorInfo[0]['JUR_NAME'],
                    num_value: contractorInfo[0]['ISN'],
                  };
                } else if (column[key].type == 'DATE') {
                  return {
                    ...column[key],
                    date_value: dayjs(column[key].date_value).isValid()
                      ? dayjs(column[key].date_value)
                      : null,
                  };
                } else {
                  return { ...column[key] };
                }
              });
              return check;
            } else {
              const data = Object.keys(column).map((key) => {
                if (column[key].type == 'DATE') {
                  return {
                    ...column[key],
                    date_value: dayjs(column[key].date_value).isValid()
                      ? dayjs(column[key].date_value)
                      : null,
                  };
                } else {
                  return { ...column[key] };
                }
              });
              return data;
            }
          });
          const send = allData.map((row) =>
            Object.fromEntries(
              row.map((column) => [
                `id-${column.editor_id}`,
                {
                  display_no: column.display_no,
                  editor_id: column.editor_id,
                  order_no: column.order_no,
                  type: column.type,
                  document_id: id,
                  destination_id: column.destination_id,
                  executor_id: column.executor_id,
                  date_value: column.date_value,
                  num_value: column.num_value,
                  char_value: column.char_value,
                  id: column.id,
                  full_name: column.field_name
                    ? column.field_name
                    : column.full_name,
                  department: column.department,
                  duty: column.duty,
                  document_row_id: column.document_row_id,
                  // full_name: column.field_name,
                },
              ])
            )
          );

          form.setFieldsValue({ row_column_editor: send });
        } else {
          notification.error({
            message: 'Ошибка',
            description: 'Договор контрагента не найден',
          });
        }
      } catch (error) {
        let message;

        if (axios.isAxiosError(error) && error.response) {
          message = error.response.data.message;
        } else {
          message = String(error);
        }

        notification.info({
          message: 'Ошибка',
          description: message,
        });
      }
    }
  };
  const selectContractor = async (value, fromInfo) => {
    try {
      dispatch({ type: 'search', payload: true });
      if (!value.ISN && !value.isn) {
        const info = await api.saveContractorKias({
          xml_isn: value.XMLISN,
          ext_system_key: value.EXTSYSTEMKEY,
        });
        const { data } = await api.getContractorByISN(info.data.isn);
        if (fromInfo == 'related') {
          dispatch({ type: 'selectRelatedContractor', payload: data.data });
        } else {
          dispatch({
            type: 'selectContractor',
            payload: { data: data.data, juridical: data.data.juridical },
          });
        }
      } else {
        const isn = value.ISN ? value.ISN : value.isn;
        const { data } = await api.getContractorByISN(isn);
        dispatch({
          type: 'selectContractor',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      }

      dispatch({ type: 'nextStep', payload: 3 });
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      dispatch({ type: 'search', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const handleSearchUser = (event) => {
    if (event.target.value.length === 12) {
      setTimeout(async () => {
        try {
          dispatch({ type: 'search', payload: true });
          const { data } = await api.getContractorInfo({
            iin: event.target.value,
          });
          if (data.data.length === 1) {
            if (!data.data[0].ISN) {
              const info = await api.saveContractorKias({
                xml_isn: data.data[0].XMLISN,
                ext_system_key: data.data[0].EXTSYSTEMKEY,
              });
              dispatch({
                type: 'selectContractor',
                payload: {
                  data: info.data.data,
                  juridical: info.data.data.juridical,
                },
              });
            } else {
              const info = await api.getContractorByISN(data.data[0].ISN);
              dispatch({
                type: 'selectContractor',
                payload: {
                  data: info.data.data,
                  juridical: info.data.data.juridical,
                },
              });
            }
          } else {
            dispatch({ type: 'nextStep', payload: 2 });
            dispatch({
              type: 'saveContractorList',
              payload: { data: data.data },
            });
          }
          if (isAdmin && isDisabledStatus && onChangeByTd) {
            onChangeByTd(fieldID.split('-')[1], name);
          }
        } catch (error) {
          let message;
          let description;
          if (axios.isAxiosError(error)) {
            description =
              typeof error.response?.data.message !== 'undefined'
                ? error.response.data.message
                : error.response?.statusText;
            message = `Ошибка ${error.response?.status}`;
          } else {
            message = String(error);
          }
          notification.error({
            message,
            description,
          });
        } finally {
          dispatch({ type: 'search', payload: false });
        }
      }, 300);
    }
  };
  const createContractor = () => {
    dispatch({ type: 'nextStep', payload: 3 });
  };
  const changeState = (step, data: any | null, fromInfo) => {
    dispatch({ type: 'nextStep', payload: { step, from: fromInfo } });
    if (data !== null) {
      dispatch({ type: 'saveContractorList', payload: { data } });
    }
    if (fromInfo == 'related') {
      dispatch({
        type: 'removeContractInfo',
        payload: { ...contractorFullInfo, from: 'related', jur: 'Y' },
      });
    }
  };
  const updateContractorInfo = async (value) => {
    dispatch({
      type: 'selectContractor',
      payload: { data: value, juridical: value.juridical },
    });
  };

  const handleDeleteUser = () => {
    dispatch({ type: 'removeContractInfo', payload: null });
    if (isRowColumnEditor) {
      const { row_column_editor } = form.getFieldsValue();

      let allData = JSON.parse(JSON.stringify(row_column_editor));
      allData = allData.map((column, index) => {
        if (index == name) {
          const check = Object.keys(column).map((key) => {
            if (column[key].type == 'DOCS') {
              return {
                ...column[key],
                char_value: undefined,
                num_value: undefined,
              };
            } else if (key === fieldID) {
              return {
                ...column[key],
                department: undefined,
                duty: undefined,
                num_value: undefined,
                char_value: undefined,
              };
            } else if (column[key].type == 'DATE') {
              return {
                ...column[key],
                date_value: dayjs(column[key].date_value).isValid()
                  ? dayjs(column[key].date_value)
                  : null,
              };
            } else {
              return { ...column[key] };
            }
          });
          return check;
        } else {
          const data = Object.keys(column).map((key) => {
            if (column[key].type == 'DATE') {
              return {
                ...column[key],
                date_value: dayjs(column[key].date_value).isValid()
                  ? dayjs(column[key].date_value)
                  : null,
              };
            } else {
              return { ...column[key] };
            }
          });
          return data;
        }
      });
      const send = allData.map((row) =>
        Object.fromEntries(
          row.map((column) => [
            `id-${column.editor_id}`,
            {
              display_no: column.display_no,
              editor_id: column.editor_id,
              order_no: column.order_no,
              type: column.type,
              document_id: id,
              destination_id: column.destination_id,
              executor_id: column.executor_id,
              date_value: column.date_value,
              num_value: column.num_value,
              char_value: column.char_value,
              id: column.id,
              full_name: column.field_name
                ? column.field_name
                : column.full_name,
              department: column.department,
              duty: column.duty,
              document_row_id: column.document_row_id,
              // full_name: column.field_name,
            },
          ])
        )
      );

      form.setFieldsValue({ row_column_editor: send });

      //   Object.assign(row_column_editor[name][fieldID], {
      //     [fieldName]: undefined,
      //     department: undefined,
      //     duty: undefined,
      //     full_name: undefined,
      //   });

      //   form.setFieldsValue({ row_column_editor });
    } else {
      form.setFieldsValue({
        [fieldID]: {
          [fieldName]: undefined,
          department: undefined,
          duty: undefined,
          full_name: undefined,
        },
      });
    }
  };

  const handleCancelAddClientModal = () => {
    dispatch({ type: 'hideContractorModal', payload: 0 });
    dispatch({ type: 'nextStep', payload: { from: null } });
  };
  const showRelatedInfo = async (info) => {
    try {
      if (info) {
        if (info.headisn) {
          const { data } = await api.getContractorByISN(info.headisn);
          dispatch({
            type: 'openRelatedInfo',
            payload: { data: data.data, show: true },
          });
        } else if (info.isn) {
          const { data } = await api.getContractorByISN(info.isn);
          dispatch({
            type: 'openRelatedInfo',
            payload: { data: data.data, show: true },
          });
        }
      }
      notification.success({
        message: 'Сохранено',
        description: 'Данные успешно сохранены',
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: 'Что-то пошло не так',
      });
    }
    return false;
  };

  const closeRelatedInfo = () => {
    dispatch({
      type: 'openRelatedInfo',
      payload: { data: null, show: false },
    });
  };

  useEffect(() => {
    if (contractorInfo && contractorInfo[0]) {
      dispatch({ type: 'search', payload: true });
      (async () => {
        if (isRowColumnEditor) {
          const { row_column_editor } = form.getFieldsValue();
          const res = Object.keys(row_column_editor[name]).filter(
            (key) => row_column_editor[name][key].type === 'DOCS'
          );

          if (res.length > 0) {
            const { data } = await api.getDocument(String(id));
            const class_id = await data?.data?.class?.id;

            const disable_agreement_ahd = [
              7798, 7799, 7802, 7803, 7804, 7806, 7807, 7797,
            ];
            if (!disable_agreement_ahd.includes(class_id)) {
              getUserDocsByIsn(contractorInfo[0].ISN);
            }

            Object.assign(row_column_editor[name][fieldID], {
              char_value: contractorInfo[0]['FULLNAME'],
              full_name: contractorInfo[0]['FULLNAME'],
              num_value: contractorInfo[0]['ISN'],
              [fieldName]: contractorInfo[0]['FULLNAME'],
            });
          } else {
            Object.assign(row_column_editor[name][fieldID], {
              char_value: contractorInfo[0]['FULLNAME'],
              full_name: contractorInfo[0]['FULLNAME'],
              num_value: contractorInfo[0]['ISN'],
              [fieldName]: contractorInfo[0]['FULLNAME'],
            });
          }
          form.setFieldsValue({
            row_column_editor,
          });
        } else {
          form.setFieldsValue({
            full_name: contractorInfo[0]['FULL_NAME'],
            char_value: contractorInfo[0]['FULL_NAME'],
            num_value: contractorInfo[0]['CLIENTISN'],
            [fieldID]: contractorInfo[0]['FULL_NAME'],
          });
        }
        dispatch({ type: 'search', payload: false });
      })();
    }
  }, [contractorInfo]);
  useEffect(() => {
    const { row_column_editor } = form.getFieldsValue();
    if (row_column_editor && name !== undefined && !contractorFullInfo) {
      Object.keys(row_column_editor[name]).filter(async (key) => {
        if (
          row_column_editor[name][key].type === 'SUBJECT_CONTRACTOR' &&
          row_column_editor[name][key].num_value &&
          key === fieldID
        ) {
          const { data } = await api.getContractorByISN(
            row_column_editor[name][key].num_value
          );
          dispatch({
            type: 'selectContractor',
            payload: { data: data.data, isJurUser: data.data.juridical == 'Y' },
          });
        }
      });
    }
  }, [form.getFieldsValue(), name]);
  return (
    <>
      <Row gutter={15} align="bottom">
        <Col span="24">
          <Form.Item
            label={title}
            rules={[{ required }]}
            initialValue={initialValue?.val?.id}
            name={
              isRowColumnEditor
                ? [name, fieldID, fieldName]
                : [fieldID, fieldName]
            }
          >
            <Input readOnly hidden />
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                if (isRowColumnEditor) {
                  return (
                    prevValues.row_column_editor[name][fieldID][fieldName] !==
                    curValues.row_column_editor[name][fieldID][fieldName]
                  );
                } else {
                  return (
                    prevValues[fieldID][fieldName] !==
                    curValues[fieldID][fieldName]
                  );
                }
              }}
            >
              {({ getFieldValue }) => {
                const fieldObject = isRowColumnEditor
                  ? getFieldValue(['row_column_editor', name, fieldID])
                  : getFieldValue(fieldID);
                return (
                  <CustomLoader spinning={isLoading}>
                    <Input.Group compact>
                      <Form.Item
                        style={{
                          width:
                            fieldObject?.[fieldName] !== null &&
                            fieldObject?.[fieldName] !== undefined
                              ? `calc(100% - 92px)`
                              : `calc(100% - 46px)`,
                          marginBottom: 0,
                        }}
                      >
                        <Input
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          value={
                            fieldObject?.char_value ||
                            initialValue?.val?.char_value
                          }
                          onChange={handleSearchUser}
                          // readOnly
                        />
                      </Form.Item>

                      {fieldObject?.[fieldName] !== null &&
                      contractorFullInfo &&
                      fieldObject?.[fieldName] !== undefined ? (
                        <>
                          <Button
                            type="primary"
                            icon={<EditOutlined />}
                            style={{ width: '46px' }}
                            onClick={() =>
                              selectContractor(contractorFullInfo, null)
                            }
                            htmlType="button"
                            disabled={isLoading || isDisabledForm}
                          />
                          <Button
                            type="primary"
                            icon={<DeleteFilled />}
                            style={{ width: '46px' }}
                            onClick={handleDeleteUser}
                            danger
                            htmlType="button"
                            disabled={isLoading || isDisabledForm}
                          />
                        </>
                      ) : (
                        <Button
                          type="primary"
                          icon={<SearchOutlined />}
                          style={{ width: '46px' }}
                          onClick={AdvancedContractorSearch}
                          htmlType="button"
                          disabled={isLoading || isDisabledForm}
                        />
                      )}
                    </Input.Group>
                  </CustomLoader>
                );
              }}
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="ФИО"
        name={
          isRowColumnEditor
            ? [name, fieldID, 'full_name']
            : [fieldID, 'full_name']
        }
        hidden
      >
        <Input disabled />
      </Form.Item>
      {step === 1 && (
        <SearchClientModal
          isLoading={isLoading}
          from=""
          isVisible={isVisibleConstactorModal}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          getContractorList={getContractorList}
          contractorFullInfo={null}
        />
      )}
      {step === 2 && (
        <ResultClientModal
          contractorList={contractorList}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          selectContractor={selectContractor}
          isLoading={isLoading}
          createContractor={createContractor}
          from={null}
        />
      )}
      {step === 3 && (
        <AddClientModal
          isLoading={isLoading}
          searchContractor={searchContractor}
          handleCancel={handleCancelAddClientModal}
          contractorFullInfo={contractorFullInfo}
          isJurUser={isJurUser}
          selectContractor={updateContractorInfo}
          changeState={changeState}
          selectRelatedContractor={null}
          showRelatedInfo={updateContractorInfo}
          relatedInfo={null}
          closeRelatedInfo={handleCancelAddClientModal}
        />
      )}
      {isRelatedOpen && (
        <AddClientModal
          searchContractor={searchContractor}
          isLoading={isLoading}
          isJurUser={isJurUser}
          contractorFullInfo={null}
          handleCancel={handleCancelAddClientModal}
          selectContractor={selectContractor}
          changeState={changeState}
          selectRelatedContractor={selectRelatedContractor}
          showRelatedInfo={showRelatedInfo}
          relatedInfo={relatedInfo}
          closeRelatedInfo={closeRelatedInfo}
        />
      )}
    </>
  );
}
export default FieldContractorCard;
