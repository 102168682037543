import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusOutlined,
  UploadOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import {
  Table,
  Button,
  Form,
  Col,
  Row,
  Select,
  Input,
  DatePicker,
  Upload,
  message,
  Tabs,
  Space,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { DatePickerProps, UploadProps, TabsProps } from 'antd';
import EditContractorDocFiles from '../EditContractorDocFiles';
import EditContractorDocInfo from '../EditContractorDocInfo';
type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

function ClientRequisitesTable({ bank, settings }) {
  const [editingKey, setEditingKey] = useState<any>();
  const columns = [
    {
      // title: 'Тип документа',
      dataIndex: 'bin',
      key: 'bin',
      width: '16%',
      editable: true,

      // ellipsis: true,
    },
    {
      // title: 'Номер документа',
      dataIndex: 'payacc',
      key: 'payacc',
      ellipsis: true,
      width: '20%',
      editable: true,
    },
    {
      // title: 'Дата выдачи',
      dataIndex: 'currisn_name',
      key: 'currisn_name',
      ellipsis: true,
      width: '7%',
      editable: true,
      render: (_, record) => record.currisn_name == 'Тенге' && 'KZT',
    },
    {
      // title: 'Кем выдан',
      dataIndex: 'bankname',
      key: 'bankname',
      ellipsis: true,
      width: '14%',
      editable: true,
    },
    {
      // title: 'Файлы',
      dataIndex: 'bik',
      key: 'bik',
      ellipsis: true,
      width: '16%',
      editable: true,
    },
    // {
    //   key: 'action',
    //   ellipsis: true,
    //   width: '10%',
    //   render: (_, record) => (
    //     <Button type="primary" danger>
    //       <DeleteOutlined />
    //     </Button>
    //   ),
    // },
  ];
  const operations = (
    <Space>
      <Button size="small" onClick={() => setEditingKey(null)}>
        <CloseOutlined />
      </Button>
      <Button type="primary" size="small">
        <CheckOutlined />
      </Button>
    </Space>
  );
  return (
    <>
      <Form
        name="dynamic_form_item"
        // {...formItemLayoutWithOutLabel}
        style={{ maxWidth: 1000 }}
      >
        <Form.List name="names">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row
                  gutter={6}
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                    justifyContent: 'space-between',
                  }}
                >
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      rules={[
                        { required: true, message: 'Missing first name' },
                      ]}
                    >
                      <Input disabled placeholder="Номер П/П" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      rules={[
                        { required: true, message: 'Missing first name' },
                      ]}
                    >
                      <Input disabled placeholder="Cчет" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      rules={[
                        { required: true, message: 'Missing first name' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Валюта"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          {
                            value: 'jack',
                            label: 'Jack',
                          },
                          {
                            value: 'lucy',
                            label: 'Lucy',
                          },
                          {
                            value: 'tom',
                            label: 'Tom',
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, 'last']}
                      rules={[{ required: true, message: 'Missing last name' }]}
                    >
                      <Input disabled placeholder="Банк" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, 'last']}
                      rules={[{ required: true, message: 'Missing last name' }]}
                    >
                      <Input disabled placeholder="БИК" />
                    </Form.Item>
                  </Col>
                  <Button>
                    <CloseOutlined onClick={() => remove(name)} />
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ backgroundColor: '#39c33e' }}
                  >
                    <CheckOutlined />
                  </Button>
                </Row>
              ))}
            </>
          )}
        </Form.List>
      </Form>
      <Table
        showHeader={false}
        className="mt-5"
        columns={columns}
        dataSource={bank}
      />
    </>
  );
}
export default ClientRequisitesTable;
