import React from 'react';
import { Button } from 'antd';
import './CButton.scss';

const CButton = (props) => {
  return (
    <Button {...props} className={`c-button ${props.className || ''}`}>
      {props.children}
    </Button>
  );
};

export default CButton;
