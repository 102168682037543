import {Button, Card, Col, List, message, notification, Result, Row, Space} from "antd";
import Logo from "@modules/common/Logo";
import React, {useEffect, useState} from "react";
import Title from "antd/es/typography/Title";
import AdminPanelModal from "@modules/FileExchange/AdminPanel/AdminPanelModal/AdminPanelModal";
import api from "@modules/FileExchange/services/api";
import apiColleague from '../../../services/api';
import axios from "axios";


interface IUser {
    first_name: string;
    last_name: string;
    email: string;
    external_id: number;
    id: number;
    role: string;
}

const AdminPanel = () => {
    const [isOpenAdminModal, setIsOpenAdminModal] = useState<boolean>(false);
    const [users, setUsers] = useState<IUser[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);
    const [access, setAccess] = useState(true);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getUsers = async (numberPage) => {
        try {
            const response = await api.getUsers(numberPage);

            setUsers(response.data.data);
            setTotalUsers(response.data.meta.total);
            setLoading(true);

            if(response.data.data.length === 0) {
                setCurrentPage(currentPage - 1);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                switch (error.response.status) {
                    case 403:
                        setAccess(!(error.response.status === 403));
                        break;
                    default:
                        message.error(`Не удалось получить пользователей`);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const deleteUser = async (id) => {
        try {
            await api.deleteUser(id);
            getUsers(currentPage);
            message.success("Вы успешно удалили пользователя");
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                switch (error.response.status) {
                    case 403:
                        notification.error({
                            message: 'Ошибка',
                            description: 'У вас нет доступа к удалению пользователя',
                        });
                        break;
                    default:
                        message.error(`Не удалось удалить пользователя`);
                }
            }
        }
    };

    useEffect(() => {
        getUsers(currentPage)
    }, [currentPage]);

    return access ? (
        <div className="content-file-exchange">
            <div className="container-file-exchange">
                <AdminPanelModal
                    isModalOpen={isOpenAdminModal}
                    onCancel={() => {
                        setIsOpenAdminModal(false);
                        setCurrentUser(null);
                    }}
                    getUsers={() => getUsers(currentPage)}
                    currentUser={currentUser}
                />
                <Row className="header-file-exchange">
                    <Logo/>
                </Row>
                <Row className="d-flex align-start justify-space-between">
                    <Col span={24}>
                        <div className="sector-file-exchange">
                            <Row className="d-flex justify-space-between">
                                <Title level={4} className="path-category-file-exchange">
                                    Админ панель
                                </Title>
                                <Row>
                                    <Space size={10}>
                                        <Button
                                            className="add-folder-file-exchange"
                                            size="large"
                                            href="/file-exchange"
                                        >
                                            Вернуться в файлообменик
                                        </Button>
                                        <Button
                                            className="add-folder-file-exchange"
                                            size="large"
                                            href="/file-exchange/audit"
                                        >
                                            Аудит
                                        </Button>
                                        <Button
                                            className="add-folder-file-exchange"
                                            size="large"
                                            onClick={() => setIsOpenAdminModal(true)}
                                        >
                                            Создать пользователя
                                        </Button>
                                    </Space>
                                </Row>
                            </Row>
                            <Card className="w-100 mt-5">
                                <List
                                    loading={loading}
                                    className="w-100"
                                    pagination={{
                                        current: currentPage,
                                        pageSize: 10,
                                        total: totalUsers,
                                        onChange: handlePageChange,
                                        showSizeChanger: false,
                                        position: 'bottom',
                                    }}
                                    dataSource={users}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            key={index}
                                            actions={[
                                                <Button
                                                    key="list-loadmore-edit"
                                                    type="primary"
                                                    onClick={() => {
                                                        setCurrentUser(item);
                                                        setIsOpenAdminModal(true);
                                                    }}
                                                >
                                                    Редактировать
                                                </Button>,
                                                <Button
                                                    type="primary"
                                                    danger
                                                    key="list-loadmore-delete"
                                                    onClick={() => deleteUser(item.id)}
                                                >
                                                    Удалить
                                                </Button>
                                            ]}
                                        >
                                            <List.Item.Meta
                                                title={`${item.first_name} ${item.last_name}`}
                                            />
                                            <List.Item.Meta
                                                description={`Почта - ${item.email}`}
                                            />
                                            <List.Item.Meta
                                                description={`Роль - ${item.role}`}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    ) : <Result
        status="warning"
        title="У Вас нет прав для доступа к этой странице"
        extra={
            <Button
                className="add-folder-file-exchange"
                size="large"
                href="/file-exchange"
            >
                Вернуться в файлообменик
            </Button>
        }
    />
};

export default AdminPanel;