import React from 'react';
import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { IResult } from './index';

const columns: ColumnsType<IResult> = [
  {
    title: '№',
    dataIndex: '№',
    key: '№',
    render: (data, record, index) => ++index,
    width: "10%"
  },
  {
    title: 'ФИО Кандидата',
    dataIndex: 'candidate_fullname',
    key: 'candidate_fullname',
    width: '15%'
  },
  {
    title: 'Структурное подразделение/ФИО ответственного',
    dataIndex: 'department_to',
    key: 'department_to',
    width: '20%'
  },
  // TODO
  // УЗНАТЬ dataIndex для вакансии
  {
    title: 'Вакансия',
    key: 'name_of_post_id', 
    dataIndex: 'name_of_post_id',
    width: '20%'
  },
  {
    title: 'Статус',
    key: 'candidate_status',
    dataIndex: 'candidate_status',
    width: '10%'
  },
  {
    title: 'Ответственный рекрутер',
    key: 'responsible_recruiter_id',
    dataIndex: 'responsible_recruiter_id',
    width: '15%'
  },
  {
    title: 'Дата выхода кандидата по ДОУ',
    key: 'date_of_conclusion_dou',
    dataIndex: 'date_of_conclusion_dou',
    width: '10%'
  },
];

function ResultTable({resultData}) {
  return <Table style={{ fontWeight: 'initial' }} columns={columns} dataSource={resultData} scroll={{ x: 1000 }} rowKey="id"/>;
}

export default ResultTable;
