import { Col, Form, Select } from 'antd';
import React from 'react';
import useRecruiting from '../hooks/useRecruiting';

const { Option } = Select;

function LanguagesFields() {
  const { state } = useRecruiting();

  const { selectValues } = state;

  return (
    <>
      <Col span={8} xs={24} xl={8}>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="lang_kaz"
          label="Казахский язык (уровень владения)"
        >
          <Select>
            {selectValues.languages.select.length &&
              selectValues.languages.select.map((element) => (
                <Option value={element.id} key={element.id}>
                  {element.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8} xs={24} xl={8}>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="lang_ru"
          label="Русский язык (уровень владения)"
        >
          <Select>
            {selectValues.languages.select.length &&
              selectValues.languages.select.map((element) => (
                <Option value={element.id} key={element.id}>
                  {element.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8} xs={24} xl={8}>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="lang_en"
          label="Английский язык (уровень владения)"
        >
          <Select>
            {selectValues.languages.select.length &&
              selectValues.languages.select.map((element) => (
                <Option value={element.id} key={element.id}>
                  {element.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}

export default LanguagesFields;
