import React from 'react';
import './JournalQuotation.scss';
import { Form, Row, Col, Select, Divider, Typography } from 'antd';

import useAuth from '../../../hooks/useAuth';
import TreeSelectKias from '@modules/TreeSelect/TreeSelectKias';
import dayjs from 'dayjs';
import useQuotation from '../hooks/useQuotation';
import useStatuses from '../hooks/CommonMethods/MainComponents/useStatuses';
import CInput from '@components/UI/input/CInput';
import CSelect from '@components/UI/select/CSelect';
import CButton from '@components/UI/button/CButton';
import CDatePicker from '@components/UI/datepicker/CDatePicker';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Title } = Typography;

function JournalQuotation({ setFilerProduct, setDataAfterSubmitAndClear }) {
  const auth = useAuth();

  const { colleaguesKiasList, isLoadingColleaguesKiasList, productList } =
    useQuotation();

  const [journalQuotationForm] = Form.useForm();

  const { statusList } = useStatuses();

  function openCreatedQuotation(submitedFormObject) {
    undefinedValuesChangeToNullableAndSetData(submitedFormObject);
    setFilerProduct(true);
  }

  function undefinedValuesChangeToNullableAndSetData(submitedFormObject) {
    let copySubmitedFormObjectAndClearReturnString = JSON.stringify(
      submitedFormObject,
      function (key, value) {
        if (key === 'date_beg' && value !== undefined) {
          value = dayjs(value).format('DD.MM.YYYY');
        } else if (key === 'date_end' && value !== undefined) {
          value = dayjs(value).format('DD.MM.YYYY');
        }

        return typeof value === 'undefined' ? null : value;
      }
    );
    const newCopyOfSubmitedObject = JSON.parse(
      copySubmitedFormObjectAndClearReturnString
    );

    setDataAfterSubmitAndClear(newCopyOfSubmitedObject);
  }

  return (
    <Form
      form={journalQuotationForm}
      onFinish={openCreatedQuotation}
      name="basic"
      layout="vertical"
      className="c-form"
    >
      <Row gutter={24}>
        <Col span={8} xs={24} xl={24}>
          <Title style={{ color: '#7449EF' }} level={3}>
            Поиск по фильтрам
          </Title>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8} xs={24} xl={8}>
          <Form.Item label="Номер котировки" name="quotation_id">
            <CInput placeholder="Введите номер котировки" size="large" />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            name="agreementDate_typeQuotation"
            label="Вид котировки"
            initialValue={220603}
          >
            <CSelect size="large">
              <Option value={220603}>Первичная</Option>
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item name="date_beg" label="Дата начала котировки">
            <CDatePicker
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item name="date_end" label="Дата окончания котировки">
            <CDatePicker
              format={'DD.MM.YYYY'}
              style={{ width: '100%' }}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item label="Страховой продукт" name="product_id">
            <CSelect
              placeholder="Выберите продукт"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="large"
              allowClear
            >
              {productList?.map((product) => (
                <Option key={product.foreign_id} value={product.foreign_id}>
                  {product.full_name}
                </Option>
              ))}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item name="status_id" label="Статус" initialValue={223368}>
            <CSelect placeholder="Статус не выбран" size="large" allowClear>
              {statusList.length &&
                statusList?.map((element) => {
                  return (
                    <Option value={element.foreign_id} key={element.foreign_id}>
                      {element.full_name}
                    </Option>
                  );
                })}
            </CSelect>
          </Form.Item>
        </Col>
        <Col xs={24} xl={16}>
          <Form.Item
            name="user_id"
            label={`Куратор`}
            rules={[
              {
                required: true,
                message: 'Куратор не выбран!',
              },
            ]}
            initialValue={auth.user?.data?.user_info?.foreign_id}
          >
            <TreeSelectKias
              colleaguesKiasList={colleaguesKiasList}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Form.Item>
            <CButton
              type="primary"
              htmlType="submit"
              block
              className="c-button_between"
              icon={<SearchOutlined />}
            >
              Поиск
            </CButton>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
    </Form>
  );
}

export default JournalQuotation;
