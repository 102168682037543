import dayjs from "dayjs";

export type Action =
  | { type: TypeAction.AGR_ISN; payload: number }
  | { type: TypeAction.DATE_SIGN; payload: string }
  | { type: TypeAction.DATE_BEG; payload: string }
  | { type: TypeAction.DATE_END; payload: string }
  | { type: TypeAction.CURRENCY; payload: number }
  | { type: TypeAction.EMPL_ISN; payload: number }
  | { type: TypeAction.USER_ISN; payload: number }
  | { type: TypeAction.CLIENT_ID; payload: number }
  | { type: TypeAction.STATUS_ISN; payload: number }
  | { type: TypeAction.REMARK; payload: string }
  | { type: TypeAction.STATUS_ISN_KIAS; payload: number}
  | { type: TypeAction.AGR_STATUS; payload: string}

export const enum TypeAction {
  AGR_ISN = 'AGR_ISN',
  DATE_SIGN = 'DATE_SIGN',
  DATE_BEG = 'DATE_BEG',
  DATE_END = 'DATE_END',
  CURRENCY = 'CURRENCY',
  EMPL_ISN = 'EMPL_ISN',
  USER_ISN = 'USER_ISN',
  CLIENT_ID = 'CLIENT_ID',
  STATUS_ISN = 'STATUS_ISN',
  REMARK = 'REMARK',
  STATUS_ISN_KIAS = 'STATUS_ISN_KIAS',
  AGR_STATUS = 'AGR_STATUS'
}

export interface IInitialState {
  agr_isn: number;
  date_sign: string;
  date_beg: string;
  date_end: string;
  currency: number;
  user_isn: number;
  client_id: number;
  status_isn: number;
  remark: string | null;
  status_isn_kias: number;
  connection: string;
  agr_status: string;
}


export const initialState: IInitialState = {
  agr_isn: 0,
  date_beg: dayjs().format('YYYY-MM-DD'),
  date_sign: dayjs().format('YYYY-MM-DD'),
  date_end: dayjs().add(1, 'y').subtract(1, 'd').format('YYYY-MM-DD'),
  currency: 9813,
  user_isn: 0,
  client_id: 0,
  status_isn: 223368,
  remark: null,
  status_isn_kias: 0,
  connection: 'centras',
  agr_status: ''
};

export const reducer = (draft: IInitialState, action: Action) => {
  const { payload, type } = action;
  switch (type) {
    case TypeAction.AGR_ISN:
      draft.agr_isn = payload;
      break;
    case TypeAction.DATE_SIGN:
      draft.date_sign = payload;
      break;
    case TypeAction.DATE_BEG:
      draft.date_beg = payload;
      break;
    case TypeAction.DATE_END:
      draft.date_end = payload;
      break;
    case TypeAction.CURRENCY:
      draft.currency = payload;
      break;
    case TypeAction.USER_ISN:
      draft.user_isn = payload;
      break;
    case TypeAction.CLIENT_ID:
      draft.client_id = payload;
      break;
    case TypeAction.STATUS_ISN:
      draft.status_isn = payload;
      break;
    case TypeAction.REMARK:
      draft.remark = payload;
      break;
    case TypeAction.STATUS_ISN_KIAS:
      draft.status_isn_kias = payload;
      break;
    case TypeAction.AGR_STATUS:
      draft.agr_status = payload;
      break;
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
};
