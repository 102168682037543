import React, { useEffect, useRef } from 'react';
import { Input, InputNumber } from 'antd';
import './CInput.scss';
import inputmask from 'inputmask';

const CInput = (props) => {
  useEffect(() => {
    inputmask({
      alias: 'numeric',
      groupSeparator: ' ',
      autoGroup: true,
      digits: 0,
      rightAlign: false,
      placeholder: ' ',
      showMaskOnHover: false,
      showMaskOnFocus: false,
      autoUnmask: true,
    }).mask('.c-input.numericMask');

    // Номера телефонов
    inputmask({
      mask: '+7(999)999-99-99',
      placeholder: ' ',
      showMaskOnHover: false,
      showMaskOnFocus: true,
      onBeforeMask: (value, opts) => {
        return value.replace(/^8[\s(]*7/, '+77');
      },
    }).mask('.c-input.phoneMask');

    // Только цифры
    inputmask({
      mask: '9{*}',
      placeholder: ' ',
      showMaskOnHover: false,
      showMaskOnFocus: false,
    }).mask('.c-input.digitsMask');
  }, []);
  return (
    <Input
      {...props}
      // filter(Boolean) - не добавляет пробелы если элемент массива пустой
      className={`${['c-input', props.className, props.theme]
        .filter(Boolean)
        .join(' ')}`}
    />
  );
};

export default CInput;
