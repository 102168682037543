import { Col, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import React from 'react';
import { fieldNamesRecruiting } from '../Common/fieldNames';
import useRecruiting from '../hooks/useRecruiting';

const { Option } = Select;

interface IInterviewFields {
  disabled?: boolean;
}

function InterviewFields({ disabled }: IInterviewFields) {

  const { state } = useRecruiting()

  const { selectValues } = state

  return (
    <>
      <Col xs={24} lg={8}>
        <Form.Item
          label="Количество единиц"
          name={fieldNamesRecruiting.quantity_people}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          label="Дата собеседования (в течение 2 дней после получения резюме)"
          name={fieldNamesRecruiting.interview_date}
        >
          <DatePicker
            style={{ width: '100%' }}
            name="date"
            onChange={(e, date) => {}}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          label="Время собеседования"
          trigger="onSelect"
          name={fieldNamesRecruiting.interview_time}
        >
          <TimePicker
            format="HH:mm"
            style={{ width: '100%' }}
            minuteStep={5}
            clearIcon={false}
            disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23]}
            hideDisabledOptions
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={8}>
        <Form.Item
          label="Результат интервью кандидата с руководителем(ответ в течение дня)"
          name={fieldNamesRecruiting.interview_result}
        >
          <Select>
            {selectValues.interview_result.map((element) => (
              <Option value={element.id} key={element.id}>{element.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}

export default InterviewFields;
