import { Button, Popconfirm } from 'antd';

interface Props {
  handleDocumentRemove: () => void;
  disabled?: boolean;
}

function CancelLS({ handleDocumentRemove, disabled }: Props) {
  return (
    <Popconfirm
      title="Подтвердить аннулирование"
      onConfirm={handleDocumentRemove}
      okText="Да"
      cancelText="Нет"
    >
      <Button
        type="primary"
        danger
        htmlType="button"
        disabled={disabled ? disabled : false}
      >
        Аннулировать СЗ
      </Button>
    </Popconfirm>
  );
}

export default CancelLS;
