import { Col, Row, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import useQuotationJournal from '../hooks/useQuotationJournal';
import ApplicationMessage from './ApplicationMessage';
import PassingApplicationTable from './PassingApplicationTable';
import { setFieldsMethod } from './setFieldsMethod';

export const enum fieldNamesServiceCenter {
  id = 'id',
  created = 'created',
  req_statusisn_name = 'req_statusisn_name',
  emplisn_name = 'emplisn_name',
  operatorremark = 'operatorremark',
  reqtext = 'reqtext',
  responsetext = 'responsetext',
}

const { TextArea } = Input;

function ServiceCenterFields() {
  const [textApplication, setTextApplication] = useState<string>('');

  const form = Form.useFormInstance();

  const { state } = useQuotationJournal();

  useEffect(() => {
    if (state.passingApplicationData.length) {
      const {
        id,
        created,
        req_statusisn_name,
        emplisn_name,
        reqtext,
        responsetext,
      } = state.passingApplicationData[0];
      setFieldsMethod(form, fieldNamesServiceCenter.id, id);
      setFieldsMethod(form, fieldNamesServiceCenter.created, created);
      setFieldsMethod(
        form,
        fieldNamesServiceCenter.req_statusisn_name,
        req_statusisn_name
      );
      setFieldsMethod(form, fieldNamesServiceCenter.emplisn_name, emplisn_name);
      setFieldsMethod(form, fieldNamesServiceCenter.reqtext, reqtext);
      setFieldsMethod(form, fieldNamesServiceCenter.responsetext, responsetext);
    }
  }, [state.passingApplicationData]);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} xl={8}>
          <Form.Item label="Номер" name={fieldNamesServiceCenter.id}>
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} xl={8}>
          <Form.Item label="Дата" name={fieldNamesServiceCenter.created}>
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} xl={8}>
          <Form.Item
            label="Статус"
            name={fieldNamesServiceCenter.req_statusisn_name}
          >
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} xl={8}>
          <Form.Item label="Автор" name={fieldNamesServiceCenter.emplisn_name}>
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} xl={8}>
          <Form.Item
            label="Текст заявки"
            name={fieldNamesServiceCenter.reqtext}
          >
            <TextArea autoSize={{ minRows: 3 }} readOnly />
          </Form.Item>
        </Col>

        <Col xs={24} xl={8}>
          <Form.Item
            label="Ответ заявки"
            name={fieldNamesServiceCenter.responsetext}
          >
            <TextArea autoSize={{ minRows: 3 }} readOnly />
          </Form.Item>
        </Col>
      </Row>

      <PassingApplicationTable setTextApplication={setTextApplication} />

      <ApplicationMessage textApplication={textApplication} />
    </>
  );
}

export default ServiceCenterFields;
