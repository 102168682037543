import CInput from '@components/UI/input/CInput';
import CRadio from '@components/UI/radio/CRadio';
import CSelect from '@components/UI/select/CSelect';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Input,
  notification,
} from 'antd';
import api from '@modules/Quotation/services';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useQuotation from '../../hooks/useQuotation';
import CButton from '@components/UI/button/CButton';
import { DeleteFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { useImmerReducer } from 'use-immer';
import SearchClientModal from '@modules/common/Client/SearchClientModal';
import contractorFunctions from '@modules/common/Client/Functions';
import ResultClientModal from '@modules/common/Client/ResultClientModal';
import axios from 'axios';
import AddClientModal from '@modules/common/Client/AddClientModal';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
const { Search } = Input;

const { Option } = Select;
type Action = {
  type:
    | 'nextStep'
    | 'backStep'
    | 'showSearchContractorModal'
    | 'hideContractorModal'
    | 'getContractorInfo'
    | 'searchContractor'
    | 'saveContractorList'
    | 'selectContractor'
    | 'search'
    | 'loading'
    | 'removeContractInfo';
  payload?: any;
};

interface contractorFullInfo {
  isn: number;
  fullname: string;
}
interface State {
  step: number;
  isVisibleConstactorModal: boolean;
  contractorInfo: any[] | null;
  contractorList: [] | null;
  contractorFullInfo: contractorFullInfo | null;
  isLoading: boolean;
  isJurUser: boolean;
  from: any;
}
const initialState: State = {
  step: 0,
  isVisibleConstactorModal: false,
  contractorInfo: null,
  contractorList: null,
  contractorFullInfo: null,
  isLoading: false,
  isJurUser: true,
  from: null,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'showSearchContractorModal': {
      draft.step = 1;
      draft.isVisibleConstactorModal = true;
      break;
    }
    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
    case 'searchContractor': {
      draft.step = 2;
      break;
    }
    case 'saveContractorList': {
      draft.isJurUser = action.payload.isJurUser == 'Y';
      draft.contractorList = action.payload.data;
      break;
    }
    case 'hideContractorModal': {
      draft.step = 0;
      break;
    }
    case 'selectContractor': {
      draft.isJurUser = action.payload.juridical == 'Y';
      draft.contractorFullInfo = action.payload.data;
      if (action.payload.data) {
        draft.contractorInfo = [
          {
            FULLNAME: action.payload.data.fullname,
            IIN: action.payload.data.iin,
            BIRTHDAY: action.payload.data.birthday,
            ISN: action.payload.data.isn,
          },
        ];
      }
      break;
    }
    case 'getContractorInfo': {
      draft.contractorInfo = action.payload;
      break;
    }
    case 'removeContractInfo': {
      draft.contractorInfo = null;
      draft.contractorFullInfo = action.payload;
      if (action.payload?.jur) {
        draft.isJurUser = action.payload.jur == 'Y';
      }
      break;
    }
    case 'nextStep': {
      if (action.payload.step) {
        draft.step = action.payload.step;
      } else {
        draft.step = action.payload;
      }
      if (action.payload.from) {
        draft.from = action.payload.from;
      }
      break;
    }
    case 'backStep': {
      draft.step = draft.step - 1;
      break;
    }
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const CardCarMain = ({
  selectedCar,
  dicties,
  agrIsn,
  saveObject,
  carForm,
  AdvancedContractorSearch,
}) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const {
    isVisibleConstactorModal,
    contractorInfo,
    step,
    contractorList,
    isLoading,
    contractorFullInfo,
    isJurUser,
    from,
  } = state;
  const { productSelectedQuotation, stateDateQuotation, agrCond } =
    useQuotation();
  useEffect(() => {
    (async () => {
      if (selectedCar) {
        dispatch({ type: 'loading', payload: true });
        carForm.setFieldsValue({
          obj_isn: selectedCar.isn,
          fid_obj: selectedCar.fid,
          auto_typeisn: +selectedCar.vehicle_type_isn
            ? +selectedCar.vehicle_type_isn
            : null,
          auto_markisn: selectedCar.vehicle_mark_isn,
          auto_mark: selectedCar.vehicle_mark,
          auto_modelisn: selectedCar.vehicle_model_isn,
          auto_model: selectedCar.vehicle_model,
          auto_categoryisn: +selectedCar.vehicle_category_isn
            ? +selectedCar.vehicle_category_isn
            : null,
          auto_regno: selectedCar.reg_no,
          auto_vin: selectedCar.vin,
          auto_engineid: selectedCar.engine_id,
          auto_year: selectedCar.vehicle_year?.split('.')[2],
          auto_volume: selectedCar.engine_volume,
          auto_power: selectedCar.engine_power,
          auto_probeg: selectedCar.vehicle_mileage,
          auto_colorisn: +selectedCar.vehicle_color_isn
            ? +selectedCar.vehicle_color_isn
            : null,
          auto_chassisid: selectedCar.chassis_number,
          auto_bodyid: selectedCar.body_number,
          auto_ptsno: selectedCar.doc_number,
          auto_ptsser: selectedCar.doc_series,
          num_seats: selectedCar.num_seats,
          auto_ptsdate: dayjs(selectedCar.doc_date, 'DD.MM.YYYY'),
          auto_modification: selectedCar.auto_modification,
          auto_caruseisn: +selectedCar.auto_caruseisn
            ? +selectedCar.auto_caruseisn
            : null,
          auto_ownershipisn: +selectedCar.auto_ownershipisn
            ? +selectedCar.auto_ownershipisn
            : null,
          auto_realprice: selectedCar.auto_realprice,
          auto_ptsclassisn: +selectedCar.auto_ptsclassisn
            ? +selectedCar.auto_ptsclassisn
            : null,
          right_hand_drive_bool: selectedCar.auto_rightwheel,
          auto_countryisn: selectedCar.country_isn
            ? +selectedCar.country_isn
            : null,
        });
      }
      if (selectedCar.owner_isn?.length > 0) {
        const { data } = await api.contractor.getContractorByISN(
          selectedCar.owner_isn
        );
        dispatch({
          type: 'selectContractor',
          payload: { data: data.data, juridical: data.data.juridical },
        });
        dispatch({ type: 'loading', payload: false });
      }
    })();
  }, [selectedCar]);
  useEffect(() => {
    if (agrCond && !carForm.getFieldsValue().limit_sum) {
      const data = agrCond.filter(
        (item) => item.obj_isn === selectedCar.isn
      )[0];

      if (data) {
        carForm.setFieldsValue({
          fid_cond: data.fid,
          cond_isn: data.isn,
          risk_isn: data.risk_isn,
          limit_sum: data.limit_sum,
          premium_sum: data.premium_sum,
          tariff: data.tariff,
          franch_sum: data.franch_sum,
          franch_tariff: data.franch_tariff,
          franch_type: data.franch_type,
          remark: data.remark,
        });
      }
    }
  }, [agrCond]);
  const onChangeOwner = (e) => {
    if (e.target.value.length === 12) {
      setTimeout(async () => {
        dispatch({ type: 'loading', payload: true });
        const { data } = await api.contractor.getContractorByIIN({
          iin: e.target.value,
        });
        if (data.data[0].ISN) {
          const info = await api.contractor.getContractorByISN(
            data.data[0].ISN
          );
          dispatch({
            type: 'selectContractor',
            payload: { data: info.data.data, juridical: data.data.juridical },
          });
        } else {
          const saveKias = await api.contractor.saveContractorKias({
            xml_isn: data.data[0].XMLISN,
            ext_system_key: data.data[0].EXTSYSTEMKEY,
          });
          const info = await api.contractor.getContractorByISN(
            saveKias.data.data[0].isn
          );
          dispatch({
            type: 'selectContractor',
            payload: { data: info.data.data, juridical: data.data.juridical },
          });
        }
        dispatch({ type: 'loading', payload: false });
      }, 300);
    }
  };
  const AdvancedOwnerSearch = () => {
    dispatch({ type: 'nextStep', payload: 1 });
  };
  useEffect(() => {
    if (stateDateQuotation) {
      carForm.setFieldsValue({
        date_sign: dayjs(stateDateQuotation.date_sign),
        date_beg: dayjs(stateDateQuotation.date_beg),
        date_end: dayjs(stateDateQuotation.date_end),
      });
    }
  }, [stateDateQuotation]);
  const sendData = () => {
    saveObject(carForm.getFieldsValue());
  };
  const selectContractor = async (value, fromInfo) => {
    try {
      dispatch({ type: 'search', payload: true });
      if (!value.ISN && !value.isn) {
        const info = await api.contractor.saveContractorKias({
          xml_isn: value.XMLISN,
          ext_system_key: value.EXTSYSTEMKEY,
        });
        const { data } = await api.contractor.getContractorByISN(info.data.isn);
        dispatch({
          type: 'selectContractor',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      } else {
        const isn = value.ISN ? value.ISN : value.isn;
        const { data } = await api.contractor.getContractorByISN(isn);
        dispatch({
          type: 'selectContractor',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      }
      dispatch({ type: 'nextStep', payload: 3 });
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      dispatch({ type: 'search', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const handleCancelAddClientModal = () => {
    dispatch({ type: 'hideContractorModal', payload: 0 });
    dispatch({ type: 'nextStep', payload: { from: null } });
  };
  const handleDeleteUser = () => {
    dispatch({ type: 'removeContractInfo', payload: null });
    carForm.setFieldsValue({
      ...carForm.getFieldsValue(),
      owner: null,
    });
  };
  useEffect(() => {
    if (contractorFullInfo) {
      carForm.setFieldsValue({
        ...carForm.getFieldsValue(),
        owner_isn: contractorFullInfo.isn,
      });
    }
  }, [contractorFullInfo]);

  const getContractorList = (values) => {
    contractorFunctions.getContractorList({ dispatch, values });
  };
  const changeState = (step, data: any | null, fromInfo) => {
    dispatch({ type: 'nextStep', payload: { step, from: fromInfo } });
    if (data !== null) {
      dispatch({ type: 'saveContractorList', payload: { data } });
    }
    if (fromInfo == 'related') {
      dispatch({
        type: 'removeContractInfo',
        payload: { ...contractorFullInfo, from: 'related', jur: 'Y' },
      });
    }
  };
  return (
    <Form
      className="c-form"
      layout="vertical"
      name="transportCard"
      form={carForm}
    >
      <Row gutter={6}>
        <Form.Item name="fid_obj" hidden noStyle>
          <CInput />
        </Form.Item>
        <Col span={8}>
          <Form.Item name="auto_typeisn" label="Тип транспортного средства">
            <CSelect
              placeholder="Тип транспортного средства"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {dicties.typeCar?.map((item) => (
                <Option value={item.foreign_id} key={item.foreign_id}>
                  {item.full_name}
                </Option>
              ))}
            </CSelect>
          </Form.Item>
        </Col>
        <Form.Item name="num_seats" hidden noStyle>
          <CInput placeholder="num_seats" />
        </Form.Item>
        <Form.Item name="auto_markisn" hidden noStyle>
          <CInput placeholder="model_isn" />
        </Form.Item>
        <Form.Item name="obj_isn" hidden noStyle>
          <CInput placeholder="obj_isn" />
        </Form.Item>
        <Col span={8}>
          <Form.Item name="auto_mark" label="Марка">
            <CInput placeholder="Марка" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_modelisn" hidden noStyle>
            <CInput placeholder="model_isn" />
          </Form.Item>
          <Form.Item name="auto_model" label="Модель">
            <CInput placeholder="Модель" disabled />
          </Form.Item>
        </Col>
        {contractorFullInfo && (
          <Form.Item name="owner_isn" hidden noStyle>
            <Input value={contractorFullInfo.isn} />
          </Form.Item>
        )}
        <Col span={8}>
          <Form.Item name="owner" label="Собственник">
            {contractorFullInfo ? (
              <Input.Group compact>
                <Form.Item
                  style={{
                    width: `calc(100% - 92px)`,
                    marginBottom: 0,
                  }}
                >
                  <Input
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    value={contractorFullInfo.fullname}
                    disabled={checkDisabledField(stateDateQuotation.status_isn)}
                    // onChange={handleSearchUser}
                    // readOnly
                  />
                </Form.Item>
                <>
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    style={{ width: '46px' }}
                    onClick={() => selectContractor(contractorFullInfo, null)}
                    htmlType="button"
                    disabled={checkDisabledField(stateDateQuotation.status_isn)}
                  />
                  <Button
                    type="primary"
                    icon={<DeleteFilled />}
                    style={{ width: '46px' }}
                    onClick={handleDeleteUser}
                    danger
                    htmlType="button"
                    disabled={checkDisabledField(stateDateQuotation.status_isn)}
                  />
                </>
              </Input.Group>
            ) : (
              <Search
                loading={isLoading}
                onChange={onChangeOwner}
                placeholder="ИИН"
                onSearch={AdvancedOwnerSearch}
                style={{ width: '100%' }}
                enterButton
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_modification" label="Модификация">
            <CInput
              placeholder="Модификация"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="auto_categoryisn"
            label="Категория транспортного средства"
          >
            <CSelect
              placeholder="Категория транспортного средства"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {dicties.categoryCar?.children?.map((item) => (
                <Option value={item.foreign_id} key={item.foreign_id}>
                  {item.full_name}
                </Option>
              ))}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="auto_regno" label="Гос. номер">
            <CInput
              placeholder="Гос. номер"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="auto_vin" label="VIN">
            <CInput
              placeholder="VIN"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="auto_engineid" label="Номер двигателя">
            <CInput
              placeholder="Номер двигателя"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="auto_year" label="Год выпуска">
            <CInput
              placeholder="Год выпуска"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_volume" label="Обьем двигателя">
            <CInput
              placeholder="Обьем двигателя"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_probeg" label="Пробег">
            <CInput
              placeholder="Пробег"
              className="numericMask"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_colorisn" label="Цвет">
            <CSelect
              placeholder="Цвет"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {dicties.colorCar?.children?.map((item) => (
                <Option value={item.foreign_id} key={item.foreign_id}>
                  {item.full_name}
                </Option>
              ))}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_power" label="Мощность">
            <CInput
              placeholder="Мощность"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_caruseisn" label="Цель использования">
            <CSelect
              placeholder="Цель использования"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {dicties.purpOfUseCar?.children.map((item) => (
                <Option value={item.foreign_id} key={item.foreign_id}>
                  {item.full_name}
                </Option>
              ))}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_ownershipisn" label="Право использования">
            <CSelect
              placeholder="Право использования"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {dicties.ownerOfCar?.children.map((item) => (
                <Option value={item.foreign_id} key={item.foreign_id}>
                  {item.full_name}
                </Option>
              ))}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_chassisid" label="Шасси">
            <CInput
              placeholder="Шасси"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_bodyid" label="Кузов">
            <CInput
              placeholder="Кузов"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="auto_realprice" label="Действительная стоимость">
            <CInput
              placeholder="Действительная стоимость"
              className="numericMask"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="auto_ptsclassisn"
            label="Документы на транспортное средство"
          >
            <CSelect
              placeholder="Документы на транспортное средство"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {dicties.docsCar?.children.map((item) => (
                <Option value={item.foreign_id} key={item.foreign_id}>
                  {item.full_name}
                </Option>
              ))}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="auto_ptsser" label="Серия">
            <CInput
              placeholder="Серия"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="auto_ptsno" label="Номер">
            <CInput
              placeholder="Номер"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="auto_ptsdate" label="Дата выдачи">
            <DatePicker
              placeholder="Дата выдачи"
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="auto_countryisn" label="Страна регистрации">
            <CSelect
              placeholder="Страна"
              showSearch
              filterOption={(input, option) =>
                (option?.children.toLowerCase() ?? '').includes(
                  input.toLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.children ?? '').toLowerCase())
              }
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {dicties.countryCar?.children?.map((item) => (
                <Option value={item.foreign_id} key={item.foreign_id}>
                  {item.full_name}
                </Option>
              ))}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="right_hand_drive_bool">
            <CRadio
              className="juridical"
              options={[
                { label: 'Левый руль', value: 'N' },
                { label: 'Правый руль', value: 'Y' },
              ]}
              optionType="button"
              style={{ width: '100%' }}
              buttonStyle="solid"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Divider />
        <Form.Item name="fid_cond" hidden noStyle>
          <CInput />
        </Form.Item>
        <Form.Item name="cond_isn" hidden noStyle>
          <CInput />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            name="risk_isn"
            label="Риски (пакет рисков)"
            style={{ width: '100%' }}
          >
            <CSelect
              placeholder="Риски (пакет рисков)"
              style={{ width: '100%' }}
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {productSelectedQuotation[0]?.risks?.map((item) => (
                <Option value={item.risk_isn} key={item.risk_isn}>
                  {item.risk_name}
                </Option>
              ))}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="date_sign" label="Дата подписания">
            <DatePicker
              placeholder="Дата начала"
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="date_beg" label="Дата начала">
            <DatePicker
              placeholder="Дата начала"
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="date_end" label="Дата окончания">
            <DatePicker
              placeholder="Дата начала"
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="limit_sum" label="Страховая сумма">
            <CInput
              placeholder="Страховая сумма"
              className="numericMask"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="tariff" label="Тариф">
            <CInput
              placeholder="Номер"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="premium_sum" label="Страховая премия">
            <CInput
              placeholder="Страховая премия"
              className="numericMask"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="franch_tariff" label="Франшиза %">
            <CInput
              placeholder="Номер"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="franch_sum" label="Франшиза сумма">
            <CInput
              placeholder="Франшиза сумма"
              className="numericMask"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="franch_type"
            label="Тип франшизы"
            style={{ width: '100%' }}
          >
            <CSelect
              placeholder="Тип франшизы"
              style={{ width: '100%' }}
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {productSelectedQuotation[0]?.franchise?.franchise_type.map(
                (item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                )
              )}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="remark" label="Примечание">
            <CInput
              placeholder="Номер"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Col span={24}>
        <Button type="primary" style={{ width: '100%' }} onClick={sendData}>
          Сохранить
        </Button>
      </Col>
      {step == 1 && (
        <SearchClientModal
          isLoading={isLoading}
          from={from}
          contractorFullInfo={contractorFullInfo}
          isVisible={isVisibleConstactorModal}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          getContractorList={getContractorList}
        />
      )}
      {step == 2 && (
        <ResultClientModal
          isLoading={isLoading}
          contractorList={contractorList}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          selectContractor={selectContractor}
          createContractor={() => {}}
          from={from}
        />
      )}
      {step == 3 && (
        <AddClientModal
          isLoading={isLoading}
          isJurUser={isJurUser}
          contractorFullInfo={contractorFullInfo}
          handleCancel={handleCancelAddClientModal}
          changeState={changeState}
        />
      )}
    </Form>
  );
};
export default CardCarMain;
