import { Button, Col, Form, Input, message, Modal, notification, Row } from 'antd';
import useAuth from '@hooks/useAuth';
import Title from 'antd/es/typography/Title';
import React from 'react';
import './AddModalFolder.scss';
import api from '@modules/FileExchange/services/api';
import axios from 'axios';

interface IPropsAddModalFolder {
  isModalOpen: boolean;
  onCancel: () => void;
  getCategories: () => void;
}
const AddModalFolder = ({ isModalOpen, onCancel, getCategories }: IPropsAddModalFolder) => {
  const { user } = useAuth();
  const [addForm] = Form.useForm();

  const onSubmit = async (event: Event) => {
    const requestData = {
      name: event['folder-name'],
    };

    try {
      await api.createFolder(user?.data?.id, requestData);

      getCategories();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const checkError = error.response.data.message.indexOf('Directory') !== -1;

        if(checkError) {
          message.error('Не получилось добавить папку возможно папка с таким наименованием существует, попробуйте снова');
        }

        if (error.response.status === 400 && !checkError) {
          notification.error({
            message: 'Ошибка',
            description: 'У вас нет доступа к данному разделу',
          });
        }
      }
    } finally {
      addForm.setFieldValue('folder-name', '');
      onCancel();
    }
  };

  return (
    <Modal
      title={<Title level={4}>Создать папку</Title>}
      open={isModalOpen}
      footer={null}
      onCancel={onCancel}
      className="add-modal-file-exchange"
      centered
    >
      <Form form={addForm} onFinish={onSubmit} layout="vertical">
        <Row>
          <Col span={18}>
            <Form.Item label={<Title level={5}>Название папки</Title>} name="folder-name">
              <Input placeholder="Введите название папки" size="large"/>
            </Form.Item>
          </Col>
          <Col span={6} className="d-flex align-end justify-end">
            <Form.Item>
              <Button
                htmlType="submit"
                block
                type="primary"
                size="large"
                className="create-button-file-exchange"
              >
                Создать +
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export default AddModalFolder;