import { IGetSpeakers } from '../api';

export interface IState {
  isOpenModal: boolean;
  isLoading: boolean;
  speakers: IGetSpeakers[];
  speaker: IGetSpeakers | Record<string, never>;
  typeModal: boolean;
}

export type Action =
  | { type: 'isOpenModal'; payload: boolean }
  | { type: 'isLoading'; payload: boolean }
  | { type: 'speakers'; payload: IGetSpeakers[] }
  | { type: 'reset'; payload: IState }
  | { type: 'speaker'; payload: IGetSpeakers | Record<string, never> }
  | { type: 'typeModal'; payload: boolean };

export const initialState = {
  isOpenModal: false,
  isLoading: false,
  speakers: [],
  speaker: {} as IGetSpeakers,
  typeModal: false
};

export const reducer = (draft: IState, action: Action) => {
  switch (action.type) {
    case 'isOpenModal':
      draft.isOpenModal = action.payload;
      break;
    case 'isLoading':
      draft.isLoading = action.payload;
      break;
    case 'speakers':
      draft.speakers = action.payload;
      break;
    case 'reset':
      draft = initialState;
      break;
    case 'speaker':
      draft.speaker = action.payload;
      break;
    case 'typeModal':
      draft.typeModal = action.payload
      break;
    default: {
      throw new Error(`Unknown action: ${action}`);
    }
  }
};
