import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  Upload,
  notification,
} from 'antd';
import CUpload from '@components/UI/upload/CUpload';
import CFiles from '@components/UI/files/CFiles';
import { useState } from 'react';

function EditContractorDocFiles({ editingKey, props, files, deleteFile }) {
  const base64toFile = (b64Data, filename, contentType, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new File(byteArrays, `"${filename}"`, { type: contentType });
  };
  const downloadFile = async (fileData) => {
    try {
      const data = fileData.file_base64.split('base64');
      const file = base64toFile(data[1], fileData.filename, data[0]);
      const downloadLink = document.createElement('a');
      //     // Устанавливаем атрибут href для ссылки на Blob URL
      downloadLink.href = URL.createObjectURL(file);
      //     // Устанавливаем имя файла для загрузки (необязательно)
      downloadLink.download = fileData.filename;
      //     // Симулируем клик по ссылке для начала скачивания
      downloadLink.click();
      //     // Очищаем Blob URL после завершения скачивания
      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: 'При скачивании файла произошла ошибка. Попробуйте снова',
      });
    }
  };

  return (
    <Row gutter={12}>
      <Col span={6}>
        <Form.Item name="files">
          <CUpload props={props} />
        </Form.Item>
      </Col>
      {files && files.length > 0
        ? files.map((doc) => {
            if (doc.delete == undefined) {
              if (doc.isn && doc.active == 'Y') {
                return (
                  <Col span={6}>
                    <CFiles
                      doc={doc}
                      deleteFile={deleteFile}
                      downloadFile={downloadFile}
                    />
                  </Col>
                );
              }
              if (!doc.isn) {
                return (
                  <Col span={6}>
                    <CFiles
                      doc={doc}
                      deleteFile={deleteFile}
                      downloadFile={downloadFile}
                    />
                  </Col>
                );
              }
            }
          })
        : ''}
    </Row>
  );
}
export default EditContractorDocFiles;
