import { useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import AuthContext from '../contexts/AuthContext';
import api from '../services/api';
import { domain } from '../services/api/axios';
import { IUser } from '@contexts/AuthContext/type';

interface IAuthProvider {
  children: React.ReactNode;
}

const AuthProvider: React.FC<IAuthProvider> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState<IUser>({} as IUser);
  const [token, setTokenData] = useState<string | null>(null);

  const setToken = useCallback((tokenData) => {
    setTokenData(tokenData);
    if (tokenData) {
      // Cookies.set('token', tokenData, { sameSite: 'None', secure: true });
      Cookies.set('token', tokenData, {
        domain:
          process.env.NODE_ENV === 'development' ? 'localhost' : '.mycent.kz',
        sameSite: 'None',
        secure: true,
      });

      // localStorage.setItem('token', tokenData); // Запасной вариант на случай если в iframe Bitrix не будет работать авторизация
    } else {
      Cookies.remove('token', domain);
    }
  }, []);

  const logOut = useCallback(() => {
    setUser({} as IUser);
    setToken(null);
    api.auth.logout();
  }, [setToken]);

  const loadData = useCallback(async () => {
    const tokenData = Cookies.get('token', domain); // || localStorage?.getItem('token') : null; // Запасной вариант на случай если в iframe Bitrix не будет работать авторизация
    setTokenData(tokenData);

    try {
      if (tokenData) {
        const { data } = await api.auth.getProfile();
        setUser(data);
      }
    } catch {
      setToken(null);
    } finally {
      setIsLoaded(true);
    }
  }, [setToken]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const contextValue = useMemo(
    () => ({
      isLoaded,
      user,
      token,
      setUser,
      setToken,
      logOut,
    }),
    [isLoaded, user, token, setToken, logOut]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
