import { useState } from 'react';
import { Button, Drawer, Layout, notification } from 'antd';

import useAuth from '@hooks/useAuth';
import PageHeaderDropdown from './components/Dropdown';
import MainMenu from '../MainMenu';

import './PageHeader.scss';
import { MenuOutlined } from '@ant-design/icons';
import Logo from '../Logo';
import { NavLink } from 'react-router-dom';
import { openLearningPage } from '@pages/ISpring/utils';

const { Header } = Layout;

function PageHeader() {
  const auth = useAuth();
  const [open, setOpen] = useState(false);

  const handleLogOut = (): void => {
    auth.logOut();
  };

  return (
    <Header className="page-header" role="banner">
      <div className="page-header__inner">
        <Button
          role="buttonBurger"
          className="page-header__menu-button"
          type="text"
          size="large"
          onClick={() => {
            setOpen(true);
          }}
          icon={<MenuOutlined />}
        />
        <Drawer placement="left" onClose={() => setOpen(false)} open={open}>
          <MainMenu onSelect={() => setOpen(false)} />
        </Drawer>
        <div className="page-header__logo-wrap">
          <Logo />
        </div>
        <ul className="page-header__nav">
          <li className="page-header__nav-item">
            <a
              href="https://cic.kz/about"
              target="_blank"
              rel="noreferrer"
              role="navigation-item"
            >
              О компании
            </a>
          </li>
          <li className="page-header__nav-item">
            <a
              href="/#"
              onClick={(event) => event.preventDefault()}
              role="navigation-item"
            >
              Шеф говорит
            </a>
          </li>
          <li className="page-header__nav-item">
            <a
              href="http://wall.cic.kz/"
              target="_blank"
              rel="noreferrer"
              role="navigation-item"
            >
              Стена
            </a>
          </li>
          <li className="page-header__nav-item">
            <NavLink to="/parse" role="navigation-item">
              Parse
            </NavLink>
          </li>
          <li className="page-header__nav-item">
            <NavLink to="/onlineLibrary" role="navigation-item">
              Библиотека
            </NavLink>
          </li>
          {auth?.user?.data?.ispring?.group !== null ? (
            <li className="page-header__nav-item">
              <NavLink to="/ispring" role="navigation-item">
                Обучение
              </NavLink>
            </li>
          ) : (
            ''
          )}
          {(auth.user.data?.ispring?.group === 'FRONT' ||
            auth.user.data?.ispring?.group === 'NEW') && (
            <li className="page-header__nav-item">
              <a
                href={void 0}
                onClick={() => openLearningPage()}
                role="navigation-item"
              >
                СДО (Обучение)
              </a>
            </li>
          )}
        </ul>
        <PageHeaderDropdown onLogOut={handleLogOut} />
      </div>
    </Header>
  );
}

export default PageHeader;
