import { Layout, FloatButton } from 'antd';
import { Outlet, useSearchParams } from 'react-router-dom';
import { UpOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import api from '@modules/Quotation/services';

import catchErrorCustomAxios from '../services/api/catchErrors';

const { Sider } = Layout;

interface PageLayoutBitrix {
  children?: React.ReactNode;
}

const PageLayoutBitrix: React.FC<PageLayoutBitrix> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const hash = searchParams.get('hash');
  const isBitrix = searchParams.get('isBitrix');
  const managerIsn = searchParams.get('managerIsn');
  const clientIin = searchParams.get('clientIin');
  const productIsn = searchParams.get('productIsn');
  const idBitrix = searchParams.get('idBitrix');

  function dataBitrix(
    getHash,
    getIsBitrix,
    getManagerIsn,
    getClientIin,
    getProductIsn,
    getIdBitrix
  ) {
    return {
      hash: getHash,
      isBitrix: getIsBitrix,
      managerIsn: getManagerIsn,
      clientIin: getClientIin,
      productIsn: getProductIsn,
      idBitrix: getIdBitrix,
    };
  }

  async function makeRequestAuthorizeAndSetCookieAndSetUser(
    bodyDataBitrixQuotation
  ) {
    const response = await api.saveQuotation.authorizeByBitrixUrl(
      bodyDataBitrixQuotation
    );
    setCookieBitrix(response);
    setUser(response.data);
    return response;
  }

  function setCookieBitrix(token) {
    Cookies.set('tokenBitrix', token.data?.access_token, {
      sameSite: 'none',
      secure: true,
      domain: 'mycent.kz',
    });
  }

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const checkNotNullable =
          hash !== null &&
          isBitrix !== null &&
          managerIsn !== null &&
          clientIin !== null &&
          productIsn !== null &&
          idBitrix !== null;
        if (checkNotNullable) {
          const bodyDataBitrixQuotation = dataBitrix(
            hash,
            isBitrix,
            managerIsn,
            clientIin,
            productIsn,
            idBitrix
          );
          await makeRequestAuthorizeAndSetCookieAndSetUser(
            bodyDataBitrixQuotation
          );
        }
      } catch (error) {
        let message;
        catchErrorCustomAxios(error, message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [hash, clientIin, idBitrix, isBitrix, managerIsn, productIsn]);

  return (
    <Layout className="page-app">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={228}
        style={{ background: '#fff' }}
      ></Sider>
      <Layout>
        <Layout>{user?.isBitrix === 1 && <Outlet />}</Layout>
      </Layout>
      <FloatButton.BackTop>
        <div className="back-top">
          <UpOutlined />
        </div>
      </FloatButton.BackTop>
    </Layout>
  );
};

export default PageLayoutBitrix;
