import { Form, FormInstance, Select } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { HandbookListType } from '@hooks/useHandbook';
import { IRowColumnEditor } from '@modules/Edo/types';

interface ISelectCustomTag {
	settings: IRowColumnEditor;
	handbookList: HandbookListType;
	name: number;
	form: FormInstance<any>;
}

const SelectCustomTag = ({ settings, handbookList, name, form }: ISelectCustomTag) => {
	const selectRef = useRef<any>(null);

	const handleChange = useCallback((value: string[]) => {
		const selectedValue = value.length > 0 ? [value[value.length - 1]] : [];
		const currentValues = form.getFieldsValue();

		form.setFieldsValue({
			row_column_editor: [
				{
					...currentValues.row_column_editor[0],
					[`id-${settings.id}`]: {
						...currentValues.row_column_editor[0][`id-${settings.id}`],
						char_value: selectedValue,
					},
				},
			],
		});

		if (selectRef.current) {
			selectRef.current.blur();
		}
	}, [form, settings.id]);


	return (
		<Form.Item
			label={settings.field_name}
			name={[name, `id-${settings.id}`, 'char_value']}
			rules={[{ required: settings.required }]}
			hidden={!settings.show_column}
		>
			<Select
				ref={selectRef}
				mode="tags"
				style={{ width: '100%' }}
				placeholder={settings.field_name}
				onChange={(value) => handleChange(value)}
				options={handbookList[0] ? handbookList[0].children.map(item => ({
					label: item.full_name,
					value: item.full_name // Если нужно отправлять айди String(item.id)
				})) : []}
			/>
		</Form.Item>
	)
};

export default SelectCustomTag;

