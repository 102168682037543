import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Divider,
} from 'antd';
import CInput from '@components/UI/input/CInput';
import type { RadioChangeEvent } from 'antd';
import CRadio from '@components/UI/radio/CRadio';
import dayjs from 'dayjs';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import DateMask from '@modules/Edo/DateMask';
interface Props {
  isVisible: boolean;
  from: string;
  handleCancel: () => void;
  getContractorList;
  isLoading: boolean;
  contractorFullInfo: any;
}
function SearchClientModal(props: Props) {
  const {
    isLoading,
    handleCancel,
    getContractorList,
    contractorFullInfo,
    from,
  } = props;
  const [form] = Form.useForm();
  const options = [
    { label: 'Резидент', value: 'Y' },
    { label: 'Нерезидент', value: 'N' },
  ];
  const options2 = [
    { label: 'Физическое', value: 'N' },
    { label: 'Юридическое', value: 'Y' },
  ];

  useEffect(() => {
    form.setFieldValue('jur', 'N');
  }, []);
  useEffect(() => {
    if (contractorFullInfo && !contractorFullInfo.from) {
      form.setFieldsValue({
        ...contractorFullInfo,
        org_name: contractorFullInfo.fullname,
        birthday: contractorFullInfo.birthday
          ? dayjs(contractorFullInfo.birthday, 'DD-MM-YYYY')
          : '',
      });
    }
  }, [contractorFullInfo]);
  DateMask();
  return (
    <>
      <Modal
        title={
          <span
            style={{
              color: '#7449ef',
              fontSize: 20,
              fontWeight: 600,
              lineHeight: 1.4,
            }}
          >
            Поиск контрагента
          </span>
        }
        open={true}
        onCancel={handleCancel}
        width="1000px"
        footer={[]}
      >
        <CustomLoader spinning={isLoading}>
          <Divider />
          <Form
            onFinish={getContractorList}
            form={form}
            className="c-form"
            layout="vertical"
          >
            <Row gutter={8} className="mt-5">
              <Col span={12}>
                <Form.Item name="iin" label="ИИН / БИН">
                  <Input
                    type={'number'}
                    style={{ width: '100%' }}
                    placeholder="ИИН / БИН"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="from" hidden noStyle>
                  <Input value={from} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="org_name" label="ФИО / Наименование">
                  <Input placeholder="ФИО / Наименование" allowClear />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="birthday" label="Дата рождения">
                  <DatePicker
                    className="dateMask"
                    format="DD.MM.YYYY"
                    placeholder="Дата рождения"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="jur"
                  label="Орг. форма"
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                  <Select
                    placeholder="Орг. форма"
                    style={{ width: '100%' }}
                    options={options2}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="resident"
                  label="Статус"
                  // rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                  <CRadio
                    className="juridical"
                    options={options}
                    optionType="button"
                    style={{ width: '100%' }}
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              Поиск
            </Button>
          </Form>
        </CustomLoader>
      </Modal>
    </>
  );
}
export default SearchClientModal;
