import {
  SearchOutlined,
  MessageOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import RecruitingComponent from '@modules/Recruiting';
import { Card, Row, Col, Button } from 'antd';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

function RecruitingPage() {
  const navigate = useNavigate();

  const navigateToNewApplication = () => {
    navigate('/recruiting/new-application');
  };

  const navigateToInterview = () => {
    navigate('/recruiting');
  };

  const navigateToResult = () => {
    navigate('/recruiting/result');
  };

  return (
    <>
      <Card>
        <Row gutter={24} justify={{ lg: 'start' }} align="bottom">
          <Col span={8} xs={24} xl={8}>
            <Button
              size="large"
              icon={<SearchOutlined />}
              onClick={navigateToNewApplication}
              block
              style={{
                whiteSpace: 'normal',
                height: 'auto',
              }}
            >
              Заявка на поиск кандидата (ГО и Регионы)
            </Button>
          </Col>
          <Col span={8} xs={24} xl={8}>
            <Button
              size="large"
              icon={<MessageOutlined />}
              onClick={navigateToInterview}
              block
              style={{
                whiteSpace: 'normal',
                height: 'auto',
              }}
            >
              Интервью руководителей с кандидатами
            </Button>
          </Col>
          <Col span={8} xs={24} xl={8}>
            <Button
              size="large"
              icon={<CheckOutlined />}
              onClick={navigateToResult}
              block
              style={{
                whiteSpace: 'normal',
                height: 'auto',
              }}
            >
              Результат проделанной работы
            </Button>
          </Col>
        </Row>
      </Card>

      <RecruitingComponent>
        <Outlet />
      </RecruitingComponent>
    </>
  );
}

export default RecruitingPage;
