import { SaveOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import React from 'react';

type SubmitButtonProps = {
  buttonText?: string;
  onClick?: () => void;
};

function SubmitButton({ buttonText, onClick }: SubmitButtonProps) {
  return (
    <>
      <Button htmlType="submit" block type="primary" icon={<SaveOutlined />} onClick={onClick}>
        {buttonText || 'Отправить'}
      </Button>
    </>
  );
}

export default SubmitButton;
