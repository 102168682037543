import api from '@modules/Quotation/services';

import { useEffect, useState } from 'react';

export interface ICityData {
  id: number;
  parent_isn: number;
  region_isn: number;
  city_isn: number;
  name: string;
  child: ICityData[];
  is_reg: string;
}

function useCityQuotation() {
  const [cityData, setCityData] = useState<ICityData[]>([]);

  useEffect(() => {
    getCityDataMethod().then((values) => {
      setCityData(values);
    });
  }, []);

  const getCityDataMethod = async () => {
    const { data } = await api.quotation.getCities();
    return data.data;
  };

  return { cityData };
}

export default useCityQuotation;
