import { useEffect, useState } from 'react';
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusOutlined,
  UploadOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  Table,
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  notification,
  Upload,
  DatePicker,
  Tabs,
  Space,
  message,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import type { UploadProps, TabsProps } from 'antd';
import EditContractorDocInfo from '../EditContractorDocInfo';
import EditContractorDocFiles from '../EditContractorDocFiles';
import api from '@modules/Quotation/services';
import { useImmerReducer } from 'use-immer';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

type Action = {
  type: 'getContractorDocs' | 'loading';
  payload?: any;
};
interface State {
  docList: any;
  isLoad: boolean;
}
const initialState: State = {
  docList: [],
  isLoad: false,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'getContractorDocs': {
      draft.docList = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoad = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};
type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

function ClientDocumentTable({
  docs,
  settings,
  subjisn,
  juridical,
  selectContractor,
  isRelated,
}) {
  const [editingKey, setEditingKey] = useState<any>();
  const [isAdd, setIsAdd] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const [documentInfoForm] = Form.useForm();
  const [documentForm] = Form.useForm();

  const { docList, isLoad } = state;

  const edit = (record) => {
    setFiles(record.docs_files);
    setEditingKey(record);
  };
  const deleteDocument = async (record) => {
    dispatch({
      type: 'loading',
      payload: true,
    });
    await api.contractor.deleteContractorDocument(record.isn);
    const { data } = await api.contractor.getContractorByISN(subjisn);
    const type = isRelated ? 'related' : '';
    selectContractor(data.data, type);
    const array = data.data.docs.map((item) => {
      if (item.docno == data.data.docno) {
        return { ...item, mainDoc: true };
      } else {
        return { ...item, mainDoc: false };
      }
    });
    const findMain = array.filter((item) => item.mainDoc);
    if (findMain && findMain.length > 0) {
      dispatch({
        type: 'getContractorDocs',
        payload: array,
      });
    } else {
      dispatch({
        type: 'getContractorDocs',
        payload: [
          {
            docno: data.data.docno,
            docdate: data.data.docdate,
            classisn: +data.data.docclassisn,
            classisn_name: data.data.docclassisn_name,
            handby: data.data.docissuedby,
            mainDoc: true,
          },
          ...array,
        ],
      });
    }
    dispatch({
      type: 'loading',
      payload: false,
    });
  };

  const columns = [
    {
      dataIndex: 'dataIndex',
      key: 'docno',
      width: '0',
      editable: true,
    },
    {
      dataIndex: 'classisn_name',
      key: 'classisn_name',
      width: '15%',
      editable: true,
      render: (value: string, record) =>
        record.mainDoc ? (
          <p style={{ fontWeight: 600 }}>{value}</p>
        ) : (
          <p>{value}</p>
        ),
    },
    {
      dataIndex: 'mainDoc',
      key: 'mainDoc',
      width: '12%',
      editable: true,
      render: (value: string, record) =>
        record.mainDoc ? (
          <p className="text-align" style={{ color: '#38C33E' }}>
            Основной
          </p>
        ) : (
          <p className="text-align">{value}</p>
        ),
    },
    {
      dataIndex: 'docno',
      key: 'docno',
      ellipsis: true,
      width: '10%',
      editable: true,
      render: (value: string, record) =>
        record.mainDoc ? (
          <p
            className="text-align"
            style={{ fontWeight: 600, marginBottom: 0 }}
          >
            {value}
          </p>
        ) : (
          <p className="text-align">{value}</p>
        ),
    },
    {
      dataIndex: 'docdate',
      key: 'docdate',
      ellipsis: true,
      width: '10%',
      editable: true,
      render: (value: string, record) =>
        record.mainDoc ? (
          <p
            className="text-align"
            style={{ fontWeight: 600, marginBottom: 0 }}
          >
            {value}
          </p>
        ) : (
          <p className="text-align">{value}</p>
        ),
    },
    {
      dataIndex: 'handby',
      key: 'handby',
      ellipsis: true,
      width: '10%',
      editable: true,
      render: (value: string, record) =>
        record.mainDoc ? (
          <p
            className="text-align"
            style={{ fontWeight: 600, marginBottom: 0 }}
          >
            {value}
          </p>
        ) : (
          <p className="text-align">{value}</p>
        ),
    },
    {
      dataIndex: 'pict_count',
      key: 'pict_count',
      ellipsis: true,
      width: '14%',
      editable: true,
      render: (_, record) => <a className="text-align">{record.pict_count}</a>,
    },
    {
      key: 'action',
      ellipsis: true,
      width: '14%',
      render: (_, record) => (
        <>
          <Button type="primary" className="mr-1" onClick={() => edit(record)}>
            <EditOutlined />
          </Button>
          {!record.mainDoc && (
            <Button
              type="primary"
              danger
              onClick={() => deleteDocument(record)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </>
      ),
    },
  ];
  const deleteFile = (file) => {
    const data = files.map((item) => {
      if (
        (item.file_name &&
          file.file_name &&
          item.file_name == file.file_name) ||
        (item.filename && file.filename && item.filename == file.filename)
      ) {
        return {
          ...item,
          active: 'N',
          delete: true,
        };
      } else {
        return item;
      }
    });
    setFiles(data);
  };

  const token = Cookies.get('token'); // || localStorage?.getItem('token'); // Запасной вариант на случай если в iframe Bitrix не будет работать авторизация
  const [docExtensions] = useState<any>([
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.rar',
    'application/zip',
  ]);

  const props: UploadProps = {
    name: 'file',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    accept: docExtensions,
    fileList: [],
    beforeUpload: (file) => {
      const reader = new FileReader();
      if (files.length > 0) {
        reader.onload = () => {
          setFiles([...files, { file_name: file.name, file: reader.result }]);
        };
        reader.readAsDataURL(file);
      } else {
        reader.onload = () => {
          setFiles([{ file_name: file.name, file: reader.result }]);
        };
        reader.readAsDataURL(file);
      }
      message.success(`${file.name} file uploaded successfully`);
      return false;
    },
  };
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Редактирование информации`,
      children: (
        <EditContractorDocInfo settings={settings} editingKey={editingKey} />
      ),
    },
    {
      key: '2',
      label: `Вложенные файлы`,
      children: (
        <EditContractorDocFiles
          editingKey={editingKey}
          props={props}
          files={files}
          deleteFile={deleteFile}
        />
      ),
    },
  ];
  const createDocument = async (values: any) => {
    try {
      dispatch({
        type: 'loading',
        payload: true,
      });
      const { data } = await api.contractor.getContractorByISN(subjisn);
      if (data.data.docs.length == 0 && data.data.docno.length == 0) {
        if (values.document) {
          await api.contractor.saveContractorDocs({
            ...values.document[0],
            docdate: dayjs(values.document[0].docdate).format('DD.MM.YYYY'),
            files,
            subjisn,
            mainDoc: true,
            juridical,
          });
        } else {
          await api.contractor.saveContractorDocs({
            ...values,
            docdate: dayjs(values.docdate).format('DD.MM.YYYY'),
            files,
            subjisn,
            juridical,
            mainDoc: true,
          });
        }
      } else {
        if (values.document) {
          await api.contractor.saveContractorDocs({
            ...values.document[0],
            docdate: dayjs(values.document[0].docdate).format('DD.MM.YYYY'),
            files,
            subjisn,
            juridical,
          });
        } else {
          await api.contractor.saveContractorDocs({
            ...values,
            docdate: dayjs(values.docdate).format('DD.MM.YYYY'),
            files,
            subjisn,
            juridical,
          });
        }
      }

      const info = await api.contractor.getContractorByISN(subjisn);

      const type = isRelated ? 'related' : '';

      // dispatch({ type: 'getContractorDocs', payload: info.data.data.docs });
      // selectContractor(data.data, type);
      setIsAdd(false);

      const array = info.data.data.docs.map((item) => {
        if (item.docno == info.data.data.docno) {
          return { ...item, mainDoc: true };
        } else {
          return { ...item, mainDoc: false };
        }
      });
      const findMain = array.filter((item) => item.mainDoc);
      if (findMain && findMain.length > 0) {
        dispatch({
          type: 'getContractorDocs',
          payload: [...findMain, ...array.filter((item) => !item.mainDoc)],
        });
      } else {
        dispatch({
          type: 'getContractorDocs',
          payload: [
            {
              docno: data.data.docno,
              docdate: data.data.docdate,
              classisn: +data.data.docclassisn,
              classisn_name: data.data.docclassisn_name,
              handby: data.data.docissuedby,
              mainDoc: true,
            },
            ...array,
          ],
        });
      }
      dispatch({
        type: 'loading',
        payload: false,
      });
      setEditingKey(null);
    } catch (error) {
      dispatch({
        type: 'loading',
        payload: false,
      });
      let message;
      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const updateDocument = async (values: any) => {
    dispatch({
      type: 'loading',
      payload: true,
    });
    try {
      await api.contractor.saveContractorDocs({
        ...values,
        docdate: dayjs(values.docdate).isValid()
          ? dayjs(values.docdate).format('DD.MM.YYYY')
          : '',
        files,
        subjisn,
        juridical,
      });
      const { data } = await api.contractor.getContractorByISN(subjisn);
      const type = isRelated ? 'related' : '';
      selectContractor(data.data, type);
      const array = data.data.docs.map((item) => {
        if (item.docno == data.data.docno) {
          return { ...item, mainDoc: true };
        } else {
          return { ...item, mainDoc: false };
        }
      });
      const findMain = array.filter((item) => item.mainDoc);
      if (findMain && findMain.length > 0) {
        dispatch({
          type: 'getContractorDocs',
          payload: array,
        });
      } else {
        dispatch({
          type: 'getContractorDocs',
          payload: [
            {
              docno: data.data.docno,
              docdate: data.data.docdate,
              classisn: +data.data.docclassisn,
              classisn_name: data.data.docclassisn_name,
              handby: data.data.docissuedby,
              mainDoc: true,
            },
            ...array,
          ],
        });
      }
      dispatch({
        type: 'loading',
        payload: false,
      });
      setEditingKey(null);
    } catch (error) {
      dispatch({
        type: 'loading',
        payload: false,
      });
      let message;
      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const onFinish = async (values: any) => {
    if (values.subdocisn) {
      if (
        values.docclassisn == 221719 ||
        values.docclassisn == 221720 ||
        values.docclassisn == 221721 ||
        values.docclassisn == 221722 ||
        values.docclassisn == 221723
      ) {
        const activeFiles = files.filter(
          (file) => (file.isn && file.active == 'Y') || file.file
        );
        if (activeFiles.length > 0) {
          updateDocument(values);
        } else {
          notification.info({
            message: 'Ошибка',
            description: 'Для данного документа вложения обязательны!',
          });
        }
      } else {
        updateDocument(values);
      }
    } else {
      if (
        values.document &&
        (values.document[0].docclassisn == 221719 ||
          values.document[0].docclassisn == 221720 ||
          values.document[0].docclassisn == 221721 ||
          values.document[0].docclassisn == 221722 ||
          values.document[0].docclassisn == 221723)
      ) {
        const activeFiles = files.filter(
          (file) => (file.isn && file.active == 'Y') || file.file
        );
        if (activeFiles.length > 0) {
          createDocument(values);
        } else {
          notification.info({
            message: 'Ошибка',
            description: 'Для данного документа вложения обязательны!',
          });
        }
      } else {
        createDocument(values);
      }
    }
  };
  useEffect(() => {
    if (docs) {
      dispatch({ type: 'getContractorDocs', payload: docs });
    }
  }, [docs]);

  const operations = (
    <Space>
      <Button
        size="small"
        onClick={() => {
          setEditingKey(null);
          setFiles([]);
        }}
      >
        <CloseOutlined />
      </Button>
      <Button type="primary" size="small" htmlType="submit">
        <CheckOutlined />
      </Button>
    </Space>
  );

  useEffect(() => {
    if (editingKey) {
      documentInfoForm.setFieldsValue({
        ...editingKey,
        subdocisn: editingKey.isn,
        docclassisn: editingKey.classisn,
        docdate:
          editingKey.docdate.length > 0
            ? dayjs(editingKey.docdate, 'DD-MM-YYYY')
            : null,
        mainDoc: editingKey.mainDoc ? editingKey.mainDoc : false,
      });
    }
  }, [editingKey]);

  useEffect(() => {}, [subjisn]);
  const sendValue = () => {
    documentForm.setFieldsValue({ document: [{ subjisn }] });
  };

  return (
    <>
      <CustomLoader spinning={isLoad}>
        <Form
          name="contractorDocument"
          onFinish={onFinish}
          style={{ maxWidth: 1000 }}
          form={documentForm}
        >
          <Form.List name="document">
            {(fields, { add, remove }, { errors }) => (
              <>
                {isAdd && subjisn ? (
                  fields.map(({ key, name }) => (
                    <Row
                      gutter={6}
                      key={key}
                      className="mt-3"
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                        justifyContent: 'space-between',
                      }}
                    >
                      <Form.Item name={[name, 'subjisn']} hidden noStyle>
                        <Input />
                      </Form.Item>
                      <Col span={4}>
                        <Form.Item
                          name={[name, 'docclassisn']}
                          rules={[
                            {
                              required: true,
                              message: 'Выберите тип документа',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Выберите тип документа"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.full_name ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.full_name ?? '')
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.full_name ?? '').toLowerCase()
                                )
                            }
                            options={settings?.docclassisn.children}
                            fieldNames={{
                              label: 'full_name',
                              value: 'foreign_id',
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[name, 'docno']}
                          rules={[
                            {
                              required: true,
                              message: 'Заполните номер документа',
                            },
                          ]}
                        >
                          <Input placeholder="Номер документа" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[name, 'docdate']}
                          rules={[
                            {
                              required: true,
                              message: 'Заполните дату документа',
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD.MM.YYYY"
                            style={{ width: '100%' }}
                            placeholder="Дата выдачи"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[name, 'handby']}
                          rules={[
                            {
                              required: true,
                              message: 'Укажите кем выдан документ',
                            },
                          ]}
                        >
                          <Input placeholder="Кем выдан" />
                        </Form.Item>
                      </Col>

                      <Form.Item
                      // name={[name, 'last']}
                      // rules={[{ required: true, message: 'Missing last name' }]}
                      >
                        <Upload {...props}>
                          <Button icon={<UploadOutlined />}>
                            Загрузить файлы
                          </Button>
                        </Upload>
                      </Form.Item>
                      <Button
                        onClick={() => {
                          setIsAdd(false);
                          setFiles([]);
                          remove(name);
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        style={{ backgroundColor: '#39c33e' }}
                      >
                        <CheckOutlined />
                      </Button>
                    </Row>
                  ))
                ) : (
                  <Form.Item className="mt-3">
                    <Button
                      type="text"
                      onClick={() => {
                        add();
                        setIsAdd(true);
                        sendValue();
                      }}
                      style={{
                        width: '100%',
                        color: '#7449ef',
                        fontSize: '16px',
                      }}
                      icon={<PlusOutlined />}
                    >
                      Добавить документ
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </Form>
        {docList.map((doc) => {
          if (editingKey && doc.isn == editingKey.isn) {
            return (
              <Form
                name="documentInfoForm"
                layout="vertical"
                form={documentInfoForm}
                onFinish={onFinish}
              >
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  style={{ backgroundColor: '#F0F2F5', padding: '20px 30px' }}
                  tabBarExtraContent={operations}
                />
              </Form>
            );
          } else {
            return (
              <Table
                showHeader={false}
                columns={columns}
                dataSource={[doc]}
                pagination={{
                  position: [
                    'none' as TablePaginationPosition,
                    'none' as TablePaginationPosition,
                  ],
                }}
                rowKey="docno"
              />
            );
          }
        })}
      </CustomLoader>
    </>
  );
}
export default ClientDocumentTable;
