import { Button, Modal, Select, Divider, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
interface DataType {
  key: string;
  MODEL: string;
  NYEAR: string;
  MARK: string;
  REG_NUM: string;
  VIN: string;
  isn: number;
}

function ResultCarModal({
  autoList,
  selectCar,
  closeTransportModal,
  isLoading,
}) {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Марка / Модель',
      dataIndex: 'MODEL',
      key: 'MODEL',
      width: '35%',
      render: (val, record) => {
        return (
          <p>
            {record.MARK} / {val}
          </p>
        );
      },
    },
    {
      title: 'Год',
      dataIndex: 'NYEAR',
      key: 'NYEAR',
      width: '10%',
    },
    {
      title: 'Госномер',
      dataIndex: 'REG_NUM',
      key: 'REG_NUM',
      width: '17%',
    },
    {
      title: 'VIN',
      dataIndex: 'VIN',
      key: 'VIN',
      width: '23%',
    },
    {
      title: '',
      key: 'action',
      width: '15%',
      render: (_, record) => (
        <Button type="primary" onClick={() => selectCar(record)}>
          Открыть
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title={
        <span
          style={{
            color: '#7449ef',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          Выберите транспортное средство
        </span>
      }
      open={true}
      onCancel={closeTransportModal}
      width="1000px"
      footer={[<></>]}
    >
      <CustomLoader spinning={isLoading}>
        {/* <Divider /> */}
        {/* <Button onClick={createContractor}>Создать контрагента</Button> */}

        <Table className="mt-5" columns={columns} dataSource={[autoList]} />
      </CustomLoader>
    </Modal>
  );
}
export default ResultCarModal;
