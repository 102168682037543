import React, { useState } from 'react';
import { Row, Col } from 'antd';
import useAuth from '../../hooks/useAuth';
import Centcoins from '../Centcoins/Centcoins';
import UserInfo from '@modules/UserInfo/UserInfo';
import UserDetails from '@modules/common/UserDetails';
import UserDetailsCareer from '@modules/common/UserDetails/UserDetailsCareer';
import UserDetailsDefault from '@modules/common/UserDetails/UserDetailsDefault';
import UserDetailsMission from '@modules/common/UserDetails/UserDetailsMission';
import UserDetailsVacation from '@modules/common/UserDetails/UserDetailsVacation';

interface IColleagueInfo {
  colleagueInfo: any;
}

export default function ColleagueInfo({ colleagueInfo }: IColleagueInfo) {
  const [showCentCoins, setShowCentCoins] = useState<boolean>(false);
  const auth = useAuth();

  return (
    <div className="aboutme">
      <UserInfo
        userInfo={colleagueInfo?.user_info}
        userDetails={colleagueInfo?.user_detail}
        permission={auth.user?.data}
      />
      <section className="user-profile-data">
        <div className="options-about">
          <div className="user-options">
            <a
              className="user-option-about user-dossier"
              onClick={() => {
                setShowCentCoins(false);
              }}
            >
              Досье
            </a>
          </div>
          <div className="user-options">
            <a
              className="user-option-about"
              onClick={() => setShowCentCoins(true)}
            >
              Сенткоины
            </a>
          </div>
        </div> 
        <hr className="options-divider"></hr>
      </section>
      <Row>
        {showCentCoins ? (
          <Col span={24} className="mt-5">
            <Centcoins
              id={colleagueInfo.id}
              centcoin={colleagueInfo.centcoin}
            />
          </Col>
        ) : (
          <>
            <Col span={24} className="mt-5">
              <UserDetails title="Кадровое перемещение">
                <UserDetailsCareer
                  detailList={colleagueInfo?.user_detail?.carier}
                />
              </UserDetails>
            </Col>
            <Col span={24} className="mt-5">
              <UserDetails title="Административные дни">
                <UserDetailsDefault
                  detailList={colleagueInfo?.user_detail?.admin}
                  titleRest={`Не использованные адм. дни: ${
                    colleagueInfo?.user_detail?.admin_balance ?? ''
                  }`}
                />
              </UserDetails>
            </Col>
            <Col span={24} className="mt-5">
              <UserDetails title="Отпуск">
                <UserDetailsVacation
                  detailList={colleagueInfo?.user_detail?.vacation}
                />
              </UserDetails>
            </Col>
            <Col span={24} className="mt-5">
              <UserDetails title="Больничные дни">
                <UserDetailsDefault
                  detailList={colleagueInfo?.user_detail?.sick}
                />
              </UserDetails>
            </Col>
            <Col span={24} className="mt-5">
              <UserDetails title="Командировка">
                <UserDetailsMission
                  detailList={colleagueInfo?.user_detail?.mission}
                />
              </UserDetails>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}
