import { Row, Col, InputNumber, Select, Form } from 'antd';
import React from 'react';
import useRecruiting from '../hooks/useRecruiting';
import { fieldNamesRecruiting } from './fieldNames';

const { Option } = Select;

function SalaryFields() {
  const { state } = useRecruiting();

  const { selectValues } = state;

  return (
    <>
      <Row gutter={24} align="bottom" justify={{ lg: 'start'}}>
        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="На испытательный срок:"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.salary}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              min={String(0)}
            />
          </Form.Item>
        </Col>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="После испытательного срока:"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.salary_after_period}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              min={String(0)}
            />
          </Form.Item>
        </Col>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Наличие дополнительной мотивации (бонусов)"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.motivation}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.motivation.length &&
                selectValues.motivation.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="График работы"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.job_chart}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.job_chart.length &&
                selectValues.job_chart.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Наличие автомобиля"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.have_car}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.have_car.length &&
                selectValues.have_car.map((element) => (
                  <Option value={element.id} key={element.id}>
                    {element.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.have_car !== curValues.have_car
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const carExistValue = getFieldValue(fieldNamesRecruiting.have_car);

            if (carExistValue === 7626) {
              return (
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Категория вод. удостоверения"
                    rules={[{ required: true }]}
                    name={fieldNamesRecruiting.driver_category}
                  >
                    <Select style={{ width: '100%' }}>
                      {selectValues.driver_category.length &&
                        selectValues.driver_category.map((element) => (
                          <Option value={element.id} key={element.id}>
                            {element.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              );
            }
          }}
        </Form.Item>

        <Col span={8} xs={24} xl={8}>
          <Form.Item
            label="Больничный, мед.страхование, отпускные"
            rules={[{ required: true }]}
            name={fieldNamesRecruiting.social_packages}
          >
            <Select style={{ width: '100%' }}>
              {selectValues.social_packages.length &&
                selectValues.social_packages
                  .filter(
                    (value, index, self) =>
                      self.findIndex((data) => data.id === value.id) === index
                  )
                  .map((element) => (
                    <Option value={element.id} key={element.id + Math.random()}>
                      {element.name}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default SalaryFields;
