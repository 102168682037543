import { Modal } from 'antd';
import Approvers from '../../../modules/common/Approvers';
import { IDocument } from '.';
interface Props {
  visible: boolean;
  handleOk: any;
  handleCancel: any;
  data?: IDocument;
}
function EdoSearchModal({ visible, handleOk, handleCancel, data }: Props) {
  return (
    <>
      <Modal
        data-testid="edo-search-modal"
        title={data?.class.full_name + '. ' + data?.document_id}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="1000px"
      >
        {data?.coordination && data?.document_id ? (
          <Approvers
            userList={data.coordination}
            documentID={data.document_id}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  );
}

export default EdoSearchModal;
