import { IDocAttr } from '../type';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface Props {
  docAttrList: IDocAttr[];
}

const columns: ColumnsType<IDocAttr> = [
  {
    title: 'Название',
    dataIndex: 'ATTRTYPENAME',
    key: 'ATTRTYPENAME',
    width: '50%',
  },
  {
    title: 'Значение',
    dataIndex: 'VALUE',
    key: 'VALUE',
    width: '50%',
  },
];

export default function AgreementDocAttr(props: Props) {
  const { docAttrList = [] } = props;
  return (
    <div>
      <Table
        columns={columns}
        dataSource={docAttrList}
        bordered
        showHeader={false}
        scroll={{ x: 600 }}
      />
    </div>
  );
}
