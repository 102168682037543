export const ADD_COMPANY_DATA = 'ADD_COMPANY_DATA';
export const ADD_NUMBER_PAGE = 'ADD_NUMBER_PAGE';

export function addData(data: number) {
  return {
    type: ADD_COMPANY_DATA as typeof ADD_COMPANY_DATA,
    payload: data,
  };
}

export function addNumberPage(data: number) {
  return {
    type: ADD_NUMBER_PAGE as typeof ADD_NUMBER_PAGE,
    payload: data,
  };
}