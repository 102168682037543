import { Button, Empty, message, Row, Upload, UploadFile, UploadProps } from 'antd';
import Logo from '@modules/common/Logo';
import React, { useEffect, useState } from 'react';
import api from '@modules/FileExchange/services/api';
import { useParams } from 'react-router-dom';
import DocumentItem from '@modules/FileExchange/DocumentItem/DocumentItem';
import Title from 'antd/es/typography/Title';
import { get } from 'react-hook-form';
import FileListModal from '@modules/FileExchange/FileListModal/FileListModal';

interface IFiles {
	file_uuid: string;
	name: string;
}

const { Dragger } = Upload;

const OuterClient = () => {
	const { uuid } = useParams();

	const [isFileDownload, setIsFileDownload] = useState(false);
	const [isFileListModal, setIsFileListModal] = useState(false);
	const [isFileListError, setIsFileListError] = useState(false);

	const [files, setFiles] = useState<IFiles[]>([]);
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const getFiles = async () => {
		try {
			const response = await api.getFilesFromLink(uuid);
			setFiles(response.data.data)
		} catch (e) {
			message.error('Неправильная ссылка для клиента');
		}
	};

	const downloadFile = async () => {
		try {
			setIsFileDownload(true);
			const getLink = await api.downloadFiles(uuid)

			const arrayBuffer = getLink.data;
			const downloadUrl = window.URL.createObjectURL(new Blob([arrayBuffer]));

			const downloadLink = document.createElement('a');
			downloadLink.href = downloadUrl;
			downloadLink.setAttribute('download', `${uuid}.zip`);
			document.body.appendChild(downloadLink);
			downloadLink.click();
			downloadLink.remove();
		} catch (e) {
			message.error("Произошла ошибка при скачивании файлов, попробуйте снова.")
		} finally {
			setIsFileDownload(false);
		}
	};

	const props: UploadProps = {
		name: 'file',
		action: `https://mycent.kz/api/file-exchange/link/upload/${uuid}`,
		data: {
			link_uuid: uuid,
		},
		onChange(info) {
			setIsFileListModal(true);
			setFileList(info.fileList);

			if (info.file.status === 'error') {
				setIsFileListError(true);
			}
		},
		showUploadList: false,
		multiple: true,
		accept: '.docx, .pdf, .png, .jpeg, .jpg, .webp, .gif, .tiff, .doc, .xlsx, .xls, .xlsm, .pptx',
		fileList: fileList,
	};

	useEffect(() => {
		getFiles();
	}, []);

	const renderFiles = files.length >= 1 && files.map((file) => {
		return (
			<DocumentItem
				isCheckbox={false}
				fileLink={null}
				main={true}
				key={file.file_uuid}
				title={file.name}
				uuid={file.file_uuid}
			/>
		)
	});

	return (
		<div className="content-file-exchange">
			<div className="container-file-exchange">
				<FileListModal
					isModalOpen={isFileListModal}
					isError={isFileListError}
					handleCancel={async () => {
						setFileList([]);
						setIsFileListModal(false);
						setIsFileListError(false);
						await getFiles();
					}}
					fileList={fileList}
				/>
				<Row className="header-file-exchange">
					<Logo />
				</Row>
				<div className="sector-file-exchange">
					<Row className="d-flex justify-space-between">
						<Title level={4} className="path-category-file-exchange">
							Временное хранилище
						</Title>
						{files.length >= 1 && <Row className="d-flex justify-end mb-5">
							<Button
								loading={isFileDownload}
								className="add-folder-file-exchange"
								size="large"
								onClick={downloadFile}
							>
								Скачать
							</Button>
						</Row>}
					</Row>
					<Row gutter={[24, 24]} className="width">
						{files.length >= 1 ? renderFiles : <Empty className="width" description="Данная ссылка не содержит файлов"/>}
					</Row>
				</div>
				<div className="sector-file-exchange">
					<Dragger
						{...props}
						className="d-flex align-center justify-center"
					>
						<Button className="add-folder-file-exchange">
							<i className="download-icon-file-exchange"/>
							Выберите файл
						</Button>
						<p className="ant-upload-hint">Или перетащите и оставьте</p>
					</Dragger>
				</div>
			</div>
		</div>
	)
};

export default OuterClient;