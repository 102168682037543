import { Button, Menu, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { MenuProps } from 'antd/es/menu';
import './MenuCategories.scss'
import useLibrary from '@hooks/useLibrary';
import useAuth from '@hooks/useAuth';
import { getAccess } from '@modules/OnlineLibrary/helpers/getAccess';

const { Text } = Typography;
type MenuItem = Required<MenuProps>['items'][number];

interface IPropsMenuCategories {
  items: MenuItem[]
  onClickCategory: (e) => void;
  addCategory: () => void;
  deleteCategory: (e) => void;
  editCategory: (e) => void;
}

const MenuCategories = ({ items, onClickCategory, addCategory, deleteCategory, editCategory } : IPropsMenuCategories) => {
  const { setDeleteCategoryId , setEditCategoryId, setIsDocument } = useLibrary();
  const { user } = useAuth();

  const onDeleteCategory = (e, id: string) => {
    setDeleteCategoryId(id);
    setIsDocument(false);
    deleteCategory(e);
  };

  const onEditCategory = (e, id: string) => {
    setEditCategoryId(id);
    editCategory(e);
  }

  const renderButtons = (item) => (
    <>
      {getAccess('update', user) && <Button
        className="edit-button-online-library"
        icon={<i className="edit-icon-online-library"/>}
        onClick={(e) => onEditCategory(e, item.eventKey)}
      />}
      {getAccess('delete', user) && <Button
        className="close-button-online-library"
        icon={<i className="close-icon-online-library"/>}
        onClick={(e) => onDeleteCategory(e, item.eventKey)}
      />}
    </>
  );


  return (
    <div className="sector-online-library">
      {getAccess('create', user) && <Button
        icon={<PlusOutlined/>}
        className="add-category-online-library"
        onClick={() => addCategory()}
      >
        <Text className="button-text-online-library">Добавить папку</Text>
      </Button>}
      <Menu
        className="menu-categories-online-library"
        items={items}
        onClick={(e) => onClickCategory(e)}
        itemIcon={(item) => renderButtons(item)}
      />
    </div>
  )
};

export default MenuCategories;