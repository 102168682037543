import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Typography,
  message,
  Tabs,
  Upload,
  InputNumber,
} from 'antd';

import dayjs from 'dayjs';
import type { UploadProps } from 'antd';
import ClientDocumentTable from './ClientDocumentTable';
import ClientRequisitesTable from './ClientRequisitesTable';
import ClientRelatedTable from './ClientRelatedTable';
const { Option } = Select;
const { Title } = Typography;
function JurClient({
  contractorFullInfo,
  settings,
  form,
  selectContractor,
  searchContractor,
  changeState,
  selectRelatedContractor,
  showRelatedInfo,
  saveContractorInfo,
}) {
  const [docs, setDocs] = useState<any>();
  useEffect(() => {
    if (contractorFullInfo?.isn) {
      if (contractorFullInfo.docno) {
        const data = contractorFullInfo.docs.map((item) => {
          if (item.docno == contractorFullInfo.docno) {
            return { ...item, mainDoc: true };
          } else {
            return { ...item, mainDoc: false };
          }
        });
        const findMain = data.filter((item) => item.mainDoc);
        if (findMain && findMain.length > 0) {
          setDocs(data);
        } else {
          setDocs([
            {
              docno: contractorFullInfo.docno,
              docdate: contractorFullInfo.docdate,
              classisn: +contractorFullInfo.docclassisn,
              classisn_name: contractorFullInfo.docclassisn_name,
              handby: contractorFullInfo.docissuedby,
              mainDoc: true,
            },
            ...data,
          ]);
        }
      } else {
        setDocs(contractorFullInfo.docs);
      }
      const emailData =
        contractorFullInfo.contacts.length > 0
          ? contractorFullInfo.contacts.filter(
              (item) => item.classisn_name == 'E-mail контактный'
            )
          : '';
      const phoneWork =
        contractorFullInfo.contacts.length > 0
          ? contractorFullInfo.contacts.filter(
              (item) => item.classisn_name == 'Телефон рабочий'
            )
          : '';
      const phoneContact =
        contractorFullInfo.contacts.length > 0
          ? contractorFullInfo.contacts.filter(
              (item) => item.classisn_name == 'Телефон контактный'
            )
          : '';
      form.setFieldsValue({
        ...contractorFullInfo,
        bin: contractorFullInfo.iin,
        name: contractorFullInfo.fullname,
        countryisn: +contractorFullInfo.countryisn,
        subjisn: contractorFullInfo.isn,
        economic_sector: +contractorFullInfo.economicisn,
        okvdisn: +contractorFullInfo.okvdisn,
        docdate: contractorFullInfo.docdate
          ? dayjs(contractorFullInfo.docdate, 'DD-MM-YYYY')
          : '',
        address:
          contractorFullInfo.address.length > 0
            ? contractorFullInfo.address[0].address
            : '',
        phone2: phoneWork[0]?.phone.split('+7')[1],
        phone3: phoneContact[0]?.phone.split('+7')[1],
        email: emailData && emailData.length > 0 ? emailData[0].phone : '',
      });
    }
  }, [contractorFullInfo]);

  return (
    <>
      <Form
        name="jurContractor"
        form={form}
        onFinish={saveContractorInfo}
        className="c-form"
        layout="vertical"
      >
        <Row gutter={8} className="mt-5">
          <Form.Item name="juridical" hidden noStyle>
            <Input disabled />
          </Form.Item>
          <Form.Item name="subjisn" hidden noStyle>
            <Input disabled />
          </Form.Item>
          <Col span={8}>
            <Form.Item name="bin" label="БИН">
              <Input
                allowClear
                type={'number'}
                style={{ width: '100%' }}
                placeholder="БИН"
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="name" label="Наименование">
              <Input placeholder="Наименование" allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="economic_sector" label="Сектор экономики">
              <Select
                style={{ width: '100%' }}
                placeholder="Сектор экономики"
                filterSort={(optionA, optionB) =>
                  (optionA?.full_name ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.full_name ?? '').toLowerCase())
                }
                fieldNames={{ label: 'full_name', value: 'foreign_id' }}
                options={settings?.economicisn.children}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="okvdisn" label="Вид экономической деятельности">
              <Select
                style={{ width: '100%' }}
                placeholder="Вид экономической деятельности"
                filterSort={(optionA, optionB) =>
                  (optionA?.full_name ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.full_name ?? '').toLowerCase())
                }
                fieldNames={{ label: 'full_name', value: 'foreign_id' }}
                options={settings?.okvdisn.children}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="countryisn" label="Страна">
              <Select
                style={{ width: '100%' }}
                placeholder="Страна"
                fieldNames={{ label: 'full_name', value: 'foreign_id' }}
                options={settings?.countryisn.children}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="address" label="Адрес">
              <Input placeholder="Адрес" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="phone2" label="Номер телефона">
              <Input
                addonBefore="+7"
                maxLength={10}
                minLength={10}
                placeholder="Рабочий номер телефона"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="phone3" label="Контактный телефона">
              <Input
                addonBefore="+7"
                maxLength={10}
                minLength={10}
                placeholder="Контактный телефон"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="email" label="E-mail">
              <Input placeholder="E-mail" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {contractorFullInfo &&
      contractorFullInfo.isn &&
      !selectRelatedContractor ? (
        <Tabs
          defaultActiveKey={'1'}
          type="card"
          style={{ marginBottom: 32 }}
          items={[
            {
              label: 'Документы',
              key: '1',
              children: (
                <ClientDocumentTable
                  docs={docs}
                  settings={settings}
                  subjisn={contractorFullInfo ? contractorFullInfo.isn : []}
                  juridical={'Y'}
                  selectContractor={selectContractor}
                  isRelated={false}
                />
              ),
            },
            {
              label: 'Банковские реквизиты',
              key: '2',
              children: (
                <ClientRequisitesTable
                  bank={contractorFullInfo ? contractorFullInfo.bank : []}
                  settings={settings}
                />
              ),
            },
            {
              label: 'Связанное лицо',
              key: '3',
              children: (
                <ClientRelatedTable
                  searchContractor={searchContractor}
                  subhead={contractorFullInfo ? contractorFullInfo.subhead : []}
                  settings={settings}
                  subjisn={contractorFullInfo ? contractorFullInfo.isn : []}
                  changeState={changeState}
                  selectRelatedContractor={selectRelatedContractor}
                  selectContractor={selectContractor}
                  showRelatedInfo={showRelatedInfo}
                />
              ),
            },
          ]}
        />
      ) : (
        <Tabs
          defaultActiveKey={'3'}
          type="card"
          style={{ marginBottom: 32 }}
          items={[
            {
              label: 'Документы',
              key: '1',
              children: (
                <ClientDocumentTable
                  docs={contractorFullInfo ? contractorFullInfo.docs : []}
                  settings={settings}
                  subjisn={contractorFullInfo ? contractorFullInfo.isn : []}
                  juridical={'Y'}
                  selectContractor={selectContractor}
                  isRelated={false}
                />
              ),
            },
            {
              label: 'Банковские реквизиты',
              key: '2',
              children: (
                <ClientRequisitesTable
                  bank={contractorFullInfo ? contractorFullInfo.bank : []}
                  settings={settings}
                />
              ),
            },
            {
              label: 'Связанное лицо',
              key: '3',
              children: (
                <ClientRelatedTable
                  searchContractor={searchContractor}
                  subhead={contractorFullInfo ? contractorFullInfo.subhead : []}
                  settings={settings}
                  subjisn={contractorFullInfo ? contractorFullInfo.isn : []}
                  changeState={changeState}
                  selectRelatedContractor={selectRelatedContractor}
                  selectContractor={selectContractor}
                  showRelatedInfo={showRelatedInfo}
                />
              ),
            },
          ]}
        />
      )}
      <Button
        style={{ width: '100%' }}
        type="primary"
        form="jurContractor"
        htmlType="submit"
        // onClick={saveContractorInfo}
      >
        Сохранить
      </Button>
    </>
  );
}
export default JurClient;
