import React from 'react';
import { DatePicker } from 'antd';
import './CDatePicker.scss';

const CDatePicker = (props) => {
  return (
    <DatePicker {...props} className={`c-datepicker ${props.className || ''}`} />
  );
};

export default CDatePicker;
