import './FileExchange.scss';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  message, notification,
  Row,
  Space,
  Statistic,
  Typography,
  Upload, UploadFile,
  UploadProps,
} from 'antd';
import Logo from '@modules/common/Logo';
import React, { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import FolderItem from '@modules/FileExchange/FolderItem/FolderItem';
import DocumentItem from '@modules/FileExchange/DocumentItem/DocumentItem';
import AddModalFolder from '@modules/FileExchange/AddModalFolder/AddModalFolder';
import { ICategory } from '@modules/OnlineLibrary/interfaces/ICategory';
import { IFileLibrary } from '@modules/OnlineLibrary/interfaces/IFileLibrary';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import DeleteModalFolder from '@modules/FileExchange/DeleteModalFolder/DeleteModalFolder';
import useAuth from '@hooks/useAuth';
import Cookies from 'js-cookie';
import api from '@modules/FileExchange/services/api';
import { CountdownProps } from 'antd/es/statistic/Countdown';
import FileListModal from '@modules/FileExchange/FileListModal/FileListModal';
import axios from 'axios';

const { Dragger } = Upload;
const { Countdown } = Statistic;


interface IConfig {
  delete_after: number;
  max_directory_size: number;
  max_file_size: number;
}

const FileExchange = () => {
  const { user } = useAuth();
  const hasAdminPermission = Array.isArray(user.data.access.files_exchanger) &&
      user.data.access.files_exchanger.some(permission => permission.slug === "admin");

  const [isAddModal, setIsAddModal] = useState(false);
  const [isFileListModal, setIsFileListModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isFileListError, setIsFileListError] = useState(false);
  const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [files, setFiles] = useState<IFileLibrary[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [config, setConfig] = useState<IConfig[]>([]);

  const [checkedFolderList, setCheckedFolderList] = useState<CheckboxValueType[]>([]);
  const [checkedFilesList, setCheckedFilesList] = useState<CheckboxValueType[]>([]);

  const [currentFolder, setCurrentFolder] = useState<number | null>(null);
  const [currentFolderName, setCurrenFolderName] = useState<string>('');
  const [currentCountdown, setCurrentCountdown] =
    useState<{ file_uuid: string, to_be_deleted_on: string } | null | undefined>(null);

  const getFiles = async (id: number | string | null) => {
    try {
      const responseFiles = await api.getFiles(id, user?.data?.id);

      setFiles(responseFiles.data.data);
    } catch (e) {
      message.error('Не удалось получить файлы файлообменика');
    }
  };

  const getFolders = async (id: number | string | null) => {
    try {
      const responseMainSubCategories = await api.getFolders(id);

      setCategories(responseMainSubCategories.data.data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        switch (error.response.status) {
          case 400:
            notification.error({
              message: 'Ошибка',
              description: 'У вас нет доступа к данному разделу',
            });
            break;
          default:
            message.error(`Не удалось загрузить директории файлообменика`);
        }
      }
    }
  };

  const deadline = Date.parse(currentCountdown?.to_be_deleted_on as string)

  const onFinish: CountdownProps['onFinish'] = () => {
    window.location.reload();
  };

  const token = Cookies.get('token');
  const props: UploadProps = {
    name: 'file',
    action: `https://mycent.kz/api/file-exchange/files/upload?user_id=${user?.data?.id}`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {
      directory_id: currentFolder,
    },
    onChange(info) {
      setIsFileListModal(true);
      setFileList(info.fileList);

      if (info.file.status === 'error') {
        setIsFileListError(true);
      }
    },
    showUploadList: false,
    multiple: true,
    accept: '.docx, .pdf, .png, .jpeg, .jpg, .webp, .gif, .tiff, .doc, .xlsx, .xls, .xlsm, .pptx',
    fileList: fileList,
  };

  const onChangeFolder = (list: CheckboxValueType[]) => {
    setCheckedFolderList(list);
  };

  const onChangeFiles = (list: CheckboxValueType[]) => {
    setCheckedFilesList(list);
  };

  const deleteFolder = async () => {
    setLoadingButtonDelete(true);
    try {
      for (const id of checkedFolderList) {
        try {
          await api.deleteFolder(id, user?.data?.id)
        } catch (error) {
          message.error(`Возникла ошибка с удалением папки`);
        }
      }

      await getFolders(user?.data?.id);
      message.success('Все выбранные директории были успешно удалены');
    } catch (error) {
      message.error('Произошла ошибка при обновлении списка директории');
    } finally {
      setLoadingButtonDelete(false);
      setIsDeleteModal(false);
      setCheckedFolderList([]);
    }
  };

  const deleteFile = async () => {
    setLoadingButtonDelete(true);
    try {
      for (const id of checkedFilesList) {
        try {
          await api.deleteFileExchange(user?.data?.id, id);
        } catch (error) {
          message.error(`Возникла ошибка с удалением файла с id ${id}`);
        }
      }
      await getFiles(currentFolder);
      message.success('Все выбранные файлы были успешно удалены');
    } catch (error) {
      message.error('Произошла ошибка при обновлении списка файлов');
    } finally {
      setLoadingButtonDelete(false);
      setIsDeleteModal(false);
      setCheckedFilesList([]);
    }
  };

  const createLink = async (userId: number | string) => {
    try {
      await api.createLink(userId, {directory_id: currentFolder});
      message.info('Вы успешно создали ссылку на скачивание файлов');
      setCurrentCountdown(null);
      setFiles([]);
      setCurrentFolder(null);
      await getFolders(user?.data?.id);
    } catch (e) {
      message.error('Не удалось создать ссылку на директорию');
    }
  };

  const getConfig = async () => {
    const response = await api.getConfig();
    setConfig(response.data);
  };

  const renderFolders =
    categories.length > 0 &&
    categories.map((folder) => {
      return (
        <FolderItem
          title={folder.name}
          key={folder.id}
          id={folder.id}
          onClickFolder={async () => {
            await getFiles(folder.id);
            setCurrentCountdown(folder.file_link)
            setCurrenFolderName(folder.name);
            setCurrentFolder(folder.id);
            setCheckedFolderList([]);
          }}
        />
      );
    });

  const renderFiles =
    files.length > 0 &&
    files.map((file) => {
      return (
        <DocumentItem
          isCheckbox={true}
          fileLink={currentCountdown}
          main={true}
          key={file.file_uuid}
          title={file.name}
          uuid={file.file_uuid}
        />
      );
    });

  useEffect(() => {
    getFolders(user?.data?.id);
    getConfig();
  }, []);

  return (
    <div className="content-file-exchange">
      <div className="container-file-exchange">
        <FileListModal
          isModalOpen={isFileListModal}
          isError={isFileListError}
          handleCancel={async () => {
            setFileList([]);
            setIsFileListModal(false);
            setIsFileListError(false);
            await getFiles(currentFolder);
          }}
          fileList={fileList}
        />
        <AddModalFolder
          isModalOpen={isAddModal}
          onCancel={() => setIsAddModal(false)}
          getCategories={() => getFolders(user?.data?.id)}
        />
        <DeleteModalFolder
          isLoading={loadingButtonDelete}
          isModalOpen={isDeleteModal}
          onCancel={() => setIsDeleteModal(false)}
          deleteFolder={checkedFolderList.length >= 1 ? deleteFolder : deleteFile}
        />
        <Row className="header-file-exchange">
          <Logo />
        </Row>
        <Row className="d-flex align-start justify-space-between">
          <Col span={24}>
            <div className="sector-file-exchange">
              <Row className="d-flex justify-space-between">
                <Title level={4} className="path-category-file-exchange">
                  Файлообменник
                </Title>
                <Row>
                  <Space>
                    {currentCountdown && <Countdown value={deadline} onFinish={onFinish}/>}
                    {currentFolder !== null &&
                      <Button
                        className="cancel-folder-file-exchange"
                        size="large"
                        onClick={() => {
                          setCheckedFilesList([]);
                          setCurrentCountdown(null);
                          setFiles([]);
                          setCurrentFolder(null);
                          setIsCopied(false);
                        }}
                      >
                        Вернуться к папкам
                      </Button>
                    }
                    {(checkedFolderList.length >= 1 ||
                      checkedFilesList.length >= 1) && (
                      <>
                        <Button
                          className="cancel-folder-file-exchange"
                          size="large"
                          onClick={() =>
                            checkedFolderList.length >= 1
                              ? setCheckedFolderList([])
                              : setCheckedFilesList([])
                          }
                        >
                          Отменить
                        </Button>
                        <Button
                          className="delete-folder-file-exchange"
                          size="large"
                          onClick={() => setIsDeleteModal(true)}
                        >
                          <i className="delete-icon-file-exchange"></i>
                          Удалить
                        </Button>
                      </>
                    )}
                    {hasAdminPermission && <Button
                        className="add-folder-file-exchange"
                        size="large"
                        href="/file-exchange/admin-panel"
                    >
                      Админ. панель
                    </Button>}
                    {currentFolder === null && <Button
                      className="add-folder-file-exchange"
                      size="large"
                      onClick={() => setIsAddModal(true)}
                    >
                      + Создать
                    </Button>}
                    {currentFolder !== null && !currentCountdown && (
                      <Button
                        className="add-folder-file-exchange"
                        size="large"
                        onClick={() => createLink(user?.data?.id)}
                      >
                        Создать ссылку
                      </Button>
                    )}
                    {currentCountdown && (
                      <Button
                        className="add-folder-file-exchange"
                        size="large"
                        onClick={() => {
                          setIsCopied(true);
                          navigator.clipboard.writeText(`https://mycent.kz/file-exchange/${currentCountdown?.file_uuid}`);
                        }}
                      >
                        {isCopied ? "Ссылка скопирована" : "Скопировать ссылку"}
                      </Button>
                    )}
                  </Space>
                </Row>
              </Row>
              {currentFolder === null &&
                <>
                  <Checkbox.Group
                    onChange={onChangeFolder}
                    value={checkedFolderList}
                    className="width mb-5"
                  >
                    <Row gutter={[24, 24]} className="mt-5 width">
                      {renderFolders}
                    </Row>
                  </Checkbox.Group>
                  {!categories.length && <Empty description="Добавьте папки, чтобы продолжить работу с файлообмеником"/>}
                </>
              }
              {currentFolder !== null &&
                (<>
                  <Divider orientation="left">{currentFolderName}</Divider>
                  <Checkbox.Group
                    onChange={onChangeFiles}
                    value={checkedFilesList}
                    className="width"
                  >
                    <Row gutter={[24, 24]} className="width">
                      {renderFiles}
                    </Row>
                  </Checkbox.Group>
                  {!files.length && <Empty description="Данная папка пуста добавьте файлы."/>}
                </>)
              }
            </div>
            {currentFolder !== null &&
              (<div className="sector-file-exchange">
                <Dragger
                  {...props}
                  className="d-flex align-center justify-center"
                >
                  <Button className="add-folder-file-exchange">
                    <i className="download-icon-file-exchange"/>
                    Выберите файл
                  </Button>
                  <p className="ant-upload-hint">Или перетащите и оставьте</p>
                </Dragger>
              </div>)}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FileExchange;
