import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Carousel, Empty, Typography } from 'antd';

import BdayList from './BdayList';
import { IUsersData } from './type';

const { Paragraph } = Typography;

interface BdayGroupProps {
  users: IUsersData[];
}

function BdayGroup({ users = [] }: BdayGroupProps) {
  if (!users.length) {
    return <Empty />;
  }

  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );

  return (
    <Carousel
      effect="scrollx"
      dots={false}
      arrows={true}
      infinite={false}
      prevArrow={
        <SlickButtonFix currentSlide slideCount>
          <ArrowLeftOutlined />
        </SlickButtonFix>
      }
      nextArrow={
        <SlickButtonFix currentSlide slideCount>
          <ArrowRightOutlined />
        </SlickButtonFix>
      }
    >
      {users.map((group) => (
        <div key={group.date} role='group_date'>
          <Paragraph style={{ textAlign: 'center' }} className="mb-5">
            {group.date}
          </Paragraph>

          <BdayList userList={group.userList} />
        </div>
      ))}
    </Carousel>
  );
}

export default BdayGroup;
