import axios from '../axios';

const endpoints = {
  registration: (data) => axios.post('/reset', data),
  login: (data) => axios.post('/login', data),
  resetPassword: (data) => axios.post('/reset-by-email', data),
  getProfile: () => axios.get('/user/info'),
  deleteAvatar: (avatarId) => axios.delete(`/avatar/${avatarId}`),
  updateAvatar: (data) =>
    axios.post('/avatar', data, {
      headers: { 'Content-type': 'multipart/form-data' },
    }),
  getCentcoin: ({
    id,
    params,
  }: {
    id: number;
    params: { start: string; end: string };
  }) => axios.get(`/centcoin/${id}`, { params }),
  getCompanyList: () => axios.get('/company/list'),
  logout: () => axios.post('/logout'),
  getSellerInfo: (userId) => axios.get(`/seller/info?user_id=${userId}`),
  getSellerRaitingInfo: (params) => axios.get('/seller/rating', { params }),
};

export default endpoints;
