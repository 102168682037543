import { IRowColumnEditor } from '@modules/Edo/types';
import { DatePicker, Form } from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
interface IDependentDatePicker {
  settings: IRowColumnEditor;
  name: number;
  isDisabledForm: boolean;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}

function DependentDatePicker(props: IDependentDatePicker) {
  const {
    settings,
    name,
    isDisabledForm,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;

  const form = Form.useFormInstance();
  const propertyValue = Form.useWatch(['row_column_editor', name], form);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  /**
   * Следим за изменениями полей "Дата, с" и "Дата, по"
   */
  useEffect(() => {
    if (propertyValue) {
      const formValues = Object.values(propertyValue);

      formValues.map((element: any) => {
        if (
          element.full_name &&
          element.full_name.toLowerCase() === 'дата, с'
        ) {
          setDateFrom(element?.date_value || null);
        } else if (
          element.full_name &&
          element.full_name.toLowerCase() === 'дата, по'
        ) {
          setDateTo(element?.date_value || null);
        }
      });
    }
  }, [propertyValue]);

  /**
   * Блокируем выбор даты в зависимости от выбранных дат
   * @param { Object } current - дата компонента datepicker для сравнения с заданной датой, возвращает true/false
   * и блокирует или разблокирует дату в календаре
   * @returns { Boolean }
   */
  const disabledDate = (current) => {
    if (settings.field_name.toLowerCase() === 'дата, с') {
      return dayjs(dateTo).startOf('day') < dayjs(current).startOf('day');
    }
    return dayjs(dateFrom).startOf('day') > dayjs(current).startOf('day');
  };

  /**
   * Если дату сбросили нажатием на крестик - сбрасываем значения полей для пересчета доступных дат для выбора
   * @param value - выбранная дата в формате Dayjs или null
   */
  const triggerChange = (value) => {
    if (!value) {
      const { row_column_editor } = form.getFieldsValue();
      Object.assign(row_column_editor[name][`id-${settings.id}`], {
        date_value: null,
      });
      form.setFieldsValue({ row_column_editor });
    } else {
      if (isDisabledStatus && isAdmin && onChangeByTd) {
        onChangeByTd(settings.id, name);
      }
    }
  };
  return (
    <Form.Item
      label={settings.field_name}
      name={[name, `id-${settings.id}`, 'date_value']}
      rules={[{ required: settings.required }]}
    >
      <DatePicker
        className="dateMask"
        style={{ width: '100%' }}
        format="DD.MM.YYYY"
        disabledDate={(current) => disabledDate(current)}
        onChange={(e) => triggerChange(e)}
        disabled={isDisabledForm ? isDisabledForm : settings.readonly}
        onOpenChange={() => {
          const selector = document.querySelectorAll('.ant-picker-today-btn');
          selector.forEach((btn, index) => {
            const id = index + 1;
            if (!btn.hasAttribute('id'))
              btn.setAttribute('id', `date-today-${id.toString()}`);
          });
        }}
      />
    </Form.Item>
  );
}

export default DependentDatePicker;
