import { Modal, notification, Select, Input, Form, Button } from 'antd';
import { useImmerReducer } from 'use-immer';
import axios from 'axios';
import api from '@services/api/index';
import React, { useState } from 'react';
import './EdoDismissalModal.scss';
interface Action {
  type:
    | 'setDocument'
    | 'loading'
    | 'resetState'
    | 'confirmLoading'
    | 'showSuccessMessage';
  payload?: any;
}
interface State {
  // document: Data | null;
  isLoading: boolean;
  isAllowSend: boolean;
  isConfirmLoading: boolean;
  isSuccessSend: boolean;
}

const initialState: State = {
  // document: null,
  isLoading: true,
  isAllowSend: false,
  isConfirmLoading: false,
  isSuccessSend: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setDocument':
      // state.document = action.payload;
      state.isAllowSend = true;
      break;
    case 'loading':
      state.isLoading = action.payload;
      break;
    case 'resetState':
      // state.document = null;
      state.isLoading = true;
      state.isAllowSend = false;
      state.isConfirmLoading = false;
      state.isSuccessSend = false;
      break;
    case 'confirmLoading':
      state.isConfirmLoading = action.payload;
      break;
    case 'showSuccessMessage':
      state.isSuccessSend = true;
      break;
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};
interface EdoDismissalModalProps {
  visible: boolean;
  onCancel: () => void;
  onSend: () => void;
  data: any;
  documentID: number | undefined;
}
function EdoDismissalModal({
  visible,
  onCancel = () => {},
  onSend = () => {},
  data = [],
  documentID,
}: EdoDismissalModalProps) {

  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const [remarkArray, setRemarkArray] = useState<any[]>([]);
  const [form] = Form.useForm();

  const handleOk = () => {
    try {
      dispatch({ type: 'confirmLoading', payload: true });
      // TODO отправить запрос на бэк, для сохранения анкеты
      // await api.sendList({
      //   status_id: 2522, // на подписи
      //   document_id: documentID,
      // });
      dispatch({ type: 'showSuccessMessage' });
      onSend();

      // onSend();
      // getCountAgreement();
    } catch (error) {
      let message;

      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      dispatch({ type: 'confirmLoading', payload: false });
    }
  };
  const onChange = (value: string, index: number) => {
    const valueInfo = data[index].dicti[0].children.filter(
      (item) => item.id == value
    )[0];
    const findData = remarkArray.filter((item) => item.id == index);

    if (valueInfo && valueInfo.text_code == 'TEXT') {
      if (findData.length == 0) {
        setRemarkArray([
          ...remarkArray,
          {
            val: value,
            id: index,
          },
        ]);
      } else {
        let copy = JSON.parse(JSON.stringify(remarkArray));
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].id == index) {
            copy.splice(i, 1, {
              val: value,
              id: index,
            });
          }
        }
      }
    } else {
      if (findData.length > 0) {
        let copy = JSON.parse(JSON.stringify(remarkArray));
        let deleteIndex;

        for (let i = 0; i < copy.length; i++) {
          if (copy[i].id == index) {
            deleteIndex = i;
          }
        }
        copy.splice(deleteIndex, 1);
        setRemarkArray(copy);
      }
    }
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };
  const sendData = async (values) => {
    const sendData: string[] = [];
    for (let key in values) {
      sendData.push(values[key]);
    }
    try {
      const answer = await api.edo.addSurveyData(sendData);
      if (answer.status == 200) {
        onCancel();
        onSend();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Modal
      title={data.filter((question) => question.id == 1)[0].description}
      open={visible}
      // onOk={handleOk}
      onCancel={onCancel}
      footer={[]}
      width={1100}
    >
      <Form form={form} layout="vertical" name="survey" onFinish={sendData}>
        {data
          ? data.map((question, index) => {
              if (question.id != 1) {
                return (
                  <React.Fragment key={`question-${index}`}>
                    <Form.Item
                      label={index + 1 + '. ' + question.dicti[0].full_name}
                      name={[`question-${question.id}`, 'num_value']}
                      rules={[{ required: true }]}
                    >
                      {question.dicti[0].text_code == null && (
                        <Select
                          showSearch
                          placeholder="Выберите ответ"
                          optionFilterProp="children"
                          onChange={(value) => onChange(value, index)}
                          onSearch={onSearch}
                          style={{ width: '100%' }}
                          options={question.dicti[0].children.map((item) => {
                            return { value: item.id, label: item.full_name };
                          })}
                        />
                      )}
                    </Form.Item>

                    <Form.Item
                      label={'Примечание'}
                      name={[`question-${question.id}`, 'char_value']}
                      required
                      hidden={
                        remarkArray.filter((val) => val.id == index).length <= 0
                      }
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={[`question-${question.id}`, 'document_id']}
                      initialValue={documentID}
                      hidden
                      noStyle
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      name={[`question-${question.id}`, 'editor_id']}
                      initialValue={question.id}
                      hidden
                      noStyle
                    >
                      <Input disabled />
                    </Form.Item>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })
          : ''}
        <Button
          htmlType="submit"
          type="primary"
          // loading={isLoading || isLoadingClient}
        >
          Сохранить
        </Button>
      </Form>
    </Modal>
  );
}

export default EdoDismissalModal;
