import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import useQuotation from '../hooks/useQuotation';
import useQuotationJournal from '../hooks/useQuotationJournal';
import { TypeAction } from '../providers/DateReducer';

function SetupFieldsJournal() {
  const { state } = useQuotationJournal();

  const { dispatchDate } = useQuotation();

  const form = Form.useFormInstance();

  useEffect(() => {
    if (state.agrDate.user_id !== 0) {
      const {
        date_beg,
        date_end,
        date_sign,
        status_id,
        curr_id,
        user_id,
        remark,
      } = state.agrDate;
      setFieldsValueMethod(TypeAction.CURRENCY, curr_id);
      setFieldsValueMethod(TypeAction.DATE_BEG, dayjs(date_beg));
      setFieldsValueMethod(TypeAction.DATE_END, dayjs(date_end));
      setFieldsValueMethod(TypeAction.DATE_SIGN, dayjs(date_sign));
      setFieldsValueMethod(TypeAction.STATUS_ISN, status_id);
      setFieldsValueMethod(TypeAction.REMARK, remark);
      setFieldsValueMethod(TypeAction.USER_ISN, user_id);

      dispatchDate({
        type: TypeAction.STATUS_ISN,
        payload: status_id,
      });
    }
  }, [state.agrDate]);

  function setFieldsValueMethod(fieldName: string, value: number | string | Dayjs) {
    form.setFieldsValue({
      [fieldName]: value,
    });
  }

  return null;
}

export default SetupFieldsJournal;
