import { notification, Card, Row, Col, Typography, Input, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';

type edsDataState = {
  id: number;
  cert: string;
  fio: string;
  iin: string;
  tspDate: string;
};

const { Title } = Typography;

function EdsPage({ title }) {
  const [edsData, setEdsData] = useState<edsDataState[]>([]);

  const { id } = useParams();

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    if (id === undefined || id === null || id === '') return;
    (async function () {
      try {
        const { data } = await api.agreement.getEdsInfo(id);
        setEdsData(data?.data);
        if (data?.data.length === 0) {
          notification.info({
            message: 'Уведомление',
            description: 'По данному документу данные не найдены.',
            duration: 10,
          });
        }
      } catch {
        notification.info({
          message: 'Уведомление',
          description: 'Неправильные данные для получения',
        });
      }
    })();
  }, [id]);

  return (
    <>
      <Row
        gutter={24}
        style={{ margin: 'auto', width: '80%', marginTop: 50 }}
        justify="center"
      >
        <Card
          style={{
            width: 1280,
            height: 100,
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <Title
            style={{
              color: '#0057FF',
              marginTop: 10,
              marginBottom: 20,
            }}
            level={2}
          >
            Mycent.kz
          </Title>
        </Card>
        <Card
          style={{
            width: 1280,
            height: 900,
            marginTop: 20,
            paddingLeft: 50,
            marginBottom: 100,
            paddingRight: 50,
          }}
        >
          <Title
            style={{
              color: '#0057FF',
              marginTop: 40,
              marginBottom: 20,
            }}
            level={4}
          >
            Подписанты со стороны Centras Insurance
          </Title>
          {edsData.length > 0 &&
            edsData.map((item, index) => (
              <React.Fragment key={item.id}>
                <label>Подписант №{++index}</label>
                <br />
                <span>{item.fio}</span>
                <span style={{ float: 'right' }}>
                  Дата подписания: {item.tspDate}
                </span>
                <br />
                <span>ИИН: {item.iin}</span>
                <span style={{ float: 'right' }}>
                  Срок действия ключа: {item.cert}
                </span>
                <Divider />
              </React.Fragment>
            ))}
        </Card>
      </Row>
    </>
  );
}

export default EdsPage;
