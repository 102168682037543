import Title from 'antd/es/typography/Title';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { IRequestData } from '@modules/OnlineLibrary/interfaces/IRequestData';
import useLibrary from '@hooks/useLibrary';
import useAuth from '@hooks/useAuth';
import api from '@modules/OnlineLibrary/services/api';

interface IPropsEditModal {
  isModalOpen: boolean;
  onCancel: () => void;
  editCategory: (id: string, data: IRequestData) => void;
}

const EditCategoryModal = ({ isModalOpen, onCancel, editCategory }: IPropsEditModal) => {
  const { editCategoryId } = useLibrary();
  const { user } = useAuth();
  const [editForm] = Form.useForm();
  const [currentFolder, setCurrentFolder] = useState<IRequestData>({
    name: '',
    description: 'test',
    company_id: -1,
    parent_id: '',
    created_by: 1,
  });

  const onSubmit = () => {
    const data = {
      name: editForm.getFieldValue('folder-name'),
      description: 'test',
      company_id: -1,
      parent_id: currentFolder.parent_id,
      updated_by: user?.data?.id,
    };

    editCategory(editCategoryId, data);
    onCancel();
  };

  const getCurrentCategory = async () => {
    try {
      const response = await api.getCategory(editCategoryId);
      setCurrentFolder(response.data);
      editForm.setFieldValue('folder-name', response.data.name);
    } catch (e) {
      message.error('Что-то пошло не так');
    }
  }

  useEffect(() => {
    if(editCategoryId) getCurrentCategory();
  }, [editCategoryId]);


  return (
    <Modal
      title={<Title level={4}>Редактирование названия папки</Title>}
      open={isModalOpen}
      footer={null}
      onCancel={onCancel}
      className="add-modal-online-library"
      centered
    >
      <Form form={editForm} onFinish={onSubmit} layout="vertical">
        <Row>
          <Col span={16}>
            <Form.Item label={<Title level={5}>Название папки</Title>} name="folder-name">
              <Input placeholder="Введите название папки" size="large"/>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex align-end justify-end">
            <Form.Item>
              <Button
                htmlType="submit"
                block
                type="primary"
                size="large"
                className="create-button-online-library"
              >
                Редактировать
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export default EditCategoryModal;