import axios from '@services/api/axios';

interface IGetFullQuotation {
  user_isn: number;
  quotation_id: string | undefined;
}

const endpoints = {
  getAgrIsn: (quotationId) => axios.get('/agr-isn/' + quotationId),

  getAgrRole: (agrIsn) => axios.get('/role/' + agrIsn),

  getAgrCargo: (agrIsn) => axios.get('/agr-cargo/' + agrIsn),

  getFullQuotationJournal: (values: IGetFullQuotation) =>
    axios.post('quotation/update', values),

  getAgreementData: (agrIsn: number | undefined, userForeignId: number) =>
    axios.post('/quotation/show/agr/doc', {
      agr_isn: agrIsn,
      user_isn: userForeignId,
    }),
};

export default endpoints;
