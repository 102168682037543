import { Button, Modal, Select, Divider, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
interface DataType {
  key: string;
  full_name: string;
  birth_date: string;
  id: number;
  isn: number;
}

function ResultClientModal({
  contractorList,
  handleCancel,
  selectContractor,
  isLoading,
  createContractor,
  from,
}) {
  const columns: ColumnsType<DataType> = [
    {
      title: 'ФИО/Наименование',
      dataIndex: 'FULLNAME',
      key: 'full_name',
      width: '35%',
    },
    {
      title: 'Дата рождения',
      dataIndex: 'BIRTHDAY',
      key: 'birth_date',
      width: '25%',
    },
    {
      title: ' ИИН / БИН',
      dataIndex: 'IIN',
      key: 'id',
    },
    {
      title: '',
      key: 'action',
      width: '15%',
      render: (_, record) => (
        <Button onClick={() => selectContractor(record, from)} type="primary">
          Открыть
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title={
        <span
          style={{
            color: '#7449ef',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          Выберите контрагента
        </span>
      }
      open={true}
      onCancel={handleCancel}
      width="1000px"
      footer={[<></>]}
    >
      <CustomLoader spinning={isLoading}>
        <Divider />
        <Button onClick={createContractor}>Создать контрагента</Button>

        <Table
          className="mt-5"
          columns={columns}
          dataSource={contractorList ? contractorList : []}
        />
      </CustomLoader>
    </Modal>
  );
}
export default ResultClientModal;
