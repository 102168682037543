import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Button, Form, Input, notification } from 'antd';
import MycentLogoWhite from '../../assets/mycent-logo-auth.svg';
import api from '../../services/api';
import useAuth from '../../hooks/useAuth';

import './Registration.scss';
interface RegistrationProps {
  title: string;
}

interface RegistrationResponseInterface {
  access_token: string;
}

const Registration: React.FC<RegistrationProps> = ({ title = 'Mycent.kz' }) => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { email, token, reset } = useParams();

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleSubmitForm = async (data) => {
    setIsLoading(true);

    try {
      const response: AxiosResponse<RegistrationResponseInterface> =
        await api.auth.registration({ ...data, token });
      auth.setToken(response.data.access_token);

      // TODO Попросить переделать бэк, чтобы данные пользователя приходили в первом запросе
      const { data: user } = await api.auth.getProfile();
      auth.setUser(user);
    } catch (error) {
      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="page-registration">
      <div className="page-registration__content">
        <div className="page-registration__welcome">
          <img
            src={MycentLogoWhite}
            alt="mycent"
            className="page-login__logo"
          />
        </div>
        <Form
          name="registration"
          layout="vertical"
          autoComplete="off"
          initialValues={{ username: email }}
          style={{ maxWidth: '400px' }}
          onFinish={handleSubmitForm}
          validateMessages={{ required: 'Обязательное поле' }}
        >
          <Form.Item name="username" rules={[{ required: true }]}>
            <Input
              size="large"
              placeholder="Логин"
              readOnly
              disabled={isLoading}
              className="page-registration__input"
            />
          </Form.Item>
          <Form.Item
            name="password"
            hasFeedback
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (
                    !value ||
                    value.match(
                      /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'Пароль должен состоять из восьми или более символов латинского алфавита, содержать заглавные и строчные буквы, цифры'
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder="Пароль"
              disabled={isLoading}
              className="page-registration__input"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Подтверждение пароля не совпадает')
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder="Подтвердите пароль"
              disabled={isLoading}
              className="page-registration__input"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
              className="page-registration__btn"
            >
              {reset && Number(reset) === 1 ? 'Войти' : 'Зарегистрироваться'}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="page-registration__helpdesk-content">
        <p className="page-registration__copyright">
          &copy; 1997&mdash;2024 АО "СТРАХОВАЯ КОМПАНИЯ "СЕНТРАС ИНШУРАНС"
          <br />
          ВСЕ ПРАВА ЗАЩИЩЕНЫ
        </p>
        <div className="page-registration__helpdesk">
          <span className="page-registration__span">Контакты HelpDesk: </span>
          <a href="tel:+77272597755" className="page-registration__number">
            {' '}
            +7 (727) 259 77 55{' '}
          </a>{' '}
          (вн. 2054, 2107, 2078)
        </div>
      </div>
    </div>
  );
};

export default Registration;