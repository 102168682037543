import { MenuProps } from 'antd/es/menu';
import { Button, Col, Dropdown, message, Row, Tooltip, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import './DocumentItem.scss';
import axios from 'axios';
import useUseful from '@hooks/useUseful';
import { base64toFile } from '@modules/OnlineLibrary/helpers/base64toFile';
import useAuth from '@hooks/useAuth';
import { getAccess } from '@modules/OnlineLibrary/helpers/getAccess';
import { getAccessUseful } from '@modules/UsefulSection/helpers/getAccessUseful';
import api from '@modules/UsefulSection/services/api';

const { Text } = Typography;

interface IPropsDocumentItem {
  title: string;
  uuid: string;
  deleteDocument: (e) => void;
  showInfo: () => void;
  extension: string
}

const DocumentItem = ({ title, uuid, deleteDocument, showInfo, extension }: IPropsDocumentItem) => {
  const { setIsDocument, setCurrentFileUuid } = useUseful();
  const { user } = useAuth();

  const downloadDocument = async (e) => {
    e.stopPropagation();
    try {
      const response = await api.downloadDocumentUseful(uuid);
      const data = response.data.content.slice(2, -1);
      const file = base64toFile(data, response.data.filename, response.headers['content-type']);

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = response.data.filename;
      downloadLink.click();
      URL.revokeObjectURL(downloadLink.href);
    } catch (e) {
      message.error('Не удалось скачать файл');
    }
  };

  const readDocument = async () => {
    try {
      const response = await api.readDocumentUseful(uuid, user?.data?.id)
      const downloadLink = document.createElement('a');
      downloadLink.href = `/read-file-library?uuid=${uuid}&contentType=${response.headers['content-type']}`;
      downloadLink.target = '_blank';
      downloadLink.click();
      URL.revokeObjectURL(downloadLink.href);
    } catch (e) {
      message.error('Не удалось совершить предпросмотр документа');
    }
  };

  const onDeleteDocument = (e) => {
    setIsDocument(true);
    setCurrentFileUuid(uuid);
    deleteDocument(e);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a onClick={(e) => downloadDocument(e)} className="d-flex align-center">
          <i className="download-dropdown-useful"/>
          <Text className="ml-1">Скачать</Text>
        </a>
      ),
    },
    getAccessUseful('create', user) || getAccess('update', user) ? {
      key: '2',
      label: (
        <a
          className="d-flex align-center"
          onClick={(e) => {
            e.stopPropagation();
            setIsDocument(true);
            setCurrentFileUuid(uuid)
            showInfo();
          }}
        >
          <i className="info-dropdown-useful"/>
          <Text className="ml-1">Сведения</Text>
        </a>
      ),
    } : null,
    getAccessUseful('delete', user) ? {
      type: 'divider',
    } : null,
    getAccessUseful('delete', user) ? {
      key: '3',
      label: (
        <a onClick={(e) => onDeleteDocument(e)} className="d-flex align-center">
          <i className="delete-dropdown-useful"/>
          <Text className="ml-1">Удалить</Text>
        </a>
      ),
    } : null,
  ];

  return (
    <Col xs={24} lg={6}>
      <Row className="item-folder-useful dropdown-index" onClick={readDocument}>
        <Row className="d-flex justify-space-between width">
          <Row className="d-flex align-start justify-center">
            <i className="main-document-icon-useful"/>
            <Title level={4} className="filename-useful">{extension}</Title>
          </Row>
          <Dropdown menu={{ items }} placement="bottom">
            <Button className="dropdown-useful" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
              &middot;&middot;&middot;
            </Button>
          </Dropdown>
        </Row>
        <Row className="d-flex justify-space-between width">
          <Tooltip title={title} placement="bottom">
            <Title level={5} className="title-document-useful">{title}</Title>
          </Tooltip>
        </Row>
      </Row>
    </Col>
  )
};

export default DocumentItem;