import QuotationJournalContext from '@contexts/QuotationContext/QuotationJournalContext';
import api from '@modules/Quotation/services';

import { notification } from 'antd';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useImmerReducer } from 'use-immer';
import { Types } from '../hooks/DynamicFieldsAttributes';
import {
  IAgrObjCarGoItem,
  IAgrObjectCarItem,
} from '../Interfaces/MainQuotation/index.interface';
import {
  Action,
  clearJournalState,
  IInitialState,
  initialState,
  reducer,
  TypeAction,
} from './JournalReducer';

interface IQuotationJournalProvider {
  children: React.ReactNode;
}

export type agrClause = {
  agr_isn: number;
  class_isn: number;
  clause_isn: number;
};

export type agrCond = {
  limit_sum: number;
  tariff: number;
  premium_sum: number;
  risk_isn: number;
  date_beg: string;
  date_end: string;
  date_sign: string;
  id_obj: number;
  ins_class_isn: number;
};

export type AgrObj = {
  class_isn: number;
  sub_class_isn: number;
  id_obj: number;
  obj_count: number;
  subj_name: string;
  city_isn: number;
  address: string;
};

export type agrAttribute = {
  attr_isn: number;
  val_d: string;
  val_n: number;
  val_c: string;
  attr_isn_code: Types;
  value: string;
};

export type agrObjectAttribute = {
  attr_isn: number;
  val_d: string;
  val_n: number;
  val_c: string;
  fid: number;
  id_obj: number;
  attr_isn_code: Types;
  value: string;
};

export type passingApplicationDataT = {
  nodename: string;
  flow_statusisn_name: string;
  updatedby_name: string;
  updated: string;
  orderno: string;
  reqtext: string;
  id: string;
  created: string;
  req_statusisn_name: string;
  emplisn_name: string;
  operatorremark: string;
  responsetext: string;
};

export type AgrDateT = {
  readonly date_sign: string;
  readonly date_beg: string;
  readonly date_end: string;
  readonly status_id: number;
  readonly user_id: number;
  readonly curr_id: number;
  readonly remark: string;
  readonly id: number;
  readonly product_id: number;
  readonly product_name: string;
  readonly premium_sum_total: string;
};

export interface IAgreementData {
  ID: string;
  STATUS: string;
  ISSUED: string;
  ISN: string;
}

export interface IMember {
  subj_isn: string;
  subj_short_name: string;
  class_isn: string;
}

export interface ICar extends IAgrObjectCarItem {
  id_obj: number;
  type_isn: number;
  mark_isn: number;
  model_isn: number;
  release_date: string;
  pts_no: string;
  vin: string;
  reg_no: string;
  engine_id: string;
  ext_system_key: string;
  power: number;
  modification: string;
  pts_date: string;
  doc_no: string;
  body_id: string;
  chassis_id: string;
  num_seats: string;
  max_mass: string;
  num_key: string;
  owner_name: string;
  ownership_isn: number;
  country_isn: number;
  color_isn: number;
  caruse_isn: number;
  foreign: 1 | 0;
  category_isn: number;
  probeg: number;
  real_price: number;
  passenger: string;
  use_trailer: 1 | 0;
  use_special_signal: 1 | 0;
}
export interface IJournalData {
  attributes: agrAttribute[];
  members: IMember[];
  objects: AgrObj[];
  attributes_object: agrObjectAttribute[];
  clauses: agrClause[];
  conds: agrCond[];
  card_car: ICar[];
  attributes_under: IAttributesUnder[];
}

export interface IAgrCargo extends IAgrObjCarGoItem {
  id_obj: number;
  date_from: string;
  carrier_name: string;
  expeditor_name: string;
}

export interface IAttributesUnder {
  attr_isn: string;
  val_c: string;
  val_d: string;
  val_n: number;
  attr_isn_code: Types;
  value: string;
}

const QuotationJournalProvider: React.FC<IQuotationJournalProvider> = (
  props
) => {
  const [state, dispatch] = useImmerReducer<IInitialState, Action>(
    reducer,
    initialState
  );

  const fetchAllSections = useCallback(
    async (
      agrIsn: number,
      userForeignId: number,
      journalQuotationId: string | undefined
    ) => {
      try {
        Promise.all([
          // api.quotationJournal.getAgrCargo(agrIsn),
          api.quotationJournal.getAgreementData(agrIsn, userForeignId),
          api.quotation.getPpk(agrIsn),
        ]).then(
          ([
            // agrCargo,
            agreementData,
            ppkData,
          ]) => {
            // if (agrCargo.data) {
            //   dispatch({ type: TypeAction.agrCargo, payload: agrCargo.data });
            // }
            if (agreementData.data?.success === true) {
              dispatch({
                type: TypeAction.agreementData,
                payload: agreementData.data?.data,
              });
            }
            if (Array.isArray(ppkData.data)) {
              notification.info({
                message: 'Уведомление',
                description:
                  'Лист согласования по правилу первой котировки создана. Ожидайте согласования предыдущего куратора',
              });
            }
          }
        );
      } catch (error) {
        let message;
        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }

        notification.info({
          message: 'Ошибка',
          description: message,
        });
      }
    },
    []
  );

  const setJournalData = (values: IJournalData) => {
    if (values) {
      const {
        attributes,
        clauses,
        objects,
        attributes_object,
        conds,
        attributes_under,
        members,
        // TODO CARD CAR REMOVED FROM BACK-END
        // card_car,
      } = values;

      if (attributes.length) {
        dispatch({ type: TypeAction.agrAttribute, payload: attributes });
      }

      if (objects.length) {
        dispatch({ type: TypeAction.agrObject, payload: objects });
      }

      if (clauses.length) {
        dispatch({ type: TypeAction.agrClauseJournal, payload: clauses });
      }

      if (attributes_object.length) {
        dispatch({
          type: TypeAction.agrObjectAttribute,
          payload: attributes_object,
        });
      }

      // TODO CARD CAR REMOVED FROM BACK-END

      // if (card_car.length) {
      //   dispatch({ type: TypeAction.agrCar, payload: card_car });
      // }

      if (conds.length) {
        dispatch({ type: TypeAction.agrCond, payload: conds });
      }

      if (attributes_under.length) {
        dispatch({
          type: TypeAction.attributesUnder,
          payload: attributes_under,
        });
      }

      if (members.length) {
        dispatch({ type: TypeAction.agrRole, payload: members });
      }
    }
  };

  const clearJournalStateMethod = () => {
    clearJournalState({ dispatch });
  };

  const contextValue = useMemo(
    () => ({
      fetchAllSections,
      setJournalData,
      state,
      dispatch,
      clearJournalStateMethod,
    }),
    [fetchAllSections, setJournalData, state, dispatch, clearJournalStateMethod]
  );

  return (
    <QuotationJournalContext.Provider value={contextValue}>
      {props.children}
    </QuotationJournalContext.Provider>
  );
};

export default QuotationJournalProvider;
