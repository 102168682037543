import { notification } from 'antd';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import api from '../services/api';

export const updateForm = async (
  idCandidate: string | undefined,
  recruitingId: number,
  data: any,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  const isFileList = checkFileList(data?.file);
  if (isFileList) {
    updateCandidateFile(
      data?.file,
      idCandidate,
      recruitingId,
      data.candidate_iin,
      setIsLoading
    );
  } else {
    updateCandidate(idCandidate, data, recruitingId, setIsLoading);
  }
};

const checkFileList = (fileList) => {
  if (fileList && fileList.length) return true;
  return false;
};

const updateCandidateFile = async (
  fileList: any,
  idCandidate: string | undefined,
  recruitingId: number,
  candidateIin: string | null,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  const formData = new FormData();
  if (fileList.length && fileList[0]?.originFileObj) {
    formData.append('file', fileList[0]?.originFileObj);
    formData.append('recruiting_id', String(recruitingId));
    formData.append('id', String(idCandidate));

    try {
      setIsLoading(true);
      notification.info({
        message: 'Уведомление',
        description: 'Идет загрузка с сервера, ожидайте...',
        duration: 0,
        key: 'save',
      });
      const { data } = await api.updCanditeFile(formData);
      if (data?.success === true) {
        notification.info({
          message: 'Инфо',
          description: data?.message,
        });
      }
    } finally {
      notification.destroy('save');
      setIsLoading(true);
    }
  } else if (candidateIin !== null) {
    const data = {};
    data['candidate_iin'] = candidateIin;
    data['recruiting_id'] = String(recruitingId);

    try {
      setIsLoading(true);
      notification.info({
        message: 'Уведомление',
        description: 'Идет загрузка с сервера, ожидайте...',
        duration: 0,
        key: 'save',
      });
      const response = await api.updCandidate(idCandidate, data);
      if (response?.data?.success === true) {
        notification.info({
          message: 'Инфо',
          description: response?.data?.message,
        });
      }
    } finally {
      notification.destroy('save');
      setIsLoading(true);
    }
  }
};

const updateCandidate = async (
  idCandidate: string | undefined,
  data: any,
  recruitingId: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  data['recruiting_id'] = recruitingId;
  if (data?.interview_time) {
    data['interview_time'] = dayjs(data.interview_time).format('HH:mm');
  }

  try {
    setIsLoading(true);
    notification.info({
      message: 'Уведомление',
      description: 'Идет загрузка с сервера, ожидайте...',
      duration: 0,
      key: 'save',
    });
    const response = await api.updCandidate(idCandidate, data);
    if (response?.data?.success === true) {
      notification.info({
        message: 'Инфо',
        description: response?.data?.message,
      });
    }
  } finally {
    notification.destroy('save');
    setIsLoading(false);
  }
};
