import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Select, Divider, Tabs, notification } from 'antd';
import JurClient from './Fields/JurClient';
import api from '@modules/Quotation/services';
import dayjs from 'dayjs';
import IndividualClient from './Fields/IndividualClient';
import { boolean } from 'yup';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
interface Props {
  isLoading: boolean;
  handleCancel: any;
  contractorFullInfo: any;
  isJurUser: boolean;
  selectContractor?: any;
  searchContractor?: any;
  changeState: any;
  selectRelatedContractor?: any;
  showRelatedInfo?: any;
  relatedInfo?: any;
  closeRelatedInfo?: any;
}
function AddClientModal({
  isLoading,
  handleCancel,
  contractorFullInfo,
  isJurUser,
  selectContractor,
  searchContractor,
  changeState,
  selectRelatedContractor,
  showRelatedInfo,
  relatedInfo,
  closeRelatedInfo,
}: Props) {
  const [settings, setSettings] = useState();
  const [isLoad, setIsLoad] = useState(boolean);
  const [contractorIndlForm] = Form.useForm();
  const [contractorJurForm] = Form.useForm();
  useEffect(() => {
    (async () => {
      const settings = await api.contractor.getContragentCardSettings();
      setSettings(settings.data.data);
    })();
  }, []);
  const saveContractorInfo = async () => {
    if (!isJurUser || relatedInfo) {
      const { data } = await api.contractor.editContractor({
        ...contractorIndlForm.getFieldsValue(),
        juridical: 'N',
        birthday: dayjs(contractorIndlForm.getFieldsValue().birthday).format(
          'DD.MM.YYYY'
        ),
      });
      if (data.data.SUBJISN) {
        const info = await api.contractor.getContractorByISN(data.data.SUBJISN);

        if (relatedInfo) {
          showRelatedInfo(info.data.data);
        } else {
          selectContractor(info.data.data);
          notification.success({
            message: 'Сохранено',
            description: 'Данные успешно сохранены',
          });
        }
      } else {
        notification.error({
          message: 'Ошибка',
          description: data.data.ERROR,
        });
      }
    } else {
      const { data } = await api.contractor.editContractor({
        ...contractorJurForm.getFieldsValue(),
        birthday: contractorJurForm.getFieldsValue().birthday
          ? dayjs(contractorJurForm.getFieldsValue().birthday).format(
              'DD.MM.YYYY'
            )
          : '',
        juridical: 'Y',
        resident:
          contractorJurForm.getFieldsValue().countryisn === 9515 ? 'Y' : 'N',
        phone2:
          contractorJurForm.getFieldsValue().phone2?.length > 0
            ? '+7' + contractorJurForm.getFieldsValue().phone2
            : '',
        phone3:
          contractorJurForm.getFieldsValue().phone3?.length > 0
            ? '+7' + contractorJurForm.getFieldsValue().phone3
            : '',
        economic_type: contractorJurForm.getFieldsValue().okvdisn,
        country: contractorJurForm.getFieldsValue().countrisn,
        docdate: contractorJurForm.getFieldsValue().docdate
          ? dayjs(contractorJurForm.getFieldsValue().docdate).format(
              'DD.MM.YYYY'
            )
          : '',
      });
      if (data.data.SUBJISN) {
        const info = await api.contractor.getContractorByISN(data.data.SUBJISN);
        selectContractor(info.data.data);
        notification.success({
          message: 'Сохранено',
          description: 'Данные успешно сохранены',
        });
      } else if (data.data.ERROR) {
        notification.error({
          message: 'Ошибка',
          description: data.data.ERROR,
        });
      }
    }
  };

  const handleCancelModal = () => {
    if (relatedInfo) {
      closeRelatedInfo();
    } else {
      handleCancel();
    }
  };

  return (
    <Modal
      title={
        <span
          style={{
            color: '#7449ef',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          Добавить контрагента
        </span>
      }
      open={true}
      onCancel={handleCancelModal}
      width="1000px"
      footer={[]}
    >
      <CustomLoader spinning={isLoading}>
        <Divider />
        {isJurUser && relatedInfo == null ? (
          <JurClient
            settings={settings}
            form={contractorJurForm}
            contractorFullInfo={isJurUser ? contractorFullInfo : {}}
            selectContractor={selectContractor}
            searchContractor={searchContractor}
            changeState={changeState}
            selectRelatedContractor={selectRelatedContractor}
            showRelatedInfo={showRelatedInfo}
            saveContractorInfo={saveContractorInfo}
          />
        ) : (
          <IndividualClient
            settings={settings}
            contractorFullInfo={!isJurUser ? contractorFullInfo : {}}
            form={contractorIndlForm}
            selectContractor={selectContractor}
            relatedInfo={relatedInfo}
            saveContractorInfo={saveContractorInfo}
          />
        )}
      </CustomLoader>
    </Modal>
  );
}
export default AddClientModal;
