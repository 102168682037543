import { Form, Row, Col, Input, Select, DatePicker } from 'antd';
import CRadio from '@components/UI/radio/CRadio';
import { useEffect } from 'react';
function EditContractorDocInfo({ settings, editingKey }) {
  useEffect(() => {}, [editingKey]);

  return (
    <Row gutter={6}>
      <Form.Item name="subdocisn" hidden noStyle>
        <Input />
      </Form.Item>
      <Form.Item name="mainDoc" hidden noStyle>
        <Input />
      </Form.Item>
      <Form.Item name="juridical" hidden noStyle>
        <Input />
      </Form.Item>
      <Col span={6}>
        <Form.Item label="Тип документа" name="docclassisn">
          <Select
            options={settings?.docclassisn.children}
            fieldNames={{ label: 'full_name', value: 'foreign_id' }}
          />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="Номер" name="docno">
          <Input placeholder="16161616161" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Дата выдачи" name="docdate">
          <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Орган выдачи" name="handby">
          <Input placeholder="Кем выдан" />
        </Form.Item>
      </Col>
    </Row>
  );
}
export default EditContractorDocInfo;
