import React, { useEffect, useState } from 'react';
import { Card, Divider } from 'antd';
import { Typography } from 'antd';
import InterviewTable from './InterviewTable';
import ApplicationTable, { DataType } from './ApplicationTable';
import { IFetchInterview } from './interface';
import { setInterview } from './getInterviewData';
import { setApplication } from './getApplicationData';

type InterviewProps = {
  title: string;
};

const { Title } = Typography;

function InterviewRecruiting(props: InterviewProps) {
  const [fetchInterview, setFetchInterview] = useState<IFetchInterview[]>([]);
  const [fetchApplication, setFetchApplication] = useState<DataType[]>([])

  const { title } = props;

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setInterview(setFetchInterview)
    setApplication(setFetchApplication)
  }, [])

  return (
    <>
      <Card style={{ marginTop: 25 }}>
        <Title
          level={4}
          style={{
            whiteSpace: 'normal',
            height: 'auto',
            color: '#0057FF' 
          }}
        >
          Интервью руководителей с кандидатами (ГО и Регионы)
        </Title>
        <Divider />

        <InterviewTable fetchInterview={fetchInterview} />
      </Card>

      <Card style={{ marginTop: 25, marginBottom: 400 }}>
        <Title level={4} style={{ color: '#0057FF' }}>
          Мои заявки
        </Title>
        <Divider />

        <ApplicationTable fetchApplication={fetchApplication}/>
      </Card>
    </>
  );
}

export default InterviewRecruiting;
