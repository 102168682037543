import { useCallback, useEffect, useState } from 'react';
import api from '@modules/Quotation/services';
import { IProductList } from '@pages/Quotation/ExpressQuotation';
import catchErrorCustomAxios from '@services/api/catchErrors';


const useProductListApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState<IProductList[]>([]);

  const getProductList = useCallback(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await api.quotation.getListQuotations();
        setProductList(response.data.data);
      } catch (error) {
        let message;
        catchErrorCustomAxios(error, message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  return { productList, isLoading, setIsLoading };
};

export default useProductListApi;
