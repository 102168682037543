import { useEffect } from 'react';

import { ParseCompanies } from '@modules/Parse';

export default function PageParse({ title }) {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return <ParseCompanies />;
}
