import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import api from '@modules/Quotation/services';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { ISearchProductItem } from '@modules/Quotation/Interfaces/SearchProduct';

interface useFetchCreatedAgreementProps {
  dataAfterSubmitAndClear: any;
}

const useFetchCreatedAgreement = ({
  dataAfterSubmitAndClear,
}: useFetchCreatedAgreementProps) => {
  const [data, setData] = useState<ISearchProductItem[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (!dataAfterSubmitAndClear) return;
    try {
      setLoading(true);
      const response2 = await api.quotation.searchAgreement(
        dataAfterSubmitAndClear
      );
      setData(response2.data.data);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        notification.info({
          message: 'Ошибка',
          description: String(err.response.data.data),
          duration: 0,
        });
      } else {
        notification.info({
          message: 'Ошибка',
          description: String(error),
          duration: 0,
        });
      }
    } finally {
      setLoading(false);
    }
  }, [dataAfterSubmitAndClear]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading };
};

export default useFetchCreatedAgreement;
