import React, { useCallback, useEffect, useState } from 'react';
import api from '@modules/Quotation/services';
import { IDocType } from '@modules/Quotation/Interfaces/CreateQuotation/index.interface';
import catchErrorCustomAxios from '@services/api/catchErrors';


const useDocTypesList = () => {
  const [docTypesList, setDocTypesList] = useState<IDocType[]>([]);

  const getDocTypeList = useCallback(() => {
    (async () => {
      const responseData = {
        isn: 221726,
        connection: 'centras',
      };
      try {
        const response = await api.quotation.getDocsTypes(responseData);
        setDocTypesList(response?.data?.data);
      } catch (error) {
        let message;
        catchErrorCustomAxios(error, message);
      }
    })();
  }, []);

  useEffect(() => {
    getDocTypeList();
  }, [getDocTypeList]);

  return { docTypesList };
};

export default useDocTypesList;
