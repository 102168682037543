import React from 'react';
import { Button } from 'antd';
import './EventItemHeading.scss';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { IBooking } from '../../../pages/Talk/Talk';

interface EventItemHeadingProps {
  title?: string;
  company?: string;
  isInvite?: boolean;
  onAcceptEvent?: () => void;
  onRevokeEvent?: () => void;
  totalList: IBooking[];
}

const EventItemHeading: React.FC<EventItemHeadingProps> = ({
  title = 'Centras',
  company = 'Компания',
  isInvite = false,
  onAcceptEvent = () => {},
  onRevokeEvent = () => {},
  totalList = [],
}) => {
  {
    return (
      <div className="event-item-heading">
        <h4 className="event-item-heading__title">{company}</h4>
        <h3 className="event-item-heading__company event-item-heading__company-color">
          {title}
        </h3>
        {isInvite && (
          <div className="event-item-heading__group-button">
            <Button
              type="text"
              size="large"
              shape="circle"
              onClick={(event: any) => {
                event.stopPropagation();
                onAcceptEvent();
              }}
              icon={<CheckCircleFilled />}
              className="event-item-heading__button ant-btn-success"
            />
            <Button
              type="text"
              size="large"
              shape="circle"
              danger
              onClick={(event: any) => {
                event.stopPropagation();
                onRevokeEvent();
              }}
              icon={<CloseCircleFilled />}
              className="event-item-heading__button"
            />
          </div>
        )}
      </div>
    );
  }
};

export default EventItemHeading;
