import './CDragger.scss';
import { Upload } from 'antd';

const { Dragger } = Upload;

const CDragger = (props) => {
  return (
    <Dragger {...props} className="c-dragger">
      <span className="ant-upload-hint">+Выберите файл для загрузки</span>
    </Dragger>
  );
};

export default CDragger;
