import React, { useEffect, useState } from 'react';
import useMembersQuotation from '@modules/Quotation/hooks/useMembersQuotation';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Space,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import CButton from '@components/UI/button/CButton';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import CInput from '@components/UI/input/CInput';
import ListMembers from '../MembersQuotation/ListMembers';
import api from '@modules/Quotation/services';
import { useImmerReducer } from 'use-immer';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import axios from 'axios';
import SearchClientModal from '@modules/common/Client/SearchClientModal';
import contractorFunctions from '@modules/common/Client/Functions';
import ResultClientModal from '@modules/common/Client/ResultClientModal';
import AddClientModal from '@modules/common/Client/AddClientModal';
type Action = {
  type:
    | 'nextStep'
    | 'search'
    | 'loading'
    | 'hideContractorModal'
    | 'selectContractor'
    | 'selectSubject'
    | 'hideSubjectModal'
    | 'removeSubjectInfo'
    | 'saveContractorList';
  payload?: any;
};

interface State {
  step: number;
  contractorInfo: object | null;
  isLoading: boolean;
  isVisibleSubjectModal: boolean;
  subjectFullInfo: any | null;
  isJurUser: boolean;
  subjectList: [] | null;
  subjectInfo: any[] | null;
  advancedSearchIndex: number | null;
}

const initialState: State = {
  step: 0,
  contractorInfo: null,
  subjectInfo: null,
  isLoading: false,
  subjectFullInfo: null,
  isVisibleSubjectModal: false,
  subjectList: null,
  isJurUser: true,
  advancedSearchIndex: null,
};

const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
    case 'selectSubject':
    case 'selectContractor': {
      draft.isJurUser = action.payload.juridical == 'Y';
      draft.subjectFullInfo = action.payload.data;
      if (action.payload.data) {
        draft.subjectInfo = [
          {
            FULLNAME: action.payload.data.fullname,
            IIN: action.payload.data.iin,
            BIRTHDAY: action.payload.data.birthday,
            ISN: action.payload.data.isn,
          },
        ];
      }
      break;
    }
    case 'hideSubjectModal': {
      draft.step = 0;
      break;
    }
    case 'nextStep': {
      if (action.payload.step) {
        draft.step = action.payload.step;
      } else {
        draft.step = action.payload;
      }
      if (action.payload.index) {
        draft.advancedSearchIndex = action.payload.index;
      }
      break;
    }
    case 'saveContractorList': {
      draft.isJurUser = action.payload.isJurUser == 'Y';
      draft.subjectList = action.payload.data;
      break;
    }
    case 'hideContractorModal': {
      draft.step = 0;
      break;
    }
    case 'removeSubjectInfo': {
      draft.subjectInfo = null;
      draft.subjectFullInfo = action.payload;
      if (action.payload?.jur) {
        draft.isJurUser = action.payload.jur == 'Y';
      }
      break;
    }

    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
  }
};
const MainContractRole = ({ roles, userForeignId, agrIsn, contractData }) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const {
    isLoading,
    step,
    subjectFullInfo,
    isVisibleSubjectModal,
    isJurUser,
    subjectList,
    subjectInfo,
    advancedSearchIndex,
  } = state;
  const { members, setMembers } = useMembersQuotation();
  const [memberType, setMemberType] = useState<any>(
    Object.assign(
      {},
      roles.map((role) =>
        Number(role.classisn) ? Number(role.isn) : Number(role.RoleISN)
      )
    )
  );
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isSavedIndex, setIsSavedIndex] = useState<number | null>();

  const { Text, Title } = Typography;
  const [memberList, setMemberList] = useState(roles);
  /**
   * По ISN роли возвращает текстовое наименование
   * @param roleIsn - ISN роли
   */
  const roleIsnToText = (roleIsn) => {
    if (members.length) {
      return members.find((role) => role.id === Number(roleIsn))?.label;
    }
    return roleIsn;
  };
  const handleMemberAdd = () => {
    setMemberList([
      ...memberList,
      {
        active_mycent: true,
      },
    ]);
  };
  const deleteMembers = async (member, deleteIndex) => {
    if (!deleteIndex && deleteIndex !== 0) return;
    const { foreign_id: role, ISN: isn, subj_isn, classisn } = member;
    if (!(isn || subj_isn)) {
      setMemberList((item) =>
        item.filter((element, elementIndex) => elementIndex !== deleteIndex)
      );
    } else {
      try {
        const sendData = {
          agr_isn: agrIsn,
          class_isn: role || classisn,
          subj_isn: isn || subj_isn,
          user_isn: userForeignId,
        };
        const { data } = await api.contractor.deleteContractor(sendData);

        setMemberList((item) =>
          item.filter((element, elementIndex) => elementIndex !== deleteIndex)
        );
        notification.success({
          message: 'Участник успешно удален',
        });
        return data;
      } catch (error) {
        console.log(error);

        let message;
        message = error || 'Непредвиденная ошибка';
        notification.error({
          message: 'Ошибка',
          description: message,
        });
        return false;
      }
    }
  };

  const sendMembersQuotation = async ({ role, isn, newMember }, index) => {
    const sendData = {
      agr_isn: agrIsn,
      connection: 'centras',
      role_isn: role,
      subj_isn: isn,
      user_isn: userForeignId,
    };
    const { data } = await api.contractor.saveContractor(sendData);

    if (data.success === true) {
      const temp: Array<any> = [...memberList];
      // const existingElementIndex = temp.findIndex(
      //   (item) => item.foreign_id === newMember.foreign_id
      // );
      temp[index] = {
        agrisn: data.data.data[0].AGRISN,
        classisn: data.data.data[0].CLASSISN,
        isn: data.data.data[0].ISN,
        subj_full_name: newMember.FULLNAME
          ? newMember.FULLNAME
          : newMember.fullname,
        subj_isn: data.data.data[0].SUBJISN,
        subj_short_name: newMember.FULLNAME
          ? newMember.FULLNAME
          : newMember.fullname,
        user_isn: '0',
      };
      setMemberList(temp);
      // setMembers(temp);
      notification.success({
        message: 'Сообщение',
        description: data.message,
      });
      return true;
    } else {
      notification.info({
        message: 'Ошибка',
        description: data.error,
      });
      return false;
    }
  };
  const updateMembers = async (newMember, index) => {
    const data = await sendMembersQuotation(
      {
        role: newMember.foreign_id,
        isn: newMember.ISN,
        newMember,
      },
      index
    );
    setIsLoadingUser(false);
    setIsSavedIndex(null);

    if (!data) return;
  };

  const searchContractor = async (iin, memberType, index) => {
    dispatch({ type: 'search', payload: true });
    try {
      if (iin) {
        const { data } = await api.contractor.getContractorByIIN({
          iin,
        });

        if (data.data.length === 1) {
          if (!data.data[0].ISN) {
            const info = await api.contractor.saveContractorKias({
              xml_isn: data.data[0].XMLISN,
              ext_system_key: data.data[0].EXTSYSTEMKEY,
            });
          } else {
            // empty
          }
        } else {
          // empty
        }

        data.data[0].foreign_id = memberType;
        updateMembers(data.data[0], index);
      } else {
        notification.error({
          message: 'Ошибка',
          description: 'Введите ИИН / БИН',
        });
      }
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.error({
        message: 'Ошибка',
        description: message,
      });
      dispatch({ type: 'search', payload: false });
    }
  };
  const AdvancedOwnerSearch = (index) => {
    if (memberType[index]) {
      dispatch({ type: 'nextStep', payload: { step: 1, index } });
    } else {
      notification.info({
        message: 'Выберите сначала тип участника',
      });
    }
  };
  const getContractorList = (values) => {
    contractorFunctions.getContractorList({ dispatch, values });
  };
  const selectContractor = async (value, fromInfo) => {
    try {
      dispatch({ type: 'search', payload: true });
      if (!value.ISN && !value.isn) {
        const info = await api.contractor.saveContractorKias({
          xml_isn: value.XMLISN,
          ext_system_key: value.EXTSYSTEMKEY,
        });
        const { data } = await api.contractor.getContractorByISN(info.data.isn);
        dispatch({
          type: 'selectSubject',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      } else {
        const isn = value.ISN ? value.ISN : value.isn;
        const { data } = await api.contractor.getContractorByISN(isn);

        if (advancedSearchIndex && memberType[advancedSearchIndex]) {
          data.data.foreign_id = memberType[advancedSearchIndex];
          updateMembers(
            {
              ...data.data,
              isn,
              agrIsn,
              subj_birthday: data.data.birthday,
              subj_full_name: data.data.fullname,
              subj_iin: data.data.iin,
              subj_isn: data.data.isn,
              ISN: isn,
              user_isn: isn,
            },
            advancedSearchIndex
          );
          // searchContractor(data.data.iin, isn);
        }
        dispatch({
          type: 'selectSubject',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      }
      dispatch({ type: 'nextStep', payload: 3 });
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      dispatch({ type: 'search', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };

  const handleCancelAddClientModal = () => {
    dispatch({ type: 'hideSubjectModal', payload: 0 });
    dispatch({
      type: 'selectSubject',
      payload: {},
    });
    dispatch({ type: 'nextStep', payload: { from: null, index: null } });
  };
  const onChange = (event, foreign_id, index) => {
    if (event.target.value.length === 12) {
      const item = [...memberList];

      item[index].subj_iin = event.target.value;
      setMemberList(item);
    }

    if (event.target.value.length === 12 && memberType[index]) {
      setIsLoadingUser(true);
      setIsSavedIndex(index);
      setTimeout(() => {
        searchContractor(event.target.value, memberType[index], index);
      }, 500);
    }
  };
  const changeState = (step, data: any | null, fromInfo) => {
    dispatch({ type: 'nextStep', payload: { step, from: fromInfo } });
    if (data !== null) {
      dispatch({ type: 'saveContractorList', payload: { data } });
    }
    if (fromInfo == 'related') {
      dispatch({
        type: 'removeSubjectInfo',
        payload: { ...subjectFullInfo, from: 'related', jur: 'Y' },
      });
    }
  };

  const memberRoleChange = (e, index) => {
    setMemberType({ [index]: e });
  };

  return (
    <Card className="mt-5">
      <Title className="main-contract__section-title" level={3}>
        Участники
      </Title>
      {memberList.map((role, index) => {
        if (role.subj_isn) {
          return (
            <Card
              style={{ backgroundColor: '#f8f8f8' }}
              className="quotation-members mt-5"
              size="small"
              key={index}
            >
              <Row className="align-center justify-space-between" gutter={18}>
                <Col span={6} style={{ alignItems: 'center' }}>
                  <Text style={{ fontSize: '18px', fontWeight: '700' }}>
                    {roleIsnToText(role.classisn)}
                  </Text>
                </Col>
                <Col span={6} style={{ textAlign: 'right', fontSize: '18px' }}>
                  {role.subj_full_name}
                </Col>
                <div>
                  <Space
                    size="middle"
                    style={{ width: '100%', justifyContent: 'end' }}
                  >
                    <Tooltip
                      placement="top"
                      title="Удалить участника"
                      mouseEnterDelay={1}
                    >
                      <Button
                        type="primary"
                        className="quotation-members__button"
                        danger
                        onClick={() => deleteMembers(role, index)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </Space>
                </div>
              </Row>
            </Card>
          );
        } else {
          return (
            <CustomLoader spinning={isLoadingUser && index === isSavedIndex}>
              <Row gutter={13} className="mt-3 mb-3">
                <Col span={6} style={{ alignItems: 'center' }}>
                  <ListMembers
                    className="w-100"
                    member={{ foreign_id: Number(role.RoleISN) }}
                    placeholder="Тип участника"
                    members={members}
                    onChange={(event) => memberRoleChange(event, index)}
                  />
                </Col>
                <Col span={12}>
                  <Form.Item className="mb-0">
                    <CInput
                      className="digitsMask"
                      placeholder="Поиск по ИИН/БИН"
                      disabled={false}
                      size="large"
                      theme="light"
                      onChange={(e) => onChange(e, role.foreign_id, index)}
                    ></CInput>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <CButton
                    type="primary"
                    className="c-button_left"
                    block
                    onClick={() => AdvancedOwnerSearch(index)}
                  >
                    <SearchOutlined />
                    Расширенный поиск
                  </CButton>
                </Col>
                <Col span={1}>
                  <Button
                    type="primary"
                    className="quotation-members__button"
                    danger
                    onClick={() => deleteMembers(role, index)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Col>
              </Row>
            </CustomLoader>
          );
        }
      })}
      {memberList.length ===
      memberList.filter((member) => member.subj_isn).length ? (
        <div
          className="mt-6"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <CButton
            type="text"
            size="large"
            style={{
              color: '#7449ef',
              fontWeight: 600,
            }}
            onClick={handleMemberAdd}
          >
            + Добавить участника
          </CButton>
        </div>
      ) : null}
      {step == 1 && (
        <SearchClientModal
          isLoading={isLoading}
          from={''}
          contractorFullInfo={subjectFullInfo}
          isVisible={isVisibleSubjectModal}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(dispatch, '')
          }
          getContractorList={getContractorList}
        />
      )}
      {step == 2 && (
        <ResultClientModal
          isLoading={isLoading}
          contractorList={subjectList}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(dispatch, '')
          }
          selectContractor={selectContractor}
          createContractor={() => {}}
          from={''}
        />
      )}
      {step == 3 && (
        <AddClientModal
          isLoading={isLoading}
          isJurUser={isJurUser}
          contractorFullInfo={subjectFullInfo}
          handleCancel={handleCancelAddClientModal}
          changeState={changeState}
          selectContractor={selectContractor}
        />
      )}
    </Card>
  );
};

export default MainContractRole;
