import axios from '../axios';

const endpoints = {
  getColleagues: () => axios.get('/client/tree'),
  getDepartments: () => axios.get('/document_designer/access'),
  getBirthDays: () => axios.get('/birthday'),
  getColleagueInfo: (id) => axios.get('/user/info/' + id),
};

export default endpoints;
