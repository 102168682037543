import { ColleaguesKias } from '@modules/Quotation/hooks/CommonMethods/MainComponents/useCompanyTree';
import { TreeSelect } from 'antd';
import _ from 'lodash';
import CTreeSelect from '@components/UI/treeselect/CTreeSelect';

const renderCompanyTreeNodes = (item: ColleaguesKias) => {
  const identifyIsDepartmentOrEmployee =
    parseInt(item.ISDEPT) === 1 ? +item.DEPTISN : +item.EMPLISN;
  return (
    <TreeNode
      key={identifyIsDepartmentOrEmployee}
      value={identifyIsDepartmentOrEmployee}
      title={item.FULLNAME}
    >
      {_.uniqBy(item?.children, function (e) {
        return e.DEPTISN || e.EMPLISN;
      }).map((item) => renderCompanyTreeNodes(item))}
    </TreeNode>
  );
};

const { TreeNode } = TreeSelect;

interface Props {
  colleaguesKiasList: ColleaguesKias[];
  disabled?: boolean;
  readonly?: boolean;
  selectDepartment?: boolean;
  value?: number;
  onChange?: (value: number | undefined) => void;
  size?: 'small' | 'middle' | 'large' | undefined;
  style?: any;
  placeholder?: string;
  className?: string;
}

function TreeSelectKias({
  colleaguesKiasList,
  disabled,
  value,
  onChange,
  size,
  className,
}: Props) {
  return (
    // TODO
    // Response TreeSelect to
    // small device

    <CTreeSelect
      placeholder="Куратор"
      style={{ width: '100%' }}
      treeLine={{ showLeafIcon: false }}
      disabled={disabled}
      showSearch={true}
      treeNodeFilterProp="title"
      value={value}
      onChange={onChange}
      size={size}
      className={`c-select ${className || ''}`}
    >
      {colleaguesKiasList?.map((item) => renderCompanyTreeNodes(item))}
    </CTreeSelect>
  );
}

export default TreeSelectKias;
