import { Layout } from 'antd';

import './PageSider.scss';
import MainMenu from '../MainMenu';
import Logo from '../Logo';

const { Sider } = Layout;

export default function PageSider() {
  return (
    <Sider
      width={320}
      breakpoint="xl"
      collapsedWidth={0}
      className="page-sider"
    >
      <div className="page-sider__logo-wrap">
        <Logo />
      </div>

      <MainMenu />
    </Sider>
  );
}
