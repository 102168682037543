import cargo from './cargo';
import auto from './auto';
import expressQuotation from './expressQuotation';
import kias from './kias';
import quotation from './quotation';
import quotationJournal from './quotationJournal';
import saveQuotation from './saveQuotation';
import contractor from './contractor'
import invoice from './invoice'

const endpoints = {
  cargo,
  auto,
  expressQuotation,
  kias,
  quotation,
  quotationJournal,
  saveQuotation,
  contractor,
  invoice
};

export default endpoints;
