import { Collapse, Typography } from 'antd';

import './UserDetails.scss';

const { Panel } = Collapse;
const { Title } = Typography;

interface UserDetailsProps {
  title: string;
  children: React.ReactNode;
}

function UserDetails({ title = 'Заголовок', children }: UserDetailsProps) {
  return (
    <Collapse
      defaultActiveKey={['1']}
      expandIconPosition="end"
      className="user-details"
    >
      <Panel
        header={
          <Title className="mb-0" level={5}>
            {title}
          </Title>
        }
        key="1"
      >
        {children}
      </Panel>
    </Collapse>
  );
}

export default UserDetails;
