import { Empty, Steps, Typography } from 'antd';
import dayjs from 'dayjs';

const { Step } = Steps;
const { Title } = Typography;

interface UserDetailsDefaultProps {
  detailList: AdminDay[] | null;
  titleRest?: string;
}

interface AdminDay {
  duration: number;
  period: string;
  remark: string;
}

function UserDetailsDefault({
  detailList = [],
  titleRest = '',
}: UserDetailsDefaultProps) {
  if (detailList === null) {
    return <Empty />;
  }

  const formattedDetailsList = [...detailList].map((item) => {
    const [datebeg, dateend] = item.period.split('-');

    return {
      ...item,
      datebeg: datebeg.split('.').reverse().join('.'),
      dateend: dateend.split('.').reverse().join('.'),
    };
  });

  const sortByDateDetailsList = formattedDetailsList.sort(
    (itemA, itemB) =>
      new Date(itemB.datebeg).getTime() - new Date(itemA.datebeg).getTime()
  );

  return (
    <>
      <Steps
        progressDot
        status="finish"
        direction="vertical"
        current={detailList.length}
        items={sortByDateDetailsList.map((item, index) => {
          const dateBegin = dayjs(item.datebeg, 'YYYY.MM.DD').isValid()
            ? dayjs(item.datebeg, 'YYYY.MM.DD').format('DD.MM.YYYY')
            : '';
          const dateEnd = dayjs(item.dateend, 'YYYY.MM.DD').isValid()
            ? dayjs(item.dateend, 'YYYY.MM.DD').format('DD.MM.YYYY')
            : 'н.в.';

          return {
            title: item.remark || '-',
            description: 'Количество дней: ' + item.duration,
            'data-date-begin': dateBegin,
            'data-date-end': dateEnd,
          };
        })}
      />
      {titleRest && (
        <Title level={5} className="mt-2">
          {titleRest}
        </Title>
      )}
    </>
  );
}

export default UserDetailsDefault;
