import { ColleaguesListType } from '@hooks/useColleagues';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { api, ISaveSpeaker } from '../api';
import { getMessageError } from '../api/catchError';
import { Action, IState } from '../reducer/reducer';
import SpeakerModalSetupField from './SpeakerModalSetupField';
import { getSpeakers } from './Speaker';
import useAuth from '@hooks/useAuth';

interface ISpeakerModal {
  state: IState;
  dispatch: React.Dispatch<Action>;
  colleaguesList: ColleaguesListType;
  isLoadingColleaguesList: boolean;
}

function SpeakerModal(props: ISpeakerModal) {
  const { state, dispatch, colleaguesList, isLoadingColleaguesList } = props;

  const { isOpenModal, isLoading, typeModal, speaker } = state;

  const [form] = Form.useForm();

  const auth = useAuth();

  const userId = auth.user.data.id

  const onSubmit = (values: ISaveSpeaker, dispatch: React.Dispatch<Action>) => {
    let { date_time } = values;

    if (date_time && date_time !== null) {
      date_time = dayjs(date_time).format('YYYY-MM-DD HH:mm:ss');
    }
    sendData(values, dispatch).catch(getMessageError);
  };

  const sendData = async (
    values: ISaveSpeaker,
    dispatch: React.Dispatch<Action>
  ) => {
    try {
      dispatch({ type: 'isLoading', payload: true });
      const data = await api.saveSpeaker(values);
      if (data?.success === true) {
        notification.info({
          message: 'Уеведомление',
          description: data?.message,
        });
        getSpeakers(dispatch);
        dispatch({ type: 'isOpenModal', payload: false });
        form.resetFields();
      }
    } finally {
      dispatch({ type: 'isLoading', payload: false });
    }
  };

  const updateData = async() => {
    const data = form.getFieldsValue();
    sendUpdateData(data, speaker.id).catch(getMessageError)
  };

  const sendUpdateData = async(values: ISaveSpeaker, id: number) => {
    try {
      dispatch({ type: 'isLoading', payload: true})
      await api.updateSpeakerById(values, id)
      notification.info({
        message: 'Уведомление',
        description: 'Успешно обновлено'
      })
    } finally {
      dispatch({ type: 'isLoading', payload: false})
    }
  }

  const footerButtons = [
    <Button
      disabled={isLoading}
      onClick={() => {
        dispatch({ type: 'typeModal', payload: false });
        dispatch({ type: 'isOpenModal', payload: false });
      }}
    >
      Отменить
    </Button>,
    <Button
      htmlType="submit"
      type="primary"
      form="speakerModal"
      disabled={isLoading}
    >
      Сохранить
    </Button>,
  ];

  const editButtons = [
    <Button
      disabled={isLoading}
      onClick={() => {
        dispatch({ type: 'typeModal', payload: false });
        dispatch({ type: 'isOpenModal', payload: false });
      }}
    >
      Отменить
    </Button>,
    <Button
      type="primary"
      form="speakerModal"
      disabled={isLoading}
      onClick={updateData}
    >
      Обновить
    </Button>,
  ];

  const isDisabled = () => {
    if (typeModal === true && speaker.hr === false) {
      return true
    } else if (typeModal === true && speaker.hr === true) {
      return false
    }
    return false
  };

  const isCreator = () => {
    if (typeModal === true && speaker.user_id.id === userId) {
      return false
    } else if (typeModal === true && speaker.hr === true) {
      return false
    } else if (typeModal === false) {
      return false
    }
    return true
  }

  return (
    <>
      <Modal
        open={isOpenModal}
        width={700}
        onCancel={() => {
          dispatch({ type: 'typeModal', payload: false });
          dispatch({ type: 'isOpenModal', payload: false });
        }}
        footer={typeModal === false ? footerButtons : editButtons}
        destroyOnClose
      >
        <Form
          layout="vertical"
          validateMessages={{ required: 'Обязательное поле для заполнения' }}
          name="speakerModal"
          onFinish={(values) => {
            onSubmit(values, dispatch);
          }}
          disabled={isLoading}
          form={form}
        >
          <Row gutter={[24, 24]} className="mt-6">
            <Col xs={24} xl={12}>
              <Form.Item
                name="user_id"
                label="ФИО Спикера"
                rules={[{ required: true }]}
              >
                <TreeSelectCollegues
                  colleaguesList={colleaguesList}
                  placeHolder="Выбрать спикера"
                  disabled={isLoadingColleaguesList || isDisabled()}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item name="topic" label="Тема">
                <Input disabled={isCreator()} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item name="date_time" label="Дата и время">
                <DatePicker
                  className="width"
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  disabled={isDisabled()}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item name="university_name" label="Наименование вуза">
                <Input disabled={isDisabled()} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item name="specialization" label="Специализация">
                <Input disabled={isDisabled()} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item name="address" label="Адрес">
                <Input disabled={isDisabled()} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item name="quantity" label="Количество">
                <InputNumber disabled={isDisabled()} className="width" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item name="course" label="Курс">
                <InputNumber disabled={isDisabled()} className="width" />
              </Form.Item>
            </Col>
          </Row>
          {Object.keys(state.speaker).length ? (
            <SpeakerModalSetupField state={state} dispatch={dispatch} />
          ) : null}
        </Form>
      </Modal>
    </>
  );
}

export default SpeakerModal;
