import { IRowColumnEditor } from '@modules/Edo/types';
import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { IUser } from './FieldUser';
import TreeSelectHandbook from '@modules/TreeSelect/TreeSelectHandook';
import { HandbookListType } from '@hooks/useHandbook';

interface ISelectAdminDay {
  settings: IRowColumnEditor;
  isDisabledForm: boolean;
  name: number;
  handbookList: HandbookListType;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}

interface IUserAdminDay {
  adminDay: number;
}

const SelectAdminDay = (props: ISelectAdminDay) => {
  const [user, setUser] = useState<IUser | IUserAdminDay>({ adminDay: 0 });
  const {
    settings,
    name,
    handbookList,
    isDisabledForm,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;
  const form = Form.useFormInstance();
  const isRowColumnEditor = true;
  const propertyValue = Form.useWatch(['row_column_editor', name], form);
  const [selectedAdminDay, setSelectedAdminDay] = useState<number | undefined>(
    undefined
  );
  const [filteredHandbookList, setFilteredHandbookList] =
    useState(handbookList);

  /**
   * Изменение select'а "Указать кол-во используемых дней"
   * @param value - выбранное значение
   */
  const handleChange = (value) => {
    if (settings?.dicti && settings.dicti[0].constant1 === 'cUseAdmDay') {
      const check = settings.dicti[0].children.filter(
        (item) => item.id === value
      )[0];
      setSelectedAdminDay(
        check?.short_name ? parseFloat(check.short_name) : undefined
      );
      if (isDisabledStatus && isAdmin && onChangeByTd) {
        onChangeByTd(settings.id, name);
      }
    }
  };

  /**
   * Отслеживает изменение select'a "ФИО работника"
   */
  useEffect(() => {
    if (propertyValue) {
      const formValues = Object.values(propertyValue);

      formValues.map((element: any) => {
        if (element.type === 'DEPTEMPL') {
          setUser((prevState) => ({ ...prevState, ...element }));
        }

        if (element.full_name.toLowerCase() === 'доступное кол-во дней') {
          setUser({ ...user, adminDay: parseFloat(element.char_value) });
        }
      });
    }
  }, [propertyValue]);

  /*
  // Фильтруем элементы списка по значению adminDay
  const filteredChildren = useMemo(() => {
    if (user.adminDay) {
      return {
        children: handbookList[0].children.filter((el) => {
          return parseFloat(el.short_name) <= user.adminDay;
        }),
      }
      return handbookList;
    }
  }, [user]);
  */

  /**
   * Фильтрует доступные для выбора значения для поля "Указать кол-во используемых дней"
   * и сбрасывает выбранное значение если оно больше допустимого
   */
  useEffect(() => {
    if (isRowColumnEditor) {
      const { row_column_editor } = form.getFieldsValue();

      // Фильтруем элементы списка по значению adminDay
      const filteredChildren = {
        children: handbookList[0].children.filter((el) => {
          return parseFloat(el.short_name) <= user.adminDay;
        }),
      };

      // Если нет adminDay или оно меньше установленного значения - сбрасываем значение поля
      if (
        user.adminDay <= 0 ||
        (selectedAdminDay && user.adminDay < selectedAdminDay)
      ) {
        Object.assign(row_column_editor[name][`id-${settings.id}`], {
          num_value: undefined,
        });
      }

      setFilteredHandbookList([
        Object.assign({}, handbookList[0], filteredChildren),
      ]);
    }
  }, [user, selectedAdminDay, handbookList, form, name]);

  return (
    <Form.Item
      label={settings.field_name}
      name={[name, `id-${settings.id}`, 'num_value']}
      rules={[{ required: settings.required }]}
    >
      <TreeSelectHandbook
        settings={settings}
        handbookList={filteredHandbookList}
        onChange={(selectedValue: number | undefined) =>
          handleChange(selectedValue)
        }
        disabled={isDisabledForm ? isDisabledForm : settings.readonly}
      />
    </Form.Item>
  );
};

export default SelectAdminDay;
