import { Empty, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface UserVacationProps {
  detailList: IVacation[] | null;
}

interface IVacation {
  classname: string;
  duration: number;
  period: string;
  rest: number;
}

interface DataType extends IVacation {
  key?: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Вид',
    dataIndex: 'classname',
    width: 200,
  },
  {
    title: 'Период',
    dataIndex: 'period',
    width: 200,
  },
  {
    title: 'Период отпуска',
    dataIndex: 'periodvac',
    width: 200,
    render(text) {
      return text?.split('\r').map((item, index) => (
        <span key={index} style={{ display: 'block' }}>
          {item}
        </span>
      ));
    },
  },
  {
    title: 'Использованные дни',
    dataIndex: 'duration',
    width: 150,
  },
  {
    title: 'Оставшиеся дни',
    dataIndex: 'rest',
    width: 150,
  },
];

function UserDetailsVacation({ detailList = [] }: UserVacationProps) {
  if (detailList === null) {
    return <Empty />;
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={detailList}
        scroll={{ x: 1100 }}
        rowKey={(record) => record.duration}
      />
    </>
  );
}

export default UserDetailsVacation;
