import Title from 'antd/es/typography/Title';
import { Button, Col, Modal, Row } from 'antd';
import React from 'react';
import './DeleteCategoryModal.scss';
import useLibrary from '@hooks/useLibrary';

interface IPropsDeleteModal {
  isModalOpen: boolean;
  onCancel: () => void;
  deleteCategory: (id: string) => void;
  deleteDocument: (uuid: string) => void;
}

const DeleteCategoryModal = ({ isModalOpen, onCancel, deleteCategory, deleteDocument }: IPropsDeleteModal) => {
  const { deleteCategoryId, isDocument, currentFileUuid } = useLibrary();

  return (
    <Modal
      title={<Title level={4}>Вы уверены что хотите удалить {isDocument ? 'документ' : 'папку'} ?</Title>}
      open={isModalOpen}
      footer={null}
      onCancel={onCancel}
      className="add-modal-online-library"
      centered
    >
      <Row className="d-flex justify-space-between mt-8">
        <Col span={11}>
          <Button
            block
            size="large"
            className="delete-btn-online-library"
            onClick={() => {
              isDocument ? deleteDocument(currentFileUuid) : deleteCategory(deleteCategoryId)
              onCancel();
            }}
          >
            Да
          </Button>
        </Col>
        <Col span={11}>
          <Button
            block
            size="large"
            className="reject-btn-online-library"
            onClick={onCancel}
          >
            Отмена
          </Button>
        </Col>
      </Row>
    </Modal>
  )
};

export default DeleteCategoryModal;