import { KeyOutlined } from '@ant-design/icons';
import { Button, Col, Form, notification, Row, Typography } from 'antd';
import axios from 'axios';
import React, { Dispatch, SetStateAction, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import api from '../services/api';
import { getToken } from './getToken';
import { AppState } from './type';

const { Title } = Typography;

type EdsProps = {
  handleCancel: () => void;
  agreement: any;
  setIsButtonDisabled: Dispatch<SetStateAction<boolean>>;
  ecpRedirect: boolean | null;
  ecpRedirectUrl: string | null;
};

const EDS = ({ handleCancel, agreement, setIsButtonDisabled, ecpRedirect, ecpRedirectUrl }: EdsProps) => {
  const auth = useAuth();

  const form = Form.useFormInstance();

  let responseProcessed = false;
  let webSocket;
  let base64EncodedSignature;

  const initAppState = (): AppState => {
    return {
      loading: false,
      seenmoney: false,
      ws: null,
      onlinePlayers: 0,
      human: {
        pathToFile: '',
        pathDir: '',
      },
      sign: {
        password: '',
        // здесь возможно баг
        token: null,
      },
      selectedFile: '',
      base64String: 'dGVzdA==',
      signedFile: null,
      signedFileInfo: [],
      edsConfirmed: false,
      checkCmsPermission2: true,
    };
  };

  const [state, setState] = useState<AppState>(initAppState());

  const redirectToSignatureKias = () => {
    const baseUrl = `${ecpRedirectUrl}`;
    const redirectLink = 'mycent.kz/agreement/success';
    const url = new URL(baseUrl);
    url.searchParams.append('redirect', redirectLink);
    window.location.href = url.toString();
  };

  const redirectToSignatureMycent = () => {
    const baseUrl = `${agreement?.signature_link}`;
    const url = new URL(baseUrl);
    window.location.href = url.toString();
  };

  const connectSocket = () => {
    webSocket = new WebSocket('wss://127.0.0.1:13579/');

    return new Promise((resolve, reject) => {
      responseProcessed = false;
      setHandlers(resolve, reject);

      webSocket.onmessage = (msg) => {
        if (responseProcessed) {
          return;
        }
        responseProcessed = true;

        const response = JSON.parse(msg.data);

        if (response.result && response.result.version) {
          resolve(response.result.version);
          return;
        }
        reject('Ошибка взаимодействия с NCALayer.');
      };
    })
      .then(async () => {
        try {
          base64EncodedSignature = await signing(state.base64String, true);

          // Режем лишние данные из возвращаемого base64 из NcaLayer
          const responseObject = base64EncodedSignature[0]
            .replace('-----BEGIN CMS-----', '')
            .replace('-----END CMS-----', '')
            .trim();

          setState({
            ...state,
            signedFile: base64toFile(responseObject),
          });

          // В режиме отладки скачиваем файл на комп
          // downloadFile(state.signedFile);

          const bodyData = {
            file: responseObject,
            fileName: auth.user.data.id,
            documentId:
              agreement.docsform === undefined
                ? agreement.document_id
                : undefined,
            isn:
              agreement.docsform !== undefined
                ? agreement.docsform[0].ISN
                : undefined,
            refDocIsnClass:
              agreement.docsform !== undefined
                ? agreement.docsform[0].REFDOCISN_CLASS
                : undefined,
            refDocIsn:
              agreement.docsform !== undefined
                ? agreement.docsform[0].REFDOCISN
                : undefined,
          };

          const { data } = await api.saveFile(bodyData);
          if (data.status !== true) {
            notification.info({ message: 'Ошибка', description: data.error });
          } else {
            checkSignedFile(data.url, responseObject);
          }
        } catch (error: any) {
          notification.info({ message: 'Ошибка', description: error });

          return;
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    };

  const getClickButtonFunction = () => {
    if(ecpRedirect !== null && ecpRedirect) return redirectToSignatureKias;
    else if(agreement?.ecp) return redirectToSignatureMycent;
    else return connectSocket;
  }

  /**
   * Скачивает подписанный файл
   * @param file - файл для скачивания
   */
  const downloadFile = (file) => {
    if (!file) {
      return;
    }
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    const url = window.URL.createObjectURL(file);
    downloadLink.href = url;
    downloadLink.download = file.name;
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  };

  /**
   * Конвертируем base64 от NCALayer в File
   * @param b64Data - файл из NCALayer
   * @param sliceSize - размер разбивки на кусочки
   * @returns {File} - возвращает файл
   */
  const base64toFile = (b64Data, sliceSize = 512) => {
    const filename = `test.txt.cms`;
    const contentType = 'application/octet-stream';
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      // @ts-ignore
      byteArrays.push(byteArray);
    }

    return new File(byteArrays, filename, { type: contentType });
  };

  let setHandlers = (resolve, reject) => {
    responseProcessed = false;

    // Если NCALayer не запущен выводим уведомление
    webSocket.onerror = (msg) => {
      console.log(msg);
      if (msg.type === 'error') {
        reject(
          notification.info({
            message: 'Ошибка',
            description:
              'Ошибка при подключении к NCALayer Убедитесь что программа NCALayer установлена и запущена. Программу можно скачать по адресу https://pki.gov.kz/ncalayer/',
          })
        );
      }
    };

    webSocket.onmessage = (msg) => {
      if (responseProcessed) {
        return;
      }
      responseProcessed = true;

      const response = JSON.parse(msg.data);

      // basics response
      if (response.hasOwnProperty('status')) { // eslint-disable-line no-prototype-builtins
        if (!response.status) {
          reject(`${response.code}: ${response.message} (${response.details})`);
          return;
        }

        if (!response.body.hasOwnProperty('result')) {
          reject('Действие отменено пользователем');
          return;
        }

        resolve(response.body.result);
        return;
      }

      if (response.code !== '200') {
        reject(`${response.code}: ${response.message}`);
        return;
      }

      resolve(response.responseObject);
    };
  };

  /*
  webSocket.onclose = (e) => {
    console.log(e, 'e');
    if (e.wasClean) {
      console.log('connection closed');
    } else {
      notification.warning({
        message: 'Отсутствует подключение',
        description:
          'Включите NCALayer или перезагрузите страницу (CTRL + F5)',
      });
      handleCancel();
    }
  };
  */

  const sendRequest = (request) => {
    if (!webSocket) {
      ('Подключение к NCALayer не установлено');
      return;
    }

    const jsonRequest = JSON.stringify(request);

    webSocket.send(jsonRequest);
  };

  /*
  function installModule() {
    var webSocket = new WebSocket('wss://127.0.0.1:13579');
    webSocket.onopen = function () {
      webSocket.send(
        '{\n' +
          '    module: "kz.gov.pki.ncalayerservices.accessory", \n' +
          '    method: "installBundle", \n' +
          '    symname: "kz.uchet.signUtil" \n' +
          '}'
      );
    };
    webSocket.onmessage = function (msg) {
      console.log(msg);
    };
    webSocket.onerror = function (msg) {
      console.log(msg);
    };
  }


  function getKey() {
    var webSocket = new WebSocket('wss://127.0.0.1:13579');
    installModule();
    webSocket.onopen = function () {
      webSocket.send(
        '{\n' +
          '                    "module":"kz.uchet.signUtil.commonUtils",\n' +
          '                    "method":"getFilePath",\n' +
          '                    "args":["all",""]\n' +
          '                }'
      );
    };

    webSocket.onmessage = function (msg) {
      var { code, responseObject } = JSON.parse(msg.data);

      if (Number(code) === 200) {
        setState({
          ...state,
          selectedECPFile:
            typeof responseObject.path !== undefined ? responseObject.path : '',
        });
      }
    };

    webSocket.onerror = function (msg) {
      console.log(msg);
    };
  }

  function getTokenMethod() {
    getToken().then((data) => {
      if (data.success === true) {
        setState({
          ...state,
          sign: {
            ...state.sign,
            token: data.result.token,
          },
        });
        notification.info({
          message: 'Сообщение',
          description: 'Ожидайте ответа от сервера',
        });
        signing(data.result.token);
      } else {
        notification.info({
          message: 'Ошибка',
          description: 'Получения токена. Попробуйте чуть позже',
        });
      }
    });
  }
  */
  /*
  function signing(token) {
    if (state.selectedECPFile === '' || state.sign.password === '') {
      notification.info({
        message: 'Сообщение',
        description: 'Укажите пожалуйста данные ЭЦП ключа',
      });
      return false;
    }

    if (typeof token !== 'undefined') {
      var webSocket = new WebSocket('wss://127.0.0.1:13579');
      webSocket.onopen = function () {
        var responseObj = {
          module: 'kz.uchet.signUtil.commonUtils',
          lang: 'en',
          method: 'signFileAndReturnBase64Api',
          args: [
            token,
            state.base64String,
            state.selectedECPFile,
            state.sign.password,
            'PKCS12',
          ],
        };
        webSocket.send(JSON.stringify(responseObj));
      };

      webSocket.onmessage = async function (msg) {
        var { code, responseObject, message } = JSON.parse(msg.data);
        if (code) {
          if (Number(code) === 200) {
            setState({
              ...state,
              signedFile: responseObject,
            });

            const bodyData = {
              file: responseObject,
              fileName: auth.user.data.id,
              documentId:
                agreement.docsform === undefined
                  ? agreement.document_id
                  : undefined,
              isn:
                agreement.docsform !== undefined
                  ? agreement.docsform[0].ISN
                  : undefined,
              refDocIsnClass:
                agreement.docsform !== undefined
                  ? agreement.docsform[0].REFDOCISN_CLASS
                  : undefined,
              refDocIsn:
                agreement.docsform !== undefined
                  ? agreement.docsform[0].REFDOCISN
                  : undefined,
            };

            const { data } = await api.saveFile(bodyData);
            if (data.status !== true) {
              notification.info({ message: 'Ошибка', description: data.error });
            } else {
              checkSignedFile(data.url, responseObject);
            }
          } else {
            if (message === 'Не удалось открыть хранилище') {
              notification.info({
                message: 'Оповещение',
                description: 'Вы указали неверный пароль!',
              });
            } else {
              notification.info({
                message: 'Оповещение',
                description: message,
              });
            }
          }
        }

        webSocket.onerror = function (msg) {
          console.log(msg);
        };
      };

      webSocket.onclose = (e) => {
        console.log(e, 'e');
        if (e.wasClean) {
          console.log('connection closed');
        } else {
          notification.warning({
            message: 'Отсутствует подключение',
            description:
              'Включите NCALayer или перезагрузите страницу (CTRL + F5)',
          });
          handleCancel();
        }
      };
    }
  }
 */
  const signing = async (data, attach = true) => {
    const request = {
      module: 'kz.gov.pki.knca.basics',
      method: 'sign',
      args: {
        allowedStorages: null,
        format: 'cms',
        data,
        signingParams: {
          decode: true, // true - data в base64
          encapsulate: attach, // true - вшивать исходный документ в csm
          digested: false,
          tsaProfile: {}, // Ставить метку TSP
        },
        signerParams: {
          extKeyUsageOids: [],
          chain: [],
        },
        locale: 'ru',
      },
    };

    sendRequest(request);

    return new Promise((resolve, reject) => {
      setHandlers(resolve, reject);
    });
  };

  /**
   * Проверяем подписанный файл на соответствие ИИН
   * @param url - путь к файлу
   * @param fileBase64 - файл в base64
   */
  const checkSignedFile = async (url, fileBase64) => {
    if (url !== '') {
      const blob = await fetch(url).then((response) => response.blob()); // Получаем Blob из URL
      const urlObject = new URL(url);
      const fileName = urlObject.pathname.split('/').pop();
      const file = new File([blob], String(fileName), { type: blob.type });

      try {
        const formData = new FormData();
        formData.append('file', file);
        const { data }: any = await api.checkSignedFile(formData);
        const iin = data?.data[0]?.iin;
        const name = data?.data[0]?.commonName;
        const certificateValidityPeriod = data?.data[0]?.certificateValidityPeriod;
        const tspDate = data?.data[0]?.tspDate;
        const serialNumber = data?.data[0]?.serialNumber;
        if (
          iin &&
          name &&
          certificateValidityPeriod &&
          tspDate &&
          serialNumber
        ) {
          const responseObjects = [
            {
              certificateValidityPeriod,
              serialNumber,
              tspDate,
              name,
              iin,
            },
          ];

          checkEdsFile(iin, fileBase64, responseObjects);
        }
      } catch (error) {
        let message;
        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }

        notification.info({
          message: 'Ошибка',
          description: message,
        });
      }

      webSocket.onerror = () => {
        notification.info({
          message: 'Инфо',
          description:
            'Убедитесь пожалуйста что у Вас установлена программа NCLayer и она запущена. Программу можно скачать по адресу https://pki.gov.kz/ncalayer/',
        });
      };
    } else {
      notification.info({
        message: 'Инфо',
        description: 'Выберите пожалуйста файл',
      });
    }
  };

  async function checkEdsFile(iin, fileBase64, responseObjects) {
    const bodyData = {
      documentId:
        agreement.docsform === undefined ? agreement.document_id : undefined,
      isn:
        agreement.docsform !== undefined
          ? agreement.docsform[0].ISN
          : undefined,
      file: fileBase64,
      iin: iin,
      responseObjects: responseObjects,
      refDocIsnClass:
        agreement.docsform !== undefined
          ? agreement.docsform[0].REFDOCISN_CLASS
          : undefined,
      refDocIsn:
        agreement.docsform !== undefined
          ? agreement.docsform[0].REFDOCISN
          : undefined,
    };

    const { data }: any = await api.findFileByIsn(bodyData);
    if (data.success === true) {
      notification.success({
        message: 'Сообщение',
        description: 'Ваша подпись принята.',
      });
      setIsButtonDisabled(false);
    } else {
      notification.info({
        message: 'Ошибка',
        description: data.result,
      });
    }
  }

  return (
    <>
      <Title level={5} type="secondary" className="mb-5">
        Подписать с ЭЦП
      </Title>
      <Row gutter={24} className="mt-6">
        <Col xs={24} xl={8}>
          <Button block onClick={getClickButtonFunction()}>
            Подписать
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default EDS;
