import axios from 'axios';
import Cookies from 'js-cookie';

export const domain =
  process.env.NODE_ENV === 'development' ? null : { domain: 'mycent.kz' };

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'https://mycent.kz/api' : '/api',
});

axiosInstance?.interceptors.request.use(
  (config) => {
    const authToken =
      Cookies.get('token', domain) || Cookies.get('tokenBitrix'); // || localStorage.getItem('token'); // Запасной вариант на случай если в iframe Bitrix не будет работать авторизация

    return {
      ...config,
      headers: {
        Authorization: authToken ? `Bearer ${authToken}` : '',
      },
    };
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;

