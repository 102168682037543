import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useLocation } from "react-router-dom";
import './ReadDocs.scss';

export default function ReadDocs() {

  // для начало получаем путь файла из параметров
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search.replace(/\+/g, '%2B'));
  const path = searchParams.get("path");
  
  // чтение с файлами из котировок не получаеться
  // 'https://mycent.kz/api/quotation/file/show/-C-3-701-C3701759-9464907.pptx'

  // полный путь в котором храниться данный файл
  const fullPath = `https://mycent.kz/storage/${path}`;

  /**
   * получаем расширение файлов
   */
  const file_extension = (file_path) => {
    const file_arr = file_path?.split('.');
    const lastElem = file_arr[file_arr.length - 1];
    return lastElem;
  }

  // путь к файлу и его тип
  const docs = [
    { 
      uri: fullPath,
      fileType: file_extension(path),
    },
  ];
     
  // фильтр для этих форматов что бы открывал в браузере 
  const extensions = {
    png: 'png',
    jpg: 'jpg',
    jpeg: 'jpeg',
    webp: 'webp',
  }

  // если картинка открываем в браузере
  if(extensions[file_extension(path)]) {
    return (
      <img src={fullPath} width="100%" height="100%" alt="image" />
    )
  }
  // если формат pdf открываем в iframe
  if(file_extension(path) === 'pdf' || file_extension(path) === 'PDF') {
    return (
      <div className="pdf-viewer">
        <iframe
          src={fullPath}
          title="PDF Viewer"
          width="100%"
          height="100%"
          className="iframe"
        />
      </div>
    )
  }
  
  // (docx,doc,xlsx,xls,pptx ... ) для этих форматов используем отдельную библиотеку для 
  // чтение (предварительный просмотр)
  else {
    return (
      <div className="doc-viewer">
        <DocViewer 
          documents={docs}
          pluginRenderers={DocViewerRenderers}
        />
      </div>
    );
  }
} 