import axios from 'axios';
import { notification } from 'antd';
import api from '@pages/ISpring/api';

/**
 * Получает токен и переходит на страницу iSpring
 * @param newTab { boolean } - Открывать в новой вкладке
 */
export async function openLearningPage(newTab = true) {
  try {
    const { data } = await api.getIspringToken();

    if (data?.jwt) {
      window.open(
        `https://centrasinsurance.ispringlearn.ru/sso/login/jwt?jwt=${data.jwt}`,
        newTab ? '_blank' : '_self'
      );
    } else {
      notification.info({
        message: 'Ошибка',
        description: 'Получения токена. Попробуйте чуть позже',
      });
    }
  } catch (error) {
    let message;

    if (axios.isAxiosError(error) && error.response) {
      message = error.response.data.message;
    } else {
      message = String(error);
    }

    notification.info({
      message: 'Ошибка',
      description: message,
    });
  }
}
