import React from 'react';
import { Link } from 'react-router-dom';

const NotFound404: React.FC = () => {
  return (
    <div className="a" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h1 style={{ textAlign: 'center' }}>Ошибка 404 — страница не найдена <span style={{ display: 'block' }}><Link to="/">На главную</Link></span></h1>
    </div>
  );
};

export default NotFound404;
