import { Button, Col, Divider, message, Modal, Row, Typography } from 'antd';
import './CustomModalEds.scss';
import axios from 'axios';
import { getErrorMessage } from '@modules/Agreement/CustomEDS/helpers/getErrorMessage';

const { Text, Title } = Typography;
const CustomModalEds = ({ isModalOpen, setIsVisibleModal, cmsInfo, fileBase64, encryptPassword, clearFields, signatureUUID }) => {
	// Осталось добавить правильный uuid для signature.
	const renderField = (fieldName, fieldValue) => (
		<Row gutter={[24, 24]} className="mb-2">
			<Col span={8}>
				<span className="sign-modal__label">{fieldName}</span>
			</Col>
			<Col span={16}>
				<span className="sign-modal__title">{fieldValue}</span>
			</Col>
		</Row>
	);

	const onSign = async () => {
		try {
			await axios.post('https://signature.kz/api/sign', {
				session_uuid: [signatureUUID],
				cert: fileBase64,
				password: encryptPassword,
			});

			message.success("Вы успешно подписали документ");

			clearFields();
		} catch (error) {
			let errorMessage;
			if (axios.isAxiosError(error) && error.response) {
				errorMessage = error.response.data.message;
			} else {
				errorMessage = String(error);
			}

			message.error(getErrorMessage(errorMessage));
		}
	};

	return (
		<Modal
			centered
			title={
				<Divider orientation="left" plain>
					<Title level={4}>Данные ключа</Title>
				</Divider>
			}
			open={isModalOpen}
			onCancel={() => setIsVisibleModal(false)}
			footer={null}
			className="sign-modal"
			closable={false}
		>
			{cmsInfo.common_name && renderField("Подписант", cmsInfo.common_name)}
			{cmsInfo.iin && renderField("ИИН", cmsInfo.iin)}
			{cmsInfo.company_name && renderField("Название компании", cmsInfo.company_name)}
			{cmsInfo.bin && renderField("БИН", cmsInfo.bin)}
			{cmsInfo.start && cmsInfo.end && renderField("Срок действия", `${cmsInfo.start} -  ${cmsInfo.end}`)}
			{cmsInfo.issuer_name && renderField("Имя издателя", cmsInfo.issuer_name)}
			<Row gutter={[12, 12]} className="mt-5">
				<Button
					type="primary"
					size="large"
					className="w-100"
					onClick={onSign}
				>
					Подписать
				</Button>
				<Button
					type="link"
					size="large"
					className="w-100"
					onClick={() => setIsVisibleModal(false)}
				>
					Отменить
				</Button>
			</Row>
		</Modal>
	);
};

export default CustomModalEds;