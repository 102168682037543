import React, { useState } from 'react';

import { Form, Card, Row, Col, Checkbox, Input } from 'antd';
import ServiceCenterFields from './ServiceCenterFields';
import useQuotationJournal from '../hooks/useQuotationJournal';

const { TextArea } = Input;

type underWritingDepartmentProps = {
  getQuotationId: string | undefined;
};

const UnderWritingDepartment = ({
  getQuotationId,
}: underWritingDepartmentProps) => {
  const { state } = useQuotationJournal();

  return (
    <Card hoverable style={{ marginTop: 20, marginBottom: 20 }}>
      <Row gutter={15}>
        <Col span={12}>
          <Form.Item
            name="submitUnderwritingDepartment"
            valuePropName="checked"
          >
            <Checkbox>Отправить в Департамент андеррайтинга</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => prevValues !== curValues}
      >
        {({ getFieldsValue }) => {
          const { submitUnderwritingDepartment } = getFieldsValue();
          if (submitUnderwritingDepartment === true) {
            return (
              <>
                <Col span={24}>
                  <Form.Item
                    name="applicationTextSendUnderwritingDepartment"
                    label="Текст заявки"
                    initialValue={'Полная котировка №' + getQuotationId}
                  >
                    <TextArea rows={4}></TextArea>
                  </Form.Item>
                </Col>

                {state.passingApplicationData.length > 0 ? (
                  <ServiceCenterFields />
                ) : null}
              </>
            );
          }
        }}
      </Form.Item>

      {/* Предстраховой осмотр 
          commented 
      */}
      {/* <Row gutter={15}>
        <Col span={12}>
          <Form.Item
            name="PreInsuranceInspection"
            valuePropName="Предстраховой осмотр"
          >
            <Checkbox
              checked={checkPreInsuranceInspection}
              onChange={(e) => {
                e.target.checked === true
                  ? setCheckPreInsuranceInspection(true)
                  : setCheckPreInsuranceInspection(false);
              }}
            >
              Предстраховой осмотр
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}></Col>
        {checkPreInsuranceInspection && (
          <>
            <Col span={8}>
              <Form.Item
                name="PreInsuranceInspectionDateInspection"
                label="Дата осмотра"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="PreInsuranceInspectionInspectionTime"
                label="Время осмотра"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="PreInsuranceInspectionLocationObject"
                label="Местонахождения объекта"
              >
                <Input />
              </Form.Item>
            </Col>
          </>
        )}
      </Row> */}
    </Card>
  );
};

export default UnderWritingDepartment;
