import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusOutlined,
  SearchOutlined,
  CheckOutlined,
  UploadOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import api from '@modules/Quotation/services';
import {
  Table,
  Button,
  Form,
  Col,
  Row,
  Select,
  Input,
  message,
  DatePicker,
  Upload,
  Tabs,
  notification,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { DatePickerProps, UploadProps } from 'antd';
import { useImmerReducer } from 'use-immer';
import { useEffect, useState } from 'react';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
interface DataType {
  key: string;
  headisn_name: string;
  iin: string;
  relation: string;
}
type Action = {
  type: 'getContractorRelations' | 'loading';
  payload?: any;
};
interface State {
  subheadList: any;
  isLoad: boolean;
}
const initialState: State = {
  subheadList: [],
  isLoad: false,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'getContractorRelations': {
      draft.subheadList = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoad = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};
type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

function ClientRelatedTable({
  subhead,
  settings,
  subjisn,
  searchContractor,
  selectContractor,
  changeState,
  selectRelatedContractor,
  showRelatedInfo,
}) {
  const [editingKey, setEditingKey] = useState<any>();
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const { subheadList, isLoad } = state;
  const [isAdd, setIsAdd] = useState(false);
  const [relatedForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [relatedInfoForm] = Form.useForm();
  const deleteRelated = async (info) => {
    dispatch({ type: 'loading', payload: true });
    try {
      const { data } = await api.contractor.addEditRelatedPerson({
        subjisn: subjisn,
        headisn: info.headisn,
        classisn: info.classisn,
        active: 'N',
      });

      if (data.success) {
        setIsAdd(false);
        const { data } = await api.contractor.getContractorByISN(subjisn);
        selectContractor(data.data);
      }
      dispatch({ type: 'loading', payload: false });
      notification.success({
        message: 'Успешно',
        description: 'Успешно удалено',
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: 'При удалении произошла ошибка',
      });
      dispatch({ type: 'loading', payload: false });
    }
  };
  const columns: ColumnsType<DataType> = [
    {
      dataIndex: 'headisn_name',
      key: 'headisn_name',
      width: '25%',
    },
    {
      dataIndex: 'head_iin',
      key: 'head_iin',
      ellipsis: true,
      width: '18%',
    },
    {
      dataIndex: 'classisn_name',
      key: 'classisn_name',
      ellipsis: true,
      width: '25%',
    },
    {
      key: 'action',
      ellipsis: true,
      width: '20%',
      render: (_, record) => (
        <>
          <Button type="primary" className="mr-1" onClick={() => edit(record)}>
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            className="mr-1"
            onClick={async () => {
              dispatch({ type: 'loading', payload: true });
              setIsAdd(false);
              await showRelatedInfo(record);
              dispatch({ type: 'loading', payload: false });
            }}
          >
            <FullscreenOutlined />
          </Button>
          <Button type="primary" danger onClick={() => deleteRelated(record)}>
            <DeleteOutlined />
          </Button>
        </>
      ),
    },
  ];
  const edit = (record) => {
    setIsAdd(false);
    setEditingKey(record);
  };

  const searchContractorInfo = async () => {
    dispatch({ type: 'loading', payload: true });
    if (relatedForm.getFieldsValue().document) {
      const formInfo = relatedForm.getFieldsValue().document[0];
      try {
        if (
          (formInfo.iin && formInfo.iin.length > 0) ||
          (formInfo.firstname && formInfo.firstname.length > 0) ||
          (formInfo.lastname && formInfo.lastname.length > 0) ||
          (formInfo.parentname && formInfo.parentname.length > 0)
        ) {
          const firstname =
            formInfo.firstname && formInfo.firstname.length > 0
              ? formInfo.firstname
              : ' ';
          const lastname =
            formInfo.lastname && formInfo.lastname.length > 0
              ? formInfo.lastname
              : ' ';
          const parentname =
            formInfo.parentname && formInfo.parentname.length > 0
              ? formInfo.parentname
              : ' ';

          const { data } = await api.contractor.getContractorByIIN({
            ...formInfo,
            org_name: `${lastname} ${firstname} ${parentname}`,
            jur: 'N',
          });
          if (data.data.length == 1) {
            const fullName = data.data[0].FULLNAME.split(' ');

            relatedForm.setFieldsValue({
              document: [
                {
                  lastname: fullName[0],
                  firstname: fullName[1],
                  parentname: fullName[2] ? fullName[2] : '',
                  iin: data.data[0].IIN,
                  subjisn: subjisn,
                  headisn: data.data[0].ISN,
                },
              ],
            });
            dispatch({ type: 'loading', payload: false });
          } else {
            changeState(2, data.data, 'related');
          }
        } else {
          changeState(1, [], 'related');
        }
        dispatch({ type: 'loading', payload: false });
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: 'Контрагент не найден',
        });
        dispatch({ type: 'loading', payload: false });
      }
    } else {
      const formInfo = updateForm.getFieldsValue();

      try {
        if (
          (formInfo.iin && formInfo.iin.length > 0) ||
          (formInfo.firstname && formInfo.firstname.length > 0) ||
          (formInfo.lastname && formInfo.lastname.length > 0) ||
          (formInfo.parentname && formInfo.parentname.length > 0)
        ) {
          const firstname =
            formInfo.firstname && formInfo.firstname.length > 0
              ? formInfo.firstname
              : ' ';
          const lastname =
            formInfo.lastname && formInfo.lastname.length > 0
              ? formInfo.lastname
              : ' ';
          const parentname =
            formInfo.parentname && formInfo.parentname.length > 0
              ? formInfo.parentname
              : ' ';
          const { data } = await api.contractor.getContractorByIIN({
            ...formInfo,
            org_name: `${lastname} ${firstname} ${parentname}`,
            jur: 'N',
          });
          if (data.data.length == 1) {
            const fullName = data.data[0].FULLNAME.split(' ');
            updateForm.setFieldsValue({
              lastname: fullName[0],
              firstname: fullName[1],
              parentname: fullName[2] ? fullName[2] : '',
              iin: data.data[0].IIN,
              subjisn: data.data[0].ISN,
            });
            dispatch({ type: 'loading', payload: false });
          } else {
            changeState(2, data.data, 'related');
          }
        } else {
          changeState(1, [], 'related');
        }
        dispatch({ type: 'loading', payload: false });
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: 'Контрагент не найден',
        });
        dispatch({ type: 'loading', payload: false });
      }
    }
  };
  const onFinish = async (values: any) => {
    dispatch({ type: 'loading', payload: true });
    try {
      const { data } = await api.contractor.addEditRelatedPerson({
        subjisn: subjisn,
        headisn: values.document[0].headisn,
        classisn: values.document[0].classisn,
        active: 'Y',
      });

      if (data.success) {
        setIsAdd(false);
        const { data } = await api.contractor.getContractorByISN(subjisn);
        selectContractor(data.data);
      }
      dispatch({ type: 'loading', payload: false });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: 'При сохранении произошла ошибка',
      });
      dispatch({ type: 'loading', payload: false });
    }
  };
  useEffect(() => {
    if (subhead) {
      dispatch({ type: 'getContractorRelations', payload: subhead });
    }
  }, [subhead]);
  useEffect(() => {
    if (selectRelatedContractor) {
      relatedForm.setFieldsValue({
        document: [
          {
            subjisn: subjisn,
            headisn: selectRelatedContractor.isn,
            lastname: selectRelatedContractor.lastname,
            firstname: selectRelatedContractor.firstname,
            parentname: selectRelatedContractor.parentname,
            iin: selectRelatedContractor.iin,
          },
        ],
      });
      setIsAdd(true);
    }
  }, [selectRelatedContractor]);
  useEffect(() => {
    if (editingKey) {
      const nameArray = editingKey.headisn_name.split(' ');

      updateForm.setFieldsValue({
        ...editingKey,
        firstname: nameArray[1],
        lastname: nameArray[0],
        parentname: nameArray[2],
        classisn: +editingKey.classisn,
      });
    }
  }, [editingKey]);
  const onFinishUpdateForm = async (values) => {
    dispatch({ type: 'loading', payload: true });
    try {
      const { data } = await api.contractor.addEditRelatedPerson({
        ...values,
        subjisn: subjisn,
        headisn: +values.headisn,
        classisn: +values.classisn,
        active: 'Y',
      });
      if (data.success) {
        setEditingKey(null);
        const { data } = await api.contractor.getContractorByISN(subjisn);

        selectContractor(data.data, 'related');
      }
      dispatch({ type: 'loading', payload: false });
    } catch (e) {}
  };
  const sendValue = () => {
    relatedForm.setFieldsValue({ document: [{ subjisn }] });
  };

  return (
    <>
      <CustomLoader spinning={isLoad}>
        <Form
          name="relatedForm"
          onFinish={onFinish}
          style={{ maxWidth: 1000 }}
          form={relatedForm}
        >
          <Form.List name="document">
            {(fields, { add, remove }, { errors }) => (
              <>
                {isAdd && subjisn ? (
                  fields.map(({ key, name }) => (
                    <Row
                      gutter={6}
                      key={key}
                      className="mt-3"
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                        justifyContent: 'space-between',
                      }}
                    >
                      <Form.Item name={[name, 'subjisn']} hidden noStyle>
                        <Input />
                      </Form.Item>
                      <Form.Item name={[name, 'headisn']} hidden noStyle>
                        <Input />
                      </Form.Item>
                      <Col span={4}>
                        <Form.Item name={[name, 'lastname']}>
                          <Input placeholder="Фамилия" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item name={[name, 'firstname']}>
                          <Input placeholder="Имя" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item name={[name, 'parentname']}>
                          <Input placeholder="Отчество" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item name={[name, 'iin']}>
                          <Input placeholder="ИИН" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[name, 'classisn']}
                          rules={[
                            {
                              required: true,
                              message: 'Выберите вид отношеия',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Отношеия"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.full_name ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.full_name ?? '')
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.full_name ?? '').toLowerCase()
                                )
                            }
                            options={
                              typeof settings?.relationisn.prod.children ==
                                'object' &&
                              typeof settings?.relationisn.rel.children ==
                                'object'
                                ? [
                                    ...settings?.relationisn.prod.children,
                                    // ...settings?.relationisn.rel.children,
                                  ]
                                : []
                            }
                            fieldNames={{
                              label: 'full_name',
                              value: 'foreign_id',
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Button type="primary" onClick={searchContractorInfo}>
                        <SearchOutlined />
                      </Button>
                      <Button
                        onClick={() => {
                          setIsAdd(false);
                          // setFiles([]);
                          remove(name);
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        style={{ backgroundColor: '#39c33e' }}
                      >
                        <CheckOutlined />
                      </Button>
                    </Row>
                  ))
                ) : (
                  <Form.Item className="mt-3">
                    <Button
                      type="text"
                      onClick={() => {
                        // add();
                        setIsAdd(true);
                        sendValue();
                      }}
                      style={{
                        width: '100%',
                        color: '#7449ef',
                        fontSize: '16px',
                      }}
                      icon={<PlusOutlined />}
                    >
                      Добавить связанное лицо
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </Form>
        {subheadList.map((doc) => {
          if (editingKey && doc.isn == editingKey.isn) {
            return (
              <Form
                name="updateForm"
                layout="vertical"
                form={updateForm}
                onFinish={onFinishUpdateForm}
              >
                <Row
                  gutter={6}
                  className="mt-3"
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                    justifyContent: 'space-between',
                  }}
                >
                  <Form.Item name={'subjisn'} hidden noStyle>
                    <Input />
                  </Form.Item>
                  <Form.Item name={'isn'} hidden noStyle>
                    <Input />
                  </Form.Item>
                  <Form.Item name={'headisn'} hidden noStyle>
                    <Input />
                  </Form.Item>
                  <Col span={4}>
                    <Form.Item name={'lastname'}>
                      <Input placeholder="Фамилия" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name={'firstname'}>
                      <Input placeholder="Имя" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name={'parentname'}>
                      <Input placeholder="Отчество" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name={'head_iin'}>
                      <Input placeholder="ИИН" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={'classisn'}
                      rules={[
                        {
                          required: true,
                          message: 'Выберите вид отношеия',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Отношеия"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.full_name ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.full_name ?? '')
                            .toLowerCase()
                            .localeCompare(
                              (optionB?.full_name ?? '').toLowerCase()
                            )
                        }
                        options={
                          typeof settings?.relationisn.prod.children ==
                            'object' &&
                          typeof settings?.relationisn.rel.children == 'object'
                            ? [
                                ...settings?.relationisn.prod.children,
                                // ...settings?.relationisn.rel.children,
                              ]
                            : []
                        }
                        fieldNames={{
                          label: 'full_name',
                          value: 'foreign_id',
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Button type="primary" onClick={searchContractorInfo}>
                    <SearchOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      setEditingKey(null);
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                  <Button type="primary" htmlType="submit">
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form>
            );
          } else {
            return (
              <Table
                showHeader={false}
                columns={columns}
                dataSource={[doc]}
                pagination={{
                  position: [
                    'none' as TablePaginationPosition,
                    'none' as TablePaginationPosition,
                  ],
                }}
                rowKey="docno"
              />
            );
          }
        })}
      </CustomLoader>
    </>
  );
}
export default ClientRelatedTable;
