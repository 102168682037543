import axios from "@services/api/axios";


const endpoints = {
    searchIinKias: (iin) => axios.post('/kias/subject', iin),
    getDocInfo: (isn) => axios.post('/kias/doc/info', isn),
    getSubDoc: (isn) => axios.post('/kias/sub/doc', isn),
    getSubAddr: (isn) => axios.post('/kias/sub/addr', isn),
    getSubPhone: (isn) => axios.post('/kias/sub/phone', isn)
};

export default endpoints;
