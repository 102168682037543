import { IRowColumnEditor } from '@modules/Edo/types';
import { Form, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { IUser } from './FieldUser';
import api from '@modules/Edo/services/api';
interface IDayCount {
  settings: IRowColumnEditor;
  name: number;
  docData?: any;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}

function DayCount(props: IDayCount) {
  const [user, setUser] = useState<IUser | null>(null);

  const { settings, name, docData, onChangeByTd, isAdmin, isDisabledStatus } =
    props;

  const form = Form.useFormInstance();

  const isRowColumnEditor = typeof name === 'number';
  const propertyValue = Form.useWatch(['row_column_editor', name], form);

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const class_id = String(docData?.class?.id || docData?.class_id);

  /**
   * Задает значение поля "ИТОГО кол-во дней"
   * @param { number | null } value - присваемое значение
   */
  const setDayCount = (value) => {
    const { row_column_editor } = form.getFieldsValue();
    Object.assign(row_column_editor[name][`id-${settings.id}`], {
      num_value: value,
    });
    form.setFieldsValue({ row_column_editor });
    if (isDisabledStatus && isAdmin && onChangeByTd) {
      onChangeByTd(settings.id, name);
    }
  };

  /**
   * Отправляем запрос на бэк для получения дней отпуска
   * и подставляем полученное значение в поле "ИТОГО кол-во дней"
   * @param { string } dateBeg - Дата, с
   * @param { string } dateEnd - Дата, по
   */
  const handleSelectUser = async (dateBeg: string, dateEnd: string) => {
    if (dateBeg && dateEnd) {
      const { data } = await api.getCountVacationDays({
        dateBeg,
        dateEnd,
        class_id,
      });
      setDayCount(data);
    }
  };

  useEffect(() => {
    if (dateFrom && dateTo) {
      handleSelectUser(dateFrom, dateTo);
    }
  }, [dateFrom, dateTo]);

  /**
   * Следим за изменениями полей "Дата, с" и "Дата, по"
   */
  useEffect(() => {
    if (propertyValue) {
      const formValues = Object.values(propertyValue);

      formValues.map((element: any) => {
        if (
          element.full_name &&
          element.full_name.toLowerCase() === 'дата, с'
        ) {
          if (
            element.date_value &&
            typeof element.date_value === 'object' &&
            Object.keys(element.date_value).length
          ) {
            setDateFrom(element.date_value.format('DD.MM.YYYY'));
          } else {
            setDateFrom(null);
            setDayCount(null);
          }
        } else if (
          element.full_name &&
          element.full_name.toLowerCase() === 'дата, по'
        ) {
          if (
            element.date_value &&
            typeof element.date_value === 'object' &&
            Object.keys(element.date_value).length
          ) {
            setDateTo(element.date_value.format('DD.MM.YYYY'));
          } else {
            setDateTo(null);
            setDayCount(null);
          }
        }
      });
    }
  }, [propertyValue]);

  useEffect(() => {
    if (user) {
      if (isRowColumnEditor) {
        const { row_column_editor } = form.getFieldsValue();

        if (typeof user.adminDay == 'number') {
          Object.assign(row_column_editor[name][`id-${settings.id}`], {
            num_value: user.adminDay,
          });
        }
        form.setFieldsValue({ row_column_editor });
      }
    }
  }, [user]);

  return (
    <Form.Item
      label={settings.field_name}
      name={[name, `id-${settings.id}`, 'num_value']}
      rules={[{ required: settings.required }]}
    >
      <InputNumber
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        parser={(x) => parseFloat(`${x}`.replace(/\s/g, ''))}
        style={{ width: '100%' }}
        disabled={true}
      />
    </Form.Item>
  );
}

export default DayCount;
