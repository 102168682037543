import { useEffect } from 'react';

import Agreement from '@modules/Agreement';

interface AgreementPageProps {
  title: string;
}

function AgreementPage({ title = 'Mycent.kz' }: AgreementPageProps) {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return <Agreement title="Документы на согласование" />;
}

export default AgreementPage;
