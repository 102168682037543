import {
  DeleteOutlined,
  FileTwoTone,
  UploadOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import './EdoUploadForm.scss';
import {
  Button,
  Card,
  Col,
  message,
  Row,
  Space,
  Typography,
  UploadProps,
  Upload,
  Modal,
  Form,
  Popover,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import api from './services/api';
import { useEffect, useState } from 'react';

const { Title } = Typography;
const { Dragger } = Upload;

interface Props {
  id: number;
  files: {
    file_path: string;
    id: number;
    file_name: string;
  }[];
  isDisabledForm: boolean;
  settings: any;
  form?: any;
  isDisabledStatus: any;
}

function EdoUploadForm({
  id,
  files = [],
  isDisabledForm = false,
  settings,
  form,
  isDisabledStatus,
}: Props) {
  const [fileList, setFileList] = useState<any>(files);

  useEffect(() => {}, [fileList]);

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    headers: {
      Authorization: 'Bearer ' + Cookies.get('token'),
    },
    action: 'https://mycent.kz/api/document/save_file',

    onChange(info) {
      const { status } = info.file;

      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }

      if (status === 'done') {
        message.success(`Файл "${info.file.name}" успешно загружен.`);
      } else if (status === 'error') {
        message.error(
          `При загрузке файла "${info.file.name}" произошла ошибка.`
        );
      }
    },
    async onRemove(info) {
      const file = info.response.filter(
        (item) => item.file_name == info.name
      )[0];
      await api.deleteFile({
        file_path: file.file_path,
        id: file.id,
      });
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const content = (
    <div>
      <p>{settings.placeholder_sup}</p>
    </div>
  );

  const info = () => {
    Modal.info({
      width: 600,
      title: 'Необходимые файлы',
      content: (
        <div>
          <p>{settings.placeholder_sup}</p>
        </div>
      ),
      onOk() {},
    });
  };

  const deleteFile = async (file_path, id) => {
    setFileList(fileList.filter((item) => item.id !== id));
    await api.deleteFile({
      file_path,
      id,
    });
  };

  return (
    <Card className="mt-5" style={{ position: 'relative' }}>
      <Title level={5} type="secondary">
        {settings.name}
        {settings.placeholder_sup.length >= 100 ? (
          <QuestionCircleOutlined onClick={info} className="ml-3" />
        ) : (
          <Popover content={content} trigger="click">
            <QuestionCircleOutlined className="ml-3" />
          </Popover>
        )}
      </Title>

      <Dragger
        {...props}
        data={{ file_type: 'D', ref_id: id }}
        disabled={isDisabledForm}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">
          {settings.placeholder ? settings.placeholder_text : ''}
        </p>
        {/*
        <p className="ant-upload-hint">
          Поддержка одиночной или массовой загрузки. Категорически запрещается
          загрузка данных компании или других групповых файлов.
        </p> */}
      </Dragger>

      {fileList && fileList.length !== 0 && (
        <Row className="mt-5">
          <Col span="24">
            <Space wrap={true}>
              {fileList.map(({ file_path, id, file_name }) => (
                <div className="ant-upload-btn" key={id}>
                  <Link
                    to={`/read-docs?path=${file_path}&page=edo`}
                    target="_blank"
                  >
                    <Button
                      target="_blank"
                      key={id}
                      type="text"
                      icon={<FileTwoTone />}
                    >
                      {file_name}
                    </Button>
                  </Link>

                  <Button
                    icon={<VerticalAlignBottomOutlined />}
                    className="ant-upload-icon"
                    href={`https://mycent.kz/storage/${file_path}`}
                    download={file_name}
                    target="_blank"
                  />
                  <Button
                    onClick={() => deleteFile(file_path, id)}
                    className="ant-upload-icon"
                  >
                    <DeleteOutlined />
                  </Button>
                </div>
              ))}
            </Space>
          </Col>
        </Row>
      )}
    </Card>
  );
}

export default EdoUploadForm;
