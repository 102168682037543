import React from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  notification,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import CInput from '@components/UI/input/CInput';
import CButton from '@components/UI/button/CButton';
import './CardMembers.scss';
import contractorFunctions from '@modules/common/Client/Functions';
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import api from '@modules/Quotation/services';
import axios from 'axios';
import { useImmerReducer } from 'use-immer';
import ListMembers from '@modules/Quotation/MembersQuotation/ListMembers';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import SearchClientModal from '@modules/common/Client/SearchClientModal';
import ResultClientModal from '@modules/common/Client/ResultClientModal';
import AddClientModal from '@modules/common/Client/AddClientModal';
type Action = {
  type:
    | 'nextStep'
    | 'search'
    | 'loading'
    | 'hideContractorModal'
    | 'selectContractor'
    | 'selectSubject'
    | 'hideSubjectModal'
    | 'removeSubjectInfo'
    | 'saveContractorList';
  payload?: any;
};

interface State {
  step: number;
  contractorInfo: object | null;
  isLoading: boolean;
  isVisibleSubjectModal: boolean;
  subjectFullInfo: any | null;
  isJurUser: boolean;
  subjectList: [] | null;
  subjectInfo: any[] | null;
}

const initialState: State = {
  step: 0,
  contractorInfo: null,
  subjectInfo: null,
  isLoading: false,
  subjectFullInfo: null,
  isVisibleSubjectModal: false,
  subjectList: null,
  isJurUser: true,
};

const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
    case 'selectSubject':
    case 'selectContractor': {
      draft.isJurUser = action.payload.juridical == 'Y';
      draft.subjectFullInfo = action.payload.data;
      if (action.payload.data) {
        draft.subjectInfo = [
          {
            FULLNAME: action.payload.data.fullname,
            IIN: action.payload.data.iin,
            BIRTHDAY: action.payload.data.birthday,
            ISN: action.payload.data.isn,
          },
        ];
      }
      break;
    }
    case 'hideSubjectModal': {
      draft.step = 0;
      break;
    }
    case 'nextStep': {
      if (action.payload.step) {
        draft.step = action.payload.step;
      } else {
        draft.step = action.payload;
      }
      break;
    }
    case 'saveContractorList': {
      draft.isJurUser = action.payload.isJurUser == 'Y';
      draft.subjectList = action.payload.data;
      break;
    }
    case 'hideContractorModal': {
      draft.step = 0;
      break;
    }
    case 'removeSubjectInfo': {
      draft.subjectInfo = null;
      draft.subjectFullInfo = action.payload;
      if (action.payload?.jur) {
        draft.isJurUser = action.payload.jur == 'Y';
      }
      break;
    }

    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
  }
};

const CardMembers = ({
  member,
  updateMembers,
  deleteMembers,
  index,
  roles,
  agr_isn,
}) => {
  const { foreign_id, full_name } = member;
  const { Text } = Typography;
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const {
    isLoading,
    step,
    subjectFullInfo,
    isVisibleSubjectModal,
    isJurUser,
    subjectList,
    subjectInfo,
  } = state;
  const { agrMembers, setAgrMembers, stateDateQuotation } = useQuotation();

  const searchContractor = async (iin, foreignId) => {
    dispatch({ type: 'search', payload: true });
    try {
      if (iin) {
        const { data } = await api.contractor.getContractorByIIN({
          iin,
        });

        if (data.data.length === 1) {
          if (!data.data[0].ISN) {
            const info = await api.contractor.saveContractorKias({
              xml_isn: data.data[0].XMLISN,
              ext_system_key: data.data[0].EXTSYSTEMKEY,
            });
          } else {
            // empty
          }
        } else {
          // empty
        }

        data.data[0].foreign_id = foreignId;
        updateMembers(data.data[0]);
      } else {
        notification.error({
          message: 'Ошибка',
          description: 'Введите ИИН / БИН',
        });
      }
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      console.log(error);

      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.error({
        message: 'Ошибка',
        description: message,
      });
      dispatch({ type: 'search', payload: false });
    }
  };

  const onChange = (event, foreignId) => {
    if (event.target.value.length === 12) {
      const item = [...agrMembers];
      item[index].subj_iin = event.target.value;
      setAgrMembers(item);
    }

    if (event.target.value.length === 12 && member?.foreign_id) {
      setTimeout(() => {
        searchContractor(event.target.value, foreignId);
      }, 500);
    }
  };

  /**
   * По ISN роли возвращает текстовое наименование
   * @param roleIsn - ISN роли
   */
  const roleIsnToText = (roleIsn) => {
    if (roles.length) {
      return roles.find((role) => role.id === Number(roleIsn))?.label;
    }
  };

  /**
   * Добавляем в массив нового пользователя
   * @param memberRole - isn роли
   */
  const memberRoleChange = (memberRole) => {
    if (!index && index !== 0) return;
    const item = [...agrMembers];
    item[index].foreign_id = memberRole;
    setAgrMembers(item);

    if (member?.subj_iin && member?.foreign_id) {
      searchContractor(member.subj_iin, memberRole);
    }
  };
  const AdvancedOwnerSearch = () => {
    if (member?.foreign_id) {
      dispatch({ type: 'nextStep', payload: 1 });
    } else {
      notification.info({
        message: 'Выберите сначала тип участника',
      });
    }
  };
  const getContractorList = (values) => {
    contractorFunctions.getContractorList({ dispatch, values });
  };
  const selectContractor = async (value, fromInfo) => {
    try {
      dispatch({ type: 'search', payload: true });
      if (!value.ISN && !value.isn) {
        const info = await api.contractor.saveContractorKias({
          xml_isn: value.XMLISN,
          ext_system_key: value.EXTSYSTEMKEY,
        });
        const { data } = await api.contractor.getContractorByISN(info.data.isn);
        dispatch({
          type: 'selectSubject',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      } else {
        const isn = value.ISN ? value.ISN : value.isn;
        const { data } = await api.contractor.getContractorByISN(isn);
        if (member?.foreign_id) {
          data.data.foreign_id = member?.foreign_id;
          updateMembers({
            ...data.data,
            isn,
            agr_isn,
            subj_birthday: data.data.birthday,
            subj_full_name: data.data.fullname,
            subj_iin: data.data.iin,
            subj_isn: data.data.isn,
            ISN: isn,
            user_isn: isn,
          });
          // searchContractor(data.data.iin, isn);
        }
        dispatch({
          type: 'selectSubject',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      }
      dispatch({ type: 'nextStep', payload: 3 });
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      dispatch({ type: 'search', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const handleCancelAddClientModal = () => {
    dispatch({ type: 'hideSubjectModal', payload: 0 });
    dispatch({
      type: 'selectSubject',
      payload: {},
    });
    dispatch({ type: 'nextStep', payload: { from: null } });
  };
  const changeState = (step, data: any | null, fromInfo) => {
    dispatch({ type: 'nextStep', payload: { step, from: fromInfo } });
    if (data !== null) {
      dispatch({ type: 'saveContractorList', payload: { data } });
    }
    if (fromInfo == 'related') {
      dispatch({
        type: 'removeSubjectInfo',
        payload: { ...subjectFullInfo, from: 'related', jur: 'Y' },
      });
    }
  };

  return (
    <CustomLoader spinning={isLoading}>
      <Card
        style={{ backgroundColor: '#f8f8f8' }}
        className="quotation-members mt-5"
        size="small"
      >
        {member.IIN || member.user_isn ? (
          <Row className="align-center justify-space-between" gutter={18}>
            <Col span={6} style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: '18px', fontWeight: '700' }}>
                {roleIsnToText(member.class_isn || member.foreign_id)}
              </Text>
            </Col>
            <Col span={6} className="text-center">
              {member.FULLNAME || member.subj_full_name}
            </Col>
            <Col span={6} className="text-center">
              {member.IIN || member.subj_iin}
            </Col>
            <Col span={6} className="d-flex justify-space-between align-center">
              <div>{member.BIRTHDAY || member.subj_birthday}</div>
              <div>
                <Space
                  size="middle"
                  style={{ width: '100%', justifyContent: 'end' }}
                >
                  <Tooltip
                    placement="top"
                    title="Удалить участника"
                    mouseEnterDelay={1}
                  >
                    <Button
                      type="primary"
                      className="quotation-members__button"
                      danger
                      onClick={() => deleteMembers(member, index)}
                      disabled={
                        roleIsnToText(member.class_isn || member.foreign_id) !==
                          'Подписант от страховщика' &&
                        checkDisabledField(stateDateQuotation.status_isn)
                      }
                    >
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                </Space>
              </div>
            </Col>
          </Row>
        ) : (
          <Row gutter={13}>
            <Col span={6} style={{ alignItems: 'center' }}>
              <ListMembers
                className="w-100"
                member={member}
                placeholder="Тип участника"
                members={roles}
                onChange={memberRoleChange}
              />
            </Col>
            <Col span={12}>
              <Form.Item className="mb-0">
                <CInput
                  className="digitsMask"
                  placeholder="Поиск по ИИН/БИН"
                  disabled={false}
                  size="large"
                  theme="light"
                  onChange={(e) => {
                    onChange(e, foreign_id);
                  }}
                ></CInput>
              </Form.Item>
            </Col>
            <Col span={5}>
              <CButton
                type="primary"
                className="c-button_left"
                block
                onClick={AdvancedOwnerSearch}
              >
                <SearchOutlined />
                Расширенный поиск
              </CButton>
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                className="quotation-members__button"
                danger
                onClick={() => deleteMembers(member, index)}
              >
                <DeleteOutlined />
              </Button>
            </Col>
          </Row>
        )}
      </Card>
      {step == 1 && (
        <SearchClientModal
          isLoading={isLoading}
          from={''}
          contractorFullInfo={subjectFullInfo}
          isVisible={isVisibleSubjectModal}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(dispatch, '')
          }
          getContractorList={getContractorList}
        />
      )}
      {step == 2 && (
        <ResultClientModal
          isLoading={isLoading}
          contractorList={subjectList}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(dispatch, '')
          }
          selectContractor={selectContractor}
          createContractor={() => {}}
          from={''}
        />
      )}
      {step == 3 && (
        <AddClientModal
          isLoading={isLoading}
          isJurUser={isJurUser}
          contractorFullInfo={subjectFullInfo}
          handleCancel={handleCancelAddClientModal}
          changeState={changeState}
          selectContractor={selectContractor}
        />
      )}
    </CustomLoader>
  );
};

export default CardMembers;
