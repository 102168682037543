import React, { useState } from 'react';

import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
} from 'antd';
import locale from 'yup/lib/locale';
import { DynamicFieldsProps } from '../Interfaces/DynamicFieldsAttributes/index.interface';
import api from '@modules/Quotation/services';

import { ModalSqlType } from './ModalSqlType';
import { AxiosError } from 'axios';
import TreeSelectKias from '@modules/TreeSelect/TreeSelectKias';
import useQuotation from '../hooks/useQuotation';
import useDynamicFieldsAttributes from '../hooks/DynamicFieldsAttributes';

const { Option } = Select;

const DynamicFields: React.FC<DynamicFieldsProps> = ({
  type = '',
  name,
  setAgrAttributes,
  agrAttributes = [],
  data,
  userForeignId,
  agrIsn,
  required,
  label = '',
  id,
  setAgrObjAttributes,
  agrObjAttributes = [],
  form,
  disabled,
  dicti,
}) => {
  const { insertDataAgrAttributes, insertDataAgrAttributesObject, Types } =
    useDynamicFieldsAttributes({
      setAgrAttributes: setAgrAttributes,
      agrAttributes: agrAttributes,
      data: data,
      userForeignId: userForeignId,
      agrIsn: agrIsn,
      id_obj: id,
      setAgrObjAttributes: setAgrObjAttributes,
      agrObjAttributes: agrObjAttributes,
    });
  const { colleaguesKiasList, isLoadingColleaguesKiasList } = useQuotation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [attributeMrpData, setAttributeMrpData] = useState(null);

  const searchMrp = async (bodyDataSearchMRPAttribute) => {
    try {
      const { data } = await api.quotation.searchAttributeMRP(
        bodyDataSearchMRPAttribute
      );
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.data?.error) {
        notification.info({
          message: 'Ошибка',
          description: String(err.response.data.error),
        });
      } else if (!err.response?.data?.error) {
        notification.info({
          message: 'Ошибка',
          description: 'Заполните страхователя',
        });
      } else {
        notification.info({
          message: 'Ошибка',
          description: String(error),
        });
      }
    }
  };

  const setMrpDataAndShowModal = async (attributeIsn) => {
    const bodyDataSearchMRPAttribute = {
      agr_isn: agrIsn,
      attr_isn: attributeIsn,
    };
    const data = await searchMrp(bodyDataSearchMRPAttribute);
    if (data?.data) {
      setAttributeMrpData(data.data);
      setIsModalVisible(true);
    }
  };

  function handleOkSql(value) {
    insertDataAgrAttributes(Types.SQL, value);
    setIsModalVisible(false);
    setSqlField(value);
  }

  function setSqlField(value) {
    if (typeof name === 'object') {
      form.setFields([
        {
          name: ['agrObject', name[0], 'agrObjAttribute', name[2]],
          value: value,
        },
      ]);
    } else if (typeof name === 'number') {
      form.setFields([
        {
          name: name,
          value: value,
        },
      ]);
    }
  }

  function handleCancelSql() {
    setIsModalVisible(false);
  }
  return (
    <>
      {type === Types.CHECKBOX && (
        <Form.Item
          name={name}
          valuePropName="checked"
          rules={[
            {
              required: required === 1 ? true : false,
              message: `${data?.attr_name} не выбран!`,
            },
          ]}
        >
          <Checkbox
            style={{ marginTop: 30 }}
            onChange={(value) => {
              insertDataAgrAttributes(Types.CHECKBOX, value.target.checked);
              insertDataAgrAttributesObject(
                Types.CHECKBOX,
                value.target.checked
              );
            }}
            disabled={disabled}
          >
            {data?.attr_name}
          </Checkbox>
        </Form.Item>
      )}
      {(type === Types.DICTI || type === Types.DICTI_CODENAME) && (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: required === 1 ? true : false,
              message: `${data?.attr_name} не выбран!`,
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value) => {
              insertDataAgrAttributes(Types.DICTI, value);
              insertDataAgrAttributesObject(Types.DICTI, value);
            }}
            disabled={disabled}
          >
            {dicti?.children.map((el) => (
              <Option value={el.isn} key={el.isn}>
                {el.full_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {(type === Types.NUMBER || type === Types.INTEGER) && (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: required === 1 ? true : false,
              message: `${data?.attr_name} не выбран!`,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={String(0)}
            onChange={(value) => {
              insertDataAgrAttributes(Types.NUMBER || Types.INTEGER, value);
              insertDataAgrAttributesObject(
                Types.NUMBER || Types.INTEGER,
                value
              );
            }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            disabled={disabled}
          ></InputNumber>
        </Form.Item>
      )}
      {(type === Types.TEXT ||
        type === Types.AGREEMENTCALC ||
        type === Types.AGRCALC ||
        type === Types.AGREEMENT) && (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: required === 1 ? true : false,
              message: `${data?.attr_name} введите!`,
            },
          ]}
        >
          <Input
            onChange={(value) => {
              insertDataAgrAttributes(
                Types.TEXT ||
                  Types.AGREEMENTCALC ||
                  Types.AGRCALC ||
                  Types.AGREEMENT,
                value.target.value
              );
              insertDataAgrAttributesObject(
                Types.TEXT ||
                  Types.AGREEMENTCALC ||
                  Types.AGRCALC ||
                  Types.AGREEMENT,
                value.target.value
              );
            }}
            disabled={disabled}
          />
        </Form.Item>
      )}
      {(type === Types.DEPTEMPL ||
        type === Types.EMPL ||
        type === Types.DEPT) && (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: required === 1 ? true : false,
              message: `${data?.attr_name} не выбран!`,
            },
          ]}
        >
          <TreeSelectKias
            colleaguesKiasList={colleaguesKiasList}
            disabled={isLoadingColleaguesKiasList || disabled}
            onChange={(value) => {
              insertDataAgrAttributes(
                Types.DEPTEMPL || Types.EMPL || Types.DEPT,
                value
              );
              insertDataAgrAttributesObject(
                Types.DEPTEMPL || Types.EMPL || Types.DEPT,
                value
              );
            }}
          />
        </Form.Item>
      )}
      {type === Types.DATE && (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: required === 1 ? true : false,
              message: `${data?.attr_name} введите!`,
            },
          ]}
        >
          <DatePicker
            locale={locale}
            style={{ width: '100%' }}
            onChange={(e, dateString) => {
              insertDataAgrAttributes(Types.DATE, dateString);
              insertDataAgrAttributesObject(Types.DATE, dateString);
            }}
            disabled={disabled}
          ></DatePicker>
        </Form.Item>
      )}
      {type === Types.DOCS && (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: required === 1 ? true : false,
              message: `${data?.attr_name} введите!`,
            },
          ]}
        >
          <InputNumber
            min={String(0)}
            style={{ width: '100% ' }}
            onChange={(value) => {
              insertDataAgrAttributes(Types.DOCS, value);
              insertDataAgrAttributesObject(Types.DOCS, value);
            }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            disabled={disabled}
          />
        </Form.Item>
      )}
      {type === Types.SQL && (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: required === 1 ? true : false,
              message: `${data?.attr_name} введите!`,
            },
          ]}
        >
          <Input.Search
            readOnly
            onSearch={() => {
              setMrpDataAndShowModal(data?.attr_isn);
            }}
            disabled={disabled}
          />
        </Form.Item>
      )}

      <ModalSqlType
        isModalVisible={isModalVisible}
        handleOkSql={handleOkSql}
        handleCancelSql={handleCancelSql}
        attributeMrpData={attributeMrpData}
        attributeIsn={data?.attr_isn}
        insertDataAgrAttributesObject={insertDataAgrAttributesObject}
        insertDataAgrAttributes={insertDataAgrAttributes}
      />
    </>
  );
};

export default DynamicFields;
