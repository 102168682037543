import { IDocRow, IDynamicTable, ITableRow } from './data.type';
import type { ColumnsType } from 'antd/es/table';
import { Table, Descriptions, Form, Input, Col, Row } from 'antd';
import { getDescriptionItem } from './getDescriptionItem';
import { DynamicDescription } from './DynamicDescription';
import api from '@modules/Agreement/services/api';
import { useState } from 'react';
interface IProps {
  agreementDocument: IDynamicTable;
}

function DynamicTable(props: IProps) {
  const { agreementDocument } = props;
  const [print, setPrint] = useState<any>();

  let isTabel: any;
  if (
    agreementDocument?.Docs?.docs?.[0] &&
    agreementDocument.Docs.docs[0].classisn_name === 'Табель'
  ) {
    isTabel = true;
  } else if (agreementDocument?.Docs?.docs?.[0]) {
    isTabel = true;
  } else if (agreementDocument?.Docs === 'Документ не общей формы') {
    isTabel = false;
  }

  let dynamicColumns: ColumnsType<any> = [];
  let dynamicData: any[] = [];
  let printData: any[] = [];

  if (agreementDocument?.DocSpecs?.docrow?.length) {
    const copyDocRow: ITableRow[] = JSON.parse(
      JSON.stringify(agreementDocument.DocSpecs.docrow)
    );

    copyDocRow.map(async (element) => {
      if (parseInt(element.displayno)) {
        const tempValue = {
          title: element.fieldname,
          key: element.displayno,
          dataIndex: element.rowcolumn.toUpperCase() + '_' + 'VALUE',
        };
        dynamicColumns.splice(parseInt(element.displayno), 0, tempValue);
      }
    });

    dynamicColumns.push({
      title: 'Изменено',
      key: 'UPDATED',
      dataIndex: 'UPDATED',
    });

    dynamicColumns.push({
      title: 'Автор',
      key: 'UPDATEDBYNAME',
      dataIndex: 'UPDATEDBYNAME',
    });

    /**
     * Если таблица - Табель. Лист согласования, то
     * фиксируем шапку таблицы и колонку ФИО сотрудника
     * при скролле
     */
    if (isTabel) {
      for (const element of dynamicColumns) {
        switch (String(element?.title).toLowerCase()) {
          case 'фио сотрудника':
            element.width = 150;
            element.fixed = 'left';
            break;
          case 'должность':
            element.width = 110;
            element.fixed = 'left';
            break;
          case 'изменено':
          case 'автор':
            element.width = 110;
            break;
          case 'норма дней/часов':
            element.width = 78;
            break;
          case 'дней':
          case 'часов':
            element.width = 80;
            break;
          case 'од':
          case 'ож':
          case 'бс':
          case 'пр':
            element.width = 60;
            break;
          default:
            element.width = 50;
            break;
        }
      }
    }
  }
  const getPrintDoc = () => {
    if (!print || print.length == 0) {
      agreementDocument.DocRow.map(async (item) => {
        const a = await api.getDocPrint({ document_isn: item.DOCISN });
        const info = a.data;
        if (print && print.length > 0) {
          setPrint([...print, { docisn: item.DOCISN, ...info }]);
        } else {
          setPrint([{ docisn: item.DOCISN, ...info }]);
        }
      });
    }
  };

  if (agreementDocument.DocRow.length) {
    const copyData: IDocRow[] = JSON.parse(
      JSON.stringify(agreementDocument.DocRow)
    );

    copyData.map(async (element) => {
      if (parseInt(element.ORDERNO)) {
        dynamicData.splice(parseInt(element.ORDERNO), 0, element);
      } else {
        dynamicData.push(element);
      }
    });
  }

  if (!dynamicColumns.length && !dynamicData.length) {
    return null;
  }

  return (
    <>
      {isTabel ? (
        <Table
          bordered
          columns={dynamicColumns}
          dataSource={dynamicData}
          rowKey={(record) => record.ISN}
          scroll={{ x: 1000, y: isTabel ? 600 : undefined }}
          expandable={{
            expandedRowRender: (record) => {
              if (record?.PRIKAZ_KADRY !== null) {
                let copyData = record.PRIKAZ_KADRY;
                const docPrint = print?.filter(
                  (item) => item.docisn == record.DOCISN
                )[0];
                copyData = copyData.split(/\r/);
                return copyData.map((item, index) => {
                  if (index === 0) {
                    return <h3>{item}</h3>;
                  } else {
                    const splitData = item.split(':');
                    if (splitData.length > 1) {
                      return (
                        <>
                          <p>
                            <b>{splitData[0]}:</b>
                            {splitData[1]}
                          </p>
                          {docPrint ? (
                            <a
                              href={`https://docs.google.com/gview?url=${docPrint.file_path}`}
                              target="_blank"
                            >
                              {docPrint.file_name}
                            </a>
                          ) : (
                            ''
                          )}
                        </>
                      );
                    }
                  }
                });

                // if (newArray.length) {
                //   if (newArray[0].doc_info) {
                //     return (
                //       <>
                //         <Descriptions title={newArray[0].doc_info} column={1}>
                //           {newArray.map((item) => DynamicDescription(item))}
                //         </Descriptions>
                //         {docPrint ? (
                //           <a
                //             href={`https://docs.google.com/gview?url=${docPrint.file_path}`}
                //             target="_blank"
                //           >
                //             {docPrint.file_name}
                //           </a>
                //         ) : (
                //           ''
                //         )}
                //       </>
                //     );
                //   }
                // }
              }
            },
            rowExpandable: (record) => {
              // getPrintDoc();
              // print.filter(item => item.})

              return record.PRIKAZ_KADRY !== null;
            },
            onExpandedRowsChange: (record: any) => {
              if (record.length > 0) {
                getPrintDoc();
              }
            },
          }}
        />
      ) : (
        <>
          <Row gutter={24}>
            {agreementDocument.Docs.docform.map((elem) => {
              if (Number(elem.field_hidden) !== 1) {
                return (
                  <Col span={24} sm={12} md={8}>
                    <Form.Item
                      label={elem.field_name}
                      name={elem.field_id}
                      initialValue={elem.field_value_name}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                );
              }
            })}
          </Row>
          {agreementDocument.Docs.row_headers && (
            <Table
              columns={agreementDocument.Docs.row_headers
                .filter((elem) => Number(elem.hidden) !== 1)
                .map((elem) => {
                  return {
                    title: elem.title,
                    key: elem.key,
                    dataIndex: elem.key,
                  };
                })}
            />
          )}
        </>
      )}
    </>
  );
}

export default DynamicTable;
