import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import SubjectCard from '../ObjectQuotation/ObjectCardDynamic/SubjectCard';
import {
  Button,
  Card,
  Col,
  Form,
  notification,
  Row,
  Select,
  Typography,
  Modal,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import api from '@modules/Quotation/services';

import MainQuotation from '../MainQuotation';
import useAuth from '../../../hooks/useAuth';
import { ICreateQuotation } from '../Interfaces/CreateQuotation/index.interface';
import useQuotation from '../hooks/useQuotation';
import CSelect from '@components/UI/select/CSelect';
import CButton from '@components/UI/button/CButton';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const { Title } = Typography;
const { Option } = Select;

const CreateQuotation: React.FC<ICreateQuotation> = memo(
  ({ transitionQuotation = false }) => {
    const [createNewQuotation, setCreateNewQuotation] = useState(false);
    const [quotationIsnUserSelected, setQuotationIsnUserSelected] = useState(0);
    const [quotationId, setQuotationId] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [loadingNewQuotation, setLoadingNewQuotation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();
    const [contragentForm] = Form.useForm();
    const navigate = useNavigate();
    const auth = useAuth();

    const [productIsn, setProductIsn] = useState<any>(null);
    const params = useParams();
    const [bitrixId, setBitrixId] = useState<any>(null);

    if (params.quotationId) {
      setQuotationId(params.quotationId);
    }
    const { productList, setProductSelectedQuotation } = useQuotation();

    const makeRequestToGetFieldsAndNavigateToQuotationComponent = async () => {
      setLoadingNewQuotation(true);
      setCreateNewQuotation(false);
      if (quotationId) {
        navigate(`/quotation/${quotationId}`);
      }
    };

    useEffect(() => {
      const urlSearchString = window.location.search;
      setBitrixId(new URLSearchParams(urlSearchString).get('bitrix_id'));
    }, []);

    function dataForEmptyQuotationByUrl(user_isn, product_isn) {
      return {
        user_isn: user_isn,
        product_isn: product_isn,
        connection: 'centras',
        bitrix_id: bitrixId,
      };
    }

    async function getQuotationFieldsAndDataByIdAndSetResponse() {
      const { data } = await api.quotation.getFullQuotation(productIsn);
      setProductSelectedQuotation(data.data);
    }

    async function getEmptyQuotationByUrlAndSetKiasIsn() {
      const { data } = await api.quotation.getEmptyQuotation(
        dataForEmptyQuotationByUrl(
          auth.user.data.user_info.foreign_id,
          productIsn
        )
      );
      setQuotationId(data?.data?.quotation_id);
    }

    function setFieldsMethodToProductSelect() {
      form.setFieldsValue({ productSelectMain: productIsn });
      setCreateNewQuotation(true);
    }

    async function createContractWithoutQuotation(info) {
      const { data } = await api.quotation.createContractWithoutQuotation(info);
      navigate(`/contract/${data.data.agreement[0].isn}`);
    }
    useEffect(() => {
      if (!productIsn) return;
      (async () => {
        setIsLoading(true);
        if (Number(productIsn) === 221711 || Number(productIsn) === 471391) {
          setIsOpenModal(true);
        } else {
          try {
            getQuotationFieldsAndDataByIdAndSetResponse();
            getEmptyQuotationByUrlAndSetKiasIsn();
            setFieldsMethodToProductSelect();
          } catch (error) {
            let message;
            if (axios.isAxiosError(error)) {
              message = error.message;
            } else {
              message = String(error);
            }
            notification.info({
              message: 'Ошибка',
              description: message,
            });
          } finally {
            setIsLoading(false);
          }
        }
      })();
    }, [productIsn]);

    useEffect(() => {
      if (quotationId) {
        setCreateNewQuotation(false);
        makeRequestToGetFieldsAndNavigateToQuotationComponent();
      }
    }, [quotationId]);

    const createQuotationAndMakeRequest = async () => {
      if (quotationIsnUserSelected === 0) return;
      setProductIsn(quotationIsnUserSelected);
    };

    function backToJournal() {
      navigate(`/quotation`);
    }
    function createContract() {
      (async () => {
        try {
          if (contragentForm.getFieldsValue().contr_isn) {
            setIsLoading(true);
            createContractWithoutQuotation({
              product_isn: productIsn,
              user_isn: auth.user.data.user_info.foreign_id,
              contr_isn: contragentForm.getFieldsValue().contr_isn,
            });
          } else {
            notification.error({
              message: 'Контрагент обязателен!',
            });
          }
        } catch (error) {
          let message;
          if (axios.isAxiosError(error)) {
            message = error.message;
          } else {
            message = String(error);
          }
          notification.info({
            message: 'Ошибка',
            description: message,
          });
        } finally {
          setIsLoading(false);
        }
      })();
    }
    function closeModal() {
      setIsOpenModal(false);
      setIsLoading(false);
    }

    return (
      <div className="main-quotation">
        {transitionQuotation === false ? (
          !createNewQuotation ? (
            <CustomLoader spinning={loadingNewQuotation}>
              <Card key={quotationIsnUserSelected}>
                <Button
                  style={{
                    backgroundColor: 'white',
                    borderColor: 'white',
                    cursor: 'pointer',
                    padding: 0,
                  }}
                  onClick={backToJournal}
                >
                  <ArrowLeftOutlined
                    style={{ fontSize: 26, marginRight: 220 }}
                  />
                  <h3 style={{ marginTop: -30, marginRight: 30 }}>
                    Назад к журналу
                  </h3>
                </Button>
                <Title className="mt-5 main-quotation__section-title" level={3}>
                  Создать полную котировку
                </Title>

                <Form
                  className="c-form"
                  form={form}
                  name="basic"
                  layout="vertical"
                >
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="productSelectMain"
                        label="Продукт"
                        rules={[
                          {
                            required: true,
                            message: 'Продукт не выбран!',
                          },
                        ]}
                      >
                        <CSelect
                          showSearch
                          onSelect={setQuotationIsnUserSelected}
                          placeholder="Выберите Продукт"
                          notFoundContent="Отсутствует данные"
                          size="large"
                          filterOption={(input, option) =>
                            (option!.children as unknown as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.full_name ?? '')
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.full_name ?? '').toLowerCase()
                              )
                          }
                          opti
                        >
                          {productList
                            ?.sort((a, b) => {
                              if (a.full_name < b.full_name) {
                                return -1;
                              }
                              if (a.full_name > b.full_name) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((product) => (
                              <Option
                                value={product.foreign_id}
                                key={product.foreign_id}
                              >
                                {product.full_name}
                              </Option>
                            ))}
                        </CSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item>
                        <CButton
                          type="primary"
                          htmlType="submit"
                          block
                          loading={isLoading}
                          onClick={createQuotationAndMakeRequest}
                        >
                          Создать котировку
                        </CButton>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
              <Modal
                title="Поиск контрагента"
                open={isOpenModal}
                onOk={createContract}
                onCancel={closeModal}
              >
                <Form form={contragentForm} style={{ padding: '20px 0' }}>
                  <SubjectCard
                    key={`contr_isn`}
                    elem={{
                      column_name: 'contr_isn',
                      full_name: `fullname-contr_isn`,
                      label: '',
                    }}
                    stateDateQuotation={{ status: '' }}
                    mainForm={contragentForm}
                    col={24}
                  />
                </Form>
              </Modal>
            </CustomLoader>
          ) : (
            <CustomLoader spinning={isLoading}>
              <MainQuotation />
            </CustomLoader>
          )
        ) : null}
      </div>
    );
  }
);

export default CreateQuotation;
