import { useCallback, useEffect, useState } from 'react';
import api from '@modules/Quotation/services';
import { IStatutesItem } from '@modules/Quotation/Interfaces/MainQuotation/index.interface';
import catchErrorCustomAxios from '@services/api/catchErrors';


const useStatuses = () => {
  const [statusList, setStatusList] = useState<IStatutesItem[]>([]);

  const getStatusList = useCallback(() => {
    (async () => {
      try {
        const { data } = await api.quotation.getQuotationStatuses();
        setStatusList(data.data.children);
      } catch (error) {
        let message;
        catchErrorCustomAxios(error, message);
      } finally {
      }
    })();
  }, []);

  useEffect(() => {
    getStatusList();
  }, [getStatusList]);

  return { statusList };
};

export default useStatuses;
