import { IRowColumnEditor } from '@modules/Edo/types';

import { HandbookListType } from '../../../../hooks/useHandbook';
import { Form, Select } from 'antd';

interface IDepartmentUser {
  settings: IRowColumnEditor;
  handbookList: HandbookListType;
  name: number;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}
function SQLField(props: IDepartmentUser) {
  const {
    settings,
    handbookList,
    name,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;

  const form = Form.useFormInstance();

  const isRowColumnEditor = typeof name === 'number';

  const onChangeDuty = (value) => {
    let duty_name = handbookList.filter((item) => item.ISN == value)[0];

    if (isRowColumnEditor) {
      const { row_column_editor } = form.getFieldsValue();
      Object.assign(row_column_editor[name][`id-${settings.id}`], {
        duty: duty_name.FULLNAME,
        num_value: value,
        foreign_id: duty_name.ISN,
        char_value: duty_name.FULLNAME,
      });
      form.setFieldsValue({ row_column_editor });
      if (isDisabledStatus && isAdmin && onChangeByTd) {
        onChangeByTd(settings.id, name);
      }
    }
  };

  return (
    <Form.Item
      label={settings.field_name}
      name={
        isRowColumnEditor
          ? [name, `id-${settings.id}`, 'duty']
          : [`id-${settings.id}`, 'duty']
      }
      rules={[{ required: settings.required }]}
    >
      <Select
        options={handbookList}
        showSearch={true}
        style={{ width: '100%' }}
        fieldNames={{ label: 'FULLNAME', value: 'ISN' }}
        filterOption={(input, option) =>
          (option?.FULLNAME ?? '').toLowerCase().includes(input.toLowerCase())
        }
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        onChange={onChangeDuty}
        allowClear={true}
      />
    </Form.Item>
  );
}

export default SQLField;
