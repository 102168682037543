import api from '@services/api';
import { IResetDocument } from '@services/api/endpoints/edo';
import { Button, notification, Popconfirm } from 'antd';
import './index.scss';

interface Props {
  document_id: number;
  disabled?: boolean;
}

function EditLS({ document_id, disabled }: Props) {
  const onYes = async () => {
    let response: IResetDocument = await api.edo.resetDocument(document_id);
    if (response.success === true) {
      showNotification(response.message);
      refreshPage();
    } else {
      showNotification(response.message);
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const showNotification = (message: string) => {
    notification.info({
      message: 'Уведомление',
      description: message,
    });
  };

  return (
    <Popconfirm
      title="Вы хотите очистить ЛС?"
      okText="Да"
      cancelText="Нет"
      onConfirm={onYes}
    >
      <Button type="primary" className="edit-ls" disabled={disabled ? disabled : false}>
        Очистить ЛС
      </Button>
    </Popconfirm>
  );
}

export default EditLS;
