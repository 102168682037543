import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Radio,
  Space,
  Button,
  Tabs,
  InputNumber,
} from 'antd';
import dayjs from 'dayjs';
import CRadio from '@components/UI/radio/CRadio';
import ClientDocumentTable from './ClientDocumentTable';
import ClientRequisitesTable from './ClientRequisitesTable';
import api from '@modules/Quotation/services';

const { Search } = Input;
function IndividualClient({
  contractorFullInfo,
  settings,
  form,
  selectContractor,
  saveContractorInfo,
  relatedInfo,
}) {
  const [docs, setDocs] = useState<any>();
  const [isRequiredIIN, setIsRequiredIIN] = useState<boolean>(false);
  useEffect(() => {
    if (contractorFullInfo?.isn) {
      if (contractorFullInfo.docno) {
        const data = contractorFullInfo.docs.map((item) => {
          if (item.docno == contractorFullInfo.docno) {
            return { ...item, mainDoc: true };
          } else {
            return { ...item, mainDoc: false };
          }
        });
        const findMain = data?.filter((item) => item.mainDoc);
        if (findMain && findMain.length > 0) {
          setDocs([...findMain, ...data.filter((item) => !item.mainDoc)]);
        } else {
          setDocs([
            {
              docno: contractorFullInfo.docno,
              docdate: contractorFullInfo.docdate,
              classisn: +contractorFullInfo.docclassisn,
              classisn_name: contractorFullInfo.docclassisn_name,
              handby: contractorFullInfo.docissuedby,
              mainDoc: true,
            },
            ...data,
          ]);
        }
      } else {
        setDocs(contractorFullInfo.docs);
      }
      form.setFieldsValue({
        ...contractorFullInfo,
        country: +contractorFullInfo.countryisn,
        subjisn: contractorFullInfo.isn,
        docclass: contractorFullInfo.docclassisn,
        sex: contractorFullInfo.sex
          ? contractorFullInfo.sex == 'М'
            ? '1'
            : '0'
          : null,
        birthday: contractorFullInfo.birthday
          ? dayjs(contractorFullInfo.birthday, 'DD-MM-YYYY')
          : '',
        docser: 'N056',
        benefits:
          contractorFullInfo.docs?.filter(
            (doc) =>
              doc.classisn == 221719 ||
              doc.classisn == 221720 ||
              doc.classisn == 221721 ||
              doc.classisn == 221722 ||
              doc.classisn == 221723
          ).length > 0
            ? 'Y'
            : 'N',
        phone: contractorFullInfo.contacts
          ? contractorFullInfo.contacts?.filter(
              (item) => item.classisn_name == 'Телефон мобильный'
            )[0]?.phone
          : '',
        email: contractorFullInfo.contacts
          ? contractorFullInfo.contacts?.filter(
              (item) => item.classisn_name == 'E-mail контактный'
            )[0]?.phone
          : '',
        address: contractorFullInfo.address
          ? contractorFullInfo.address[0]?.address
          : '',
      });
    }
  }, [contractorFullInfo]);
  useEffect(() => {
    if (relatedInfo?.isn) {
      if (relatedInfo.docno) {
        const data = relatedInfo.docs?.map((item) => {
          if (item.docno == relatedInfo.docno) {
            return { ...item, mainDoc: true };
          } else {
            return { ...item, mainDoc: false };
          }
        });
        const findMain = data?.filter((item) => item.mainDoc);
        if (findMain && findMain.length > 0) {
          setDocs(data);
        } else {
          setDocs([
            {
              docno: relatedInfo.docno,
              docdate: relatedInfo.docdate,
              classisn: +relatedInfo.docclassisn,
              classisn_name: relatedInfo.docclassisn_name,
              handby: relatedInfo.docissuedby,
              mainDoc: true,
            },
            ...data,
          ]);
        }
      } else {
        setDocs(relatedInfo.docs);
      }
      form.setFieldsValue({
        ...relatedInfo,
        country: +relatedInfo.countryisn,
        subjisn: relatedInfo.isn,
        docclass: relatedInfo.docclassisn,
        sex: relatedInfo.sex ? (relatedInfo.sex == 'М' ? '1' : '0') : null,
        birthday: dayjs(relatedInfo.birthday, 'DD-MM-YYYY'),
        benefits:
          relatedInfo.docs?.filter(
            (doc) =>
              doc.classisn == 221719 ||
              doc.classisn == 221720 ||
              doc.classisn == 221721 ||
              doc.classisn == 221722 ||
              doc.classisn == 221723
          ).length > 0
            ? 'Y'
            : 'N',
        phone: relatedInfo.contacts
          ? relatedInfo.contacts?.filter(
              (item) => item.classisn_name == 'Телефон мобильный'
            )[0]?.phone
          : '',
        email: relatedInfo.contacts
          ? relatedInfo.contacts?.filter(
              (item) => item.classisn_name == 'E-mail контактный'
            )[0]?.phone
          : '',
        address: relatedInfo.address ? relatedInfo.address[0]?.address : '',
      });
    }
  }, [relatedInfo]);
  const onSearch = async () => {
    const { data } = await api.contractor.getContractorKBM(
      contractorFullInfo.isn
    );
    form.setFieldValue('kbm', data.data);
  };
  const onChangeCountry = (e) => {
    const data = form.getFieldsValue();
    if (e === 9515) {
      form.setFieldValue('resident', 'Y');
    } else {
      form.setFieldValue('resident', 'N');
    }
  };
  const onChangeStatus = (e) => {
    if (e.target.value === 'Y') {
      setIsRequiredIIN(true);
    } else {
      setIsRequiredIIN(false);
    }
  };
  useEffect(() => {
    if (!contractorFullInfo && !relatedInfo) {
      form.setFieldValue('resident', 'Y');
      setIsRequiredIIN(true);
    }
  }, []);

  return (
    <>
      <Form
        name="individualContractor"
        form={form}
        onFinish={saveContractorInfo}
        className="c-form"
        layout="vertical"
      >
        <Row gutter={8} className="mt-5">
          <Form.Item name="subjisn" hidden noStyle>
            <Input disabled />
          </Form.Item>
          <Form.Item name="docclass" hidden noStyle>
            <Input disabled />
          </Form.Item>
          <Form.Item name="juridical" hidden noStyle>
            <Input disabled />
          </Form.Item>
          <Form.Item name="docdate" hidden noStyle>
            <Input disabled />
          </Form.Item>
          <Form.Item name="docno" hidden noStyle>
            <Input disabled />
          </Form.Item>
          <Form.Item name="docser" hidden noStyle>
            <Input disabled />
          </Form.Item>
          <Col span={6}>
            <Form.Item
              name="iin"
              rules={[
                { required: isRequiredIIN, message: 'Обязательное поле!' },
              ]}
              label="ИИН"
            >
              <Input
                maxLength={12}
                type="number"
                style={{ width: '100%' }}
                placeholder="ИИН"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="lastname"
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              label="Фамилия"
            >
              <Input placeholder="Фамилия" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="firstname"
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              label="Имя"
            >
              <Input placeholder="Имя" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="parentname" label="Отчество">
              <Input placeholder="Отчество" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="birthday" label="Дата рождения">
              <DatePicker
                placeholder="Дата рождения"
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="birthday" label="Водительский стаж">
              <DatePicker
                placeholder="Водительский стаж"
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="resident"
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              label="Статус"
            >
              <CRadio
                optionType="button"
                style={{ width: '100%' }}
                buttonStyle="solid"
                disabled={contractorFullInfo}
                onChange={onChangeStatus}
                options={[
                  { label: 'Резидент', value: 'Y' },
                  { label: 'Нерезидент', value: 'N' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="country" label="Страна">
              <Select
                style={{ width: '100%' }}
                placeholder="Страна"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.full_name ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                fieldNames={{ label: 'full_name', value: 'foreign_id' }}
                options={settings?.countryisn.children}
                onChange={onChangeCountry}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="phone" label="Номер телефона">
              <Input placeholder="Номер телефона" allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="address" label="Адрес">
              <Input placeholder="Адрес" allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="ip_name" label={'Наименование "КХ/ИП"'}>
              <Input placeholder={'Наименование "КХ/ИП"'} allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="email" label="Электронная почта">
              <Input placeholder="Электронная почта" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Space.Compact style={{ width: '100%' }}>
              <Form.Item name="kbm" style={{ width: '60%' }}>
                <Input placeholder="КБМ" disabled />
              </Form.Item>
              <Button
                type="primary"
                onClick={onSearch}
                style={{ width: '40%' }}
              >
                {' '}
                Получить
              </Button>
            </Space.Compact>
          </Col>
          <Col span={6}>
            <Form.Item name="sex">
              <CRadio
                title="Пол :"
                titleSpan={5}
                optionType="button"
                style={{ width: '100%' }}
                buttonStyle="solid"
                options={[
                  { label: 'Муж', value: '1' },
                  { label: 'Жен', value: '0' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="benefits">
              <CRadio
                optionType="button"
                disabled={true}
                title="Льготы :"
                titleSpan={7}
                style={{ width: '100%' }}
                buttonStyle="solid"
                options={[
                  { label: 'Да', value: 'Y' },
                  { label: 'Нет', value: 'N' },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {contractorFullInfo?.isn && (
        <Tabs
          defaultActiveKey="1"
          type="card"
          style={{ marginBottom: 32 }}
          items={[
            {
              label: 'Документы',
              key: '1',
              children: (
                <ClientDocumentTable
                  docs={docs}
                  subjisn={contractorFullInfo ? contractorFullInfo.isn : []}
                  settings={settings}
                  juridical={'N'}
                  selectContractor={selectContractor}
                  isRelated={false}
                />
              ),
            },
            {
              label: 'Банковские реквизиты',
              key: '2',
              children: (
                <ClientRequisitesTable
                  bank={contractorFullInfo ? contractorFullInfo.bank : []}
                  settings={settings}
                />
              ),
            },
          ]}
        />
      )}
      {relatedInfo?.isn && (
        <Tabs
          defaultActiveKey="1"
          type="card"
          style={{ marginBottom: 32 }}
          items={[
            {
              label: 'Документы',
              key: '1',
              children: (
                <ClientDocumentTable
                  docs={docs}
                  isRelated={true}
                  subjisn={relatedInfo ? relatedInfo.isn : []}
                  settings={settings}
                  juridical={'N'}
                  selectContractor={selectContractor}
                />
              ),
            },
            {
              label: 'Банковские реквизиты',
              key: '2',
              children: (
                <ClientRequisitesTable
                  bank={contractorFullInfo ? contractorFullInfo.bank : []}
                  settings={settings}
                />
              ),
            },
          ]}
        />
      )}
      <Button
        style={{ width: '100%' }}
        type="primary"
        form="individualContractor"
        htmlType="submit"
        // onClick={saveContractorInfo}
      >
        Сохранить
      </Button>
    </>
  );
}
export default IndividualClient;
