import axios from '../axios';

interface IFileInstall {
  file_path: string;
  file_name: string;
}

const endpoints = {
  getDocInfoByAgrIsn: (docIsn: string) =>
    axios.post('/quotation/docs', {
      doc_isn: docIsn,
    }),

  installFile: (values: IFileInstall) =>
    axios.post('/file', values, { responseType: 'blob' }),
};

export default endpoints;
