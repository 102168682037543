import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Card, Form, Typography } from 'antd';
import api from '@modules/Quotation/services';

import ObjectQuotation from '../ObjectQuotation/index';
import DateQuotation from '../DateQuotation';
import AdditionalAttributes from '../AdditionalAttributes';
import Clauses from '../ClausesQuotation';
import FilesQuotation from '../Files';
import UnderWritingDepartment from '../UnderWritingDepartment';
import MembersQuotation from '../MembersQuotation';
import catchErrorCustomAxios from '../../../services/api/catchErrors';
import MainButtons from '../MainButtons';
import { validateForms } from '../MainButtons/utils/validateForms';
import ContractDocument from '../ContractDocuments';
import useQuotation from '../hooks/useQuotation';

const { Title } = Typography;

const BitrixQuotation = () => {
  const [quotationId, setQuotationId] = useState('');
  const [agrIsn, setAgrIsn] = useState(0);

  const [userForeignId, setUserForeignId] = useState(0);

  const [searchParams] = useSearchParams();
  const isBitrix = searchParams.get('isBitrix');
  const managerIsn = searchParams.get('managerIsn');
  const clientIin = searchParams.get('clientIin');
  const hash = searchParams.get('hash');
  const productIsn = searchParams.get('productIsn');

  const { productList, setProductSelectedQuotation } = useQuotation();

  const [form] = Form.useForm();
  const [clauseForm] = Form.useForm();
  const [dateForm] = Form.useForm();
  const [membersForm] = Form.useForm();
  const [additionalAttributesForm] = Form.useForm();
  const [objectForm] = Form.useForm();
  const [fileForm] = Form.useForm();
  const [documentForm] = Form.useForm();

  useEffect(() => {
    setUserForeignId(Number(managerIsn));
  }, [managerIsn]);

  const getQuotationName = () => {
    if (productIsn === null) return;
    let result = productList?.filter((el) => el.foreign_id == productIsn);
    return result[0]?.full_name;
  };

  useEffect(() => {
    if (productIsn === null) return;
    (async () => {
      try {
        const response = await api.quotation.getFullQuotation(productIsn);
        setProductSelectedQuotation(response.data.data);
        form.setFieldsValue({ productSelectMain: productIsn });
      } catch (error) {
        let message;
        catchErrorCustomAxios(error, message);
      } finally {
      }
    })();
  }, [productIsn]);

  useEffect(() => {
    (async () => {
      const responseData = {
        user_isn: managerIsn,
        product_isn: productIsn,
        connection: 'centras',
      };
      try {
        const { data } = await api.quotation.getEmptyQuotation(responseData);
        setAgrIsn(data?.data?.id);
        setQuotationId(data?.data?.quotation_id);
      } catch (error) {
        let message;
        catchErrorCustomAxios(error, message);
      }
    })();
  }, [managerIsn]);

  const getQuotationId = () => {
    if (quotationId) return quotationId;
  };

  return (
    <div style={{ paddingBottom: 200 }}>
      <Card
        style={{
          marginTop: 20,
        }}
      >
        <Title level={4}>
          Полная котировка по продукту {getQuotationName()} <br></br>ID
          Котировки {getQuotationId()}
        </Title>
      </Card>

      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          validateForms(forms);
        }}
      >
        <Form name="dateQuotation" form={dateForm} layout="vertical">
          {/* Дата начала / окончания договора */}
          <DateQuotation userForeignId={userForeignId} />
        </Form>

        <Form name="membersQuotation" form={membersForm} layout="vertical">
          {/* TODO
          SOLVE INSIDE MEMBERS
          WITH CLIENT IIN
          */}
          <MembersQuotation
            key={agrIsn}
            agrIsn={agrIsn}
            userForeignId={userForeignId}
            clientIin={clientIin}
          ></MembersQuotation>
        </Form>

        <Form
          name="additionalAttributesForm"
          form={additionalAttributesForm}
          layout="vertical"
        >
          {/* Дополнительные атрибуты котировки*/}
          <AdditionalAttributes agrIsn={agrIsn} userForeignId={userForeignId} />
        </Form>

        <Form name="documentForm" form={documentForm} layout="vertical">
          <ContractDocument agrIsn={agrIsn} userForeignId={userForeignId} />
        </Form>

        <Form
          name="agrObject"
          form={objectForm}
          layout="vertical"
          initialValues={{ agrObject: [''] }}
        >
          <ObjectQuotation agrIsn={agrIsn} userForeignId={userForeignId} />
        </Form>

        <Form name="clauseQuotationForm" form={clauseForm} layout="vertical">
          {/* Оговорки и ограничения */}
          <Clauses agrIsn={agrIsn} userForeignId={userForeignId} />
        </Form>

        <Form name="fileQuotationForm" form={fileForm} layout="vertical">
          <FilesQuotation userForeignId={userForeignId} agrIsn={agrIsn} />
        </Form>

        <Form name="restQuotation" form={form} layout="vertical">
          <UnderWritingDepartment getQuotationId={getQuotationId()} />
          {/* <MainButtons userForeignId={userForeignId} agrIsn={agrIsn} /> */}
        </Form>
      </Form.Provider>
    </div>
  );
};

export default BitrixQuotation;
