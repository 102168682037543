import { notification } from 'antd';
import axios from 'axios';

export function getMessageError(error) {
  let message;
  let description;

  if (axios.isAxiosError(error)) {
    description =
      typeof error.response?.data.message !== 'undefined'
        ? error.response.data.message
        : error.response?.statusText;

    message = `Ошибка ${error.response?.status}`;
  } else {
    message = String(error);
  }

  notification.error({
    message,
    description,
  });
}
