/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, notification, Row, Select } from 'antd';
const { Option } = Select;
import { DeleteFilled } from '@ant-design/icons';
import axios from 'axios';
import api from './api';
import dayjs from 'dayjs';

import { useSelector } from 'react-redux';

const { Search } = Input;

interface Props {
  fieldID: string;
  fieldName: string;
  title: string;
  required: boolean;
  initialValue?: {
    val: any;
  };
  name?: number;
  id: number;
  isDisabledForm: boolean;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}

// ???

interface IUser {
  department: string;
  duty: string;
  full_name: string;
  user_id: number;
}

function AgreementAhd(props: Props) {
  const {
    fieldID,
    fieldName,
    title = 'Title',
    required = false,
    initialValue,
    name,
    id,
    isDisabledForm,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const form: any = Form.useFormInstance();
  const data = useSelector((state: any) => state.data);
  const [agreement, setAgreement] = useState<Array<any>>([]);

  useEffect(() => {
    setAgreement(data);
  }, [data]);

  const isRowColumnEditor = typeof name === 'number';

  const handleSearchAgreement = async (document_id, event) => {
    if (event?.currentTarget.nodeName === 'INPUT' || document_id.length === 0) {
      return;
    }
    try {
      setIsLoading(true);
    } catch (error) {
      let message;
      let description;

      if (axios.isAxiosError(error)) {
        description =
          typeof error.response?.data.message !== 'undefined'
            ? error.response.data.message
            : error.response?.statusText;

        message = `Ошибка ${error.response?.status}`;
      } else {
        message = String(error);
      }

      notification.error({
        message,
        description,
      });
    } finally {
      setIsLoading(false);
      if (isDisabledStatus && isAdmin && onChangeByTd) {
        onChangeByTd(fieldID, name);
      }
    }
  };

  const onChangeSelect = (val) => {
    const find = agreement.filter((item) => item.ID === val)[0];
    const { row_column_editor } = form.getFieldsValue();

    if (name !== undefined) {
      Object.assign(row_column_editor[name][fieldID], {
        num_value: find.ISN,
      });

      form.setFieldsValue({ row_column_editor });
      if (isDisabledStatus && isAdmin && onChangeByTd) {
        onChangeByTd(fieldID, name);
      }
    }
  };

  return (
    <>
      <Row gutter={15} align="bottom">
        <Col span="24">
          {
            // прошу пока не трогать этот файл
            agreement?.length > 1 ? (
              <>
                <Form.Item
                  label={title}
                  rules={[{ required }]}
                  // initialValue={initialValue?.val?.id}
                  name={
                    isRowColumnEditor
                      ? [name, fieldID, fieldName]
                      : [fieldID, fieldName]
                  }
                >
                  <Select style={{ width: '100%' }} onChange={onChangeSelect}>
                    {agreement?.map((item) => (
                      <Option key={item?.ID} value={item?.ID}>
                        {item?.ID}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={title}
                  rules={[{ required }]}
                  initialValue={initialValue?.val?.id}
                  hidden
                  noStyle
                  name={
                    isRowColumnEditor
                      ? [name, fieldID, 'num_value']
                      : [fieldID, 'num_value']
                  }
                >
                  <Input />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  label={title}
                  rules={[{ required }]}
                  initialValue={initialValue?.val?.id}
                  name={
                    isRowColumnEditor
                      ? [name, fieldID, fieldName]
                      : [fieldID, fieldName]
                  }
                >
                  <Search
                    placeholder="Введите номер договора"
                    loading={isLoading}
                    enterButton
                    allowClear
                    disabled={isDisabledForm}
                    onSearch={handleSearchAgreement}
                  />
                </Form.Item>
                <Form.Item
                  label={title}
                  rules={[{ required }]}
                  initialValue={initialValue?.val?.id}
                  hidden
                  noStyle
                  name={
                    isRowColumnEditor
                      ? [name, fieldID, 'num_value']
                      : [fieldID, 'num_value']
                  }
                >
                  <Input />
                </Form.Item>
              </>
            )
          }
        </Col>
      </Row>
    </>
  );
}
export default AgreementAhd;
