import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import useAuth from '@hooks/useAuth';
import { Avatar, Popconfirm, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { api } from '../api';
import { getMessageError } from '../api/catchError';
import { Action, IState } from '../reducer/reducer';
import { getSpeakers } from './Speaker';

import './SpeakerTableIcon.scss';

interface ISpeakerTable {
  state: IState;
  dispatch: React.Dispatch<Action>;
}

export function SpeakerTable({ state, dispatch }: ISpeakerTable) {
  const auth = useAuth();

  const userId = auth.user.data.id;

  const columns: ColumnsType<any> = [
    {
      title: '№',
      dataIndex: 'id',
      render: (data, record, index) => ++index,
      width: '5%',
    },
    {
      title: 'ФИО спикера',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (data, record, index) => {
        return (
          <>
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 40, xl: 40, xxl: 40 }}
              src={`https://mycent.kz/${record.avatar.link}`}
            />
            <span className="ml-4">{record.user_id.full_name}</span>
          </>
        );
      },
    },
    {
      title: 'Тема',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'Дата',
      key: 'date_time',
      dataIndex: 'date_time',
    },
    {
      title: 'Наименование вуза',
      key: 'university_name',
      dataIndex: 'university_name',
    },
    {
      title: 'Перейти по записи',
      key: 'random',
      render: (data, record, index) => {
      
        return (
          <Space align="center">
            {record.hr === true ? (
              <>
                <PopConfirmCustom id={record.id} dispatch={dispatch} />
                <DeleteRecord id={record.id} dispatch={dispatch} />
              </>
            ) : record.hr === false && record.user_id.id === userId ? (
              <PopConfirmCustom id={record.id} dispatch={dispatch} />
            ) : null}
          </Space>
        );
      },
      width: '10%',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={state.speakers}
      rowKey="id"
      bordered
      scroll={{ x: 1000 }}
      loading={state.isLoading}
    />
  );
}

interface IProps {
  id: number;
  dispatch: React.Dispatch<Action>;
}

function DeleteRecord({ id, dispatch }) {
  const confirm = () => {
    deleteSpeakerById(id).catch(getMessageError);
  };

  const deleteSpeakerById = async (id: number) => {
    try {
      dispatch({ type: 'isLoading', payload: true });
      const data = await api.deleteSpeakerById(id);
      getSpeakers(dispatch);
    } finally {
      dispatch({ type: 'isLoading', payload: false });
    }
  };

  return (
    <Popconfirm
      title="Удалить запись"
      okText="Да"
      cancelText="Нет"
      onConfirm={confirm}
    >
      <DeleteOutlined
        className="icon-width-custom ml-6"
        style={{ color: '	#FF0000' }}
      />
    </Popconfirm>
  );
}

function PopConfirmCustom({ id, dispatch }: IProps) {
  const confirm = () => {
    getSpeakerById(id).catch(getMessageError);
  };

  const getSpeakerById = async (id: number) => {
    try {
      dispatch({ type: 'isLoading', payload: true });
      const data = await api.getSpeakerById(id);
      dispatch({ type: 'typeModal', payload: true });
      dispatch({ type: 'speaker', payload: data });
      dispatch({ type: 'isOpenModal', payload: true });
    } finally {
      dispatch({ type: 'isLoading', payload: false });
    }
  };

  return (
    <Popconfirm
      title="Перейти на запись?"
      onConfirm={confirm}
      okText="Да"
      cancelText="Нет"
    >
      <a>
        <EditOutlined className="icon-width-custom" />
      </a>
    </Popconfirm>
  );
}
