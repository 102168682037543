import { NavLink } from 'react-router-dom';
import { Avatar, Space, Dropdown } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';

import useAuth from '@hooks/useAuth';

interface Props {
  onLogOut: () => void;
}

function PageHeaderDropdown({ onLogOut = () => {} }: Props) {
  const auth = useAuth();

  const handleLogOut = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    event.preventDefault();
    onLogOut();
  };

  const items = [
    {
      label: (
        <NavLink to="/about-me">
          {auth.user?.data?.user_info?.short_name}
        </NavLink>
      ),
      key: 'about-me',
    },
    {
      label: (
        <NavLink to="/settings">Настройки</NavLink>
      ),
      key: 'settings',
    },
    {
      // http://192.168.30.31:23325/login?redirect=%2F
      // https://admin.mycent.kz/
      label: auth.user?.data?.permission?.is_admin === 1 && (
        <a href="https://admin.mycent.kz/">Админ панель</a>
      ),
      key: 'admin',
    },
    {
      label: (
        <a href="/#" onClick={handleLogOut}>
          Выйти
        </a>
      ),
      key: 'logout',
    },
  ];

  return (
    <Dropdown menu={{ items }} placement={'bottomRight'} trigger={['click']}>
      <a href="/#" onClick={(event) => event.preventDefault()} role="open-dropdown">
        <Space>
          <Avatar
            size={36}
            src={`https://mycent.kz/${auth.user?.data?.user_info?.avatar2?.link}`}
          />
          <CaretDownFilled style={{ color: '#815CED' }} />
        </Space>
      </a>
    </Dropdown>
  );
}

export default PageHeaderDropdown;
