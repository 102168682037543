import {
  PlusOutlined,
  QuestionCircleOutlined,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import PinIcon from '../../../assets/pinning.svg';
import { Button, Divider, Input, notification, UploadProps } from 'antd';
import {
  Dropdown,
  MenuProps,
  Modal,
  Form,
  message,
  Card,
  Col,
  Row,
  Typography,
  Table,
  Popconfirm,
  Empty,
} from 'antd';
import CButton from '@components/UI/button/CButton';
import DynamicDocuments from '../ContractDocuments/DynamicDocuments';
import { useImmerReducer } from 'use-immer';
import CInput from '@components/UI/input/CInput';
import Dragger from 'antd/es/upload/Dragger';
import api from '../services/quotation';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
type Action = {
  type:
    | 'changeStep'
    | 'changeModal'
    | 'saveListOfReqType'
    | 'saveListOfReq'
    | 'showReqInfo'
    | 'saveDocument'
    | 'isLoad';
  payload?: any;
};
interface State {
  step: number;
  isModalOpenUnder: boolean;
  isModalOpenRisk: boolean;
  listOfReqType: any[] | null;
  listOfReq: any[] | null;
  isLoading: boolean;
  isReqModal: boolean;
  reqInfo: any;
  document: any;
}
const initialState: State = {
  step: 0,
  isModalOpenUnder: false,
  isModalOpenRisk: false,
  listOfReqType: null,
  listOfReq: null,
  isLoading: false,
  isReqModal: false,
  reqInfo: {},
  document: null,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'saveListOfReq': {
      draft.listOfReq = action.payload;
      break;
    }
    case 'changeModal': {
      draft.step = action.payload.isOpen ? 1 : 0;
      if (action.payload.type == 'under') {
        draft.isModalOpenUnder = action.payload.isOpen;
      } else if (action.payload.type == 'risk') {
        draft.isModalOpenRisk = action.payload.isOpen;
      }
      break;
    }
    case 'showReqInfo': {
      draft.isReqModal = action.payload.isOpen;
      draft.reqInfo = action.payload.info;
      break;
    }
    case 'isLoad': {
      draft.isLoading = action.payload;
      break;
    }
    case 'saveListOfReqType': {
      draft.listOfReqType = action.payload;
      break;
    }
    case 'changeStep': {
      draft.step = action.payload;
      break;
    }
    case 'saveDocument': {
      draft.document = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const { Title, Text } = Typography;
type RequestQuotaionProps = {
  userForeignId: number;
  agrIsn: number | undefined;
  quotationData: any;
  quotationId: string | undefined;
  productIsn: number | undefined;
};

const RequestQuotaion = (props: RequestQuotaionProps) => {
  const { userForeignId, agrIsn, quotationData, quotationId, productIsn } =
    props;
  const objectForm = Form.useFormInstance();
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const [requestFiles, setRequestFiles] = useState<any[]>([]);
  const [underReqForm] = Form.useForm();
  const [riskReqForm] = Form.useForm();
  const {
    isModalOpenUnder,
    isModalOpenRisk,
    step,
    listOfReqType,
    listOfReq,
    isLoading,
    isReqModal,
    reqInfo,
    document,
  } = state;
  const filterData = (arr) => {
    const res = arr.reduce(function (obj, item) {
      const check = obj.filter((elem) => elem.isn === item.isn);
      if (check.length === 0) {
        obj.push(item);
      } else {
        const check = arr.filter((elem) => elem.isn === item.isn);
        const findLatest = check.reduce(function (el, item) {
          if (el.length === 0) {
            el.push(item);
          } else {
            const a = el.filter(
              (i) => new Date(i.updated) < new Date(item.updated)
            );
          }

          return el;
        }, []);
        obj.filter((b, index) => {
          if (b.isn === findLatest[0].isn) {
            obj[index] = findLatest[0];
          }
        });
      }
      return obj;
    }, []);
    return res;
  };
  const uploadProps: UploadProps = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload: (file) => {
      if (requestFiles?.length > 0) {
        setRequestFiles([...requestFiles, file]);
      } else {
        setRequestFiles([file]);
      }

      return false;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const columns = [
    {
      width: '10%',
      title: () => {
        return <span>Номер</span>;
      },
      dataIndex: 'id',
      key: '1',
      render: (id, item) => (
        <span
          onClick={() =>
            dispatch({
              type: 'showReqInfo',
              payload: { isOpen: true, info: item },
            })
          }
          className="quotqtion-id"
        >
          {id}
        </span>
      ),
    },
    {
      width: '40%',
      dataIndex: 'reqtext',
      title: () => {
        return <span>Текст заявки</span>;
      },
      key: '2',
    },
    {
      width: '15%',
      dataIndex: 'responsetext',
      title: () => {
        return <span>Ответ по заявке</span>;
      },
      key: '3',
      render: (_record) =>
        _record?.length > 0 ? (
          <p>{_record}</p>
        ) : (
          <p style={{ color: '#FF6262' }}>
            {_record.length > 0 ? _record : 'Нет ответа'}
          </p>
        ),
    },
    {
      width: '20%',
      title: () => {
        return <span>Статус заявки</span>;
      },
      dataIndex: 'req_statusisn_name',
      key: '4',
      render: (_record, item) =>
        item.req_statusisn === '1272' ? (
          <span style={{ color: '#FF6262' }}>{_record}</span>
        ) : (
          <span style={{ color: '#38C33E' }}>{_record}</span>
        ),
    },
    {
      width: '15%',
      title: () => {
        return <span>Исполнитель заявки</span>;
      },
      dataIndex: 'updatedby_name',
      key: '5',
      // render: (_record, item) => {
      //   console.log(item);
      //   return '';
      // },
      // item.req_statusisn === '1272' ? (
      //   <span style={{ color: '#FF6262' }}>{_record}</span>
      // ) : (
      //   <span style={{ color: '#38C33E' }}>{_record}</span>
      // ),
    },
  ];
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Row
          onClick={() => {
            dispatch({
              type: 'changeModal',
              payload: { type: 'under', isOpen: true },
            });
          }}
          // target="_blank"
          // rel="noopener noreferrer"
          // href="https://www.antgroup.com"
        >
          <Col span={23}>Департамент андеррайтинга</Col>
          <Col span={1}>
            <RightOutlined />
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: (
        <Row
          onClick={() => {
            dispatch({
              type: 'changeModal',
              payload: { type: 'risk', isOpen: true },
            });
          }}
        >
          <Col span={23}>Отдел риск инжиниринга</Col>
          <Col span={1}>
            <RightOutlined />
          </Col>
        </Row>
      ),
    },
  ];

  function showAgrObj(name) {
    const { agrObject } = objectForm.getFieldsValue();
    const fieldValue = agrObject[name]['showObject'];

    objectForm.setFields([
      {
        name: ['agrObject', name, 'showObject'],
        value: !fieldValue,
      },
    ]);
  }
  const closeModal = (type) => {
    dispatch({
      type: 'changeModal',
      payload: { type: type, isOpen: false },
    });
  };

  /*
    установка поле defaultKiasValues

    нужно чтобы не отправлять через пропсы данные, которые приходят при заходе в котировку

    а их просто можно вытащить на любом уровне вложенности

    */
  useEffect(() => {
    (async () => {
      try {
        if (quotationData?.full_quotation[0]?.id) {
          const { data } = await api.getReqType({
            isn: quotationData?.full_quotation[0]?.id,
          });
          dispatch({ type: 'saveListOfReqType', payload: data.data });
        }
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: 'Что-то пошло не так',
        });
      }
    })();
  }, []);
  const onFinishReq = async (type) => {
    try {
      dispatch({ type: 'isLoad', payload: true });
      if (type === 'under') {
        await api.saveRequest({
          ...underReqForm.getFieldsValue(),
          user_isn: userForeignId,
          agr_isn: agrIsn,
          attachment: requestFiles,
        });
        const { data } = await api.getFullQuotationJournal({
          quotation_id: quotationId,
          user_isn: userForeignId,
        });
        const res = filterData(data.data.service_req);
        dispatch({
          type: 'saveListOfReq',
          payload: res,
        });
        dispatch({
          type: 'changeModal',
          payload: { type: 'under', isOpen: false },
        });
        underReqForm.resetFields();
      } else if (type === 'risk') {
        const req = await api.saveRequest({
          ...riskReqForm.getFieldsValue(),
          user_isn: userForeignId,
          agr_isn: agrIsn,
          attachment: requestFiles,
          doc_isn: Number(document[2235591].doc_list.doc_isn),
        });
        if (req.data.success) {
          const { data } = await api.getFullQuotationJournal({
            quotation_id: quotationId,
            user_isn: userForeignId,
          });
          dispatch({
            type: 'saveListOfReq',
            payload: data.data.service_req,
          });
          notification.success({
            message: 'Сохранено',
            description: 'Заявка успешно сохранена',
          });
        } else {
          notification.error({
            message: 'Ошибка',
            description: req.data.error,
          });
        }

        dispatch({
          type: 'changeModal',
          payload: { type: 'risk', isOpen: false },
        });
      }
      riskReqForm.resetFields();
      dispatch({ type: 'isLoad', payload: false });

      setRequestFiles([]);
    } catch (error) {
      console.log(error);

      dispatch({ type: 'isLoad', payload: false });
      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };

  const resendReq = async (req) => {
    try {
      dispatch({ type: 'isLoad', payload: true });
      const { data } = await api.saveRequest({
        user_isn: userForeignId,
        agr_isn: agrIsn,
        attachment: requestFiles,
        node: req.nodename === 'Департамент андеррайтинга' ? 2 : 88,
        req_type: req.classisn,
        req_isn: req.isn,
        req_text: req.reqtext,
      });
      dispatch({
        type: 'showReqInfo',
        payload: {
          info: {
            id: data.data.ID,
            reqtext: data.data.REQTEXT,
            responsetext: data.data.RESPONSETEXT,
            req_statusisn_name: data.data.REQ_STATUSISN_NAME,
            req_statusisn: data.data.REQ_STATUSISN,
            classisn: data.data.CLASSISN,
            isn: data.data.ISN,
          },
          isOpen: true,
        },
      });
      const info = await api.getFullQuotationJournal({
        quotation_id: quotationId,
        user_isn: userForeignId,
      });
      const res = filterData(info.data.data.service_req);
      dispatch({
        type: 'saveListOfReq',
        payload: res,
      });
      notification.success({
        message: 'Успешно',
        description: 'Заявка успешно отправлена повторно',
      });
      dispatch({ type: 'isLoad', payload: false });
    } catch (e) {
      dispatch({ type: 'isLoad', payload: false });
      notification.error({
        message: 'Ошибка',
        description: 'Что-то пошло не так',
      });
    }
  };

  const selectReqType = (item, step, type) => {
    if (type === 'under') {
      underReqForm.setFieldValue('req_type', item.REQ_TYPE_ISN);
      underReqForm.setFieldValue('node', item.NODE_ISN);
    } else if (type === 'risk') {
      riskReqForm.setFieldValue('req_type', item.REQ_TYPE_ISN);
      riskReqForm.setFieldValue('node', item.NODE_ISN);
    }

    dispatch({ type: 'changeStep', payload: step });
  };
  useEffect(() => {
    if (quotationData) {
      const res = filterData(quotationData.service_req);
      dispatch({ type: 'saveListOfReq', payload: res });
      if (quotationData.docs && quotationData.docs[2235591]) {
        dispatch({
          type: 'saveDocument',
          payload: { [2235591]: quotationData.docs[2235591] },
        });
      }
    }
  }, [quotationData]);
  return (
    <Card className="mt-5">
      <Row justify={'space-between'}>
        <Col span={8}>
          <Title className="main-quotation__section-title" level={3}>
            Заявки
          </Title>
        </Col>
        <Col span={8}>
          <Row gutter={12}>
            <Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
              <CButton
                type="primary"
                icon={<PlusOutlined />}
                block
                className="c-button_between"
              >
                Новая заявка
              </CButton>
            </Dropdown>
          </Row>
        </Col>
      </Row>
      <Modal
        title={
          <Title level={4} style={{ color: '#7449EF' }}>
            {step > 1 && (
              <LeftOutlined
                onClick={() => dispatch({ type: 'changeStep', payload: 1 })}
              />
            )}{' '}
            Департамент андерайтинга
          </Title>
        }
        open={isModalOpenUnder}
        onCancel={() => closeModal('under')}
        footer={[]}
      >
        <CustomLoader spinning={isLoading}>
          <Form
            name="underReqForm"
            form={underReqForm}
            onFinish={() => onFinishReq('under')}
          >
            {step === 1 && (
              <>
                <Text style={{ color: '#7449EF80' }}>Тип заявки</Text>
                {listOfReqType
                  ?.filter((item) => item.NODE_ISN === '2')
                  .map((item, index) => {
                    return (
                      <CButton
                        icon={<RightOutlined />}
                        key={`reqTypeBtn-${index}`}
                        block
                        className="c-button_between mt-5"
                        onClick={() => {
                          selectReqType(item, index + 2, 'under');
                        }}
                      >
                        {item.REQ_TYPE_NAME}
                      </CButton>
                    );
                  })}
              </>
            )}
            {listOfReqType
              ?.filter((item) => item.NODE_ISN === '2')
              .map(
                (item, index) =>
                  index + 2 == step && (
                    <Text style={{ color: '#7449EF80' }}>
                      {item.REQ_TYPE_NAME}
                    </Text>
                  )
              )}
            {(step === 2 || step === 3) && (
              <>
                <Form.Item name="req_type" hidden noStyle>
                  <Input />
                </Form.Item>
                <Form.Item name="node" hidden noStyle>
                  <Input />
                </Form.Item>
                <Form.Item name="req_text">
                  <CInput
                    placeholder={'Примечание'}
                    size="large"
                    className="mt-5 mb-3"
                  />
                </Form.Item>
                <Dragger {...uploadProps} className="mt-3">
                  <img src={PinIcon} alt="icon" className="mr-3" />
                  Прикрепление файла
                </Dragger>
                <CButton
                  type="primary"
                  htmlType="submit"
                  form="underReqForm"
                  block
                  className="mt-3"
                >
                  Отправить
                </CButton>
              </>
            )}
          </Form>
        </CustomLoader>
      </Modal>

      <Modal
        title={
          <Title level={4} style={{ color: '#7449EF' }}>
            {step > 1 && (
              <LeftOutlined
                onClick={() => dispatch({ type: 'changeStep', payload: 1 })}
              />
            )}{' '}
            Отдел риск инжиниринга
          </Title>
        }
        open={isModalOpenRisk}
        onCancel={() => closeModal('risk')}
        footer={[]}
      >
        <CustomLoader spinning={isLoading}>
          <Form
            name="riskReqForm"
            form={riskReqForm}
            onFinish={() => onFinishReq('risk')}
          >
            {step === 1 && (
              <>
                <Text style={{ color: '#7449EF80' }}>Тип заявки</Text>
                {listOfReqType
                  ?.filter((item) => item.NODE_ISN === '88')
                  .map((item, index) => {
                    return (
                      <CButton
                        icon={<RightOutlined />}
                        key={`reqTypeBtn-${index}`}
                        block
                        className="c-button_between mt-5"
                        onClick={() => {
                          selectReqType(item, index + 2, 'risk');
                        }}
                      >
                        {item.REQ_TYPE_NAME}
                      </CButton>
                    );
                  })}
              </>
            )}
            {listOfReqType
              ?.filter((item) => item.NODE_ISN === '88')
              .map(
                (item, index) =>
                  index + 2 == step && (
                    <Text style={{ color: '#7449EF80' }}>
                      {item.REQ_TYPE_NAME}
                    </Text>
                  )
              )}
            <Form.Item name="req_type" hidden noStyle>
              <Input />
            </Form.Item>
            <Form.Item name="node" hidden noStyle>
              <Input />
            </Form.Item>
            {step >= 2 && (
              <>
                <Form.Item name="req_text">
                  <CInput
                    placeholder={'Примечание'}
                    size="large"
                    className="mt-5 mb-3"
                  />
                </Form.Item>
                {document && step > 2 && (
                  <DynamicDocuments
                    agreement={document}
                    productIsn={productIsn}
                  />
                )}
                <Dragger {...uploadProps} className="mt-3">
                  <img src={PinIcon} alt="icon" className="mr-3" />
                  Прикрепление файла
                </Dragger>

                <CButton
                  type="primary"
                  htmlType="submit"
                  form="riskReqForm"
                  block
                  className="mt-3"
                >
                  Отправить
                </CButton>
              </>
            )}
          </Form>
        </CustomLoader>
      </Modal>
      {listOfReq && (
        <Table
          columns={columns}
          dataSource={listOfReq}
          // loading={loading}
          rowKey={'requestID'}
          locale={{
            emptyText: (
              <Empty description="Котировки не найдены с выбранными фильтрами" />
            ),
          }}
          className="c-table"
          scroll={{ x: 1000 }}
        />
      )}
      <Modal
        title={
          <Title level={4} style={{ color: '#7449EF' }}>
            Заявка #{reqInfo.id}
          </Title>
        }
        width="1000px"
        open={isReqModal}
        onCancel={() =>
          dispatch({
            type: 'showReqInfo',
            payload: { isOpen: false, info: {} },
          })
        }
        footer={[]}
      >
        <CustomLoader spinning={isLoading}>
          <Title level={4}>Текст заявки</Title>
          <Text>{reqInfo.reqtext}</Text>
          <Divider />
          <Title level={4}>Ответ по заявке</Title>
          <Text>
            {reqInfo.responsetext?.length > 0
              ? reqInfo.responsetext
              : 'Нет ответа'}
          </Text>
          <Divider />
          <Text>
            Статус заявки:{' '}
            <span
              style={{
                color: '#38C33E',
              }}
            >
              {reqInfo.req_statusisn_name}
            </span>
          </Text>
          <Divider />
          {reqInfo.req_statusisn === '1272' && (
            <Button onClick={() => resendReq(reqInfo)} type="primary">
              Отправить повторно
            </Button>
          )}
        </CustomLoader>
      </Modal>
    </Card>
  );
};

export default RequestQuotaion;
