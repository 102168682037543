import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Typography
} from 'antd';
import Title from 'antd/es/typography/Title';
import CSelect from '@components/UI/select/CSelect';
import React, { useState } from 'react';
import InfoDocument from '@modules/Quotation/MainContract/DenounceModal/InfoDocument';
import { getDateWithoutTime } from '@modules/Quotation/MainContract/DenounceModal/helpers/getDateWithoutTime';
import api from '@modules/Quotation/services';
import dayjs from 'dayjs';
import axios from 'axios';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const { Option } = Select;
const { Text } = Typography;

interface IPropsDenounceModal {
  denounceModalOpen: boolean;
  handleCancel: () => void;
  contactInfo: any;
  docIsn: number;
}

interface IRequestData {
  pagrisn: number,
  pcanceldate: string,
  pstatus: string,
  prvd: number,
  pmethod: number,
  pcancelreason: number,
  pdatereg: string,
}

const DenounceModal = ({ denounceModalOpen, handleCancel, contactInfo, docIsn }: IPropsDenounceModal) => {
  const [denounceForm] = Form.useForm();

  const [pstatus, setPstatus] = useState<string>("Д");
  const [returnPremium, setReturnPremium] = useState<string>("1");
  const [rvd, setRvd] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleStatusChange = (e) => {
    setPstatus(e.target.value);
    if (e.target.value === "Щ") {
      setReturnPremium("2");
    } else {
      setReturnPremium("1");
    }
  };

  const handlePremiumChange = (e) => {
    setReturnPremium(e.target.value);
    if (e.target.value === "1") {
      setPstatus("Д");
    } else {
      setPstatus("Щ");
    }
  };

  const denounceContract = async (values) => {
    try {
      setIsLoading(true);
      const requestData: IRequestData = {
        pagrisn: docIsn,
        pcanceldate: dayjs(values.pcanceldate).format('DD.MM.YYYY'),
        pstatus,
        prvd: !rvd ? -1 : values.prvd,
        pmethod: values.pmethod,
        pcancelreason: Number(values.pcancelreason),
        pdatereg: dayjs(values.pcanceldate).format('DD.MM.YYYY'),
      };

      const response = await api.contractor.denounceContract(requestData);

      if (response.data.success) {
        denounceForm.resetFields();
        handleCancel();
        window.location.reload();
      }
    } catch (error) {
      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: "Ошибка",
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={<Title level={4}>Прекращение договора - {contactInfo?.data?.agreement[0].id}</Title>}
      open={denounceModalOpen}
      onCancel={() => {
        handleCancel();
        denounceForm.resetFields();
      }}
      width="40%"
      footer={null}
    >
      <CustomLoader spinning={isLoading}>
        <InfoDocument
          contractInfo={contactInfo}
        />
        <Form form={denounceForm} onFinish={denounceContract} layout="vertical">
          <Divider orientation="left">Причина прекращения</Divider>
          <Col lg={12} xs={24}>
            <Form.Item
              name="pcancelreason"
              rules={[{ required: true, message: 'Пожалуйста, выберите причину прекращения' }]}
            >
              <CSelect>
                {contactInfo?.data.denounce_reason?.map((val) => (
                  <Option value={String(val.reason_isn)} key={val.reason_isn}>
                    {val.reason_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
          <Divider orientation="left">Инициатор прекращения</Divider>
          <Form.Item initialValue={pstatus}>
            <Radio.Group value={pstatus} onChange={handleStatusChange}>
              <Radio value={"Д"}>Страхователь</Radio>
              <Radio value={"Щ"}>Страховщик</Radio>
            </Radio.Group>
          </Form.Item>
          <Divider orientation="left">Возврат премии</Divider>
          <Form.Item initialValue={returnPremium}>
            <Radio.Group value={returnPremium} onChange={handlePremiumChange}>
              <Radio value={"1"}>Производится</Radio>
              <Radio value={"2"}>Не производится</Radio>
            </Radio.Group>
          </Form.Item>
          <Divider orientation="left">Дата заявления</Divider>
          <Col lg={12} xs={24}>
            <Form.Item
              name="pdatereg"
              rules={[{ required: true, message: 'Пожалуйста, выберите дату заявления' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format="DD.MM.YYYY"
                className="dateMask"
              />
            </Form.Item>
          </Col>
          <Divider orientation="left">Дата прекращения</Divider>
          <Col lg={12} xs={24}>
            <Form.Item
              name="pcanceldate"
              label="Последний день действия договора"
              rules={[{ required: true, message: 'Пожалуйста, выберите дату действия договора' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format="DD.MM.YYYY"
                className="dateMask"
              />
            </Form.Item>
          </Col>
          <Col span={18} className="mt-10">
            <Text type="danger">
              ВНИМАНИЕ: Если дата прекращения меньше даты принятия договора к учету
              ({getDateWithoutTime(contactInfo.data.agreement[0].datebeg)}), то договор будет признан
              недействительным и убыток сформирован не будет!
            </Text>
          </Col>
          <Divider/>
          <Divider orientation="left">Удерживать РВД</Divider>
          <Col className="d-flex">
            <Form.Item>
              <Radio.Group value={rvd} onChange={(event) => setRvd(event.target.value)}>
                <Radio value={true}>Да</Radio>
                <Radio value={false}>Нет</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="prvd">
              <InputNumber<number>
                min={0}
                max={100}
                maxLength={3}
                addonAfter="%"
                disabled={!Boolean(rvd)}
              />
            </Form.Item>
          </Col>
          <Divider/>
          <Divider orientation="left">Методика расчета суммы возврата</Divider>
          <Form.Item
            name="pmethod"
            rules={[{ required: true, message: 'Пожалуйста, выберите методику расчета суммы возврата' }]}
          >
            <Radio.Group>
              <Col className="d-flex flex-column">
                <Radio value={0}>Пропорционально дням действия</Radio>
                <Radio value={1}>Пропорционально полным месяцам действия</Radio>
              </Col>
            </Radio.Group>
          </Form.Item>
          <Divider/>
          <Row gutter={[24, 24]} className="d-flex justify-end">
            <Col>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                danger
                onClick={() => {
                  handleCancel()
                  denounceForm.resetFields();
                }}
              >
                Отменить
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomLoader>
    </Modal>
  )
};

export default DenounceModal;