import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Cookies from 'js-cookie';
import {domain} from '@services/api/axios';

interface Props {
  children: React.ReactNode;
}

function GuestRoute({ children }: Props) {
  const auth = useAuth();
  const location = useLocation();
  const url = new URLSearchParams(location.search.slice(1));

  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const logout = searchParams.get('logout');
  if (logout) {
    searchParams.delete('logout');
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    Cookies.remove('token', domain);
    auth.logOut();
  }

  if (auth.token) {
    return <Navigate to={url.get('redirect') || '/'} />;
  }

  return <>{children}</>;
}

export default GuestRoute;
