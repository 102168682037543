import {
  IDocType,
  IProductList,
} from '@modules/Quotation/Interfaces/CreateQuotation/index.interface';
import {
  IAgrClauseItem,
  IAgrObjAttributeItem,
  IAgrobjectItem,
  IAgrObjectCarItem,
  IAgrObjCarGoItem,
  IAgrCondItem,
  IUserProductSelectedItem,
  IAgrAttributeItem,
} from '@modules/Quotation/Interfaces/MainQuotation/index.interface';
import dayjs from 'dayjs';
import { createContext, Dispatch, SetStateAction } from 'react';
import {
  Action,
  IInitialState,
} from '@modules/Quotation/providers/DateReducer';
import { ColleaguesKias } from '@modules/Quotation/hooks/CommonMethods/MainComponents/useCompanyTree';

interface QuotationContextInterface {
  colleaguesKiasList: ColleaguesKias[];
  isLoadingColleaguesKiasList: boolean;
  productList: IProductList[];
  docTypesList: IDocType[];
  productSelectedQuotation: IUserProductSelectedItem[];
  setProductSelectedQuotation: Dispatch<
    SetStateAction<IUserProductSelectedItem[]>
  >;
  agrClauses: IAgrClauseItem[];
  setAgrClauses: Dispatch<SetStateAction<IAgrClauseItem[]>>;
  agrAttributes: IAgrAttributeItem[];
  setAgrAttributes: Dispatch<SetStateAction<IAgrAttributeItem[]>>;
  agrAttributesUnder: IAgrAttributeItem[];
  setAgrAttributesUnder: Dispatch<SetStateAction<IAgrAttributeItem[]>>;
  agrObjAttributes: IAgrObjAttributeItem[];
  setAgrObjAttributes: Dispatch<SetStateAction<IAgrObjAttributeItem[] | any>>;
  agrObj: IAgrobjectItem[];
  setAgrObj: Dispatch<SetStateAction<IAgrobjectItem[] | any>>;
  agrObjCar: IAgrObjectCarItem[];
  setAgrObjCar: Dispatch<SetStateAction<IAgrObjectCarItem[]>>;
  agrObjCarGo: IAgrObjCarGoItem[];
  setAgrObjCarGo: Dispatch<SetStateAction<IAgrObjCarGoItem[]>>;
  agrCond: IAgrCondItem[];
  setAgrCond: Dispatch<SetStateAction<IAgrCondItem[] | any>>;
  fileList: any[];
  setFileList: Dispatch<SetStateAction<any[]>>;
  stateDateQuotation: IInitialState;
  dispatchDate: Dispatch<Action>;
  agrContractor: any;
  setAgrContractor: Dispatch<SetStateAction<any>>;
  agrMembers: any[];
  setAgrMembers: Dispatch<SetStateAction<any>>;
  memberSettings: any[];
  setMemberSettings: Dispatch<SetStateAction<any>>;
  status: number;
  setStatus: Dispatch<SetStateAction<any>>;
  isCustomTemplate: number;
  setIsCustomTemplate: Dispatch<SetStateAction<any>>;
  nshdFileList: any[];
  setNshdFileList: Dispatch<SetStateAction<any>>;
}

const QuotationContext = createContext<QuotationContextInterface>({
  colleaguesKiasList: [],
  isLoadingColleaguesKiasList: false,
  productList: [],
  docTypesList: [],
  productSelectedQuotation: [],
  setProductSelectedQuotation: () => {},
  agrClauses: [],
  setAgrClauses: () => {},
  agrAttributes: [],
  setAgrAttributes: () => {},
  agrAttributesUnder: [],
  setAgrAttributesUnder: () => {},
  agrObjAttributes: [],
  setAgrObjAttributes: () => {},
  agrObj: [],
  setAgrObj: () => {},
  agrObjCar: [],
  setAgrObjCar: () => {},
  agrObjCarGo: [],
  setAgrObjCarGo: () => {},
  agrCond: [],
  setAgrCond: () => {},
  fileList: [],
  setFileList: () => {},
  stateDateQuotation: {
    agr_isn: 0,
    date_beg: dayjs().format('YYYY-MM-DD'),
    date_sign: dayjs().format('YYYY-MM-DD'),
    date_end: dayjs().add(1, 'y').subtract(1, 'd').format('YYYY-MM-DD'),
    client_id: 0,
    connection: 'centras',
    currency: 9813,
    user_isn: 0,
    status_isn: 223368,
    agr_status: '',
    remark: '',
    status_isn_kias: 0,
  },
  dispatchDate: () => {},
  agrContractor: [],
  setAgrContractor: () => {},
  agrMembers: [],
  setAgrMembers: () => {},
  memberSettings: [],
  setMemberSettings: () => {},
  status: 0,
  setStatus: () => {},
  isCustomTemplate: 0,
  setIsCustomTemplate: () => {},
  nshdFileList: [],
  setNshdFileList: () => {},
});

export default QuotationContext;
