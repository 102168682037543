import React, { useEffect, useState } from 'react';
import { Collapse, notification, Typography } from 'antd';
import EventItemHeading from '../EventItemHeading';
import EventItemContent from '../EventItemContent';

import './EventsInviteList.scss';

import useEvent from '../../../hooks/useEvent';
import { IBooking } from '../../../pages/Talk/Talk';
import useAuth from '../../../hooks/useAuth';
import useCounter from '@hooks/useCounter';
import api from '../services/api';

const { Panel } = Collapse;
const { Title } = Typography;

const EventsInviteList = ({}) => {
  const [filteredBooking, setFilteredBooking] = useState<any[]>([]);

  const { getUserBookingMethod, totalList } = useEvent();

  const auth = useAuth();

  const { getCountEvent } = useCounter();

  const handleAcceptEvent = async (bookingId) => {
    try {
      const response = await api.updBooking(bookingId, { status: 2 });
      const successStatuses =
        response.status === 200 && response.statusText.toLowerCase() === 'ok';
      if (successStatuses) {
        getUserBookingMethod();
        getCountEvent();
      } else {
        throw new Error('Попробуйте еще раз');
      }
    } catch (error: any) {
      notification.info({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  const handleRevokeEvent = async (bookingId) => {
    try {
      const response = await api.delUserBooking(bookingId, { status: 0 });
      const successStatuses =
        response.status === 200 && response.statusText.toLowerCase() === 'ok';
      if (successStatuses) {
        getUserBookingMethod();
        getCountEvent();
      } else {
        throw new Error('Попробуйте еще раз');
      }
    } catch (error: any) {
      notification.info({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  useEffect(() => {
    setFilteredBooking(
      totalList.map((element) =>
        element.booking[0].booking_user.filter(
          (item) =>
            Number(item.user_id) === auth.user?.data?.id &&
            Number(item.status) === 1
        )
      )
    );
  }, [totalList]);

  return (
    <div className="events-invite-list">
      <Title className="mb-5" level={3} type="secondary">
        Приглашения
      </Title>
      <Collapse accordion expandIconPosition="start" ghost>
        {filteredBooking?.map((element: IBooking) => {
          if (element[0]?.booking_id) {
            return (
              <React.Fragment key={Math.random()}>
                {totalList.map((item, index) => {
                  if (item.booking_id === element[0]?.booking_id) {
                    return (
                      <React.Fragment key={element[0].booking_id + index}>
                        <Panel
                          header={
                            <EventItemHeading
                              company={
                                item.booking[0].building_name
                              }
                              title={item.booking[0].title}
                              totalList={totalList}
                              isInvite={true}
                              onAcceptEvent={() => {
                                handleAcceptEvent(element[0].booking_id);
                              }}
                              onRevokeEvent={() => {
                                handleRevokeEvent(element[0].booking_id);
                              }}
                            />
                          }
                          key={element[0].booking_id + index}
                        >
                          <EventItemContent
                            {...item}
                            deadline={item.booking[0].begin}
                            range={
                              Date.now() +
                              (new Date(item.booking[0].end).getTime() -
                                new Date(item.booking[0].begin).getTime()) -
                              Date.now()
                            }
                          />
                        </Panel>
                      </React.Fragment>
                    );
                  }
                })}
              </React.Fragment>
            );
          }
        })}
      </Collapse>
    </div>
  );
};

export default EventsInviteList;
