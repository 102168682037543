import { Button, Col, DatePicker, Form, Modal, notification, Row, Select } from 'antd';
import CSelect from '@components/UI/select/CSelect';
import React, { useState } from 'react';
import Title from 'antd/es/typography/Title';
import api from '@modules/Quotation/services';
import dayjs from 'dayjs';
import axios from 'axios';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

interface IAddendumReason {
  isn: string;
  product_isn: string;
  reason_isn: string;
  reason_name: string;
}

interface IPropsAddendumModal {
  addendumModal: boolean;
  handleCancel: () => void;
  addendumReason: IAddendumReason[];
  docIsn: string | undefined;
}

interface IRequestData {
  "doc_isn": number;
  "reason_isn": number;
  "date_sign": string;
  "date_beg": string;
}

const { Option } = Select;

const AddendumModal = ({ addendumModal, handleCancel, addendumReason, docIsn }: IPropsAddendumModal) => {
  const [addendumForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createAddendum = async () => {
    try {
      setIsLoading(true);
      const requestData: IRequestData = {
        doc_isn: Number(docIsn),
        reason_isn: Number(addendumForm.getFieldValue('reason_isn')),
        date_sign: dayjs(addendumForm.getFieldValue('date_sign')).format('YYYY.MM.DD'),
        date_beg: dayjs(addendumForm.getFieldValue('date_beg')).format('YYYY.MM.DD'),
      };

      const response = await api.contractor.createAddendumQuotation(requestData);

      if (response.data.success) {
        notification.info({
          message: "Информация",
          description: "Вы успешно создали Котировку на аддендум",
        });
        addendumForm.resetFields();
        handleCancel();

        if (response?.data?.data?.ADDENDUM_CALC_ID) window.open(`/quotation/${response?.data?.data?.ADDENDUM_CALC_ID}`, '_blank');
      }
    } catch (error) {
      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: "Ошибка",
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Modal
      title={<Title level={4}>Котировка на аддендум</Title>}
      open={addendumModal}
      onCancel={() => {
        handleCancel();
        addendumForm.resetFields();
      }}
      footer={null}
      centered
    >
      <CustomLoader spinning={isLoading}>
        <Form className="mt-5" layout="vertical" form={addendumForm} onFinish={createAddendum}>
          <Form.Item label="Причина изменения условий договора" name="reason_isn" rules={[{ required: true }]}>
            <CSelect>
              {addendumReason?.map((reason) => {
                return (
                  <Option value={reason.reason_isn} key={reason.isn}>
                    {reason.reason_name}
                  </Option>
                )
              })}
            </CSelect>
          </Form.Item>
          <Form.Item label="Дата подписания" name="date_sign" rules={[{ required: true }]}>
            <DatePicker
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item label="Дата начала действия изменений условий договора" name="date_beg"
                     rules={[{ required: true }]}>
            <DatePicker
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Row gutter={[24, 24]} className="d-flex justify-end">
            <Col>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
              >
                Выполнить
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                onClick={handleCancel}
              >
                Отмена
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomLoader>
    </Modal>
  )
};

export default AddendumModal;