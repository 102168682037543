import api from '@modules/Quotation/services';
import { Button, notification, Typography, Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import useQuotationJournal from '../hooks/useQuotationJournal';
import PrintedFormModal from './PrintedFormModal';
import CButton from '@components/UI/button/CButton';
import type { MenuProps } from 'antd';

const { Title } = Typography;

type PrintedFormProps = {
  agrIsn: number | undefined;
  userForeignId: number;
  printAgrIsn: string;
};

export interface IPrintFormData {
  TEMPLATE_ISN: string;
  TEMPLATE_NAME: string;
  FILE_FORMAT: string;
  FILE_EXTENSION: string;
}

function PrintedForm(props: PrintedFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [printFormData, setPrintFormData] = useState<IPrintFormData[]>([]);
  const [items, setItems] = useState<MenuProps['items']>([]);

  const { state } = useQuotationJournal();

  const { agrIsn, userForeignId, printAgrIsn } = props;
  const getPrintFile = async (item) => {
    setIsLoading(true);
    const { data } = await api.quotation.getUrlByPrintedFormISN({
      isn: printAgrIsn,
      tempIsn: item.TEMPLATE_ISN,
      userIsn: userForeignId,
    });
    if (data.length) {
      downloadFile(data, item.FILE_EXTENSION, item.TEMPLATE_NAME);
    }
  };
  const downloadFile = async (
    url: string,
    fileFormat: string,
    templateName: string
  ) => {
    const payloadData = {
      fileName: templateName,
      url: url,
      format: fileFormat,
    };

    try {
      const { data } = await api.quotation.getFileByUrl(payloadData);
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${templateName} ${agrIsn}${fileFormat}`);
      link.click();
      link.remove();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const payloadData = {
        isn: printAgrIsn,
        userIsn: userForeignId,
      };
      const { data } = await api.quotation.getPrintedForm(payloadData);
      setPrintFormData(data);
      setItems(
        data.map((item) => {
          return {
            label: (
              <a onClick={() => getPrintFile(item)}>{item.TEMPLATE_NAME}</a>
            ),
            key: item.TEMPLATE_ISN,
          };
        })
      );
    })();
  }, []);
  return (
    <>
      {/* <Col xs={24} xl={{ span: 8 }}> */}
      {/* <Title level={5} style={{ textAlign: 'end' }}>
          Номер договора: {state.agreementData.ID} <br />
          Статус договора: {state.agreementData.STATUS}{' '}
        </Title> */}
      {/* </Col> */}
      {/* <Col xs={24} xl={8}> */}
      {printFormData?.length > 0 && (
        <Dropdown menu={{ items }} trigger={['click']}>
          <CButton
            block
            type="primary"
            onClick={(e) => e.preventDefault()}
            className="button-flow print-button"
            loading={isLoading}
          >
            Печатная форма
          </CButton>
        </Dropdown>
      )}
      {}

      {/* <Button block type="primary" onClick={getPrintedForms}>
        Печатная форма
      </Button> */}
      {/* </Col> */}
      {/* <PrintedFormModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        printFormData={printFormData}
        userForeignId={userForeignId}
        agrIsn={agrIsn}
      /> */}
    </>
  );
}

export default PrintedForm;
