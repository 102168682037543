import React from 'react';
import './NotificationsModal.scss';
import ItemModal from '@modules/Quotation/NotificationsModal/ItemModal';
import { Typography } from 'antd';
import dayjs from 'dayjs';

const { Title } = Typography;

interface IFlow {
  id: string,
  type: number,
  message: string,
  comments: string,
  date: string,
}

interface IProps {
  flow: IFlow[]
}

const NotificationsModal = ({ flow }: IProps) => {
  const renderItem = flow && flow?.length !== 0 && [...flow].sort((nowDate, prevDate) => {
    return dayjs(prevDate.date).diff(dayjs(nowDate.date));
  }).map((flow: IFlow) => {
    return (
      <ItemModal
        dateTime={flow.date}
        key={flow?.id}
        status={flow?.type === 0 ? 'error' : flow.type === 1 ? 'success' : 'neutral'}
        message={flow?.message}
        comments={flow?.comments}
      />
    )
  });

  return (
    <div className="modal-notifications">
      <Title level={2} className="title">Уведомления</Title>
      <div className="content">
        <div className="items">
          {renderItem}
        </div>
      </div>
    </div>
  )
};

export default NotificationsModal;