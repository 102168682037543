import React, { useEffect, useState } from 'react';
import { Col, Form, notification, Row, Select } from 'antd';
import { TypeAction } from '@modules/Quotation/providers/DateReducer';
import CSelect from '@components/UI/select/CSelect';
import api from '@modules/Quotation/services';
import { AxiosError } from 'axios';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import useStatuses from '@modules/Quotation/hooks/CommonMethods/MainComponents/useStatuses';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

type StatusQuotationProps = {
  userForeignId: number;
  agrIsn: number | undefined;
};

const StatusQuotation = (props: StatusQuotationProps) => {
  const { userForeignId, agrIsn } = props;
  const { dispatchDate, stateDateQuotation, setStatus } = useQuotation();
  const form = Form.useFormInstance();
  const { statusList } = useStatuses();
  const { Option } = Select;
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Следим за изменением статуса котировки и меняем значение в форме
   * @param status
   */
  useEffect(() => {
    if (stateDateQuotation) {
      form.setFieldValue(
        [TypeAction.STATUS_ISN],
        stateDateQuotation.status_isn
      );
    }
  }, [stateDateQuotation]);

  /**
   * Меняем статус при изменении значения выпадающего списка путем отправки на бэк
   * @param status_isn - isn статуса из справочника
   */
  const quotationStatusChange = async (status_isn) => {
    const payloadData = {
      connection: 'centras',
      agr_isn: agrIsn,
      user_isn: userForeignId,
      status_isn: status_isn,
    };

    try {
      setIsLoading(true);
      const { data } = await api.quotation.updEmptyQuotation(payloadData);
      if (data?.success === true) {
        notification.success({
          message: 'Уведомление при переводе статуса',
          description: `Статус "${data?.data?.status_name}" успешно изменен`,
        });
        dispatchDate({
          type: TypeAction.STATUS_ISN,
          payload: status_isn,
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        notification.info({
          message: 'Уведомление при переводе статуса',
          description: err.response.data.error_full
            ? String(err.response.data.error_full)
            : String(err.response.data.error),
          duration: 0,
        });
      } else {
        notification.info({
          message: 'Уведомление при переводе статуса',
          description: String(error),
          duration: 0,
        });
      }
      form.setFieldValue(
        [TypeAction.STATUS_ISN],
        stateDateQuotation.status_isn
      );
    } finally {
      setIsLoading(false);
      setStatus((prevState) => {
        return prevState + status_isn;
      });
    }
  };
  return (
    <Row gutter={[24, { xs: 8, sm: 8, md: 0 }]} style={{ marginTop: 20 }}>
      <Col span={6} xs={24} md={6}>
        <CustomLoader spinning={isLoading}>
          <Form.Item name={TypeAction.STATUS_ISN} label="Статус">
            <CSelect
              placeholder="Статус"
              size="large"
              onChange={quotationStatusChange}
              disabled={stateDateQuotation?.agr_status === 'Оформление'}
            >
              {statusList.length &&
                statusList?.map((element) => {
                  return (
                    <Option value={element.foreign_id} key={element.foreign_id}>
                      {element.full_name}
                    </Option>
                  );
                })}
            </CSelect>
          </Form.Item>
        </CustomLoader>
      </Col>
    </Row>
  );
};

export default StatusQuotation;
