import React, { useState } from 'react';
import { Input, Select, Space } from 'antd';

interface Props {
  onChange: (param: any) => void;
}

const MySelect = (props: Props) => {
  const { onChange } = props;

  const options = [
    { value: '0', label: 'Выберите раздел', disabled: true, hidden: true },
    { value: '1', label: 'Техническая' },
    { value: '2', label: 'Бизнес часть' },
    { value: '3', label: 'Страховые продукты' },
    { value: '4', label: 'Клиентская часть' },
    { value: '5', label: 'Свой вариант' },
  ];

  const [ideaTheme, setIdeaTheme] = useState('0');
  const [customTheme, setCustomTheme] = useState('');

  const [temp, setTemp] = useState({
    value: '5',
    label: 'Свой вариант',
    custom: '',
  });

  const handleIdeaThemeChange = (selectedTheme, selectedThemeParams) => {
    const newSelectedTheme = { ...selectedThemeParams, customTheme };

    setIdeaTheme(selectedTheme);
    setTemp(newSelectedTheme);
    onChange(newSelectedTheme);
  };

  const handleCustomThemeChange = (newCustomTheme) => {
    setCustomTheme(newCustomTheme);
    onChange({ ...temp, custom: newCustomTheme });
  };

  if (ideaTheme === '5') {
    return (
      <Space.Compact style={{ width: '100%' }}>
        <Select
          defaultValue="0"
          options={options}
          onChange={handleIdeaThemeChange}
          value={ideaTheme}
          style={{ flexBasis: 'max-content' }}
        />
        <Input
          value={customTheme}
          onChange={(e) => handleCustomThemeChange(e.target.value)}
          placeholder="Укажите свой вариант"
        />
      </Space.Compact>
    );
  } else {
    return (
      <Select
        defaultValue="0"
        style={{ width: '100%' }}
        onChange={handleIdeaThemeChange}
        options={options}
        value={ideaTheme}
      />
    );
  }
};

export default MySelect;
