import React, { useEffect, useState } from 'react';
import { Card, Button, Col, Typography, Space, notification } from 'antd';
import useQuotation from '../hooks/useQuotation';
import './FilesQuotation.scss';

import { Link } from 'react-router-dom';

import CDragger from '@components/UI/dragger/CDragger';
import api from '@modules/Quotation/services';
import useFormSubmit from '@modules/Quotation/hooks/CommonMethods/MainComponents/useFormSubmit';
import { IFile } from '@modules/Agreement/type';
import {
  DeleteOutlined,
  DownloadOutlined,
  FileTwoTone,
} from '@ant-design/icons';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
type FilesQuotationProps = {
  userForeignId: number;
  agrIsn: number | undefined;
};

const FilesQuotation = ({ userForeignId, agrIsn }: FilesQuotationProps) => {
  const { stateDateQuotation } = useQuotation();
  const { Title } = Typography;
  const [temporaryFileList, setTemporaryFileList] = useState<any[]>([]);
  const [docExtensions] = useState<any>([
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.rar',
    'application/zip',
  ]);
  const { fileListSend } = useFormSubmit();
  const { setFileList, fileList } = useQuotation();
  const beforeUpload = (file: any, newFilesList: any) => {
    setTemporaryFileList([...temporaryFileList, ...newFilesList]);
  };
  useEffect(() => {
    console.log('fileListQUOT', fileList);
  }, [fileList]);
  /**
   * Отправляем запрос на удаление на бэк
   * При успехе удаляем из fileList
   * или выводим ошибку
   * @param fileData - isn удаляемого файла
   */
  const handleRemove = async (fileData: any = undefined) => {
    event?.preventDefault();
    if (!fileData) return;
    try {
      await api.saveQuotation.fileArchive({ file_isn: fileData.isn });
      setFileList((fileList3) => {
        const index = fileList3.findIndex((item) => item.isn === fileData.isn);
        const newFileList = fileList3.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      notification.success({
        message: 'Файл успешно удален',
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: 'При удалении файла произошла ошибка, попробуйте снова',
      });
    }
  };

  /**
   * загрузка файла на бэк
   * @param options
   */
  // source: https://stackoverflow.com/questions/58128062/using-customrequest-in-ant-design-file-upload
  const uploadFile = async (options) => {
    const { onSuccess, onError } = options;

    try {
      const result = await api.saveQuotation.fileSave(
        fileListSend(temporaryFileList, userForeignId, Number(agrIsn))
      );

      onSuccess(
        notification.success({
          message: 'Файл успешно загружен',
        })
      );

      // Чистим список файлов у Dragger и пихаем их в список загруженных файлов
      // Нужно добавить свойство "fileName" в ответе бэка чтобы правильно просадить данные в массив
      /*
      setTemporaryFileList([
        {
          ...temporaryFileList,
          file_path: result.data.file_path,
          isn: result.data.file_isn,
        },
      ]);
      */

      setFileList([
        ...fileList,
        ...temporaryFileList.map((fileItem) => {
          return {
            file_name: fileItem.name,
            file_path: result.data.file_path,
            isn: result.data.file_isn,
          };
        }),
      ]);

      setTemporaryFileList([]);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: 'При загрузке файла произошла ошибка. Повторите загрузку',
      });
    }
  };

  /**
   * Конвертирует base64 в файл
   * @param b64Data
   * @param filename
   * @param contentType
   * @param sliceSize
   */
  // TODO: Копипаста - вынести в отдельный файл и импортировать
  const base64toFile = (b64Data, filename, contentType, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new File(byteArrays, `"${filename}"`, { type: contentType });
  };

  const downloadFile = async (fileData) => {
    event?.preventDefault();
    const { file_path, file_name } = fileData;
    if (!file_path && !file_name) return;

    try {
      const result = await api.saveQuotation.fileDownload({
        file_path,
        file_name,
      });

      if (result.status === 200) {
        // Создаем Blob объект из бинарных данных
        const file = base64toFile(
          result.data.file,
          file_name,
          result.data.contentType
        );
        const downloadLink = document.createElement('a');

        // Устанавливаем атрибут href для ссылки на Blob URL
        downloadLink.href = URL.createObjectURL(file);

        // Устанавливаем имя файла для загрузки (необязательно)
        downloadLink.download = file_name;

        // Симулируем клик по ссылке для начала скачивания
        downloadLink.click();

        // Очищаем Blob URL после завершения скачивания
        URL.revokeObjectURL(downloadLink.href);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: 'При скачивании файла произошла ошибка. Попробуйте снова',
      });
    }
  };

  /**
   * для котировок
   */
  const read_files = (file_path) => {
    return file_path.replaceAll('/', '-');
  };

  const UploadedFiles = () => {
    return (
      <Col span="24">
        <Title level={5} type="secondary">
          Вложенные документы
        </Title>
        <div className="mb-2">
          <Space wrap>
            {fileList?.map(({ file_path, file_name, isn }: IFile, index) => {
              return (
                <div className="files-quotation__item" key={index}>
                  <div className="files-quotation__icon">
                    <FileTwoTone />
                  </div>

                  <p className="files-quotation__filename">{file_name}</p>

                  <Button
                    className="files-quotation__item-btn ml-1"
                    title="Скачать"
                    shape="circle"
                    icon={<DownloadOutlined />}
                    onClick={() => downloadFile({ file_path, file_name })}
                  />

                  <Button
                    className="files-quotation__item-btn"
                    title="Удалить"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemove({ isn })}
                    disabled={checkDisabledField(stateDateQuotation.status_isn)}
                  />
                </div>
              );
            })}
          </Space>
        </div>
      </Col>
    );
  };

  return (
    <Card className="mt-5">
      <Col xs={24} xl={8}>
        <Title className="main-quotation__section-title" level={3}>
          Прикрепление файла
        </Title>
      </Col>
      {!!fileList.length && <UploadedFiles />}
      <CDragger
        className="mt-6"
        listType="text"
        name="files"
        accept={docExtensions}
        customRequest={uploadFile}
        beforeUpload={beforeUpload}
        fileList={temporaryFileList}
        onRemove={(e) => handleRemove(e)}
        disabled={checkDisabledField(stateDateQuotation.status_isn)}
      />
    </Card>
  );
};

export default FilesQuotation;
