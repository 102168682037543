/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Col, Form, Input, notification, Row, Select } from 'antd';
import axios from 'axios';
import api from './api';
import dayjs from 'dayjs';

const { Search } = Input;
const { Option } = Select;

interface Props {
  fieldID: string;
  fieldName: string;
  title: string;
  required: boolean;
  initialValue?: {
    val: any;
  };
  name: number;
  id: number;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}

// ???

interface IUser {
  department: string;
  duty: string;
  full_name: string;
  user_id: number;
}

interface IFullData {
  ARCH_ID: null | number | string;
  ARCH_ISN: null | number | string;
  ATT_1: string;
  ATT_2: string
  DATEBEG: string
  DATEEND: string;
  ID: string
  ISN: string
  STATUS: string;
}

function AgreementField(props: Props) {
  const {
    fieldID,
    fieldName,
    title = 'Title',
    required = false,
    initialValue,
    name,
    id,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const form = Form.useFormInstance();
  const isRowColumnEditor = typeof name === 'number';
  const [fullData, setFullData] = useState<IFullData[]>([]);

  const changeFields = (selectedData) => {
    const { row_column_editor } = form.getFieldsValue();
    let allData = JSON.parse(JSON.stringify(row_column_editor));
    allData = allData.map((column, index) => {
      if (index == name) {
        const check = Object.keys(column).map((key) => {
          if (column[key].full_name) {
            switch (column[key].full_name) {
              case 'Сдача в архив':
                return {
                  ...column[key],
                  char_value: selectedData.ARCH_ID,
                  num_value: selectedData.ARCH_ISN,
                };
              case 'Статус':
                return {
                  ...column[key],
                  char_value: selectedData.STATUS,
                };
              case 'Дата начала договора':
                return {
                  ...column[key],
                  date_value: dayjs(selectedData.DATEBEG).isValid()
                    ? dayjs(selectedData.DATEBEG)
                    : null,
                };
              case 'Дата окончания договора':
                return {
                  ...column[key],
                  date_value: dayjs(selectedData.DATEEND).isValid()
                    ? dayjs(selectedData.DATEEND)
                    : null,
                };
              case 'Комментарии':
                return {
                  ...column[key],
                };
              case 'Вложение 1 раздела договора':
                return {
                  ...column[key],
                  char_value: selectedData.ATT_1,
                };
              case 'Вложение заявление-анкеты':
                return {
                  ...column[key],
                  char_value: selectedData.ATT_2,
                };
              case 'Номер договора':
                return {
                  ...column[key],
                  char_value: selectedData.ID,
                  num_value: selectedData.ISN,
                };
              default:
                if (column[key].type == 'DATE') {
                  return {
                    ...column[key],
                    date_value: dayjs(column[key].date_value).isValid()
                      ? dayjs(column[key].date_value)
                      : null,
                  };
                } else {
                  return {
                    ...column[key],
                  };
                }
            }
          } else {
            switch (column[key].display_no) {
              case 1:
                return {
                  ...column[key],
                  char_value: selectedData.ID,
                  num_value: selectedData.ISN,
                };

              case 2:
                return {
                  ...column[key],
                  char_value: selectedData.STATUS,
                };
              case 3:
                return {
                  ...column[key],
                  date_value: dayjs(selectedData.DATEBEG).isValid()
                    ? dayjs(selectedData.DATEBEG)
                    : null,
                };
              case 4:
                return {
                  ...column[key],
                  date_value: dayjs(selectedData.DATEEND).isValid()
                    ? dayjs(selectedData.DATEEND)
                    : null,
                };
              case 5:
                return {
                  ...column[key],
                };
              case 6:
                return {
                  ...column[key],
                  char_value: selectedData.ATT_1,
                };
              case 7:
                return {
                  ...column[key],
                  char_value: selectedData.ATT_2,
                };
              case 8:
                return {
                  ...column[key],
                  char_value: selectedData.ARCH_ID,
                  num_value: selectedData.ARCH_ISN,
                };
              default:
                if (column[key].type == 'DATE') {
                  return {
                    ...column[key],
                    date_value: dayjs(column[key].date_value).isValid()
                      ? dayjs(column[key].date_value)
                      : null,
                  };
                } else {
                  return {
                    ...column[key],
                  };
                }
            }
          }
        });

        return check;
      } else {
        const data = Object.keys(column).map((key) => {
          if (column[key].type == 'DATE') {
            return {
              ...column[key],
              date_value: dayjs(column[key].date_value).isValid()
                ? dayjs(column[key].date_value)
                : null,
            };
          } else {
            return { ...column[key] };
          }
        });

        return data;
      }
    });

    const send = allData.map((row) =>
      Object.fromEntries(
        row.map((column) => [
          `id-${column.editor_id}`,
          {
            display_no: column.display_no,
            editor_id: column.editor_id,
            order_no: column.order_no,
            type: column.type,
            document_id: id,
            destination_id: column.destination_id,
            executor_id: column.executor_id,
            date_value: column.date_value,
            num_value: column.num_value,
            char_value: column.char_value,
            id: column.id,
            full_name: column.field_name,
            department: column.department,
            duty: column.duty,
            document_row_id: column.document_row_id,
            // full_name: column.field_name,
          },
        ])
      )
    );

    form.setFieldsValue({ row_column_editor: send });
    if (isDisabledStatus && isAdmin && onChangeByTd) {
      onChangeByTd(fieldID, name);
    }
  };
  const handleSearchAgreement = async (iin, event) => {
    if (event?.currentTarget.nodeName === 'INPUT' || iin.length === 0) {
      return;
    }
    try {
      setIsLoading(true);

      const { data } = await api.searchAgreement({
        doc_num: iin,
      });

      if (data.data.length > 1) {
        setFullData(data.data);
      } else {
        changeFields(data.data['0']);
      }
    } catch (error) {
      let message;
      let description;

      if (axios.isAxiosError(error)) {
        description =
          typeof error.response?.data.message !== 'undefined'
            ? error.response.data.message
            : error.response?.statusText;

        message = `Ошибка ${error.response?.status}`;
      } else {
        message = String(error);
      }

      notification.error({
        message,
        description,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeSelect = (val) => {
    const find = fullData.filter((item) => item.ISN === val)[0];
    if (find) {
      changeFields(find);
    }
  };

  return (
    fullData?.length > 1 ? (
      <>
        <Form.Item
          label={title}
          rules={[{ required }]}
          // initialValue={initialValue?.val?.id}
          name={
            isRowColumnEditor
              ? [name, fieldID, fieldName]
              : [fieldID, fieldName]
          }
        >
          <Select style={{ width: '100%' }} onChange={onChangeSelect}>
            {fullData?.map((item) => (
              <Option key={item?.ISN} value={item?.ISN}>
                {item?.ID}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={title}
          rules={[{ required }]}
          initialValue={initialValue?.val?.id}
          hidden
          noStyle
          name={
            isRowColumnEditor
              ? [name, fieldID, 'num_value']
              : [fieldID, 'num_value']
          }
        >
          <Input/>
        </Form.Item>
      </>
    ) : (<>
      <Row gutter={15} align="bottom">
        <Col span="24">
          <Form.Item
            label={title}
            rules={[{ required }]}
            initialValue={initialValue?.val?.id}
            name={
              isRowColumnEditor
                ? [name, fieldID, fieldName]
                : [fieldID, fieldName]
            }
          >
            <Search
              placeholder="Введите номер договора"
              loading={isLoading}
              enterButton
              allowClear
              onSearch={handleSearchAgreement}
            />
          </Form.Item>
          <Form.Item
            label={title}
            rules={[{ required }]}
            initialValue={initialValue?.val?.id}
            hidden
            noStyle
            name={
              isRowColumnEditor
                ? [name, fieldID, 'num_value']
                : [fieldID, fieldName]
            }
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
    </>)
  );
}
export default AgreementField;
