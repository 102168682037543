import CInput from '@components/UI/input/CInput';
import CRadio from '@components/UI/radio/CRadio';
import CSelect from '@components/UI/select/CSelect';
import {
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Checkbox,
  Button,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import api from '@modules/Quotation/services';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import useQuotation from '@modules/Quotation/hooks/useQuotation';

const { Option } = Select;
const CardCarAdditional = ({
  selectedCar,
  agrIsn,
  objAttributesList,
  userForeignId,
  objAttrData,
  attrObjForm,
  productIsn,
}) => {
  const [loading, setLoading] = useState(false);
  const { stateDateQuotation } = useQuotation();
  const saveObjAttr = async () => {
    setLoading(true);
    const all = Object.keys(attrObjForm.getFieldsValue())
      .map((key) => attrObjForm.getFieldsValue()[key])
      .filter((elem) => elem.val_n);
    await api.saveQuotation.attribute_obj_save({ agr_obj_attributes: all });
    setLoading(false);
  };
  useEffect(() => {
    if (agrIsn) {
      attrObjForm.setFieldValue('agr_isn', agrIsn);
    }
  }, [agrIsn]);

  const getPriceFromKolesa = () => {
    api.saveQuotation
      .priceFromKolesa(agrIsn)
      .then((data) => {
        notification.info({
          message:
            'Успешное вычисление предположительной стоимости с kolesa.kz',
        });
        attrObjForm.setFieldValue(
          [2668771, 'val_n'],
          data.data.data.KOLESA_PRICE
        );
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          attrObjForm.setFieldValue([2668771, 'val_n'], 0);
          notification.info({ message: error.response.data.message });
        }
      });
  };

  return (
    <CustomLoader spinning={loading}>
      <Form
        className="c-form"
        layout="vertical"
        name="objAttributes"
        form={attrObjForm}
      >
        <Row className="d-flex justify-end mb-4">
          <Button onClick={getPriceFromKolesa}>
            Узнать стоимость в kolesa.kz
          </Button>
        </Row>
        <Row gutter={6}>
          {objAttributesList?.map((item) => {
            if (item.active_mycent) {
              switch (item.attr_type) {
                case 'DICTI':
                  return (
                    <Col span={12}>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'val_n']}
                        label={item.attr_name}
                      >
                        <CSelect
                          placeholder={item.attr_name}
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        >
                          {item.dictis?.map((dicti) => (
                            <Option value={dicti.isn} key={dicti.isn}>
                              {dicti.full_name}
                            </Option>
                          ))}
                        </CSelect>
                      </Form.Item>
                    </Col>
                  );
                case 'NUMBER':
                case 'INTEGER': {
                  return (
                    <Col span={12}>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'class_isn']}
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'val_n']}
                        label={item.attr_name}
                      >
                        <CInput
                          placeholder={item.attr_name}
                          className={
                            item.attr_isn === 475611 ? 'numericMask' : null
                          }
                          disabled={
                            checkDisabledField(stateDateQuotation.status_isn) ||
                            item.attr_isn === 2668771
                          }
                        />
                      </Form.Item>
                    </Col>
                  );
                }
                case 'CHECKBOX': {
                  return (
                    <Col span={12}>
                      <Form.Item
                        name={[item.attr_isn, 'fid_obj']}
                        initialValue={selectedCar.fid}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'agr_isn']}
                        initialValue={`${agrIsn}`}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name="class_isn"
                        initialValue={'220596'}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'attr_isn']}
                        initialValue={item.attr_isn}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[item.attr_isn, 'user_isn']}
                        initialValue={userForeignId}
                        hidden
                        noStyle
                      >
                        <CInput
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                      <Form.Item name={[item.attr_isn, 'val_n']}>
                        <Checkbox
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        >
                          {item.attr_name}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  );
                }
              }
            }
          })}
        </Row>
        <Col span={24}>
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={saveObjAttr}
          >
            Сохранить
          </Button>
        </Col>
      </Form>
    </CustomLoader>
  );
};
export default CardCarAdditional;
