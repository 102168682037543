import { IRowColumnEditor } from '@modules/Edo/types';
import { Form, Input, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { IUser } from './FieldUser';
import api from '@modules/Edo/services/api';

interface IAdminDay {
  settings: IRowColumnEditor;
  name: number;
  onChangeByTd: any;
  isAdmin: any;
  isDisabledStatus: any;
}

function AdminDay(props: IAdminDay) {
  const [user, setUser] = useState<IUser | null>(null);

  const { settings, name, onChangeByTd, isAdmin, isDisabledStatus } = props;

  const form = Form.useFormInstance();

  const isRowColumnEditor = typeof name === 'number';
  const propertyValue = Form.useWatch(['row_column_editor', name], form);

  useEffect(() => {
    if (propertyValue) {
      const formValues = Object.values(propertyValue);

      formValues.map((element: any) => {
        if (element.type == 'DEPTEMPL') {
          setUser(element);
        }
      });
    }
  }, [propertyValue]);
  useEffect(() => {
    if (user) {
      if (isRowColumnEditor) {
        const { row_column_editor } = form.getFieldsValue();
        if (typeof user.adminDay == 'number') {
          Object.assign(row_column_editor[name][`id-${settings.id}`], {
            char_value: user.adminDay,
          });
        }
        form.setFieldsValue({ row_column_editor });
      }
    }
  }, [user]);

  return (
    <Form.Item
      label={settings.field_name}
      name={[name, `id-${settings.id}`, 'char_value']}
      rules={[{ required: settings.required }]}
    >
      <InputNumber
        onChange={
          isAdmin && isDisabledStatus && onChangeByTd(settings.id, name)
        }
        formatter={(value) =>
          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(/\,/g, '.')
        }
        parser={(x) =>
          parseFloat(`${x}`.replace(/\s/g, '').replace(/\,/g, '.'))
        }
        disabled={true}
        style={{ width: '100%' }}
        type="number"
      />
    </Form.Item>
  );
}

export default AdminDay;
