import React from 'react';
import { Upload , Button} from 'antd';
import './CUpload.scss';
import { UploadOutlined , PlusOutlined} from '@ant-design/icons';

const CUpload = (props) => {
  return (
    <Upload {...props.props} className={`c-upload ${props.className || ''}`}>
        <Button>
            <PlusOutlined />
        </Button>
        <div>
            <p>Загрузить новый файл</p>
            <p>Максимум 10 МБ</p>
        </div>
    </Upload>
  );
};

export default CUpload;
