import { Modal, Tabs } from 'antd';
import CardCarMainDynamic from './CardCarMainDynamic';
import type { TabsProps } from 'antd';
import { useImmerReducer } from 'use-immer';
import api from '@modules/Quotation/services';
import { useEffect, useState } from 'react';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import PrimaryConditionsDynamic from '../ObjectCardDynamic/PrimaryConditionsDynamic';
import ObjectCardAdditionalDynamic from '../ObjectCardDynamic/ObjectCardAdditionalDynamic';
import ObjectCardUnderDynamic from '../ObjectCardDynamic/ObjectCardUnderDynamic';
type Action = {
  type: 'setDicti' | 'loading' | 'saveObjAttrData';
  payload?: any;
};
interface State {
  // dicti: any;
  ownerOfCar: any;
  isLoadingDicti: boolean;
  attrData: any;
}
const initialState: State = {
  // dicti: null,
  ownerOfCar: null,
  isLoadingDicti: false,
  attrData: null,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    // case 'setDicti': {
    //   if (draft.dicti) {
    //     draft.dicti = { ...draft.dicti, ...action.payload };
    //   } else {
    //     draft.dicti = action.payload;
    //   }

    //   draft.ownerOfCar = action.payload.ownerOfCar;
    //   break;
    // }
    case 'saveObjAttrData': {
      draft.attrData = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoadingDicti = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const CardCarModalDynamic = ({
  closeTransportModal,
  selectedCar,
  isLoading,
  agrIsn,
  objAttributesList,
  userForeignId,
  saveObject,
  objAttrData,
  productIsn,
  carForm,
  AdvancedContractorSearch,
  modalSetting,
  saveObjectCond,
  productSelectedQuotation,
  primaryCondForm,
  additionalObjAttributesForm,
  additionalUnderForm,
  agrCond,
  dicti,
  quotationData,
  condData,
}) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const { isLoadingDicti, ownerOfCar, attrData } = state;
  const [items, setItems] = useState<TabsProps['items']>();
  useEffect(() => {
    if (dicti) {
      setItems([
        {
          key: '1',
          label: 'Основное',
          children: (
            <CardCarMainDynamic
              agrIsn={agrIsn}
              selectedCar={selectedCar}
              dicti={dicti}
              saveObject={saveObject}
              carForm={carForm}
              AdvancedContractorSearch={AdvancedContractorSearch}
              modalSetting={modalSetting}
            />
          ),
        },
      ]);
    }
  }, [dicti, selectedCar]);
  useEffect(() => {
    if (items && items.length === 1 && selectedCar) {
      if (
        objAttributesList.additional &&
        objAttributesList.additional.length > 0 &&
        (!objAttributesList.under || objAttributesList.under.length === 0)
      ) {
        setItems([
          ...items,
          {
            key: '2',
            label: 'Первичные условия',
            children: (
              <PrimaryConditionsDynamic
                productIsn={productIsn}
                modalSetting={modalSetting}
                saveObjectCond={saveObjectCond}
                productSelectedQuotation={productSelectedQuotation}
                primaryCondForm={primaryCondForm}
                selectedObj={selectedCar}
                carForm={carForm}
                agrCond={agrCond}
                dicti={dicti}
                quotationData={quotationData}
                condData={condData}
              />
            ),
          },
          {
            key: '3',
            label: 'Раздел страховщика',
            children: (
              <ObjectCardAdditionalDynamic
                agrIsn={agrIsn}
                objAttributesList={objAttributesList}
                objAttrData={objAttrData}
                userForeignId={userForeignId}
                additionalObjAttributesForm={additionalObjAttributesForm}
                selectedObj={selectedCar}
                dicti={dicti}
              />
            ),
          },
        ]);
      } else if (
        !objAttributesList.additional &&
        objAttributesList.under &&
        objAttributesList.under.length > 0
      ) {
        setItems([
          ...items,
          {
            key: '2',
            label: 'Первичные условия',
            children: (
              <PrimaryConditionsDynamic
                productIsn={productIsn}
                modalSetting={modalSetting}
                saveObjectCond={saveObjectCond}
                productSelectedQuotation={productSelectedQuotation}
                primaryCondForm={primaryCondForm}
                selectedObj={selectedCar}
                carForm={carForm}
                agrCond={agrCond}
                dicti={dicti}
                quotationData={quotationData}
                condData={condData}
              />
            ),
          },
          {
            key: '4',
            label: 'Раздел андеррайтера',
            children: (
              <ObjectCardUnderDynamic
                agrIsn={agrIsn}
                objAttributesList={objAttributesList}
                userForeignId={userForeignId}
                objAttrData={objAttrData}
                productIsn={productIsn}
                additionalUnderForm={additionalUnderForm}
                selectedObj={selectedCar}
                dicti={dicti}
              />
            ),
          },
        ]);
      } else if (
        objAttributesList.additional &&
        objAttributesList.additional.length > 0 &&
        objAttributesList.under &&
        objAttributesList.under.length > 0
      ) {
        setItems([
          ...items,
          {
            key: '2',
            label: 'Первичные условия',
            children: (
              <PrimaryConditionsDynamic
                productIsn={productIsn}
                modalSetting={modalSetting}
                saveObjectCond={saveObjectCond}
                productSelectedQuotation={productSelectedQuotation}
                primaryCondForm={primaryCondForm}
                selectedObj={selectedCar}
                carForm={carForm}
                agrCond={agrCond}
                dicti={dicti}
                quotationData={quotationData}
                condData={condData}
              />
            ),
          },
          {
            key: '3',
            label: 'Раздел страховщика',
            children: (
              <ObjectCardAdditionalDynamic
                agrIsn={agrIsn}
                objAttributesList={objAttributesList}
                userForeignId={userForeignId}
                objAttrData={objAttrData}
                additionalObjAttributesForm={additionalObjAttributesForm}
                selectedObj={selectedCar}
                dicti={dicti}
              />
            ),
          },
          {
            key: '4',
            label: 'Раздел андеррайтера',
            children: (
              <ObjectCardUnderDynamic
                agrIsn={agrIsn}
                objAttributesList={objAttributesList}
                userForeignId={userForeignId}
                objAttrData={objAttrData}
                productIsn={productIsn}
                additionalUnderForm={additionalUnderForm}
                selectedObj={selectedCar}
                dicti={dicti}
              />
            ),
          },
        ]);
      } else {
        setItems([
          ...items,
          {
            key: '2',
            label: 'Первичные условия',
            children: (
              <PrimaryConditionsDynamic
                productIsn={productIsn}
                modalSetting={modalSetting}
                saveObjectCond={saveObjectCond}
                productSelectedQuotation={productSelectedQuotation}
                primaryCondForm={primaryCondForm}
                selectedObj={selectedCar}
                carForm={carForm}
                agrCond={agrCond}
                dicti={dicti}
                quotationData={quotationData}
                condData={condData}
              />
            ),
          },
        ]);
      }
    }
  }, [objAttributesList, items, selectedCar]);

  return (
    <Modal
      width={'1000px'}
      title={
        <span
          style={{
            color: '#7449ef',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          Добавить транспортное средство
        </span>
      }
      open={true}
      onCancel={closeTransportModal}
      footer={<></>}
    >
      <CustomLoader spinning={isLoading || isLoadingDicti}>
        <Tabs defaultActiveKey="1" items={items} type="card" />
      </CustomLoader>
    </Modal>
  );
};
export default CardCarModalDynamic;
