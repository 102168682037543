import { useEffect, useState } from 'react';
import { TreeSelect } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import api from '../../services/api';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const { TreeNode } = TreeSelect;
const { Content } = Layout;

const Colleagues = ({ title = 'Mycent.kz' }) => {
  const [fetchedColleagues, setFetchedColleagues] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
    getColleagues();
  }, []);

  async function getColleagues() {
    setIsLoading(true);
    const { data } = await api.colleagues.getColleagues();
    setIsLoading(false);
    setFetchedColleagues(data.data);
  }

  const getTreeSelect = (company: any) => {
    return (
      <>
        {company.users.length
          ? company.users.map((user: any) => (
              <TreeNode
                value={user?.id + '-' + user?.full_name}
                key={user?.id + '-' + user?.full_name}
                user={{ id: user?.id, target: 'user' }}
                title={user?.full_name}
              ></TreeNode>
            ))
          : null}
        {company?.child.length
          ? company?.child?.map((child: any) => (
              <TreeNode
                value={company?.id + '-' + child?.full_name}
                key={company?.id + '-' + child?.full_name}
                title={child?.full_name}
              >
                {child?.child ? getTreeSelect(child) : null}
              </TreeNode>
            ))
          : null}
      </>
    );
  };

  const onSelect = (value: string, node: any) => {
    // переход на стр. профиля (новый майсент)
    if (node?.user?.target !== 'user') {
      return;
    } else if (Number(node?.user?.id)) {
      process.env.NODE_ENV === 'development'
      ? window.location.replace(`http://localhost:5173/profile/${node?.user?.id}`)
      : window.location.replace(`https://new.mycent.kz/profile/${node?.user?.id}`);
    }
  };

  const clearSelected = () => {
    setSelected(null);
  };
  if (isLoading) {
    return (
      <>
        <CustomLoader />
      </>
    );
  }

  return (
    <Content>
      <TreeSelect
        defaultValue={selected}
        showSearch={true}
        dropdownStyle={{ overflow: 'auto' }}
        style={{ display: 'block', marginBottom: '20px' }}
        placeholder="Найти коллегу"
        allowClear={true}
        onSelect={onSelect}
        onChange={(value) => setSelected(value)}
        value={selected}
        onClick={clearSelected}
      >
        {fetchedColleagues?.map((company: any) => (
          <TreeNode
            value={company.id}
            title={company?.full_name}
            key={company.id}
          >
            {getTreeSelect(company)}
          </TreeNode>
        ))}
      </TreeSelect>
      <Outlet />
    </Content>
  );
};

export default Colleagues;
