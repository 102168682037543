import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Typography } from 'antd';
import CInput from '@components/UI/input/CInput';
const MainContractObjects = ({ objects, quotationData }) => {
  const [clausesData, setClausesData] = useState<any[]>([]);
  const { Title } = Typography;

  useEffect(() => {
    if (quotationData) {
      setClausesData(quotationData?.data?.agrcond);
    }
  }, [quotationData]);

  return (
    <Form className="c-form" layout="vertical">
      <Card className="mt-5">
        <Title className="main-contract__section-title" level={3}>
          Объекты
        </Title>
        <Row gutter={18} className="mt-5" style={{ marginBottom: -20 }}>
          {objects.map((object, index) => (
            object?.address && <Col span="8" key={index}>
              <Form.Item
                label="Адрес"
                name={[`id-${object?.attr_isn}`, 'char_value']}
                initialValue={object?.address}
              >
                <CInput disabled={true} value={object?.address} size="large" />
              </Form.Item>
            </Col>
          ))}
          {clausesData[0]?.limitsum ? (
            <Col span="8">
              <Form.Item
                label="Страховая сумма"
                name={[`id-${clausesData[0]?.limitsum}`, 'char_value']}
                initialValue={clausesData[0]?.limitsum}
              >
                <CInput disabled={true} className="numericMask" value={clausesData[0]?.limitsum} size="large" />
              </Form.Item>
            </Col>
          ) : null}
          {clausesData[0]?.tariff ? (
            <Col span="8">
              <Form.Item
                label="Тариф"
                name={[`id-${clausesData[0]?.tariff}`, 'char_value']}
                initialValue={clausesData[0]?.tariff}
              >
                <CInput disabled={true}  value={clausesData[0]?.tariff} size="large" />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
      </Card>
    </Form>
  );
};

export default MainContractObjects;
