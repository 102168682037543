import { Button, Result } from 'antd';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface Props {
  children?: React.ReactNode;
}

function ProtectedRoute({ children }: Props) {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const url = new URLSearchParams(location.search.slice(1));

  if (auth.user === null) {
    return <Navigate to={{ pathname: '/login', search: url.toString() }} />;
  }
  if (auth.user && auth.user?.data?.permission?.show_parse) {
    return <>{children}</>;
  }

  return (
    <Result
      status="warning"
      title="У Вас нет прав для доступа к этой странице"
      extra={
        <Button type="primary" key="console" onClick={() => navigate('/')}>
          На главную страницу
        </Button>
      }
    />
  );
}

export default ProtectedRoute;
