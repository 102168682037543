import { Button, Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import './InfoItem.scss';
import useUseful from '@hooks/useUseful';
import { IFolderInfo } from '@modules/OnlineLibrary/interfaces/IFoldreItem';
import { getNormalizeDate } from '@modules/OnlineLibrary/helpers/getNormalizeDate';
import { IFileLibrary } from '@modules/OnlineLibrary/interfaces/IFileLibrary';
import api from '@services/api';

const { Text, Title } = Typography;

interface IInfoItem {
  closeInfoModal: () => void;
  categories: IFolderInfo[];
  fileList: IFileLibrary[];
}

const InfoItem = ({ closeInfoModal, categories, fileList }: IInfoItem) => {
  const { infoCategoryId, currentFileUuid, isDocument } = useUseful();

  const [currentFolder, setCurrentFolder] = useState<IFolderInfo>({
    created_at: '',
    id: 0,
    name: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
  });

  const [currentFile, setCurrentFile] = useState<IFileLibrary>({
    file_uuid: '',
    filename: '',
    category_id: 0,
    created_at: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
  });

  const [fullNameCreatorFolder, setFullNameCreatorFolder] = useState<string>('');
  const [fullNameUpdatedFolder, setFullNameUpdatedFolder] = useState<string>('');

  const getUsersInfo = (created: string | null, updated: string | null) => {
    created !== null ? api.colleagues.getColleagueInfo(created).then((data) => {
      setFullNameCreatorFolder(data.data.data.user_info.full_name);
    }) : setFullNameCreatorFolder('');

    updated !== null ? api.colleagues.getColleagueInfo(updated).then((data) => {
      setFullNameUpdatedFolder(data.data.data.user_info.full_name);
    }) : setFullNameUpdatedFolder('');
  };


  useEffect(() => {
    if (isDocument) {
      const findFile = fileList.find((item) => item.file_uuid === currentFileUuid);
      if (findFile) {
        setCurrentFile(findFile);
        getUsersInfo(findFile.created_by, findFile.updated_by);
      }
    } else {
      const findCategory = categories.find((item) => item.id === Number(infoCategoryId));
      if (findCategory) {
        setCurrentFolder(findCategory);
        getUsersInfo(findCategory.created_by, findCategory.updated_by);
      }
    }
  }, [infoCategoryId, currentFileUuid, isDocument]);

  return (
    <Row className="sector-useful d-flex flex-column">
      <Row className="d-flex justify-space-between align-center">
        <Col span={18} className="d-flex align-center">
          {isDocument ? <i className="icon-document-information"/> : <i className="icon-folder-information"/>}
          <Title level={5} className="title-name-information">
            {isDocument ? currentFile.filename : currentFolder.name}
          </Title>
        </Col>
        <Col span={4} className="d-flex justify-end">
          <Button className="close-btn-information" onClick={closeInfoModal}>
            <i className="close-icon-information"/>
          </Button>
        </Col>
      </Row>
      <Row className="d-flex flex-column mt-5">
        <Title level={5} className="info-title-useful">Тип</Title>
        <Text className="info-subtitle-useful">{isDocument ? 'Документ' : 'Папка'}</Text>
      </Row>
      {!isDocument && <Row className="d-flex flex-column mt-5">
          <Title level={5} className="info-title-useful">Изменено</Title>
          <Text className="info-subtitle-useful">
            {isDocument ? getNormalizeDate(currentFile.updated_at) : getNormalizeDate(currentFolder.updated_at)} автор
              последних изменений {fullNameUpdatedFolder}
          </Text>
      </Row>}
      <Row className="d-flex flex-column mt-5">
        <Title level={5} className="info-title-useful">Создано</Title>
        <Text className="info-subtitle-useful">
          {isDocument ? getNormalizeDate(currentFile.created_at) : getNormalizeDate(currentFolder.created_at)} - {fullNameCreatorFolder}
        </Text>
      </Row>
    </Row>
  )
};

export default InfoItem;