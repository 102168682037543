export const enum fieldNamesRecruiting{
    chief_id = 'chief_id',
    name_of_post_id = 'name_of_post_id',
    quantity_people = 'quantity_people',
    department_to = 'department_to',
    reason_to_recruiting = 'reason_to_recruiting',
    desired_age = 'desired_age',
    sex = 'sex',
    education = 'education',
    functional_responsibilities = 'functional_responsibilities',
    work_experience = 'work_experience',
    is_he_was_boss = 'is_he_was_boss',
    type_of_hire = 'type_of_hire',
    request_to_candidate = 'request_to_candidate',
    perspective_to_candidate = 'perspective_to_candidate',
    computer_knowing = 'computer_knowing',
    salary = 'salary',
    motivation = 'motivation',
    job_chart = 'job_chart',
    have_car = 'have_car',
    driver_category = 'driver_category',
    candidates_trait = 'candidates_trait',
    interview_stage = 'interview_stage',
    interview_date = 'interview_date',
    interview_time = 'interview_time',
    interview_result = 'interview_result',
    date_of_internship = 'date_of_internship',
    date_of_conclusion_dou = 'date_of_conclusion_dou',
    commentary = 'commentary',
    responsible_recruiter_id = 'responsible_recruiter_id',
    application_status = 'application_status',
    lang_kaz = 'lang_kaz',
    lang_ru = 'lang_ru',
    lang_en = 'lang_en',
    social_packages = 'social_packages',
    salary_after_period = 'salary_after_period',
    reminder_quantity_people = 'reminder_quantity_people',
    candidate_phone_number = 'candidate_phone_number',
    candidate_fullname = 'candidate_fullname',
    file = 'file',
    date_of_conclusion_td = 'date_of_conclusion_td',
    security_service_result = 'security_service_result',
    candidate_iin = 'candidate_iin',
    security_service_comment = 'security_service_comment',
    reminder_candidates = 'reminder_candidates'
}

/*

$table->integer('staging_internship')->comment('Статус стажировки')->nullable();    
$table->date('date_of_conclusion_td')->comment('Дата ТД')->nullable();  
$table->integer('chief_id')->comment('ID Заполняющего')->index()->nullable();
$table->integer('name_of_post_id')->index()->nullable()->comment('Наименование вакансии');
$table->integer('quantity_people')->comment('количество');
$table->integer('department_to')->comment('Наименование структурного подразделения')->index()->nullable();
$table->integer('reason_to_recruiting')->comment('Причина возникновения вакансии')->nullable();
$table->integer('desired_age')->comment('Желаемый возраст')->nullable();
$table->integer('sex')->comment('Пол')->nullable();
$table->integer('education')->comment('Образование')->nullable();
$table->integer('functional_responsibilities')->comment('Функциональные обязанности')->nullable();
$table->integer('work_experience')->comment('Опыт работы')->nullable();
$table->integer('is_he_was_boss')->comment('Наличие подчиненных')->nullable();
$table->integer('type_of_hire')->comment('Форма найма')->nullable();
$table->string('request_to_candidate')->comment('Квалификационные требования к кандидату')->nullable();
$table->json('perspective_to_candidate')->comment('Возможности и перспективы для кандидата')->nullable();
$table->integer('computer_knowing')->comment('Уровень владения компьютерными программами')->nullable();
$table->integer('salary')->comment('На испытательный срок')->nullable();
$table->integer('motivation')->comment('Наличие дополнительной мотивации (бонусов)')->nullable();
$table->integer('job_chart')->comment('График работы')->nullable();
$table->integer('have_car')->comment('Наличие автомобиля')->nullable();
$table->integer('driver_category')->comment('Категория вод. удостоверения')->nullable();
$table->string('candidates_trait')->comment('Личностные компетенции кандидата')->nullable();
$table->string('interview_stage')->comment('Стадия интервью')->nullable();
$table->date('interview_date')->comment('Дата интервью')->nullable();
$table->dateTime('interview_time')->comment('Время интервью')->nullable();
$table->integer('interview_result')->comment('Результат интервью')->nullable()->index();
$table->date('date_of_internship')->comment('Дата стажировки')->nullable();
$table->date('date_of_conclusion_dou')->comment('Дата ДОУ')->nullable();
$table->text('commentary')->comment('Комментарий')->nullable();
$table->integer('responsible_recruiter_id')->comment('ID рекрутера')->index()->nullable();
$table->string('application_status')->comment('Статус заявки')->index()->nullable();
$table->integer('lang_kaz')->comment('Казахский')->nullable();
$table->integer('lang_ru')->comment('Русский')->nullable();
$table->integer('lang_en')->comment('Английский')->nullable();
$table->integer('social_packages')->comment('Социальный пакет')->nullable();
$table->integer('salary_after_period')->comment('После испытательного срока')->comment('После испытательного срока')->nullable();
$table->integer('reminder_quantity_people')->comment('Остаток количество')->nullable();

*/