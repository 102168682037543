import { notification } from 'antd';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import api from '@modules/Quotation/services';

export async function agrCalcMethod(
  agrCalc: (
    userForeignId: number,
    agrIsn: number | undefined
  ) => { user_isn: number; agr_isn: number | undefined},
  setPremiumTotal: Dispatch<SetStateAction<number>>,
  userForeignId: number,
  agrIsn: number | undefined,
  setIsLoadingCalcButton: Dispatch<SetStateAction<boolean>>
) {
  try {
    setIsLoadingCalcButton(true);
    const responseToAgrCalc = await api.quotation.setAgrCalculate(
      agrCalc(userForeignId, agrIsn)
    );

    if (responseToAgrCalc?.data?.success) {
      notification.success({
        message: '',
        description: responseToAgrCalc.data.message,
      });
      setPremiumTotal(responseToAgrCalc?.data?.data);
    }

  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      notification.info({
        message: 'Ошибка',
        description: String(err.response.data.error),
        duration: 0,
      });
    } else {
      notification.info({
        message: 'Ошибка',
        description: String(error),
        duration: 0,
      });
    }
  } finally {
    setIsLoadingCalcButton(false);
  }
}
