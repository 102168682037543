import React from 'react';

import { Col, Form, Modal, Select } from 'antd';
import { Types } from '../hooks/DynamicFieldsAttributes';

const { Option } = Select;

export function ModalSqlType({
  isModalVisible,
  handleOkSql,
  handleCancelSql,
  attributeMrpData,
  attributeIsn,
  insertDataAgrAttributesObject,
  insertDataAgrAttributes
}) {
  function afterSubmitValues() {
    const data = formModalSql.getFieldsValue(attributeIsn);
    handleOkSql(data[attributeIsn]);
  }

  const [formModalSql] = Form.useForm();

  return (
    <Modal
      title="МРП"
      open={isModalVisible}
      onOk={afterSubmitValues}
      onCancel={handleCancelSql}
      centered
      cancelText="Отмена"
      okText="Сохранить"
    >
      <Col span={24}>
        <Form
          name="formModalSqlType"
          onFinish={afterSubmitValues}
          form={formModalSql}
        >
          <Form.Item name={attributeIsn}>
            <Select
              placeholder="SQL"
              notFoundContent="Данные не найдены"
              onChange={(value) => {
                insertDataAgrAttributes(Types.SQL, value);
                insertDataAgrAttributesObject(Types.SQL, value);
              }}
            >
              {attributeMrpData !== null &&
                attributeMrpData?.map((attr, index) => (
                  <Option value={attr?.FULLNAME} key={attr?.ISN + index}>
                    {attr?.FULLNAME}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
}
