import axios from '@services/api/axios';

const api = {
    getFiles: (id, userId) => axios.get(`/file-exchange/directories/${id}/file-list?user_id=${userId}`),
    getFolders: (id) => axios.get(`/file-exchange/directories?user_id=${id}`),
    deleteFolder: (id, userId) => axios.delete(`/file-exchange/directories/delete/${id}?user_id=${userId}`),
    createFolder: (userId, data) => axios.post(`/file-exchange/directories/create?user_id=${userId}`, data),
    createLink: (userId, data) => axios.post(`/file-exchange/link/form?user_id=${userId}`, data),
    deleteFileExchange: (userId, uuid) => axios.delete(`/file-exchange/files/delete/${uuid}?user_id=${userId}`),
    downloadFiles: (uuid) => axios.get(`/file-exchange/link/download/${uuid}`, {responseType: 'blob'}),
    getFilesFromLink: (linkUuid) => axios.get(`/file-exchange/link/file-list/${linkUuid}`),
    getConfig: () => axios.get('/file-exchange/config/upload'),
    createUser: (data) => axios.post(`/file-exchange/users`, data),
    getUsers: (numberPage) => axios.get(`/file-exchange/users?page=${numberPage}`),
    editUser: (id, data) => axios.put(`/file-exchange/users/${id}`, data),
    deleteUser: (id) => axios.delete(`/file-exchange/users/${id}`),
    getDirectoriesAudit: (numberPage) => axios.get(`/file-exchange/audit/directories?page=${numberPage}`),
    getFilesAudit: (numberPage) => axios.get(`/file-exchange/audit/files?page=${numberPage}`),
    getLinksAudit: (numberPage) => axios.get(`/file-exchange/audit/links?page=${numberPage}`),
    getUsersAudit: (numberPage) => axios.get(`/file-exchange/audit/users?page=${numberPage}`),
};

export default api;
