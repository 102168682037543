import { Checkbox, Col, Progress, Row, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import './DocumentItem.scss';

interface IPropsDocumentItem {
	title: string | undefined;
	uuid?: string | undefined;
	main: boolean;
	fileLink: undefined | null | { file_uuid: string, to_be_deleted_on: string, }; // если нужно запретить удаление ставим на checkbox disabled={!!fileLink}
	isCheckbox: boolean;
};
const DocumentItem = ({ title, uuid, main, fileLink, isCheckbox }: IPropsDocumentItem) => {
	return (
		<Col xs={24} lg={main ? 6 : 11}>
			<Row className="item-file-exchange">
				<Row className="d-flex justify-space-between width">
					<i className="main-document-icon-file-exchange"/>
					{isCheckbox && <Checkbox value={uuid} onClick={(e) => e.stopPropagation()}/>}
				</Row>
				<Row className="d-flex justify-space-between width">
					<Title level={5} className="title-document-file-exchange">{title}</Title>
				</Row>
			</Row>
		</Col>
	)
};

export default DocumentItem;
