export const getErrorMessage = (errorMessage: string) => {
	const classMap: { [key: string]: string } = {
		'session_canceled': 'Сессия аннулирована',
		'session_draft': 'Документ не соглосован',
		'session_signed': 'Документ уже полностью подписан',
		'sessions_not_found': 'Сессии не найдены (при передаче нескольких session uuid)',
		'session_not_found' : 'Сессия не найдена (при передеаче одного session uuid)',
		'session_not_draft' : 'Сессия не в статусе соглосования',
		'cert_password_incorrect' : 'Неверный пароль сертификата',
		'cert_invalid' : 'Сертификат не действителен',
		'cert_revoked' : 'Сертификат отозван',
		'cert_expired' : 'Истек срок действия сертификата',
		'invalid_signer' : 'Неверный подписант',
		'invalid_approver' : 'Неверный согласующий',
		'signed' : 'Вы уже подписали данный документ',
		'signing_error' : 'Ошибка при подписании',
		'signing_failed' : 'Не удалось подписать',
	};

	return classMap[errorMessage] || 'Ошибка на сервере, попробуйте позже';
};