import React from 'react';
import './TableQuotation.scss';
import {Popconfirm, Col, Divider, Row, Table, Empty, Tooltip} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import useFetchCreatedQuotation from '../hooks/SearchProduct/useFetchCreatedQuotation';
import {Link} from 'react-router-dom';

interface JournalQuotationProps {
  dataAfterSubmitAndClear: any;
  transitionQuotationMethod: (quotationId) => void;
}

const TableQuotation: React.FC<JournalQuotationProps> = ({
  dataAfterSubmitAndClear,
  transitionQuotationMethod,
}) => {
  const { data, loading } = useFetchCreatedQuotation({
    dataAfterSubmitAndClear,
  });

  const columns = [
    {
      width: '15%',
      title: () => {
        return <span>Номер</span>;
      },
      dataIndex: 'QUOTATION_ID',
      key: '1',
      render: (quotationId) => (
        <Tooltip title="Открыть котировку" mouseEnterDelay={1}>
          <Link to={`/quotation/${quotationId}`} className="quotqtion-id">
            {quotationId}
          </Link>
        </Tooltip>
      ),
    },
    {
      width: '15%',
      dataIndex: 'STATUS_NAME',
      title: () => {
        return <span>Статус</span>;
      },
      key: '2',
    },
    {
      width: '15%',
      dataIndex: 'STAGE_NAME',
      title: () => {
        return <span>Стадия</span>;
      },
      key: '3',
    },
    {
      width: '15%',
      title: () => {
        return <span>Дата подписания</span>;
      },
      dataIndex: 'DATE_SIGN',
      key: '4',
    },
    {
      width: '15%',
      title: () => {
        return <span>Продукт</span>;
      },
      dataIndex: 'PRODUCT_NAME',
      key: '6',
    },
    {
      width: '20%',
      title: () => {
        return <span>Страхователь</span>;
      },
      dataIndex: 'CLIENT_NAME',
      key: '9',
    },
    {
      width: '15%',
      title: () => {
        return <span>Валюта</span>;
      },
      dataIndex: 'CURR_NAME',
      key: '7',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Divider />
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey="ID"
          locale={{
            emptyText: (
              <Empty description="Котировки не найдены с выбранными фильтрами" />
            ),
          }}
          className="c-table"
          scroll={{ x: 1000 }}
        />
      </Col>
    </Row>
  );
};

export default TableQuotation;
