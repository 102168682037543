import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Row, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
// import useQuotation from '@modules/Quotation/hooks/useQuotation';
const { Title } = Typography;

const ObjectQuotationItemDynamic = ({ props }) => {
  const { obj, openObjectModal, deleteObj, agrCond, item } = props;
  // const { stateDateQuotation } = useQuotation();
  const [objCond, setObjCond] = useState<any>();

  useEffect(() => {
    if (agrCond) {
      const carCond = agrCond.filter(
        (item) => Number(item.objisn) === Number(obj.isn)
      )[0];
      setObjCond(carCond);
    }
  }, [agrCond]);

  return (
    <Card className="quotation-object">
      <Row justify={'space-between'}>
        <Col>
          <div className="quotation-object__breadcrumb">{obj.obj_name}</div>
        </Col>
        <Col>
          <Button
            type="primary"
            className="mr-2"
            onClick={() => openObjectModal({ obj, item, step: 3 })}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => deleteObj(obj)}
            // disabled={checkDisabledField(stateDateQuotation.status_isn)}
          >
            <DeleteOutlined />
          </Button>
        </Col>
      </Row>
      <Title level={5} className="quotation-object__title">
        {obj?.objname}
      </Title>
      {/* <div className="quotation-object__description">{car?.reg_no}</div> */}
      <Row gutter={8}>
        <Col span={8} className="desc-text">
          <p>Страховая сумма</p>
          <p>{objCond?.limitsum}</p>
        </Col>
        <Col span={8} className="desc-text">
          <p>Тариф премии</p>
          <p>{objCond?.tariff}</p>
        </Col>
        <Col span={8} className="desc-text">
          <p>Страховая премия</p>
          <p>{objCond?.premiumsum}</p>
        </Col>
      </Row>
    </Card>
  );
};

export default ObjectQuotationItemDynamic;
