import RecruitingContext from '@contexts/RecruitingContext';
import useAuth from '@hooks/useAuth';
import useColleagues from '@hooks/useColleagues';
import React, { useEffect, useMemo } from 'react';
import { useImmerReducer } from 'use-immer';
import api from './services/api';

type RecruitingProviderProps = {
  children: React.ReactNode;
};

export interface IName_of_post_id {
  id: number;
  name: string;
}

export interface ISelectValues {
  name_of_post_id: IName_of_post_id[];
  reason_to_recruiting: IName_of_post_id[];
  desired_age: IName_of_post_id[];
  sex: IName_of_post_id[];
  education: IName_of_post_id[];
  work_experience: IName_of_post_id[];
  is_he_was_boss: IName_of_post_id[];
  type_of_hire: IName_of_post_id[];
  perspective_to_candidate: IName_of_post_id[];
  computer_knowing: IName_of_post_id[];
  motivation: IName_of_post_id[];
  job_chart: IName_of_post_id[];
  have_car: IName_of_post_id[];
  driver_category: IName_of_post_id[];
  social_packages: IName_of_post_id[];
  interview_result: IName_of_post_id[];
  security_service_result: IName_of_post_id[];
  languages: { type: IName_of_post_id[]; select: IName_of_post_id[] };
  application_status: IName_of_post_id[];
  message?: string;
}

export enum actionType {
  loading = 'loading',
  fetchSelectValues = 'fetch_select_values',
}

const reducer = (state: IinititalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionType.loading:
      state.loading = payload;
      break;
    case actionType.fetchSelectValues:
      state.selectValues = payload;
      break;
    default:
      throw new Error(`unknown action type: ${type}`);
  }
};

export interface IinititalState {
  loading: boolean;
  selectValues: ISelectValues;
}

const initialState: IinititalState = {
  loading: false,
  selectValues: {
    name_of_post_id: [],
    reason_to_recruiting: [],
    desired_age: [],
    sex: [],
    education: [],
    work_experience: [],
    is_he_was_boss: [],
    type_of_hire: [],
    perspective_to_candidate: [{ id: 0, name: '' }],
    computer_knowing: [],
    motivation: [],
    job_chart: [],
    have_car: [],
    driver_category: [],
    social_packages: [],
    security_service_result: [],
    languages: { type: [], select: [] },
    interview_result: [],
    application_status: [],
  },
};

function RecruitingProvider(props: RecruitingProviderProps) {
  const { colleaguesList, departmentList, isLoadingColleaguesList } =
    useColleagues();

  const [state, dispatch] = useImmerReducer<IinititalState>(
    reducer,
    initialState
  );

  const auth = useAuth();

  useEffect(() => {
    setSelectValuesMethod();
  }, []);

  const getSelectValuesMethod = async () => {
    const { data } = await api.getOptionValues(auth.user?.data?.company?.id);
    return data;
  };

  const setSelectValuesMethod = () => {
    getSelectValuesMethod().then((values) => {
      if (values) {
        dispatch({ type: actionType.fetchSelectValues, payload: values });
      }
    });
  };

  const contextValues = useMemo(
    () => ({
      colleaguesList,
      departmentList,
      isLoadingColleaguesList,
      state,
    }),
    [state, isLoadingColleaguesList]
  );

  return (
    <RecruitingContext.Provider value={contextValues}>
      {props.children}
    </RecruitingContext.Provider>
  );
}

export default RecruitingProvider;
