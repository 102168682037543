import { Card, Col, Form, Typography, Input } from 'antd';
import { useEffect } from 'react';
const { Title } = Typography;
const { TextArea } = Input;

interface Props {
  isDisabledForm: boolean;
  id: number;
  data: any;
  name: string;
  settings: any;
  isDisabledStatus: boolean;
}

function EdoRemark({
  settings,
  isDisabledForm,
  id,
  data = [],
  name,
  isDisabledStatus,
}: Props) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      if (data.remark && data.remark.length > 0) {
        form.setFieldsValue(data);
      }
      if (data.remark2 && data.remark2.length > 0) {
        form.setFieldsValue(data);
      }
    }
  }, [data, form]);

  return (
    <Card className="mt-5">
      <Title level={5} type="secondary">
        {settings.name}
      </Title>
      <Form
        name={name}
        layout="vertical"
        autoComplete="off"
        validateMessages={{ required: 'Обязательное поле' }}
        disabled={isDisabledForm}
        form={form}
      >
        <Col span="24">
          <Form.Item name={name} rules={[{ required: settings.required }]}>
            <TextArea
              readOnly={settings.readonly}
              rows={6}
              placeholder={
                settings.placeholder_show ? settings.placeholder : ''
              }
            />
          </Form.Item>
          <Form.Item hidden name={'document_id'} initialValue={id}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Form>
    </Card>
  );
}
export default EdoRemark;
