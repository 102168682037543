import { Button, Card, DatePicker, Typography, Select, Row, Col } from 'antd';
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';

const { RangePicker } = DatePicker;
const { Title } = Typography;

interface Props {
  title: string;
  selected: {
    first_date: dayjs.Dayjs;
    second_date: dayjs.Dayjs;
    dateType: string;
  };
  onUpdateDateRange: (dateRange) => void;
  onUpdateDateType: (dateType) => void;
  onFilter: () => void;
}

const DATA_TYPES = [
  { value: 'rise', label: 'С нарастанием' },
  { value: 'month', label: 'Месяц' },
  { value: 'quarter', label: 'Квартал' },
  { value: 'year', label: 'Год' },
];

const QUARTER_FORMAT = 'Q квартал YYYY';
const MONTH_FORMAT = 'MMMM YYYY';

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current > dayjs().endOf('year');
};

export default function ParseFilter(props: Props) {
  const { title, selected, onUpdateDateRange, onUpdateDateType, onFilter } =
    props;

  return (
    <Card className="mb-5">
      <Title type="secondary" level={5}>
        {title}
      </Title>
      <Row gutter={[15, 15]}>
        <Col>
          <Select
            options={DATA_TYPES}
            style={{ width: 160 }}
            value={selected.dateType}
            onChange={onUpdateDateType}
          />
        </Col>
        <Col>
          {selected.dateType === 'rise' && (
            <RangePicker
              picker="month"
              value={[dayjs(selected.first_date), dayjs(selected.second_date)]}
              onChange={onUpdateDateRange}
              format={MONTH_FORMAT}
              disabledDate={disabledDate}
            />
          )}
          {selected.dateType === 'month' && (
            <RangePicker
              picker="month"
              value={[dayjs(selected.first_date), dayjs(selected.second_date)]}
              onChange={onUpdateDateRange}
              format={MONTH_FORMAT}
              disabledDate={disabledDate}
            />
          )}
          {selected.dateType === 'quarter' && (
            <RangePicker
              picker="quarter"
              value={[dayjs(selected.first_date), dayjs(selected.second_date)]}
              onChange={onUpdateDateRange}
              format={QUARTER_FORMAT}
              disabledDate={disabledDate}
            />
          )}
          {selected.dateType === 'year' && (
            <RangePicker
              picker="year"
              value={[dayjs(selected.first_date), dayjs(selected.second_date)]}
              onChange={onUpdateDateRange}
              disabledDate={disabledDate}
            />
          )}
        </Col>
        <Col>
          <Button type="primary" onClick={onFilter}>
            Показать
          </Button>
        </Col>
      </Row>
    </Card>
  );
}
