import { TreeSelect } from 'antd';
import { HandbookListType, Handbook } from '../../hooks/useHandbook';
import { IRowColumnEditor } from '@modules/Edo/types';

const { TreeNode } = TreeSelect;

interface Props {
  handbookList: HandbookListType;
  disabled?: boolean;
  readonly?: boolean;
  value?: number;
  onChange?: (value: number | undefined) => void;
  settings?: IRowColumnEditor;
  add?: () => void;
  remove?: (index: number | number[]) => void;
  large?: boolean;
}

/**
 * TODO: Использовать во всех компонентах
 * @param handbookList список справочника для TreeSelect
 * @param disabled атрибут для TreeSelect
 * @param value необязательное начальное значение из родителя Form.Item
 * @param onChange колбек для получения значения из дочернего компонента для родителя Form.Item
 */

function renderTreeNodes(item: Handbook) {
  return (
    <TreeNode
      key={item.id}
      value={item.id}
      title={item.full_name}
      selectable={item.has_child === 0 || item.has_child === null}
      data={item}
    >
      {item.children.map((childrenItem) => renderTreeNodes(childrenItem))}
    </TreeNode>
  );
}

function TreeSelectHandbook({
  onChange,
  value,
  handbookList = [],
  disabled = false,
  readonly = false,
  settings,
  large,
}: Props) {

  const handleChange = (value) => {
    if (typeof onChange === 'function') {
      if (
        settings?.dicti &&
        settings.dicti[0].constant1 == 'cTypeofTransport'
      ) {
        const check = settings.dicti[0].children.filter(
          (item) => item.constant1 == 'ucTypeCar'
        )[0];
        if (check.id == value) {
        } else {
        }
      }
      onChange(value);

      // const newValue = parseInt(value);

      // if (!isNaN(newValue)) {
      //   onChange(value);
      // } else {
      //   // Сбросим значение
      //   onChange(undefined);
      // }
    }
  };

  return (
    <TreeSelect
      data-testid="tree-select"
      onChange={(value) => handleChange(value)}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeLine={{ showLeafIcon: true }}
      treeNodeFilterProp="title"
      disabled={disabled || readonly}
      allowClear={true}
      showSearch={true}
      value={value}
      size={large ? 'large' : 'middle'}
    >
      {handbookList.map((item) => renderTreeNodes(item))}
    </TreeSelect>
  );
}

export default TreeSelectHandbook;
