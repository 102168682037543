import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Input, Row, Typography, notification } from 'antd';
import { TypeAction } from '@modules/Quotation/providers/DateReducer';
import dayjs from 'dayjs';
import CDatePicker from '@components/UI/datepicker/CDatePicker';
import CSelect from '@components/UI/select/CSelect';
import '@pages/Quotation/Quotation.scss';
import api from '@modules/Quotation/services';
import axios from 'axios';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const MainContractFormular = ({ fields, setStatus }) => {
  const [form] = Form.useForm();
  const [statusList, setStatusList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { TextArea } = Input;
  const { Title } = Typography;
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.contractor.getContractStatus();
        setStatusList(data.data);
      } catch (error) {
        let message;
      }
    })();
  }, []);
  const onChangeStatus = async (val) => {
    try {
      setIsLoading(true);
      let status = statusList.filter((item) => item.NAME === val)[0];
      let { data } = await api.contractor.updateContractStatus({
        doc_isn: fields[0].isn,
        status: status.STATUS,
      });
      if (data.success) {
        notification.success({
          message: 'Данные успешно сохранены',
          description: 'Статус договора изменен',
          duration: 5,
        });
        setStatus(val);
      }
      setIsLoading(false);
    } catch (error) {
      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      let indexOfStatus;
      statusList.map((item, index) => {
        if (item.NAME === val) {
          indexOfStatus = index;
        }
      });
      if (indexOfStatus >= 1) {
        form.setFieldValue('STATUS_ISN', statusList[indexOfStatus - 1].NAME);
      }
      setIsLoading(false);
      notification.info({
        message: 'Ошибка',
        description: message,
        duration: 5,
      });
    }
  };
  return (
    <Form className="c-form" layout="vertical" form={form}>
      <CustomLoader spinning={isLoading}>
        <Card className="mt-5">
          <Title className="main-contract__section-title" level={3}>
            Формуляр
          </Title>
          <Row gutter={18}>
            <Col xs={24} md={8}>
              <Form.Item
                name={TypeAction.DATE_SIGN}
                label="Дата подписания"
                initialValue={dayjs(fields[0].datesign)}
              >
                <CDatePicker
                  format="DD.MM.YYYY"
                  className="w-100"
                  size="large"
                  placeholder="Дата подписания"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name={TypeAction.DATE_BEG}
                label="Дата Начала"
                initialValue={dayjs(fields[0].datebeg)}
              >
                <CDatePicker
                  className="w-100"
                  format="DD.MM.YYYY"
                  size="large"
                  placeholder="Дата начала"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name={TypeAction.DATE_END}
                label="Дата окончания"
                initialValue={dayjs(fields[0].dateend)}
              >
                <CDatePicker
                  format="DD.MM.YYYY"
                  className="w-100"
                  size="large"
                  placeholder="Дата окончания"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={8} xs={24} md={12}>
              <Form.Item
                name={TypeAction.STATUS_ISN}
                label="Статус"
                initialValue={fields[0].status_name}
              >
                <CSelect
                  placeholder="Статус"
                  size="large"
                  onChange={onChangeStatus}
                  options={statusList?.map((item) => {
                    return {
                      label: item.NAME,
                      value: item.NAME,
                    };
                  })}
                  disabled={fields[0].status_name.toLowerCase === 'выпущен'}
                />
              </Form.Item>
            </Col>
            <Col span={8} xs={24} md={12}>
              <Form.Item
                name={TypeAction.CURRENCY}
                label="Валюта"
                initialValue={fields[0].currisn_name}
              >
                <CSelect placeholder="Валюта" size="large" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={18} style={{ marginBottom: -20 }}>
            <Col span={24}>
              <Form.Item
                name={TypeAction.REMARK}
                label="Особое условие"
                initialValue={fields[0].remark}
              >
                <TextArea
                  rows={4}
                  placeholder="Особое условие"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </CustomLoader>
    </Form>
  );
};

export default MainContractFormular;
