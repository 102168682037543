import { Button, Col, Dropdown, Row, Tooltip, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { MenuProps } from 'antd/es/menu';
import './FolderItem.scss';
import useUseful from '@hooks/useUseful';
import useAuth from '@hooks/useAuth';
import { getAccessUseful } from '@modules/UsefulSection/helpers/getAccessUseful';


interface IPropsFolderItem {
  title: string;
  onClickEvent: () => void;
  deleteCategory: (e) => void;
  getDeleteCategoryId: () => void;
  editCategory: (e) => void;
  getEditCategoryId: () => void;
  getInfoCategoryId: () => void;
  showInfo: () => void;
}

const { Text } = Typography;


const FolderItem = ({
                      title,
                      onClickEvent,
                      deleteCategory,
                      getDeleteCategoryId,
                      getEditCategoryId,
                      editCategory,
                      getInfoCategoryId,
                      showInfo
                    }: IPropsFolderItem) => {
  const { setIsDocument } = useUseful();
  const { user } = useAuth();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          className="d-flex align-center"
          onClick={(e) => {
            getEditCategoryId()
            editCategory(e)
          }}
        >
          <i className="edit-dropdown-useful"/>
          <Text className="ml-1">Переименовать</Text>
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          className="d-flex align-center"
          onClick={(e) => {
            e.stopPropagation();
            setIsDocument(false);
            getInfoCategoryId();
            showInfo();
          }}
        >
          <i className="info-dropdown-useful"/>
          <Text className="ml-1">Сведения</Text>
        </a>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <a
          className="d-flex align-center"
          onClick={(e) => {
            getDeleteCategoryId()
            setIsDocument(false);
            deleteCategory(e)
          }}
        >
          <i className="delete-dropdown-useful"/>
          <Text className="ml-1">Удалить</Text>
        </a>
      ),
    },
  ];

  return (
    <Col xs={24} lg={6}>
      <Row className="item-folder-useful dropdown-index" onClick={onClickEvent}>
        <Row className="d-flex justify-space-between width">
          <i className="main-folder-icon-useful"/>
          {getAccessUseful('update', user) && getAccessUseful('delete', user) &&
              <Dropdown menu={{ items }} placement="bottom">
                  <Button className="dropdown-useful" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}>
                      &middot;&middot;&middot;
                  </Button>
              </Dropdown>}
        </Row>
        <Row className="d-flex justify-space-between width">
          <Tooltip title={title} placement="bottom">
            <Title level={5} className="title-folder-useful">{title}</Title>
          </Tooltip>
        </Row>
      </Row>
    </Col>
  )
};

export default FolderItem;