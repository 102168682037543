import React, { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Empty, InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { useNavigate } from 'react-router-dom';
import useRecruiting from '../hooks/useRecruiting';
import { IFetchInterview } from './interface';

type DataIndex = keyof DataType;

interface DataType {
  candidate_fullname: string;
  id: number;
  department_to: string;
  recruiting_id: number;
  interview_date: null | string;
  interview_result: null | string;
}

type InterviewTableProps = {
  fetchInterview: IFetchInterview[];
};

function InterviewTable({ fetchInterview }: InterviewTableProps) {
  const navigate = useNavigate();

  const searchInput = useRef<InputRef>(null);

  const navigateToCandidate = (value: number) => {
    navigate(`/recruiting/candidate/${value}`);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                handleReset(clearFilters);
                handleSearch(selectedKeys as string[], confirm, dataIndex);
              }
            }}
            size="small"
            style={{ width: 90 }}
          >
            Очистить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex == 'id') {
        // @ts-ignore
        const data = fetchInterview.filter((item, index) => index + 1 == value);

        return record[dataIndex]
          .toString()
          .includes((value as string).toLowerCase());
      } else {
        // @ts-ignore
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columnsInterview: ColumnsType<DataType> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      ...getColumnSearchProps('id'),
      render: (value, data, index) => {
        return (
          <a
            onClick={() => {
              navigateToCandidate(value);
            }}
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'ФИО кандидата',
      dataIndex: 'candidate_fullname',
      key: 'candidate_fullname',
      width: '20%',
      ...getColumnSearchProps('candidate_fullname'),
      render: (value, record) => {
        return (
          <a
            onClick={() => {
              navigateToCandidate(record.id);
            }}
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Структурное подразделение',
      dataIndex: 'department_to',
      key: 'department_to',
      width: '30%',
      ...getColumnSearchProps('department_to'),
      render: (value, record) => {
        return (
          <a
            onClick={() => {
              navigateToCandidate(record.id);
            }}
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Дата собеседования',
      dataIndex: 'interview_date',
      key: 'interview_date',
      width: '15%',
      render: (value, record) => {
        return (
          <a
            onClick={() => {
              navigateToCandidate(record.id);
            }}
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Результат итогового интервью',
      dataIndex: 'interview_result',
      key: 'interview_result',
      width: '15%',
      render: (value, record) => {
        return (
          <a
            onClick={() => {
              navigateToCandidate(record.id);
            }}
          >
            {value}
          </a>
        );
      },
    },
  ];

  return (
    <Table
      columns={columnsInterview}
      dataSource={fetchInterview}
      locale={{
        emptyText: <Empty description="У вас нет доступа к просмотру" />,
      }}
      rowKey="id"
      scroll={{ x: 1000 }}
      style={{ fontWeight: 'initial' }}
    />
  );
}

export default InterviewTable;
