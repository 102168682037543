const LibraryData = {
    InternalRegulatoryDocuments: {
        name: 'Внутренние нормативные документы',
        category: [
            {
                id: 1,
                name: 'Актуарные расчёты',
                documents: [
                    {
                        id: 1,
                        name: 'П-15 Тарифная политика',
                        path: require('./documents/InternalRegulatoryDocuments/П-15 Тарифная политика.docx'),
                    },
                    {
                        id: 2,
                        name: 'И-22 Внутренние процедуры по сбору, обработке и анализу статистических данных, необходимых для расчета страховых резервов',
                        path: require('./documents/InternalRegulatoryDocuments/И-22 Внутренние процедуры по сбору, обработке и анализу статистических данных, необходимых для расчета страховых резервов.docx'),
                    },
                    {
                        id: 3,
                        name: 'П-36 Методика определения крупных убытков',
                        path: require('./documents/InternalRegulatoryDocuments/П-36 Методика определения крупных убытков.docx'),
                    },
                    {
                        id: 4,
                        name: 'П-10 Политика по формированию страховых резервов резерва непредвиденных рисков и стабилизационного резерва',
                        path: require('./documents/InternalRegulatoryDocuments/П-10 Политика по формированию страховых резервов резерва непредвиденных рисков и стабилизационного резерва.docx'),
                    },
                    {
                        id: 5,
                        name: 'П-24 Методика оценки страховых обязательств',
                        path: require('./documents/InternalRegulatoryDocuments/П-24 Методика оценки страховых обязательств.docx'),
                    }
                ]
            },
            {
                id: 2,
                name: 'Административно-хозяйственная деятельность',
                documents: [
                    {
                        id: 1,
                        name: 'И-13 Регламент работы водителей Головного офиса',
                        path: require('./documents/InternalRegulatoryDocuments/И-13 Регламент работы водителей Головного офиса.doc'),
                    },
                    {
                        id: 2,
                        name: 'И-14 Инструкция по проведению инструктажей по охране труда и технике безопасности',
                        path: require('./documents/InternalRegulatoryDocuments/И-14 Инструкция по проведению инструктажей по охране труда и технике безопасности.docx'),
                    },
                    {
                        id: 3,
                        name: 'И-15 Инструкция по охране труда при работе на ПК 10.08.2015',
                        path: require('./documents/InternalRegulatoryDocuments/И-15 Инструкция по охране труда при работе на ПК 10.08.2015.docx'),
                    },
                    {
                        id: 4,
                        name: 'И-16 Инструкция по обеспечению пожарной безопасности 10.08.2015',
                        path: require('./documents/InternalRegulatoryDocuments/И-16 Инструкция по обеспечению пожарной безопасности 10.08.2015.docx'),
                    },
                    {
                        id: 5,
                        name: 'Форма №1 к И-14 Журнал учета проведения инструкатажа по ПБ 10.08.2015',
                        path: require('./documents/InternalRegulatoryDocuments/Форма №1 к И-14 Журнал учета проведения инструкатажа по ПБ 10.08.2015.docx'),
                    },
                    {
                        id: 6,
                        name: 'Форма №2 к И-16 Контрольный лист проведения инструктажей по ПБ 10.08.2015',
                        path: require('./documents/InternalRegulatoryDocuments/Форма №2 к И-16 Контрольный лист проведения инструктажей по ПБ 10.08.2015.docx'),
                    },
                    {
                        id: 7,
                        name: 'И-17 Инструкция по заключению учету хранению и исполнению административно-хозяйственных договоров',
                        path: require('./documents/InternalRegulatoryDocuments/И-17 Инструкция по заключению учету хранению и исполнению административно-хозяйственных договоров.docx'),
                    },
                ]
            },
            {
                id: 3,
                name: 'Разработка ВНД, делопроизводство и архив',
                documents: [
                    {
                        id: 1,
                        name: 'И-09 Инструкция по управлению нормативной документацией',
                        path: require('./documents/InternalRegulatoryDocuments/И-09 Инструкция по управлению нормативной документацией.docx'),
                    },
                    {
                        id: 2,
                        name: 'П-38 Положение о Частном архиве',
                        path: require('./documents/InternalRegulatoryDocuments/П-38 Положение о Частном архиве.doc'),
                    },
                    {
                        id: 3,
                        name: 'И-34 Инструкция о порядке изготовления, учета, использования, хранения и уничтожения печатей штампов бланков',
                        path: require('./documents/InternalRegulatoryDocuments/И-34 Инструкция о порядке изготовления, учета, использования, хранения и уничтожения печатей штампов бланков.pdf'),
                    },
                    {
                        id: 4,
                        name: 'П-11 Правила документирования и управления документацией 2023г.',
                        path: require('./documents/InternalRegulatoryDocuments/П-11 Правила документирования и управления документацией 2023г..doc'),
                    },
                    {
                        id: 5,
                        name: 'Номенклатура дел',
                        path: require('./documents/InternalRegulatoryDocuments/Номенклатура дел.docx'),
                    },
                    {
                        id: 5,
                        name: 'И-35 Инструкция о порядке приема, отправки и получения документации',
                        path: require('./documents/InternalRegulatoryDocuments/И-35 Инструкция о порядке приема, отправки и получения документации.docx'),
                    }
                ]
            },
            {
                id: 4,
                name: 'Заключение договоров',
                documents: [
                    {
                        id: 1,
                        name: 'Лимиты на подписание договоров страхования 2021',
                        path: require('./documents/InternalRegulatoryDocuments/Лимиты на подписание договоров страхования 2021.xlsx'),
                    },
                    {
                        id: 2,
                        name: 'П-06 Правила согласования и заключения сделок с особыми условиями',
                        path: require('./documents/InternalRegulatoryDocuments/П-06 Правила согласования и заключения сделок с особыми условиями.docx'),
                    },
                    {
                        id: 3,
                        name: 'И-29 Инструкция выдачи и учета доверенностей',
                        path: require('./documents/InternalRegulatoryDocuments/И-29 Инструкция выдачи и учета доверенностей_'),
                    },
                ]
            },
            {
                id: 5,
                name: 'Информационная безопасность',
                documents: [
                    {
                        id: 1,
                        name: 'И-04 ИНСТРУКЦИЯ О РЕЗЕРВНОМ КОПИРОВАНИИ ИНФОРМАЦИИ',
                        path: require('./documents/InternalRegulatoryDocuments/И-04 ИНСТРУКЦИЯ О РЕЗЕРВНОМ КОПИРОВАНИИ ИНФОРМАЦИИ.docx'),
                    },
                    {
                        id: 2,
                        name: 'И-30 РЕГЛАМЕНТ ПО АВТОМАТИЗАЦИИ БУХГАЛТЕРСКОГО УЧЕТА',
                        path: require('./documents/InternalRegulatoryDocuments/И-30 Регламент по автоматизации ведения бухгалтерского учета.docx'),
                    },
                    {
                        id: 3,
                        name: 'И–31 Матрица доступов в КИАС',
                        path: require('./documents/InternalRegulatoryDocuments/И-31 Матрица доступов в КИАС.docx'),
                    },
                    {
                        id: 4,
                        name: 'И–32 Порядок обработки инцидентов информационной безопасности',
                        path: require('./documents/InternalRegulatoryDocuments/И-32 Порядок обработки инцидентов информационной безопасности 2019.docx'),
                    },
                    {
                        id: 5,
                        name: 'И-21 Процедура по предотвращению сбоев с помощью регулярных инспекций оборудования и проверке отчетов о работе',
                        path: require('./documents/InternalRegulatoryDocuments/И-21 Процедура по предотвращению сбоев с помощью регулярных инспекций оборудования и проверке отчетов о работе.docx'),
                    },
                    {
                        id: 6,
                        name: 'И-02 Порядок пользования ресурсами локальной вычислительной сети',
                        path: require('./documents/InternalRegulatoryDocuments/И-02 Порядок пользования ресурсами локальной вычислительной сети.docx'),
                    },
                    {
                        id: 7,
                        name: 'И-03 Процедура по предотвращению утечки конфиденциальной информации и искажению информационных данных',
                        path: require('./documents/InternalRegulatoryDocuments/И-03 Процедура по предотвращению утечки конфиденциальной информации и искажению информационных данных.docx'),
                    },
                    {
                        id: 8,
                        name: 'П-08 Политика информационной безопасности',
                        path: require('./documents/InternalRegulatoryDocuments/П-08 Политика информационной безопасности.docx'),
                    },
                    {
                        id: 9,
                        name: 'И-33 Порядок обеспечения физической безопасности информационных активов Общества',
                        path: require('./documents/InternalRegulatoryDocuments/И-33 Порядок обеспечения физической безопасности информационных активов Общества.docx'),
                    },
                    {
                        id: 10,
                        name: 'И-28 Инструкция по работе в ЕСБД',
                        path: require('./documents/InternalRegulatoryDocuments/И-28 Инструкция по работе в ЕСБД.docx'),
                    },
                ]
            },
            {
                id: 6,
                name: 'Комплаенс',
                documents: [
                    {
                        id: 1,
                        name: 'П-04 Правила внутреннего контроля за распоряжением и использованием инсайдерской информации',
                        path: require('./documents/InternalRegulatoryDocuments/П-04 Правила внутреннего контроля за распоряжением и использованием инсайдерской информации.docx'),
                    },
                    {
                        id: 2,
                        name: 'П-06 Политика по обработке персональных данных субъектов',
                        path: require('./documents/InternalRegulatoryDocuments/П-06 Политика по обработке персональных данных субъектов.docx'),
                    },
                    {
                        id: 3,
                        name: 'П-35 Положение о порядке рассмотрения жалоб обращений и урегулирования споров',
                        path: require('./documents/InternalRegulatoryDocuments/П-35 Положение о порядке рассмотрения жалоб обращений и урегулирования споров.docx'),
                    },
                    {
                        id: 4,
                        name: 'П-16 Политика по управлению комплаенс-рисками',
                        path: require('./documents/InternalRegulatoryDocuments/П-16 Политика по управлению комплаенс-рисками.docx'),
                    },
                    {
                        id: 5,
                        name: 'П-02 Правила внутреннего контроля по ПОДФТ 2023',
                        path: require('./documents/InternalRegulatoryDocuments/П-02 Правила внутреннего контроля по ПОДФТ 2023.docx'),
                    },

                    {
                        id: 6,
                        name: 'Двойное страхование 26',
                        path: require('./documents/InternalRegulatoryDocuments/Двойное страхование 26.pdf'),
                    },
                    {
                        id: 7,
                        name: 'Перестрахование придание законности',
                        path: require('./documents/InternalRegulatoryDocuments/Перестрахование придание законности.pdf'),
                    },
                    {
                        id: 8,
                        name: 'Презентация по ПОДФТ 2023 08 декабря',
                        path: require('./documents/InternalRegulatoryDocuments/Презентация по ПОДФТ 2023 08 декабря.pptx'),
                    },
                    {
                        id: 9,
                        name: 'Страховые способы и схемы отмывания',
                        path: require('./documents/InternalRegulatoryDocuments/Страховые способы и схемы отмывания.pdf'),
                    },
                    {
                        id: 10,
                        name: 'Фиктивные страховые выплаты',
                        path: require('./documents/InternalRegulatoryDocuments/Фиктивные страховые выплаты.pdf'),
                    },
                    {
                        id: 11,
                        name: 'Финансирование терроризма',
                        path: require('./documents/InternalRegulatoryDocuments/Финансирование терроризма.pdf'),
                    }
                ]
            },
            {
                id: 7,
                name: 'Корпоративное управление',
                documents: [
                    {
                        id: 1,
                        name: 'П - 23 Положение об организационно-функциональной структуре управления',
                        path: require('./documents/InternalRegulatoryDocuments/П - 23 Положение об организационно-функциональной структуре управления.docx'),
                    },
                    {
                        id: 2,
                        name: 'К-01 Кодекс корпоративного управления',
                        path: require('./documents/InternalRegulatoryDocuments/К-01 Кодекс корпоративного управления.docx'),
                    },
                    {
                        id: 3,
                        name: 'П-21 План на случай чрезвычайных обстоятельств',
                        path: require('./documents/InternalRegulatoryDocuments/П-21 План на случай чрезвычайных обстоятельств.pdf'),
                    },
                    {
                        id: 4,
                        name: 'И-26 Процедура создания (открытия), закрытия и перерегистрации филиалов, внесения изменений в Положение о филиале и уведомления государственных органов',
                        path: require('./documents/InternalRegulatoryDocuments/И-26 Процедура создания (открытия), закрытия и перерегистрации филиалов.docx'),
                    },
                    {
                        id: 5,
                        name: 'И - 27 Порядок передачи информации между подразделениями, комитетами, советами, Правлением и Советом директоров',
                        path: require('./documents/InternalRegulatoryDocuments/И - 27 Порядок передачи информации между подразделениями, комитетами, советами, Правлением и Советом директоров.doc'),
                    },
                    {
                        id: 6,
                        name: 'ПП-11 Кодекс корпоративной этики',
                        path: require('./documents/InternalRegulatoryDocuments/ПП-11 Кодекс корпоративной этики.docx'),
                    },
                    {
                        id: 7,
                        name: 'П-17 Положение о коммерческой тайне',
                        path: require('./documents/InternalRegulatoryDocuments/П-17 Положение о коммерческой тайне.docx'),
                    },
                    {
                        id: 8,
                        name: 'П-35 Положение о порядке рассмотрения жалоб обращений и урегулирования споров',
                        path: require('./documents/InternalRegulatoryDocuments/П-35 Положение о порядке рассмотрения жалоб обращений и урегулирования споров.docx'),
                    },
                    {
                        id: 9,
                        name: 'П-25 Внутренняя Политика по назначениям и вознаграждениям руководящих работников',
                        path: require('./documents/InternalRegulatoryDocuments/П-25 Внутренняя Политика по назначениям и вознаграждениям руководящих работников.docx'),
                    },
                    {
                        id: 10,
                        name: 'Методика распределения Клиентов между ПП',
                        path: require('./documents/InternalRegulatoryDocuments/Методика распределения Клиентов между ПП.pdf'),
                    },
                    {
                        id: 11,
                        name: 'Корпоративная стратегия на 2022-2024',
                        path: require('./documents/InternalRegulatoryDocuments/Корпоративная стратегия на 2022-2024.docx'),
                    },
                ]
            },
            {
                id: 8,
                name: 'Маркетинг',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 9,
                name: 'Мотивация',
                documents: [
                    {
                        id: 1,
                        name: 'П-37 Правила корпоративной мотивационной программы',
                        path: require('./documents/InternalRegulatoryDocuments/П-37 Правила корпоративной мотивационной программы.docx'),
                    },
                    {
                        id: 2,
                        name: 'П-05 Положение о мотивации работников без Приложений',
                        path: require('./documents/InternalRegulatoryDocuments/П-05 Положение о мотивации работников без Приложений.docx'),
                    },
                ]
            },
            {
                id: 10,
                name: 'Риск менеджмент',
                documents: [
                    {
                        id: 1,
                        name: 'П-32 Политика по управлению рисками',
                        path: require('./documents/InternalRegulatoryDocuments/П-32 Политика по управлению рисками.pdf'),
                    },
                    {
                        id: 2,
                        name: 'П-34 Политика по управлению операционными, сопутствующими рисками',
                        path: require('./documents/InternalRegulatoryDocuments/П-34 Политика по управлению операционными, сопутствующими рисками.docx'),
                    },
                ]
            },
            {
                id: 11,
                name: 'Управление персоналом',
                documents: [
                    {
                        id: 1,
                        name: 'И-05 Инструкция по управлению Положениями о подразделениях и Должностными инструкциями',
                        path: require('./documents/InternalRegulatoryDocuments/И-05 Инструкция по управлению Положениями о подразделениях и Должностными инструкциями.doc'),
                    },
                    {
                        id: 2,
                        name: 'П-39 Положение об учёте военнообязанных и призывников, работающих в АО «СК «Сентрас Иншуранс»',
                        path: require('./documents/InternalRegulatoryDocuments/П-39 Положение об учёте военнообязанных и призывников, работающих в АО «СК «Сентрас Иншуранс».docx'),
                    },
                    {
                        id: 3,
                        name: 'П-30 Положение о служебных командировках работников',
                        path: require('./documents/InternalRegulatoryDocuments/П-30 Положение о служебных командировках работников.doc'),
                    },
                    {
                        id: 4,
                        name: 'П-14 Положение о персональных данных работников',
                        path: require('./documents/InternalRegulatoryDocuments/П-14 Положение о персональных данных работников.doc'),
                    },
                    {
                        id: 5,
                        name: 'П-19 Правила трудового распорядка',
                        path: require('./documents/InternalRegulatoryDocuments/П-19 Правила трудового распорядка.doc'),
                    },
                    {
                        id: 6,
                        name: 'П-40 Правила по организации подбора и отбора персонала АО «СК «Сентрас Иншуранс»',
                        path: require('./documents/InternalRegulatoryDocuments/П-40 Правила по организации подбора и отбора персонала АО «СК «Сентрас Иншуранс».docx'),
                    },
                    {
                        id: 7,
                        name: 'П-09 Правила организации обучения работников',
                        path: require('./documents/InternalRegulatoryDocuments/П-09 Правила организации обучения работников.docx'),
                    },
                    {
                        id: 8,
                        name: 'ПП-02 Положение о Согласительной Комиссии',
                        path: require('./documents/InternalRegulatoryDocuments/ПП-02 Положение о Согласительной Комиссии.doc'),
                    },
                    {
                        id: 9,
                        name: 'П-18 Положение об оплате труда и материального стимулирования работников',
                        path: require('./documents/InternalRegulatoryDocuments/П-18 Положение об оплате труда и материального стимулирования работников.doc'),
                    },
                    {
                        id: 10,
                        name: 'П-43 Правила прохождения практики студентов ВУЗ',
                        path: require('./documents/InternalRegulatoryDocuments/П-43 Правила прохождения практики студентов ВУЗ.docx'),
                    },
                    {
                        id: 11,
                        name: 'И-01 Порядок определения комиссионного вознаграждения страхового агента',
                        path: require('./documents/InternalRegulatoryDocuments/И-01 Порядок определения комиссионного вознаграждения страхового агента.docx'),
                    },
                    {
                        id: 12,
                        name: 'П-20 Внутренняя Политика по управлению деятельностью страховых агентов',
                        path: require('./documents/InternalRegulatoryDocuments/П-20 Внутренняя Политика по управлению деятельностью страховых агентов.docx'),
                    },
                ]
            },
            {
                id: 12,
                name: 'Управленческая отчетность',
                documents: [
                    {
                        id: 1,
                        name: 'П - 22 Положение о сроках и формах предоставления управленческой отчетности',
                        path: require('./documents/InternalRegulatoryDocuments/П - 22 Положение о сроках и формах предоставления управленческой отчетности.docx'),
                    },
                ]
            },
            {
                id: 13,
                name: 'Урегулирование убытков',
                documents: [
                    {
                        id: 1,
                        name: 'П-07 Политика урегулирования убытков',
                        path: require('./documents/InternalRegulatoryDocuments/П-07 Политика урегулирования убытков.docx'),
                    },
                    {
                        id: 2,
                        name: 'Лимиты принятия решений по страховым выплатам 2021',
                        path: require('./documents/InternalRegulatoryDocuments/Лимиты принятия решений по страховым выплатам 2021.pdf'),
                    },
                    {
                        id: 3,
                        name: 'инструкция о порядке проведения осмотра и оценке транспортных средств перед заключением договора добровольного страхования автомобильного транспорта',
                        path: require('./documents/InternalRegulatoryDocuments/инструкция о порядке проведения осмотра и оценке транспортных средств.pdf'),
                    },
                    {
                        id: 4,
                        name: 'И-12 Инструкция о порядке проведения осмотра грузов - 10.02.2015',
                        path: require('./documents/InternalRegulatoryDocuments/И-12 Инструкция о порядке проведения осмотра грузов - 10.02.2015.docx'),
                    },
                    {
                        id: 5,
                        name: 'Форма №1 к И-12 Акт осмотра грузов при погрузке - 10.02.2015',
                        path: require('./documents/InternalRegulatoryDocuments/Форма №1 к И-12 Акт осмотра грузов при погрузке - 10.02.2015.docx'),
                    },
                    {
                        id: 6,
                        name: 'Форма №2 к И-12 Акт осмотра грузов при разгрузке - 10.02.2015',
                        path: require('./documents/InternalRegulatoryDocuments/Форма №2 к И-12 Акт осмотра грузов при разгрузке - 10.02.2015.docx'),
                    },
                ]
            },
            {
                id: 14,
                name: 'Финансы и учет',
                documents: [
                    {
                        id: 1,
                        name: 'П-26 Налоговая учетная политика',
                        path: require('./documents/InternalRegulatoryDocuments/П-26 Налоговая учетная политика.docx'),
                    },
                    {
                        id: 2,
                        name: 'Регламент по финансовому планированию денежных потоков',
                        path: require('./documents/InternalRegulatoryDocuments/Регламент по финансовому планированию денежных потоков.docx'),
                    },
                    {
                        id: 3,
                        name: 'Политика по управлению активными и пассивными операциями',
                        path: require('./documents/InternalRegulatoryDocuments/Политика по управлению активными и пассивными операциями.docx'),
                    },
                    {
                        id: 4,
                        name: 'П-29 Инвестиционная политика',
                        path: require('./documents/InternalRegulatoryDocuments/П-29 Инвестиционная политика.docx'),
                    },
                    {
                        id: 5,
                        name: 'П-28 Положение о бюджетировании',
                        path: require('./documents/InternalRegulatoryDocuments/П-28 Положение о бюджетировании.docx'),
                    },
                    {
                        id: 6,
                        name: 'И-06 Инструкция по учету Абандона',
                        path: require('./documents/InternalRegulatoryDocuments/И-06 Инструкция по учету Абандона.docx'),
                    },
                    {
                        id: 7,
                        name: 'И-07 Инструкция по учету основных средств',
                        path: require('./documents/InternalRegulatoryDocuments/И-07 Инструкция по учету основных средств.doc'),
                    },
                    {
                        id: 8,
                        name: 'И-08 Инструкция по учету расчетов с подотчетными лицами',
                        path: require('./documents/InternalRegulatoryDocuments/И-08 Инструкция по учету расчетов с подотчетными лицами.doc'),
                    },
                    {
                        id: 9,
                        name: 'П-03 Правила организации работ по дебиторской задолженности по договорам страхования (перестрахования)',
                        path: require('./documents/InternalRegulatoryDocuments/П-03 Правила организации работ по дебиторской задолженности по договорам страхования (перестрахования).docx'),
                    },
                    {
                        id: 10,
                        name: 'И-19 инструкция по ведению кассовых операций',
                        path: require('./documents/InternalRegulatoryDocuments/И-19 инструкция по ведению кассовых операций.docx'),
                    },
                    {
                        id: 11,
                        name: 'И-11 Инструкция по учету и списанию договоров страхования (перестрахования)',
                        path: require('./documents/InternalRegulatoryDocuments/И-11 Инструкция по учету и списанию договоров страхования (перестрахования).docx'),
                    },
                    {
                        id: 12,
                        name: 'Учетная политика',
                        path: require('./documents/InternalRegulatoryDocuments/Учетная политика.docx'),
                    },
                ]
            },
            {
                id: 15,
                name: 'Юридические вопросы',
                documents: [
                    {
                        id: 1,
                        name: 'Инструкция по размещению информации на интернет-ресурсе депозитария финансовой отчетности АО «СК «Сентрас Иншуранс»',
                        path: require('./documents/InternalRegulatoryDocuments/Инструкция по размещению информации на интернет-ресурсе депозитария финансовой отчетности АО «СК «Сентрас Иншуранс».docx'),
                    },
                    {
                        id: 2,
                        name: 'И-26 Процедура открытия, закрытия, перерегистр. филиалов',
                        path: require('./documents/InternalRegulatoryDocuments/И-26 Процедура открытия, закрытия, перерегистр. филиалов.docx'),
                    },
                    {
                        id: 3,
                        name: 'Регламент взаимодействия юридического департамента со структурными подразделениями Общества',
                        path: require('./documents/InternalRegulatoryDocuments/Регламент взаимодействия юридического департамента со структурными подразделениями Общества.pdf'),
                    },
                    {
                        id: 4,
                        name: 'Положение о выкупе размещенных акций общества методика определения цены выкупа акций',
                        path: require('./documents/InternalRegulatoryDocuments/Положение о выкупе размещенных акций общества методика определения цены выкупа акций.doc'),
                    },
                ]
            },
            {
                id: 16,
                name: 'Для Правления',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 17,
                name: 'Внутрений Аудит',
                documents: [
                    {
                        id: 1,
                        name: 'П-13 Правила внутреннего контроля и внутреннего аудита формирования страховых резервов',
                        path: require('./documents/InternalRegulatoryDocuments/П-13 Правила внутреннего контроля и внутреннего аудита формирования страховых резервов.doc'),
                    },
                    {
                        id: 2,
                        name: 'П-31 Правила проведения внутреннего аудита в структурных подразделениях',
                        path: require('./documents/InternalRegulatoryDocuments/П-31 Правила проведения внутреннего аудита в структурных подразделениях.doc'),
                    },
                    {
                        id: 3,
                        name: 'П-41 Методика оценки системы управления рисками внутреннего контроля и корпоративного управления',
                        path: require('./documents/InternalRegulatoryDocuments/1674818544_'),
                    },
                    {
                        id: 4,
                        name: 'П-33 Политика внутреннего аудита',
                        path: require('./documents/InternalRegulatoryDocuments/П-33 Политика внутреннего аудита.docx'),
                    },
                ]
            },
            {
                id: 18,
                name: 'Андеррайтинг и перестрахование',
                documents: [
                    {
                        id: 1,
                        name: 'П-12 Андеррайтинговая политика',
                        path: require('./documents/InternalRegulatoryDocuments/П-12 Андеррайтинговая политика.doc'),
                    },
                    {
                        id: 2,
                        name: 'П-21 Политика по перестрахованию',
                        path: require('./documents/InternalRegulatoryDocuments/П-21 Политика по перестрахованию.docx'),
                    },
                    {
                        id: 3,
                        name: 'Лимиты договоров исходящего и входящего перестрахования (по всем классам (видам) страхования)',
                        path: require('./documents/InternalRegulatoryDocuments/Лимиты договоров исходящего и входящего перестрахования (по всем классам (видам) страхования).xlsx'),
                    },
                    {
                        id: 4,
                        name: 'Лимиты на подписание договоров страхования 2022',
                        path: require('./documents/InternalRegulatoryDocuments/Лимиты на подписание договоров страхования 2022.xlsx'),
                    },
                    {
                        id: 5,
                        name: 'Лимиты на принятие решения о заключении сделки СД 2024',
                        path: require('./documents/InternalRegulatoryDocuments/Лимиты на принятие решения о заключении сделки СД 2024_copy1.xlsx'),
                    },
                ]
            },
            {
                id: 19,
                name: 'Обучение',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 20,
                name: 'Обслуживание клиентов',
                documents: [
                    {
                        id: 1,
                        name: 'И-20 Инструкция пользователя CRM системы Битрикс24',
                        path: require('./documents/InternalRegulatoryDocuments/И-20 Инструкция пользователя CRM системы Битрикс24.docx'),
                    },
                    {
                        id: 2,
                        name: 'И-10 Инструкция о порядке обслуживания клиентов и абонентов по телефону работниками УСС',
                        path: require('./documents/InternalRegulatoryDocuments/И-10 Инструкция о порядке обслуживания клиентов и абонентов по телефону работниками УСС.docx'),
                    },
                ]
            },
        ]
    },
    buisness_process: {
        name: 'Бизнес процессы',
        category: [
            {
                id: 1,
                name: 'Андеррайтинг',
                documents: [
                    {
                        id: 1,
                        name: 'БП-03 Регламент бизнес-процесса Андеррайтинг',
                        path: require('./documents/buisness_process/БП-03 Регламент бизнес-процесса Андеррайтинг.docx'),
                    },
                ]
            },
            {
                id: 2,
                name: 'Перестрахование',
                documents: [
                    {
                        id: 1,
                        name: 'БП-04 Регламент бизнес-процесса Перестрахование',
                        path: require('./documents/buisness_process/БП-04 Регламент бизнес-процесса Перестрахование.docx'),
                    },
                ]
            },
            {
                id: 3,
                name: 'Урегулирование убытков',
                documents: [
                    {
                        id: 1,
                        name: 'БП-06 Урегулирование убытков (страхового случая)',
                        path: require('./documents/buisness_process/БП-06 Урегулирование убытков (страхового случая).pdf'),
                    },
                ]
            },
            {
                id: 4,
                name: 'Создание страхового продукта',
                documents: [
                    {
                        id: 1,
                        name: 'БП-01 Регламент БП Создание страхового продукта',
                        path: require('./documents/buisness_process/БП-01 Регламент БП Создание страхового продукта.doc'),
                    },
                ]
            },
            {
                id: 5,
                name: 'Продажи',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    }
                ]
            },
            {
                id: 6,
                name: 'Операционная деятельность',
                documents: [
                    {
                        id: 1,
                        name: 'БП-09 Регламент бизнес-процесса Электронный закуп услуг страхования',
                        path: require('./documents/buisness_process/БП-09 Регламент бизнес-процесса Электронный закуп услуг страхования.pdf'),
                    }
                ]
            },
            {
                id: 7,
                name: 'Административно-хозяйственное обеспечение',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 8,
                name: 'Юридическое обеспечение',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 9,
                name: 'IT-обеспечение',
                documents: [
                    {
                        id: 1,
                        name: 'БП-07 Регламент бизнес-процесса Автоматизация бизнес-процессов',
                        path: require('./documents/buisness_process/1674636457_'),
                    },
                ]
            },
            {
                id: 10,
                name: 'Управление персоналом',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 11,
                name: 'Управление финансами',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 12,
                name: 'Маркетинг',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 13,
                name: 'Бизнес-процесс Отдела Contact-center',
                documents: [
                    {
                        id: 1,
                        name: 'БП-20 Регламент бизнес-процесса Отдел Contact-center',
                        path: require('./documents/buisness_process/БП-20 Регламент бизнес-процесса Отдел Contact-center.doc'),
                    },
                ]
            },
        ],
    },
    // пока не известно нужен ли KIAS 
    KIAS_instructions: {
        name: 'Инструкции КИАС',
        category: [
            {
                id: 1,
                name: 'Продукты',
                documents: [
                    {
                        id: 1,
                        name: 'И-КИАС-5 Инструкция по формированию договора ОГПОПП',
                        path: require('./documents/KIAS_instructions/И-КИАС-5 Инструкция по формированию договора ОГПОПП.pdf'),
                    },
                    {
                        id: 2,
                        name: 'И-КИАС-6 Инструкция по формированию договора МСТ',
                        path: require('./documents/KIAS_instructions/И-КИАС-6 Инструкция по формированию договора МСТ.pdf'),
                    },
                    {
                        id: 3,
                        name: 'И-КИАС-7 Инструкция по формированию договора Сакта',
                        path: require('./documents/KIAS_instructions/И-КИАС-7 Инструкция по формированию договора Сакта.pdf'),
                    },
                    {
                        id: 4,
                        name: 'И-КИАС-8 Инструкция по формированию договора НС выбор',
                        path: require('./documents/KIAS_instructions/И-КИАС-8 Инструкция по формированию договора НС выбор.pdf'),
                    },
                    {
                        id: 5,
                        name: 'И-КИАС-9 Инструкция по формированию договора ДМС (физ, юр.лиц).pdf',
                        path: require('./documents/KIAS_instructions/И-КИАС-9 Инструкция по формированию договора ДМС (физ, юр.лиц).pdf'),
                    },
                    {
                        id: 6,
                        name: 'И-КИАС-10 Инструкция по формированию договора Преимущество',
                        path: require('./documents/KIAS_instructions/И-КИАС-10 Инструкция по формированию договора Преимущество.pdf'),
                    },
                    {
                        id: 7,
                        name: 'И-КИАС-11 Инструкция по формированию договора Грузы(разовая перевозка).pdf',
                        path: require('./documents/KIAS_instructions/И-КИАС-11 Инструкция по формированию договора Грузы(разовая перевозка).pdf'),
                    },
                    {
                        id: 8,
                        name: 'И-КИАС-4 Инструкция по формированию договора ОГПОВТС',
                        path: require('./documents/KIAS_instructions/1690522310_'),
                    },
                ]
            },
            {
                id: 2,
                name: 'Сервисные услуги_Акции',
                documents: [
                    {
                        id: 1,
                        name: 'И-КИАС-15 Инструкция по регистрации заявок на оказание услуг по карте Помощь на дороге',
                        path: require('./documents/KIAS_instructions/И-КИАС-15 Инструкция по регистрации заявок на оказание услуг по карте Помощь на дороге.pdf'),
                    }
                ]
            },
            {
                id: 3,
                name: 'Андеррайтинг',
                documents: [
                    {
                        id: 1,
                        name: 'И-КИАС-13 Инструкция по формированию Полных котировок (андеррайтинг)',
                        path: require('./documents/KIAS_instructions/И-КИАС-13 Инструкция по формированию Полных котировок (андеррайтинг).pdf'),
                    },
                    {
                        id: 2,
                        name: 'И-КИАС-14 Инструкция по формированию Генеральных заявок (андеррайтинг)',
                        path: require('./documents/KIAS_instructions/И-КИАС-14 Инструкция по формированию Генеральных заявок (андеррайтинг).pdf'),
                    },
                ]
            },
            {
                id: 4,
                name: 'Сопровождение договоров',
                documents: [
                    {
                        id: 1,
                        name: 'И-КИАС-3 Инструкция по формированию и ведению электронного досье контрагента',
                        path: require('./documents/KIAS_instructions/И-КИАС-3 Инструкция по формированию и ведению электронного досье контрагента.pdf'),
                    },
                    {
                        id: 2,
                        name: 'Приложение №1 к И-КИАС-3 перечень док. и свед. физ.лица (упрощенная)',
                        path: require('./documents/KIAS_instructions/Приложение №1 к И-КИАС-3 перечень док. и свед. физ.лица (упрощенная).pdf'),
                    },
                    {
                        id: 3,
                        name: 'Приложение №2 к И-КИАС-3 Перечень док. и свед. юр.лица (упрощенная)',
                        path: require('./documents/KIAS_instructions/Приложение №2 к И-КИАС-3 Перечень док. и свед. юр.лица (упрощенная).pdf'),
                    },
                    {
                        id: 4,
                        name: 'Приложение №3к И-КИАС-3 Перечень док. и свед. физ.лица (углубленная)',
                        path: require('./documents/KIAS_instructions/Приложение №3к И-КИАС-3 Перечень док. и свед. физ.лица (углубленная).pdf'),
                    },
                    {
                        id: 5,
                        name: 'Приложение №4 к И-КИАС-3 перечень док. и свед. юр.лица (углубленная)',
                        path: require('./documents/KIAS_instructions/Приложение №4 к И-КИАС-3 перечень док. и свед. юр.лица (углубленная).pdf'),
                    },
                    {
                        id: 6,
                        name: 'И-КИАС-22 Инструкция по запросу и формированию доверенностей',
                        path: require('./documents/KIAS_instructions/И-КИАС-22 Инструкция по запросу и формированию доверенностей.pdf'),
                    },
                    {
                        id: 7,
                        name: 'И-КИАС-24 Инструкция по работе с лимитами на подписание договоров страхования',
                        path: require('./documents/KIAS_instructions/И-КИАС-24 Инструкция по работе с лимитами на подписание договоров страхования.pdf'),
                    },
                    {
                        id: 8,
                        name: 'И-КИАС-02 Инструкция по заключению договоров страхования с использованием ЭЦП',
                        path: require('./documents/KIAS_instructions/И-КИАС-02 Инструкция по заключению договоров страхования с использованием ЭЦП.docx'),
                    },
                    {
                        id: 9,
                        name: 'И-КИАС-1 Инструкция по формированию СД при заключении договора страхования',
                        path: require('./documents/KIAS_instructions/И-КИАС-1 Инструкция по формированию СД при заключении договора страхования.docx'),
                    },
                ]
            },
            {
                id: 5,
                name: 'Агентская деятельность',
                documents: [
                    {
                        id: 1,
                        name: 'И-КИАС-28 Инструкция по процессу согласования размеров КВ по ОС',
                        path: require('./documents/KIAS_instructions/И-КИАС-28 Инструкция по процессу согласования размеров КВ по ОС.pdf'),
                    }
                ]
            },
            {
                id: 6,
                name: 'Операционная деятельность',
                documents: [
                    {
                        id: 1,
                        name: 'И-КИАС-29 Инструкция по работе с кассой в КИАС с учетом интеграции с Web-кассой',
                        path: require('./documents/KIAS_instructions/И-КИАС-29 Инструкция по работе с кассой в КИАС с учетом интеграции с Web-кассой.pdf'),
                    }
                ]
            },
            {
                id: 7,
                name: 'Электронный документооборот',
                documents: [
                    {
                        id: 1,
                        name: 'И-КИАС-20 Инструкция по формированию и согласованию служебных записок',
                        path: require('./documents/KIAS_instructions/И-КИАС-20 Инструкция по формированию и согласованию служебных записок.pdf'),
                    },
                    {
                        id: 2,
                        name: 'И-КИАС-27 Инструкция по процессу согласования обходного листа',
                        path: require('./documents/KIAS_instructions/И-КИАС-27 Инструкция по процессу согласования обходного листа.pdf'),
                    },
                    {
                        id: 3,
                        name: 'И-КИАС-30 Инструкция по формированию отчета по итогам аудита и согласованию Плана мероприятий',
                        path: require('./documents/KIAS_instructions/И-КИАС-30 Инструкция по формированию отчета по итогам аудита и согласованию Плана мероприятий.docx'),
                    },
                ]
            },
            {
                id: 8,
                name: 'Делопроизводство',
                documents: [
                    {
                        id: 1,
                        name: 'И-КИАС-18 Инструкция по регистрации входящих и исходящих документов',
                        path: require('./documents/KIAS_instructions/И-КИАС-18 Инструкция по регистрации входящих и исходящих документов.pdf'),
                    },
                    {
                        id: 2,
                        name: 'И-КИАС-19 Инструкция по учету фирменных бланков с логотипом',
                        path: require('./documents/KIAS_instructions/И-КИАС-19 Инструкция по учету фирменных бланков с логотипом.pdf'),
                    },
                    {
                        id: 3,
                        name: 'И-КИАС-21 Инструкция по процедуре подачи заявки на почтовую отправку документов в КИАС',
                        path: require('./documents/KIAS_instructions/И-КИАС-21 Инструкция по процедуре подачи заявки на почтовую отправку документов в КИАС.docx'),
                    },
                    {
                        id: 4,
                        name: 'И-КИАС-23 Инструкция по формированию запроса на выдачу документов из архива',
                        path: require('./documents/KIAS_instructions/И-КИАС-23 Инструкция по формированию запроса на выдачу документов из архива.pdf'),
                    },
                    {
                        id: 5,
                        name: 'Памятка по порядку и учету работ при заключении договоров на оказание услуг',
                        path: require('./documents/KIAS_instructions/Памятка по порядку и учету работ при заключении договоров на оказание услуг.docx'),
                    },
                ]
            },
            {
                id: 9,
                name: 'Административно хозяйственная деятельность',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ],
            },
            {
                id: 10,
                name: 'Кадровая деятельность',
                documents: [
                    {
                        id: 1,
                        name: 'И-КИАС-16 Инструкция по формированию списка сотрудников',
                        path: require('./documents/KIAS_instructions/И-КИАС-16 Инструкция по формированию списка сотрудников.pdf'),
                    },
                    {
                        id: 2,
                        name: 'И-КИАС-25 Инструкция по процессу при прохождении испытательного срока',
                        path: require('./documents/KIAS_instructions/И-КИАС-25 Инструкция по процессу при прохождении испытательного срока.pdf'),
                    },
                    {
                        id: 3,
                        name: 'И-КИАС-26 Инструкция по созданию заявления на административный день',
                        path: require('./documents/KIAS_instructions/И-КИАС-26 Инструкция по созданию заявления на административный день.pdf'),
                    },
                    {
                        id: 4,
                        name: 'И-КИАС-32 Инструкция по формированию, согласованию и подписанию кадровых Приказов',
                        path: require('./documents/KIAS_instructions/И-КИАС-32 Инструкция по формированию, согласованию и подписанию кадровых Приказов.docx'),
                    },
                    {
                        id: 5,
                        name: 'Памятка по порядку и учету работ при заключении договоров на оказание услуг',
                        path: require('./documents/KIAS_instructions/Памятка по порядку и учету работ при заключении договоров на оказание услуг.docx'),
                    },
                ]
            },
        ]
    },

    protocols: {
        name: 'Протоколы',
        category: [
            {
                id: 1,
                name: 'Андеррайтинговый Совет',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 2,
                name: 'Выплатной Комитет',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
            {
                id: 3,
                name: 'Комитет по внутреннему аудиту',
                documents: [
                    {
                        id: 1,
                        name: '',
                        path: '',
                    },
                ]
            },
        ]
    },
    
    training_from_OD: {
        name: 'Обучение от ОД',
        category: [
            {
                id: 1,
                name: 'УСДС обучение',
                documents: [
                    {
                        id: 1,
                        name: 'БП-05.Ф4.06 Проверка страх. докум',
                        path: require('./documents/training_from_OD/БП-05.Ф4.06 Проверка страх. докум.pdf'),
                    },
                    {
                        id: 2,
                        name: 'Видео-инструкция КИАС (приложение к презентации)',
                        path: require('./documents/training_from_OD/Видео-инструкция КИАС (приложение к презентации).mp4'),
                    },
                    {
                        id: 3,
                        name: 'ГРАЖДАНСКИЙ КОДЕКС РК (ОСОБЕННАЯ ЧАСТЬ) ОТ 01.07.1999 № 409-I',
                        path: '',
                    },
                    {
                        id: 4,
                        name: 'ДИ-16 Специалист по учету 1,2,3 категории 10.03.2015',
                        path: require('./documents/training_from_OD/ДИ-16 Специалист по учету 1,2,3 категории 10.03.2015.docx'),
                    },
                    {
                        id: 5,
                        name: 'ЗАКОН РК «О СТРАХОВОЙ ДЕЯТЕЛЬНОСТИ» ОТ 18.12.00 № 126-II',
                        path: '',
                    },
                    {
                        id: 6,
                        name: 'И-11 Инструкция по учету и списанию договоров страхования',
                        path: require('./documents/training_from_OD/И-11 Инструкция по учету и списанию договоров страхования.docx'),
                    },
                    {
                        id: 7,
                        name: 'И-КИАС-1 Инструкция по формированию СД при заключении договора страхования',
                        path: require('./documents/training_from_OD/И-КИАС-1 Инструкция по формированию СД при заключении договора страхования.docx'),
                    },
                    {
                        id: 8,
                        name: 'Перечень ФИЗ. лица',
                        path: require('./documents/training_from_OD/Перечень ФИЗ. лица.pdf'),
                    },
                    {
                        id: 9,
                        name: 'Перечень ЮР. лица',
                        path: require('./documents/training_from_OD/Перечень ЮР. лица.pdf'),
                    },
                    {
                        id: 10,
                        name: 'Постановление Правительства РК от 31 октября 2018 года № 703',
                        path: '',
                    },
                    {
                        id: 11,
                        name: 'Презентация УСДС ОД',
                        path: require('./documents/training_from_OD/Презентация УСДС ОД.pptx'),
                    },
                    {
                        id: 12,
                        name: 'Приказ №15 -ОД от 21 05 2018г_скан копии договоров',
                        path: require('./documents/training_from_OD/Приказ №15 -ОД от 21 05 2018г_скан копии договоров.pdf'),
                    },
                    {
                        id: 13,
                        name: 'Формирование страхового дела (документы)',
                        path: require('./documents/training_from_OD/Формирование страхового дела (документы).xlsx'),
                    },
                ]
            },
            {
                id: 2,
                name: 'УАДСиП обучение',
                documents: [
                    {
                        id: 1,
                        name: 'УАДСиП',
                        path: require('./documents/training_from_OD/УАДСиП.pptx'),
                    },
                ]
            },
            {
                id: 3,
                name: 'УРиП обучение',
                documents: [
                    {
                        id: 1,
                        name: 'РВ - возврат БАНК',
                        path: require('./documents/training_from_OD/РВ - возврат БАНК.docx'),
                    },
                    {
                        id: 2,
                        name: 'РВ - Возврат аванса страхователю БАНК',
                        path: require('./documents/training_from_OD/РВ - Возврат аванса страхователю БАНК.docx'),
                    },
                    {
                        id: 3,
                        name: 'Операционный Департамент (УРиП)',
                        path: require('./documents/training_from_OD/Операционный Департамент (УРиП).pptx'),
                    },
                    {
                        id: 4,
                        name: 'Инструкция по созданию счета на оплату',
                        path: require('./documents/training_from_OD/Инструкция по созданию счета на оплату.docx'),
                    },
                    {
                        id: 5,
                        name: 'Инструкция по созданию МЕОК_взаимозачет',
                        path: require('./documents/training_from_OD/Инструкция по созданию МЕОК_взаимозачет.docx'),
                    },
                    {
                        id: 6,
                        name: 'Инструкция по выставлению счета на оплату в мобильное приложение Kaspi',
                        path: require('./documents/training_from_OD/Инструкция по выставлению счета на оплату в мобильное приложение Kaspi.docx'),
                    },
                    {
                        id: 7,
                        name: 'Инструкция - Заявки в ЦО',
                        path: require('./documents/training_from_OD/Инструкция - Заявки в ЦО.docx'),
                    },
                    {
                        id: 8,
                        name: 'Инструкция проведения Инвентаризации в кассах',
                        path: require('./documents/training_from_OD/Инструкция проведения Инвентаризации в кассах.docx'),
                    },
                    {
                        id: 9,
                        name: 'Инструкция по заполнению кассовых книг в КИАС',
                        path: require('./documents/training_from_OD/Инструкция по заполнению кассовых книг в КИАС.docx'),
                    },
                    {
                        id: 10,
                        name: 'Инструкция - групповая загрузка',
                        path: require('./documents/training_from_OD/Инструкция - групповая загрузка.docx'),
                    },
                    {
                        id: 11,
                        name: 'И-КИАС-29 Инструкция по работе с кассой в КИАС с учетом интеграции с Web-кассой',
                        path: require('./documents/training_from_OD/И-КИАС-29 Инструкция по работе с кассой в КИАС с учетом интеграции с Web-кассой.docx'),
                    },
                    {
                        id: 12,
                        name: '№20-ОД от 01.09.2021г. приказ о проведении инвентаризации',
                        path: require('./documents/training_from_OD/№20-ОД от 01.09.2021г. приказ о проведении инвентаризации.pdf'),
                    },
                    {
                        id: 13,
                        name: '6-ОД от 28.02.2020г. лимит в кассах',
                        path: require('./documents/training_from_OD/6-ОД от 28.02.2020г. лимит в кассах.pdf'),
                    },
                ]
            },
        ]
    }

};
export default LibraryData;


