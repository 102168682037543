import {
  Alert,
  Col,
  Modal,
  Progress,
  Row,
  Space,
  Typography,
  UploadFile,
} from 'antd';
import { FileImageTwoTone, FileTwoTone } from '@ant-design/icons';

const { Text } = Typography;

interface IPropsFileListModal {
  isError: boolean;
  isModalOpen: boolean;
  handleCancel: () => void;
  fileList: UploadFile[];
}

const FileListModal = ({
  isModalOpen,
  handleCancel,
  fileList,
  isError,
}: IPropsFileListModal) => {
  const renderFileList =
    fileList.length >= 1 &&
    fileList.map((file) => {
      return (
        <Row
          className="width mt-1 d-flex align-center justify-center"
          key={file.uid}
        >
          <Col span={24}>
            {file.type === 'image/jpeg' ? (
              <FileImageTwoTone style={{ fontSize: '200%' }} />
            ) : (
              <FileTwoTone style={{ fontSize: '200%' }} />
            )}
            <Text>{file.name}</Text>
          </Col>
          <Col span={24}>
            <Progress percent={file.percent} showInfo={false} />
          </Col>
        </Row>
      );
    });

  return (
    <Modal
      title="Прогресс загрузки файлов"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      {isError && (
        <Row className="mt-5">
          <Alert
            message={
              <Space direction="vertical">
                <Text>
                  Если файл не загрузился, это может быть связано с проблемами
                  на стороне сервера. Пожалуйста, проверьте следующие возможные
                  причины
                </Text>
                <Text>
                  1. Проблемы на сервере: Возможно, сервер временно недоступен
                  или возникли технические неполадки. Попробуйте повторить
                  загрузку файла через некоторое время.
                </Text>
                <Text>
                  2. Файл уже существует: Убедитесь, что файл с таким же именем
                  и содержимым еще не существует в целевой директории. Наша
                  система отклоняет загрузку дублирующихся файлов.
                </Text>
              </Space>
            }
            type="info"
          />
        </Row>
      )}
      <Row className="width mt-5">{renderFileList}</Row>
    </Modal>
  );
};

export default FileListModal;
