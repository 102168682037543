import { ColleaguesListType } from '@hooks/useColleagues';
import { IinititalState } from '@modules/Recruiting/RecruitingProvider';
import { createContext } from 'react';

interface RecruitingContextInterface {
  colleaguesList: ColleaguesListType;
  isLoadingColleaguesList: boolean;
  departmentList: any[];
  state: IinititalState;
}

const RecruitingContext = createContext<RecruitingContextInterface>({
  colleaguesList: [],
  isLoadingColleaguesList: false,
  departmentList: [],
  state: {
    loading: false,
    selectValues: {
      name_of_post_id: [],
      reason_to_recruiting: [],
      desired_age: [],
      sex: [],
      education: [],
      work_experience: [],
      is_he_was_boss: [],
      type_of_hire: [],
      perspective_to_candidate: [],
      computer_knowing: [],
      motivation: [],
      job_chart: [],
      have_car: [],
      driver_category: [],
      social_packages: [],
      languages: { type: [], select: [] },
      interview_result: [],
      security_service_result: [],
      application_status: [],
    },
  },
});

export default RecruitingContext;
