import { notification } from 'antd';
import api from '../services/api';

const updateForm = async (idApplication: string | undefined, data: any) => {
  const response = await api.updApplication(idApplication, data);
  if (response?.data?.success === true) {
    notification.info({
      message: 'Инфо',
      description: response?.data?.message,
    });
  }
};

export default updateForm;
