import { Empty, Steps } from 'antd';
import dayjs from 'dayjs';

const { Step } = Steps;

interface UserDetailsCareerProps {
  detailList: Career[] | null;
}

interface Career {
  datebeg: Date;
  dateend: Date;
  dept: string;
  fullname: string;
}

function UserDetailsCareer({ detailList = [] }: UserDetailsCareerProps) {
  if (detailList === null) {
    return <Empty />;
  }

  const sortByDateDetailList = [...detailList].sort(
    (itemA, itemB) =>
      new Date(itemB.datebeg).getTime() - new Date(itemA.datebeg).getTime()
  );

  return (
    <Steps
      progressDot
      status="finish"
      direction="vertical"
      current={detailList.length}
      items={sortByDateDetailList.map((item) => {
        const dateBegin = dayjs(item.datebeg, 'YYYY-MM-DD').isValid()
          ? dayjs(item.datebeg, 'YYYY-MM-DD').format('DD.MM.YYYY')
          : '';
        const dateEnd = dayjs(item.dateend, 'YYYY-MM-DD').isValid()
          ? dayjs(item.dateend, 'YYYY-MM-DD').format('DD.MM.YYYY')
          : 'н.в.';

        return {
          title: item.dept,
          description: item.fullname,
          'data-date-begin': dateBegin,
          'data-date-end': dateEnd,
        };
      })}
    />
  );
}

export default UserDetailsCareer;
