import { notification } from 'antd';
import axios from 'axios';
import api from '../services/api';
import { Dispatch, SetStateAction } from 'react';

const submitForm = async (
  values,
  recruitingId: string | undefined,
  handleCancel: () => void,
  setIsLoadingSubmitForm: Dispatch<SetStateAction<boolean>>
) => {
  const formData = new FormData();

  if (values.file && values.file.length) {
    formData.append('file', values.file[0]?.originFileObj);
  }

  const { candidate_fullname, candidate_phone_number, candidate_iin } = values;

  formData.append('candidate_phone_number', candidate_phone_number);
  formData.append('candidate_fullname', candidate_fullname);
  formData.append(
    'recruiting_id',
    recruitingId !== undefined ? recruitingId : ''
  );

  if (candidate_iin) {
    formData.append('candidate_iin', candidate_iin);
  }

  try {
    setIsLoadingSubmitForm(true)
    const { data } = await api.saveApplicationCandidate(formData);

    if (data?.success === true) {
      notification.info({
        message: 'Информация',
        description: data?.message,
      });
      handleCancel();
    }
  } catch (error) {
    let message;

    if (axios.isAxiosError(error)) {
      message = error.message;
    } else {
      message = String(error);
    }

    notification.info({
      message: 'Ошибка',
      description: message,
    });
  } finally {
    setIsLoadingSubmitForm(false)
  }
};

export default submitForm;
