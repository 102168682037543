import inputmask from 'inputmask';
export default function DateMask() {
  return (
    /**
     * Подключаем маску для datePicker
     */
    setTimeout(() => {
      const selector = document.querySelectorAll('.dateMask input');
      inputmask({
        mask: '99.99.9999',
        placeholder: ' ',
        showMaskOnHover: false,
        showMaskOnFocus: false,
      }).mask(selector);
    }, 0)
  );
}
