import { notification } from 'antd';
import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import api from '@modules/Quotation/services';


export interface ColleaguesKias {
  DEPTISN: string;
  PARENTISN: string;
  FULLNAME: string;
  ISDEPT: string;
  EMPLISN: string;
  children: ColleaguesKias[];
}

const useCompanyTree = () => {
  const [colleaguesKiasList, setColleaguesKiasList] = useState<
    ColleaguesKias[]
  >([]);
  const [isLoadingColleaguesKiasList, setIsLoadingColleaguesKiasList] =
    useState(false);

  const getCompanyTree = useCallback(() => {
    (async () => {
      setIsLoadingColleaguesKiasList(true);
      try {
        const { data } = await api.quotation.getCompanyTree();
        setColleaguesKiasList(data.data);
      } catch (error) {
        let message;
        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }
        notification.info({
          message: 'Ошибка',
          description: message,
        });
      } finally {
        setIsLoadingColleaguesKiasList(false);
      }
    })();
  }, []);

  useEffect(() => {
    getCompanyTree();
  }, [getCompanyTree]);

  return { colleaguesKiasList, isLoadingColleaguesKiasList };
};

export default useCompanyTree;
