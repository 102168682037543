import { Form } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { IState, Action } from '../reducer/reducer';

interface IProps {
  state: IState;
  dispatch: React.Dispatch<Action>;
}

function SpeakerModalSetupField(props: IProps) {
  const { state, dispatch } = props;

  const { speaker } = state;

  const form = Form.useFormInstance();

  useEffect(() => {
    if (Object.keys(speaker).length) {
      const {
        date_time,
        topic,
        university_name,
        specialization,
        address,
        course,
        quantity,
        user_id
      } = speaker;

      //   TODO
      // ID DOES NOT COME FROM BACK-END
      form.setFieldsValue({
        // user_id: speaker.avatar.client_id,
        topic: topic,
        date_time:
          date_time !== '' && date_time !== null ? dayjs(date_time) : '',
        university_name: university_name,
        specialization: specialization,
        address: address,
        quantity: quantity,
        course: course,
        user_id: user_id.id
      });
    }

    return () => {
      dispatch({ type: 'speaker', payload: {} });
      form.resetFields();
    };
  }, [speaker]);

  return null;
}

export default SpeakerModalSetupField;
