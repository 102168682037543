import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Select, Typography, notification } from 'antd';
import CSelect from '@components/UI/select/CSelect';
import api from '@modules/Quotation/services';
import axios from 'axios';
const MainContractRole = ({
  clauses,
  quotationData,
  productIsn,
  agrIsn,
  userForeignId,
  contractData,
}) => {
  const [clausesData, setClausesData] = useState<any[]>(clauses);

  const { Option } = Select;
  const { Title } = Typography;
  const [clauseForm] = Form.useForm();
  useEffect(() => {
    if (clauses?.length > 0) {
      setClausesData(
        clauses.map((cl) => {
          return {
            ...cl,
            clause_value: cl.clause_value.map((val) => {
              return {
                clause_value: val.full_name ? val.full_name : val.clause_value,
                clause_value_isn: val.foreign_id
                  ? val.full_name
                  : val.clause_value_isn,
              };
            }),
          };
        })
      );
    } else if (quotationData?.data?.[0]?.clauses.length > 0) {
      setClausesData(
        quotationData?.data?.[0]?.clauses.map((cl) => {
          return {
            ...cl,
            clause_value: cl.clause_value.map((val) => {
              return {
                clause_value: val.full_name,
                clause_value_isn: val.foreign_id,
              };
            }),
          };
        })
      );
    }
  }, [clauses]);
  const onClauseChange = async ({ clause_isn, class_isn }) => {
    if (!clause_isn || !class_isn) return;

    const requestBody = {
      agr_clause: [
        {
          agr_isn: agrIsn,
          clause_isn,
          class_isn,
          user_isn: userForeignId,
        },
      ],
    };

    let message;
    let messageType = 'success';

    try {
      const { data } = await api.saveQuotation.clauseSave(requestBody);
      await api.quotation.setGraph({
        agr_isn: agrIsn,
        user_isn: userForeignId,
      });
      message = data.message;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      messageType = 'info';
      notification[messageType]({
        message: messageType === 'info' ? 'Ошибка' : null,
        description: message,
      });
    }
  };
  useEffect(() => {
    if (contractData?.data?.agrclause) {
      contractData?.data?.agrclause.map((item) => {
        clauseForm.setFieldValue([item.clause_isn], Number(item.class_isn));
      });
      if (Number(contractData?.data?.agreement[0]?.productisn) === 221711) {
        clauseForm.setFieldValue('11110', 198490);
        clauseForm.setFieldValue('2999', 5377);
      }
    }
  }, [contractData]);

  return (
    <Form className="c-form" layout="vertical" form={clauseForm}>
      <Card className="mt-5">
        <Title className="main-contract__section-title" level={3}>
          Ограничения/Оговорки
        </Title>
        <Row gutter={18} className="mt-5" style={{ marginBottom: -20 }}>
          {/* {Number(productIsn) !== 221711 && */}
          {
            clausesData?.map((clause, index) => {
              console.log(clausesData);

              const cols = 24 / clauses.length;
              return (
                <Col xs={24} md={cols} key={index}>
                  <Form.Item
                    name={clause.clause_isn}
                    initialValue={clause.class_isn}
                    label={
                      clausesData?.find(
                        (clause1) =>
                          clause1.clause_isn === Number(clause.clause_isn)
                      )?.clause_name
                    }
                  >
                    <CSelect
                      size="large"
                      onSelect={(e) => {
                        onClauseChange({
                          clause_isn: clause.clause_isn,
                          class_isn: e,
                        });
                      }}
                    >
                      {/* {Number(productIsn) === 471391 ||
                      Number(productIsn) === 221711 ? ( */}
                      {clause?.clause_value?.map((clauseVal) => (
                        <Option
                          value={clauseVal.clause_value_isn}
                          key={clauseVal.clause_value_isn}
                        >
                          {clauseVal.clause_value}
                        </Option>
                      ))}
                    </CSelect>
                  </Form.Item>
                </Col>
              );
            })
            // })}
          }
          {/* {Number(productIsn) === 221711 && (
            <Col xs={24} md={'12'} key={1}>
              <Form.Item name={'11110'} label={'Канал продаж'}>
                <CSelect
                  size="large"
                  onSelect={(e) => {
                    onClauseChange({
                      clause_isn: 11110,
                      class_isn: e,
                    });
                  }}
                >
                  <Option value={'198390'}>Агентские продажи</Option>
                  <Option value={'198490'}>Прямые продажи</Option>
                </CSelect>
              </Form.Item>
              <Form.Item
                name={'2999'}
                label={'Порядок уплаты страхового взноса'}
              >
                <CSelect
                  size="large"
                  onSelect={(e) => {
                    onClauseChange({
                      clause_isn: 2999,
                      class_isn: e,
                    });
                  }}
                >
                  <Option value={'5377'}>
                    Единовременно по условиям договора
                  </Option>
                  <Option value={'650451'}>
                    в рассрочку. Порядок уплаты страховых взносов:
                  </Option>
                  <Option value={'5376'}>
                    Единовременно до начала действия договора
                  </Option>
                </CSelect>
              </Form.Item>
            </Col>
          )} */}
        </Row>
      </Card>
    </Form>
  );
};

export default MainContractRole;
