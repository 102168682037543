import useAuth from '@hooks/useAuth';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { getClientFileUrl } from './getClientFileUrl';
import { sendClientData } from './sendClientData';

interface IClientsProps {
  agreementType: string;
  agreementID: number;
}

interface IUrlClientData {
  LS_ISN: string;
  FILE_ISN: string;
  FILE_PATH_STORAGE: string;
}

export interface IClientDataSend {
  ls_isn: string;
  file_isn: string;
  certificateValidityPeriod: string;
  iin: string;
  tspDate: string;
  name: string;
}

function Clients(props: IClientsProps) {
  const [isStatusClient, setIsStatusClient] = useState<boolean>(false);
  const [urlClientData, setUrlClientData] = useState<IUrlClientData[]>([]);
  const [clientDataSend, setClientDataSend] = useState<IClientDataSend[]>([]);

  const auth = useAuth();

  const { agreementID, agreementType } = props;

  useEffect(() => {
    if (agreementType === 'kias' && agreementID) {
      const foreignId = auth.user.data.user_info.foreign_id;
      getClientFileUrl(foreignId, agreementID).then((values) => {
        if (values?.status === true) {
          setIsStatusClient(true);
          setUrlClientData(values?.url);
        }
      });
    }
  }, [agreementType, agreementID]);

  useEffect(() => {
    if (isStatusClient === true && urlClientData.length) {
      connectSocket(urlClientData);
    }
  }, [isStatusClient, urlClientData]);

  useEffect(() => {
    const clientDataSendLength = clientDataSend.length;
    const urlClientDataLength = urlClientData.length;
    if (clientDataSendLength > 0 && urlClientDataLength === clientDataSendLength) {
      sendClientData(clientDataSend)
    }
  }, [clientDataSend])

  const connectSocket = (urlClientData: IUrlClientData[]) => {
    var webSocket = new WebSocket('wss://127.0.0.1:13579/');

    webSocket.onopen = (e) => {
      console.log('connection opened')
      webSocket.send(
        '{\n' +
          '    module: "kz.uchet.signUtil.commonUtils", \n' +
          '    method: "getVersion", \n' +
          '    args: [""]\n' +
          '}'
      );
    };

    webSocket.onmessage = function (msg) {
      var result = JSON.parse(msg.data);
      if (!result.success && result.errorCode === 'MODULE_NOT_FOUND') {
        installModule();
      } else {
        if (result?.code === '200') {
          mapData(urlClientData);
        }
      }
    };

    webSocket.onclose = (e) => {
      console.log(e, 'closed')
      if (e.wasClean) {
        console.log('connection closed');
      }
    };
  };

  function installModule() {
    var webSocket = new WebSocket('wss://127.0.0.1:13579');
    webSocket.onopen = function () {
      webSocket.send(
        '{\n' +
          '    module: "kz.gov.pki.ncalayerservices.accessory", \n' +
          '    method: "installBundle", \n' +
          '    symname: "kz.uchet.signUtil" \n' +
          '}'
      );
    };
    webSocket.onmessage = function (msg) {
      console.log(msg);
    };
    webSocket.onerror = function (msg) {
      console.log(msg);
    };
  }

  function mapData(urlClientData: IUrlClientData[]) {
    urlClientData.map((element) =>
      checkSignedFile(
        element.FILE_PATH_STORAGE,
        element.LS_ISN,
        element.FILE_ISN
      )
    );
  }

  function checkSignedFile(url: string, lsIsn: string, fileIsn: string) {
    if (url !== '') {
      var webSocket = new WebSocket('wss://127.0.0.1:13579');
      webSocket.onopen = function () {
        var responseObj = {};
        responseObj = {
          module: 'kz.uchet.signUtil.commonUtils',
          lang: 'en',
          method: 'checkCMSFromWeb',
          args: [url],
        };
        webSocket.send(JSON.stringify(responseObj));
      };

      webSocket.onmessage = function (msg) {
        var { responseObjects, code, message } = JSON.parse(msg.data);
        if (code) {
          if (Number(code) === 200) {
            if (responseObjects.length) {
              const data = responseObjects[0]
              data['ls_isn'] = lsIsn;
              data['file_isn'] = fileIsn;
              setClientDataSend((prevValue) => [...prevValue, data])
            }
          } else {
            notification.info({
              message: 'Оповещение',
              description: message,
            });
          }
        }
      };
      webSocket.onerror = function (msg) {
        notification.info({
          message: 'Инфо',
          description:
            'Убедитесь пожалуйста что у Вас установлена программа NCLayer и она запущена. Программу можно скачать по адресу https://pki.gov.kz/ncalayer/',
        });
      };
    }
  }

  return null;
}

export default Clients;
