import React, { memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IMainContract } from '@modules/Quotation/Interfaces/MainContract/index.interface';
import api from '@modules/Quotation/services';
import useAuth from '@hooks/useAuth';
import { Col, notification, Row, Typography } from 'antd';
import MainContractFormular from '@modules/Quotation/MainContract/MainContractFormular';
import MainContractRole from '@modules/Quotation/MainContract/MainContractRole';
import MainContractAdditionalAttributes from '@modules/Quotation/MainContract/MainContractAdditionalAttributes';
import MainContractClauses from '@modules/Quotation/MainContract/MainContractClauses';
import MainContractObjects from '@modules/Quotation/MainContract/MainContractObjects';
import MainContractAccruals from '@modules/Quotation/MainContract/MainContractAccruals';
import './MainContractSection.scss';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import CButton from '@components/UI/button/CButton';
import PrintedForm from '../PrintedForm/PrintedForm';
import { LeftOutlined } from '@ant-design/icons';
import MainContractDocument from './MainContractDocument';
import AddendumModal from '@modules/Quotation/MainContract/AddendumModal';
import DenounceModal from '@modules/Quotation/MainContract/DenounceModal';
import MainContractRequest from './MainContractRequest';
import ObjectQuotationDynamic from './MainContractObject/ObjectQuotationDynamic';
import ObjectVTS from './MainContractObject/ObjectVTS';
import MainContractButtons from './MainContractObject/MainContractButtons';
const MainContract: React.FC<IMainContract> = memo(() => {
  const { contractIsn } = useParams();
  const [contractData, setContractData] = useState<any>();
  const [userForeignId, setUserForeignId] = useState(0);
  const [quotationData, setQuotationData] = useState<any>();
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const [isAddendumOpen, setIsAddendumOpen] = useState<boolean>(false);
  const [isDenounceOpen, setIsDenounceOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<any>();
  const auth = useAuth();
  const { Title } = Typography;

  const savedAccruals = (params: any) => {};
  const getContract = () => {
    const fetchData = async () => {
      const { data } = await api.contractor.getAgreementByISN({
        agr_isn: contractIsn,
      });
      if (data) {
        setContractData(data);
      }
    };
    fetchData();
  };

  useEffect(() => {
    if (contractIsn) {
      const fetchData = async () => {
        const { data } = await api.contractor.getAgreementByISN({
          agr_isn: contractIsn,
        });
        if (data) {
          setContractData(data);
        }
      };
      fetchData();
    }
  }, [contractIsn]);

  useEffect(() => {
    if (contractData?.data) {
      setDocumentTitle(
        `Договор ${contractData?.data?.agreement[0]?.productisn_name} #${contractIsn} «${contractData?.data?.agreement[0]?.id}»`
      );
      setStatus(contractData.data.agreement[0].status_name);
      const fetchData2 = async () => {
        const { data } = await api.quotation.getFullQuotation(
          contractData?.data?.agreement[0]?.productisn
        );
        if (data) {
          setQuotationData(data);
        }
      };
      fetchData2();
    }
  }, [contractData]);

  useEffect(() => {
    if (!documentTitle) return;
    document.title = documentTitle;
  }, [documentTitle]);

  useEffect(() => {
    setUserForeignId(auth.user?.data?.user_info.foreign_id);
  }, [auth]);

  const contractProlongation = async () => {
    try {
      const { data } = await api.quotation.contractProlongation({
        isn: contractData.data.agreement[0].isn,
        user_isn: userForeignId,
      });
      if (data.NEWLY) {
        notification.success({
          message: 'Продление договора',
          description: 'Договор успешно продлен',
        });
        setTimeout(() => {
          window.location.replace(`/quotation/${data.AGREEMENTCALC_ID}`);
        }, 1100);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const saveContractToESBD = async () => {
    try {
      const { data } = await api.quotation.saveContractToESBD({
        doc_isn: contractIsn,
      });
      if (data.success) {
        notification.success({
          message: 'Успешно сохранено',
        });
      }
    } catch (e) {
      notification.error({
        message: 'При сохранении произошла ошибка',
      });
    }
  };
  const contractDataSections = useMemo(() => {
    if (contractData?.data) {
      if (
        Number(contractData?.data?.agreement[0]?.productisn) === 221711 ||
        Number(contractData?.data?.agreement[0]?.productisn) === 471391
      ) {
        return Object.entries({
          agreement: contractData.data.agreement,
          agrrole: [
            ...contractData.data.agrrole.filter(
              (role) => Number(role.classisn) === 2103
            ),
            ...contractData.data.agrrole.filter((role) => {
              if (
                contractData.data.details[0].roles.filter(
                  (item) => Number(item.RoleISN) !== Number(role.classisn)
                )[0] &&
                Number(role.classisn) !== 2103
              ) {
                return role;
              }
            }),
            ...contractData.data.details[0].roles.filter((item) => {
              if (
                contractData.data.agrrole.filter(
                  (role) => Number(item.RoleISN) === Number(role.classisn)
                )[0]
              ) {
                return null;
              } else {
                return item;
              }
            }),
          ],
          addattr_insurer: contractData.data?.details[0].attributes,
          addattr_underwriter: contractData.data?.details[0].attributes,
          addattr_object: contractData.data.details[0].attributes_object,
          addattr_object_underwriter:
            contractData.data.details[0].attributes_object,
          agrclause: contractData.data?.details[0].clauses,
          agrobject: contractData.data.details[0].objects,
          docspict: contractData.data.docspict,
          agrcond: contractData.data?.details[0].insrules,
          servreq: contractData.data.servreq,
          servreq_risk: contractData.data.servreq_risk,
          tariff: contractData.data.details[0].tariff,
          docs: contractData.data.docs,
          addendum_reason: contractData.data?.addendum_reason,
          denounce_reason: contractData.data.denounce_reason,
          agrsum: contractData.data.details[0].limitsum,
          prolongation: contractData.data.prolongation,
          primary_conditions: contractData.data.primary_conditions,
        });
      } else {
        return Object.entries(contractData.data);
      }
    }
    return null;
  }, [contractData]);
  const changeClausesFormat = (clauses) => {
    const clausesIsnsDublicate = clauses.map((cl) => Number(cl.ClauseISN));

    let clausesIsnsWithoutDublicate = clausesIsnsDublicate.filter(
      (cl, index) => clausesIsnsDublicate.indexOf(cl) === index
    );
    let newCheck = clausesIsnsWithoutDublicate.map((isn) => {
      let clauseData;
      let listOfClausesValue = clauses
        .filter((cl) => {
          if (Number(cl.ClauseISN) === Number(isn)) {
            clauseData = {
              active_mycent: true,
              clause_isn: Number(isn),
              clause_name: cl.ClauseName,
            };
            return cl;
          }
        })
        .map((cl) => {
          return {
            clause_value: cl.ClauseValue,
            clause_value_isn: Number(cl.ClauseValueISN),
          };
        });
      return {
        ...clauseData,
        clause_value: listOfClausesValue,
      };
    });
    return newCheck;
  };
  return (
    <div className="main-contract">
      {contractDataSections?.length && (
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={1}>{documentTitle}</Title>
          </Col>
        </Row>
      )}

      {contractDataSections?.length && (
        <Row gutter={24} className="mt-5">
          {contractData.data.prolongation &&
            parseInt(contractData.data.agreement[0].stageisn) === 221103 && (
              <Col>
                <CButton onClick={contractProlongation} type="primary">
                  Продлить договор
                </CButton>
              </Col>
            )}
          {contractData.data.addendum_reason.length >= 1 && (
            <>
              <Col>
                <CButton onClick={() => setIsAddendumOpen(true)} type="primary">
                  Аддендум
                </CButton>
              </Col>
              <AddendumModal
                addendumReason={
                  contractDataSections?.length &&
                  contractData.data.addendum_reason
                }
                addendumModal={isAddendumOpen}
                handleCancel={() => setIsAddendumOpen(false)}
                docIsn={contractIsn}
              />
            </>
          )}
          {contractData.data.agreement[0].status_name === 'Выпущен' && (
            <Col>
              <PrintedForm
                agrIsn={contractData.data.agreement[0].isn}
                printAgrIsn={contractData.data.agreement[0].isn}
                userForeignId={userForeignId}
              />
            </Col>
          )}
          {contractData.data.agreement[0].status_name === 'Выпущен' && (
            <>
              <Col>
                <CButton
                  onClick={() => setIsDenounceOpen(true)}
                  type="primary"
                  danger
                >
                  Прекращение
                </CButton>
              </Col>
              <DenounceModal
                contactInfo={contractDataSections.length && contractData}
                denounceModalOpen={isDenounceOpen}
                handleCancel={() => setIsDenounceOpen(false)}
                docIsn={Number(contractIsn)}
              />
            </>
          )}
          <Col>
            <CButton
              href={`/quotation/${contractData?.data.agreement[0].quotation_id}`}
              type="default"
              icon={<LeftOutlined />}
              className="d-flex align-center justify-center"
            >
              Вернуться в котировку
            </CButton>
          </Col>
          {status === 'Подписан' && (
            <Col>
              <CButton
                onClick={saveContractToESBD}
                type="default"
                className="d-flex align-center justify-center"
              >
                Сохранить в ЕСБД
              </CButton>
            </Col>
          )}
        </Row>
      )}

      {contractDataSections?.length ? (
        contractDataSections?.map(([key, array]: any[], index) => {
          if (
            // (Number(contractData?.data?.agreement[0]?.productisn) === 221711 ||
            //   Number(contractData?.data?.agreement[0]?.productisn) ===
            //     471391) &&
            array.length
          ) {
            switch (key) {
              case 'agreement':
                return (
                  <MainContractFormular
                    setStatus={setStatus}
                    fields={array}
                    key={index}
                  />
                );
              case 'agrrole':
                return (
                  <MainContractRole
                    roles={array}
                    key={index}
                    userForeignId={userForeignId}
                    agrIsn={Number(contractIsn)}
                    contractData={contractData}
                  />
                );
              case 'addattr_insurer':
                return (
                  <>
                    <MainContractAdditionalAttributes
                      contractData={contractData}
                      fields={array}
                      key={index}
                      userForeignId={userForeignId}
                      agrIsn={Number(contractIsn)}
                      quotationData={quotationData}
                      productIsn={contractData?.data?.agreement[0]?.productisn}
                    />
                    <MainContractDocument
                      agrIsn={contractData.data.agreement[0].isn}
                      userForeignId={userForeignId}
                      objData={contractData?.data?.agrobject}
                      condData={contractData?.data?.agrcond}
                      onClick={savedAccruals}
                      agreement={contractData?.data?.docs.map((item) => {
                        return {
                          [item?.doc_classisn]: {
                            doc_classisn_name: item.doc_classisn_name,
                            doc_list: item,
                          },
                        };
                      })}
                    />
                  </>
                );
              case 'agrclause':
                return (
                  <MainContractClauses
                    clauses={
                      quotationData?.data[0]?.clauses?.length > 0
                        ? quotationData?.data[0]?.clauses
                        : contractData?.data?.details[0]?.clauses &&
                          changeClausesFormat(
                            contractData?.data?.details[0]?.clauses
                          )
                    }
                    quotationData={quotationData}
                    key={index}
                    productIsn={contractData?.data?.agreement[0]?.productisn}
                    agrIsn={Number(contractIsn)}
                    userForeignId={userForeignId}
                    contractData={contractData}
                  />
                );
              case 'agrobject':
                if (
                  Number(contractData?.data?.agreement[0]?.productisn) ===
                  471391
                ) {
                  if (quotationData) {
                    return (
                      <>
                        <ObjectQuotationDynamic
                          agrIsn={Number(contractIsn)}
                          userForeignId={userForeignId}
                          productIsn={
                            contractData?.data?.agreement[0]?.productisn
                          }
                          objData={contractData?.data?.agrobject}
                          objAttributesList={{
                            additional: quotationData?.data[0].objects_attr[0],
                            under: [],
                          }}
                          objList={quotationData?.data[0].objects}
                          productSelectedQuotation={quotationData.data}
                          getFullData={getContract}
                          agrCond={contractData?.data?.agrcond}
                          objAttrData={{
                            attributes_object_insurer: contractData?.data
                              ?.addattr_object_insurer
                              ? contractData?.data?.addattr_object_insurer
                              : contractData?.data?.addattr_object,
                          }}
                          AdvancedContractorSearch={{}}
                          quotationData={contractData?.data}
                        />
                        <MainContractButtons
                          userForeignId={userForeignId}
                          agrIsn={Number(contractIsn)}
                          premiumSum={
                            contractData?.data?.agreement[0]?.premium_sum_total
                          }
                        />
                      </>
                    );
                  } else {
                    break;
                  }
                } else if (
                  Number(contractData?.data?.agreement[0]?.productisn) ===
                  221711
                ) {
                  return (
                    <>
                      <ObjectVTS
                        agrIsn={Number(contractIsn)}
                        userForeignId={userForeignId}
                        productIsn={
                          contractData?.data?.agreement[0]?.productisn
                        }
                        objData={contractData?.data?.agrobject.filter(
                          (elem) => Number(elem.classisn) === 221713
                        )}
                        subObjData={contractData?.data?.agrobject.filter(
                          (elem) => Number(elem.classisn) === 2118
                        )}
                        objAttributesList={{
                          additional: quotationData?.data[0].objects_attr[0],
                          under: [],
                        }}
                        objList={[
                          {
                            id: 7246,
                            full_name: 'Автотранспорт',
                            foreign_id: 2118,
                            constant: 'cObjClassMotor',
                            isn: 2118,
                            child: [
                              {
                                id: 124,
                                isn: 98550,
                                product_isn: 2694861,
                                class_obj_isn: 2118,
                                class_obj_name: 'Автотранспорт',
                                sub_class_obj_isn: null,
                                sub_class_obj_name: null,
                                remark: null,
                                active_mycent: true,
                              },
                            ],
                            card: [
                              {
                                id: 36,
                                table_name: 'AGROBJCAR',
                                column_name: 'BRAND',
                                data_type: 'NUMBER',
                                comments: '(FK_DICTI) Марка',
                                name: 'Марка',
                                code: 'DICTI',
                                dicti_isn: 2118,
                                order_no: 2,
                              },
                              {
                                id: 125,
                                table_name: 'AGROBJCAR',
                                column_name: 'MODELISN',
                                data_type: 'NUMBER',
                                comments: '(FK_DICTI) Модель',
                                name: 'Модель',
                                code: 'DICTI',
                                dicti_isn: 2118,
                                order_no: 3,
                              },
                              {
                                id: 37,
                                table_name: 'AGROBJCAR',
                                column_name: 'MODIFICATION',
                                data_type: 'VARCHAR2',
                                comments: 'Модификация в текстовом виде',
                                name: 'Модификация',
                                code: 'TEXT',
                                dicti_isn: null,
                                order_no: 4,
                              },
                              {
                                id: 38,
                                table_name: 'AGROBJCAR',
                                column_name: 'RELEASEDATE',
                                data_type: 'DATE',
                                comments: 'Дата выпуска',
                                name: 'Дата выпуска',
                                code: 'DATE',
                                dicti_isn: null,
                                order_no: 5,
                              },
                              {
                                id: 39,
                                table_name: 'AGROBJCAR',
                                column_name: 'DOCSER',
                                data_type: 'VARCHAR2',
                                comments: 'Серия свидетельства о регистрации',
                                name: 'Серия свидетельства',
                                code: 'TEXT',
                                dicti_isn: null,
                                order_no: 6,
                              },
                              {
                                id: 40,
                                table_name: 'AGROBJCAR',
                                column_name: 'DOCNO',
                                data_type: 'VARCHAR2',
                                comments:
                                  'Номер свидетельства о регистрации ТС',
                                name: 'Номер свидетельства',
                                code: 'TEXT',
                                dicti_isn: null,
                                order_no: 7,
                              },
                              {
                                id: 41,
                                table_name: 'AGROBJCAR',
                                column_name: 'DOCDATE',
                                data_type: 'DATE',
                                comments:
                                  'Дата выдачи свидетельства о регистрации ТС',
                                name: 'Дата выдачи свидетельства',
                                code: 'DATE',
                                dicti_isn: null,
                                order_no: 8,
                              },
                              {
                                id: 42,
                                table_name: 'AGROBJCAR',
                                column_name: 'VIN',
                                data_type: 'VARCHAR2',
                                comments: 'VIN',
                                name: 'VIN',
                                code: 'TEXT',
                                dicti_isn: null,
                                order_no: 9,
                              },
                              {
                                id: 43,
                                table_name: 'AGROBJCAR',
                                column_name: 'REGNO',
                                data_type: 'VARCHAR2',
                                comments: 'Регистрационный номер',
                                name: 'Гос.рег.знак',
                                code: 'TEXT',
                                dicti_isn: null,
                                order_no: 10,
                              },
                              {
                                id: 44,
                                table_name: 'AGROBJCAR',
                                column_name: 'BODYID',
                                data_type: 'VARCHAR2',
                                comments: 'Номер кузова',
                                name: 'Кузов №',
                                code: 'TEXT',
                                dicti_isn: null,
                                order_no: 11,
                              },
                              {
                                id: 45,
                                table_name: 'AGROBJCAR',
                                column_name: 'ENGINEID',
                                data_type: 'VARCHAR2',
                                comments: 'Номер двигателя',
                                name: 'Двигатель №',
                                code: 'TEXT',
                                dicti_isn: null,
                                order_no: 12,
                              },
                              {
                                id: 46,
                                table_name: 'AGROBJCAR',
                                column_name: 'CHASSISID',
                                data_type: 'VARCHAR2',
                                comments: 'Номер шасси',
                                name: 'Шасси(рама) №',
                                code: 'TEXT',
                                dicti_isn: null,
                                order_no: 13,
                              },
                              {
                                id: 47,
                                table_name: 'AGROBJCAR',
                                column_name: 'POWER',
                                data_type: 'NUMBER',
                                comments: 'Мощность, л.с.',
                                name: 'Мощность(лс)',
                                code: 'NUMBER',
                                dicti_isn: null,
                                order_no: 14,
                              },
                              {
                                id: 48,
                                table_name: 'AGROBJCAR',
                                column_name: 'VOLUME',
                                data_type: 'NUMBER',
                                comments: 'Объем двигателя, куб.см.',
                                name: 'Объем(см3)',
                                code: 'NUMBER',
                                dicti_isn: null,
                                order_no: 15,
                              },
                              {
                                id: 49,
                                table_name: 'AGROBJCAR',
                                column_name: 'NUMSEATS',
                                data_type: 'NUMBER',
                                comments: 'Число посадочных мест',
                                name: 'Мест',
                                code: 'NUMBER',
                                dicti_isn: null,
                                order_no: 16,
                              },
                              {
                                id: 50,
                                table_name: 'AGROBJCAR',
                                column_name: 'TONNAGE',
                                data_type: 'NUMBER',
                                comments: 'Грузоподъемность, т',
                                name: 'Груз.(т)',
                                code: 'NUMBER',
                                dicti_isn: null,
                                order_no: 17,
                              },
                              {
                                id: 51,
                                table_name: 'AGROBJCAR',
                                column_name: 'MAXMASS',
                                data_type: 'NUMBER',
                                comments: 'Разрешенная максимальная масса, т',
                                name: 'MAX масса(т)',
                                code: 'NUMBER',
                                dicti_isn: null,
                                order_no: 18,
                              },
                              {
                                id: 52,
                                table_name: 'AGROBJCAR',
                                column_name: 'NUMKEY',
                                data_type: 'NUMBER',
                                comments: 'Количество комплектов ключей',
                                name: 'Ключи',
                                code: 'NUMBER',
                                dicti_isn: null,
                                order_no: 19,
                              },
                              {
                                id: 53,
                                table_name: 'AGROBJCAR',
                                column_name: 'OWNERISN',
                                data_type: 'NUMBER',
                                comments: '(FK_SUBJECT) Собственник',
                                name: 'Собственник',
                                code: 'SUBJECT',
                                dicti_isn: null,
                                order_no: 20,
                              },
                              {
                                id: 54,
                                table_name: 'AGROBJCAR',
                                column_name: 'OWNERSHIPISN',
                                data_type: 'NUMBER',
                                comments: '(FK_DICTI) Право владения',
                                name: 'Право владения',
                                code: 'DICTI',
                                dicti_isn: 2019,
                                order_no: 21,
                              },
                              {
                                id: 55,
                                table_name: 'AGROBJCAR',
                                column_name: 'COUNTRYISN',
                                data_type: 'NUMBER',
                                comments:
                                  '(FK_DICTI) Страна регистрации транспортного средства',
                                name: 'Страна регистрации',
                                code: 'DICTI',
                                dicti_isn: 9434,
                                order_no: 22,
                              },
                              {
                                id: 56,
                                table_name: 'AGROBJCAR',
                                column_name: 'COLORISN',
                                data_type: 'NUMBER',
                                comments: '(FK_DICTI) Цвет',
                                name: 'Цвет',
                                code: 'DICTI',
                                dicti_isn: 2028,
                                order_no: 23,
                              },
                              {
                                id: 57,
                                table_name: 'AGROBJCAR',
                                column_name: 'CARUSEISN',
                                data_type: 'NUMBER',
                                comments:
                                  '(FK_DICTI) Цель использования транспортного средства',
                                name: 'Цель использования',
                                code: 'DICTI',
                                dicti_isn: 2672,
                                order_no: 24,
                              },
                              {
                                id: 58,
                                table_name: 'AGROBJCAR',
                                column_name: 'MULTIDRIVE',
                                data_type: 'CHAR',
                                comments:
                                  'Y - мультидрайв (нет ограничения по лицам, допущенным к управлению)',
                                name: 'Без ограничений по лицам допущенным к управлению',
                                code: 'CHECKBOX',
                                dicti_isn: null,
                                order_no: 25,
                              },
                              {
                                id: 59,
                                table_name: 'AGROBJCAR',
                                column_name: 'FOREIGN',
                                data_type: 'CHAR',
                                comments: 'Признак: иномарка/нашамарка (Y/N)',
                                name: 'Иномарка',
                                code: 'CHECKBOX',
                                dicti_isn: null,
                                order_no: 26,
                              },
                              {
                                id: 60,
                                table_name: 'AGROBJCAR',
                                column_name: 'CATEGORYISN',
                                data_type: 'NUMBER',
                                comments:
                                  '(FK_DICTI) Категория транспортного средства ("А","B","C" и т.д.)',
                                name: 'Категория ТС',
                                code: 'DICTI',
                                dicti_isn: 2018,
                                order_no: 27,
                              },
                              {
                                id: 61,
                                table_name: 'AGROBJCAR',
                                column_name: 'TERRITORYISN',
                                data_type: 'NUMBER',
                                comments:
                                  '(FK_CITY) Территория (населенный пункт) преимущественного использования для ОСАГО',
                                name: 'Территория преимущественного использования',
                                code: 'CITY',
                                dicti_isn: null,
                                order_no: 28,
                              },
                              {
                                id: 62,
                                table_name: 'AGROBJCAR',
                                column_name: 'RIGHTWHEEL',
                                data_type: 'CHAR',
                                comments: 'Правый руль (Y)',
                                name: 'Правый руль',
                                code: 'CHECKBOX',
                                dicti_isn: null,
                                order_no: 29,
                              },
                              {
                                id: 63,
                                table_name: 'AGROBJCAR',
                                column_name: 'DATEBEG',
                                data_type: 'DATE',
                                comments: 'Дата начала эксплуатации ТС',
                                name: 'Используется с',
                                code: 'DATE',
                                dicti_isn: null,
                                order_no: 30,
                              },
                              {
                                id: 64,
                                table_name: 'AGROBJCAR',
                                column_name: 'PROBEG',
                                data_type: 'NUMBER',
                                comments: 'Пробег, км',
                                name: 'Пробег(км)',
                                code: 'NUMBER',
                                dicti_isn: null,
                                order_no: 31,
                              },
                              {
                                id: 65,
                                table_name: 'AGROBJCAR',
                                column_name: 'REALPRICE',
                                data_type: 'NUMBER',
                                comments: 'Действительная стоимость ТС',
                                name: 'Действительная стоимость',
                                code: 'NUMBER',
                                dicti_isn: null,
                                order_no: 32,
                              },
                              {
                                id: 66,
                                table_name: 'AGROBJCAR',
                                column_name: 'PASSENGER',
                                data_type: 'NUMBER',
                                comments: 'Пассажировместимость',
                                name: 'П-вмест',
                                code: 'NUMBER',
                                dicti_isn: null,
                                order_no: 33,
                              },
                              {
                                id: 67,
                                table_name: 'AGROBJCAR',
                                column_name: 'USETRAILER',
                                data_type: 'CHAR',
                                comments: 'ТС используется с прицепом (Y/N)',
                                name: 'С прицепом',
                                code: 'CHECKBOX',
                                dicti_isn: null,
                                order_no: 34,
                              },
                              {
                                id: 68,
                                table_name: 'AGROBJCAR',
                                column_name: 'USESPECIALSIGNAL',
                                data_type: 'CHAR',
                                comments:
                                  'ТС оборудовано устройствами для подачи специальных световых и звуковых сигналов (Y/N)',
                                name: 'Спец.сигналы',
                                code: 'CHECKBOX',
                                dicti_isn: null,
                                order_no: 35,
                              },
                            ],
                          },
                        ]}
                        productSelectedQuotation={quotationData?.data}
                        getFullData={getContract}
                        agrCond={[]}
                        objAttrData={{
                          attributes_object_insurer:
                            contractData?.data?.addattr_object_insurer,
                        }}
                        AdvancedContractorSearch={{}}
                        quotationData={contractData?.data}
                      />
                      <MainContractButtons
                        userForeignId={userForeignId}
                        agrIsn={Number(contractIsn)}
                        premiumSum={
                          contractData?.data?.agreement[0]?.premium_sum_total
                        }
                      />
                    </>
                  );
                } else {
                  return (
                    <MainContractObjects
                      objects={array}
                      quotationData={contractData}
                      key={index}
                    />
                  );
                }

              case 'agrsum':
                return (
                  <MainContractAccruals
                    agrsums={array}
                    quotationData={contractData}
                    key={index}
                  />
                );
              case 'servreq':
                return (
                  <MainContractRequest
                    userForeignId={userForeignId}
                    agrIsn={Number(contractIsn)}
                    quotationData={contractData?.data}
                    productIsn={contractData?.data?.agreement[0]?.productisn}
                  />
                );
            }
          } else if (key === 'servreq') {
            return (
              <MainContractRequest
                userForeignId={userForeignId}
                agrIsn={Number(contractIsn)}
                quotationData={undefined}
                productIsn={contractData?.data?.agreement[0]?.productisn}
              />
            );
          }
        })
      ) : contractData ? (
        <div>Нет данных</div>
      ) : (
        <CustomLoader spinning={true} />
      )}
    </div>
  );
});

export default MainContract;
