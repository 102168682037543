import React from 'react';
import { Segmented } from 'antd';
import './CSegmented.scss';

const CSegmented = (props) => {
  return (
    <Segmented {...props} className={`c-segmented ${props.className || ''}`} />
  );
};

export default CSegmented;
