import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Typography,
} from 'antd';
import PinIcon from '../../../assets/pinning.svg';
import Dragger from 'antd/es/upload/Dragger';
import React, { useEffect, useState } from 'react';
import api from '@modules/Quotation/services';
import useFormSubmit from '@modules/Quotation/hooks/CommonMethods/MainComponents/useFormSubmit';
import { IFile } from '@modules/Agreement/type';
import {
  DeleteOutlined,
  DownloadOutlined,
  FileTwoTone,
} from '@ant-design/icons';
import useQuotation from '@modules/Quotation/hooks/useQuotation';

interface IProps {
  isOpenModal: boolean;
  closeModalHandler: () => void;
  agrIsn: number | undefined;
  userForeignId: number;
  nshdIsn: number;
  nshdRemark: string;
}

const { Title } = Typography;

const ModalCustomTemplateForm = ({
  isOpenModal,
  closeModalHandler,
  agrIsn,
  userForeignId,
  nshdIsn,
  nshdRemark,
}: IProps) => {
  const [customTemplateForm] = Form.useForm();
  const [currentFileList, setCurrentFileList] = useState<any[]>([]);
  console.log(1);

  const { fileListSend } = useFormSubmit();
  const { nshdFileList, setNshdFileList } = useQuotation();

  const handleSubmit = async () => {
    closeModalHandler();

    await api.saveQuotation.sendNshd({
      isn: String(agrIsn),
      user_isn: String(userForeignId),
      remark: customTemplateForm.getFieldValue('notes'),
    });
  };
  const uploadFile = async (options) => {
    const { onSuccess } = options;

    try {
      const data = fileListSend(
        currentFileList,
        userForeignId,
        Number(nshdIsn)
      );
      data.append('type', 'D');
      const result = await api.saveQuotation.fileSave(data);

      onSuccess(
        notification.success({
          message: 'Файл успешно загружен',
        })
      );

      setNshdFileList([
        ...nshdFileList,
        ...currentFileList.map((fileItem) => {
          return {
            file_name: fileItem.name,
            file_path: result.data.file_path,
            isn: result.data.file_isn,
          };
        }),
      ]);

      setCurrentFileList([]);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: 'При загрузке файла произошла ошибка. Повторите загрузку',
      });
    }
  };
  const beforeUpload = (file: any, newFilesList: any) => {
    setCurrentFileList([...currentFileList, ...newFilesList]);
  };

  const handleRemove = async (fileData: any = undefined) => {
    event?.preventDefault();
    if (!fileData) return;
    try {
      await api.saveQuotation.contractorFileArchive({
        user_isn: userForeignId,
        isn: fileData.isn,
      });
      setNshdFileList((fileList3) => {
        const index = fileList3.findIndex((item) => item.isn === fileData.isn);
        const newFileList = fileList3.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      notification.success({
        message: 'Файл успешно удален',
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: 'При удалении файла произошла ошибка, попробуйте снова',
      });
    }
  };
  const base64toFile = (b64Data, filename, contentType, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new File(byteArrays, `"${filename}"`, { type: contentType });
  };

  const downloadFile = async (fileData) => {
    event?.preventDefault();
    const { file_path, file_name } = fileData;
    if (!file_path && !file_name) return;

    try {
      const result = await api.saveQuotation.fileDownload({
        file_path,
        file_name,
      });

      if (result.status === 200) {
        const file = base64toFile(
          result.data.file,
          file_name,
          result.data.contentType
        );
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.download = file_name;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: 'При скачивании файла произошла ошибка. Попробуйте снова',
      });
    }
  };

  const UploadedFiles = () => {
    return (
      <Col span="24">
        <Title level={5} type="secondary">
          Вложенные документы
        </Title>
        <div className="mb-2">
          <Space wrap>
            {nshdFileList?.map(
              ({ file_path, file_name, isn }: IFile, index) => {
                return (
                  <div className="files-quotation__item" key={index}>
                    <div className="files-quotation__icon">
                      <FileTwoTone />
                    </div>
                    <p className="files-quotation__filename">{file_name}</p>
                    <Button
                      className="files-quotation__item-btn ml-1"
                      title="Скачать"
                      shape="circle"
                      icon={<DownloadOutlined />}
                      onClick={() => downloadFile({ file_path, file_name })}
                    />
                    <Button
                      className="files-quotation__item-btn"
                      title="Удалить"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemove({ isn })}
                    />
                  </div>
                );
              }
            )}
          </Space>
        </div>
      </Col>
    );
  };

  const handleRemoveFile = (file) => {
    const updatedFileList = currentFileList.filter((f) => f !== file);
    setCurrentFileList(updatedFileList);
  };

  useEffect(() => {
    customTemplateForm.setFieldsValue({
      notes: nshdRemark,
    });
  }, [isOpenModal]);

  return (
    <Modal
      forceRender
      title="Нестандартный шаблон договора"
      centered
      open={isOpenModal}
      onCancel={() => closeModalHandler()}
      footer={null}
    >
      <Form form={customTemplateForm}>
        <Col span={24}>
          <Form.Item name="notes">
            <Input size="large" placeholder="Примечание" />
          </Form.Item>
        </Col>
        <Col span={24}>{!!nshdFileList.length && <UploadedFiles />}</Col>
        <Col span={24}>
          <Form.Item>
            <Dragger
              className="mt-3"
              listType="text"
              name="files"
              customRequest={uploadFile}
              beforeUpload={beforeUpload}
              fileList={currentFileList}
              onRemove={(e) => handleRemoveFile(e)}
            >
              <img src={PinIcon} alt="icon" className="mr-3" />
              Прикрепление файла
            </Dragger>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" size="large" block onClick={handleSubmit}>
              ОК
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};

export default ModalCustomTemplateForm;
