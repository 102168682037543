import { Modal, Typography } from 'antd';
import SearchInvestDocuments from '@modules/Edo/ModalInvestDecisions/SearchIvestDocuments/SearchInvestDocuments';
import { HandbookListType } from '@hooks/useHandbook';
import { ColleaguesListType } from '@hooks/useColleagues';

interface IPropsModalInvestDecisions {
	isModalOpen: boolean;
	handleCancel: () => void;
	handbookList: HandbookListType;
	colleaguesList: ColleaguesListType;
	departmentList: ColleaguesListType;
}

const { Title } = Typography;
const ModalInvestDecisions = ({
	                              isModalOpen,
	                              handleCancel,
	                              handbookList,
	                              colleaguesList,
	                              departmentList
                              }: IPropsModalInvestDecisions) => {
	return (
		<Modal
			title={<Title level={4}>Выбор инвест решений к документу</Title>}
			open={isModalOpen}
			onCancel={handleCancel}
			footer={null}
			centered
			width="70%"
		>
			<SearchInvestDocuments
				handbookList={handbookList}
				colleaguesList={colleaguesList}
				departmentList={departmentList}
			/>
		</Modal>
	);
};

export default ModalInvestDecisions;