import React from 'react';
import { Upload , Button} from 'antd';
import './CFiles.scss';
import downloadFile from '../../../assets/download-file.svg';
import deleteFile from '../../../assets/delete-file.svg'
import { UploadOutlined , PlusOutlined , FileOutlined} from '@ant-design/icons';

const CFiles = (props) => {
  return (
    <div className="c-files">
        <div className='c-files-info'>
            <Button>
                <FileOutlined/>
            </Button>
            {props.doc.filename && (<p>{props.doc.filename.length < 10 ? props.doc.filename : props.doc.filename.split('').filter((letter , index) => index < 10).join('')+'...'}</p>)}
            {props.doc.file_name && (<p>{props.doc.file_name.length < 10 ? props.doc.file_name : props.doc.file_name.split('').filter((letter , index) => index < 10).join('')+'...'}</p>)}
            
        </div>
        <div className='c-files-action'>
            <img src={downloadFile} alt="" onClick={() => props.downloadFile(props.doc)}/>
            <img src={deleteFile} alt="" onClick={() => props.deleteFile(props.doc)}/>
        </div>
    </div>
  );
};

export default CFiles;
