import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface Props {
  children: React.ReactNode;
}

function PrivateRoute({ children }: Props) {
  const auth = useAuth();
  const location = useLocation();
  const url = new URLSearchParams();
  url.set('redirect', location.pathname + location.search);

  if (auth.token) {
    return <>{children}</>;
  }

  return <Navigate to={{ pathname: '/login', search: url.toString() }} />;
}

export default PrivateRoute;
