import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import './Container.scss';

export default function LayoutContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const [style, setStyle] = useState({});
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('showSide') === 'false') {
      setStyle({ marginLeft: 0, marginTop: '30px' });
    }
  }, []);
  return (
    <Layout className="layout-container" style={style}>
      {children}
    </Layout>
  );
}
