import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Table } from 'antd';

const EditableContext = React.createContext<any>(null);

interface Item {
  key: number;
  class_name: string;
  date_pay: string;
  date_cross: string;
  amount: string;
  obj_name: string;
  agr_isn: string;
}

interface DataType {
  key: number;
  class_name: string;
  date_pay: string;
  date_cross: string;
  amount: string;
  obj_name: string;
  agr_isn: string;
}

const EditableRow: React.FC<any> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          onKeyDown={(e) => {
            // Проверяем, что введенный символ является числом или backspace
            if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
              e.preventDefault(); // Предотвращаем ввод недопустимых символов
            }
          }}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const MainContractQuatations = ({ status, agrsums, getAgrsumsList }: any) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [statusField, setStatusField] = useState<boolean>();

  useEffect(() => {
    setStatusField(!status.field_status);
  }, [status]);

  useEffect(() => {
    if (agrsums) {
      const addNew = (arr) => {
        return [...arr].map((el, index) => {
          el['key'] = index;
          return el;
        });
      };
      setDataSource(addNew(agrsums));
      console.log('formated_agrsums', dataSource);
    }
  }, [agrsums]);

  const defaultColumns: any = [
    {
      title: 'Тип',
      dataIndex: 'class_name',
    },
    {
      title: 'Дата',
      dataIndex: 'date_cross',
    },
    {
      title: 'Дата оплаты',
      dataIndex: 'date_pay',
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      editable: statusField,
    },
    {
      title: 'Обьект',
      dataIndex: 'obj_name',
    },
    {
      title: 'Исн согласование',
      dataIndex: 'agr_isn',
    },
  ];

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    // ADD_INVOICE_AMOUNT_ISN - переминуем потом
    setDataSource(newData);
    getAgrsumsList(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Table // как передать событие из этого компонента
        scroll={{ x: 1200, y: 200 }}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default MainContractQuatations;
