import auth from './endpoints/auth';
import event from './endpoints/event';
import handbook from './endpoints/handbook';
import colleagues from './endpoints/colleagues';
import edo from './endpoints/edo';
import agreement from './endpoints/agreement';
import catchErrorCustomAxios from './catchErrors';
import centcoin from './endpoints/centcoin';
import doc from './endpoints/doc';

const allEndPoints = {
  auth,
  event,
  handbook,
  colleagues,
  edo,
  agreement,
  catchErrorCustomAxios,
  centcoin,
  doc,
};

export default allEndPoints;
