import axios from '@services/api/axios';

interface IFile {
  id: number | undefined;
  recruiting_id: number | undefined;
  fileName: string | undefined;
}

const api = {
  getOptionValues: (companyId: number) =>
    axios.post('/recruiting-request', { company_id: companyId }),
  getMyApplicationData: () => axios.get('/recruiting'),
  getApplicationById: (id: number) => axios.get(`/recruiting/${id}`),
  getInterviewCandidate: () => axios.get('/recruiting-candidate'),
  getCandidateById: (id: string) => axios.get(`/recruiting-candidate/${id}`),
  getResult: () => axios.post('/recruiting-result'),
  getFile: (data: IFile) =>
    axios.post('/download/resume', data, { responseType: 'blob' }),

  getResultFile: () =>
    axios.post('/download/recruiting', {}, { responseType: 'blob' }),

  saveNewApplication: (data: any) => axios.post('/recruiting', data),
  saveApplicationCandidate: (data: any) =>
    axios.post('recruiting-candidate/save', data),

  updApplication: (id: string | undefined, data: any) =>
    axios.put(`/recruiting/${id}`, data),
  updCandidate: (id: string | undefined, data: any) =>
    axios.put(`/recruiting-candidate/save/${id}`, data),
  updCanditeFile: (data: any) =>
    axios.post('/recruiting-candidate/update-file', data),
  postData: (data) => axios.post('/ideaOffer', data),
};

export default api;
