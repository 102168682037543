import React, { memo, useEffect } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { IDateQuotationItem } from '../Interfaces/DateQuotation/index.interface';
import dayjs from 'dayjs';
import SetupFieldsJournal from './SetupFieldsJournal';
import TreeSelectKias from '@modules/TreeSelect/TreeSelectKias';
import { TypeAction } from '../providers/DateReducer';
import useQuotation from '../hooks/useQuotation';
import useQuotationJournal from '../hooks/useQuotationJournal';
import useStatuses from '../hooks/CommonMethods/MainComponents/useStatuses';

const { Option } = Select;

export const enum STATUS_ISN {
  TOBEAGREED = 223369,
  AGREED = 223370,
}

export const isFieldDisabled = (statusIsn: number) => {
  return statusIsn === STATUS_ISN.AGREED || statusIsn === STATUS_ISN.TOBEAGREED;
};

const DateQuotation: React.FC<IDateQuotationItem> = memo(
  ({ userForeignId }) => {
    const {
      colleaguesKiasList,
      isLoadingColleaguesKiasList,
      productSelectedQuotation,
      dispatchDate,
    } = useQuotation();

    const { state } = useQuotationJournal();

    const dateForm = Form.useFormInstance();

    const { statusList } = useStatuses();

    const openOrCloseCard = () => {
      const showCardValue = dateForm.getFieldValue('showCard');
      dateForm.setFieldsValue({
        ['showCard']: !showCardValue,
      });
    };

    return (
      <>
        <Card
          title={
            <Button
              type="link"
              style={{
                border: 'none',
                background: 'none',
                padding: 0,
                width: '100%',
                textAlign: 'left',
                whiteSpace: 'normal',
                height: 'auto',
              }}
              onClick={openOrCloseCard}
            >
              <span
                style={{
                  color: '#0057FF',
                  fontSize: 20,
                  fontWeight: 600,
                  lineHeight: 1.4,
                  whiteSpace: 'break-spaces',
                }}
              >
                Дата начала / окончания договора
              </span>
            </Button>
          }
          extra={
            <Button
              type="link"
              style={{ border: 'none', background: 'none', padding: 0 }}
              onClick={openOrCloseCard}
              icon={<DownOutlined />}
            ></Button>
          }
          hoverable
          style={{
            marginTop: 20,
          }}
        >
          <Form.Item initialValue={false} name="showCard" hidden noStyle>
            <Input />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.dateForm !== curValues.dateForm;
            }}
            noStyle
          >
            {({ getFieldValue }) => {
              const showCardValue = getFieldValue('showCard');

              if (showCardValue === true) {
                return (
                  <Row gutter={24}>
                    <Col span={8} xs={24} xl={8}>
                      <Form.Item
                        name={TypeAction.DATE_SIGN}
                        label="Дата подписания"
                        initialValue={dayjs()}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          name="date"
                          onChange={(e, date) => {
                            dispatchDate({
                              type: TypeAction.DATE_SIGN,
                              payload: dayjs(date).format('YYYY-MM-DD'),
                            });
                          }}
                          disabled={isFieldDisabled(state.agrDate.status_id)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} xs={24} xl={8}>
                      <Form.Item
                        name={TypeAction.STATUS_ISN}
                        label="Статус"
                        initialValue={223368}
                      >
                        <Select
                          onChange={(value) => {
                            dispatchDate({
                              type: TypeAction.STATUS_ISN_KIAS,
                              payload: value,
                            });
                          }}
                        >
                          {statusList.length &&
                            statusList?.map((element) => {
                              return (
                                <Option
                                  value={element.foreign_id}
                                  key={element.foreign_id}
                                >
                                  {element.full_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} xs={24} xl={8}>
                      <Form.Item
                        initialValue={dayjs()}
                        name={TypeAction.DATE_BEG}
                        label="Дата начала"
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          onChange={(e, date) => {
                            dispatchDate({
                              type: TypeAction.DATE_BEG,
                              payload: dayjs(date).format('YYYY-MM-DD'),
                            });
                          }}
                          disabled={isFieldDisabled(state.agrDate.status_id)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} xs={24} xl={8}>
                      <Form.Item
                        name={TypeAction.DATE_END}
                        label="Дата окончания"
                        initialValue={dayjs().add(1, 'y').subtract(1, 'd')}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          name="date"
                          onChange={(e, date) => {
                            dispatchDate({
                              type: TypeAction.DATE_END,
                              payload: dayjs(date).format('YYYY-MM-DD'),
                            });
                          }}
                          disabled={isFieldDisabled(state.agrDate.status_id)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} xs={24} xl={8}>
                      <Form.Item
                        name="agreementDate_typeQuotation"
                        label="Вид котировки"
                        initialValue={220603}
                      >
                        <Select
                          disabled={isFieldDisabled(state.agrDate.status_id)}
                        >
                          <Option value={220603}>Первичная</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} xs={24} xl={8}>
                      {/* 9813 tenge */}
                      <Form.Item
                        name={TypeAction.CURRENCY}
                        label="Валюта"
                        initialValue={9813}
                      >
                        <Select
                          onChange={(value) => {
                            dispatchDate({
                              type: TypeAction.CURRENCY,
                              payload: value,
                            });
                          }}
                          disabled={isFieldDisabled(state.agrDate.status_id)}
                        >
                          {productSelectedQuotation?.map((element) =>
                            element.currency?.map((item) => {
                              return (
                                <Option
                                  value={Number(item.curr_isn)}
                                  key={Number(item.curr_isn)}
                                >
                                  {item.curr_name}
                                </Option>
                              );
                            })
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} xs={24} xl={8}>
                      {/* 9813 tenge */}
                      <Form.Item
                        name={TypeAction.REMARK}
                        label="Особые условия"
                      >
                        <Input
                          onChange={({ target }) => {
                            dispatchDate({
                              type: TypeAction.REMARK,
                              payload: target.value,
                            });
                          }}
                          disabled={isFieldDisabled(state.agrDate.status_id)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={16}>
                      <Form.Item
                        name={TypeAction.USER_ISN}
                        label={`Куратор`}
                        rules={[
                          {
                            required: true,
                            message: 'Куратор не выбран!',
                          },
                        ]}
                        initialValue={userForeignId}
                      >
                        <TreeSelectKias
                          colleaguesKiasList={colleaguesKiasList}
                          disabled={
                            isLoadingColleaguesKiasList ||
                            isFieldDisabled(state.agrDate.status_id)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              }
            }}
          </Form.Item>
        </Card>

        {state.agrDate.user_id !== 0 ? <SetupFieldsJournal /> : null}
      </>
    );
  }
);

export default DateQuotation;
