import {
  Row,
  Col,
  Form,
  notification,
  Checkbox,
  Select,
  TreeSelect,
} from 'antd';
import TreeSelectKias from '@modules/TreeSelect/TreeSelectKias';
import CSelect from '@components/UI/select/CSelect';
import CDatePicker from '@components/UI/datepicker/CDatePicker';
import useQuotation from '../hooks/useQuotation';
import { useEffect, useMemo, useState } from 'react';
import CInput from '@components/UI/input/CInput';
import useAuth from '@hooks/useAuth';
import api from '@modules/Quotation/services/saveQuotation';
import dayjs from 'dayjs';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import QuotationTreeSelect from '../MainQuotation/QuotationTreeSelect';
import CTreeSelect from '@components/UI/treeselect/CTreeSelect';
import _ from 'lodash';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import SubjectCard from '../ObjectQuotation/ObjectCardDynamic/SubjectCard';
import DateMask from '@modules/Edo/DateMask';
const NewAdditionalAttributesFields = ({
  agrIsn,
  userForeignId,
  productIsn,
  attributesList,
  contractorInfo,
  objectForm,
  isJuridical,
  dicti,
  quotationData,
  isShowAttr,
  setIsShowAttr,
}) => {
  const {
    productSelectedQuotation,
    setAgrAttributes,
    agrAttributes,
    colleaguesKiasList,
    agrContractor,
    agrCond,
    setAgrCond,
    setIsCustomTemplate,
    stateDateQuotation,
  } = useQuotation();
  const auth = useAuth();

  const { Option } = Select;
  const { TreeNode } = TreeSelect;
  const additionalAttributesForm = Form.useFormInstance();

  // Возможно и не нужно, отправлять массивом при сохранении
  // const mrpField = Form.useWatch('2488591', additionalAttributesForm);

  // useEffect(() => {
  //   if (mrpField) {
  //     onChange(2488591, mrpField, 'DICTI');
  //   }
  // }, [mrpField]);

  /**
   * Записываем атрибуты в agrAttributes
   * @param attrIsn
   * @param attrValue
   * @param type
   */
  const setAttributes = (attrIsn, attrValue, type?) => {
    const cloneAgrAttributes = cloneDeep(agrAttributes);
    let foundItem;
    Object.keys(cloneAgrAttributes).map((key) => {
      cloneAgrAttributes[key].find((item) => {
        if (String(item.attr_isn) === String(attrIsn)) {
          foundItem = item;
        }
      });
    });
    if (foundItem) {
      foundItem.val_n = Number(attrValue);
      foundItem.val_c = String(attrValue);
      foundItem.value = attrValue;
      foundItem.attr_isn_code = type;
    } else {
      cloneAgrAttributes.push({
        attr_isn: attrIsn,
        class_isn: 220565,
        obj_isn: agrIsn,
        attr_isn_code: type,
        user_isn: userForeignId,
        val_c: String(attrValue),
        val_n: Number(attrValue),
        value: attrValue,
      });
    }

    setAgrAttributes(cloneAgrAttributes);
  };

  let attributesTimeout;
  const onChange = async (
    attr_isn,
    val,
    type,
    info?: any,
    callback: any = undefined
  ) => {
    clearTimeout(attributesTimeout);
    if (Number(attr_isn) === 2656251) {
      if (Number(val) === 482291) {
        setIsShowAttr(true);
      } else {
        setIsShowAttr(false);
      }
    }

    attributesTimeout = setTimeout(async () => {
      let requestBody = {};
      switch (type) {
        case 'DATETIME':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_d: dayjs(val).format('YYYY.MM.DD HH:mm:ss'),
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DATE':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_d: dayjs(val).format('YYYY.MM.DD'),
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'CHECKBOX':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val.target.checked,
                val_n: Number(val.target.checked),
                value: val.target.checked,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'TEXT':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val.target.value,
                val_n: Number(val.target.value),
                value: val.target.value,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DICTI':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val,
                val_n: val,
                value: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DICTI_CODENAME':
          if (Number(attr_isn) === 2488601) {
            additionalAttributesForm.setFieldValue(828061, info[0]);
            requestBody = {
              agr_attributes: [
                {
                  attr_isn: attr_isn,
                  class_isn: 220565,
                  val_c: val,
                  val_n: val,
                  value: val,
                  obj_isn: String(agrIsn),
                  user_isn: userForeignId,
                },
                {
                  attr_isn: 828061,
                  class_isn: 220565,
                  val_c: info[0],
                  value: info[0],
                  obj_isn: String(agrIsn),
                  user_isn: userForeignId,
                },
              ],
            };
          } else {
            requestBody = {
              agr_attributes: [
                {
                  attr_isn: attr_isn,
                  class_isn: 220565,
                  val_c: val,
                  val_n: val,
                  value: val,
                  obj_isn: String(agrIsn),
                  user_isn: userForeignId,
                },
              ],
            };
          }
          break;
        case 'SUBJECT':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_n: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'NUMBER':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_n: val.target.value.length > 0 ? +val.target.value : null,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DEPTEMPL':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_n: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        default:
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                value: val.target.value,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
      }
      try {
        console.log('requestBody', requestBody);
        await api.attributeSave(requestBody);
      } catch (error) {
        let message;
        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }
        notification.error({
          message: 'Ошибка',
          description: message,
        });
      }

      // Обновляем объект атрибутов
      const attrValue = val?.target?.value || val;
      setAttributes(attr_isn, attrValue);

      // Передаем выбранное значение во внешнюю функцию
      if (callback) {
        callback(val);
      }
    }, 400);
  };

  useEffect(() => {
    setIsCustomTemplate(additionalAttributesForm.getFieldValue('499591'));
    if (auth && agrIsn) {
      (async () => {
        const manager = agrAttributes?.filter(
          (item) => item.attr_isn == 2386051
        );
        if (manager.length == 0) {
          additionalAttributesForm.setFieldValue(
            '2386051',
            auth.user?.data?.user_info?.foreign_id
          );
          try {
            await api.attributeSave({
              agr_attributes: [
                {
                  attr_isn: 2386051,
                  class_isn: 220565,
                  val_n: auth.user?.data?.user_info?.foreign_id,
                  obj_isn: String(agrIsn),
                  user_isn: userForeignId,
                },
              ],
            });
          } catch (error) {
            let message;
            if (axios.isAxiosError(error)) {
              message = error.message;
            } else {
              message = String(error);
            }
            notification.error({
              message: 'Ошибка',
              description: message,
            });
          }
        }
      })();
    }
  }, [auth, agrIsn]);

  /**
   * Форматирует категории ВОО из бека в формат для TreeSelect
   * @param categoryItem - элемент списка категорий
   * @param selectable { boolean } - возможность выбора элемента TreeSelect
   */
  const renderTreeNodesCategory = (categoryItem: any, selectable = false) => {
    return (
      <TreeNode
        value={categoryItem.id || categoryItem.isn}
        key={categoryItem.id || categoryItem.isn}
        title={categoryItem.full_name}
        selectable={selectable}
      >
        {_.uniqBy(categoryItem?.children, function (e: any) { 
          return e.id || e.isn;
        }).map((subCategoryItem) =>
          renderTreeNodesCategory(subCategoryItem, true)
        )}
      </TreeNode>
    );
  };
  DateMask();
  return (
    <CustomLoader spinning={!productSelectedQuotation.length}>
      <Row style={{ minHeight: 100 }} gutter={18}>
        {productSelectedQuotation[0]?.attributes[0].map((item) => {
          switch (item.attr_type) {
            case 'DICTI':
            case 'DICTI_SQL':
            case 'SQL':
            case 'DICTI_CODENAME':
              const findItem =
                dicti &&
                dicti[item.attr_isn]?.children?.filter(
                  (item) => item.n_children
                );
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    validateTrigger="onChange"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    {findItem?.length > 0 ? (
                      <QuotationTreeSelect
                        placeholder={item.attr_name}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                        size="large"
                        onChange={(e, info) => {
                          onChange(item.attr_isn, e, 'DICTI_CODENAME', info);
                          setIsCustomTemplate(e);
                        }}
                        list={dicti[item.attr_isn]?.children}
                      />
                    ) : (
                      <CSelect
                        showSearch
                        placeholder={item.attr_name}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        size="large"
                        onChange={(e) => {
                          onChange(item.attr_isn, e, 'DICTI_CODENAME');
                          setIsCustomTemplate(e);
                        }}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                      >
                        {dicti &&
                        dicti[item.attr_isn] &&
                        dicti[item.attr_isn].children
                          ? dicti[item.attr_isn].children.map((el) => {
                              return (
                                <Option
                                  selected={
                                    dicti[item.attr_isn].children.length === 1
                                  }
                                  value={el.foreign_id ? el.foreign_id : el.isn}
                                  key={el.foreign_id ? el.foreign_id : el.isn}
                                >
                                  {el.full_name}
                                </Option>
                              );
                            })
                          : dicti &&
                            dicti[item.attr_isn] &&
                            dicti[item.attr_isn].map((el) => {
                              return (
                                <Option
                                  value={el.foreign_id ? el.foreign_id : el.isn}
                                  key={el.foreign_id ? el.foreign_id : el.isn}
                                >
                                  {el.full_name}
                                </Option>
                              );
                            })}
                      </CSelect>
                    )}
                  </Form.Item>
                </Col>
              );
            case 'TEXT': {
              if (
                Number(item.attr_isn) === 2578281 ||
                Number(item.attr_isn) === 2580491
              ) {
                if (isShowAttr) {
                  return (
                    <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                      <Form.Item
                        label={item.attr_name}
                        name={item.attr_isn}
                        className="bold-label"
                        rules={[
                          {
                            required:
                              typeof item.required === 'boolean'
                                ? item.required
                                : false,
                            message: 'Поле обязательно для заполнения',
                          },
                        ]}
                      >
                        <CInput
                          placeholder={item.attr_name}
                          size="large"
                          onChange={(e) => onChange(item.attr_isn, e, 'TEXT')}
                          disabled={
                            checkDisabledField(stateDateQuotation.status_isn) ||
                            item.readonly
                          }
                        />
                      </Form.Item>
                    </Col>
                  );
                } else {
                  return null;
                }
              } else {
                if (item.active_mycent) {
                  return (
                    <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                      <Form.Item
                        label={item.attr_name}
                        name={item.attr_isn}
                        className="bold-label"
                        rules={[
                          {
                            required:
                              typeof item.required === 'boolean'
                                ? item.required
                                : false,
                            message: 'Поле обязательно для заполнения',
                          },
                        ]}
                      >
                        <CInput
                          placeholder={item.attr_name}
                          size="large"
                          onChange={(e) => onChange(item.attr_isn, e, 'TEXT')}
                          disabled={
                            checkDisabledField(stateDateQuotation.status_isn) ||
                            item.readonly
                          }
                        />
                      </Form.Item>
                    </Col>
                  );
                }
                return '';
              }
            }
            case 'DEPTEMPL': {
              if (item.active_mycent) {
                return (
                  <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                    <Form.Item
                      label={item.attr_name}
                      name={item.attr_isn}
                      className="bold-label"
                      rules={[
                        {
                          required:
                            typeof item.required === 'boolean'
                              ? item.required
                              : false,
                          message: 'Поле обязательно для заполнения',
                        },
                      ]}
                    >
                      <TreeSelectKias
                        colleaguesKiasList={colleaguesKiasList}
                        placeholder={item.attr_name}
                        style={{ width: '100%' }}
                        size="large"
                        onChange={(e) => onChange(item.attr_isn, e, 'DEPTEMPL')}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                      />
                    </Form.Item>
                  </Col>
                );
              }
              return '';
            }
            case 'DATETIME': {
              if (item.active_mycent) {
                return (
                  <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                    <Form.Item
                      label={item.attr_name}
                      name={item.attr_isn}
                      className="bold-label"
                      rules={[
                        {
                          required:
                            typeof item.required === 'boolean'
                              ? item.required
                              : false,
                          message: 'Поле обязательно для заполнения',
                        },
                      ]}
                    >
                      <CDatePicker
                        showTime
                        style={{ width: '100%' }}
                        onChange={(e) => onChange(item.attr_isn, e, 'DATETIME')}
                        placeholder={item.attr_name}
                        size="large"
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                      />
                    </Form.Item>
                  </Col>
                );
              }
              return '';
            }
            case 'DATE': {
              if (Number(item.attr_isn) === 2578291) {
                if (isShowAttr) {
                  return (
                    <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                      <Form.Item
                        label={item.attr_name}
                        name={item.attr_isn}
                        className="bold-label"
                        rules={[
                          {
                            required:
                              typeof item.required === 'boolean'
                                ? item.required
                                : false,
                            message: 'Поле обязательно для заполнения',
                          },
                        ]}
                      >
                        <CDatePicker
                          style={{ width: '100%' }}
                          format={'DD.MM.YYYY'}
                          placeholder={item.attr_name}
                          size="large"
                          className="dateMask"
                          onChange={(e) => onChange(item.attr_isn, e, 'DATE')}
                          disabled={
                            checkDisabledField(stateDateQuotation.status_isn) ||
                            item.readonly
                          }
                        />
                      </Form.Item>
                    </Col>
                  );
                } else {
                  return null;
                }
              } else {
                return (
                  <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                    <Form.Item
                      label={item.attr_name}
                      name={item.attr_isn}
                      className="bold-label"
                      rules={[
                        {
                          required:
                            typeof item.required === 'boolean'
                              ? item.required
                              : false,
                          message: 'Поле обязательно для заполнения',
                        },
                      ]}
                    >
                      <CDatePicker
                        style={{ width: '100%' }}
                        format={'DD.MM.YYYY'}
                        placeholder={item.attr_name}
                        size="large"
                        className="dateMask"
                        onChange={(e) => onChange(item.attr_isn, e, 'DATE')}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                      />
                    </Form.Item>
                  </Col>
                );
              }
            }
            case 'DOCS': {
              if (item.active_mycent) {
                return (
                  <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                    <Form.Item
                      label={item.attr_name}
                      name={item.attr_isn}
                      className="bold-label"
                      rules={[
                        {
                          required:
                            typeof item.required === 'boolean'
                              ? item.required
                              : false,
                          message: 'Поле обязательно для заполнения',
                        },
                      ]}
                    >
                      <CInput
                        placeholder={item.attr_name}
                        size="large"
                        onChange={(e) => onChange(item.attr_isn, e, 'TEXT')}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                      />
                    </Form.Item>
                  </Col>
                );
              }
              return '';
            }
            case 'CHECKBOX': {
              if (item.active_mycent) {
                return (
                  <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                    <Form.Item
                      name={item.attr_isn}
                      valuePropName="checked"
                      className="bold-label"
                      rules={[
                        {
                          required:
                            typeof item.required === 'boolean'
                              ? item.required
                              : false,
                          message: 'Поле обязательно для заполнения',
                        },
                      ]}
                    >
                      <Checkbox
                        onChange={(e) => onChange(item.attr_isn, e, 'CHECKBOX')}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                      >
                        {item.attr_name}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                );
              }
              return '';
            }
            case 'AGREEMENT':
            case 'AGRCALC': {
              if (item.active_mycent) {
                return (
                  <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                    <Form.Item
                      label={item.attr_name}
                      name={item.attr_isn}
                      className="bold-label"
                      rules={[
                        {
                          required:
                            typeof item.required === 'boolean'
                              ? item.required
                              : false,
                          message: 'Поле обязательно для заполнения',
                        },
                      ]}
                    >
                      <CInput
                        placeholder={item.attr_name}
                        size="large"
                        onChange={(e) => onChange(item.attr_isn, e, 'TEXT')}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                      />
                    </Form.Item>
                  </Col>
                );
              }
              return '';
            }
            case 'NUMBER':
            case 'INTEGER': {
              if (item.active_mycent) {
                return (
                  <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                    <Form.Item
                      label={item.attr_name}
                      name={item.attr_isn}
                      className="bold-label"
                      rules={[
                        {
                          required:
                            typeof item.required === 'boolean'
                              ? item.required
                              : false,
                          message: 'Поле обязательно для заполнения',
                        },
                      ]}
                    >
                      <CInput
                        placeholder={item.attr_name}
                        size="large"
                        type={'number'}
                        onChange={(e) => onChange(item.attr_isn, e, 'NUMBER')}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly ||
                          Number(item.id) === 4336
                        }
                      />
                    </Form.Item>
                  </Col>
                );
              }
              return '';
            }
            case 'SUBJECT_SHORT': {
              if (item.active_mycent) {
                return (
                  <SubjectCard
                    key={`fullname-${item.attr_isn}`}
                    elem={{
                      column_name: item.attr_isn,
                      full_name: `fullname-${item.attr_isn}`,
                      label: item.attr_name,
                    }}
                    stateDateQuotation={stateDateQuotation}
                    col={6}
                    mainForm={additionalAttributesForm}
                    size="large"
                    onChange={onChange}
                  />
                );
              }
              return '';
            }
            case 'SUBJECT': {
              if (item.active_mycent) {
                return (
                  <SubjectCard
                    key={`fullname-${item.attr_isn}`}
                    elem={{
                      column_name: item.attr_isn,
                      full_name: `fullname-${item.attr_isn}`,
                      label: item.attr_name,
                    }}
                    stateDateQuotation={stateDateQuotation}
                    col={6}
                    mainForm={additionalAttributesForm}
                    size="large"
                    onChange={onChange}
                  />
                );
              }
              return '';
            }
            default: {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <CInput
                      placeholder={item.attr_name}
                      size="large"
                      onChange={(e) => onChange(item.attr_isn, e, 'NUMBER')}
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }
          }
        })}
      </Row>
    </CustomLoader>
  );
};
export default NewAdditionalAttributesFields;
