import { IAutoDictiItem } from '@modules/Quotation/Interfaces/ObjectQuotation/Auto.interface';
import api from '@modules/Quotation/services';
import catchErrorCustomAxios from '@services/api/catchErrors';
import { useCallback, useState } from 'react';

const useCarGetDictis = () => {
  const [autoData, setAutoData] = useState<IAutoDictiItem[]>([]);
  const [autoCategory, setAutoCategory] = useState<IAutoDictiItem[]>([]);
  const [autoOwner, setAutoOwner] = useState<IAutoDictiItem[]>([]);
  const [autoPurpose, setAutoPurpose] = useState<IAutoDictiItem[]>([]);
  const [autoColor, setAutoColor] = useState<IAutoDictiItem[]>([]);
  const [autoOwnerShip, setAutoOwnerShip] = useState<IAutoDictiItem[]>([]);
  const [autoCountry, setAutoCountry] = useState<IAutoDictiItem[]>([]);

  const fetchCarDicti = useCallback(() => {
    try {
      Promise.all([
        api.auto.getDictiCarAuto(),
        api.auto.getDictiCarCategory(),
        api.auto.getDictiCarOwner(),
        api.auto.getDictiCarPurpose(),
        api.auto.getDictiCarColor(),
        api.auto.getDictiCarOwnerShip(),
        api.auto.getDictiCarCountry(),
      ]).then(
        ([
          response1,
          response2,
          response3,
          response4,
          response5,
          response6,
          response7,
        ]) => {
          setAutoData(response1.data.data.children);
          setAutoCategory(response2.data.data.children);
          setAutoOwner(response3.data.data.children);
          setAutoPurpose(response4.data.data.children);
          setAutoColor(response5.data.data.children);
          setAutoOwnerShip(response6.data.data.children);
          setAutoCountry(response7.data.data.children);
        }
      );
    } catch (error) {
      let message;
      catchErrorCustomAxios(error, message);
    }
  }, []);

  return {
    autoData,
    autoCategory,
    autoOwner,
    autoPurpose,
    autoColor,
    autoOwnerShip,
    autoCountry,
    fetchCarDicti,
  };
};

export default useCarGetDictis;
