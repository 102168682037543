import { EditOutlined } from '@ant-design/icons';
import { Col, Form, Row, Card, Typography, Divider, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddCandidateButton from '../Common/AddCandidateButton';
import CandidateFields from '../Common/CandidateFields';
import MainDataFields from '../Common/MainDataFields';
import SalaryFields from '../Common/SalaryFields';
import SubmitButton from '../Common/SubmitButton';
import AddCandidateModal from './AddCandidateModal';
import { setApplicationById } from './getApplicationDataById';
import { IFetchedApplicationById } from './interface';
import SetupFields from './SetupFields';
import updateForm from './updateForm';

const { Title } = Typography;

function ApplicationRecruiting(props) {
  const [componentDisabled, setComponentDisabled] = useState<boolean>(true);
  const [isOpenCandidateModal, setIsOpenCandidateModal] =
    useState<boolean>(false);
  const [fetchApplicationById, setFetchApplicationById] =
    useState<IFetchedApplicationById>();
  
  const { id } = useParams();
  const { title } = props;

  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (id) {
      setApplicationById(Number(id), setFetchApplicationById);
    }
  }, [id]);

  const onFormLayoutChange = ({ disabled }: { disabled: boolean }) => {
    setComponentDisabled(disabled);
  };

  const showModalMethod = () => {
    setIsOpenCandidateModal(true);
  };

  const checkReminderAndQuantityAmount = () => {
    if (fetchApplicationById) {
      return (
        Number(fetchApplicationById?.quantity_people) ===
        Number(fetchApplicationById?.reminder_quantity_people)
      );
    }
  };

  const checkPermission = () => {
    if (fetchApplicationById) {
      return fetchApplicationById.permission === true;
    }
  };

  const checkPermissionDirector = () => {
    if (fetchApplicationById) {
      return fetchApplicationById.dir_permission === true;
    }
  };

  return (
    <>
      <Card style={{ marginTop: 25, marginBottom: 400 }}>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          type="default"
          className="mb-3"
        >
          Назад
        </Button>

        <Form
          layout="vertical"
          validateMessages={{ required: 'Обязательное поле для заполнения' }}
          name="applicationExisting"
          onValuesChange={onFormLayoutChange}
          disabled={componentDisabled}
          form={form}
          onFinish={(values) => {
            updateForm(id, values);
          }}
          style={{ fontWeight: 'initial' }}
        >
          <Title level={4} style={{ color: '#0057FF', marginBottom: 30 }}>
            Заявка на поиск кандидата
          </Title>

          <MainDataFields disabled={componentDisabled} />

          <Divider />

          <Title level={4} style={{ color: '#0057FF', marginBottom: 30 }}>
            Оплата труда
          </Title>

          <SalaryFields />

          <CandidateFields />
        </Form>

        {componentDisabled === true ? (
          <>
            {checkReminderAndQuantityAmount() === false &&
            checkPermission() === true ? (
              <Row gutter={24} align="bottom">
                <Col xs={24} xl={8}>
                  <AddCandidateButton showModalMethod={showModalMethod} />
                </Col>
                <Col xs={24} xl={{ span: 8, offset: 8 }}>
                  <Button
                    block
                    icon={<EditOutlined />}
                    onClick={() => {
                      setComponentDisabled((prevValue) => !prevValue);
                    }}
                    type="primary"
                  >
                    Изменить
                  </Button>
                </Col>
              </Row>
            ) : (
              checkPermission() === true && (
                <Col xs={24} xl={{ span: 8, offset: 16 }}>
                  <Button
                    block
                    icon={<EditOutlined />}
                    onClick={() => {
                      setComponentDisabled((prevValue) => !prevValue);
                    }}
                    type="primary"
                  >
                    Изменить
                  </Button>
                </Col>
              )
            )}
          </>
        ) : (
          <>
            {checkPermission() === true ? (
              <Row gutter={24}>
                <Col xs={24} xl={8}>
                  <AddCandidateButton showModalMethod={showModalMethod} />
                </Col>
                <Col xs={24} xl={{ span: 8, offset: 8 }}>
                  <SubmitButton
                    buttonText="Сохранить"
                    onClick={() => {
                      form.submit();
                    }}
                  />
                </Col>
              </Row>
            ) : (
              checkPermissionDirector() === true && (
                <Col xs={24} xl={{ span: 8, offset: 16 }}>
                  <SubmitButton
                    buttonText="Сохранить"
                    onClick={() => {
                      form.submit();
                    }}
                  />
                </Col>
              )
            )}
          </>
        )}
      </Card>
      <AddCandidateModal
        isOpenCandidateModal={isOpenCandidateModal}
        setIsOpenCandidateModal={setIsOpenCandidateModal}
        fetchApplicationById={fetchApplicationById}
      />
      {fetchApplicationById && (
        <SetupFields fetchApplicationById={fetchApplicationById} form={form} />
      )}
    </>
  );
}

export default ApplicationRecruiting;
