import {Button, Col, List, message, Result, Row, Space, Tabs, Typography} from "antd";
import Logo from "@modules/common/Logo";
import React, {useEffect, useState} from "react";
import Title from "antd/es/typography/Title";
import api from "@modules/FileExchange/services/api";
import dayjs from "dayjs";

const types = [
    {
        label: 'По папкам',
        key: 'folders',
    },
    {
        label: 'По файлам',
        key: 'files',
    },
    {
        label: 'По ссылкам',
        key: 'links',
    },
    {
        label: 'По пользователю',
        key: 'users',
    },
];

interface User {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    external_id: number;
}

interface Changes {
    name: string;
    user_id: number;
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    link_id: string;
    size: number;
    role: {
        role: string;
    },
    directory: {
        name: string;
    }
}

interface EventData {
    event: string;
    user: User;
    changes: Changes;
    created_at: string;
    updated_at: string;
}

const Audit = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalData, setTotalData] = useState<number>(0);
    const [data, setData] = useState([]);
    const [currentType, setCurrentType] = useState('folders');
    const [access, setAccess] = useState(true);

    const getChildren = (type: string) => {
        setCurrentType(type);

        const apiActions: { [key: string]: (page: number) => Promise<any> } = {
            folders: (page) => api.getDirectoriesAudit(page),
            files: (page) => api.getFilesAudit(page),
            links: (page) => api.getLinksAudit(page),
            users: (page) => api.getUsersAudit(page),
        };

        const action = apiActions[type];

        if (action) {
            action(currentPage)
                .then(data => {
                    setData(data?.data?.data);
                    setTotalData(data.data.meta.total);
                })
                .catch(error => {
                        const status = error.response?.status;
                        setAccess(!(status === 403));

                        if(status !== 403) {
                            message.error('Не удалось получить данные');
                        }
                    }
                );
        } else {
            message.error('Не удалось получить данные')
        }
    };

    const eventTranslations: Record<string, string> = {
        created: 'создано',
        updated: 'обновлено',
        deleted: 'удалено'
    };

    function translateEvent(event: string): string {
        return eventTranslations[event] || event;
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        getChildren(currentType);
    }, [currentPage]);


    return access ? (
        <div className="content-file-exchange">
            <div className="container-file-exchange">
                <Row className="header-file-exchange">
                    <Logo/>
                </Row>
                <Row className="d-flex align-start justify-space-between">
                    <Col span={24}>
                        <div className="sector-file-exchange">
                            <Row className="d-flex justify-space-between">

                                <Title level={4} className="path-category-file-exchange">
                                    Аудит файлообменника
                                </Title>
                                <Row>
                                    <Space size={10}>
                                        <Button
                                            className="add-folder-file-exchange"
                                            size="large"
                                            href="/file-exchange"
                                        >
                                            Вернуться в файлообменик
                                        </Button>
                                        <Button
                                            className="add-folder-file-exchange"
                                            size="large"
                                            href="/file-exchange/admin-panel"
                                        >
                                            Админ. панель
                                        </Button>
                                    </Space>
                                </Row>
                            </Row>
                            <Row className="mt-5">
                                <Tabs
                                    className="w-100"
                                    onChange={(e) => {
                                        setCurrentPage(1);
                                        getChildren(e);
                                    }}
                                    size="large"
                                    tabPosition="left"
                                    items={types.map((type) => {
                                        return {
                                            label: type.label,
                                            key: type.key,
                                            children: <List
                                                pagination={{
                                                    current: currentPage,
                                                    pageSize: 15,
                                                    total: totalData,
                                                    onChange: handlePageChange,
                                                    showSizeChanger: false,
                                                    position: 'bottom',
                                                }}
                                                bordered
                                                dataSource={data}
                                                renderItem={(item: EventData) => (
                                                    <List.Item>
                                                        <Typography.Text
                                                            mark>[{dayjs(item?.created_at).format('DD.MM.YYYY HH:mm:ss')}] </Typography.Text>
                                                        {item?.user?.first_name} {item?.user?.last_name} {item?.user?.email}
                                                        <Typography.Text>  {translateEvent(item?.event)}</Typography.Text>
                                                        {item?.changes?.directory?.name && currentType === 'links' &&
                                                            <Typography.Text> в
                                                                папке {item?.changes?.directory?.name} </Typography.Text>}
                                                        {item?.changes?.id && currentType === 'links' &&
                                                            <Typography.Text> uuid
                                                                ссылки {item?.changes?.id} </Typography.Text>}
                                                        <Typography.Text>  {item?.changes?.first_name} {item?.changes?.last_name}</Typography.Text>
                                                        <Typography.Text>  {item?.changes?.name} {item?.changes?.email} {item?.changes?.role?.role}</Typography.Text>
                                                        {item?.changes?.size &&
                                                            <Typography.Text> {item?.changes?.size} КБ</Typography.Text>}
                                                        {item?.changes?.link_id &&
                                                            <Typography.Text> {item?.changes?.link_id} </Typography.Text>}
                                                    </List.Item>
                                                )}
                                            />,
                                        };
                                    })}
                                />
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    ) : <Result
        status="warning"
        title="У Вас нет прав для доступа к этой странице"
        extra={
            <Button
                className="add-folder-file-exchange"
                size="large"
                href="/file-exchange"
            >
                Вернуться в файлообменик
            </Button>
        }
    />
};

export default Audit;