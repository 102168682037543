import React, { useState, useEffect } from 'react';
import { Empty, Row } from 'antd';
import CalendarSales from '@modules/common/SalesmanDetails/CalendarSales/CalendarSales';
import RaitingSales from './RaitingSales/RaitingSales';
import { IDataSeller } from '@modules/common/SalesmanDetails/IDataSeller';

interface IRaitingType {
  id: number;
  emplisn: number;
  period: string;
  rate: string;
  percentage: string;
  created_at: string;
  updated_at: string;
}

interface IPropsSalesmanDetails {
  dataSeller: IDataSeller | null;
  listRaiting: Array<IRaitingType>;
}

const SalesmanDetails = ({ dataSeller, listRaiting }: IPropsSalesmanDetails) => {
  const [raiting, setRaiting] = useState<boolean>(false);

  useEffect(() => {
    if (listRaiting && listRaiting.length > 0) {
      setRaiting(true);
    } else {
      setRaiting(false);
    }
  }, [listRaiting]);

  if (dataSeller === null) {
    return <Empty />;
  }

  return (
    <>
      <Row className={raiting ? 'raiting-sales' : 'raiting-sales raiting-h'}>
        {raiting ? <RaitingSales listRaiting={listRaiting} /> : <Empty />}
      </Row>
      <Row className="d-flex justify-space-between calendar-salesman">
        <CalendarSales yearPlan={false} type="fact" dataSeller={dataSeller} />
        <CalendarSales yearPlan={true} type="nach" dataSeller={dataSeller} />
        <CalendarSales yearPlan={true} type="brutto" dataSeller={dataSeller} />
      </Row>
    </>
  );
};

export default SalesmanDetails;
