import { Form, FormInstance } from 'antd';
import React, { useEffect } from 'react';
import { fieldNamesRecruiting } from '../Common/fieldNames';
import { IFetchedApplicationById } from './interface';

type SetupFieldsProps = {
  fetchApplicationById: IFetchedApplicationById | undefined;
  form: FormInstance<any>
};

function SetupFields({ fetchApplicationById, form }: SetupFieldsProps) {
  useEffect(() => {
    if (fetchApplicationById) {
      const {
        department_to,
        name_of_post_id,
        quantity_people,
        reason_to_recruiting,
        desired_age,
        sex,
        education,
        work_experience,
        functional_responsibilities,
        is_he_was_boss,
        type_of_hire,
        request_to_candidate,
        perspective_to_candidate,
        computer_knowing,
        salary_after_period,
        motivation,
        job_chart,
        have_car,
        driver_category,
        candidates_trait,
        interview_stage,
        lang_social_packagesen,
        salary,
        reminder_candidates,
        application_status,
      } = fetchApplicationById;

      setFieldMethod(fieldNamesRecruiting.department_to, department_to.id);
      setFieldMethod(fieldNamesRecruiting.name_of_post_id, name_of_post_id.id);
      setFieldMethod(fieldNamesRecruiting.quantity_people, quantity_people);
      setFieldMethod(fieldNamesRecruiting.reminder_candidates, reminder_candidates);
      setFieldMethod(
        fieldNamesRecruiting.reason_to_recruiting,
        reason_to_recruiting.id
      );
      setFieldMethod(fieldNamesRecruiting.desired_age, desired_age.id);
      setFieldMethod(fieldNamesRecruiting.sex, sex.id);
      setFieldMethod(fieldNamesRecruiting.education, education.id);
      setFieldMethod(fieldNamesRecruiting.work_experience, work_experience.id);
      setFieldMethod(
        fieldNamesRecruiting.functional_responsibilities,
        functional_responsibilities
      );
      setFieldMethod(fieldNamesRecruiting.is_he_was_boss, is_he_was_boss.id);
      setFieldMethod(fieldNamesRecruiting.type_of_hire, type_of_hire.id);
      setFieldMethod(
        fieldNamesRecruiting.request_to_candidate,
        request_to_candidate
      );

      if (perspective_to_candidate) {
        const values = perspective_to_candidate.map((element) =>
          parseInt(element)
        );
        setFieldMethod(fieldNamesRecruiting.perspective_to_candidate, values);
      }

      setFieldMethod(
        fieldNamesRecruiting.computer_knowing,
        computer_knowing.id
      );
      setFieldMethod(
        fieldNamesRecruiting.salary_after_period,
        salary_after_period
      );
      setFieldMethod(fieldNamesRecruiting.salary, salary);
      setFieldMethod(fieldNamesRecruiting.motivation, motivation.id);
      setFieldMethod(fieldNamesRecruiting.job_chart, job_chart.id);
      setFieldMethod(fieldNamesRecruiting.have_car, have_car.id);
      if (driver_category !== null) {
        setFieldMethod(
          fieldNamesRecruiting.driver_category,
          driver_category.id
        );
      }
      setFieldMethod(fieldNamesRecruiting.candidates_trait, candidates_trait);
      setFieldMethod(fieldNamesRecruiting.interview_stage, interview_stage);
      setFieldMethod(
        fieldNamesRecruiting.social_packages,
        lang_social_packagesen.id
      );
      setFieldMethod(fieldNamesRecruiting.application_status, application_status.id);
    }
  }, [fetchApplicationById]);

  const setFieldMethod = (field: string, value: number | string | number[]) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  return null;
}

export default SetupFields;
