import React from 'react';

const useFormSubmit = () => {
  function makeNotDeepCopyAndFilterByFieldAndTakeLastResult(
    object: any,
    field: string
  ) {
    const objectCopy = [...object]
      ?.reverse()
      ?.filter(
        (value, index, self) =>
          self.findIndex((data) => data[field] === value[field]) === index
      );
    return objectCopy;
  }

  const clauseValueFilterPickLast = (agrClauses) => {
    const agrClausesData = makeNotDeepCopyAndFilterByFieldAndTakeLastResult(
      agrClauses,
      'clause_isn'
    );

    const responseClauseData = {
      agr_clause: agrClausesData.length ? agrClausesData : null,
    };

    return responseClauseData;
  };

  const agrAttributesCheckEmptyOrNot = (agrAttributes) => {
    const responseAtributeData = {
      agr_attributes: agrAttributes.length ? agrAttributes : null,
    };

    return responseAtributeData;
  };

  function getOnlyLastValuesById(object) {
    const objectModified = object.reduce((acc, cur) => {
      acc[cur.id] = { ...acc[cur.id], ...cur };
      return acc;
    }, {});
    return objectModified;
  }

  const objectValueFilterPickLast = (agrObj) => {
    const objAgr = getOnlyLastValuesById(agrObj);

    const objAgrData = Object.values(objAgr);

    const responseObjAutoData = {
      agr_obj: objAgrData.length ? objAgrData : null,
    };
    return responseObjAutoData;
  };

  const objectCarFilterPickLast = (agrObjCar) => {
    const objCar = getOnlyLastValuesById(agrObjCar);

    const objCarData = Object.values(objCar);

    const responseObjAutoData = {
      agr_obj_car: objCarData.length ? objCarData : null,
    };

    return responseObjAutoData;
  };

  const objectCargoFilterPickLast = (agrObjCargo) => {
    const objCargo = getOnlyLastValuesById(agrObjCargo);

    const objCargoData = Object.values(objCargo);

    const responseCargoData = {
      agr_obj_cargo: objCargoData.length ? objCargoData : null,
    };

    return responseCargoData;
  };

  function makeNotDeepCopyAndFIlterByFieldAndIdAndTakeLastResult(
    object,
    field,
    id
  ) {
    const modifiedObject = [...object]
      ?.reverse()
      ?.filter(
        (value, index, self) =>
          self.findIndex(
            (data) => data[field] === value[field] && data[id] === value[id]
          ) === index
      );
    return modifiedObject;
  }

  const agrObjectAttributesCheckEmptyOrNot = (agrObjAttributes) => {
    const responseAttributesObjectData = {
      agr_obj_attributes: agrObjAttributes.length ? agrObjAttributes : null,
    };

    return responseAttributesObjectData;
  };

  const agrCondFilterPickLast = (agrCond) => {
    const agrCondValue = getOnlyLastValuesById(agrCond);

    const agrCondData = Object.values(agrCondValue);

    const responseCondData = {
      agr_cond: agrCondData.length ? agrCondData : null,
    };

    return responseCondData;
  };

  const agrCalc = (userForeignId, agrIsn) => {
    const responseAgrCalculate = {
      user_isn: userForeignId,
      agr_isn: agrIsn,
    };

    return responseAgrCalculate;
  };

  const fileListSend = (fileList, userForeignId: number, agrIsn: number) => {
    const formData = new FormData();
    formData.append('user_isn', String(userForeignId));
    formData.append('agr_isn', String(agrIsn));
    formData.append('connection', 'centras');
    fileList?.map((file: any, index: any) => {
      formData.append(`files[${index}]`, file);
    });
    return formData;
  };

  return {
    clauseValueFilterPickLast,
    objectValueFilterPickLast,
    agrAttributesCheckEmptyOrNot,
    objectCarFilterPickLast,
    objectCargoFilterPickLast,
    agrObjectAttributesCheckEmptyOrNot,
    agrCondFilterPickLast,
    agrCalc,
    makeNotDeepCopyAndFilterByFieldAndTakeLastResult,
    getOnlyLastValuesById,
    makeNotDeepCopyAndFIlterByFieldAndIdAndTakeLastResult,
    fileListSend,
  };
};

export default useFormSubmit;
