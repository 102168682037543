import { Button, Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import api from '../services/api';
import ResultTable from './ResultTable';

export interface IResult {
  id: number;
  candidate_fullname: string;
  department_to: string;
  recruiting_id: number;
  chief_id: string;
  date_of_conclusion_dou: string | null;
  interview_result: string | null;
  candidate_status: string | null;
}

function ResultRecruiting(props) {
  const [resultData, setResultData] = useState<IResult[]>([]);
  const { title } = props;

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    getResult().then((values) => setResult(values));
  }, []);

  const getResult = async () => {
    const { data } = await api.getResult();
    return data?.data;
  };

  const setResult = (values) => {
    if (values) {
      setResultData(values);
    }
  };

  const downloadFile = async () => {
    const { data } = await api.getResultFile();
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'Результат проделанной работы.xlsx');
    link.click();
    link.remove();
  };

  return (
    <>
      <Card style={{ marginTop: 25, marginBottom: 600 }}>
        <Row gutter={24}>
          <Col xs={24} xl={{ span: 8, offset: 16 }}>
            <Button
              className="mb-6"
              block
              type="primary"
              onClick={downloadFile}
            >
              Скачать
            </Button>
          </Col>
        </Row>
        <ResultTable resultData={resultData} />
      </Card>
    </>
  );
}

export default ResultRecruiting;
