import {
  IInitialState,
  Action,
} from '@modules/Quotation/providers/JournalReducer';
import { IJournalData } from '@modules/Quotation/providers/QuotationJournalProvider';
import { createContext, Dispatch } from 'react';
interface QuotationJournalContextInterface {
  fetchAllSections: (
    agrIsn: number,
    userForeignId: number,
    journalQuotationId: string | undefined
  ) => void;
  setJournalData: (values: IJournalData) => void;
  state: IInitialState;
  dispatch: Dispatch<Action>;
  clearJournalStateMethod: () => void;
}

const QuotationJournalContext = createContext<QuotationJournalContextInterface>(
  {
    fetchAllSections: () => {},
    setJournalData: () => {},
    state: {
      agrRole: [],
      agrClauseJournal: [],
      agrObject: [],
      agrAttribute: [],
      agrObjectAttribute: [],
      agrCond: [],
      agrCar: [],
      agrCargo: [],
      passingApplicationData: [],
      agrDate: {
        date_sign: '',
        date_beg: '',
        date_end: '',
        status_id: 0,
        user_id: 0,
        curr_id: 0,
        remark: '',
        product_id: 0,
        id: 0,
        product_name: '',
        premium_sum_total: '',
      },
      agreementData: {
        ID: '',
        STATUS: '',
        ISSUED: '',
        ISN: '',
      },
      attributesUnder: [],
    },
    dispatch: () => {},
    clearJournalStateMethod: () => {},
  }
);

export default QuotationJournalContext;
