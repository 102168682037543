import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	message,
	Row,
	Typography,
	Upload,
	UploadFile,
	UploadProps
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import './index.css';
import axios from 'axios';
import { JSEncrypt } from 'jsencrypt'
import { publicKey } from '@modules/Agreement/CustomEDS/helpers/publicKey';
import CustomModalEds from '@modules/Agreement/CustomEDS/CustomModalEds/CustomModalEds';

const { Title } = Typography;

const CustomEDS = ({signatureUUID}) => {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [fileBase64, setFileBase64] = useState<string>('');
	const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
	const [cmsInfo, setCmsInfo] = useState({
		bin: null,
		common_name: null,
		company_name: null,
		end: null,
		iin: null,
		issuer_name: null,
		start: null,
	});

	const handleSubmit = () => {
		form.validateFields()
			.then(values => {
				const responseData = {
					cert: fileBase64,
					password: getEncryptPassword(values.password),
				}

				axios.post('https://signature.kz/api/certificate/info', responseData)
					.then((response) => {
						setIsLoading(true);
						if (response.status === 200) {
							setCmsInfo({
								bin: response?.data?.data?.bin,
								common_name: response?.data?.data?.common_name,
								company_name: response?.data?.data?.company_name,
								end: response?.data?.data.end,
								iin: response?.data?.data.iin,
								issuer_name: response?.data?.data?.issuer_name,
								start: response?.data?.data?.start,
							});
							setIsVisibleModal(true);
						} else {
							setIsVisibleModal(false);
						}
					})
					.catch((error) => {
						if (error.response && String(error.response.status)[0] === "4") {
							message.error("Неверный пароль");
						} else {
							message.error("Техническая ошибка, Просим повторить попытку позднее.");
						}
						setIsLoading(false);
					})
					.finally(() => {
						setIsLoading(false);
					});
			})
	};

	const getBase64 = (file, callback) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			callback(reader.result);
		}
		reader.onerror = () => {
			message.error("Ошибка кодирования файла");
		};
	};

	const getEncryptPassword = (password) => {
		const encrypt = new JSEncrypt();
		encrypt.setPublicKey(publicKey);
		return encrypt.encrypt(password);
	};

	const props: UploadProps = {
		name: 'certificate',
		multiple: false,
		fileList: fileList,
		maxCount: 1,
		onChange: ({ fileList }) => {
			setFileList(fileList);
			getBase64(fileList[0]?.originFileObj, (result) => {
				setFileBase64(result.replace("data:", "").replace(/^.+,/, ""));
			});
		},
		customRequest: ({ onSuccess }) => {
			setTimeout(() => {
				onSuccess && onSuccess('ok');
			}, 1000);
		},
		onRemove: () => {
			setFileList([]);
			form.setFieldsValue({ certificate: null });
		},
		accept: '.p12'
	};

	return (
		<Card className="eds-interface">
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				<Row gutter={[24, 0]}>
					<Col span={24}>
						<Divider orientation="left" plain>
							<Title level={5}>Подписание ЭЦП</Title>
						</Divider>
					</Col>
					<Col span={9}>
						<Form.Item
							label="Выберите сертифкат для подписания"
							name="certificate"
							valuePropName="fileList"
							getValueFromEvent={e => (Array.isArray(e) ? e : e && e.fileList)}
							rules={[{ required: true, message: 'Пожалуйста, выберите сертификат' }]}
						>
							<Upload {...props}>
								<Button
									icon={<UploadOutlined/>}
									type="primary"
									size="large"
									className="w-100"
								>
									Выбрать сертификат
								</Button>
							</Upload>
						</Form.Item>
					</Col>
					<Col span={9}>
						<Form.Item
							label="Введите пароль от сертификата"
							name="password"
							rules={[{ required: true, message: 'Пожалуйста, введите пароль сертификата' }]}
						>
							<Input.Password
								placeholder="Введите пароль сертификата"
								iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
								size="large"
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label=" ">
							<Button
								type="primary"
								size="large"
								className="w-100"
								onClick={handleSubmit}
								loading={isLoading}
							>
								Открыть
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<CustomModalEds
				isModalOpen={isVisibleModal}
				setIsVisibleModal={setIsVisibleModal}
				cmsInfo={cmsInfo}
				fileBase64={fileBase64}
				encryptPassword={getEncryptPassword(form.getFieldValue('password'))}
				clearFields={() => {
					setCmsInfo({
						bin: null,
						common_name: null,
						company_name: null,
						end: null,
						iin: null,
						issuer_name: null,
						start: null,
					});
					setFileBase64('');
					setFileList([]);
					setIsVisibleModal(false);
					form.resetFields();
				}}
				signatureUUID={signatureUUID}
			/>
		</Card>
	);
};

export default CustomEDS;