import {
  Card,
  Col,
  Form,
  Row,
  Input,
  DatePicker,
  Select,
  Typography,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import CSelect from '@components/UI/select/CSelect';
import useStatuses from '../hooks/CommonMethods/MainComponents/useStatuses';
import useQuotation from '../hooks/useQuotation';
import CDatePicker from '@components/UI/datepicker/CDatePicker';
import CInput from '@components/UI/input/CInput';
import React, { useEffect, useState } from 'react';
import useQuotationJournal from '@modules/Quotation/hooks/useQuotationJournal';
import { TypeAction } from '@modules/Quotation/providers/DateReducer';
import TreeSelectKias from '@modules/TreeSelect/TreeSelectKias';
import api from '@modules/Quotation/services';
import axios from 'axios';
import CButton from '@components/UI/button/CButton';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import DateMask from '@modules/Edo/DateMask';

const { Option } = Select;

export const enum STATUS_ISN {
  TOBEAGREED = 223369,
  AGREED = 223370,
}

export const isFieldDisabled = (statusIsn: number) => {
  return statusIsn === STATUS_ISN.AGREED || statusIsn === STATUS_ISN.TOBEAGREED;
};

function FormularQuotation({ userForeignId, agrIsn, contractorInfo }) {
  const {
    colleaguesKiasList,
    isLoadingColleaguesKiasList,
    productSelectedQuotation,
    dispatchDate,
    stateDateQuotation,
    agrContractor,
  } = useQuotation();
  const { state } = useQuotationJournal();

  const form = Form.useFormInstance();
  useEffect(() => {
    if (agrContractor?.agr_id && agrContractor?.agr_status) {
      const agrData = {
        agr_id: agrContractor.agr_id,
        agr_status: agrContractor.agr_status,
      };

      form.setFieldsValue(agrData);
    }

    if (agrContractor?.client_id) {
      dispatchDate({
        type: TypeAction.CLIENT_ID,
        payload: agrContractor.client_id,
      });
    }
  }, [agrContractor, form]);

  useEffect(() => {
    if (agrIsn) {
      dispatchDate({
        type: TypeAction.AGR_ISN,
        payload: agrIsn,
      });
    }
  }, [agrIsn]);

  useEffect(() => {
    form.setFieldsValue({ [TypeAction.USER_ISN]: userForeignId });

    if (userForeignId) {
      dispatchDate({
        type: TypeAction.USER_ISN,
        payload: userForeignId,
      });
    }
  }, [form, userForeignId]);

  /**
   * Заполняем формуляр данными с бэка
   */
  useEffect(() => {
    if (stateDateQuotation) {
      form.setFieldValue(
        [TypeAction.DATE_SIGN],
        dayjs(stateDateQuotation.date_sign)
      );
      form.setFieldValue(
        [TypeAction.DATE_BEG],
        dayjs(stateDateQuotation.date_beg)
      );
      form.setFieldValue(
        [TypeAction.DATE_END],
        dayjs(stateDateQuotation.date_end)
      );
      form.setFieldValue([TypeAction.REMARK], stateDateQuotation.remark);
      form.setFieldValue(
        [TypeAction.STATUS_ISN],
        stateDateQuotation.status_isn
      );
      form.setFieldValue([TypeAction.CURRENCY], stateDateQuotation.currency);
    }
  }, [stateDateQuotation]);

  const { statusList } = useStatuses();
  const { Title } = Typography;
  const { TextArea } = Input;

  /**
   * Устанавливает дату окончания +1 год -1 день от даты начала
   * @param date - дата начала
   */
  const setDateEnd = (date) => {
    const dateEnd = dayjs(date, 'DD.MM.YYYY')
      .add(1, 'y')
      .subtract(1, 'd')
      .format('YYYY-MM-DD');
    dispatchDate({
      type: TypeAction.DATE_END,
      payload: dayjs(dateEnd).format('YYYY-MM-DD'),
    });
    form.setFieldValue([TypeAction.DATE_END], dayjs(dateEnd));
  };
  DateMask();
  return (
    <Card className="mt-5">
      <Title className="main-quotation__section-title" level={3}>
        Формуляр
      </Title>
      <Row gutter={18}>
        <Col xs={24} md={8}>
          <Form.Item
            name={TypeAction.DATE_SIGN}
            label="Дата подписания"
            initialValue={dayjs(stateDateQuotation.date_sign)}
          >
            <CDatePicker
              format="DD.MM.YYYY"
              className="w-100 dateMask"
              size="large"
              placeholder="Дата подписания"
              onChange={(e, date) => {
                if (date) {
                  dispatchDate({
                    type: TypeAction.DATE_SIGN,
                    payload: dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  });
                }
              }}
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name={TypeAction.DATE_BEG} label="Дата Начала">
            <CDatePicker
              className="w-100 dateMask"
              format="DD.MM.YYYY"
              size="large"
              placeholder="Дата начала"
              onChange={(e, date) => {
                if (date) {
                  dispatchDate({
                    type: TypeAction.DATE_BEG,
                    payload: dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  });
                  setDateEnd(date);
                }
              }}
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name={TypeAction.DATE_END} label="Дата окончания">
            <CDatePicker
              format="DD.MM.YYYY"
              className="w-100 dateMask"
              size="large"
              placeholder="Дата окончания"
              onChange={(e, date) => {
                if (date) {
                  dispatchDate({
                    type: TypeAction.DATE_END,
                    payload: dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  });
                }
              }}
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} md={12}>
          <Form.Item name={TypeAction.STATUS_ISN} label="Статус">
            <CSelect placeholder="Статус" size="large" disabled={true}>
              {statusList.length &&
                statusList?.map((element) => {
                  return (
                    <Option value={element.foreign_id} key={element.foreign_id}>
                      {element.full_name}
                    </Option>
                  );
                })}
            </CSelect>
          </Form.Item>
        </Col>
        <Col span={8} xs={24} md={12}>
          <Form.Item name={TypeAction.CURRENCY} label="Валюта">
            <CSelect
              placeholder="Валюта"
              size="large"
              onChange={(value) => {
                dispatchDate({
                  type: TypeAction.CURRENCY,
                  payload: value,
                });
              }}
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            >
              {productSelectedQuotation?.map((element) =>
                element.currency?.map((item) => {
                  return (
                    <Option
                      value={Number(item.curr_isn)}
                      key={Number(item.curr_isn)}
                    >
                      {item.curr_name}
                    </Option>
                  );
                })
              )}
            </CSelect>
          </Form.Item>
        </Col>
      </Row>
      {agrContractor?.agr_id && agrContractor?.agr_status ? (
        <>
          <Title className="main-quotation__section-title" level={3}>
            Договор
          </Title>
          <Row gutter={18}>
            <Col span={6} xs={24} md={6}>
              <Form.Item name="agr_status" label="Статус договора">
                <CInput
                  placeholder="Статус договора"
                  theme="light"
                  size="large"
                  disabled={true}
                />
              </Form.Item>
            </Col>

            {agrContractor?.agr_isn && (
              <Col span={6} xs={24} md={6}>
                <CButton
                  type="primary"
                  htmlType="submit"
                  href={`/contract/${agrContractor.agr_isn}`}
                  className="w-100 d-flex justify-center align-center"
                >
                  Просмотреть договор
                </CButton>
              </Col>
            )}

            <Col span={12} xs={24} md={12}>
              <Form.Item label="#Договора" name="agr_id">
                <CInput
                  placeholder="#Договора"
                  theme="light"
                  size="large"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : null}

      <Row gutter={18} style={{ marginBottom: -20 }}>
        <Col span={24}>
          <Form.Item name={TypeAction.REMARK} label="Особые условия">
            <TextArea
              rows={4}
              placeholder="Особые условия"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
          <Form.Item
            style={{ display: 'none' }}
            name={TypeAction.USER_ISN}
            label={`Куратор`}
            initialValue={userForeignId}
          >
            <TreeSelectKias
              colleaguesKiasList={colleaguesKiasList}
              disabled={
                isLoadingColleaguesKiasList ||
                isFieldDisabled(state.agrDate.status_id)
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}
export default FormularQuotation;
