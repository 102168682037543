import { useEffect, useState } from 'react';
import axios from 'axios';

import { Typography, Affix, notification, Skeleton } from 'antd';

import './Bday.scss';
import { IUser, IUsersData } from './type';

import BdayGroup from './BdayGroup';
import api from './api';

const { Title } = Typography;

interface IUsersGroup {
  [key: string]: IUser[];
}

enum MonthListType {
  'Января' = 0,
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
}

function Bday() {
  const [users, setUsers] = useState<IUsersData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const compose =
    (...fns) =>
    (arg) =>
      fns.reduce((composed, f) => f(composed), arg);

  const groupByBirthday = (userList: IUser[] = []): IUsersGroup => {
    const initialValue = {};

    return userList.reduce((group, user) => {
      const { birthday } = user;

      group[birthday] = group[birthday] ?? [];
      group[birthday].push(user);

      return group;
    }, initialValue);
  };

  const groupToArray = (group: IUsersGroup = {}): IUsersData[] =>
    Object.entries(group).map(([key, value]) => ({
      date: key,
      userList: value,
    }));

  const sortByDate = (userList: IUsersData[] = []) =>
    [...userList].sort(
      (itemA, itemB) => parseInt(itemA.date, 10) - parseInt(itemB.date, 10)
    );

  const parseMonth = (dateItem: string) => {
    const pattern = /[а-яА-ЯЁё]/gm;
    const result = dateItem.match(pattern);

    if (result === null) {
      throw new Error('Неверный формат данных');
    }

    return result.join('');
  };

  const sortByMonth = (userList: IUsersData[] = []) =>
    [...userList].sort(
      (itemA, itemB) =>
        parseInt(MonthListType[parseMonth(itemA.date)], 10) -
        parseInt(MonthListType[parseMonth(itemB.date)], 10)
    );

  const getBirthdays = async () => {
    try {
      setIsLoading(true);

      const { data } = await api.getBirthDays();

      const newUsers = compose(
        groupByBirthday,
        groupToArray,
      )(data.data);

      setUsers(newUsers);
    } catch (error) {
      let message;

      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBirthdays();
  }, []);

  return (
    <Affix offsetTop={15}>
      <div className="bday">
        <div className="bday__header">
          <Title level={4} style={{ fontWeight: 'normal' }}>
            Ближайшие дни рождения
          </Title>
        </div>
        {isLoading ? (
          [...Array(3)].map((_, index) => (
            <Skeleton active avatar key={index} />
          ))
        ) : (
          <BdayGroup users={users} />
        )}
      </div>
    </Affix>
  );
}

export default Bday;
