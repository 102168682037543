import api from '@modules/Quotation/services';
import axios from 'axios';
import { notification } from 'antd';
const getContractorList = async ({ dispatch, values }) => {
  dispatch({ type: 'search', payload: true });
  try {
    const { data } = await api.contractor.getContractorByIIN({
      ...values,
    });
    dispatch({ type: 'search', payload: false });

    dispatch({ type: 'nextStep', payload: 2 });

    dispatch({
      type: 'saveContractorList',
      payload: { data: data.data, isJurUser: values.jur },
    });
  } catch (error) {
    dispatch({ type: 'search', payload: false });
    let message;
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status == 404) {
        dispatch({
          type: 'selectContractor',
          payload: { juridical: values.jur },
        });
        dispatch({ type: 'nextStep', payload: 3 });
        // dispatch({ type: 'showSearchContractorModal', payload: true });
      } else if (error.response.status == 401) {
        notification.error({
          message: 'Ошибка',
          description: 'Авторизуйтесь повторно',
        });
      } else {
        notification.error({
          message: 'Ошибка',
          description: error.response.data.message,
        });
      }
    } else {
      notification.error({
        message: 'Ошибка',
        description: String(error),
      });
    }
  }
};
export default getContractorList;
